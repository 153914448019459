import React, { useReducer, useEffect, useContext, useState } from 'react';
import useStyles from './ThresholdsISOStyles';
import t from '../../utils/translation';
import _ from 'lodash';
import request from "../../utils/request";
import LocalizationContext from "../../utils/LocalizationContext";
import reducer from './ThresholdsISOReducer';
import FormattedField from '../../components/FormattedField/FormattedField';
import Button from '../../components/Button/Button';
import { getSession } from "../../utils/sessionHandler";

const ThresholdsISO = (props) => {
    const { object, updateValue, label, min, max, adornment, maxLength, step, isUmbral } = props;

    const initialState = {
        loadingSave: false,
        disabledSave: false
    }

    const locale = useContext(LocalizationContext);
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [alertThreshold, setAlertThreshold] = useState(object[0]);
    const [actionThreshold, setActionThreshold] = useState(object[1]);
    const [errorValidation, setErrorValidation] = useState("");



    const handleSaveThresholds = () => {
        dispatch({ type: "SET_DISABLE_BUTTON" })
        setErrorValidation(null)
        if (parseFloat(alertThreshold.value) >= parseFloat(actionThreshold.value)) {
            setTimeout(() => {
                dispatch({ type: 'SET_ENABLE_BUTTON' })
                setErrorValidation(t("AlertThresholdMustBeHigherThanActionThreshold", locale))
            }, 1000);
            setTimeout(() => {
                setErrorValidation(null)
            }, 4000);

        } else {
            const Parameters = object;
            request()
                .put("/api/appparameter/saveisothresholds", { UserLogged: getSession(), Parameters })
                .then(async response => {
                    setTimeout(() => {
                        props.handleAlertISOThresholds({ type: 'success', msg: response.data })
                        dispatch({ type: 'SET_ENABLE_BUTTON' })
                    }, 1000);
                })
                .catch(error => {
                    console.log(error?.response?.data?.message)
                    props.handleAlertISOThresholds({ type: 'error', msg: error?.response?.data?.message });
                    dispatch({ type: 'SET_ENABLE_BUTTON' })
                })
        }
    }

    return (
        <>
            <div>
                <FormattedField
                    label={label[0]}
                    object={alertThreshold}
                    updateValue={updateValue}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    step={step}
                    isUmbral={isUmbral}
                    handleResponseError={errorValidation}>
                </FormattedField>
            </div>
            <div style={{ marginTop: 8 }}>
                <FormattedField
                    label={label[1]}
                    object={actionThreshold}
                    updateValue={updateValue}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                    step={step}
                    isUmbral={isUmbral}>
                </FormattedField>
            </div>
            <div>
                <Button
                    onClick={() => handleSaveThresholds()}
                    caption={t("SaveButton", locale)}
                    loading={state.loadingSave}
                    loadingText={t("Saving", locale)}
                    disabled={state.disabledSave}>
                </Button>
            </div>
        </>
    );
};
export default ThresholdsISO;