import React from 'react';

import useStyles from './CircularProgressStyles';

export default function CircularProgress(props) {
	const classes = useStyles();
	const {size = 40 } = props

	return (
		<div style={{width:size, height:size}} className={classes.root}>
          <svg viewBox="22 22 44 44">
            <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
          </svg>
        </div>
	)
} 
 