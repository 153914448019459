import React, { useEffect, useState, useContext } from "react";
import request from "../../utils/request";
import { getSession, getDist } from "../../utils/sessionHandler";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import _ from "lodash";
import { flatten } from "flat";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

export default function Company() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  
  const locale = useContext(LocalizationContext);
  
  useEffect(() => {
      setLoadingContent(true);
      updateData()
  }, []);

  const updateData = () => {
    request().get("/api/company/GetByDistributorStr?distributorId=" + getDist().id)
      .then(response => {
        const comp = response.data.map(x =>
          _.mapKeys(flatten(x), (value, key) => {
            return key.match(/^\w+\.\w+$/) ? key : "company." + key;
          })
        )
        setData(comp);
        setLoadingContent(false);
      });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <CRUD
      data={data}
      url={"/api/company"}
      title={t("Title", locale)}
      columns={[
        "company.name",
        "company.country",
        "company.city",
        "company.addres",
        "administrator.toString",
        "administrator.email"
      ]}
      headers={[
        t("NameLabel", locale),
        t("CountryLabel", locale),
        t("CityLabel", locale),
        t("AddressLabel", locale),
        t("AdministratorNameLabel", locale),
        t("AdministratorMailLabel", locale)
      ]}  
      deleteOption={false}        
      editOption={false}          
      newOption={false} 
      idField={"company.id"}
      fieldsMap={[
        "company.name",
        "company.country",
        "company.province",
        "company.city",
        "company.addres",
        "company.phone",
        "company.contact",
        "company.postalCode",
        "administrator.name",
        "administrator.lastName",
        "administrator.email"
      ]}
      formFields={[
        { label: "Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Country", class: "col-12 col-md-6 px-0", required: true },
        { label: "Province", class: "col-12 col-md-6 px-0", required: true },
        { label: "City", class: "col-12 col-md-6 px-0", required: true },
        { label: "Address", class: "col-12 col-md-6 px-0", required: true },
        { label: "Phone", class: "col-12 col-md-6 px-0", required: true },
        { label: "Contact", class: "col-12 col-md-6 px-0", required: true },
        { label: "Postal Code", class: "col-12 col-md-6 px-0", required: true },
        {
          label: "Administrator Name",
          class: "col-12 col-md-6 px-0",
          required: true
        },
        {
          label: "Administrator Last Name",
          class: "col-12 col-md-6 px-0",
          required: true
        },
        {
          label: "Administrator Mail",
          class: "col-12 col-md-6 px-0",
          newOnly: true,
          required: true
        }
      ]}
      detailFields={[
        { label: t("NameLabel", locale), name: "company.name" },
        { label: t("CountryLabel", locale), name: "company.country" },
        { label: t("ProvinceLabel", locale), name: "company.province" },
        { label: t("CityLabel", locale), name: "company.city" },
        { label: t("AddressLabel", locale), name: "company.addres" },
        { label: t("PhoneLabel", locale), name: "company.phone" },
        { label: t("ContactLabel", locale), name: "company.contact" },
        { label: t("PostalCodeLabel", locale), name: "company.postalCode" },
        { label: t("AdministratorNameLabel", locale), name: "administrator.toString" },
        { label: t("AdministratorMailLabel", locale), name: "administrator.email" }
      ]}
      baseObject={{
        company: {
          id: null,
          name: "",
          country: "",
          city: "",
          addres: "",
          phone: "",
          province: "",
          contact: "",
          postalcode: ""
        },
        administrator: {
          name: "",
          lastName: "",
          email: "",
          password: ""
        },
        UserLogged: getSession()
      }}
    />
  );
}
