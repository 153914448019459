export default function reducer(state, action) {
    switch (action.type) {
      case "TOGGLE_LOADING": {
        return {
          ...state,
          pageContentLoading: true,
        };
      }
      case "SET_CONTENT": {
        var algo = {
          ...state,
          sectors: action.payload.sectors,
          users: action.payload.users,
          visualResults: action.payload.visualResults,
          washers: action.payload.washers,
          sterilizers: action.payload.sterilizers,
          disinfectors: action.payload.disinfectors,
          biResults: action.payload.biResults,
          proResults: action.payload.proResults,
          cheResults: action.payload.cheResults,
          pageContentLoading: false
        }
        return algo;
      }
      case "SET_TABLE_FIELDS": {
        return {
          ...state,
          headerText: action.payload.headerText,
          columns: action.payload.columns,
          headers: action.payload.headers,
        };
      }
      case "SET_DATA":
        return {
          ...state,
          data: action.payload.data,
          totalRows: action.payload.totalRows,
          tableActions: action.payload.tableActions,
          loadingData: false,
        };
      case "TOGGLE_LOADING_DATA":
        return {
          ...state,
          loadingData: !state.loadingData,
          activeOption: action.payload.activeOption
        };
      case "SET_FILTERS":
        return {
          ...state,
          filters: action.payload.filters,
        };
      case "CLEAR_FILTERS":
        return {
          ...state,
          filters: {},
        };
      case "SET_PAGINATION":
        return {
          ...state,
          pagination: action.payload.pagination,
        };
      case "SHOW_DIALOG":
        return {
          ...state,
          dialogValidation: true,
        };
      case "CLOSE_DIALOG":
        return {
          ...state,
          dialogValidation: false,
        };
      case "CLOSE_ERROR":
        return {
          ...state,
          errorText: "",
        };
      case "SHOW_ERROR":
        return {
          ...state,
          errorText: action.payload,
          generatingReport: false
        };
      case "GENERATING_REPORT":
        return {
          ...state,
          generatingReport: true,
          dialogValidation: false,
          report_html: "",
        };
      case "REPORT_GENERATED":
        return {
          ...state,
          generatingReport: false,
          reportVisible: true,
          report_html: action.payload,
        };
      case "RETURN_MAIN_PAGE":
        return {
          ...state,
          reportReady: false,
        };
      case "CLOSE_REPORT":
        return {
          ...state,
          reportVisible: false
        }
      case "SET_SELECTBOX_INCUBATORS":
        return {
          ...state,
          incubators: action.payload
        }
      default:
        console.log(`Case not found: ${action.type}`)
        throw new Error();
    }
  }
  