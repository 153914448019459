import React from "react";
import {
  Modal,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import SpinnerQ from "../SpinnerQ/SpinnerQ";
import SpinnerWashing from "../SpinnerWashing/SpinnerWashing";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ModalLoading = ({ open, category = "sterilization" }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      className={classes.modal}
      BackdropComponent={Backdrop}

    >
        {category.toUpperCase() === "WASHING" ? <SpinnerWashing /> : <SpinnerQ />}
    </Modal>
  );
};

export default ModalLoading;
