import DistMain from "../routes/DistMain";
import DistCompany from "../routes/DistCompany";
import DistLicenseCodes from "../routes/DistLicenseCodes";
import DistParameter from "../routes/DistParameter";

const Dist = [
  { path: "/distmain", component: DistMain },
  { path: "/distcompany", component: DistCompany },
  { path: "/distlicenseCodes", component: DistLicenseCodes },  
  { path: "/distparameter", component: DistParameter },
];

export default Dist;

