import React from "react";
import styles from "./ManageDetail.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import df from "../../utils/dateFormater";
import packageImg from "../../assets/images/MENU PACKAGE.svg";
import packageInLoad from "../../assets/images/PACKAGE IN A LOAD.svg";
import ColumnQ from "../ColumnQ/ColumnQ";

export default function ManageDetail({  pkg, load }) {
  let pkgIndicators = pkg?.indicators;
  let materials = pkg?.materials;

  let packages = load?.packages;
  let loadsIndicators = load?.indicators;

  return (
    <ColumnQ
      title={ pkg ? pkg?.description : load?.description}
      img={packageImg}
    >
      {pkg && (
        <div className={styles.detail}>
          <div>
            <p className={`${styles.titles}`}>Materials</p>
            <div className={styles.materialsContainer}>
              {materials.map((material) => (
                <div className={styles.material} key={material.id}>
                  <div className={styles.imgMaterialContainer}></div>
                  <div className={styles.materialTextContainer}>
                    <p className={styles.materialName}>
                      {material.material.name}
                    </p>
                    <p className={styles.materialQuantity}>
                      {material.material.serial
                        ? `Serial: ${material.material.serial}`
                        : `Qty: ${material.quantity}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className={`${styles.titles}`}>Indicators</p>
            <div className={styles.indicatorsContainer}>
              {pkgIndicators?.map((indicator) => (
                <IndicatorLabel
                  name={indicator.indicator.name}
                  lot={indicator.lot}
                  serial={indicator.serial}
                  quantity={indicator.quantity}
                  
                  expirationDate={"2025-03"}
                  key={indicator.id}
                />
              ))}
            </div>
          </div>
          <div>
            <p className={`${styles.titles}`}>Observation</p>
            {pkg.observations && (
              <div className={styles.observationContainer}>
                <p className={styles.observation}>{pkg.observations}</p>
                <div className={styles.observationInfo}>
                  <p>{df(pkg.creationDate, "dateTime")}</p>
                  <p>{pkg.creationUserName}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {load && (
        <div className={styles.detail}>
          <div>
            <p className={`${styles.titles}`}>Packages</p>
            <div className={styles.materialsContainer}>
              {packages?.map((pkg) => (
                <div className={styles.material} key={pkg.id}>
                  <div className={styles.imgMaterialContainer}>
                    <img src={packageInLoad} alt="Package" />
                  </div>

                  <div className={styles.materialTextContainer}>
                    <p className={styles.materialName}>
                      {pkg.package.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className={`${styles.titles}`}>Indicators</p>
            <div className={styles.indicatorsContainer}>
              {loadsIndicators?.map((indicator) => (
                <IndicatorLabel
                  name={indicator.indicator.name}
                  lot={indicator.lot}
                  serial={indicator.serial}
                  zone={indicator.indicatorModelArea.description}
                  expirationDate={"2025-03"}
                  key={indicator.id}
                />
              ))}
            </div>
          </div>
          <div>
            <p className={`${styles.titles}`}>Observation</p>
            {load.observations && (
              <div className={styles.observationContainer}>
                <p className={styles.observation}>{load.observations}</p>
                <div className={styles.observationInfo}>
                  <p>{df(load.creationDate, "dateTime")}</p>
                  <p>{load.creationUserName}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </ColumnQ>
  );
}
