import React, { useEffect, useState, useContext } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import useStyles from "./PrivacyPolicyStyles";
import LocalizationContext from "../../utils/LocalizationContext";
import request from '../../utils/request';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import Paper from '../../components/Paper/Paper';

export default function PrivacyPolicy() {
    const classes = useStyles();
    const locale = useContext(LocalizationContext);
    const [privacyPolicy, setPrivacyPolicy] = useState("")

    useEffect(() => {
        request().get(`/api/PrivacyPolicy`).then((response) => {
            setPrivacyPolicy(response.data.text)
        })
    }, [])

    return (
        <div className={classes.root}>
            <div class={classes.loginCardSignUp}>
                <Paper>
                    <div className={"row"}>
                        <h2 className={classes.title}>{locale.privacyPolicyForm.title}</h2>
                        {
                            !privacyPolicy &&
                            <div className={"col-lg-12"}>
                                <CircularProgress class={classes.loading} />
                            </div>
                        }
                        <div className={"col-lg-12"}>
                            <TextField
                                classes={classes}
                                multiline
                                defaultValue={privacyPolicy}
                                rowsMax={25}
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    );
}