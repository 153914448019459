import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import ValidateURL from "../../utils/ValidateURL";
import { PrintLabelProvider } from "../../utils/context/PrintLabelContext";
import CreateTrays from "../../pages/Washing/CreateTrays/CreateTrays";
import useLocale from "../../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();

  const currentPage = {
    menuGroup: menu?.Company,
    key: "CreateTrays",
  };

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: null }}>
      <NavBar
        moduleName={180}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <CreateTrays />
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};
