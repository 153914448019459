import React, { useState, useEffect, useRef } from "react";
import InputQ from "../../../components/FormComponents/InputQ/InputQ";
import styles from "./InputSearch.module.css";

const InputSearch = ({
  handleInputChange,
  value,
  label,
  name,
  options,
  required,
  errorMessage,
  validate,
  disabled, 
  handleClick
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const containerRef = useRef(null);
  console.log(options)

  const handleInput = (e) => {
    const inputValue = e.target.value;
    handleInputChange(inputValue);
    const filtered = options.filter(
      (option) =>
        inputValue &&
        option[name].toLowerCase().replace(/\s+/g, "").includes(inputValue.toLowerCase().replace(/\s+/g, ""))
    );
    setFilteredOptions(filtered);
    setSelectedIndex(-1);
  };

  const handleOptionClick = (option) => {
    handleClick(option)
    setFilteredOptions([]);
    setSelectedIndex(-1);
  };

const handleKeyDown = (e) => {
  if (e.key === "Enter") {
    e.preventDefault(); 
    if (selectedIndex >= 0) {
      handleInputChange(filteredOptions[selectedIndex][name]);
    }
    setFilteredOptions([]);
    setSelectedIndex(-1);
  } else if (e.key === "ArrowDown") {
    setSelectedIndex((prev) => Math.min(prev + 1, filteredOptions.length - 1));
    e.preventDefault();
  } else if (e.key === "ArrowUp") {
    setSelectedIndex((prev) => Math.max(prev - 1, -1));
    e.preventDefault();
  } else if (e.key === "Tab") {
    setFilteredOptions([]); 
  }
};
  const handleBlur = () => {
    setFilteredOptions([]);
    setSelectedIndex(-1);
  };

  const handleFocus = () => {
    const filtered = options.filter(
      (option) =>
        value &&
        option[name].toLowerCase().replace(/\s+/g, "").includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setFilteredOptions(filtered);
    setSelectedIndex(-1);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setFilteredOptions([]);
        setSelectedIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div className={styles.inputContainer} ref={containerRef}>
      <InputQ
        label={label}
        name={name}
        handleChange={handleInput}
        value={value}
        required={required}
        errorMessage={errorMessage}
        validate={validate}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
        containerWidth="100%"
        disabled={disabled}
        autocomplete="off"
      />
      {filteredOptions.length > 0 && (
        <div className={styles.optionsContainer}>
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className={`${styles.optionItem} ${
                selectedIndex === index ? styles.selected : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option[name]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InputSearch;
