export default function reducer(state, action) {
    switch (action.type) {
      case "SET_READING":
        return {
          ...state,
          reading: action.payload,
        };
      case "SET_FILETYPE":
        return {
          ...state,
          fileType: action.payload,
        };
      case "SET_SECTOR":
        return {
          ...state,
          sector: action.payload,
        };
      case "SET_DATE_FROM":
        return {
          ...state,
          dateFrom: action.payload,
        };
      case "SET_DATE_TO":
        return {
          ...state,
          dateTo: action.payload,
        };
      case "SET_USER":
        return {
          ...state,
          user: action.payload,
        };
      case "SET_INCUBATOR":
        return {
          ...state,
          incubator: action.payload,
        };
      case "SET_COLUMNS":
        return {
          ...state,
          columns: action.payload,
        };
      case "SET_CSVSEPARATOR":
        return {
          ...state,
          CSVSeparator: action.payload,
        };
      case "CLEAR_FIELDS":
        return {
          ...state,
          sector: "",
          dateFrom: null,
          dateTo: null,
          incubator: "",
          user: "",
          columns: [],
        };
      default:
        return new Error();
    }
  };
  