import React, { useContext, useEffect, useState } from "react";

import PageContainer from "../../components/PageGeneralContainer/PageGeneralContainer";

import request from "../../utils/request.js";
import t from "../../utils/translation.js";
import LocalizationContext from "../../utils/LocalizationContext.js";

import styles from "./Washer.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import { getUserCompany } from "../../utils/sessionHandler.js";
import WashersTable from "./WashersTable/WashersTable.jsx";
import WashersForm from "./WashersForm/WashersForm.jsx";
import WashersDetail from "./WashersDetail/WashersDetail.jsx";
import ButtonQ from "../../components/ButtonQ/ButtonQ.js";

const Washer = () => {
  const [washers, setWashers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [methodName, setMethodName] = useState("");
  const [modal, setModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    brand: "",
    serial: "",
    observations: "",
    companyMachineModelId: "",
  });
  const [washerData, setWasherData] = useState({});
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [loading, setLoading] = useState(true);

  const locale = useContext(LocalizationContext);

  const clearForm = () => {
    setFormData({
      name: "",
      brand: "",
      serial: "",
      observations: "",
      companyMachineModelId: "",
      companyMachineMakeId: "",
    });
  };

  const getSectors = () => {
    request()
      .get(`/api/sector?companyId=${getUserCompany().Id}`)
      .then((response) => {
        setSectors(response.data.map((s) => ({ value: s.id, name: s.name })));
      });
  };

  const getWashers = async (sector) => {
    try {
      setLoading(true);
      let res = await request().get(
        `/api/Washer/GetBySector?CompanyId=${
          getUserCompany().Id
        }&SectorId=${sector}`
      );

      let newRes = res.data.map((w) => ({
        ...w,
        modelName: w.companyMachineModel?.name,
        methodName: w.companyMachineModel?.method?.name,
      }));
      setWashers(newRes);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getModels = () => {
    request()
      .get("/api/CompanyMachineModel")
      .then((res) => {
        setModels(
          res.data
            .filter((m) => m.machineType === 2)
            .map((m) => ({
              value: m.id,
              name: m.name,
              brandId: m.companyMachineMakeId,
              methodName: m.method.name,
            }))
        );
      });
  };

  const getBrands = () => {
    request()
      .get("/api/companyMachineMake")
      .then((res) => {
        setBrands(
          res.data.map((b) => ({
            value: b.id,
            name: b.brand,
          }))
        );
      });
  };

  const handleSector = (e) => {
    setSelectedSector(e.target.value);
    getWashers(e.target.value);
  };

  useEffect(() => {
    getSectors();
    getBrands();
    getModels();
  }, []);

  const handleAdd = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    clearForm();
  };

  const handleDetail = (data) => {
    setWasherData(data);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setWasherData({});
  };

  return (
    <PageContainer title={"Washers"} category={"washer"}>
      <div className={styles.select}>
        <SelectQ
          label={t("Sector") || "Sector"}
          placeholder={t("SelectASector") || "Select A Sector"}
          value={selectedSector}
          handleSelect={handleSector}
          options={sectors}
        />
      </div>
      {selectedSector && (
        <>
          <div className={styles.container}>
            <WashersTable
              washers={washers}
              getWashers={getWashers}
              handleData={setFormData}
              handleModal={setModal}
              handleDetail={handleDetail}
              selectedSector={selectedSector}
              loading={loading}
            />
            <div className={styles.detailContainer}>
            <ButtonQ
            caption={t("AddButton") || "Add"}
            onClick={() => handleAdd()}
           />
            </div>
            </div>
          {/* <button
            className={`${styles.button} ${styles.addButton}`}
            onClick={() => handleAdd()}
          >
            Add
          </button> */}
        </>
      )}

      <WashersForm
        formData={formData}
        handleFormData={setFormData}
        handleClose={handleClose}
        open={modal}
        getWashers={getWashers}
        brands={brands}
        models={models}
        methodName={methodName}
        t={t}
        locale={locale}
        selectedSector={selectedSector}
      />

      {showDetail && (
        <WashersDetail
          open={showDetail}
          handleClose={handleCloseDetail}
          t={t}
          locale={locale}
          washerData={washerData}
        />
      )}
    </PageContainer>
  );
};

export default Washer;
