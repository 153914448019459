import df from './dateFormater';
const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export default function filterByProperties(items, filters) {
  return items.filter((item) => {
    return filters.every((filter) => {
      const { property, value } = filter;
      if (value === null || value === "") {
        return true;
      }

      const itemProperty = getNestedProperty(item, property);
      if (itemProperty === undefined || itemProperty === null) {
        return false;
      }

      let isDate = property.toLowerCase().includes("date");

      if (isDate) {
        try {
          const formattedDate = String(df(itemProperty, "date")).toLowerCase();
          return formattedDate.includes(String(value).toLowerCase());
        } catch (e) {
          return false;
        }
      } else if (typeof value === 'string') {
        return String(itemProperty).toLowerCase().includes(value.toLowerCase());
      } else {
        return itemProperty === value;
      }
    });
  });
}