import { useState, useEffect } from "react";
import { getSession, getUserId, setMenu, getMenu } from "../sessionHandler";
import request from "../request";

function useMenu() {
  const [menuOptions, setMenuOptions] = useState(getMenu());


   const requestMenuUrl =
    getSession()?.UserType === undefined
      ? "/api/menu/getsysmenujson"
      : getSession()?.UserType === 3
      ? `/api/menu/getdistmenujson?UserLoggedId=${getUserId()}`
      : `/api/menu/getappmenujson?UserLoggedId=${getUserId()}`;

  if (!menuOptions) {
    request()
      .get(requestMenuUrl)
      .then((response) => {
        setMenu(response.data);
        setMenuOptions(response.data);
      });
  }

  return menuOptions;
}

export default useMenu;
