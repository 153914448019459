import React, { useState, useContext, useEffect } from 'react';
import Progress from "../../components/Progress";
import Button from "../../components/Button";
import useStyles from "../Login/LoginStyles";
import Paper from '../../components/Paper';
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import { getUserId, getSession, getDist } from "../../utils/sessionHandler";
import request from "../../utils/request";
import CurrentPasswordView from './CurrentPasswordView';
import NewPasswordView from './NewPasswordView';
import ConfirmPasswordView from './ConfirmPasswordView';
import Button2 from '../../components/Button2';
import ButtonQ from "../../components/ButtonQ";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";
import changePasswordImage from "../../components/NavBar/Menu/icons/CHANGEPASSWORDWHITE.svg"
import ViewChangePasswordButton from './ViewChangePasswordButton.js';

const changePasswordViewStyle = {
    position:'relative',
    display:'block',
    bottom:'3.5rem',
    left:'85%',
    color:'#D6D6D6',
    height:'20px',
    cursor:'pointer',
}



export default function ChangePassword() {
    const classes = useStyles();
    const locale = useContext(LocalizationContext)

    const [error, setError] = useState({ type: "", message: "" });
    const [loading, setLoading] = useState(false);

    const [passwordActual, setPasswordActual] = useState("");
    const [passwordNuevo, setPasswordNuevo] = useState("");
    const [passwordConfirma, setPasswordConfirma] = useState("");
    const [validationMessage, setValidationMessage] = useState("");

    const iconStyles = {
        iconStyle1: {
          position:'relative',
          display:'block',
          bottom:  error.type === "passwordActual" ? '70px' : '45px',
          color: 'black',
          cursor:'pointer',
          height:'20px',
          left:'85%'
        },
        iconStyle2: {
          position:'relative',
          display:'block',
          bottom:  error.type === "passwordNuevo" ? '70px' : '45px',
          color: 'black',
          cursor:'pointer',
          height:'20px',
          left:'85%'
        },
        iconStyle3: {
            position:'relative',
            display:'block',
            bottom:  error.type === "passwordConfirma" ? '70px' : '45px',
            color: 'black',
            cursor:'pointer',
            height:'20px',
            left:'85%'
          },
      };

    const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      return regex.test(password);
    };
    const validarDatos = () => {

        if (passwordActual === "") {
            console.log('error actual');
            setError({
                type: "passwordActual",
                message: "This field is required"
            });
            setLoading(false);
            setValidationMessage("This field is required");
            return false;
        }

        if (passwordNuevo === "") {
            setError({
                type: "passwordNuevo",
                message: "This field is required"
            });
            setLoading(false);
            setValidationMessage("This field is required");
            return false;
        }
        // Validar que la nueva contraseña cumpla con los requisitos
    if (!validatePassword(passwordNuevo)) {
        setError({
            type: "passwordNuevo",
            message: "8+ characters,1 uppercase letter,1 number."
        });
        setLoading(false);
        setValidationMessage("8+ characters,1 uppercase letter,1 number.");
        return false;
    }

        if (passwordConfirma === "") {
            setError({
                type: "passwordConfirma",
                message: "This field is required"
            });
            setLoading(false);
            setValidationMessage("This field is required");
            return false;
        }

        else if (passwordNuevo !== passwordConfirma) {
            setError({
                type: "passwordConfirma",
                message: "Password does not match"
            });
            setLoading(false);
            setValidationMessage("The passwords do not match");
            return false;
        };


        return true;
    }

    useEffect(() => {
        if (passwordActual || passwordNuevo || passwordConfirma) {
          setValidationMessage("");
        }
      }, [passwordActual, passwordNuevo, passwordConfirma]);

    const enviarCambioPassword = e => {
        document.activeElement.blur();
        e.preventDefault();

        if (!validarDatos()) return;

        const parametrosPost = {
            Appuser: { Email: getSession().Email, id: getUserId() },
            Password: passwordActual,
            NewPassword: passwordNuevo,
            DistributorId: getDist().id
        }

        setLoading(true);
        
        request().post('/api/appuser/changepassword', parametrosPost)
            .then(response => {
                setTimeout(() => { window.location.href = '/' }, 1000);
            })
            .catch(error => {
                if (!error.response) {
                    setError({ type: "alert", message: error.message });
                } else {
                    setError({ type: "alert", message: error.response.data.message });
                }
                setLoading(false);
            })
    }

    const cancelarCambioPassword = (e) => {
        e.preventDefault()
        window.location.href = '/'
    }

    return (
        <PageGeneralContainer
        title={t("Title", locale)}
        category={"changePassword"}
        >
        <div className={classes.root} style={{ height: '100%', display: 'flex', backgroundColor: '#f2f2f2', justifyContent: 'center', alignItems: 'top',minHeight: '0vh' }}>
            <div className={classes.loginCard} style={{backgroundColor: '#f2f2f2'}}>
           
                    <form onSubmit={e => enviarCambioPassword(e)} style={{backgroundColor: '#f2f2f2'}}>

                        <Progress class={classes.loading} active={loading} />

                        {/* <h1 className={classes.loginText} style = {{marginTop: '0px'}}> {t("Title", locale)} </h1> */}
                        {error.type === 'alert' && <div className={"alert alert-danger"}>{error.message}</div>}

                        {/* <CurrentPasswordView 
                        locale={locale}
                        passwordActual={passwordActual}
                        setPasswordActual={setPasswordActual}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle}
                        validationMessage={validationMessage}
                        />
                        <NewPasswordView
                        locale={locale}
                        passwordNuevo={passwordNuevo}
                        setPasswordNuevo={setPasswordNuevo}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle} 
                        validationMessage={validationMessage}
                        />
                        <ConfirmPasswordView 
                        locale={locale}
                        passwordConfirma={passwordConfirma}
                        setPasswordConfirma={setPasswordConfirma}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle}
                        validationMessage={validationMessage}
                        /> */}
                        <ViewChangePasswordButton
                         locale={locale}
                         passwordActual={passwordActual}
                         setPasswordActual={setPasswordActual}
                         passwordNuevo={passwordNuevo}
                         setPasswordNuevo={setPasswordNuevo}
                         passwordConfirma={passwordConfirma}
                         setPasswordConfirma={setPasswordConfirma}
                         setError={setError}
                         error={error}
                         loading={loading}
                         t={t}
                         style={iconStyles}
                        //  style={changePasswordViewStyle}
                         validationMessage={validationMessage}
                        />
             <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto',marginTop: '30px', gap: "20px" }}>
                        <ButtonQ caption={t("CancelButton")}
                            onClick={(e) => { cancelarCambioPassword(e) }}
                            disabled={loading}
                        />
                        <ButtonQ
                            caption={t("AcceptButton")}
                            onClick={(e) => { enviarCambioPassword(e) }}
                            loading={loading}
                        />
            </div>
                    </form>
            </div>
        </div>
        </PageGeneralContainer>
    )
}