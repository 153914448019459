import React, { useContext } from "react";
import { FaGripLines } from "react-icons/fa";
import t from "../../../utils/translation";
import LocalizationContext from "../../../utils/LocalizationContext";
import styles from "./DDRow.module.css";
import { MdDragIndicator } from "react-icons/md";
import IndicatorRule from "../../IndicatorRule/IndicatorRule";

const DDRow = ({
  obj,
  name,
  body,
  serial,
  quantity,
  dragStart,
  materialMethod,
  method,
  doubleClick,
  columnId,
  load,
  img,
  category = "Sterilization",
  key,
  rule
}) => {
  const locale = useContext(LocalizationContext);

  return load ? (
    <div
      className={styles.row}
      draggable
      onDragStart={(e) => dragStart(e, obj)}
      onDoubleClick={() =>
        doubleClick(
          category.toUpperCase() === "WASHING"
            ? obj.packageWashingGeneralId
            : obj.packageGeneralId,
          columnId
        )
      }
      key={key}
    >
      <div className={styles.cell}>
        <div className={styles.content}>
          <p className={styles.title}>
            {img ? (
              <div className={styles.imgContainer}>
                <img src={img} />
              </div>
            ) : (
              <MdDragIndicator className={styles.iconWrapper} />
            )}
            {name}
          </p>
        </div>
      </div>
    </div>
  ) : rule ? (
    <div
      className={styles.row}
      draggable
      onDragStart={(e) => dragStart(e, obj)}
      onDoubleClick={() => doubleClick(obj.id, columnId)}
      key={key}
    >
      <div className={styles.content}>
       <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div className={styles.indicatorItem}>
                  <IndicatorRule
                    backgroundColor={"white"}
                    name={obj.name}
                    key={obj.id} 
                    border={true}
                  />
                </div>
              </div>
              </div>
    </div>
  ) : (
    <div
      className={styles.row}
      draggable
      onDragStart={(e) => dragStart(e, obj)}
      onDoubleClick={() => doubleClick(obj.id, columnId)}
    >
      <div className={styles.cell}>
        <div className={styles.content}>
          <p className={styles.title}>{name}</p>
          {serial && (
            <p className={styles.info}>
              {t("SerialNumber", locale) || "No. Serial"}: {serial}
            </p>
          )}
          <p className={styles.info}>{body}</p>
          {quantity && !serial && (
            <p className={styles.info}>
              {t("Quantity", locale) || "Quantity"}: {quantity}
            </p>
          )}
        </div>
        <div className={styles.iconWrapper}>
          {method !== materialMethod && category == "Sterilization" && (
            <div className={styles.icon}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DDRow;
