import React, { useState, useEffect } from 'react';
import request from '../../utils/request';
import Button from '../Button';
import FixedAlert from '../FixedAlert/FixedAlert';
import t from "../../utils/translation";
import Input from '../Input';
import { getUserId } from '../../utils/sessionHandler';

const QuickDisinfector = ({ onClickCallBack }) => {
    const screenId = 306
    const [disinfector, setDisinfector] = useState({})
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({});
    const [temporalTranslations, setTemporalTranslations] = useState({})
    
    useEffect(() => {
        request().get(`/api/screen/getjson?ScreenNameId=${screenId}&UserLoggedId=${getUserId()}`)
            .then(response => {
                setTemporalTranslations(response.data);
            })
    }, [])

    const isValidForm = () => {
        let localErrors = {}
        let isValid = true;
        if(!disinfector.Name){
            localErrors.Name = t("RequiredFieldError")
            isValid = false
        }
        if(!disinfector.Brand){
            localErrors.Brand = t("RequiredFieldError")
            isValid = false
        }
        if(!disinfector.Serial){
            localErrors.Serial = t("RequiredFieldError")
            isValid = false
        }
        setErrors(localErrors)
        return isValid;
    }

    return (
        <div>
            <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
            <Input
                label={t("NameLabel", temporalTranslations)}
                value={disinfector.Name}
                onChange={(value) => setDisinfector({ ...disinfector, Name: value })}
                error={errors.Name}
            />
            <Input
                label={t("BrandLabel", temporalTranslations)}
                value={disinfector.Brand}
                onChange={(value) => setDisinfector({ ...disinfector, Brand: value })}
                error={errors.Brand}
            />
            <Input
                label={t("SerialLabel", temporalTranslations)}
                value={disinfector.Serial}
                onChange={(value) => setDisinfector({ ...disinfector, Serial: value })}
                error={errors.Serial}
            />
            <Button caption={t("ConfirmButton")} onClick={(e) => {
                if(isValidForm()){
                    request().post(`/api/Disinfector/QuickSave`, { Disinfector: disinfector }).then(resp => {
                        disinfector.Id = resp.data
                        onClickCallBack(disinfector)
                    }).catch(err => {
                        setError(err?.response?.data?.Message)
                    })
                }
            }}></Button>
        </div>
    );
};

export default QuickDisinfector;