import React, { useEffect, useState } from "react";
import styles from "./PackageDetail.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import df from "../../utils/dateFormater";
import packageImg from "../../assets/images/MENU PACKAGE.svg";
import packageInLoad from "../../assets/images/PACKAGE IN A LOAD.svg";
import ColumnQ from "../ColumnQ/ColumnQ";
import PageGeneralContainer from "../PageGeneralContainer/PageGeneralContainer";
import { TiDocumentText } from "react-icons/ti";
import { CiUser } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import loadImg from "../../assets/images/MENU LOAD.svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Tooltip } from "@material-ui/core";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";
import materialImg from "../../components/NavBar/Menu/icons/INSTRUMENTS.svg";
import t from "../../utils/translation";
import request from "../../utils/request";
import ModalLoading from "../ModalLoading/ModalLoading";

// const AccordionSection = ({ title, isOpen, onClick, children }) => (
//   <section className={styles.section}>
//     <div
//       className={styles.sectionHeader}
//       onClick={(e) => {
//         e.stopPropagation(); // Evita que el clic en la cabecera se propague
//         onClick();
//       }}
//     >
//       <h2 className={styles.sectionTitle}>
//         {title}
//         <span className={styles.toggleIcon}>
//           {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />} {/* Puedes reemplazar esto con un icono de flecha */}
//         </span>
//       </h2>
//     </div>
//     {isOpen && <div className={styles.sectionContent}>{children}</div>}
//   </section>
// );

export default function PackageDetail({
  indicatorsWithoutDetail,
  locale,
  packageId,
}) {
  const [pkg, setPkg] = useState([]);
  // const [readIndicators, setReadIndicators] = useState([])
  // const [unreadIndicators, setUnreadIndicators] = useState([])
  const [modalLoading, setModalLoading] = useState(false);
  const [indicators, setIndicators] = useState([]);

  // Datos del paquete
  // let pkgIndicators = pkg?.indicators;
  // let materials = pkg.materials;
  // let packageId = pkg.packageGeneralId

  // const getPackageId = async () => {
  //   try {

  //     const response = await request().get(
  //       `/api/package/info?packageId=${packageId}`
  //     );
  //     setPkg(response.data)
  //     setMaterials(response.data.materials)
  //     const indicators = response.data.indicators
  //     const load = response.data.chargeRelated
  //     const readIndicators = indicators.filter(indicator => indicator.readings && indicator.readings.length > 0);
  //     const unreadIndicators = indicators.filter(indicator => !indicator.readings || indicator.readings.length === 0);
  //     setIndicators(indicators)
  //     setReadIndicators(readIndicators)
  //     setUnreadIndicators(unreadIndicators)
  //     setLoad(load)
  //   } catch (error) {

  //     console.error("Error fetching package indicators:", error);
  //   }
  // };

  const getPackageId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/package/info?packageId=${packageId}`)
        .then((response) => {
          const data = response.data;
          setPkg(data);
          const indicators = response.data.indicators;
          setIndicators(indicators);
          // const readIndicators = indicators.filter(indicator => Array.isArray(indicator.reading));
          // const unreadIndicators = indicators.filter(indicator => !Array.isArray(indicator.reading));
          // setReadIndicators(readIndicators)
          // setUnreadIndicators(unreadIndicators)
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  // const statusLoad = (value) => {
  //   return value === 2 ? "To Start" : value === 3 ? "Started" : "Finished";
  // };

  const statusLoad = (value) => {
    return value === 2
      ? t("ToStart", locale) || "To Start"
      : value === 3
      ? t("Started", locale) || "Started"
      : t("Finished", locale) || "Finished";
  };

  const statusPackage = (packageValue, loadValue) => {
    // Determina el estado del paquete
    switch (packageValue) {
      case 1:
        return t("Assembling", locale);
      case 2:
        return t("ReadyForLoad", locale);
      case 3:
        return t("InLoad", locale);
      case 4:
        return t("Sterilized", locale);
      default:
        return "Unknown Status";
    }
  };

  const getResultText = (result) => {
    switch (result) {
      case 3:
        return t("RejectedSpan");
      case 4:
        return t("AcceptedSpan");
      default:
        return "";
    }
  };

  const getEvaluationText = (result) => {
    switch (result) {
      case 0:
        return t("AgreedSpan");
      case 1:
        return t("DisagreedSpan");
      default:
        return "";
    }
  };

  useEffect(() => {
    getPackageId();
    return () => {
      setPkg([]);
    };
  }, []);

  // const [openSection, setOpenSection] = useState(null);

  // const toggleSection = (section) => {
  //   setOpenSection(openSection === section ? null : section);
  // };

  // const [sectionStates, setSectionStates] = useState({
  //   materials: false,
  //   indicators: false,
  //   observations: false,
  //   loadDetails: false,
  // });

  // const toggleSection = (section) => {
  //   setSectionStates((prevStates) => ({
  //     ...prevStates,
  //     [section]: !prevStates[section],
  //   }));
  // };

  return (
    <div className={styles.container}>
      <ModalLoading open={modalLoading} />
      <div className={styles.header}>
        <div className={styles.topHeader}>
          <div>
            <p className={styles.title}>
              {" "}
              {pkg.packageNumber} - {pkg?.description}
            </p>
            {pkg.chargeRelated?.cycleNumber && (
              <p className={styles.title}>
                {" "}
                {pkg.chargeRelated &&
                  `${t("CycleNumberSpan")}: ${
                    pkg.chargeRelated.cycleNumber
                  } - ${pkg.chargeRelated.sterilizerName} `}
              </p>
            )}
          </div>
          <img src={packageImg} className={styles.packageImage} alt={""} />
        </div>
        <div className={styles.headerDetails}>
          <div className={styles.detailItem}>
            <span className={styles.info}>
              <CiCalendar className={styles.iconStyle} />
              {df(pkg.creationDate, "dateTime")}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={styles.info}>
              <TiDocumentText className={styles.iconStyle} />
              {pkg.methodName}
            </span>
          </div>
          <div className={styles.detailItem}>
            <TiDocumentText className={styles.iconStyle} />
            <span className={styles.info}>
              {pkg.chargeRelated
                ? statusPackage(
                    pkg.packageStatus,
                    pkg.chargeRelated.chargeStatus
                  )
                : statusPackage(pkg.packageStatus)}
            </span>
          </div>
        </div>
      </div>
      {/* Sección de Materiales */}
      {pkg && (
        <section className={styles.section}>
          <div className={styles.imageTitleContainer}>
            <img src={materialImg} alt="Materials" className={styles.image} />
            <h2 className={styles.sectionTitle}>
              {t("PackageDetailMaterials", locale)}
            </h2>
          </div>
          {pkg.materials && pkg.materials.length === 0 ? (
            <p className={styles.noDataMessage}>No materials available.</p>
          ) : (
            <div className={styles.materialsContainer}>
              {pkg.materials &&
                pkg.materials.map((material) => (
                  <div className={styles.material} key={material.materialId}>
                    {/* <div className={styles.imgMaterialContainer}></div> */}
                    <div className={styles.materialTextContainer}>
                      <p className={styles.materialName}>
                        {material.materialName}
                      </p>
                      <div className={styles.iconWrapper}>
                        {<div className={styles.icon}></div>}
                      </div>
                      <p className={styles.materialQuantity}>
                        {material.materialSerial
                          ? `${t("SerialSpan")}: ${material.materialSerial}`
                          : `${t("QuantitySpan")}: ${material.quantity}`}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </section>
      )}

      {[1, 2, 3].includes(pkg.packageStatus) ? (
        <section className={styles.section}>
          <div className={styles.imageTitleContainer}>
            <img src={chemicalImg} alt="Indicators" className={styles.image} />
            <h2 className={styles.sectionTitle}>
              {t("PackageDetailIndicators", locale)}
            </h2>
          </div>
          {pkg.indicators && pkg.indicators.length === 0 ? (
            <p className={styles.noDataMessage}>{t("NoIndicatorsSpan")}.</p>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {pkg.indicators?.map((indicator) => (
                <div className={styles.indicatorItem}>
                  <IndicatorLabel
                    backgroundColor={"white"}
                    name={indicator.indicatorName}
                    lot={indicator.lot}
                    serial={indicator.serial}
                    quantity={indicator.quantity}
                    expirationDate={indicator.expirationDate}
                    key={indicator.indicatorId} // Clave única para cada tarjeta
                    border={true}
                  />
                </div>
              ))}
            </div>
          )}
        </section>
      ) : (
        <section className={styles.section}>
          <div className={styles.imageTitleContainer}>
            <img src={chemicalImg} alt="Indicators" className={styles.image} />
            <h2 className={styles.sectionTitle}>
              {t("PackageDetailIndicators", locale)}
            </h2>
          </div>
          {pkg.indicators && pkg.indicators.length === 0 ? (
            <p className={styles.noDataMessage}>{t("NoIndicatorsSpan")}.</p>
          ) : (
            <div
              style={{
                // width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {indicators.map((indicator) => (
                <Tooltip
                  key={indicator.id}
                  title={
                    <div>
                      <div>Product: {indicator.indicatorName}</div>
                      <div>Lot: {indicator.lot}</div>
                      {indicator.serial && (
                        <div>
                          {t("SerialSpan")}: {indicator.serial}
                        </div>
                      )}
                      {indicator.reading !== null ? (
                        <div>
                          <div>
                            {t("StatusSpan")}:
                            {indicator.reading !== null
                              ? t("ReadSpan")
                              : t("UnreadSpan")}
                          </div>
                          <div>
                            {t("ReadingNroSpan")}:{" "}
                            {indicator.reading.readNumber}
                          </div>
                          <div>
                            {t("AutomaticReadoutSpan")}:{" "}
                            {getResultText(indicator.reading.result)}
                          </div>
                          <div>
                            {t("UserEvaluationSpan")}:{" "}
                            {getEvaluationText(indicator.reading.visualResult)}
                          </div>
                          <div>
                            {t("CreationDateSpan")}:{" "}
                            {df(indicator.reading.creationTest, "dateTime")}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {t("StatusSpan")}: {t("UnreadSpan")}.{" "}
                          {t("UseTrazantoLensSpan")}
                        </div>
                      )}
                    </div>
                  }
                  arrow
                >
                  <div className={styles.indicatorItem}>
                    <IndicatorLabel
                      name={indicator.indicatorName}
                      lot={indicator.lot}
                      serial={indicator.serial}
                      expirationDate={indicator.expirationDate}
                      backgroundColor={"white"}
                      border={true}
                      animation={true}
                      status={
                        indicator.reading !== null
                          ? t("ReadSpan")
                          : t("UnreadSpan")
                      }
                      nro={
                        indicator.reading !== null
                          ? indicator.reading.readNumber
                          : undefined
                      }
                      result={
                        indicator.reading !== null
                          ? indicator.reading.result
                          : undefined
                      }
                      evaluationResult={
                        indicator.reading !== null
                          ? indicator.reading.visualResult
                          : undefined
                      }
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </section>
      )}

      {/* Sección de Observaciones */}
      {pkg && (
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>{t("ObservationSpan")}</h2>
          {pkg.observations ? (
            <div className={styles.observationContainer}>
              <p className={styles.observation}>{pkg.observations}</p>
              <div className={styles.observationInfo}>
                <p>{df(pkg.creationDate, "dateTime")}</p>
                <p>{pkg.creationUserName}</p>
              </div>
            </div>
          ) : (
            <p className={styles.noDataMessage}>{t("NoObservationSpan")}.</p>
          )}
        </section>
      )}

      {/* Sección de Carga Relacionada */}
      {pkg.chargeRelated && (
        <section className={styles.section}>
          <div className={styles.imageTitleContainer}>
            <img src={loadImg} alt="Load Detail" className={styles.image} />
            <h2 className={styles.sectionTitle}>{t("LoadDetails")}</h2>
          </div>
          <div className={styles.detailHeader}>
            <div className={styles.headerContent}>
              {/* <img
                className={styles.headerImage}
                src={loadImg}
                alt="Load Image"
              /> */}
              <div className={styles.headerText}>
                <p className={styles.headerTitle}>
                  {pkg.chargeRelated.chargeDescription}
                </p>
                <div className={styles.headerDetails}>
                  <div className={styles.detailItem}>
                    <AssignmentIcon className={styles.iconStyle} />
                    <span className={styles.info}>
                      {t("LoadSpan")} {pkg.chargeRelated.chargeNumber}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <PersonIcon className={styles.iconStyle} />
                    <span className={styles.info}>
                      {pkg.chargeRelated.sterilizerName}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <CiUser className={styles.iconStyle} />
                    <span className={styles.info}>
                      {pkg.chargeRelated.operator}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <CiCalendar className={styles.iconStyle} />
                    <span className={styles.info}>
                      {df(pkg.chargeRelated.creationDate, "dateTime")}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <TiDocumentText className={styles.iconStyle} />
                    <span className={styles.info}>
                      {statusLoad(pkg.chargeRelated.chargeStatus)}
                    </span>
                  </div>
                  {/* {load.observations && (
                    <div className={styles.detailItem}>
                      <TiDocumentText className={styles.iconStyle} />
                      <span className={styles.info}>
                        {load.observations}
                      </span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );

  ///////////////Items como desplegables /////////////////

  // return (
  //   <div className={styles.container}>
  //      <div className={styles.header}>
  //       <p className={styles.title}>{ pkg?.description}</p>
  //       {/* <img src={packageImg} alt={""} /> */}
  //       <div className={styles.headerDetails}>
  //                 <div className={styles.detailItem}>
  //                   <span className={styles.info}>
  //                   <CiCalendar className={styles.iconStyle} />
  //                     {df(pkg.creationDate,"dateTime")}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <span className={styles.info}>
  //                   <TiDocumentText className={styles.iconStyle} />
  //                     {pkg.method.name}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <TiDocumentText className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     {statusPackage(pkg.packageStatus)}
  //                   </span>
  //                 </div>
  //               </div>
  //               </div>
  //     {/* Sección de Materiales */}
  //     {pkg && (
  //       <AccordionSection
  //         title="Materials"
  //         isOpen={sectionStates.materials}
  //         onClick={() => toggleSection('materials')}
  //       >
  //         {materials.length === 0 ? (
  //           <p className={styles.noDataMessage}>No materials available.</p>
  //         ) : (
  //           <div className={styles.materialsContainer}>
  //             {materials.map((material) => (
  //               <div className={styles.material} key={material.id}>
  //                 {/* <div className={styles.imgMaterialContainer}></div> */}
  //                 <div className={styles.materialTextContainer}>
  //                   <p className={styles.materialName}>
  //                     {material.material.name}
  //                   </p>
  //                   <p className={styles.materialQuantity}>
  //                     {material.material.serial
  //                       ? `Serial: ${material.material.serial}`
  //                       : `Qty: ${material.quantity}`}
  //                   </p>
  //                 </div>
  //               </div>
  //             ))}
  //           </div>
  //         )}
  //       </AccordionSection>
  //     )}

  //     {/* Sección de Indicadores */}
  //     {pkg && (
  //       <AccordionSection
  //         title="Indicators"
  //         isOpen={sectionStates.indicators}
  //         onClick={() => toggleSection('indicators')}
  //       >
  //         {pkgIndicators && pkgIndicators.length === 0 ? (
  //           <p className={styles.noDataMessage}>No indicators available.</p>
  //         ) : (
  //           <div
  //             style={{
  //               width: "275px",
  //               display: "flex",
  //               gap: "10px",
  //               border: "1px solid rgb(228, 223, 223)",
  //               borderRadius: "10px",
  //             }}
  //           >
  //             {pkgIndicators?.map((indicator) => (
  //               <IndicatorLabel
  //                 // backgroundColor={"white"}
  //                 name={indicator.indicator.name}
  //                 lot={indicator.lot}
  //                 serial={indicator.serial}
  //                 quantity={indicator.quantity}
  //                 expirationDate={"2025-03"} // Asegúrate de reemplazar esto con el valor correcto
  //                 key={indicator.id}
  //               />
  //             ))}
  //           </div>
  //         )}
  //       </AccordionSection>
  //     )}

  //   {/* Sección de Observaciones */}
  //   {pkg && (
  //       <AccordionSection
  //         title="Observations"
  //         isOpen={sectionStates.observations}
  //         onClick={() => toggleSection('observations')}
  //       >
  //         {pkg.observations ? (
  //           <div className={styles.observationContainer}>
  //             <p className={styles.observation}>{pkg.observations}</p>
  //             <div className={styles.observationInfo}>
  //               <p>{df(pkg.creationDate, "dateTime")}</p>
  //               <p>{pkg.creationUserName}</p>
  //             </div>
  //           </div>
  //         ) : (
  //           <p className={styles.noDataMessage}>No observations available.</p>
  //         )}
  //       </AccordionSection>
  //     )}

  //     {/* Sección de Carga Relacionada */}
  //     {load && (
  //       <AccordionSection
  //         title="Related load"
  //         isOpen={sectionStates.loadDetails}
  //         onClick={() => toggleSection('loadDetails')}
  //       >
  //         <div className={styles.detailHeader}>
  //           <div className={styles.headerContent}>
  //             {/* <img
  //               className={styles.headerImage}
  //               src={loadImg}
  //               alt="Load Image"
  //             /> */}
  //             <div className={styles.headerText}>
  //               <p className={styles.headerTitle}>{load.description}</p>
  //               <div className={styles.headerDetails}>
  //                 <div className={styles.detailItem}>
  //                   <AssignmentIcon className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     LOAD {load.id}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <PersonIcon className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     {load.sterilizer.name}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <CiUser className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     {load.creationUserName}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <CiCalendar className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     {df(load.creationDate, "dateTime")}
  //                   </span>
  //                 </div>
  //                 <div className={styles.detailItem}>
  //                   <TiDocumentText className={styles.iconStyle} />
  //                   <span className={styles.info}>
  //                     {statusLoad(load.chargeStatus)}
  //                   </span>
  //                 </div>
  //                 {/* {load.observations && (
  //                   <div className={styles.detailItem}>
  //                     <TiDocumentText className={styles.iconStyle} />
  //                     <span className={styles.info}>
  //                       {load.observations}
  //                     </span>
  //                   </div>
  //                 )} */}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </AccordionSection>
  //     )}
  //   </div>
  // );
}
