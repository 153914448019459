import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import AppMigrationDatabase from "../pages/AppMigrationDatabase";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default () => {
    const menu = useMenu();
    const currentPage = {
        menuGroup: menu?.Configuration,
        key: "MigrationDatabase",
    };
    const screenName = 322
    const locale = useLocale(screenName);

    return (
        <ValidateURL currentPage={currentPage}>
            <LocalizationContext.Provider value={{ locale: locale }}>
                <NavBar
                    screenName={screenName}
                    moduleName={100}
                    MenuOptions={menu}
                    currentPage={currentPage}
                >
                    {locale ? <AppMigrationDatabase /> : <PageContentLoading />}
                </NavBar>
            </LocalizationContext.Provider>
        </ValidateURL>
    );
};