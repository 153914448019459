import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },

    header: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d',
        position: 'absolute',
        left: 25
    }
}))