import React, { useContext, useEffect, useState } from "react";
import styles from "./LinkIncubations.module.css";
import request from "../../utils/request";
import { getUserId } from "../../utils/sessionHandler";
import IncubatorsCarousel from "../../components/incubatorsCarousel/IncubatorsCarousel";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IndicatorsTable from "../../components/IndicatorsTable/IndicatorsTable";
import IndicatorSelected from "../../components/indicatorsSelectedModal.js/IndicatorSelectedModal.js/IndicatorSelected";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import PageContainer from "../../components/PageContainer/PageContainer";

const Incubators = ({ params }) => {
  const [incubators, setIncubators] = useState([]);
  const [AllIncubators, setAllIncubators] = useState([]);
  const [incubator, setIncubator] = useState("");
  const [incubatorCode, setIncubatorCode] = useState(null);
  const [incubatorId, setIncubatorId] = useState(null);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const [charge, setCharge] = useState(null);
  const [unassignedModal, setUnassignedModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [unassignedIndicators, setUnassignedIndicators] = useState([]);
  const [filteredUnassignedIndicators, setFilteredUnassignedIndicators] =
    useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [unlinkId, setUnlinkId] = useState(null);
  const [unlinkEvent, setUnlinkEvent] = useState(null);

  const handleOpenModal = (id) => {
    id ? handleSelectedIndicator(id) : handleSelectedIndicator(null);
    setModal(!modal);
  };

  const handleConfirmation = (id, e) => {
    setConfirmation(!confirmation);
    if (id) {
      setUnlinkId(id);
      setUnlinkEvent(e);
    }
  };

  const handleSelectedIndicator = (value) => {
    let item = indicators.find((i) => i.id === value);
    setSelectedIndicator(item);
  };

  const locale = useContext(LocalizationContext);

  const onIncubatorHandler = (value) => {
    setIncubator(value.name);
    setIncubatorCode(value.toString);
    setIncubatorId(value.id);
  };

  const unassignedModalHandler = (event, item) => {
    event.stopPropagation();
    setUnassignedModal(!unassignedModal);
    setSelectedIndicator(item);
  };

  const linkHandler = (chargeIndicatorId, readingBiId) => {
    request()
      .put(
        `/api/Charge/BI_Link?chargeIndicatorId=${chargeIndicatorId}&readingBiId=${readingBiId}`
      )
      .then(() => getIndicators(charge.id));
  };

  const unlinkHandler = () => {
    unlinkEvent.stopPropagation();
    request()
      .put(`/api/Charge/BI_Unlink?chargeIndicatorId=${unlinkId}`)
      .then(() => getIndicators(charge.id));
  };

  const parseFilter = (date) => {
    const dateSplit = date.split("/");
    let newDate = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
    return newDate;
  };

  const filterUnassigned = (dateFrom, dateTo, statusFilter, resultFilter) => {
    const filter = unassignedIndicators.filter(
      (u) =>
        (statusFilter ? u.status === parseInt(statusFilter) : true) &&
        (dateFrom
          ? parseFilter(df(u.startedTime, "date")) >=
            parseFilter(df(dateFrom, "date"))
          : true) &&
        (dateTo
          ? parseFilter(df(u.startedTime, "date")) <=
            parseFilter(df(dateTo, "date"))
          : true) &&
        (resultFilter ? u.result === parseInt(resultFilter) : true)
    );

    setFilteredUnassignedIndicators(filter);
  };

  const getCharge = (id) => {
    request()
      .get(`/api/Charge/${id}`)
      .then((response) => {
        setCharge(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getUnassignedIndicators = () => {
    request()
      .get(
        `/api/charge/unassignedReadings?chargeIndicatorId=${selectedIndicator.id}&incubatorId=${incubatorId}`
      )
      .then((response) => {
        setUnassignedIndicators([
          ...response.data.finishedReadings,
          ...response.data.inProgressReadings,
        ]);

        setFilteredUnassignedIndicators([
          ...response.data.finishedReadings,
          ...response.data.inProgressReadings,
        ]);
      });
  };

  const getIndicators = (id) => {
    setModalLoading(true);

    try {
      request()
        .get(`/api/charge/getindicatorsbycharge?chargeid=${id}`)
        .then((response) => {
          setIndicators(response.data);
        })
        .finally(() => {
          setModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setModalLoading(false);
    }
  };

  const getIncubatorName = (id) => {
    let item = AllIncubators.find((i) => i.id == id);

    return item;
  };

  let cycleId = params.cycleId;

  useEffect(() => {
    getCharge(cycleId);
    getIndicators(cycleId);
    setLoadingCarousel(true);
    request()
      .get(`/api/Incubator?${getUserId()}`)
      .then((response) => {
        setIncubators(response.data.filter((i) => i.status === 1));
        setAllIncubators(response.data);
        setLoadingCarousel(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <PageContainer
      category={"Sterilization"}
      subcategory={"Cycles"}
      title={"Manage Cycles"}
      subtitle={"Finished"}
      backUrl={"/appmanagecycles"}
      scan={true}
    >
      <ModalLoading open={modalLoading} />
      {confirmation && (
        <ConfirmationModal
          closeModal={handleConfirmation}
          handleConfirm={() => unlinkHandler()}
        />
      )}
      {!modal ? (
        <>
          <div className={styles.status}>
            <div className={styles.container}>
              <h2 className={styles.subTitle}>
                {t("CycleManagement", locale) || "Cycle Management"} N°{" "}
                {charge?.cicleNumber}
              </h2>
              <div className={styles.content}>
                <p className={styles.normalText}>
                  <AutorenewIcon />
                  {t("Sterilizer", locale) || "Sterilizer"}
                  {":"}
                  <span className={styles.boldText}>
                    {charge?.sterilizer?.name}
                  </span>
                </p>
                <p className={styles.normalText}>
                  <DateRangeIcon />
                  {t("Date", locale) || "Date"}
                  {":"}
                  <span className={styles.boldText}>
                    {df(charge?.creationDate, "date")}
                  </span>
                </p>
              </div>
            </div>
            <IncubatorsCarousel
              onIncubatorHandler={onIncubatorHandler}
              incubators={incubators}
              loadingCarousel={loadingCarousel}
              incubatorCode={incubatorCode}
              cycle={true}
            />
          </div>
          {!loadingCarousel && (
            <IndicatorsTable
              handleOpen={handleOpenModal}
              indicators={indicators}
              unlinkHandler={handleConfirmation}
              unassignedModalHandler={unassignedModalHandler}
              getIncubatorName={getIncubatorName}
              incubator={incubatorId}
              selectedIndicator={selectedIndicator}
            />
          )}
        </>
      ) : (
        <IndicatorSelected
          incubatorCode={incubatorCode}
          open={modal}
          handleOpen={handleOpenModal}
          readings={filteredUnassignedIndicators}
          selectedIndicator={selectedIndicator}
          linkHandler={linkHandler}
          charge={charge}
          filterUnassigned={filterUnassigned}
          getUnassignedIndicators={getUnassignedIndicators}
        />
      )}
    </PageContainer>
  );
};

export default Incubators;
