import Swal from "sweetalert2";

const useConfirmation = () => {
  const confirm = ({
    title = "Are you sure?",
    text = "",
    icon = "warning",
    confirmButtonText = "Yes",
    cancelButtonText = "No",
    onConfirm,
    confirmButtonColor = 'var(--primary-green)', // Color del botón de confirmación
    cancelButtonColor = 'var(--delete)', // Color del botón de cancelar
    backgroundColor = '#f4f4f4' // Color de fondo del modal
  }) => {
    Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      customClass: {
        popup: 'popup',
        confirmButton: 'confirmButton',
        cancelButton: 'cancelButton',
        actions: 'buttonContainer',
      },
      background: backgroundColor,
      confirmButtonColor,
      cancelButtonColor,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then((result) => {
      if (result.isConfirmed && onConfirm) {
        onConfirm();
      }
    });
  };

  return { confirm };
};

export default useConfirmation;
