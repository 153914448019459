import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./AutocompleteStyles";
import { makeStyles } from '@material-ui/core/styles';
import { sortByStringField } from "../../utils/functions/Sorter";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif", // Define tu tipografía deseada
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "transparent !important", // Color del borde
        },
      },
    },
  },
});

const whiteClasses = makeStyles((theme) => ({
  inputRoot: {
    margin: 0,
    padding: '3px 35px 4px 7px !important',
    backgroundColor: 'white', //white
    fontFamily:"Montserrat",
    color:"black", //black
    borderRadius: 10,
  },

  input:{
    padding: '6.5px 4px !important',
  }
}));

const whiteTextFieldStyle = {
  width: "110%",
  marginTop: "2px",
  // backgroundColor: '#3A3A3A',
  borderRadius: 10,
  marginBottom: '10px',
};

const commonClasses = makeStyles((theme) => ({
  root: {
    padding: '9px 0px',
  },
  inputRoot: {
    margin: 0,
    padding: '0 35px 0 0 !important',
    backgroundColor: 'white',
  },

  input:{
      lineHeight: 0.5,
      padding: '13.5px 13px 10.5px 2px !important',
  },
}));

const commonTextFieldStyle = {
  width: "110%", 
  marginTop: "2px", 
  backgroundColor: 'white', 
  borderRadius: 10,
  marginBottom: '10px',
};

const labelStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 0.5,
  }
}));

export default function AutoComplete(props) {
  const classes = useStyles()
  const labelClasses = labelStyles()
  let autoCompleteClasses = null
  const {
    label,
    options,
    onChange,
    defaultValue,
    multiple = false,
    descriptionField = "toString",
    loading,
    value,
    disabled,
    white,
    header,
    placeholder
  } = props;  
  
  if(white){
    autoCompleteClasses = whiteClasses();
  }
  else{
    autoCompleteClasses = commonClasses();
  }
  return (  
    <>
     <ThemeProvider theme={theme}>
      {/* {
        header &&
        <label className={classes.label}>{label}</label>
      } */}
      <Autocomplete
        disabled={disabled}
        multiple={multiple}
        classes={autoCompleteClasses}
        options={sortByStringField(options, descriptionField)}
        getOptionLabel={(option) => option[descriptionField]}
        defaultValue={defaultValue}
        value={value}        
        onChange={(event, newValue) => onChange(newValue)}
        filterSelectedOptions={true}
        renderInput={(params) => {
          return (
            <TextField
              style={ white && !props.error ? whiteTextFieldStyle : commonTextFieldStyle }
              {...params}
              label={white ? "" : label}
              placeholder={placeholder}
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                ...params.InputLabelProps,
                classes: labelClasses,

              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )
        }}
        noOptionsText={"No Options"}
        renderOption={(option, { inputValue }) => {
          const matches = match(option[descriptionField], inputValue);
          const parts = parse(option[descriptionField], matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        loading={loading}
        loadingText={"Loading..."}
      />
      {props.error && (
        <p className={classes.errorMessage} >
          {props.error}
        </p>
      )}
      </ThemeProvider>
    </>
  );
}
