import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({

    //Button: Input file//
    root: {
        fontSize: "0.875rem",
        fontWeight: 700,
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        lineHeight: "1.75",
        borderRadius: 4,
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        border: "0!important",
        outline: "0!important",
        display: 'flow-root'
    },

    labelCss: {
        borderRadius: 5,
        width: '250px',
        height: '32px',
        textAlign: 'center',
        padding: 4,
        margin: '8px 0 4px 0',
        display: 'block'
    },

    titleSection: {
        borderBottom: '1px solid',
        margin: '0 0 7px 0',
        borderColor: theme.palette.terragene.grey,
        fontSize: '0.90rem',
        color: theme.palette.terragene.grey
    },

    logoValidations: {
        margin: '0 0 7px 0',
        borderColor: theme.palette.terragene.grey,
        fontSize: '0.80rem',
        color: theme.palette.terragene.grey
    },



    disabled: {
        cursor: "auto!important",
        color: "rgba(0,0,0,0.26)",
    },

    containedVariant: {
        padding: "4px 10px",

        '&$disabled': {
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0.12)"
        }
    },

    textVariant: {
        padding: '6px 8px',
        backgroundColor: 'transparent'
    },

    primaryColor: {
        '&$containedVariant$active': {
            color: theme.palette.terragene.lightThird,
            backgroundColor: theme.palette.terragene.lightMain,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.terragene.darkThird
            }
        },

        '&$textVariant$active': {
            color: theme.palette.terragene.darkMain,

            "&:hover": {
                backgroundColor: 'rgba(3, 69, 128, 0.08)'
            }
        }
    },

    secondaryColor: {
        '&$containedVariant$active': {
            color: theme.palette.terragene.lightThird,
            backgroundColor: theme.palette.terragene.darkMain,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.terragene.darkThird
            }
        },

        '&$textVariant$active': {
            color: theme.palette.terragene.darkMain,

            "&:hover": {
                backgroundColor: 'rgba(3, 69, 128, 0.08)'
            }
        }
    },

    errorColor: {
        '&$containedVariant$active': {
            color: "#fff",
            backgroundColor: theme.palette.error,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.errorAction
            }
        },

        '&$textVariant$active': {
            color: theme.palette.errorAction,

            "&:hover": {
                backgroundColor: 'rgba(176, 0, 32, 0.08)'
            }
        }
    },

    fullwidth: {
        width: "100%",
    },

    verticalMargin: {
        margin: "10px 0"
    },

    lateralMargin: {
        margin: "0 10px"
    },

    dense: {
        margin: 0
    },

    active: {},
}))
