import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import t from "../../utils/translation";
import { Box } from "@material-ui/core";

const Chart = ({ data, userConfig, titleName, translations, backgroundColor, fontColor }) => {
    const initialState = {
        chartData: {
          chart: {
            type: 'pie',
            marginBottom: 70,
            spacingBottom: 0,
            height: "auto",
            innerWidth:100,
            backgroundColor: backgroundColor
              ? backgroundColor
              : "white",
            border:"1px solid black",

          },
          legend: {
            align: "center",
            verticalAlign: "bottom",
            floating: true,
            itemStyle: {
              color: fontColor,
            },
          },
          title: {
            style: {
              color: fontColor,
              fontFamily:"Montserrat",
            },
            text: titleName,
          },
          subtitle: {
            text:
              data.reduce((accumulator, obj) => accumulator + obj.y, 0) +
              " " +
              t("ChartCentralLabel", translations),
            floating: true,
            style: {
              color: fontColor,
              fontSize: 12,
              fontWeight: "bold",
              fontFamily:"Montserrat",
            },
          },

          series: [
            {
              data: data,
            },
          ],
          ...userConfig,
        },
        options:{
          responsive:true,
        }
      };

  const [state] = useState(initialState);

  return (
    <>
    {
      data.length > 0 ? (
          <HighchartsReact highcharts={Highcharts} options={state.chartData} />
      ) : (
        <p>{t("NotFoundInfo", translations)}</p>
      )
    }
    </>
  );
};

export default Chart;
