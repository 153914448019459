import React, { useState } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import Button from '../Button';
import t from "../../utils/translation";
import request from '../../utils/request';
import FixedAlert from '../FixedAlert/FixedAlert';

const Suscription = ({ suscription, dispatch, locale }) => {

    const [alertState, setAlertState] = useState({});
    const [loadingRequest, setLoadingRequest] = useState(false);

    const sendRequest = () => {
        setLoadingRequest(true)
        request().post(`/api/Suscriptions/TestRequest`, suscription).then((resp) => {
            setAlertState({
                type: 'info',
                text: resp.data
            })
        }).catch((err) => {
            setAlertState({
                type: 'error',
                text: err?.response?.data?.Message
            })
        }).finally(() => {
            setLoadingRequest(false)
        })
    }

    const closeAlert = () => {
        setAlertState({
            ...alertState,
            text: ''
        })
    }

    return (
        <>
            <div className={'row'} style={{ padding: 5 }}>
                <div className={'col-lg-2'} style={{ paddingTop: 4 }}>
                    <FormControlLabel
                        control={<Checkbox checked={suscription.suscribed} onChange={(event) => {
                            suscription.suscribed = event.target.checked
                            dispatch({ type: 'onChange', payload: suscription })
                        }}></Checkbox>}
                        label={suscription.indicatorType.name}
                    />
                </div>
                <div className={'col-lg-8'}>
                    <TextField style={{ width: '100%' }} value={suscription.url} onChange={(event) => {
                        suscription.url = event.target.value
                        dispatch({ type: 'onChange', payload: suscription })
                    }}></TextField>
                </div>
                {
                    suscription.buttonTest === true || suscription.buttonTest === undefined &&
                    <div className={'col-lg-2'}>
                        <Button onClick={() => { sendRequest() }} color={'secondary'} caption={t('TestButton', locale)}></Button>
                        {loadingRequest && <CircularProgress size={25} />}
                    </div>
                }
            </div>
            <FixedAlert type={alertState.type} text={alertState.text} closeErrorCb={closeAlert}></FixedAlert>
        </>
    );
};

export default Suscription;