import React, { useEffect, useState } from 'react';
import CRUD from '../../components/CRUD';
import request from '../../utils/request';
import df from '../../utils/dateFormater';

const LicenseCodes = () => {
    const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/distributor`)
      .then((response) => {
        setData(response.data);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      masterDetail={{
          visible: true,
          url: "/api/LicenseCode/",
          key: 'id',
          transformations: [{ key: "startDate", function: df, format: "date" }, { key: "endDate", function: df, format: "date" }],
          columns: ["code", "used", "licenseProduct", "company", "licenseStatusDescription", "startDate", "endDate"],
          headers: ["Code", "Used", "Product", "Licensed To", "Status", "Start Date", "End Date"]
        }}
      data={data}
      newOption={false}
      deleteOption={false}
      editOption={false}
      detailOption={false}
      loadingData={loadingData}
      url={"/api/distributor"}
      updateData={updateData}
      title={"License Codes by Distributor"}
      columns={["name", "administrator.email"]}
      headers={["Name", "Email"]}
      idField={"id"}
      fieldsMap={["name", "administrator.email"]}
      baseObject={{
        periodName: "",
        days: "",
        trial: false
      }}
    />
  );
};

export default LicenseCodes;