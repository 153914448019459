import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import GlobalBrands from "../pages/GlobalBrands/GlobalBrands";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Equipment,
    key: "Brand",
  };

  return (
    <LocalizationContext.Provider value={null}>
      <ValidateURL currentPage={currentPage}>
        <NavBar MenuOptions={menu} currentPage={currentPage}>
          <GlobalBrands />
        </NavBar>
      </ValidateURL>
    </LocalizationContext.Provider>
  );
};
