import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
	root: {
		backgroundColor: "transparent",
		fontFamily: "Montserrat",
		maxWidth: "200px",
		
		"&:hover": {
			backgroundColor: "#E0E0E0",
		}
	},
	cardContainer: {
		textAlign: "center",
		alignItems: "center",
		fontSize: "18px",
		paddingTop: "6px",
		borderRadius: "100px 100px 0px 0px",
		cursor: "pointer",
		height: '250px',
		width: '170px'
	},
	colorDefault: {
		backgroundColor: theme.palette.terragene.darkThird
	},
	avatar: {
		width: "85px",
		height: "85px",
		display: "flex",
		overflow: "hidden",
		position: "relative",
		fontSize: "1.25rem",
		alignItems: "center",
		flexShrink: "0",
		lineHeight: "1",
		userSelect: "none",
		borderRadius: "50%",
		justifyContent: "center",
		margin: "auto",

		"& .terragene-biological-indicator": {
			width: "80px",
			height: "80px"
		},
		"& .terragene-protein-indicator": {
			width: "80px",
			height: "80px"
		},
		"& .terragene-chemical-indicator": {
			width: "80px",
			height: "80px"
		}
	},
	iconColor: {
		color: "white",
		fontSize: "48px",
	},
	label: {
		fontSize: "15px",
		width: "170px",
		margin: "20px 0",
		fontWeight: "lighter",
		textTransform: "uppercase",
		paddingLeft: 0,
		paddingRight: 0,
	}
}))