import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import IQAS from "../pages/IQAS";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import useLocale from "../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Dashboard,
    key: "IQAS",
  };
  const screenName = 840
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={40}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          <IQAS />
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
