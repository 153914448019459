import React from 'react';
import { useEffect, useState, useContext } from 'react';
import useStyles from './UploaderStyles';
import FixedAlert from '../../components/FixedAlert/FixedAlert';
import classNames from "classnames";
import { CompanyContext } from '../../utils/context/CompanyContext';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import t from '../../utils/translation';

export default function Uploader({ file, translation, options, dispatch, action }) {
    const companyState = useContext(CompanyContext);
    const classes = useStyles();
    const [alertText, setAlertText] = useState("");
    const [type, setType] = useState("success");
    const acceptExtensions = options.extensionsAllowed.join(",")
    const
        fullwidth = false,
        variant = "contained",
        color = "primary",
        isVerticalMargined = false,
        isLateralMargined = false

    useEffect(() => {
        if (file)
            companyState.setCompany({ id: file.id, image: file.file });
        if (options.clearFileSelected)
            dispatch({ type: 'CLEAR' })
    }, [options.clearFileSelected]);

    const convertToBase64 = (uploadedFile) => {
        if (uploadedFile != null && uploadedFile != undefined) {
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
                if (dispatch)
                    dispatch({ type: action, payload: { id: file.id, file: e.target.result, name: uploadedFile.name } })
            });
            reader.readAsDataURL(uploadedFile);
        }
    }

    const keepFormat = (uploadedFile) => {
        if (dispatch)
            dispatch({ type: action, payload: { id: uploadedFile?.id, file: uploadedFile, name: uploadedFile.name } })
    }

    const validateExtension = (file) => {
        var nameFile = file.name;
        var ultimoPunto = file.name.lastIndexOf(".");
        var extension = nameFile.slice(ultimoPunto, nameFile.length);
        if (options.extensionsAllowed.indexOf(extension) == -1) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            <Card variant="outlined" style={{ padding: 10, marginBottom: 10,marginLeft:25 }}>
                <div className={classes.titleSection}>
                    {translation.title}
                </div>
                <FixedAlert text={alertText} type={type} closeErrorCb={() => { setAlertText("") }}></FixedAlert>
                {
                    file && options.showPreViewImage &&
                    <img
                        src={file.file}
                        alt=""
                        width={options.widthFile}
                        height={options.heightFile}
                    />
                }
                {
                    options.showFileName && file && <p>{file.name}</p>
                }
                <div className={classes.logoValidations}>
                    {translation.info && translation.info.map((trans, index) => {
                        return (
                            <div key={index}>{trans}</div>
                        )
                    })}
                </div>
                <label className={classNames(
                    classes.root,
                    classes[variant + "Variant"],
                    classes[color + "Color"], {
                    [classes.disabled]: options.disableButton,
                    [classes.active]: !options.disableButton,
                    [classes.fullwidth]: fullwidth,
                    [classes.verticalMargin]: isVerticalMargined,
                    [classes.lateralMargin]: isLateralMargined
                })}>
                    {translation.uploadButtonText}
                    <input
                        type="file"
                        accept={acceptExtensions}
                        style={{ display: 'none' }}
                        disabled={options.disableButton}
                        onChange={(e) => {
                            if (options.extensionsAllowed && !validateExtension(e.target.files[0])) {
                                setType('error')
                                setAlertText(translation.invalidExtensionError || t("InvalidExtension"))
                            } else {
                                if (options.useBase64FormattedFile)
                                    convertToBase64(e.target.files[0])
                                else
                                    keepFormat(e.target.files[0])
                            }
                        }}
                        onClick={e => (e.target.value = null)}
                    />
                </label>
            </Card>
        </div>
    );
}