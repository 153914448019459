import React, { useMemo, useState } from "react";
import propTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import t from "../../../utils/translation";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import request from "../../../utils/request";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { IndicatorType } from '../../../dictionaries/indicatorTypes'

export default function TableRow(props) {

  const [masterDetailState, setMasterDetailState] = useState({
    open: false,
    rows: [],
    loading: false
  })

  const {
    columns,
    row,
    classes,
    actions,
    multiSelect,
    connectionStatus,
    resultStatus,
    selected,
    idField = "",
    masterDetail,
    colSpan,
    noResultList,
    editedInfo,
    migrationInfo,
    viewedMark
  } = props;

  const idValue = row[idField] ? row[idField].toString() : "";

  const actionsCountFunction = () => {
    let count = 0;
    props.handleRowEdit && count++;
    props.handleRowDetail && count++;
    props.handleRowDelete && count++;

    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      let isVisible = true;

      if (action.hasCondition === true) {
        for (let j = 0; j < action.condition.length; j++) {
          switch (action.condition[j][1]) {
            case "!=":
              if (row[action.condition[j][0]] !== action.condition[j][2]) {
              } else {
                isVisible = false;
              }
              break;
            case "=":
              if (row[action.condition[j][0]] === action.condition[j][2]) {
              } else {
                isVisible = false;
              }
              break;
            default:
              break;
          }
        }
        if (isVisible) count++;
        action["visible"] = isVisible;
      } else {
        count++;
        action["visible"] = isVisible;
      }
    }

    return count;
  };

  const actionsCount = useMemo(actionsCountFunction, [props]);

  const getCell = (key, value) => {
    if (typeof value === "boolean") {
      return (
        <td key={key}>
          <Checkbox
            checked={value}
          />
        </td>
      )
    } else {
      return (
        <td className={classNames({
          [classes.notUpper]: key === "proteinFormat" || 
                              key === "cuantitativeResultFormat" || 
                              key === "ReadingPRO.cuantitativeResultFormat" ||
                              key === "alertResult" ||
                              key === "actionResult" ||
                              key === "surfaceFormat" ||
                              key === "surface"
        })} key={key}>
          {value}
        </td>
      )
    }
  }

  const resultStatusDescription = row["ReadingBI.resultDescription"] || row["ReadingPRO.resultDescription"] || row["ReadingCHEWasher.resultDescription"] || row["ReadingCHESterilizer.resultDescription"]
  const viewed = row["ReadingBI.viewed"] || row["ReadingPRO.viewed"] || row["ReadingCHEWasher.viewed"] || row["ReadingCHESterilizer.viewed"] || !viewedMark

  let editedRow = false;
  switch (editedInfo.IndicatorType) {
    case IndicatorType.Biological:
    case IndicatorType.Disinfection:
      editedRow = row["ReadingBI.loteProduct"]
      break;
    case IndicatorType.Protein:
      editedRow = row["ReadingPRO.loteProduct"]
      break;
    case IndicatorType.ChemicalSterilizer:
      editedRow = row["ReadingCHESterilizer.cycle"]
      break;
    case IndicatorType.ChemicalWasher:
      editedRow = row["ReadingCHEWasher.washerId"]
      break;
    default:
      break;
  }
  return (
    <>
      <tr
        className={classNames(classes.hasEditAction,
          {
            [classes.rowRoot]: viewed
          },
          {
            [classes.rowRootNew]: !viewed
          },
          {
            [classes.selectedRow]: selected,
          })}
        style={{ cursor: props.handleRowClick ? "pointer" : "auto" }}
        onClick={props.handleRowClick && (() => props.handleRowClick(row))}
      >
        {multiSelect && (
          <td className={classes.adormentContainer}>
            <div className={classes.selectCheck}>
              <i
                className={`${selected ? "fas fa-check-square" : "far fa-square"}`}
              />
            </div>
          </td>
        )}
        {editedInfo.enabled &&
          <td className={classes.connectionStautsContainer}>
            {editedRow &&
              <>
                <ReactTooltip
                  effect="solid"
                  place="bottom"
                  id={"edited"}
                />
                <svg
                  width={15}
                  style={{ marginBottom: 2 }}
                  key={"toHistorical"}
                  viewBox={"0 0 24 24"}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  data-place="bottom"
                  data-for="edited"
                  data-tip={t('Edited')}
                >
                  <path d={"M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z"} />
                </svg>
              </>
            }
          </td>
        }
        {migrationInfo.enabled &&
          <td className={classes.connectionStautsContainer}>
            {
              row[migrationInfo.field] &&
              <>
                <ReactTooltip
                  effect="solid"
                  place="bottom"
                  id={"edited"}
                />
                <i style={{margin: 2}} className="fas fa-coins" data-place="bottom" data-for="edited" data-tip={t("FromMigration")}></i>
              </>
            }
          </td>
        }
        {connectionStatus && (
          <td className={classes.connectionStautsContainer}>
            <ReactTooltip
              effect="solid"
              place="bottom"
              id={idValue + "connection"}
            />
            <div className={classes.connectionStatus}>
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                tabIndex="-1"
                style={{
                  color:
                    row.status === 1
                      ? "#64dd17"
                      : row.status === 2
                        ? "#d50000"
                        : "ffd600",
                }}
                data-tip={row.incConnectionStatusDescription}
                data-for={idValue + "connection"}
                data-place="bottom"
              >
                <circle cx="12" cy="12" r="8" />
              </svg>
            </div>
          </td>
        )}
        {masterDetail.visible && (
          <td className={classes.masterDetailContainer}>
            {masterDetailState.open ? (
              <ExpandLessIcon onClick={() => {
                setMasterDetailState({
                  ...masterDetailState,
                  open: false
                })
              }} className={classes.masterDetail} />
            ) : (
              <ExpandMoreIcon onClick={() => {
                setMasterDetailState({
                  ...masterDetailState,
                  loading: true
                })
                request().get(masterDetail.url + row[masterDetail.key])
                  .then((detail) => {
                    if (masterDetail.transformations && masterDetail.transformations.length > 0) {
                      masterDetail.transformations.forEach((transform) => {
                        detail.data.forEach((data) => {
                          data[transform.key] = transform.function(data[transform.key], transform.format);
                        })
                      })
                    }
                    setMasterDetailState({
                      ...masterDetailState,
                      loading: false,
                      open: true,
                      rows: detail.data
                    })
                  })

              }} className={classes.masterDetail} />
            )}
          </td>
        )}
        {resultStatus && (
          <td className={classes.connectionStautsContainer}>
            <ReactTooltip
              effect="solid"
              place="bottom"
              id={idValue + "connection"}
            />
            <div className={classes.connectionStatus}>
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                tabIndex="-1"
                style={{ color: (row["ReadingBI.color"] || row["ReadingPRO.color"] || row["ReadingCHEWasher.color"] || row["ReadingCHESterilizer.color"]) }}
                data-tip={resultStatusDescription}
                data-for={idValue + "connection"}
                data-place="bottom"
              >
                <circle cx="12" cy="12" r="8" />
              </svg>
            </div>
          </td>
        )}
        {columns.map((column) => (
          column.name ? (
            getCell(column.name, row[column.name])
          ) : (
            getCell(column, row[column])
          )
        ))}
        <td className={props.classes.actionsRoot}>
          <ReactTooltip
            effect="solid"
            place="bottom"
            id={idValue + "action"}
            multiline={true}
            className="tooltip-inner"
          />
          <div style={{ marginLeft: -34 * actionsCount, }}>
            {props.handleRowEdit && (
              <svg
                viewBox={"0 0 24 24"}
                className={classes.actionButton}
                onClick={(event) => {
                  props.handleRowEdit(row);
                  event.stopPropagation();
                }}
                data-tip={t("EditTooltip") || "Edit"}
                data-for={idValue + "action"}
                data-place="bottom"
              >
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
              </svg>
            )}
            {props.handleRowDetail && (
              <svg
                viewBox={"0 0 24 24"}
                className={classes.actionButton}
                onClick={(event) => {
                  row["Viewed"] = true;
                  props.handleRowDetail(row);
                  event.stopPropagation();
                }}
                data-tip={t("DetailTooltip") || "Detail"}
                data-for={idValue + "action"}
                data-place="bottom"
              >
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            )}
            {props.handleRowDelete && (
              <svg
                viewBox={"0 0 24 24"}
                className={classes.actionButton}
                onClick={(event) => {
                  props.handleRowDelete(row);
                  event.stopPropagation();
                }}
                data-tip={t("DeleteTooltip") || "Delete"}
                data-for={idValue + "action"}
                data-place="bottom"
              >
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
              </svg>
            )}
            {actions.map((action) => {
              if (action["visible"]) {
                return (
                  <svg
                    key={action.action}
                    viewBox={"0 0 24 24"}
                    className={classes.actionButton}
                    onClick={(event) => {
                      action.action(row);
                      event.stopPropagation();
                    }}
                    data-for={idValue + "action"}
                    data-place="bottom"
                    data-tip={action.tooltip}
                  >
                    <path d={action.icon} />
                  </svg>
                );
              }
              return null;
            })}
          </div>
        </td>
      </tr>
      <tr style={{height: "5px", backgroundColor:"transparent"}}></tr>

      {
        masterDetailState.loading &&
        <tr className={classes.rowRoot}>
          <td colSpan={colSpan}>
            <div style={{ textAlign: 'center' }}>
              <CircularProgress></CircularProgress>
            </div>
          </td>
        </tr>
      }
      {masterDetailState.open && masterDetailState.rows && masterDetailState.rows.length === 0 && masterDetailState.loading === false && noResultList()}
      {masterDetailState.open && masterDetailState.rows && masterDetailState.rows.length > 0 &&
        <tr className={classes.rowRoot}>
          <td colSpan={colSpan}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  {
                    masterDetail.headers.map((header) => {
                      return <th key={header}>{header}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  masterDetailState.rows.map((row, i) => {
                    return (
                      <>
                      <tr key={row.id}>
                        {
                          masterDetail.columns.map((column) => {
                            return <td key={column}>{row[column]}</td>
                          })
                        }
                      </tr>
                      </>
                    )
                  })
                }
              </tbody>
            </table>
          </td>
        </tr>
      }
    </>
  );
}

TableRow.propTypes = {
  columns: propTypes.array.isRequired,
  row: propTypes.object.isRequired,
};
