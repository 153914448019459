import React, { useState, useEffect, useRef } from "react";
import Table from "../../components/Table";
import request from "../../utils/request";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import Modal from "../../components/Modal";
import PageContentLoading from "../../components/PageContentLoading";

import { getSysUserLanguage } from '../../utils/sessionHandler'
import useStyles from "./ModuleStyles";

export default function Module() {
  const languagePermitted = getSysUserLanguage()
  const classes = useStyles();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [confirmationModal, setConfirmationModal] = useState({ open: false });
  const [permittedSetDefault, setPermittedSetDefault] = useState(false);

  const inputRef = useRef(null);
  const inputRefGO = useRef(null);

  useEffect(() => {
    if (languagePermitted) {
      setPermittedSetDefault(true)
    }
    request()
      .get("/api/module")
      .then((response) => {
        setData(response.data);
        setLoadingContent(false);
      })
      .catch((error) => console.log(error.response));
  }, []);

  const setDefaultData = () => {
    setLoading(true);
    request()
      .post("/api/defaultvalues")
      .then((response) => {
        setLoading(false);
        setAlert({ type: "success", message: response.data });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      })
      .catch((error) => {
        setLoading(false);
        error.response &&
          setAlert({ type: "danger", message: error.response?.data?.message });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      });
  };

  const setDefaultObjectsData = () => {
    setLoading(true);
    request()
      .post("/api/DefaultValues/DefaultObjectsValues")
      .then((response) => {
        setLoading(false);
        setAlert({ type: "success", message: response.data });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      })
      .catch((error) => {
        setLoading(false);
        error.response &&
          setAlert({ type: "danger", message: error.response?.data?.message });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      });
  };

  const exportConfiguration = () => {
    setLoading(true);
    request()
      .post("/api/migration/export")
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([JSON.stringify(response.data.JsonDataFile)])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.FileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleFileImport = async () => {
    setLoading(true);

    let File = new FormData();
    File.append("File", inputRef.current.files[0]);

    request()
      .post("/api/migration/import", File, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (response) => {
        let statusResponse = await request().get("/api/migration/importstatus");
        while(statusResponse.data.status === "running") {
          await new Promise(resolve => setTimeout(resolve, 15000));
          statusResponse = await request().get("/api/migration/importstatus");
        }
        setLoading(false);
        setAlert({ type: "success", message: statusResponse.data.detail });
      })
      .catch((error) => {
        setLoading(false);
        if(error.response)
          setAlert({ type: "danger", message: error.response?.data?.message });
        else
          setAlert({ type: "danger", message: "Unknown error (error.response == null)" });
      });
  };

  const handleFileImportGeneralObject = () => {
    setLoading(true);

    let File = new FormData();
    File.append("File", inputRefGO.current.files[0]);

    request()
      .post("/api/migration/importGeneralObjects", File, {
        "Content-Type": "multipart/form-data",
      })
      .then((response) => {
        setLoading(false);
        setAlert({ type: "success", message: response.data });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      })
      .catch((error) => {
        setLoading(false);
        error.response &&
          setAlert({ type: "danger", message: error.response?.data?.message });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 7000);
      });
  };


  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      {loading && (
        <div className={classes.progressContainer}>
          <CircularProgress size={80} />
        </div>
      )}
      <input
        type="file"
        onChange={() =>
          setConfirmationModal({
            open: true,
            header: "Import Configuration",
            text:
              "Are you sure you want to import the configuration from this file? The Current configuration will be lost",
            action: handleFileImport,
          })
        }
        ref={inputRef}
        style={{ display: "none" }}
      />

      <input
        type="file"
        onChange={() =>
          setConfirmationModal({
            open: true,
            header: "Import General Objects",
            text:
              "Are you sure you want to import the configuration from this file? The Current configuration will be lost",
            action: handleFileImportGeneralObject,
          })
        }
        ref={inputRefGO}
        style={{ display: "none" }}
      />

      {alert.message && (
        <div
          className={`alert alert-${alert.type}`}
          style={{ marginTop: 30 }}
          role="alert"
        >
          {alert.message}
        </div>
      )}

      <div className={classes.importExportContainer}>
        <div style={{marginTop:"80px"}}>
          <Button
            caption={"Default menu, message, special obj, general obj and mails Values"}
            disabled={loading}
            hidden={permittedSetDefault}
            onClick={() =>
              setConfirmationModal({
                open: true,
                header: "Set Default Values",
                text:
                  "Are you sure you want to set all the default values? The Current configuration will be lost",
                action: setDefaultData,
              })
            }
          />
          <Button
            caption={"Default object screen values"}
            disabled={loading}
            hidden={permittedSetDefault}
            onClick={() =>
              setConfirmationModal({
                open: true,
                header: "Set Default Values",
                text:
                  "Are you sure you want to set all the default objects values? The Current configuration will be lost",
                action: setDefaultObjectsData,
              })
            }
          />
        </div>
        <div>
          <Button
            caption={"Import Configuration"}
            disabled={loading}
            hidden={permittedSetDefault}
            onClick={() => inputRef.current.click()}
          />
          <Button
            caption={"Export Configuration"}
            disabled={loading}
            hidden={permittedSetDefault}
            onClick={exportConfiguration}
          />
          <Button
            caption={"Import only general objects"}
            disabled={loading}
            hidden={permittedSetDefault}
            onClick={() => inputRefGO.current.click()}
          />
        </div>
      </div>
      <Table
        loading
        headerText={"Modules"}
        headers={["Description"]}
        columns={["description"]}
        order={{ orderBy: "description", dir: "asc" }}
        rowsPerPageOptions={[5, 10, 15]}
        rowsPerPageText={"Rows per page"}
        data={data}
        handleRowClick={(val) =>
          (window.location.href = `/sysscreen?id=${val.id}`)
        }
        idField={"id"}
      />
      {confirmationModal.open && (
        <Modal
          closeModal={() => setConfirmationModal({ open: false })}
          header={
            <div className={classes.header}>
              <span className={classes.headerText}>
                {confirmationModal.header}
              </span>
            </div>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={"Confirm"}
                variant={"contained"}
                onClick={() => {
                  confirmationModal.action();
                  setConfirmationModal({ open: false });
                }}
              />
              <Button
                caption={"Cancel"}
                onClick={() => setConfirmationModal({ open: false })}
                variant={"contained"}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {confirmationModal.text}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
