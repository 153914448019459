export default function parseFormatDate(format) {
    switch (format) {
        case 'D/M/YYYY':
            return 'DD/MM/YYYY';
        case 'M/D/YYYY':
            return 'MM/DD/YYYY';
        case 'YYYY/M/D':
            return 'YYYY/MM/DD';
        default:
            return null;
    }
}

