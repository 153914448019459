import { CircularProgress } from "@material-ui/core";
import React from "react";
import Time from "../../TimeFormat/Time";
import Tooltip from "../../Tooltip/Tooltip";
import TwelveCircle from "./TwelveCircle";
import SpinnerQ from "../../SpinnerQ/SpinnerQ";

function TwelvePositions({
  imgPositionsFiltered,
  loadingImage,
  incubator,
  colorStatus,
  programFiltered,
  position,
  twelvePositions,
  t,
  locale,
  condition,
  handleClick,
  vinculation,
}) {
  const twelvePositionsMapped1 = twelvePositions[0]?.map((p, index) => (
    <TwelveCircle
      handleClick={handleClick}
      type={"r"}
      p={p}
      condition={condition}
      programFiltered={programFiltered}
      t={t}
      locale={locale}
      colorStatus={colorStatus}
      position={position}
      vinculation={vinculation}
    />


  ));


  const twelvePositionsMapped2 = twelvePositions[1]?.map((p, index) => (
    <TwelveCircle
      handleClick={handleClick}
      type={"l"}
      p={p}
      position={position === p.positionNumber}
      condition={condition}
      programFiltered={programFiltered}
      t={t}
      locale={locale}
      colorStatus={colorStatus}
      vinculation={vinculation}
    />
  ));

  return incubator == "IC1020FR" || incubator == "FRLCD" ? (
    loadingImage ? (
      <div className="spinnerContainer">
        <SpinnerQ />
      </div>
    ) : (
      <div
        style={{
          backgroundImage: `url(${imgPositionsFiltered(incubator)})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            height: "100%",
            width: "50%",
            paddingBottom: "15px",
          }}
        >
          {twelvePositionsMapped1}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: "100%",
            width: "50%",
            paddingBottom: "15px",
          }}
        >
          {twelvePositionsMapped2}
        </div>
      </div>
    )
  ) : null;
}

export default TwelvePositions;
