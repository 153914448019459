import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Redirect from "../../../utils/Redirect";

import packageImg from "../../../assets/images/PACKAGE.svg";
import loadImg from "../../../assets/images/LOAD.svg";
import { GrCycle } from "react-icons/gr"; 
import { Tooltip } from "@material-ui/core";
import t from "../../../utils/translation";

const WashingConnector = withStyles({
  alternativeLabel: {
    top: "1.375rem",
    left: "calc(-50% + 1rem)",
    right: "calc(50% + 1rem)",
  },
  active: {
    "& $line": {
      backgroundColor: "#a146f9",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#a146f9",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#cccccc",
    borderRadius: 1,
  },
})(StepConnector);

const DefaultConnector = withStyles({
  alternativeLabel: {
    top: "1.375rem",
    left: "calc(-50% + 1rem)",
    right: "calc(50% + 1rem)",
  },
  active: {
    "& $line": {
      backgroundColor: "#00b189", 
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#00b189", 
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#cccccc",
    borderRadius: 1,
  },
})(StepConnector);


const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#cccccc",
    border: `2px solid #cccccc`,
    zIndex: 1,
    color: "#fff",
    width: "3.125rem",
    height: "3.125rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 10px rgba(0,0,0,.25)",
    transition: "background-color 0.3s, border-color 0.3s",
    cursor: "pointer",
    overflow: "hidden",
  },
  active: (props) => ({
    backgroundColor: props.category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189",
    borderColor: props.category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189",
  }),
  completed: (props) => ({
    backgroundColor: props.category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189",
    borderColor: props.category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189",
  }),
  icon: {
    width: "1.875rem",
    height: "1.875rem",
  },
  cycleIcon: {
    width: "1.875rem",
    height: "1.875rem",
    color: "#fff",
  },
});

function ColorlibStepIcon(props) {
  let category = props.category.toUpperCase()
  const classes = useColorlibStepIconStyles({category});
  const { active, completed, icon, onClick } = props;

  const packagesIcons = {
    Packages: <img src={packageImg} alt="Packages" className={classes.icon} />,
    Loads: <img src={loadImg} alt="Loads" className={classes.icon} />,
    Cycles: <GrCycle className={classes.cycleIcon} />, 
  };
    const washingIcons = {
      Trays: <img src={packageImg} alt="Trays" className={classes.icon} />,
      WasherLoadings: (
        <img src={loadImg} alt="WasherLoadings" className={classes.icon} />
      ),
      WashCycles: <GrCycle className={classes.cycleIcon} />,
    };
  let icons = props.category.toUpperCase() === "WASHING" ? washingIcons : packagesIcons

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
      onClick={onClick}
    >
      {icons[icon]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.string,
  category: PropTypes.string,
  onClick: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  root: {
    width: "80%",
    backgroundColor: "transparent",
  },
  stepper: {
    backgroundColor: "transparent",
    paddingTop: "0",
    paddingBottom: "10",
  },
}));

export default function CustomizedSteppers({ currentStep, category }) {
  const classes = useStyles();

  const stepUrlsSterilization = {
    Packages: "/appmanagepackages",
    Loads: "/appmanageloads",
    Cycles: "/appmanagecycles",
  };



    const stepUrlsWashing = {
      Trays: "/appmanagetrays",
      WasherLoadings: "/appmanagewasherloadings",
      WashCycles: "/appmanagewashcycle",
    };

  const handleStepClick = (step) => {
    const stepUrls = category.toUpperCase() === "WASHING" ? stepUrlsWashing : stepUrlsSterilization

    Redirect({
      redirect: true,
      path: stepUrls[step.replace(" ", "")],
    });
  };

  const getLabel = (word) => {
    const firstWords = ["Trays", "WasherLoadings", "WashCycles"];
    const secondWords = ["Packages", "Loads", "Cycles"];
  
    const firstArray = [t("TraysSpan"), t("WasherLoadingSpan"), t("WashCycleSpan")];
    const secondArray = [t("PackageSpan"), t("LoadSpan"), t("BlankEntry11")];
  
    if (firstWords.includes(word)) {
      const index = firstWords.indexOf(word);
      return firstArray[index];
    } else if (secondWords.includes(word)) {
      const index = secondWords.indexOf(word);
      return secondArray[index];
    } else {
      return null; 
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={getSteps(category).indexOf(currentStep?.replace(' ', ''))}
        connector={
          category.toUpperCase() === "WASHING" ? <WashingConnector /> : <DefaultConnector />
        }
        className={classes.stepper}
      >
        {getSteps(category).map((cs) => (
          <Tooltip
            key={cs}
            title={<div style={{ textAlign: "center" }}>{getLabel(cs)}</div>}
            arrow
          >
            <Step>
              <StepLabel
                StepIconComponent={(props) => (
                  <ColorlibStepIcon
                    {...props}
                    icon={cs}
                    onClick={() => handleStepClick(cs)}
                    category={category.toUpperCase()}
                  />
                )}
              ></StepLabel>
            </Step>
          </Tooltip>
        ))}
      </Stepper>
    </div>
  );
}

CustomizedSteppers.propTypes = {
  currentStep: PropTypes.string.isRequired,
};

function getSteps(category) {
  let steps =
    category.toUpperCase() === "WASHING"
      ? ["Trays", "WasherLoadings", "WashCycles"]
      : ["Packages", "Loads", "Cycles"];
  return steps;
}
