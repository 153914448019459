import React, { useContext, useEffect, useState } from "react";

import styles from "../ConsultPackage/ConsultPackage.module.css";

import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import parseFiltersDate from "../../utils/parseFiltersDate.js";

import { FiPackage } from "react-icons/fi";

import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import BatchRecordDetail from "../../components/PackageDetail/BatchRecordDetail.jsx";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import CircularProgressIndicators from "../../components/CircularProgressIndicators/CircularProgressIndicators.jsx";

export default function BatchRecord() {
  const [cycles, setCycles] = useState([]);
  const [cycleDetail, setCycleDetail] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [loadingCycles, setLoadingCycles] = useState(false);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [name, setName] = useState("");
  const [operator, setOperator] = useState("");
  const [loadNumber, setLoadNumber] = useState("");
  const [sterilizers, setSterilizers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const locale = useContext(LocalizationContext);
  const [totalPages, setTotalPages] = useState(1);

  const getCycles = async () => {
    setLoadingCycles(true);
    try {
      const response = await request().get(
        `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&operator=${operator}&status=4&page=${currentPage}&search=${name}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setCycles(response.data.chargeListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingCycles(false);
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
    setLoadingCycles(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&operator=${operator}&status=4&page=${1}&search=${
            e.target.value
          }&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setCycles(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingCycles(false);
    }
  };
  const handleOperator = (e) => {
    setOperator(e.target.value);
    setLoadingCycles(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&user=${
            e.target.value
          }&status=4&page=${1}&search=${name}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setCycles(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingCycles(false);
    }
  };
  const handleLoadNumber = (e) => {
    setLoadNumber(e.target.value);
    setLoadingCycles(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${
            e.target.value
          }&operator=${operator}&status=4&page=${1}&search=${name}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setCycles(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingCycles(false);
    }
  };

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&operator=${operator}&status=4&page=${1}&search=${name}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setCycles(response.data.chargeListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingCycles(false);
    }
  };

  const handleChangeSterilizer = (e) => {
    setSelectedSterilizer(e.target.value);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&cycleNumber=${loadNumber}&operator=${operator}&status=4&page=${1}&search=${name}&sterilizer=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setCycles(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingCycles(false);
    }
  };
  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/charge`);

      const sterilizers = response.data.sterilizers.map((item) => ({
        value: item.sterilizerId,
        name: item.name,
      }));

      const operators = response.data.operators.map((item) => ({
        value: item.name.replace(/ /g, "+"),
        name: item.name,
      }));
      setSterilizers(sterilizers);
      setOperators(operators);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  //   const actualizedData = useMemo(() => {
  //     let filtered = [];

  //     if (!isNaN(Date.parse(dateFrom)) && !isNaN(Date.parse(dateTo))) {
  //         filtered = filteredData.filter(
  //             (p) =>
  //                 parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) >=
  //                     parseDf(df(dateFrom, "date"), getRegionalFormat().dateFormat) &&
  //                 parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) <=
  //                     parseDf(df(dateTo, "date"), getRegionalFormat().dateFormat)
  //         );
  //     } else {
  //         filtered = filteredData;
  //     }

  //     // Ordenar los datos filtrados por fecha en orden descendente
  //     return filtered.sort((a, b) => {
  //         const dateA = parseDf(df(a.creationDate, "date"), getRegionalFormat().dateFormat);
  //         const dateB = parseDf(df(b.creationDate, "date"), getRegionalFormat().dateFormat);
  //         return dateB - dateA; // Orden descendente (más reciente primero)
  //     });
  // }, [dateTo, dateFrom, filteredData]);

  const {
    currentPage,
    currentItems,
    itemsPerPage,
    // totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    cycles,
    ["cycleNumber", "chargeName", "operator", "sterilizerName", "creationDate"],
    totalPages
  );

  useEffect(() => {
    getParametersLoad();
  }, []);
  useEffect(() => {
    getCycles(itemsPerPage, currentPage);
  }, [itemsPerPage, currentPage]);
  // const getCycles = () => {
  //   request()
  //     .get(`/api/Charge/newCharge`)
  //     .then((response) => {
  //       setCycles(response.data.filter((c) => c.chargeStatus === 4));
  //     });
  // };

  // const getCycles = async () => {
  //   setLoadingCycles(true);
  //   try {
  //     const response = await request().get(`/api/Charge/newCharge`);
  //     setCycles(response.data.filter((c) => c.chargeStatus === 4));
  //   } catch (error) {
  //     console.error("Error fetching cycles:", error);
  //   } finally {
  //     setLoadingCycles(false);
  //   }
  // };

  // const getCycles = async () => {
  //   setLoadingCycles(true);
  //   try {
  //     const response = await request().get(`/api/charge/chargeByCompany`);
  //     console.log(response)
  //     setCycles(response.data.filter((c) => c.chargeStatus === 4));
  //   } catch (error) {
  //     console.error("Error fetching cycles:", error);
  //   } finally {
  //     setLoadingCycles(false);
  //   }
  // };

  // const fetchAdditionalData = (chargeId) => {
  //   console.log(chargeId)
  //   request()
  //     .get(`/api/Charge/chargedto?chargeId=${chargeId}`)
  //     .then((response) => {
  //       setCycleDetail(response.data)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching additional data:', error);
  //     });
  // };

  const handlePackageDetail = (row) => {
    let item = row;
    const chargeId = item.id;
    // setCycleDetail(item);
    // fetchAdditionalData(chargeId);
    setIsDetailVisible(true); // Ocultar la tabla
  };

  // useEffect(() => {
  //   getCycles();
  // }, []);

  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      title={t("BatchRecordSpan")}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handleLoadNumber}
                placeholder={t("NumberSpan")}
                label={t("NumberSpan")}
                value={loadNumber}
                containerWidth="100%"
              />

              <InputQ
                icon={<FiPackage />}
                property={"Name"}
                handleChange={handleName}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
              />

              <SelectQ
                value={operator}
                handleSelect={handleOperator}
                width={"100%"}
                containerWidth={"100%"}
                options={operators}
                placeholder={
                  t("SelectOperatorOption", locale) || "Select a Operator"
                }
              />
              <SelectQ
                value={selectedSterilizer}
                handleSelect={handleChangeSterilizer}
                width={"100%"}
                containerWidth={"100%"}
                options={sterilizers}
                placeholder={
                  t("SelectASterilizerOption", locale) || "Select a Sterilizer"
                }
              />

              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true} progress={true}>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("cycleNumber")}
                >
                  {t("CycleNumberSpan", locale) || "Cycle Number"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeName")}
                >
                  {t("NameSpan", locale) || "Name"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("operator")}
                >
                  {t("OperatorSpan", locale) || "Operator"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("sterilizerName")}
                >
                  {t("SterilizerSpan", locale) || "Sterilizer"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("creationDate")}
                >
                  {t("DateSpan", locale) || "Date"}
                </TableHeaderCellQ>
                {/* <TableHeaderCellQ
                handleClick={() => handleSortByColumn("cicleFinishDate")}
              >
                {t("ResultDate", locale) || "Finish Date"}
              </TableHeaderCellQ> */}
                {/* <TableHeaderCellQ handleClick={() => {}}></TableHeaderCellQ> */}
              </TableHeadQ>
              {loadingCycles ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((row, index) => (
                    <TableRowQ
                      progress={
                        <CircularProgressIndicators
                          totalIndicators={row.totalIndicators}
                          vinculatedIndicators={row.vinculatedIndicators}
                        />
                      }
                      // doubleClick={() => {
                      // setCycleDetail(row);
                      // handlePackageDetail(row)
                      // }}
                      actions={true}
                      detail={row}
                      onClick={() => {
                        setCycleDetail(row);
                        handlePackageDetail(row);
                      }}
                      onDetail={() => {
                        setCycleDetail(row);
                        handlePackageDetail(row);
                      }}
                    >
                      <TableRowCellQ>{row.cycleNumber}</TableRowCellQ>
                      <TableRowCellQ>{row.chargeName}</TableRowCellQ>
                      <TableRowCellQ>{row.operator}</TableRowCellQ>
                      {/* <TableRowCellQ>{row.editUserName !== null && row.editUserName !== "" ? row.editUserName : row.creationUserName }</TableRowCellQ> */}
                      <TableRowCellQ>{row.sterilizerName}</TableRowCellQ>
                      <TableRowCellQ>
                        {df(row.creationDate, "date")}
                      </TableRowCellQ>
                      {/* <TableRowCellQ>
                    {df(row.cicleFinishDate, "date")}
                  </TableRowCellQ> */}
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}
              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <BatchRecordDetail cycleId={cycleDetail.chargeId} locale={locale} />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
