import React, { useContext } from "react";
import { IconButton, Typography, Paper, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

const ConfirmationModal = ({ closeModal, handleConfirm }) => {
  const locale = useContext(LocalizationContext);

  const modalContainer = {
    position: "fixed",
    left: 0,
    width: "100%",
    height: "calc(100% - 50px)",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    zIndex: "1",
  };

  const modal = {
    position: "absolute",
    background: "white",
    width: "400px",
    display: "flex",
    flexDirection: "column",
  };

  const header = {
    display: "flex",
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderBottom: "1px solid #ccc",
    fontWeight: "bold",
    color: "#333",
    textTransform: "uppercase",
  };

  const close = {
    cursor: "pointer",
  };

  const buttonContainer = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  return (
    <div style={modalContainer}>
      <Paper style={modal}>
        <div style={header}>
          <h6>{t("Confirmation") || "Confirmation"}</h6>
          <IconButton style={close} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography style={{ padding: "20px", color: "#333" }}>
          {t("ConfirmThisActionMessage") ||
            "Are you sure you want to perform this action?"}
        </Typography>
        <div style={buttonContainer}>
          <Button
            style={{
              backgroundColor: "#dd4444",
              color: "white",
              margin: "5px",
            }}
            onClick={closeModal}
          >
            {t("CancelButton") || "Cancel"}
          </Button>
          <Button
            style={{
              backgroundColor: "#00b189",
              color: "white",
              margin: "5px",
            }}
            onClick={() => {
              handleConfirm();
              closeModal();
            }}
          >
            {t("ConfirmButton") || "Confirm"}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default ConfirmationModal;
