import React, { useEffect, useState } from "react";

import IndicatorLabel from "../../../components/IndicatorLabel/IndicatorLabel";
import PackageCard from "../../../components/CicleModal/PackageCard";

import indicatorsImg from "../../../assets/images/INDICATORS.svg";
import biologicalImg from "../../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../../assets/images/QUIMICO.svg";
import toStartIcon from "../../../assets/images/Icono To start.svg";
import inProgressIcon from "../../../assets/images/Icono In progress.svg";
import finishedIcon from "../../../assets/images/Icono Finished.svg";
import sterilizerIcon from "../../../assets/images/greenSterilizer.svg";

import PersonIcon from "@material-ui/icons/Person";

import trazantoImg from "../../../assets/images/Miniatura Trazanto Lens.png";

import t from "../../../utils/translation";
import df from "../../../utils/dateFormater";
import minutesToHourFormat from "../../../utils/minutesToHours";
import request from "../../../utils/request";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import styles from "./CycleDetail.module.css";

import { CiEdit, CiTimer, CiUser } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import { Tooltip } from "@material-ui/core";
import { IoReload } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { FaTemperatureHigh } from "react-icons/fa";
import PCDItemCycle from "../../../components/PCDItemCycle/PCDItemCycle";
import ModalLoading from "../../../components/ModalLoading/ModalLoading";

const MySwal = withReactContent(Swal);

const CycleDetail = ({
  loadId,
  locale,
  handleIndicator,
  handleFormData,
  openEdit,
  handleLoadId,
  handleIsEdit,
  reloadDetail,
  handleReload,
}) => {
  const [cycleDetail, setCycleDetail] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [indicators, setIndicators] = useState({
    indicators: [],
    finishedIndicators: [],
    linkedIndicators: [],
    unlinkedIndicators: [],
    linkedIndicatorsCH: [],
    unlinkedIndicatorsCH: [],
  });

  const status = (value) => {
    return value === 2
      ? t("ToStartSpan", locale) || "To Start"
      : value === 3
      ? t("StartedSpan", locale) || "Started"
      : t("FinishedSpan", locale) || "Finished";
  };

  const findImg = (value) => {
    return value === 2
      ? toStartIcon
      : value === 3
      ? inProgressIcon
      : finishedIcon;
  };

  const getResultText = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
    switch (result) {
      case 3:
        return t("UnsafeSpan");
      case 4:
        return t("SafeSpan");
      default:
        return "";
    }
  };

  const getUserResultCH = (result) => {
    switch (result) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const unlinkHandler = (id, isChemical) => {
    const url = isChemical ? "CHE_unlink" : "BI_Unlink";
    request()
      .put(`/api/Charge/${url}?chargeIndicatorId=${id}`)
      .then(() => getLoadId());
  };

  const handleIndicators = (value) => {
    setIndicators(value);
  };

  const unlinkConfirmation = (id, isChemical = false) => {
    MySwal.fire({
      title: "Are you sure you want to continue?",
      text: "Unlink.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "confirmButton",
        cancelButton: "cancelButton",
      },
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
    }).then((result) => {
      if (result.isConfirmed) {
        unlinkHandler(id, isChemical);
      }
    });
  };

  const getLoadId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/Charge/chargedto?chargeId=${loadId}`)
        .then((response) => {
          const data = response.data;
          setCycleDetail(data);
          const indicators = [
            ...(data.indicators || []),
            ...(data.pcdList || []),
          ];
          const finishedIndicators = [
            ...(data.indicators || []),
            ...(data.pcdList || []).flatMap((pcd) => [
              pcd.biologicalIndicator,
              pcd.chemicalIndicator,
            ]),
          ];
          handleIndicators({
            indicators: indicators,
            finishedIndicators: finishedIndicators,
            linkedIndicators: finishedIndicators.filter(
              (i) =>
                i.readingBi != null &&
                ((i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT"))
            ),
            unlinkedIndicators: finishedIndicators.filter(
              (i) =>
                i.readingBi == null &&
                ((i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT"))
            ),
            linkedIndicatorsCH: finishedIndicators.filter(
              (i) =>
                i.readingCheSterilizer != null &&
                !(
                  (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
            unlinkedIndicatorsCH: finishedIndicators.filter(
              (i) =>
                i.readingCheSterilizer == null &&
                !(
                  (i.indicator?.name?.toUpperCase() || "").includes("BT") ||
                  (i.indicatorName?.toUpperCase() || "").includes("BT")
                )
            ),
          });
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const mappedIndicators =
    cycleDetail.chargeStatus === 4
      ? indicators.finishedIndicators
      : indicators.indicators;

  const onEdit = () => {
    handleFormData(cycleDetail);
    openEdit(true);
    handleLoadId(loadId);
    handleIsEdit(true);
  };

  useEffect(() => {
    getLoadId();
  }, []);

  useEffect(() => {
    if (reloadDetail) {
      getLoadId();
      handleReload(false);
    }
  }, [reloadDetail]);

  if (modalLoading) return <ModalLoading open={modalLoading} />;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitleContainer}>
          <p className={styles.title}>
            {" "}
            {`${t("CycleNumberSpan", locale) || "Cycle Number"}: ${
              cycleDetail.cicleNumber ?? ""
            }`}
          </p>

          {cycleDetail.chargeStatus === 4 && (
            <CiEdit
              className={styles.infoHeaderIcon}
              onClick={() => onEdit()}
            />
          )}
        </div>
        <div className={styles.sterilizerContainerInfo}>
          <img
            className={styles.iconSterilizer}
            src={sterilizerIcon}
            alt="sterilizer icon"
          />
          <p className={styles.sectionSubTitle}>
            {" "}
            {cycleDetail.sterilizerName} - {cycleDetail.methodName}
          </p>
        </div>
        <div className={styles.headerDetails}>
          <div>
            <div className={styles.detailItem}>
              <TiDocumentText className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("LoadNumberSpan") || "Load number"}:{" "}
                <span className={styles.info}>{cycleDetail.chargeNumber}</span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <TiDocumentText className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("LoadSpan") || "Load"}:{" "}
                <span className={styles.info}>{cycleDetail.description}</span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <IoReload className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("StartedSpan") || "Started"}:{" "}
                <span className={styles.info}>
                  {df(cycleDetail.cicleStartDate, "dateTime")}
                </span>
                {}
              </p>
            </div>
            <div className={styles.detailItem}>
              <IoReload className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("FinishedSpan") || "Finished"}:{" "}
                <span className={styles.info}>
                  {df(cycleDetail.cicleFinishDate, "dateTime")}
                </span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <PersonIcon className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("OperatorSpan") || "User"}:{" "}
                <span className={styles.info}>{cycleDetail.operator}</span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <TiDocumentText className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("UsedProgramSpan") || "Used Program"}:{" "}
                <span className={styles.info}>{cycleDetail.usedProgram}</span>
              </p>
            </div>
          </div>

          <div>
            <div className={styles.detailItem}>
              <FaTemperatureHigh className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("TemperatureSpan") || "Temperature"}:{" "}
                <span className={styles.info}>
                  {cycleDetail.cicleTemperature &&
                    cycleDetail.cicleTemperature +
                      " " +
                      cycleDetail.temperatureUnit}
                </span>
              </p>
            </div>
            <div className={styles.detailItem}>
              <CiTimer className={styles.iconStyle} />
              <p className={styles.detailText}>
                {t("TimeSpan") || "Time"}:{" "}
                <span className={styles.info}>
                  {cycleDetail.cicleTime &&
                    cycleDetail.cicleTime + " " + cycleDetail.timeUnit}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.observationSection2}>
          <p className={`${styles.observationTitle2}`}>
            {t("ObservationSpan")}
          </p>
          {cycleDetail.cicleObservations && (
            <div className={styles.observationContainer2}>
              <p className={styles.observation2}>
                {cycleDetail.cicleObservations}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.indicatorContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.imageTitleContainer}>
            <img
              src={
                cycleDetail.chargeStatus !== 4 ? indicatorsImg : indicatorsImg
              }
            />
          </div>
          <p className={styles.indicatorsTitle}>
            {cycleDetail.chargeStatus !== 4
              ? t("IndicatorsSpan")
              : t("IndicatorsSpan")}
          </p>
        </div>

        <>
          <div className={styles.indicators}>
            {mappedIndicators
              ?.filter((i) => (cycleDetail.chargeStatus === 4 ? null : true))
              .map((indicator) =>
                indicator.biologicalIndicator ? (
                  <Tooltip
                    key={indicator.pcd.id}
                    title={
                      <div>
                        <div>
                          {t("ProductSpan")}: {indicator.pcd.indicatorName}
                        </div>
                        <div>
                          {t("LotSpan")}: {indicator.pcd.lot}
                        </div>
                        {indicator.pcd.serial && (
                          <div>
                            {t("SerialSpan")}: {indicator.pcd.serial}
                          </div>
                        )}
                        <div>
                          {t("ZoneSpan")}: {indicator.pcd.zone}
                        </div>
                      </div>
                    }
                    arrow
                  >
                    <div
                      className={styles.indicatorItem}
                      style={{ cursor: "auto" }}
                    >
                      <PCDItemCycle
                        detail={true}
                        indicator={indicator}
                        nameModelArea={indicator.pcd.zone}
                      />
                    </div>
                  </Tooltip>
                ) : indicator.pcD_Group ? (
                  <Tooltip
                    key={indicator.id}
                    title={
                      <div>
                        <div>
                          PCD: {`${indicator.pcdName} - ${indicator.pcdLot}`}
                        </div>

                        <div>
                          {t("ProductSpan")}: {indicator.indicator.name}
                        </div>
                        <div>
                          {t("LotSpan")}: {indicator.lot}
                        </div>
                        {indicator.serial && (
                          <div>
                            {t("SerialSpan")}: {indicator.serial}
                          </div>
                        )}
                        <div>
                          {t("ZoneSpan")}:{" "}
                          {indicator.indicatorModelArea.description}
                        </div>
                      </div>
                    }
                    arrow
                  >
                    <div
                      className={styles.indicatorItemGeneral}
                      style={{ cursor: "auto" }}
                    >
                      <IndicatorLabel
                        name={indicator.indicator.name}
                        pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                        lot={indicator.lot}
                        serial={indicator.serial}
                        expirationDate={indicator.indicatorExpirationDate}
                        backgroundColor={"white"}
                        zone={indicator.indicatorModelArea.description}
                        border={true}
                      />
                      {cycleDetail.chargeStatus === 4 && (
                        <img
                          src={trazantoImg}
                          className={styles.trazantoIcon}
                        />
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    key={indicator.id}
                    title={
                      <div>
                        <div>
                          {t("ProductSpan")}: {indicator.indicator.name}
                        </div>
                        <div>
                          {t("LotSpan")}: {indicator.lot}
                        </div>
                        {indicator.serial && (
                          <div>
                            {t("SerialSpan")}: {indicator.serial}
                          </div>
                        )}
                        <div>
                          {t("ZoneSpan")}:{" "}
                          {indicator.indicatorModelArea.description}
                        </div>
                      </div>
                    }
                    arrow
                  >
                    <div
                      className={styles.indicatorItemGeneral}
                      style={{ cursor: "auto" }}
                    >
                      <IndicatorLabel
                        name={indicator.indicator.name}
                        lot={indicator.lot}
                        serial={indicator.serial}
                        expirationDate={indicator.indicatorExpirationDate}
                        backgroundColor={"white"}
                        zone={indicator.indicatorModelArea.description}
                        border={true}
                      />
                      {cycleDetail.chargeStatus === 4 && (
                        <img
                          src={trazantoImg}
                          className={styles.trazantoIcon}
                        />
                      )}
                    </div>
                  </Tooltip>
                )
              )}
          </div>
          {/* //////////////////////////// BIOLOGICOS ////////////////////////////// */}
          {cycleDetail.chargeStatus === 4 && (
            <div className={styles.indicatorSection}>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={biologicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("UnlinkedBiologicalIndicatorSpan")}
                  </p>
                </div>

                <div className={styles.indicatorsContainer}>
                  {indicators.unlinkedIndicators?.map((indicator) =>
                    indicator.pcD_Group ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              PCD:{" "}
                              {`${indicator.pcdName} - ${indicator.pcdLot}`}
                            </div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <div
                          className={styles.indicatorItem}
                          onClick={() => handleIndicator(indicator)}
                        >
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            animation={true}
                          />
                          <GoLink className={styles.link} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <div
                          className={styles.indicatorItem}
                          onClick={() => handleIndicator(indicator)}
                        >
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            animation={true}
                          />
                          <GoLink className={styles.link} />
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>
              </div>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={biologicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("LinkedBiologicalIndicatorSpan")}
                  </p>
                </div>
                <div className={styles.indicatorsContainer}>
                  {indicators.linkedIndicators?.map((indicator) =>
                    indicator.pcD_Group ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              PCD:{" "}
                              {`${indicator.pcdName} - ${indicator.pcdLot}`}
                            </div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingBi && (
                              <div>
                                <div>Incubator:</div>
                                <div>
                                  {t("PositionSpan")}:{" "}
                                  {indicator.readingBi.positionNumber}
                                </div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {indicator.readingBi.statusDescription}
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingBi.readNumber}
                                </div>
                                <div>
                                  {t("ResultSpan")}:
                                  {getResultText(indicator.readingBi.result)}
                                </div>
                                <div>
                                  {t("StartSpan")}:{" "}
                                  {df(
                                    indicator.readingBi.startedTime,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("FinishSpan")}:{" "}
                                  {df(
                                    indicator.readingBi.resultDate,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("IncubationTimeSpan")}:{" "}
                                  {minutesToHourFormat(
                                    indicator.readingBi.incubationTime
                                  )}
                                </div>
                                <div>
                                  {t("ProgramSpan")}:{" "}
                                  {indicator.readingBi.program?.nombre}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultBiologic={indicator.readingBi?.result}
                            detail={true}
                          />
                          <GoUnlink
                            className={styles.unlink}
                            onClick={() => unlinkConfirmation(indicator.id)}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingBi && (
                              <div>
                                <div>Incubator:</div>
                                <div>
                                  {t("PositionSpan")}:{" "}
                                  {indicator.readingBi.positionNumber}
                                </div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {indicator.readingBi.statusDescription}
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingBi.readNumber}
                                </div>
                                <div>
                                  {t("ResultSpan")}:
                                  {getResultText(indicator.readingBi.result)}
                                </div>
                                <div>
                                  {t("StartSpan")}:{" "}
                                  {df(
                                    indicator.readingBi.startedTime,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("FinishSpan")}:{" "}
                                  {df(
                                    indicator.readingBi.resultDate,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("IncubationTimeSpan")}:{" "}
                                  {minutesToHourFormat(
                                    indicator.readingBi.incubationTime
                                  )}
                                </div>
                                <div>
                                  Program: {indicator.readingBi.program?.nombre}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultBiologic={indicator.readingBi?.result}
                            detail={true}
                          />
                          <GoUnlink
                            className={styles.unlink}
                            onClick={() => unlinkConfirmation(indicator.id)}
                          />
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          {/* ///////////////////////////// QUIMICOS /////////////////////////////////////////// */}
          {cycleDetail.chargeStatus === 4 && (
            <div className={styles.indicatorSection}>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={chemicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("UnlinkedChemicalIndicatorSpan")}
                  </p>
                </div>

                <div className={styles.indicatorsContainer}>
                  {indicators.unlinkedIndicatorsCH?.map((indicator) =>
                    indicator.pcD_Group ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              PCD:{" "}
                              {`${indicator.pcdName} - ${indicator.pcdLot}`}
                            </div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <div
                          className={styles.indicatorItem}
                          onClick={() => handleIndicator(indicator, true)}
                        >
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            animation={true}
                          />
                          {/* <GoLink className={styles.link} /> */}
                          <div>
                            <img
                              src={trazantoImg}
                              className={styles.trazantoIcon}
                            />
                            <GoLink className={styles.link} />
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <div
                          className={styles.indicatorItem}
                          onClick={() => handleIndicator(indicator, true)}
                        >
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            animation={true}
                          />
                          {/* <GoLink className={styles.link} /> */}
                          <div>
                            <img
                              src={trazantoImg}
                              className={styles.trazantoIcon}
                            />
                            <GoLink className={styles.link} />
                          </div>
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>
              </div>
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.imageTitleContainer}>
                    <img src={chemicalImg} />
                  </div>
                  <p className={styles.indicatorsTitle}>
                    {t("LinkedChemicalIndicatorSpan")}
                  </p>
                </div>
                <div className={styles.indicatorsContainer}>
                  {indicators.linkedIndicatorsCH?.map((indicator) =>
                    indicator.pcD_Group ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              PCD:{" "}
                              {`${indicator.pcdName} - ${indicator.pcdLot}`}
                            </div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingCheSterilizer && (
                              <div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {
                                    indicator.readingCheSterilizer
                                      .statusDescription
                                  }
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingCheSterilizer.readNumber}
                                </div>
                                <div>
                                  {t("DateSpan")}:{" "}
                                  {df(
                                    indicator.readingCheSterilizer
                                      ?.creationTest,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("BrandSpan")}:{" "}
                                  {indicator.readingCheSterilizer.brand}
                                </div>
                                <div>
                                  {t("ResultSpan")}:{" "}
                                  {getResultCH(
                                    indicator.readingCheSterilizer?.result
                                  )}
                                </div>
                                <div>
                                  {t("UserEvaluationSpan")}:{" "}
                                  {getUserResultCH(
                                    indicator.readingCheSterilizer?.visualResult
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            pcd={`${indicator.pcdName} - ${indicator.pcdLot}`}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultCH={indicator.readingCheSterilizer?.result}
                            userResultCH={
                              indicator.readingCheSterilizer?.visualResult
                            }
                            detail={true}
                          />
                          <div>
                            <img
                              src={trazantoImg}
                              className={styles.trazantoIcon}
                            />
                            <GoUnlink
                              className={styles.unlink}
                              onClick={() =>
                                unlinkConfirmation(indicator.id, true)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                            <div>
                              {t("ProductSpan")}: {indicator.indicator.name}
                            </div>
                            <div>
                              {t("LotSpan")}: {indicator.lot}
                            </div>
                            {indicator.serial && (
                              <div>
                                {t("SerialSpan")}: {indicator.serial}
                              </div>
                            )}
                            <div>
                              {t("ZoneSpan")}:{" "}
                              {indicator.indicatorModelArea.description}
                            </div>
                            {indicator.readingCheSterilizer && (
                              <div>
                                <div>
                                  {t("StatusSpan")}:{" "}
                                  {
                                    indicator.readingCheSterilizer
                                      .statusDescription
                                  }
                                </div>
                                <div>
                                  {t("ReadingNumber")}:{" "}
                                  {indicator.readingCheSterilizer.readNumber}
                                </div>
                                {/* <div>
                                  {t("ResultSpan")}:
                                  {getResultText(
                                    indicator.readingCheSterilizer.result
                                  )}
                                </div> */}
                                <div>
                                  {t("DateSpan")}:{" "}
                                  {df(
                                    indicator.readingCheSterilizer
                                      ?.creationTest,
                                    "dateTime"
                                  )}
                                </div>
                                <div>
                                  {t("BrandSpan")}:{" "}
                                  {indicator.readingCheSterilizer.brand}
                                </div>
                                <div>
                                  {t("ResultSpan")}:{" "}
                                  {getResultCH(
                                    indicator.readingCheSterilizer?.result
                                  )}
                                </div>
                                <div>
                                  {t("UserEvaluation")}:{" "}
                                  {getUserResultCH(
                                    indicator.readingCheSterilizer?.visualResult
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.indicatorExpirationDate}
                            backgroundColor={"white"}
                            zone={indicator.indicatorModelArea.description}
                            border={true}
                            resultCH={indicator.readingCheSterilizer?.result}
                            userResultCH={
                              indicator.readingCheSterilizer?.visualResult
                            }
                            detail={true}
                          />
                          <div>
                            <img
                              src={trazantoImg}
                              className={styles.trazantoIcon}
                            />
                            <GoUnlink
                              className={styles.unlink}
                              onClick={() =>
                                unlinkConfirmation(indicator.id, true)
                              }
                            />
                          </div>
                        </div>
                      </Tooltip>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("PackageSpan")}</p>

          <div className={styles.packagesContainer}>
            {cycleDetail.packages &&
              cycleDetail.packages.map((pkg) => (
                <PackageCard key={pkg.packageId} pkg={pkg} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CycleDetail;
