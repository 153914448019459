import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import styles from "./QuantityElement.module.css";
import t from "../../utils/translation";

const QuantityElement = ({
  quantity,
  reduceQuantity,
  increaseQuantity,
  category,
  handleQuantityChange,
}) => {
  return (
    <div className={styles.quantityContainer}>
      <div className={styles.quantityLabel}>{t("QuantitySpan")}</div>
      <div className={styles.quantityControl}>
        <button
          onClick={reduceQuantity}
          className={`${styles.iconButton} ${
            category == "Washing" ? styles.purple : styles.green
          }`}
        >
          <AiOutlineMinus />
        </button>
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange} 
          className={styles.quantityInput} 
        />
        <button
          onClick={increaseQuantity}
          className={`${styles.iconButton} ${
            category == "Washing" ? styles.purple : styles.green
          }`}
        >
          <AiOutlinePlus />
        </button>
      </div>
    </div>
  );
};

export default QuantityElement