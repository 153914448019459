import { makeStyles, createStyles } from "@material-ui/styles";
import index from "../../assets/Theme/index";

export default makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      top: 0,
      left: "auto",
      right: 0,
      position: "fixed",
      width: "100%",
      display: "flex",
      flexShrink: "0",
      flexDirection: "row",
      zIndex: 1000,
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      fontFamily: "Montserrat",
      marginBottom: "50px",
    },

    navbar: {
      minHeight: 50,
      paddingRight: "16px",
      display: "flex",
      position: "relative",
      alignItems: "center",
      height: "100%",


      [`@media (min-width: ${index.breakpoints["sm"]}px)`]: {
        minHeight: 50,
        paddingRight: "24px",
      },
    },

    hamburgerButtonRoot: {
      fontSize: "1.5rem",
      textAlign: "center",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "flex",
      outline: 0,
      position: "relative",
      alignItems: "center",
      userSelect: "none",
      justifyContent: "center",
      textDecoration: "none",
      backgroundColor: "#f3f3f3",
      width: "75px", 
      height: "10%",


      // "&:hover": {
      //   backgroundColor: "rgba(0, 0, 0, 0.08)",
      // },
    },

    hamburgerButtonContent: {
      color: "#333",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      transition: 'justify-content 0.3s ease', 
    },

    hamburgerButtonContentOpen: {
      display: 'flex',
      color: "#333",
      width: "100%",
      justifyContent: 'flex-start',
      marginLeft: '10px',
      transition: 'justify-content 0.5s ease', 
    },

    hamburgerButtonSvg: {
      fill: "currentColor",
      width: "1em",
      height: "1em",
      display: "inline-block",
      fontSize: "1.5rem",
      flexShrink: 0,
      userSelect: "none",
    },

    user: {
      display: "flex",
      color: "#fff",
      height: "70%", //o 100%
      alignItems: "center",
      // margin: "0 16px",
      // margin: "0 35px", //ultimo
      userSelect: "none",
      cursor: "pointer",
      borderRadius: '150px', // Ajusto el valor según el tamaño del margen
      backgroundColor: '#F8F8F8', 
      padding: '4px', // Espacio interno dentro del margen 
      fontFamily: 'Montserrat',
      fontSize: '0.8rem', // Tamaño de letra más pequeño
      marginTop: 13,
    },

    userName: {
      fontWeight: 700,
      margin: "0 16px",
      color: "#333",
      paddingRight: "15px", //
    },

    userIcon: {
      width: 21,
      height: 21,
      // "& svg": {
      //   fill: theme.palette.terragene.lightMain,
      // },
    },

    userCircle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 21, 
      height: 21,
      borderRadius: '50%',
      backgroundColor:  theme.palette.terragene.lightMain, 
      overflow: 'hidden', 
      margin: '3px'
    },

    content: {
      display: "flex",
      transition: "margin 225ms cubic-bezier(0,0,.2,1) 0ms",
      // filter: "blur(5px)", 
      [`@media (min-width: ${index.breakpoints["md"]}px)`]: {
        marginLeft: 75,
      },
    },


    contentChild: {
      flexGrow: 1,
      margin: "52px 10px 0px 1px",
      overflowX: "clip",
      minHeight: "calc(100vh - 86px)",
      height: "100%",

      [`@media (min-width: ${index.breakpoints["sm"]}px)`]: {
        minHeight: "calc(100vh - 94px)",
      },
    },

    contentWithBlur: {
      filter: "blur(5px)", // Estilo de desenfoque
      pointerEvents: 'none', // Bloquea las interacciones con el contenido
    },

    mainDropdown: {
      width: 200,
      backgroundColor: "#F8F8F8",
      color: theme.palette.terragene.lightThird,
      marginRight: "30px",
      textAlign: "right",
      "&:hover": {
        display: "block",
      },
    },

    btnLogout: {
      // marginTop: 10,
      // backgroundColor: theme.palette.terragene.darkSecondary,
      // fontFamily: "Montserrat",
      // color: theme.palette.terragene.lightThird,
      // borderTop: "2px solid " + theme.palette.terragene.lightMain,
      // borderBottom: "2px solid " + theme.palette.terragene.lightMain,
      // "&:hover": {
      //   backgroundColor: theme.palette.terragene.lightMain,
      // },
      backgroundColor: "#F8F8F8",
      color: "#00B189",
      fontSize: 12,
      padding: 9,
      fontFamily: "Montserrat",
      textTransform: "capitalize",
      cursor: "pointer",
      fontWeight: "bold"
    },

    companyInfo: {
      backgroundColor: "#F8F8F8",
      color: "black",
      padding: 5,
      fontSize: 12,
      fontFamily: "Montserrat",
      marginTop: "20px",
    },

    importantInfo: {
      backgroundColor: "#F8F8F8",
      color: "black",
      fontSize: 12,
      height: "auto",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      overflowWrap: "break-word", /* Alternativa a word-wrap, también permite el ajuste de palabras largas */
      whiteSpace: "normal"
    },

    title: {
      backgroundColor: "#F8F8F8",
      color: "black",
      fontSize: 12,
      // borderBottom: "solid 1px " + theme.palette.terragene.lightThird,
      height: 20,
      fontFamily: "Montserrat",
    },


    rightContainer: {
      display: "flex",
      alignItems: "center",
      marginRight: "35px"
    },

    "@media only screen and (min-width: 1920px)": { //1366 0 1920
    rightContainer :{
      marginRight: "70px", 
    },
    mainDropdown: {
      marginRight: "65px",
    },
    // logoBionovaQ: {
    //   margin: "15px 50px 0px 50px"
    // }
  },

    logoBionovaQ: {
      width: theme.palette.images.widthLogoBionova
        ? theme.palette.images.widthLogoBionova
        : 240,
        paddingLeft: "100px",
        // marginTop: 15, //
        margin: "15px 10px 0px 35px",
    },

    logoTerragene: {
      width: theme.palette.images.widthLogo
        ? theme.palette.images.widthLogo
        : 200,
      marginBottom: 5,
      paddingRight: "60px", //
      marginTop: 15, //
      marginRight: "50px",
      display: "flex",
    },
  })
);
