import React from 'react';
import useStyles from './HeaderStyles';

const Header = ({ headerText, close }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.header}>
                <span className={classes.modalCloseButton} onClick={() => close()}>
                    <svg viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </span>
                <div className={classes.headerText}>
                    {headerText}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Header;