import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    filledSuccess:{
        color: theme.palette.terragene.lightThird,
        backgroundColor: theme.palette.terragene.successAlert,
    },
    filledInfo: {
        color: theme.palette.terragene.lightThird,
        backgroundColor: theme.palette.terragene.infoAlert,
    },
    filledWarning:{
        color: theme.palette.terragene.lightThird,
        backgroundColor: theme.palette.terragene.warningAlert,
    },
    filledError:{
        color: theme.palette.terragene.lightThird,
        backgroundColor: theme.palette.terragene.errorAlert,
    }
}));
