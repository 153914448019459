const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: true
      }
    }
    case "LOADED": {
      return {
        ...state,
        loading: false
      }
    }
    case "DISABLE_SAVEBUTTON": {
      return {
        ...state,
        disableSave: true
      }
    }
    case "ENABLE_DELETEBUTTON": {
      return {
        ...state,
        disableDelete: false
      }
    }
    case "DISABLE_DELETEBUTTON": {
      return {
        ...state,
        disableDelete: true
      }
    }
    case "SET_FILE":
      return {
        ...state,
        file: action.payload.file,
        id: action.payload.id,
        clearFileSelected: action.payload.clearFileSelected,
        name: action.payload.name,
        disableSave: (state.dbFile == action.payload.file),
        disableDelete: (state.dbFile != action.payload.file)
      }
    case "SET_ALERT":
      return {
        ...state,
        alertText: action.payload.alertText,
        type: action.payload?.type,
        clearFileSelected: action.payload.clearFileSelected
      }
    case "CLOSE_ALERT":
      return {
        ...state,
        alertText: ""
      }
    case "SET_DBFILE":
      return {
        ...state,
        dbFile: action.payload.dbFile
      }
    case "CLEAR":
      return {
        ...state,
        dbFile: undefined,
        file: undefined,
        id: undefined,
        popUpDeleteFileVisible: false
      }
    case "CLOSE_MODAL":
      return {
        ...state,
        popUpDeleteFileVisible: false
      }
    case "SHOW_DELETE_MODAL":
      return {
        ...state,
        popUpDeleteFileVisible: true
      }
    default:
      throw new Error();
  }
};

export default reducer;