import React, { useEffect, useMemo, useState } from "react";

import ModalQ from "../TableBionovaQ/ModalQ/ModalQ";
import SelectQ from "../FormComponents/SelectQ/SelectQ";
import InputSearch from "../FormComponents/InputSearch/InputSearch";
import ErrorMessage from "../TableBionovaQ/ErrorMessage/ErrorMessage";
import InputQ from "../FormComponents/InputQ/InputQ";
import Zones from "../../pages/Models/Zones/Zones";

import request from "../../utils/request";

import styles from "./ModelsForm.module.css";

const ModelsForm = ({
  formData,
  handleFormData,
  handleClose,
  open,
  locale,
  t,
  brands,
  machineTypes,
  methods,
  companyModels,
  getCompanyMachineModels,
  handleCreate = ()=>{},
}) => {
  const [error, setError] = useState("");
  const [validate, setValidate] = useState(false);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);

  const getModels = async () => {
    try {
      setLoading(true);

      const response = await request().get("/api/MachineModel");
      const filteredModels = response.data.filter(
        (model) =>
          !companyModels.some(
            (c) => c.name.toUpperCase() === model.name.toUpperCase()
          ) || formData.machineModelId === model.id
      );

      const formattedModels = filteredModels.map((model) => ({
        ...model,
        value: model.id,
      }));

      setModels(formattedModels);
    } catch (error) {
      console.error("Error fetching models:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleZones = (data) => {
    handleFormData((prevData) => ({
      ...prevData,
      modelAreas: data,
    }));
  };

  const handleModelName = (data) => {
    let globalModel = models.find(
      (gb) =>
        gb.name.toUpperCase().replace(/\s+/g, "") ===
        data.toUpperCase().replace(/\s+/g, "")
    );

    if (globalModel) {
      handleFormData((prevData) => ({
        ...prevData,
        name: data,
        hasGlobal: true,
        machineModelId: globalModel.id,
        methodId: globalModel.methodId,
      }));
    } else {
      handleFormData((prevData) => ({
        ...prevData,
        name: data,
        machineModelId: null,
        hasGlobal: false,
      }));
    }
  };

  const handleClick = (option) => {
    handleFormData((prevData) => ({
      ...prevData,
      name: option.name,
      hasGlobal: true,
      machineModelId: option.id,
      methodId: option.methodId,
    }));
  };

  console.log("handleCreate:", handleCreate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.companyMachineMakeId ||
      (!formData.methodId && formData.machineType !== 2) ||
      !formData.name ||
      !formData.machineType ||
      !formData.modelAreas.length
    ) {
      setValidate(true);
    } else setValidate(false);
    setLoading(true);

    try {
      let res;
      if (formData.id) {
        res = await request().put("/api/CompanyMachineModel", formData);
      } else {
        res = await request().post("/api/CompanyMachineModel", formData);
      }
      console.log("res", res.data);
      handleClose();
      setError("");
      setValidate(false);
      console.log("HOLA")
      handleCreate(
        res.data.companyMachineModelId,
        res.data.companyMachineMakeId
      );

      getCompanyMachineModels();
    } catch (err) {
      setError(err.response?.data?.message || "Error al guardar el modelo.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    handleFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onClose = () => {
    handleClose();
    setError("");
    setValidate(false);
  };
  const filteredModels = useMemo(() => {
    let companyMachineMake = brands.find(
      (b) => formData.companyMachineMakeId == b.value
    );
    return models.filter(
      (m) =>
        formData.machineType == m.machineType &&
        companyMachineMake?.machineMakeId == m.machineMakeId
    );
  }, [formData]);

  const filteredMethods = useMemo(() => {
    return methods.filter((m) => formData.machineType == m.machineType);
  }, [formData]);

  useEffect(() => {
    getModels();
  }, []);
  return (
    <ModalQ
      open={open}
      title={
        !formData.id
          ? t("New Model", locale) || "New Model"
          : t("Edit Model", locale) || "Edit Model"
      }
      handleClose={onClose}
      loading={loading}
    >
      {error && <ErrorMessage message={error} />}
      <div className={styles.inputGroup}>
        <SelectQ
          selectValue={formData.machineType}
          handleSelect={handleSelectChange}
          options={machineTypes}
          name={"machineType"}
          label={t("SelectAMachineType", locale) || "Select a Machine Type"}
          placeholder={
            t("SelectAMachineType", locale) || "Select a Machine Type"
          }
          required={true}
          errorMessage={t("RequiredField", locale) || "This field is required"}
          validate={validate}
          disabled={formData.id && formData.machineModelId}
        />
        <SelectQ
          selectValue={formData.companyMachineMakeId}
          handleSelect={handleSelectChange}
          options={brands}
          name={"companyMachineMakeId"}
          label={t("SelectABrand", locale) || "Select a Brand"}
          placeholder={t("SelectABrand", locale) || "Select a Brand"}
          required={true}
          errorMessage={t("RequiredField", locale) || "This field is required"}
          validate={validate}
          disabled={formData.id && formData.machineModelId}
        />
      </div>
      <div className={styles.inputGroup}>
        <div style={{ width: "48%" }}>
          <InputSearch
            label={t("SearchModels", locale) || "Search Models"}
            name="name"
            handleInputChange={handleModelName}
            value={formData.name}
            required={true}
            errorMessage={
              t("RequiredField", locale) || "This field is required"
            }
            validate={validate}
            options={filteredModels}
            handleClick={handleClick}
            disabled={
              !formData.machineType ||
              !formData.companyMachineMakeId ||
              (formData.id && formData.machineModelId)
            }
          />
        </div>
        <SelectQ
          selectValue={formData.methodId}
          handleSelect={handleSelectChange}
          options={filteredMethods}
          name={"methodId"}
          label={t("SelectAMethod", locale) || "Select a Method"}
          placeholder={t("SelectAMethod", locale) || "Select a Method"}
          required={true}
          errorMessage={t("RequiredField", locale) || "This field is required"}
          validate={validate}
          disabled={
            !formData.machineType ||
            (formData.machineModelId &&
              models.find(
                (gb) =>
                  gb.name.toUpperCase().replace(/\s+/g, "") ===
                  formData.name.toUpperCase().replace(/\s+/g, "")
              )) ||
            (formData.id && formData.machineModelId)
          }
        />
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          label={t("Description", locale) || "Description"}
          name="description"
          handleChange={handleInputChange}
          value={formData.description}
        />
        <InputQ
          label={t("Observations", locale) || "Observations"}
          name="observations"
          handleChange={handleInputChange}
          value={formData.observations}
        />
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          label={t("TechnicalService", locale) || "Technical Service"}
          name="technicalService"
          handleChange={handleInputChange}
          value={formData.technicalService}
        />
        <InputQ
          label={t("TechnicalServiceMail", locale) || "Technical Service Mail"}
          name="technicalServiceMail"
          handleChange={handleInputChange}
          value={formData.technicalServiceMail}
        />
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          label={
            t("technicalServicePhone", locale) || "Technical Service Phone"
          }
          name="technicalServicePhone"
          handleChange={handleInputChange}
          value={formData.technicalServicePhone}
        />
      </div>
      {formData.machineType && (
        <div>
          <h6 className={styles.modalTitle}>
            {formData.machineType == 1 ? "Zones" : "Levels"}{" "}
            {validate && !formData.modelAreas.length && (
              <span
                className={styles.modelAreaError}
              >{`(You must add at least one ${
                formData.machineType == 1 ? "Zone" : "Level"
              })`}</span>
            )}
          </h6>
          <Zones
            handleZones={handleZones}
            machineType={formData.machineType}
            modelAreas={formData.modelAreas}
          />
        </div>
      )}
      <button
        className={`${styles.button} ${styles.confirmButton}`}
        onClick={(e) => handleSubmit(e)}
      >
        Confirm
      </button>
    </ModalQ>
  );
};

export default ModelsForm;
