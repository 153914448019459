import React, { useState, useContext, useEffect } from "react";

import request from "../../utils/request.js";

import LocalizationContext from "../../utils/LocalizationContext.js";

import PageContainer from "../../components/PageContainer/PageContainer.jsx";

import Vinculation from "./Vinculation/Vinculation.jsx";

import CycleDetail from "./CycleDetail/CycleDetail.jsx";
import CycleBoard from "./CycleBoard/CycleBoard.jsx";
import df from "../../utils/dateFormater";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import t from "../../utils/translation.js";
import FinishModal from "./FinishModal/FinishModal.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import ChemicalVinculation from "./Vinculation/ChemicalVinculation.jsx";

const ManageCycles = () => {
  const [load, setLoadDetail] = useState(null);
  const [loads, setLoads] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState({});
  const [isChemicalIndicator, setIsChemicalIndicator] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [cycleStartDate, setCycleStartDate] = useState(null);
  const [loadID, setLoadID] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [reloadDetail, setRealoadDetail] = useState(false);
  const [loadingFinishModal, setLoadingFinishModal] = useState(false);
  const [chemicalLoading, setChemicalLoading] = useState(false);

  const locale = useContext(LocalizationContext);

  const handleChangeDate = (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        const today = new Date();
        setDateTo(today);
        getLoads(selectedSterilizer, value, today);
      } else {
        getLoads(selectedSterilizer, value, dateTo);
      }
    } else if (type === "dateTo") {
      setDateTo(value);
      getLoads(selectedSterilizer, dateFrom, value);
    } else if (type === "clear") {
      setDateFrom("");
      setDateTo("");
      getLoads(selectedSterilizer, "", "");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    setFormData({
      cicleNumber: "",
      cicleObservations: "",
      cicleTemperature: "",
      cicleTime: "",
      usedProgram: "",
      bookNumber: "N/A",
      pageNumber: "N/A",
      timeUnit: t("MinuteSpan") || "Minutes",
      cicleStartDate: null,
      securityMode: "",
      temperatureUnit: "",
      img: "",
    });
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleLoad = (chargeId) => {
    setLoadDetail(loads.find((load) => load.chargeId == chargeId));
    setSelectedIndicator({});
  };

  const getLoads = async (
    sterilizer = "",
    from = new Date(),
    to = new Date()
  ) => {
    setModalLoading(true);
    try {
      await request()
        .get(
          `/api/Charge/list?pageSize=1000000&Status=6&sterilizer=${sterilizer}&dateFrom=${parseFiltersDate(
            df(from, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(to, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((response) => {
          setLoads(response.data.chargeListatorList);
          setToStartLoads(
            response.data.chargeListatorList.filter((l) => l.chargeStatus == 2)
          );
          setInProgressLoads(
            response.data.chargeListatorList.filter((l) => l.chargeStatus == 3)
          );
          setFinishedLoads(
            response.data.chargeListatorList.filter((l) => l.chargeStatus == 4)
          );
        });
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDrop = async (value, status, e, finishObject) => {
    e.preventDefault();

    try {
      const { data: infoLoad } = await request().get(
        `/api/Charge/chargedto?chargeId=${value}`
      );

      let newIndicators = infoLoad.indicators.map((i) => ({
        IndicatorId: i.indicatorId,
        quantity: 1,
        serial: i.serial || null,
        lot: i.lot || null,
        modelAreasId: i.modelAreasId,
      }));

      let newPackages = infoLoad.packages.map((p) => ({
        PackageId: p.packageId,
      }));

      const requestData = {
        chargeId: infoLoad.chargeId,
        chargeStatus: status,
      };

      const requestInProgressData = {
        id: infoLoad.chargeId,
        sterilizerId: infoLoad.sterilizerId,
        description: infoLoad.description,
        chargeStatus: 3,
        indicators: newIndicators,
        packages: newPackages,
        ...finishObject,
        cicleFinishDate: null,
      };

      const requestFinishedData = {
        id: infoLoad.chargeId,
        sterilizerId: infoLoad.sterilizerId,
        description: infoLoad.description,
        chargeStatus: 4,
        indicators: newIndicators,
        packages: newPackages,
        ...finishObject,
      };

      await request()
        .put(
          status === 2
            ? "/api/charge/chargestatus"
            : "/api/charge/modifycharge",

          status === 4
            ? requestFinishedData
            : status === 3
            ? requestInProgressData
            : requestData
        )
        .then(() => {
          status === 4 && closeModal();
          getLoads(selectedSterilizer, dateFrom, dateTo);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const onConfirmEdit = (id) => {
    setLoadID(id);
    setRealoadDetail(true);
  };

  const handleSelectedIndicator = (data, isChemical = false) => {
    setSelectedIndicator(data);
    setIsChemicalIndicator(isChemical);
  };

  const closeChemicalVinculation = () => {
    setIsChemicalIndicator(false);
    setSelectedIndicator({});
  };


  const onChemicalVinculation =  async (result, observations, image) => {
    setChemicalLoading(true);
    let requestBody = {
      // El usuario pone 0 (safe) o 1 (unsafe)
      result: result,
      loteProduct: selectedIndicator.lot,
      gtin: "07798164678151",
      productName: selectedIndicator.indicator.name,
      expirationDate: selectedIndicator.indicatorExpirationDate,
      // guid: load.GUID.toUpperCase(),
      // deviceSN: DeviceInfo.getSerialNumberSync(),
      base64Image: image,
      // User observation
      EditUserId: load.EditUserId,
      Cycle: load.cycleNumber,
      DeviceSn: "BionovaQ",
      Guid: "00000000-0000-0000-0000-000000000000",
      VisualResult: 0,
      SterilizerId: load.sterilizerId,
      Observations: observations,
      isFromCharge: true,
      ChargeIndicatorId: selectedIndicator.id,
      chargeId: load.chargeId,
    };
    try {
      await request().post("/api/ReadingCHELens/link", requestBody);
      closeChemicalVinculation()
    } catch (err) {
      console.error(err);
    } finally {
      setChemicalLoading(false);
    }
  };

  useEffect(() => {
    getLoads();
  }, []);

  return (
    <PageContainer
      categoryId={1}
      currentStep={3}
      category={t("SterilizationSpan")}
      subcategory={t("CyclesTitle", locale)}
      title={t("ManageCycle", locale)}
      menu={false}
      backUrl={"/appsterilization"}
      scan={true}
      clickBack={load ? true : null}
      onClickBack={() => {
        setLoadDetail(null);
        setSelectedIndicator({});
      }}
      progress={true}
    >
      {selectedIndicator?.indicatorId && !isChemicalIndicator ? (
        <Vinculation
          selectedIndicator={selectedIndicator}
          load={load}
          handleLoad={handleLoad}
          handleReload={setRealoadDetail}
          handleSelectedIndicator={handleSelectedIndicator}
        />
      ) : (
        <>
          {isModalOpen && (
            <FinishModal
              isModalOpen={isModalOpen}
              handleDrop={handleDrop}
              closeModal={closeModal}
              locale={locale}
              loadId={loadID}
              startDate={cycleStartDate}
              formData={formData}
              handleFormData={handleFormData}
              isEdit={isEdit}
              onConfirmEdit={onConfirmEdit}
              loadingFinishModal={loadingFinishModal}
            />
          )}
          {load && (
            <>
              <CycleDetail
                loadId={load.chargeId}
                locale={locale}
                handleIndicator={handleSelectedIndicator}
                handleFormData={handleFormData}
                handleLoadId={setLoadID}
                openEdit={setIsModalOpen}
                handleIsEdit={setIsEdit}
                reloadDetail={reloadDetail}
                handleReload={setRealoadDetail}
              />
              {isChemicalIndicator && (
                <ChemicalVinculation
                  isChemical={isChemicalIndicator}
                  onClose={closeChemicalVinculation}
                  handleSubmit={onChemicalVinculation}
                />
              )}
            </>
          )}
          {!load && (
            <CycleBoard
              locale={locale}
              handleLoad={handleLoad}
              setLoads={setLoads}
              loads={loads}
              getLoads={getLoads}
              toStartLoads={toStartLoads}
              startedLoads={inProgressLoads}
              finishedLoads={finishedLoads}
              handleChangeDate={handleChangeDate}
              dateTo={dateTo}
              dateFrom={dateFrom}
              modalLoading={modalLoading}
              handleFilterSterilizer={setSelectedSterilizer}
              selectedSterilizer={selectedSterilizer}
              handleStartDate={setCycleStartDate}
              handleLoadId={setLoadID}
              handleDrop={handleDrop}
              handleFormData={handleFormData}
              handleModal={setIsModalOpen}
              handleLoadingFinishModal={setLoadingFinishModal}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ManageCycles;
