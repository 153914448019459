import { getGeneralObjects } from "./sessionHandler";
import _ from "lodash";

export default (objectName, localeContext) => {
  const context = localeContext;
  const locale = context ? context.locale || context : null;
  const generalObjects = getGeneralObjects();

  if (_.get(locale, objectName + ".Translation")) {
    return _.get(locale, objectName + ".Translation");
  } else if (_.get(generalObjects, objectName)) {
    return _.get(generalObjects, objectName);
  } else {
    // console.log(`Object ${objectName} translation was not found in the context`);
    return "";
  }
};
