const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ENABLE_BUTTON":
            return {
                ...state,
                loadingSave: false,
                disableButton: false
            }
        case "SET_DISABLE_BUTTON":
            return {
                loadingSave: true,
                disableButton: true
            }
        default:
            throw new Error();
    }
}
export default reducer;