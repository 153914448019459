import React, { useState } from "react";
import Time from "../../TimeFormat/Time";
import { Tooltip } from "@material-ui/core";
import styles from "./TwoCircle.module.css";

const TwoCircle = ({
  t,
  locale,
  p,
  colorStatus,
  programFiltered,
  handleClick,
  position,
  vinculation,
}) => {
  const [showDate, setShowDate] = useState(false);

  return (
    <Tooltip
      arrow
      key={p.positionNumber}
      title={
        (p.resultDate || p.startedTime) &&
        (p.status === 1 ? (
          <Time
            text={t("WasStartedSpan", locale) || "Was started "}
            time={p.startedTime}
          />
        ) : (
          <Time
            text={t("AvailableSinceSpan", locale) || "Available since "}
            time={p.resultDate}
          />
        ))
      }
    >
      <div
        onClick={() => handleClick(p.positionNumber)}
        key={p.positionNumber}
        style={{
          backgroundColor: colorStatus(p.status),
          top: p.positionNumber === 1 ? "-25px" : "80px",
          right: "-1px",
        }}
        className={`${styles.circle} ${vinculation ? styles.hoverEffect : ""} ${
          p.status === 1 ? styles.yellow : styles.green
        } ${
          p.positionNumber === position
            ? p.status === 1
              ? styles.yellowActive
              : styles.greenActive
            : ""
        }`}
      >
        <p className={styles.positionNumber}>{p.positionNumber}</p>
        {p.status === 1 && (
          <p
            className={styles.statusLabel}
            style={{
              top: p.positionNumber === 1 ? "38px" : "-15px",
              right: "-48px",
            }}
          >
            {programFiltered(p.programId)}
          </p>
        )}
      </div>
    </Tooltip>
  );
};

export default TwoCircle;
