import React, { useContext, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import styles from "./IndicatorItem.module.css";
import PCDItem from "../../PCDItem/PCDItem.jsx";
import IndicatorLabel from "../../IndicatorLabel/IndicatorLabel.jsx";

const IndicatorItem = ({
  indicator,
  modelAreas,
  zone,
  deleteIndicator,
  selectIndicatorArea,
  editZone,
  isPCD,
  category
}) => {
  const [selectedModelArea, setSelectedModelArea] = useState("");
  const [nameModelArea, setNameModelArea] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleArea = (uuid, e) => {
    selectIndicatorArea(uuid, e, isPCD);
  };

  const handleSelectedArea = (id, name) => {
    setSelectedModelArea(id);
    setNameModelArea(name);
    handleArea(indicator.uuid, id);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  };

  // Cierra el menú al hacer clic fuera de él
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    // Limpia el evento cuando el componente se desmonta o el menú se cierra
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownOpen]);


  const dropdownMenu = (
    <div
      className={styles.dropdownMenu}
      ref={dropdownRef}
      style={{
        position: "absolute",
        top: dropdownPosition.top,
        left: dropdownPosition.left,
      }}
    >
      {modelAreas?.map((area) => (
        <div
          key={area.id}
          className={styles.dropdownItem}
          onClick={() => handleSelectedArea(area.id, area.description)}
        >
          {area.description}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.indicatorContainer}>
      <div className={styles.labelContainer}>
        {isPCD ? (
          <PCDItem indicator={indicator} nameModelArea={indicator.modelAreaName} />
        ) : (
          <IndicatorLabel
            name={indicator.indicator.name}
            lot={indicator.lot}
            serial={indicator.serial}
            quantity={indicator.quantity}
            expirationDate={indicator.expirationDate}
            key={indicator.id}
            zone={indicator.modelAreaName}
            border={true}
            category={category}
          />
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <div
          className={`${styles.indicatorIcon} ${styles.delete}`}
          onClick={() => {
            deleteIndicator(indicator.uuid, {
              indicatorId: indicator.indicator_Id,
              modelAreasId: selectedModelArea,
            });
          }}
        >
          <RxCross2 />
        </div>

        {zone && (
          <div className={styles.dropdownContainer}>
            <div
              className={styles.indicatorIcon}
              onClick={toggleDropdown}
              ref={buttonRef}
            >
              <IoIosArrowDown />
            </div>
            {isDropdownOpen &&
              ReactDOM.createPortal(dropdownMenu, document.body)}
          </div>
        )}
      </div>
    </div>
  );
};

export default IndicatorItem;
