import React from 'react';
import styles from './ReportModal.module.css';
import ReportPrinter from '../ReportPrinter/ReportPrinter';

const ReportModal = ({ htmlContent, onClose }) => {
    return (
        <div className={styles.modalOverlay} onClick={onClose}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <div >
            <ReportPrinter
                html_data={htmlContent}
            />
          </div>
          </div>
          <button className={styles.closeButton} onClick={onClose}>
              &times;
          </button>
        </div>
      );
};

export default ReportModal;