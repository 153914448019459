import React, { useState } from "react";
import Time from "../../TimeFormat/Time";
import { Tooltip } from "@material-ui/core";
import styles from "./ThreeCircle.module.css";

const ThreeCircle = ({
  t,
  locale,
  p,
  colorStatus,
  programFiltered,
  handleClick,
  vinculation,
  position,
}) => {
  const [showDate, setShowDate] = useState(false);

  return (
    <Tooltip
      arrow
      key={p.positionNumber}
      title={
        (p.resultDate || p.startedTime) &&
        (p.status === 1 ? (
          <Time
            text={t("WasStartedSpan", locale) || "Was started "}
            time={p.startedTime}
          />
        ) : (
          <Time
            text={t("AvailableSinceSpan", locale) || "Available since "}
            time={p.resultDate}
          />
        ))
      }
    >
      <div
        key={p.positionNumber}
        onClick={() => handleClick(p.positionNumber)}
        style={{
          backgroundColor: colorStatus(p.status),
          top: p.positionNumber === 2 ? "-34px" : "18px",
          left: p.positionNumber === 1 ? "-11px" : "",
          right: p.positionNumber === 3 ? "-11px" : "",
        }}
        className={`${styles.circle} ${vinculation ? styles.hoverEffect : ""} ${
          p.status === 1 ? styles.yellow : styles.green
        } ${
          p.positionNumber === position
            ? p.status === 1
              ? styles.yellowActive
              : styles.greenActive
            : ""
        }`}
      >
        <p className={styles.positionNumber}>{p.positionNumber}</p>
        {p.status === 1 && (
          <p className={styles.statusLabel}>{programFiltered(p.programId)}</p>
        )}
      </div>
    </Tooltip>
  );
};

export default ThreeCircle;
