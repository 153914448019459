import React, { useEffect, useState } from "react";
import styles from "./SelectQ.module.css";

const SelectQ = ({
  label,
  placeholder,
  options,
  handleSelect,
  selectValue,
  width = "100%",
  containerWidth,
  containerMargin,
  required,
  errorMessage,
  validate,
  descriptionField = "toString",
  validationMessage,
  category = "sterilization",
  name,
  disabled
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [value, setValue] = useState("")

  const validation =
    validate && required && (selectValue == "" || selectValue == null);

  useEffect(() => {
    setShowMessage(validation);
  }, [validate, selectValue]);

  useEffect(() => {
    setValue(selectValue)
  }, [selectValue])


  return (
    <>
      <div
        className={styles.selectContainer}
        style={{
          width: containerWidth ? containerWidth : "48%",
          marginBottom: containerMargin,
        }}
      >
        {label && selectValue && (
          <label
            className={`${styles.label} ${category == "washing" ? styles.purple : styles.green} ${
              selectValue ? styles.labelFocused : ""
            }`}
          >
            {label}
            {required && (
              <span className={`${category == "washing" ? styles.purple : styles.green}`}>
                *
              </span>
            )}
            {showMessage && <span className={styles.error}> ({errorMessage})</span>}
          </label>
        )}

        <select
          value={value}
          onChange={(e) => handleSelect(e)}
          className={styles.selectField}
          name={name}
          disabled={disabled}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((item, index) => (
            <option className={styles.option} value={item.value} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {validationMessage && <span className={styles.errorMessage2}>{errorMessage}</span>}
    </>
  );
};

export default SelectQ;