import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import DetailPackage from "../pages/DetailPackage/DetailPackage"

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Company,
    key: "DetailPackage",
  };

  return (
    <LocalizationContext.Provider value={null}>
      {/* <ValidateURL currentPage={currentPage}> */}
      <NavBar MenuOptions={menu} currentPage={currentPage}>
        <DetailPackage />
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};
