import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import TableRowQ from "../TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableContainerQ from "../TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TableBodyQ from "../TableBionovaQ/TableBodyQ/TableBodyQ";

const IndicatorsTable = ({
  handleOpen,
  indicators,
  unlinkHandler,
  getIncubatorName,
  incubator,
  selectedIndicator,
}) => {
  const locale = useContext(LocalizationContext);

  if (!indicators || indicators.length === 0) {
    return null;
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "IN PROGRESS":
        return "#E2A60A";
      case "FINISHED":
        return "#00b189";
      default:
        return "";
    }
  };

  const getResultBackgroundColor = (result) => {
    switch (result) {
      case 1:
        return "#d81111";
      case 2:
        return "#00b189";
      case 3:
        return "#E2A60A";
      default:
        return "";
    }
  };

  const getResultText = (result) => {
    switch (result) {
      case 1:
        return t("Positive", locale) || "Positive";
      case 2:
        return t("Negative", locale) || "Negative";
      case 3:
        return t("Canceled", locale) || "Canceled";
      default:
        return "";
    }
  };

  return (
    <TableContainerQ>
      <TableHeadQ>
        <TableHeaderCellQ>{t("Product", locale) || "Product"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Lot", locale) || "Lot"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Serial", locale) || "Serial"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Zone", locale) || "Zone"}</TableHeaderCellQ>
        <TableHeaderCellQ>
          {t("Incubator", locale) || "Incubator"}
        </TableHeaderCellQ>
        <TableHeaderCellQ>
          {t("Position", locale) || "Position"}
        </TableHeaderCellQ>
        <TableHeaderCellQ>{t("Status", locale) || "Status"}</TableHeaderCellQ>
        <TableHeaderCellQ>
          {t("ReadingNumber", locale) || "Reading No."}
        </TableHeaderCellQ>
        <TableHeaderCellQ>{t("Result", locale) || "Result"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Start", locale) || "Start"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Finish", locale) || "Finish"}</TableHeaderCellQ>
        <TableHeaderCellQ>{t("Unlink", locale) || "Unlink"}</TableHeaderCellQ>
      </TableHeadQ>

      <TableBodyQ>
        {indicators.map((row) => (
          <TableRowQ
            key={row.serial}
            onClick={() =>
              !row.readingBi?.readNumber && incubator && handleOpen(row.id)
            }
          >
            <TableRowCellQ>{row.indicator.name}</TableRowCellQ>
            <TableRowCellQ>{row.lot ? row.lot : "NONE"}</TableRowCellQ>
            <TableRowCellQ>{row.serial ? row.serial : "NONE"}</TableRowCellQ>
            <TableRowCellQ>{row.indicatorModelArea?.description}</TableRowCellQ>
            <TableRowCellQ>
              {row.readingBi?.incubatorId
                ? getIncubatorName(row.readingBi.incubatorId).toString
                : ""}
            </TableRowCellQ>
            <TableRowCellQ>
              {row.readingBi?.positionNumber
                ? row.readingBi?.positionNumber
                : ""}
            </TableRowCellQ>

            <TableRowCellQ>
              <span
                style={{
                  backgroundColor: getStatusBackgroundColor(
                    row.readingBi?.statusDescription
                  ),
                  padding: "4px 8px",
                  borderRadius: "25px",
                  color: "white",
                }}
              >
                {row.readingBi?.statusDescription
                  ? row.readingBi?.statusDescription
                  : ""}
              </span>
            </TableRowCellQ>
            <TableRowCellQ>
              {row.readingBi?.readNumber ? row.readingBi?.readNumber : ""}
            </TableRowCellQ>

            <TableRowCellQ>
              <span
                style={{
                  backgroundColor: getResultBackgroundColor(
                    row.readingBi?.result
                  ),
                  padding: "4px 8px",
                  borderRadius: "25px",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                {getResultText(row.readingBi?.result)}
              </span>
            </TableRowCellQ>

            <TableRowCellQ>
              {row.readingBi?.startedTime
                ? df(row.readingBi?.startedTime, "dateTime")
                : ""}
            </TableRowCellQ>
            <TableRowCellQ>
              {row.readingBi?.resultDate
                ? df(row.readingBi?.resultDate, "dateTime")
                : ""}
            </TableRowCellQ>
            <TableRowCellQ>
              <IconButton
                onClick={(e) => unlinkHandler(row.id, e)}
                disabled={!row.readingBi?.readNumber}
              >
                <CancelIcon
                  color={!row.readingBi?.readNumber ? "disabled" : "error"}
                />
              </IconButton>
            </TableRowCellQ>
          </TableRowQ>
        ))}
      </TableBodyQ>
    </TableContainerQ>
  );
};

export default IndicatorsTable;
