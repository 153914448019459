import React, { useState, useEffect } from "react";
import CRUD from "../../components/CRUD";
import request from "../../utils/request";


export default function GlobalNorms () {
 const [data, setData] = useState([]);
 const [loadingData, setLoadingData] = useState(true);


 useEffect(() => {
    updateData()
  }, [])



 const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/Norm`)
      .then((response) => {
        setData(response.data);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };



    return(
    <React.Fragment>
        <CRUD 
            data={data}
            loadingData={loadingData}
            url={`/api/Norm/`}
            updateData={updateData}
            title={'Norms'}
            columns={['name', 'active']}
            headers={['Name', 'Active']}
            idField={'id'}
            fieldsMap={['name', "active"]}
            formFields={[
                { label: "Name", class: "col-12 col-md-6 px-0", required: true, regex: true },
                { label: "Active", class: "col-12 col-md-6 px-0", type: "checkbox", checkCategory: "primary", required: true }
            ]}
            detailFields={[
                { label: "Name", name: "name" },
                { label: "Active", name: "active" }
            ]}
            baseObject={{
                norm: {
                id:"",
                name: "",
                active: ""
              }
            }}
        />
    </React.Fragment>
    )
    
}