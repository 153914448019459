import React, { useEffect, useCallback } from "react";

import useStyles from "./Select2Styles";
import classnames from "classnames";

import Tooltip from '@material-ui/core/Tooltip';

export default function Select2(props) {
  const classes = useStyles();

  const {
    value,
    label,
    variant = "standard",
    disabled,
    getIndex = false,
    getObject = false,
    nullable = false,
    options,
    uniqueIdentifier,
    onChange,
    msgTooltip,
    focused
  } = props;

  const checkUniqueIdentifier = useCallback(() => {
    for (let i = 0; i < options.length; i++) {
      if (options[i][2] && uniqueIdentifier) {
        if (options[i][2] === uniqueIdentifier.toString()) {
          onChange(i);
        }
      }
    }
  }, [options, uniqueIdentifier, onChange]);

  useEffect(() => {
    checkUniqueIdentifier();
  }, [uniqueIdentifier, checkUniqueIdentifier]);

  useEffect(() => {
    if (options.length === 1 && options[0][0] !== null){
      onChange(options[0][0])
    }
  }, [])

  const adorment = () => {
    if (value !== "" && value !== null && nullable) {
      return (
        <span
          className={classes.adorment}
          style={{ zIndex: 1 }}
          onClick={() => props.onChange("")}
        >
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </span>
      );
    } else {
      return (
        <span className={classes.adorment} style={{ zIndex: 0 }}>
          <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z" />
          </svg>
        </span>
      );
    }
  };
  if (msgTooltip != null) {
    return (
      <Tooltip title={<h6>{msgTooltip}</h6>} placement="up">
        <div
          className={classnames(classes.root, {
            [classes.outlinedRoot]: variant === "outlined",
            [classes.disabled]: disabled,
          })}
        >
          <select
            autoFocus={focused}
            disabled={props.disabled}
            className={classes.selectBaseRoot}
            value={options.length === 1 ? options[0][0] : value}
            onChange={(event) =>
              getIndex
                ? props.onChange(event.target.selectedIndex)
                : getObject
                  ? props.onChange(options[event.target.selectedIndex][2])
                  : props.onChange(event.target.value)
            }
          >
            {props.emptyOptionText && (
              <option value="" disabled hidden>
                {props.emptyOptionText}
              </option>
            )}
            {props.options &&
              props.options.map((option) => {
                return (
                  <option
                    key={option[0]}
                    value={option[0]}
                    hidden={option[0] === null}
                  >
                    {option[1]}
                  </option>
                )
              })}
          </select>
          <label className={classes.selectLabel}>
            {label} {props.isRequired ? "*" : ""}
          </label>
          {adorment()}
          {variant === "outlined" && (
            <fieldset
              className={classnames(classes.outline, {
                [classes.outlinedNoError]: props.noError,
              })}
            />
          )}
          {!props.noError && <p className={classes.errorMessage}>{props.error}</p>}
        </div>
      </Tooltip>
    );
  }
  else {
    return (
      <div
        className={classnames(classes.root, {
          [classes.outlinedRoot]: variant === "outlined",
          [classes.disabled]: disabled,
        })}
      >
        <select
          autoFocus={focused}
          disabled={props.disabled}
          className={classes.selectBaseRoot}
          value={value}
          onChange={(event) => {
            getIndex
              ? props.onChange(event.target.selectedIndex)
              : getObject
                ? props.onChange(options[event.target.selectedIndex][2])
                : props.onChange(event.target.value)
          }
          }
        >
          {props.emptyOptionText && (
            <option value="" disabled hidden>
              {props.emptyOptionText}
            </option>
          )}
          {props.options &&
            props.options.map((option) => {
              return (
                <option
                  key={option[0]}
                  value={option[0]}
                  hidden={option[0] === null}
                >
                  {option[1]}
                </option>
              )
            })}
        </select>
        <label className={classes.selectLabel}>
          {label} {props.isRequired ? "*" : ""}
        </label>
        <span className={classes.linkLabel}>{props.component && props.component}</span>
        {adorment()}
        {variant === "outlined" && (
          <fieldset
            className={classnames(classes.outline, {
              [classes.outlinedNoError]: props.noError,
            })}
          />
        )}
        {!props.noError && <p className={classes.errorMessage}>{props.error}</p>}
      </div>
    );
  }
}
