import { isNull, isUndefined } from 'lodash'
import { getSysUserLanguage } from './sessionHandler'
const languagePermitted = getSysUserLanguage()

export const verifyForm = (languages) => {
    const fieldsForm = []
    languages.forEach((language, index) => {
        if (!isUndefined(languagePermitted) && !isNull(languagePermitted)) {
            if (languagePermitted === language.id)
                fieldsForm.push({ label: language.description, class: "col-12 px-0", required: false })
        } else {
            fieldsForm.push({ label: language.description, class: "col-12 px-0", required: false })
        }
    })
    return fieldsForm
}

export const verifyColumns = (columns, keyEntity, languages) => {
    languages.forEach((language, index) => {
        if (!isUndefined(languagePermitted) && !isNull(languagePermitted)) {
            if (languagePermitted === language.id) {
                columns.push(keyEntity + `Translations.0.translation`)
                columns.push(keyEntity + `Translations.` + index + `.translation`)
            }
        } else {
            columns.push(keyEntity + `Translations.` + index + `.translation`)
        }
    })
    return columns
}

export const verifyHeaders = (headers, languages) => {
    languages.forEach((language) => {
        if (!isUndefined(languagePermitted) && !isNull(languagePermitted)) {
            if (languagePermitted === language.id) {
                headers.push("Base language (English)")
                headers.push(language.description)
            }
        } else {
            headers.push(language.description)
        }
    })
    return headers
};

export const verifyFieldsMap = (keyEntity, languages) => {
    const fieldsMap = []
    languages.forEach((language, index) => {
        if (!isUndefined(languagePermitted) && !isNull(languagePermitted)) {
            if (languagePermitted === language.id) {
                fieldsMap.push(keyEntity + `Translations.` + index + `.translation`)
            }
        } else {
            fieldsMap.push(keyEntity + `Translations.` + index + `.translation`)
        }
    })
    return fieldsMap
}

export const verifyDetailFields = (visibleFields, keyEntity, languages) => {
    languages.forEach((language, index) => {
        // if (languagePermitted) {
        //     if (languagePermitted === language.id) {
        //         visibleFields.push({ label: language.description, name: (keyEntity + `Translations.` + index + `.translation`) })
        //     }
        // } else {
        visibleFields.push({ label: language.description, name: (keyEntity + `Translations.` + index + `.translation`) })
        // }
    })
    return visibleFields
}