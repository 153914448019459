import React, { useState } from "react";
import styles from "./TextAreaQ.module.css";
import t from "../../../utils/translation";

const TextAreaQ = ({
  handleChange,
  value,
  label,
  width = "48%",
  inputRef,
  onHandleScan,
  name,
  required,
  errorMessage,
  validate,
  disabled,
  validationMessage,
  category = "sterilization"
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [labelColor, setLabelColor] = useState("#555");
  const [charCount, setCharCount] = useState(value?.length || 0);
  const maxChars = 300;

  const blurInputs = () => {
    inputRef.current.blur();
  };

  const inputThreshold = 110;

  const handleFocus = () => {
    setIsFocused(true);
    setLabelColor(category === "washing" ? "#a146f9" : "#00b189");
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      setIsFocused(false);
    }
    setLabelColor("#555");
  };

  const handleKeyDown = (e) => {
    if (onHandleScan) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - inputRef.current.lastInputTime;

      if (timeDifference < inputThreshold && e.key === "Enter") {
        blurInputs();
        e.preventDefault();

        const scannedText = inputRef?.current.value;

        for (let i = 0; i < scannedText.length; i++) {
          const substring = scannedText.substring(i);
          onHandleScan(substring);
          let regex = /^01\d{14}17.*$/;
          if (regex.test(substring)) {
            handleChange((prevValue) => prevValue.replace(substring, ""), true);
            setCharCount(value.replace(substring, "").length);
            break;
          }
        }
      }

      inputRef.current.lastInputTime = currentTime;
    }
  };

  const handleTextareaChange = (e) => {
    if (e.target.value.length <= maxChars) {
      handleChange(e);
      setCharCount(e.target.value.length);
    }
  };

  return (
    <div className={styles.inputContainer} style={{ width: width }}>
      {label && (
        <label
          className={`${styles.label} ${
            isFocused || value ? styles.labelFocused : ""
          }`}
          style={{ color: labelColor }}
        >
          {label}
          {required && <span className={styles.required}>*</span>}
          {validate && required && !value && (
            <span className={styles.errorMessage}> ({errorMessage})</span>
          )}
        </label>
      )}
      <textarea
        onChange={handleTextareaChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        value={value}
        className={`${styles.textarea} ${disabled ? styles.disabled : ""}`}
        name={name}
        maxLength={maxChars}
        disabled={disabled}
      />
      <div className={styles.charCount}>
        {charCount}/{maxChars} {t("CharacterSpan")}
      </div>
      {validationMessage && (
        <span className={styles.errorMessage2}>{errorMessage}</span>
      )}
    </div>
  );
};

export default TextAreaQ;
