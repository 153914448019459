import React, { useState, useEffect, useContext } from 'react';
import request from '../../utils/request';
import { getDist } from "../../utils/sessionHandler";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

import Select from "../../components/Select";
import Button from '../../components/Button';	
import Modal from '../../components/Modal';	
import PageContentLoading from '../../components/PageContentLoading';	

import useStyles from './DistParameterStyles';

export default function DistParameter() {
	const classes = useStyles();
	const locale = useContext(LocalizationContext);

	const [loadingContente, setLoadingContente] = useState(true)
	const [loading, setLoading] = useState(false)
	const [fetching, setFetching] = useState('')	
	const [parameters, setParameters] = useState([]);
	const [error, setError] = useState('')
	const [languages, setLanguages] = useState([]);
	const [success, setsuccess] = useState('')
	const [openModal, setOpenModal] = useState(false)

	  useEffect(() => {
		Promise.all([
		  request().get(`/api/language`),
		  request().get("/api/sysparameter/GetByDistributorStr?distributorId=" + getDist().id)
		])
		.then(([languagesData, parametersData]) => {
		  setLanguages(
			languagesData.data.reduce((languagesList, lang) => {
			  languagesList.push([lang.id, lang.description]);
			  return languagesList;
			}, [])
		  );
	
		  setParameters(parametersData.data);
	
		  setLoadingContente(false);
		})
		.catch(error => console.log(error.response))
	  }, []);

	useEffect(() => {
		Promise.all([
		  request().get(`/api/language`)
		])
		.then(([languagesData]) => {
		  setLanguages(
			languagesData.data.reduce((languagesList, lang) => {
			  languagesList.push([lang.id, lang.description]);
			  return languagesList;
			}, [])
		  );
		})
		.catch(error => console.log(error.response))
	  }, []);

	const refreshParameters = () => {				
			request().get("/api/sysparameter/GetByDistributorStr?distributorId=" + getDist().id)
			.then(response => setParameters(response.data))
			.catch(error => console.log(error.response))
			
			setTimeout(() => {
				setsuccess('')
			}, 3000);		
	}

	const handleSave = (parameter) => {
	setLoading(true)
	setFetching('save')
	setError('')
	setsuccess('')

	setTimeout(() => {
		request().put('/api/sysparameter', { ...parameter, value: parameter.value })
		.then(respose => {
			setLoading(false)
			setFetching('')
			setsuccess(`${t("ParameterUpdated", locale)}: ${parameter.sysParameterTypeDescription}.`)
			refreshParameters()
		})
		.catch(error => {
			setLoading(false);
			setFetching('')
			setError(error.response.data.message || error.response.data.title);
		})
	}, 500);
	}
	
	const updateParameterValue = (val, id) => {
		let newParameters = parameters;
		for (let i = 0; i < newParameters.length; i++) {
		  if (newParameters[i].id === id && !isNaN(val)) newParameters[i].value = val;
		}
		setParameters([...newParameters]);
	  };
	
	const handleSetDefaultValue = (parameter) => {
		setOpenModal(false)
		setLoading(true)
		setFetching('reset')
		setError('')
		setsuccess('')
		

		setTimeout(() => {
			request().post('/api/sysparameter/defaultvalue', parameter)
			.then(respose => {
				setLoading(false)
				setFetching('')
				setsuccess(`${t("ParameterUpdated", locale)}: ${parameter.sysParameterTypeDescription}.`)
				refreshParameters()
			})
			.catch(error => {error.respone && 
				setLoading(false)
				setFetching('')
				setError(error.response.data.message || error.response.data.title) 
			})
		}, 500);
	}
	

	const header = (
		<React.Fragment>
		  <div className={classes.header}>
			<span className={classes.modalCloseButton} onClick={()=>setOpenModal(false)}>
			  <svg viewBox="0 0 24 24">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			  </svg>
			</span>
		  </div>
		</React.Fragment>
	);

	const footer = (
		<div className={classes.footerAction}>
		  <Button
			caption={t("ConfirmButton")}
			dense
			onClick={openModal.action}
		  />
		</div>
	);
	
	if(loadingContente) return <PageContentLoading />

	return (
		<>
		<div className={"container-fluid px-0"} >
			{success &&
				<div className={"row mx-0 justify-content-md-center"}>
          		  <div className={"col-12 col-md-8"}>
						<div className={"alert alert-success"}>
  							{success}
						</div>
          		  </div>
          		</div>
			}
			
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-10"}>
				{parameters.map(parameter => {
					if (parameter.fieldType === 7) {
						return (
						<>
						<div className={classes.parameterRoot}>
							<Select
								value={parameter.value}
								label={parameter.sysParameterTypeDescription ===
									"Language"
										? t("LanguageLabel", locale)
										: parameter.sysParameterTypeDescription
										}
								variant={"outlined"}
								error={error}
								onChange={val => updateParameterValue(val, parameter.id)}
								disabled={loading}
								noError={error.length === 0}
								options={languages}
							/>
							<div className={classes.parameterActionsRoot}>
								<Button 
									caption={t("SaveButton", locale)}
									loading={fetching === 'save'}
									loadingText={t("SavingText", locale)}
									onClick={() => handleSave(parameter)}
									disabled={loading}
								/>
								<Button 
									caption={t("ResetValueButton", locale)}
									loading={fetching === 'reset'}
									loadingText={t("ResettingValueText", locale)}
									onClick={() => setOpenModal({
										open: true,
										header: parameter.appParameterTypeDescription,
										body: t("ResetDefaultValueConfirmation", locale),
										action: () => handleSetDefaultValue(parameter)
									})
									}
									disabled={loading}
								/>
							</div>
						</div>

						{openModal &&
							<Modal header={header} footer={footer} closeModal={()=>setOpenModal(false)} >
								<div className={'container-fluid'}>
									<div className={'row'}>
										<div className={'col-12'} style={{textAlign:'center'}}>
										{t("ResetDefaultValueConfirmation", locale)} <b>{parameter.sysParameterTypeDescription}</b>?
										</div>
									</div>
								</div>
							</Modal>
						}
						</>
						);
					}
					
				}
				)}
            </div>
          </div>
		</div>
		</>
	)
} 