import React from "react";
import styles from "./MenuItemQ.module.css";
import { IoIosAddCircle } from "react-icons/io";
import Redirect from "../../../utils/Redirect";

export default function MenuItemQ({
  title,
  children,
  add = true,
  url,
  addUrl,
  category = "sterilization",
}) {
  const addFunction = (e) => {
    e.stopPropagation();

    Redirect({
      redirect: true,
      path: addUrl,
      // parameters: { moduleId: moduleName, screenId: screenName },
    });
  };
  return (
    <div
      className={styles.container}
      onClick={() =>
        Redirect({
          redirect: true,
          path: url,
          // parameters: { moduleId: moduleName, screenId: screenName },
        })
      }
    >
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <div className={styles.info}>{children}</div>
      </div>
      
        <IoIosAddCircle
        style={{visibility: !add && "hidden"}}
          className={`${styles.button} ${
            category == "sterilization"
              ? styles.buttonGreen
              : category == "washing"
              ? styles.buttonPurple
              : ""
          }`}
          onClick={(e) => addFunction(e)}
        />
      
    </div>
  );
}
