import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Select2 from "../../components/Select2";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function IncubatorDistributor() {
  const query = useQuery();

  const [loadingContent, setLoadingContent] = useState(true); 
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [incubatorModels, setincubatorModels] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState(
    query.get("distributorId") || ""
  );  

  useEffect(() => {
    request()
      .get(`/api/distributor`)
      .then((response) => {
        setTimeout(() => {
          setDistributors(
            response.data.reduce((distributorOptions, distributor) => {
              distributorOptions.push([distributor.id, distributor.name]);
              return distributorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, []);
  
  useEffect(() => {
    request()
      .get("/api/Incubatormodel")
      .then((types) => {
        setincubatorModels(
          types.data.reduce((typesList, type) => {
            typesList.push([type.id, type.incubatorModelDescription.toUpperCase()]);
            return typesList;
          }, [ [null, 'Choose a Incubator'] ])
        );

        setLoadingContent(false);
      })
      .catch(error => console.log(error.response));
  }, []);

  incubatorModels.sort(function (a, b) {
    if (a[0] == null || b[0] == null){
      return 0;    
    }

    if (a[1] > b[1]) {
      return 1;
    }
    if (a[1] < b[1]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  useEffect(() => {
    if (selectedDistributor) {
      setLoadingData(true);
      request()
        .get(`/api/incubatordistributor/GetByDistributor?distributorId=${selectedDistributor}`)
        .then(response => {
          setData(
            response.data.reduce((readingsList, reading) => {
              let incubatorModelNameFromArr = "";
              
              for (let i = 0; i < incubatorModels.length; i++) {
                if (incubatorModels[i][0] === reading.incubatorModelId) {
                  incubatorModelNameFromArr = incubatorModels[i][1];
                }
              }

              readingsList.push({
                ...reading,
                incubatorModelName: incubatorModelNameFromArr,
              });
              return readingsList;
            }, [])
            .map(x =>
              _.mapKeys(x, (value, key) => {
                return "incubatordistributor." + key;
              })
            )
          );
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        }).catch((error) => console.log(error.response));
      }
    }, [loadingContent, incubatorModels, selectedDistributor]);

  const updateData = () => {
    if (selectedDistributor) { 
    setLoadingData(true);
    request()
      .get(`/api/incubatordistributor/GetByDistributor?distributorId=${selectedDistributor}`)
      .then(response => {
        setData(
          response.data.reduce((readingsList, reading) => {
            let incubatorModelNameFromArr = "";
            
            for (let i = 0; i < incubatorModels.length; i++) {
              if (incubatorModels[i][0] === reading.incubatorModelId) {
                incubatorModelNameFromArr = incubatorModels[i][1];
              }
            }

            readingsList.push({
              ...reading,
              incubatorModelName: incubatorModelNameFromArr,
            });
            return readingsList;
          }, [])
          .map(x =>
            _.mapKeys(x, (value, key) => {
              return "incubatordistributor." + key;
            })
          )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      });
    }
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div className={"col-12 col-md-5 px-0"}>
            <Select2
              label={"Distributor"}
              options={distributors}
              emptyOptionText={"Choose A Distribuidor"}
              variant={"outlined"}
              value={selectedDistributor}
              onChange={(val) => setSelectedDistributor(val)}
            />
          </div>
        </div>
      </div>
      {selectedDistributor && (
      <CRUD
        data={data}
        url={"/api/incubatordistributor"}
        title={"Incubator Name"}
        updateData={updateData}
        loadingData={loadingData}
        columns={[
          "incubatordistributor.incubatorModelName",
          "incubatordistributor.description"
        ]}
        headers={["Incubator Model", "Description"]}
        idField={"incubatordistributor.id"}
        fieldsMap={[
          "incubatordistributor.distributorId", 
          "incubatordistributor.incubatorModelId",
          "incubatordistributor.description"
        ]}
        formFields={[
          {
            label: "Distributor",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: distributors,
            defaultValue: selectedDistributor,
            updateOnly: true,
            required: true,
          },
          {
            label: "Incubator Model",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: incubatorModels,
            required: true
          },
          { label: "Description", class: "col-12 col-md-6 px-0", required: true }
        ]}
        detailFields={[
          { label: "Incubator Model", name: "incubatordistributor.incubatorModelName" },
          { label: "Description", name: "incubatordistributor.description" }
        ]}
        baseObject={{
          incubatordistributor: {
            id: null,
            distributorId: selectedDistributor,
            incubatorModelId: "",
            description: ""
          }
        }}
      />
      )}
    </div>
  );
}
