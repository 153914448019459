import React from "react";

import SterilizationDetailTable from "../SterilizationDetailTable/SterilizationDetailTable";
import SterilizationTable from "../SterilizationTable/SterilizationTable";

const SterilizationTableAndDetail = ({
  data,
  dataDetail,
  handleDataDetail,
  handleData,
  selectedData,
  ticket={ticket},
  charge,
  methods
}) => {
  return (
    <div
      style={{
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        minHeight: "350px",
      }}
    >
      <SterilizationTable
        handleData={handleData}
        data={data}
        handleDataDetail={handleDataDetail}
        ticket={ticket}
        selectedData={selectedData}
        charge={charge}
        methods={methods}
      />
      <SterilizationDetailTable dataDetail={dataDetail} charge={charge} methods={methods} />
    </div>
  );
};

export default SterilizationTableAndDetail;
