import React, { useState } from "react";
import styles from "./Calendar.module.css";
import {
  MdArrowBack,
  MdArrowForward,
  MdKeyboardArrowDown,
} from "react-icons/md";

const Calendar = ({ onSelectDate, actualDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const handleDateClick = (day) => {
    const selected = new Date(currentYear, currentMonth, day);
    setSelectedDate(selected);
    onSelectDate(selected);
  };

  const renderDays = () => {
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className={styles.emptyDay}></div>);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected =
        actualDate &&
        actualDate.getDate() === day &&
        actualDate.getMonth() === currentMonth &&
        actualDate.getFullYear() === currentYear;

      days.push(
        <div
          key={day}
          className={`${styles.day} ${isSelected ? styles.selectedDay : ""}`}
          onClick={() => handleDateClick(day)}
        >
          {day}
        </div>
      );
    }
    return days;
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value, 10);
    setCurrentDate(new Date(currentYear, newMonth, 1));
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setCurrentDate(new Date(newYear, currentMonth, 1));
  };

  const renderMonthOptions = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month, index) => (
      <option key={month} value={index}>
        {month.charAt(0).toUpperCase() + month.slice(1)}
      </option>
    ));
  };

  const renderYearOptions = () => {
    const years = [];
    for (let i = currentYear - 50; i <= currentYear + 50; i++) {
      years.push(i);
    }
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <span
          className={styles.navButton}
          onClick={() =>
            setCurrentDate(new Date(currentYear, currentMonth - 1, 1))
          }
        >
          <MdArrowBack size={20} />
        </span>
        <div className={styles.selectContainer}>
          <div className={styles.selectWrapper}>
            <select
              value={currentMonth}
              onChange={handleMonthChange}
              className={styles.monthSelect}
            >
              {renderMonthOptions()}
            </select>
            <MdKeyboardArrowDown size={20} className={styles.selectIcon} />
          </div>
          <div className={styles.selectWrapper}>
            <select
              value={currentYear}
              onChange={handleYearChange}
              className={styles.yearSelect}
            >
              {renderYearOptions()}
            </select>
            <MdKeyboardArrowDown size={20} className={styles.selectIcon} />
          </div>
        </div>
        <span
          className={styles.navButton}
          onClick={() =>
            setCurrentDate(new Date(currentYear, currentMonth + 1, 1))
          }
        >
          <MdArrowForward size={20} />
        </span>
      </div>
      <div className={styles.body}>
        {renderWeekDays()}
        {renderDays()}
      </div>
    </div>
  );
};

const renderWeekDays = () => {
  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  return weekDays.map((day) => (
    <div key={day} className={styles.weekDay}>
      {day}
    </div>
  ));
};

export default Calendar;
