import React, { useState, useContext } from "react";
import request from '../../../utils/request';
import LocalizationContext from "../../../utils/LocalizationContext";
import { useTheme } from "@material-ui/core";
import Progress from "../../Progress";
import Button from "../../Button";
import { getDist } from "../../../utils/sessionHandler";
import LoginInput from "../../LoginInput";
import Button2 from "../../Button2";
import ButtonQ from "../../ButtonQ";
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";
import isoLogoQ from "../../../assets/images/ISOLOGO-BIONOVAQ.svg"

export default function ForgotPassword(props) {
  const { classes, dispatch, state, images } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ type: "", message: "" });
  const [success, setSuccess] = useState(false);

  const locale = useContext(LocalizationContext);

  const theme = useTheme();

  // Función para manejar cambios en el email
  const handleChangeUserName = (value) => {
    dispatch({ type: "setEmail", payload: value.target.value }); // Actualiza el estado del email en el componente padre
    error.type === "userName" && setError({ type: "", message: "" }); 
  };

  const checkRequiredFields = () => {
    if (state.email.length === 0) {
      setError({ type: "userName", message: "This field is required" });
      return false;
    }
    return true;
  };

  const forgotPasswordSubmit = e => {
    document.activeElement.blur()
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    const user = { Email: state.email, DistId: getDist().id };
    request()
      .post(`/api/login/forgotPassword`, user)
      .then(response => {
        setLoading(false);
        setError({ type: "success", message: response.data });
        setSuccess(true)
      })
      .catch(error => {
        if (!error.response) { return };
        setLoading(false);
        setError({ type: "error", message: error.response.data.message });
      });
  };

  return (
    <form
      className={classes.loginForm}
      onSubmit={e =>
        error.type === "success"
          ? dispatch({ type: "loginClick" })
          : forgotPasswordSubmit(e)
      }
    >
      <Progress class={classes.loading} active={loading} />
      {/* <img src={theme.palette.images.logoBionovaQ} className={classes.logoBionova} style={{width: theme.palette.images.widthlogoBionovaLogin ? theme.palette.images.widthlogoBionovaLogin : null}}></img>
      <div>
      </div>
      <h1 className={classes.loginText}>{locale.header.forgotPassword}</h1> */}
      <div className={classes.containerHeader} style={{marginTop: "50px"}}>
                 <div >
              <img src={isoLogoQ} className={classes.smallIsoLogo} alt='title'/>
              </div>
              <div className={classes.container}>
                <p className={classes.title}>
                {isoLogoQ && `| ${locale.header.forgotPassword}`}
              </p>
              </div>
            </div>
      {(error.type === "error" || error.type === "success") && (
        <div
          className={`alert ${error.type === "error" ? "alert-danger" : "alert-primary"
            } `}
          role="alert"
        >
          {error.message}
        </div>
      )}
      { !success && (
        // <LoginInput
        //   claro={true}
        //   label={locale.form.input.username}
        //   value={state.email}
        //   onChange={value => {
        //     dispatch({ type: 'setEmail', payload: value })
        //     error.type === "userName" && setError({ type: "", message: "" });
        //   }}
        //   type={"text"}
        //   error={error.type === "userName" ? error.message : ""}
        //   disabled={loading || success}
        // />
        <InputQ
        label={locale.form.input.email}
        containerWidth={"160%"}
        disabled={loading || success}
        value={state.email}
        type={"text"}
        handleChange={handleChangeUserName}
        required={true} 
        // validate={validate}  
        errorMessage={error.type === "userName" ? error.message : ""}  //Mensaje de error a mostrar
        />
      )}
      <ButtonQ
        type={"submit"}
        caption={
          error.type === "success"
            ? locale.form.submit.login
            : locale.form.submit.resetPassword
        }
        disabled={loading}
        isVerticalMargined={true}
      />
      {!success &&
         <p className={classes.backToLoginLink} style= {{marginLeft: "0px", marginRight: "0px"}}>
         Go back to
    <span className={classes.backTologinLink2} onClick={() => window.location.replace("/login")}>
         Log in
   </span>
     </p>
      }
        <img
          src={images.logo}
          alt=""
          className={classes.logoTerragene2}
        ></img>
    </form>
    
  );
}
