import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import Company from "../pages/DistCompany";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.CompanyDist,
    key: "Company",
  };

  const locale = useLocale(1200);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          MenuOptions={menu}
          currentPage={currentPage}
        >
          {locale ? <Company /> : <PageContentLoading />}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>    
  );
};
