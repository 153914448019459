import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import CRUD from "../../components/CRUD";
export default function SysLog() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/syslog`)
      .then((response) => {
        setData(
          response.data.map((l) => {
            return { ...l, logDate: df(l.logDate, "dateTime") };
          })
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })

      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      detailOption={false}
      newOption={false}
      editOption={false}
      deleteOption={false}
      data={data}
      order={{ dir: "desc", orderBy: "logDate" }}
      url={"/api/syslog"}
      title={"Log"}
      updateData={updateData}
      loadingData={loadingData}
      columns={["logDate", "logDetail"]}
      headers={["Date", "Detail"]}
      idField={"id"}
    />
  );
}
