const parseDf = (dateString, format) => {
  let dateParts;
  let date;

  switch (format) {
    case "D/M/YYYY":
      dateParts = dateString.split("/");
      // Invertimos para obtener [YYYY, MM, DD]
      date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      break;
    case "YYYY/MM/DD":
      dateParts = dateString.split("/");
      date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      break;
    case "M/D/YYYY":
      dateParts = dateString.split("/");
      // Invertimos para obtener [YYYY, MM, DD]
      date = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      break;
    default:
      throw null;
  }

  // Ajustar la hora a medianoche
  date.setHours(0, 0, 0, 0);
  return date;
};

export default parseDf;
