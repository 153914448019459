import React, { useState } from "react";
import request from "../../utils/request";
import { getSession } from "../../utils/sessionHandler";
import _ from "lodash";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

export default function IncubatorModel() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [connectionTypes, setConnectionTypes] = useState();

  React.useEffect(() => {
    Promise.all([request().get("/api/IncubatorModel/getTypes"), updateData()])
      .then(([types]) => {
        setConnectionTypes(
          types.data.reduce(
            (cTypes, type) => {
              cTypes.push([type.id, type.description]);
              return cTypes;
            },
            [[null, "Incubator Type"]]
          )
        );

        setLoadingContent(false);
      })
      .catch((error) => console.log(error.response));
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get("/api/incubatormodel")
      .then((response) => {
        setData(
          response.data.map((x) =>
            _.mapKeys(x, (value, key) => {
              return "incubatormodel." + key;
            })
          )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <CRUD
      data={data}
      url={"/api/incubatormodel"}
      title={"Incubator Model"}
      updateData={updateData}
      loadingData={loadingData}
      columns={[
        "incubatormodel.modelId",
        "incubatormodel.description",
        //"incubatormodel.uuid",
        "incubatormodel.typeDescription",
        "incubatormodel.portBI",
        "incubatormodel.portProtein",
      ]}
      headers={[
        "Model Id",
        "Description",
        //"UUID",
        "Type",
        "BI Port",
        "Protein Port",
      ]}
      idField={"incubatormodel.id"}
      fieldsMap={[
        "incubatormodel.modelId",
        "incubatormodel.description",
        "incubatormodel.type",
        "incubatormodel.uuid",
        "incubatormodel.vid",
        "incubatormodel.pid",
        "incubatormodel.portBI",
        "incubatormodel.portProtein",
      ]}
      formFields={[
        { label: "Model Id", class: "col-12 col-md-6 px-0", required: true },
        { label: "Description", class: "col-12 col-md-6 px-0", required: true },
        {
          label: "Connection Type",
          class: "col-12 col-md-6 px-0",
          type: "select",
          options: connectionTypes,
          required: true,
        },
        {
          label: "UUID",
          class: "col-12 col-md-6 px-0",
          required: false,
          visibleCondition: ["incubatormodel.type", "=", "2"],
        },
        {
          label: "VID",
          class: "col-12 col-md-6 px-0",
          required: false,
          visibleCondition: ["incubatormodel.type", "=", "1"],
        },
        {
          label: "PID",
          class: "col-12 col-md-6 px-0",
          required: false,
          visibleCondition: ["incubatormodel.type", "=", "1"],
        },
        { label: "BI Port", class: "col-12 col-md-6 px-0", required: true },
        {
          label: "Protein Port",
          class: "col-12 col-md-6 px-0",
          required: true,
        },
      ]}
      detailFields={[
        { label: "Model Id", name: "incubatormodel.modelId" },
        { label: "Description", name: "incubatormodel.description" },
        { label: "Connection Type", name: "incubatormodel.typeDescription" },
        {
          label: "UUID",
          name: "incubatormodel.uuid",
          visibleCondition: ["incubatormodel.type", "=", "2"],
        },
        {
          label: "VID",
          name: "incubatormodel.vid",
          visibleCondition: ["incubatormodel.type", "=", "1"],
        },
        {
          label: "PID",
          name: "incubatormodel.pid",
          visibleCondition: ["incubatormodel.type", "=", "1"],
        },
        { label: "BI Port", name: "incubatormodel.portBI" },
        { label: "Protein Port", name: "incubatormodel.portProtein" },
      ]}
      baseObject={{
        incubatormodel: {
          id: null,
          modelId: "",
          description: "",
          uuid: "",
          type: null,
          portBI: "",
          portProtein: "",
        },
        UserLogged: getSession(),
      }}
    />
  );
}
