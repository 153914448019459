import React, { useState, useContext, Children } from "react";
import ReactTooltip from 'react-tooltip'
import classNames from "classnames";

import useStyles from "./MenuStyles";
import { CompanyContext } from "../../../utils/context/CompanyContext";

import MenuItem from "./MenuItem";
import NavBar from "../NavBar";

export default function Menu(props) {
  const classes = useStyles();
  const [expandedOption, setExpandedOption] = useState(null);
  const companyState = useContext(CompanyContext);


  return (
    <div
      className={classNames(
        classes.root,
        { [classes.open]: props.open }
      )}
    >
      <ReactTooltip effect="solid" />
      <div className={classes.backdrop} /> 
      <div className={classes.menuDrawer} >
      {props.hamburgerIcon}
        {/* <div className={classes.menuHeader}>
          <div
            className={classes.menuCloseButton}
            onClick={() => props.setOpen(false)}
          >
            <span>
              <svg viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </span>
          </div>
        </div> */}
        <div style={{ overflow: "auto"}}>
          {props.MenuOptions &&
            Object.keys(props.MenuOptions)
              //.filter(x => props.MenuOptions[x].MenuType == 0 || props.MenuOptions[x].MenuType == props.menuType)
              .filter(x => props.userType === 1 || props.userType === 3 || Object.keys(props.menuOptions).some(m => m === x))
              .map(x =>
                <MenuItem key={x}
                  item={props.MenuOptions[x]}
                  open={props.open}
                  expandedOption={expandedOption}
                  setExpandedOption={setExpandedOption}
                  activeMenuOption={props.activeMenuOption}
                  menuOption={props.menuOptions}
                  group={x}
                  appUser={props.userType !== 1 && props.userType !== 3}
                />
              )
          }
        </div>
        {/* {companyState.company && companyState.company.image &&
            <img
            src={companyState.company.image}
            alt=""
            width={340}
            height={65}
        />} */}
      </div>
    </div>
  );
}

Menu.propTypes = {};
