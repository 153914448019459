import React, { useState } from "react";
import t from "../../utils/translation";
import imgEdit from "../../assets/images/EditItemTable.svg";
import imgTicket from "../../assets/images/Ticket.svg";
import imgDelete from "../../assets/images/DeleteItemTable.svg";
import Modal from "../Modal";
import Button from "../Button";
import { TableCell, TableRow } from "@material-ui/core";
import request from "../../utils/request";
import { useContext } from "react";
import LocalizationContext from "../../utils/LocalizationContext";

const SterilizationItemTable = ({
  handleDataDetail,
  item,
  onEdit,
  index,
  classes,
  data,
  charge,
  ticket,
  handleData,
  selectedData,
  methodName,
}) => {
  const [confirm, setConfirm] = useState(false);

  const locale = useContext(LocalizationContext);

  const handleDelete = () => {
    setConfirm(!confirm);
  };

  const onDelete = (id) => {
    request()
      .delete(
        charge ? `/api/charge` : `/api/package`,
        data.find((data) => data.id === id)
      )
      .then(() => {
        let newData = data.filter((data) => data.id !== id);
        handleDelete();
        handleData(newData);
        handleDataDetail(null);
      });
  };

  return (
    <TableRow
      onClick={() => {
        handleDataDetail(item.id);
      }}
      onDoubleClick={() => onEdit(item.id)}
      style={{ cursor: "pointer", padding: "5px", height: "auto" }}
    >
      <TableCell
        className={selectedData === item.id ? classes.selectedRow : ""}
      >
        {`${charge ? t("Load", locale) || "LOAD" : t("Pkg", locale) || "PKG"}-${
          item.id
        } ${item.description}`}
      </TableCell>
      <TableCell
        className={selectedData === item.id ? classes.selectedRow : ""}
      >
        {methodName}
      </TableCell>
      <TableCell style={{ padding: "5px", height: "auto" }}>
        <div
          style={{
            display: "flex",
            listStyle: "none",
            width: "18%",
            padding: 0,
          }}
        >
          {ticket ? (
            <img
              src={imgTicket}
              alt="Ticket"
              style={{
                margin: 0,
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => onEdit(item.id)}
            />
          ) : (
            <img
              src={imgEdit}
              alt="Edit"
              style={{
                margin: 0,
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => onEdit(item.id)}
            />
          )}
          <img
            src={imgDelete}
            alt="Delete"
            style={{ margin: 0, cursor: "pointer" }}
            onClick={() => handleDelete()}
          />
        </div>
      </TableCell>
      {confirm && (
        <Modal
          closeModal={() => handleDelete()}
          header={
            <div className={classes.header}>
              <span className={classes.headerText}>
                {(t("DeleteText") || `Delete`) + ` ${item.description}`}
              </span>
            </div>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton") || "Cancel"}
                onClick={() => handleDelete()}
                variant={"text"}
                color={"error"}
              />
              <Button
                caption={t("ConfirmButton") || "Confirm"}
                variant={"text"}
                onClick={() => onDelete(item.id)}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            {/* {responseError && (
                      <div className={"row mx-0"}>
                        <div className={"col-12"}>
                          <div className={"alert alert-danger"}>
                            {responseError}
                          </div>
                        </div>
                      </div>
                    )} */}
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {(t("DeleteConfirmationText") ||
                  "Are you sure you want to delete this") +
                  ` ${item.description}?`}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </TableRow>
  );
};

export default SterilizationItemTable;
