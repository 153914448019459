import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
  root: {
      width: '100%',
      cursor: 'pointer',
      '& > div': {
        minHeight: 60,
        width: 350,
        alignItems: 'center',
      },
    }
}))