import React, { useContext, useEffect, useState } from "react";

import PageContainer from "../../components/PageGeneralContainer/PageGeneralContainer";
import ModelsTable from "./ModelsTable/ModelsTable.jsx";

import request from "../../utils/request.js";
import t from "../../utils/translation.js";
import LocalizationContext from "../../utils/LocalizationContext.js";

import ModelsForm from "../../components/ModelsForm/ModelsForm.jsx";
import styles from "./Models.module.css";
import ModelsDetail from "./ModelsDetail/ModelsDetail.jsx";

const Models = () => {
  const [companyMachineModels, setCompanyMachineModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [methods, setMethods] = useState([]);
  const [machineTypes, setMachineTypes] = useState();
  const [modal, setModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [formData, setFormData] = useState({
    machineType: "",
    companyMachineMakeId: "",
    name: "",
    methodId: "",
    description: "",
    observations: "",
    technicalService: "",
    technicalServiceMail: "",
    technicalServicePhone: "",
    modelAreas: [],
  });
  const [modelData, setModelData] = useState({});
  const [loading, setLoading] = useState(true);

  const locale = useContext(LocalizationContext);

  const clearForm = () => {
    setFormData({
      machineType: "",
      companyMachineMakeId: "",
      name: "",
      methodId: "",
      description: "",
      observations: "",
      technicalService: "",
      technicalServiceMail: "",
      technicalServicePhone: "",
      modelAreas: [],
    });
  };
  const getCompanyMachineModels = async () => {
    try {
      setLoading(true);
      const res = await request().get("/api/CompanyMachineModel");
      setCompanyMachineModels(res.data);
    } catch (error) {
      console.error("Error fetching machine models:", error);
    } finally {
      setLoading(false);
    }
  };

  const getBrands = async () => {
    try {
      setLoading(true);
      const res = await request().get("/api/companyMachineMake");
      setBrands(
        res.data.map((b) => ({
          value: b.id,
          name: b.brand,
          machineMakeId: b.machineMakeId,
        }))
      );
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMethods = async () => {
    try {
      setLoading(true);
      const res = await request().get("/api/method");
      setMethods(
        res.data.map((m) => ({
          value: m.id,
          name: m.name,
          machineType: m.machineType,
        }))
      );
    } catch (error) {
      console.error("Error fetching methods:", error);
    } finally {
      setLoading(false);
    }
  };

  const getMachineTypes = async () => {
    try {
      setLoading(true);
      setMachineTypes([
        { value: 1, name: "Sterilizer" },
        { value: 2, name: "Washer" },
        { value: 3, name: "Disinfector" },
      ]);
    } catch (error) {
      console.error("Error setting machine types:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    clearForm();
  };

  const handleDetail = (data) => {
    setModelData(data);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setModelData({});
  };

  const getMachineTypeName = (id) => {
    return machineTypes?.find((m) => m.value === id)?.name;
  };

  useEffect(() => {
    getCompanyMachineModels();
    getBrands();
    getMethods();
    getMachineTypes();
  }, []);
  return (
    <PageContainer title={"Models"} category={"models"}>
      <div className={styles.container}>
        <ModelsTable
          companyMachineModels={companyMachineModels}
          getCompanyMachineModels={getCompanyMachineModels}
          handleData={setFormData}
          handleModal={setModal}
          handleDetail={handleDetail}
          loading={loading}
        />
        <div className={styles.detailContainer}></div>
      </div>
      <button
        className={`${styles.button} ${styles.addButton}`}
        onClick={() => handleAdd()}
      >
        Add
      </button>

      {modal && (
        <ModelsForm
          formData={formData}
          handleFormData={setFormData}
          handleClose={handleClose}
          open={modal}
          getCompanyMachineModels={getCompanyMachineModels}
          brands={brands}
          machineTypes={machineTypes}
          methods={methods}
          t={t}
          locale={locale}
          companyModels={companyMachineModels}
        />
      )}

      <ModelsDetail
        getMachineTypeName={getMachineTypeName}
        open={showDetail}
        handleClose={handleCloseDetail}
        t={t}
        locale={locale}
        modelData={modelData}
      />
    </PageContainer>
  );
};

export default Models;
