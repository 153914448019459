import React, { useState, useReducer, useEffect, useContext } from "react";
import { getUserCompany, getSession } from "../../utils/sessionHandler";
import request from "../../utils/request";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";

import Select from "../../components/Select";
import DateInput from "../../components/DateInput";
import Autocomplete from "../../components/Autocomplete";
import Input from "../../components/Input";
import Button from "../../components/Button";
import PageContentLoading from "../../components/PageContentLoading";
import { ProductType } from "../../dictionaries/productType";
import reducer from './ExporterReducer'
import moment from "moment-timezone";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const mapIndicatorSelected = (value) => {
  switch (value) {
    case "ReadingPRO":
      return "PRO";
    case "ReadingBI":
      return "BI";
    case "ReadingBIDisinfection":
      return "BI_DISINFECTION";
    case "readingchesterilizer":
      return "CHESTERILIZER"
    case "readingchewasher":
      return "CHEWASHER"
    default: return "";
  }
}

export default function Exporter({ indicatorSelected }) {
  const locale = useContext(LocalizationContext);
  const initialState = {
    reading: mapIndicatorSelected(indicatorSelected),
    fileType: "excel",
    sector: null,
    dateFrom: null,
    dateTo: null,
    incubator: null,
    user: null,
    columns: [],
    CSVSeparator: ",",
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [contentLoading, setContentLoading] = useState(false);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [incubatorOptions, setIncubatorOptions] = useState([]);
  const [columnOptions, setColumnOptions] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    reading,
    fileType,
    sector,
    dateFrom,
    user,
    incubator,
    dateTo,
    columns,
    CSVSeparator,
  } = state;

  useEffect(() => {
    getColumns(reading);
  }, [])

  const readingOptions = [
    [null, t("ChooseAReadingText", locale)],
    ["BI", t("BiologicalIndicatorSterilizationText", locale) || "Biological Indicator Sterilization"],
    ["BI_DISINFECTION", t("BiologicalIndicatorDisinfectionText", locale) || "Biological Indicator Disinfection"],
    ["PRO", t("ProteinIndicatorText", locale)],
    ["CHEWASHER", t("ChemicalIndicatorWasherText", locale)],
    ["CHESTERILIZER", t("ChemicalIndicatorSterilizerText", locale)],
  ];

  useEffect(() => {
    if (reading) {
      const params = setParamsName();
      if (params.controller)
        request().get(`/api/${params.controller}/GetIncubatorsFromReadings?productType=${params.productType}`).then((resp) => {
          setIncubatorOptions(formatSelectObject(resp.data));
        })
    }
  }, [reading])

  const setParamsName = () => {
    let controller, productType = 1
    if (reading == "BI") {
      productType = ProductType.BiologicalSterilizer
      controller = "ReadingBI"
    }
    if (reading == "BI_DISINFECTION") {
      productType = ProductType.BiologicalDisinfection
      controller = "ReadingBIDisinfection"
    }
    if (reading == "PRO") {
      controller = "ReadingPRO"
    }
    return {
      productType: productType,
      controller: controller
    }
  }

  const fileTypes = [
    ["excel", "Excel"],
    ["csv", "CSV"],
    ["xml", "XML"],
  ];

  useEffect(() => {
    setContentLoading(true);
    Promise.all([
      request().get(`/api/Sector?companyid=${getUserCompany().Id}`),
      request().get(`/api/appuser/getbycompany?companyId=${getUserCompany().Id}`)
    ]).then(([sectorsData, usersData]) => {
      setSectorOptions(
        formatSelectObject(sectorsData.data, t("ChooseASectorText", locale))
      );
      setUserOptions(usersData.data);
      setContentLoading(false);
    });
  }, [locale]);

  const getColumns = (selectedRead) => {
    setLoadingColumns(true);
    setColumnOptions([]);
    request()
      .post("/api/export/getexportcols", {
        UserLogged: getSession(),
        Read: selectedRead,
      })
      .then((response) => {
        setColumnOptions(response.data);
        setLoadingColumns(false);
      })
      .catch((error) => console.log(error.response));
  };

  const handleReadingChange = (selectedRead) => {
    dispatch({ type: "SET_READING", payload: selectedRead });
    dispatch({ type: "CLEAR_FIELDS" });
    getColumns(selectedRead);
  };

  const handleFileTypeChange = (selectedFileType) => {
    dispatch({ type: "SET_FILETYPE", payload: selectedFileType });
    dispatch({ type: "SET_CSVSEPARATOR", payload: "," });
  };

  const handleFileExport = () => {
    setLoading(true);
    request()
      .post(
        `/api/export/${fileType}`,
        {
          UserLogged: getSession(),
          Read: reading,
          Filter: {
            SectorId: sector,
            DateFrom: dateFrom,
            DateTo: dateTo,
            IncubatorId: incubator,
            EditUserId: user?.id,
            ClientDateTimeNow: moment().toISOString(true),
            ClientTimeZoneId: moment.tz.guess(true),
          },
          CSVSeparator: CSVSeparator,
          Cols: columns,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        const extension =
          fileType === "excel"
            ? ".xlsx"
            : fileType === "csv"
              ? ".csv"
              : fileType === "xml"
                ? ".xml"
                : "";
        const exportDateTime = new Date();
        const fileName = `Exporter${reading}-${exportDateTime.toLocaleDateString()} ${exportDateTime.getHours()}:${exportDateTime.getMinutes()}:${exportDateTime.getSeconds()}${extension}`;

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(response.data, fileName);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(response.data);
          a.download = fileName;
          a.target = "_blank";
          a.click();
          a.remove();
          window.URL.revokeObjectURL(response.data);
        }
        setLoading(false);
      });
  };

  if (contentLoading) return <PageContentLoading />;

  return (
    <div className="container" style={{ marginTop: 15 }}>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Select
            label={t("ReadingLabel", locale)}
            value={reading}
            options={readingOptions}
            onChange={(val) => handleReadingChange(val)}
            variant={"outlined"}
            disabled={loading}
          />
        </div>
        {(reading === "BI" || reading === "BI_DISINFECTION" || reading === "PRO") && (
          <>
            <div
              className={`col-md-${fileType === "csv" ? "3" : "6"} col-sm-6`}
            >
              <Select
                label={t("FileTypeLabel", locale)}
                value={fileType}
                options={fileTypes}
                onChange={(val) => handleFileTypeChange(val)}
                variant={"outlined"}
                disabled={loading}
              />
            </div>
            {fileType === "csv" && (
              <div className="col-md-3 col-sm-6">
                <Input
                  label={t("CSVSeparatorLabel", locale)}
                  value={CSVSeparator}
                  onChange={(val) =>
                    dispatch({ type: "SET_CSVSEPARATOR", payload: val })
                  }
                  variant={"outlined"}
                  gutter={"14px 0"}
                  disabled={loading}
                  isRequired={true}
                  error={CSVSeparator === "" ? t("RequiredFieldError") : ""}
                />
              </div>
            )}
            <div className="col-md-4 col-sm-6">
              <Select
                label={t("SectorLabel", locale)}
                value={sector}
                options={sectorOptions}
                onChange={(val) =>
                  dispatch({ type: "SET_SECTOR", payload: val })
                }
                variant={"outlined"}
                nullable
                disabled={loading}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <Select
                label={t("IncubatorLabel", locale)}
                value={incubator}
                options={incubatorOptions}
                onChange={(val) =>
                  dispatch({ type: "SET_INCUBATOR", payload: val })
                }
                variant={"outlined"}
                nullable
                disabled={loading}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <Autocomplete
                label={t("UserLabel", locale)}
                value={user}
                options={userOptions}
                onChange={(val) => dispatch({ type: "SET_USER", payload: val })}
                disabled={loading}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <DateInput
                label={t("StartedDateLabel", locale)}
                value={dateFrom}
                onChange={(val) =>
                  dispatch({
                    type: "SET_DATE_FROM",
                    payload: val ? val.startOf('day').toISOString(true) : null,
                  })
                }
                variant={"outlined"}
                clearable
                gutter={"14px 0"}
                noError={true}
                disabled={loading}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <DateInput
                label={t("ResultDateLabel", locale)}
                value={dateTo}
                onChange={(val) =>
                  dispatch({
                    type: "SET_DATE_TO",
                    payload: val ? val.endOf('day').toISOString(true) : null,
                  })
                }
                variant={"outlined"}
                clearable
                gutter={"14px 0"}
                noError={true}
                disabled={loading}
              />
            </div>
            <div className="col-12">
              <Autocomplete
                multiple
                label={t("ColumnsLabel", locale)}
                value={columns}
                options={columnOptions}
                descriptionField={"description"}
                onChange={(val) =>
                  dispatch({ type: "SET_COLUMNS", payload: val })
                }
                loading={loadingColumns}
                disabled={loading}
                error={
                  columns.length === 0 && loadingColumns === false
                    ? t("ColumnsErrorText", locale)
                    : ""
                }
              />
            </div>
          </>
        )}
        {(reading === "CHEWASHER" || reading === "CHESTERILIZER") && (
          <>
            <div
              className={`col-md-${fileType === "csv" ? "3" : "6"} col-sm-6`}
            >
              <Select
                label={t("FileTypeLabel", locale)}
                value={fileType}
                options={fileTypes}
                onChange={(val) => handleFileTypeChange(val)}
                variant={"outlined"}
                disabled={loading}
              />
            </div>
            {fileType === "csv" && (
              <div className="col-md-3 col-sm-6">
                <Input
                  label={t("CSVSeparatorLabel", locale)}
                  value={CSVSeparator}
                  onChange={(val) =>
                    dispatch({ type: "SET_CSVSEPARATOR", payload: val })
                  }
                  variant={"outlined"}
                  gutter={"14px 0"}
                  disabled={loading}
                  isRequired={true}
                  error={CSVSeparator === "" ? t("RequiredFieldError") : ""}
                />
              </div>
            )}
            <div className="col-md-4 col-sm-6">
              <Autocomplete
                label={t("UserLabel", locale)}
                value={user}
                options={userOptions}
                onChange={(val) => dispatch({ type: "SET_USER", payload: val })}
                disabled={loading}
              />
            </div>
            <div className="col-md-4 col-sm-4">
              <DateInput
                label={t("StartedDateLabel", locale)}
                value={dateFrom}
                onChange={(val) =>
                  dispatch({
                    type: "SET_DATE_FROM",
                    payload: val ? val.startOf('day').toISOString(true) : null,
                  })
                }
                variant={"outlined"}
                clearable
                gutter={"14px 0"}
                noError={true}
                disabled={loading}
              />
            </div>
            <div className="col-md-4 col-sm-4">
              <DateInput
                label={t("ResultDateLabel", locale)}
                value={dateTo}
                onChange={(val) =>
                  dispatch({
                    type: "SET_DATE_TO",
                    payload: val ? val.endOf('day').toISOString(true) : null,
                  })
                }
                variant={"outlined"}
                clearable
                gutter={"14px 0"}
                noError={true}
                disabled={loading}
              />
            </div>
            <div className="col-12">
              <Autocomplete
                multiple
                label={t("ColumnsLabel", locale)}
                value={columns}
                options={columnOptions}
                descriptionField={"description"}
                onChange={(val) =>
                  dispatch({ type: "SET_COLUMNS", payload: val })
                }
                loading={loadingColumns}
                disabled={loading}
                error={
                  columns.length === 0 && loadingColumns === false
                    ? t("ColumnsErrorText", locale)
                    : ""
                }
              />
            </div>
          </>
        )}
        <div className="col-12" style={{ marginTop: 15 }}>
          <Button
            caption={t("ExportButton")}
            onClick={handleFileExport}
            disabled={
              reading === "" || CSVSeparator === "" || columns.length === 0
            }
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

