import React, { useContext, useState } from "react";
import InputQ from "../../../components/FormComponents/InputQ/InputQ";
import t from "../../../utils/translation";
import LocalizationContext from "../../../utils/LocalizationContext";
import { FaPlus, FaTrash } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import styles from "./Zones.module.css";

const Zones = ({ handleZones, machineType, modelAreas = [] }) => {
  const [newZone, setNewZone] = useState("");

  const locale = useContext(LocalizationContext);

  const handleNewZone = (e) => {
    setNewZone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newZone.trim()) {
      handleZones([
        ...modelAreas,
        { description: newZone, zoneNumber: uuidv4() },
      ]);
    }
    setNewZone("");
  };

  const handleRemove = (index) => {
    const updatedZones = modelAreas.filter((_, i) => i !== index);
    handleZones(updatedZones);
  };

  const handleChangeZone = (index, value) => {
    const updatedZones = modelAreas.map((zone, i) =>
      i === index
        ? { description: value, id: zone.id, zoneNumber: zone.zoneNumber }
        : zone
    );
    handleZones(updatedZones);
  };

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={styles.inputContainer}>
          <InputQ
            label={t("AddZone", locale) || "Add Zone"}
            name="newZone"
            handleChange={handleNewZone}
            value={newZone}
          />
          <button
            type="button"
            className={styles.addButton}
            onClick={(e) => handleSubmit(e)}
          >
            <FaPlus />
          </button>
        </div>
      </form>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{machineType == 0 ? "Zone" : "Level"}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {modelAreas.map((z, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={z.description}
                    onChange={(e) => handleChangeZone(index, e.target.value)}
                    className={styles.zoneInput}
                  />
                </td>
                <td>
                  <button
                    onClick={() => handleRemove(index)}
                    type="button"
                    className={styles.removeButton}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Zones;
