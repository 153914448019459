import { makeStyles, createStyles } from '@material-ui/styles';
export default makeStyles((theme) => createStyles({

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px'
    },

    disabled: {
        pointerEvents: 'none'
    },

    requiredField: {
        color: theme.palette.terragene.requiredField
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#221e20'
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },

    divScanner: {
        position: 'absolute',
        right: 50,
    },

    imgScanner: {
        width: 44,
        height: 44,
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    },

    groupedTitle: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#221e20'
    },
    groupedDiv: {
        margin: '0px 8px',
        marginBottom: 12
    }

}));