import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Select2 from "../../components/Select2";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Product() {
  const query = useQuery();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [productModels, setproductModels] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState(
    query.get("distributorId") || ""
  );

  useEffect(() => {
    request()
      .get(`/api/distributor`)
      .then((response) => {
        setTimeout(() => {
          setDistributors(
            response.data.reduce((distributorOptions, distributor) => {
              distributorOptions.push([distributor.id, distributor.name]);
              return distributorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    request()
      .get("/api/Productmodel")
      .then((types) => {
        setproductModels(
          types.data.reduce((typesList, type) => {
            typesList.push([type.id, type.productModelDescription.toUpperCase()]);
            return typesList;
          }, [[null, 'Choose a Product']])
        );

        setLoadingContent(false);
      })
      .catch(error => console.log(error.response));
  }, []);

  productModels.sort(function (a, b) {
    if (a[0] == null || b[0] == null) {
      return 0;
    }

    if (a[1] > b[1]) {
      return 1;
    }
    if (a[1] < b[1]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  useEffect(() => {
    updateData()
  }, [loadingContent, productModels, selectedDistributor]);

  const updateData = () => {
    if (selectedDistributor) {
      setLoadingData(true);
      request()
        .get(`/api/product/GetByDistributor?distributorId=${selectedDistributor}`)
        .then(response => {
          const productsMapped = response.data.reduce((readingsList, reading) => {
            let productModelNameFromArr = "";
            for (let i = 0; i < productModels.length; i++) {
              if (productModels[i][0] === reading.productModelId) {
                productModelNameFromArr = productModels[i][1];
              }
            }

            readingsList.push({
              ...reading,
              productModelName: productModelNameFromArr,
              compatible: reading.productModel.compatibleTrazanto
            });
            return readingsList;
          }, [])
            .map(x =>
              _.mapKeys(x, (value, key) => {
                return "product." + key;
              })
            )
          setData(productsMapped)
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        });
    }
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div className={"col-12 col-md-5 px-0"}>
            <Select2
              label={"Distributor"}
              options={distributors}
              emptyOptionText={"Choose A Distribuidor"}
              variant={"outlined"}
              value={selectedDistributor}
              onChange={(val) => setSelectedDistributor(val)}
            />
          </div>
        </div>
      </div>
      {selectedDistributor && (
        <CRUD
          data={data}
          url={"/api/product"}
          title={"Product"}
          updateData={updateData}
          loadingData={loadingData}
          columns={[
            "product.productModelName",
            "product.name",
            "product.brand"
          ]}
          headers={["Product Model", "Name", "Brand"]}
          idField={"product.id"}
          fieldsMap={[
            "product.distributorId",
            "product.productModelId",
            "product.name",
            "product.brand"
          ]}
          formFields={[
            {
              label: "Distributor",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: distributors,
              defaultValue: selectedDistributor,
              updateOnly: true,
              required: true,
            },
            {
              label: "Product Model",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: productModels,
              required: true
            },
            { label: "Name", class: "col-12 col-md-6 px-0", required: true },
            { label: "Brand", class: "col-12 col-md-6 px-0", required: true }
          ]}
          detailFields={[
            { label: "Product Model", name: "product.productModelName" },
            { label: "Name", name: "product.name" },
            { label: "Brand", name: "product.brand" },
            { label: "Compatible Trazanto", name: "product.compatible" }
          ]}
          baseObject={{
            product: {
              id: null,
              distributorId: selectedDistributor,
              productModelId: "",
              name: "",
              brand: "",
              compatibleTrazanto: false
            }
          }}
        />
      )}
    </div>
  );
}
