import React, { useState, useEffect, useContext } from "react";
import request from "../../utils/request";
import { getDist, getSession } from "../../utils/sessionHandler";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import df from "../../utils/dateFormater";

import CRUD from "../../components/CRUD";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Progress from "../../components/Progress";
import Input from "../../components/Input";
import useStyles from "./DistLicenseCodesStyles";

const DistLicenseCodes = () => {
  const locale = useContext(LocalizationContext);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);  
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [activationKey, setActivationKey] = useState("");
  const [confirmationObject, setConfirmationObject] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    updateData();
  }, []);
  
  const updateData = () => {
      setLoadingData(true);
      request()
        .get(`/api/LicenseCode/${getSession().Distributor.Id}`)
        .then((response) => {
          response.data.forEach((licenseCode) => {
            licenseCode.startDateFormatted = df(licenseCode.startDate, "date")
            licenseCode.endDateFormatted = df(licenseCode.endDate, "date")
          })
          setData(response.data);
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        })
        .catch((error) => {
          console.log(error.response);
        });
  };

  const buyLicense = () => {
    
  }

  return (
    <>
    <CRUD
      deleteOption={false}    
      detailOption={false}      
      newOption={false} 
      editOption={false} 
      data={data}
      loadingData={loadingData}
      url={"/api/LicenseCode"}
      updateData={updateData}
      title={t("Title", locale)}
      columns={["code", "used", "licenseProduct", "company", "licenseStatusDescription", "startDateFormatted", "endDateFormatted"]}
      headers={[t("ActivationCodeLabel", locale), t("UsedLabel", locale), t("ProductLabel", locale), t("LicensedToLabel", locale), t("StatusLabel", locale), t("StartDate", locale), t("EndDate", locale)]}
      idField={"id"}
      fieldsMap={["name"]}
      formFields={[
        { label: t("ProductLabel", locale), class: "col-12 col-md-6 px-0", required: true },
      ]}
      baseObject={{
        name: "",
      }}
    />
    {confirmationObject && (
        <Modal
          closeModal={() => setConfirmationObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {confirmationObject.header}
                </span>
              </div>
              <Progress active={loadingConfirm} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                variant={"text"}
                onClick={() => setConfirmationObject(null)}
                disabled={loadingConfirm}
                color={"error"}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={() =>
                  confirmationObject.action(
                    confirmationObject.object,
                    activationKey
                  )
                }
                disabled={loadingConfirm}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <Input
                  label={t("KeyLabel", locale)}
                  value={activationKey}
                  onChange={(value) => setActivationKey(value)}
                  error={error}
                  isRequired
                />
              </div>
            </div>
          </div>
        </Modal>
      )}      
    </>
  );
}

export default DistLicenseCodes;