import React, { useState } from 'react';
export const CompanyContext = React.createContext({
    company: {},
    setCompany: () => { }
});

export const CompanyContextProvider = (props) => {
    const setCompany = (company) => {
        setState({...state, company: company})
    }
    const initState = {
        company: { id: null, image: undefined },
        setCompany: setCompany
    }
    const [state, setState] = useState(initState)

    return (
        <CompanyContext.Provider value={state}>
            {props.children}
        </CompanyContext.Provider>
    )
}