import React, { useState } from "react";
import Time from "../../TimeFormat/Time";
import { Tooltip } from "@material-ui/core";
import styles from "./TwelveCircle.module.css"; // Import the CSS module

const TwelveCircle = ({
  t,
  locale,
  p,
  colorStatus,
  programFiltered,
  type,
  condition,
  handleClick,
  vinculation,
  position,
}) => {
  const handleTwelvePositions = (value) => {
    if (condition === "FRLCD") {
      return value === 1 || value === 12
        ? 62
        : value === 2 || value === 11
        ? 90
        : value === 3 || value === 10
        ? 100
        : value === 4 || value === 9
        ? 87
        : value === 5 || value === 8
        ? 55
        : value === 6 || value === 7
        ? 11
        : "";
    } else {
      return value === 1 || value === 12
        ? 73
        : value === 2 || value === 11
        ? 106
        : value === 3 || value === 10
        ? 116
        : value === 4 || value === 9
        ? 101
        : value === 5 || value === 8
        ? 65
        : value === 6 || value === 7
        ? 13
        : "";
    }
  };

  const handleTwelveMargins = (value) => {
    if (condition === "FRLCD") {
      return value === 1 || value === 12
        ? 130
        : value === 2 || value === 11
        ? 15
        : value === 3 || value === 10
        ? 25
        : value === 4 || value === 9
        ? 20
        : value === 5 || value === 8
        ? 15
        : value === 6 || value === 7
        ? -5
        : "";
    } else {
      return value === 1 || value === 12
        ? 112
        : value === 2 || value === 11
        ? 22
        : value === 3 || value === 10
        ? 30
        : value === 4 || value === 9
        ? 28
        : value === 5 || value === 8
        ? 18
        : value === 6 || value === 7
        ? 0
        : "";
    }
  };

  const programPosition = (value, p) => {
    let y =
      value === 1 || value === 12
        ? "-2px"
        : value === 2 || value === 11
        ? "-13px"
        : value === 3 || value === 10
        ? "-23px"
        : value === 4 || value === 9
        ? "-34px"
        : value === 5 || value === 8
        ? "-43px"
        : value === 6 || value === 7
        ? "-48px"
        : "";

    let x =
      value === 1 || value === 12
        ? 22
        : value === 2 || value === 11
        ? 28
        : value === 3 || value === 10
        ? 28
        : value === 4 || value === 9
        ? 28
        : value === 5 || value === 8
        ? 20
        : value === 6 || value === 7
        ? 0
        : "";
    return p === "x" ? x : y;
  };

  return (
    <Tooltip
      arrow
      key={p.positionNumber}
      title={
        (p.resultDate || p.startedTime) &&
        (p.status === 1 ? (
          <Time
            text={t("WasStartedSpan", locale) || "Was started "}
            time={p.startedTime}
          />
        ) : (
          <Time
            text={t("AvailableSinceSpan", locale) || "Available since "}
            time={p.resultDate}
          />
        ))
      }
    >
      <div
        key={p.positionNumber}
        onClick={() => handleClick(p.positionNumber)}
        className={`${styles.circle} ${vinculation ? styles.hoverEffect : ""} ${
          p.status === 1 ? styles.yellow : styles.green
        } ${
          p.positionNumber === position
            ? p.status === 1
              ? styles.yellowActive
              : styles.greenActive
            : ""
        }`}
        style={{
          backgroundColor: colorStatus(p.status),
          marginTop: handleTwelveMargins(p.positionNumber),
          right: type === "r" ? handleTwelvePositions(p.positionNumber) : null,
          left:
            type === "l" ? handleTwelvePositions(p.positionNumber) + 4 : null,
        }}
      >
        {p.positionNumber}
        {p.status === 1 && (
          <p
            className={styles.programLabel}
            style={{
              top: programPosition(p.positionNumber, "y"),
              right: `-${programPosition(p.positionNumber, "x")}px`,
            }}
          >
            {programFiltered(p.programId)}
          </p>
        )}
      </div>
    </Tooltip>
  );
};

export default TwelveCircle;
