import React from 'react';
import { Chip } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import t from "../../utils/translation";

const useStylesAccordion = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: theme.palette.terragene.lightMain,
        fontSize: '10px',
        textTransform: 'uppercase',
        background: theme.palette.terragene.darkSecondary,
        marginTop:'25px',
        marginLeft:'25px',

    }
}));
const useStylesAccordionSummary = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        minHeight: '35px'
    },
    expandIcon: {
        color: theme.palette.terragene.lightMain
    }
}));
const useStylesChip = makeStyles((theme) => ({
    root: {
        margin: '5px',
        color: theme.palette.terragene.darkMain,
        backgroundColor: theme.palette.terragene.lightThird
    },
    deleteIcon: {
        color: theme.palette.terragene.darkMain
    }
}));
const useStylesTypography = makeStyles((theme) => ({
    root: {
        fontSize: '13px',
        color: theme.palette.terragene.lightThird,
    }
}));
const useStylesAccordionDetail = makeStyles(() => ({
    root: {
        padding: '0px 6px 6px'
    }
}));

const TrazantoIndicators = ({ products }) => {
    const classesAccordion = useStylesAccordion();
    const classesAccordionSummary = useStylesAccordionSummary();
    const classesChip = useStylesChip();
    const classesTypographyTitle = useStylesTypography();
    const classesAccordionDetail = useStylesAccordionDetail();

    const handleDelete = () => {

    }

    return (
        <Accordion classes={classesAccordion}>
            <AccordionSummary
                classes={classesAccordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography classes={classesTypographyTitle}>{t("CompatibleIndicatorsTrazanto")}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={classesAccordionDetail}>
                <div>
                    {
                        products.map((value) => {
                            return <span key={value.id} style={{ padding: '3px' }}><Chip classes={classesChip} color="default" size="medium" deleteIcon={<DoneIcon />} onDelete={handleDelete} label={value.name + ": " + value.brand} /></span>
                        })
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default TrazantoIndicators;