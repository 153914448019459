import React, { useEffect, useState } from "react";
import DDColumn from "../DragAndDrop/DDColumn/DDColumn.jsx";

import DDRow from "../DragAndDrop/DDRow/DDRow.jsx";
import imgPackage from "../../assets/images/PACKAGE IN A LOAD.svg";

import styles from "./DragAndDropCharges.module.css";

const DragAndDropCharges = ({
  data,
  selectedFilteredData,
  selectedData,
  handleSelectedData,
  filter1,
  filter2,
  leftColumnName,
  rightColumnName,
}) => {


  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.packageGeneralId);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.packageGeneralId);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    const item = data.find((item) => item.packageGeneralId == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.packageGeneralId === item.packageGeneralId);

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };

  const moveItem = (itemID, columnID) => {
    const item = data.find((item) => item.packageGeneralId == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.packageGeneralId === item.packageGeneralId);

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");

    const newState = selectedData.filter((material) => material.packageGeneralId != itemID);
    handleSelectedData(newState);
  };

  const onDelete = (id) => {
    const newState = selectedData.filter((material) => material.packageGeneralId != id);

    handleSelectedData(newState);
  };

  const packageExist = (packageGeneralId) => {
    return selectedData.find((m) => m.packageGeneralId === packageGeneralId);
  };

  useEffect(() => {
    if (selectedData.length > 0 && selectedData.length == 0)
      handleSelectedData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    handleSelectedData(selectedData);
  }, [selectedData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.columnContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.paper}>
              <p className={styles.title}>{leftColumnName}</p>
              {filter1}
              <DDColumn packageGeneralId={"Drag"} dragOver={draggingOver} drop={onDropDelete}>
                {data &&
                  data
                    .filter((d) => !packageExist(d.packageGeneralId))
                    .map((p) => (
                      <DDRow
                        key={p.packageGeneralId}
                        obj={p}
                        name={`${p.packageNumber} - ${p.description}`}
                        dragStart={startDragColumn}
                        doubleClick={doubleClick}
                        columnId={true}
                        load={true}
                        img={imgPackage}
                      />
                    ))}
              </DDColumn>
            </div>
          </div>
          <div className={styles.quantityPaper}></div>
          <div className={styles.rightColumn}>
            <div className={styles.paper}>
              <p className={styles.title}>{rightColumnName}</p>

              {filter2}

              <DDColumn packageGeneralId={"Drop"} dragOver={draggingOver} drop={onDrop} autoScroll={true}>
                {selectedFilteredData.map((p) => (
                  <DDRow
                    key={p.packageGeneralId}
                    obj={p}
                    name={`${p.packageNumber} - ${p.description}`}
                    dragStart={startDrag}
                    onDelete={onDelete}
                    doubleClick={onDelete}
                    columnId={null}
                    load={true}
                    img={imgPackage}
                  />
                ))}
              </DDColumn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DragAndDropCharges;
