const parseFiltersDate = (dateString, format, end = false) => {
    let dateParts;
    let date;
  
    switch (format) {
      case "D/M/YYYY":
        dateParts = dateString.split("/");
        date = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
        break;
  
      case "YYYY/MM/DD":
        dateParts = dateString.split("/");
        date = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
        break;
  
      case "M/D/YYYY":
        date = dateString;
        break;
  
      default:
        return ""; 
    }
  
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return ""; 
    }
  
    return date + (end ? " 23:59:59" : "");
  };
  
  export default parseFiltersDate;
  