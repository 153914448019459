import React from "react";
import styles from "./ModelsDetail.module.css";
import ModalQ from "../../../components/TableBionovaQ/ModalQ/ModalQ";

const ModelsDetail = ({ modelData, t, locale, handleClose, open, getMachineTypeName }) => {
  console.log(modelData)
  return (
    <ModalQ handleClose={handleClose} open={open} title={"Model Detail"}>
      <div className={styles.detailContainer}>
        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("ModelName", locale) || "Model Name"}:
            </label>
            <div className={styles.detailValue}>{modelData.name || "-"}</div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Brand", locale) || "Brand"}:
            </label>
            <div className={styles.detailValue}>{modelData.companyMachineMake?.brand || "-"}</div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("MachineType", locale) || "Machine Type"}:
            </label>
            <div className={styles.detailValue}>
              {getMachineTypeName(modelData.machineType) || "-"}
            </div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Description", locale) || "Description"}:
            </label>
            <div className={styles.detailValue}>
              {modelData.description || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Observations", locale) || "Observations"}:
            </label>
            <div className={styles.detailValue}>
              {modelData.observations || "-"}
            </div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("TechnicalService", locale) || "Technical Service"}:
            </label>
            <div className={styles.detailValue}>
              {modelData.technicalService || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("TechnicalServiceMail", locale) || "Technical Service Mail"}:
            </label>
            <div className={styles.detailValue}>
              {modelData.technicalServiceMail || "-"}
            </div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("TechnicalServicePhone", locale) || "Technical Service Phone"}:
            </label>
            <div className={styles.detailValue}>
              {modelData.technicalServicePhone || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {modelData.machineType == 1
                ? t("Zones", locale) || "Zones"
                : t("Levels", locale) || "Levels"}
              :
            </label>
            <table className={styles.table}>
              <tbody>
                {modelData.modelAreas && modelData.modelAreas.length > 0 ? (
                  modelData.modelAreas.map((area, index) => (
                    <tr key={index}>
                      <td>{area.description || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      {t("NoZonesOrLevels", locale) ||
                        "No zones or levels available"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ModalQ>
  );
};

export default ModelsDetail;
