export default function reducer (state, action) {
    switch (action.type) {
        case 'loadSuscriptions':
            return {
                ...action.payload
            };
        case 'onChange':
            return {
                ...state
            };
        case 'SHOW_ALERT':
            return {
                ...state,
                text: action.payload.text,
                type: action.payload.type
            };
        case 'CLOSE_ERROR':
            return {
                ...state,
                text: ''
            };
        default:
            throw new Error();
    }
}