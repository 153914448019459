import React, { useState, useEffect } from 'react';
import t from '../../utils/translation'
import useStyles from "./CountDownTimerStyles";
import Button from "../Button";
import ButtonQ from '../ButtonQ';


const CountDownTimer = ({ initialMinute, initialSeconds, counterFinished, onceTime = false }) => {
    const classes = useStyles();
    const [stopRefresh, setStopRefresh] = useState(false);
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (!stopRefresh) {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        if (onceTime) {
                            setMinutes(null)
                            setSeconds(null)
                        } else {
                            setMinutes(initialMinute)
                            setSeconds(initialSeconds)
                        }
                        counterFinished()
                        clearInterval(myInterval)
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    const onClickStopRefresh = () => {
        setStopRefresh(!stopRefresh)
    }

    return (
        <span className={classes.root}>
            {minutes !== null && seconds != null && <span>{t("RefreshingIn") + '  '}</span>}
            {
                minutes === 0 ? '00:' :
                    <span>{minutes}</span>
            }
            {
                seconds &&
                <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            }
            {
                minutes !== null && seconds != null &&
                    <ButtonQ
                     caption={stopRefresh? t('StartRefresh') : t('StopRefresh')} onClick={onClickStopRefresh}></ButtonQ>
            }
        </span>
    );
};

export default CountDownTimer;