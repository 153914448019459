// Librerías de terceros
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FiPackage } from "react-icons/fi";

// Componentes locales
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableFilterQ from "../../components/TableBionovaQ/TableFilterQ/TableFilterQ.jsx";
import ManageDetail from "../../components/ManageDetail/ManageDetail.jsx";
import LoadDetail from "../../components/LoadDetail/LoadDetail.jsx";

// Utilidades
import useTableQ from "../../utils/Hooks/useTableQ";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import filterByProperties from "../../utils/filterByProperties.js";
// Estilos e imágenes
import styles from "./ManageLoads.module.css";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import parseDf from "../../utils/parseDf.js";
import {
  getRegionalFormat,
  getSectorId,
  getUserCompany,
} from "../../utils/sessionHandler.js";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import t from "../../utils/translation.js";
import parseFiltersDate from "../../utils/parseFiltersDate.js";

export default function ManageLoads() {
  const [loads, setLoads] = useState([]);
  const [loads2, setLoads2] = useState([]);
  const [loadDetail, setLoadDetail] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [status, setStatus] = useState(5);
  const [name, setName] = useState("");
  const [loadNumber, setLoadNumber] = useState("");
  const [method, setMethod] = useState("");
  const [sterilizers, setSterilizers] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [loadingLoads, setLoadingLoads] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  // const getLoads = async () => {
  //   setLoadingLoads(true);
  //   try {
  //     const response = await request().get(`/api/charge/newCharge`);
  //     setLoads(
  //       response.data.filter(
  //         (p) => p.chargeStatus === 1 || p.chargeStatus === 2
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error fetching loads:", error);
  //   } finally {
  //     setLoadingLoads(false);
  //   }
  // };

  const getLoads = async () => {
    setLoadingLoads(true);
    try {
      const response = await request().get(
        `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setLoads(response.data.chargeListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingLoads(false);
    }
  };

  const getStatusName = (id) => {
    return id === 1 ? t("BuildingLoad", locale) : t("ToSterilize", locale);
  };

  const getStatusColor = (id) => {
    return id === 1 ? "yellow" : "green";
  };

  const onEdit = (chargeId) => {
    localStorage.setItem("loadEditId", chargeId);
    Redirect({
      redirect: true,
      path: "/appcreateloads",
    });
  };

  const onDelete = (chargeId) => {
    confirm({
      title: "Are you sure?",
      text: "Do you really want to delete this load?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
      onConfirm: () => handleDelete(chargeId),
    });
  };

  const handleDelete = (chargeId) => {
    request()
      .delete(`/api/charge`, { id: chargeId })
      .then(() => {
        let newData = loads.filter((load) => load.chargeId !== chargeId);
        setLoads(newData);
        setLoadDetail(null);
      });
  };

  const handleLoadDetail = (chargeId) => {
    let item = loads.find((l) => l.chargeId === chargeId);
    console.log(item);
    setLoadDetail(item);
    setIsDetailVisible(true);
  };

  const handleMethod = (e) => {
    setMethod(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${1}&search=${name}&method=${
            e.target.value
          }&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${1}&search=${
            e.target.value
          }&method=${method}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleLoadNumber = (e) => {
    setLoadNumber(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${
            e.target.value
          }&status=${status}&page=${1}&search=${name}&method=${method}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${1}&search=${name}&method=${method}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setLoads(response.data.chargeListatorList);
      setCurrentPage(1);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingLoads(false);
    }
  };
  const handleChangeSterilizer = (e) => {
    setSelectedSterilizer(e.target.value);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${1}&search=${name}&method=${method}&sterilizer=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/charge`);

      const sterilizers = response.data.sterilizers.map((item) => ({
        value: item.sterilizerId,
        name: item.name,
      }));

      const methods = response.data.methods.map((item) => ({
        value: item.methodId,
        name: item.methodName,
      }));
      setSterilizers(sterilizers);
      setMethods(methods);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  useEffect(() => {
    getLoads();
    getParametersLoad();
  }, []);

  const arrayStatus = [
    { value: 5, name: t("SelectStatusOption") },
    { value: 1, name: t("BuildingLoad", locale) },
    { value: 2, name: t("ToSterilize", locale) },
  ];

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Charge/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${
            e.target.value
          }&page=${1}&search=${name}&method=${method}&sterilizer=${selectedSterilizer}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeListatorList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };
  const {
    currentPage,
    currentItems,
    itemsPerPage,
    // totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    loads,
    [
      "chargeNumber",
      "creationDate",
      "chargeName",
      "sterilizerName",
      "methodName",
      "chargeStatus",
    ],
    totalPages
  );
  useEffect(() => {
    getLoads(itemsPerPage, currentPage);
  }, [itemsPerPage, currentPage]);

  return (
    <PageContainer
      categoryId={1}
      currentStep={2}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      subcategory={t("LoadSpan")}
      title={t("ManageLoads")}
      newButton={isDetailVisible ? false : true}
      newButtonUrl={"/appcreateloads"}
      progress={true}
      subtitle={isDetailVisible ? t("ManageDetailLoad", locale) : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handleLoadNumber}
                placeholder={t("NumberSpan")}
                label={t("NumberSpan")}
                value={loadNumber}
                containerWidth="100%"
              />
              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                property={"Name"}
                handleChange={handleName}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
              />
              <SelectQ
                value={selectedSterilizer}
                handleSelect={handleChangeSterilizer}
                width={"100%"}
                containerWidth={"100%"}
                options={sterilizers}
                placeholder={
                  t("SelectASterilizerOption") || "Select a Sterilizer"
                }
              />
              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={method}
                containerWidth={"100%"}
              />
              <SelectQ
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={status}
                containerWidth={"100%"}
              />
            </div>

            <TableContainerQ>
              <TableHeadQ actions={true}>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeNumber")}
                >
                  {t("NumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("creationDate")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeName")}
                >
                  {t("NameSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("sterilizerName")}
                >
                  {t("SterilizerSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingLoads ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems?.map((l) => (
                    <TableRowQ
                      key={l.ChargeId}
                      actions={true}
                      edit={l.chargeStatus === 1}
                      detail={l.chargeStatus === 1 || l.chargeStatus === 2}
                      deleteRow={true}
                      // onClick={() => handleLoadDetail(l.id)}
                      onEdit={() => onEdit(l.chargeId)}
                      onDelete={() => onDelete(l.chargeId)}
                      doubleClick={() =>
                        l.chargeStatus === 1 && onEdit(l.chargeId)
                      }
                      onDetail={() => handleLoadDetail(l.chargeId)}
                    >
                      <TableRowCellQ>
                        <TruncatedText text={l.chargeNumber} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={df(l.creationDate, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={l.chargeName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={l.sterilizerName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={l?.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[getStatusColor(l.chargeStatus)]
                          }`}
                        ></span>
                        <TruncatedText
                          text={getStatusName(l.chargeStatus)}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}

              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <LoadDetail loadId={loadDetail.chargeId} locale={locale} />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
