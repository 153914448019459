import React, { useEffect, useRef, useCallback } from "react";
import { useSnackbar } from "notistack";
import request from "../../utils/request";
import { SnackbarProvider } from "notistack";
import useStyles from "./SysMainStyles";

import Alert from "@material-ui/lab/Alert";

function SysMain() {
  const { enqueueSnackbar } = useSnackbar();

  const newNotification = useCallback(
    (notification, variant) => {
      enqueueSnackbar({ notification, variant });
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    request()
      .get("/api/sysnotification")
      .then((response) => {
        response.data.map((n) =>
          newNotification(
            n,
            n.notificationMessageType === 0
              ? "info"
              : n.notificationMessageType === 1
              ? "warning"
              : n.notificationMessageType === 2
              ? "error"
              : "default"
          )
        );
      })
      .catch((error) => console.log(error));
  }, [newNotification]);

  return null;
}

export default function SysMainNotificationProvider() {
  const classes = useStyles();
  const notistackRef = useRef(null);

  const handleNotificationClose = (notification, key) => {
    request()
      .post("/api/sysnotification/view", notification)
      .then(() => notistackRef.current.closeSnackbar(key))
      .catch((error) => console.log(error));
  };

  const handleNotificationRedirect = (notification) => {
    request()
      .post("/api/sysnotification/view", notification)
      .then(() => (window.location.href = "/" + notification.urlDescription))
      .catch((error) => console.log(error));
  };

  return (
    <SnackbarProvider
      maxSnack={100}
      ref={notistackRef}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      content={(key, { notification, variant }) => (
        <div
          className={classes.root}
          onClick={() => handleNotificationRedirect(notification)}
        >
          <Alert
            severity={variant}
            elevation={6}
            variant="filled"
            onClose={(e) => {
              handleNotificationClose(notification, key);
              e.stopPropagation();
            }}
          >
            <div>{notification.notificationDetail}</div>
          </Alert>
        </div>
      )}
    >
      <SysMain />
    </SnackbarProvider>
  );
}

