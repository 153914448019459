import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import Suscriptions from "../pages/Suscriptions/Suscriptions";
import useLocale from "../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Configuration,
    key: "Subscriptions",
  };
  const screenName = 321
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={100}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          <Suscriptions />
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
