import React, { useState, useEffect, useRef, useContext, useMemo } from "react";

import request from "../../../utils/request";
import t from "../../../utils/translation";
import LocalizationContext from "../../../utils/LocalizationContext";
import BarcodeReader from "react-barcode-reader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import printTicket from "../../../utils/printTicket";

import ModalSerial from "../../../components/ModalSerial/ModalSerial";
import ModalLoading from "../../../components/ModalLoading/ModalLoading";
import DragAndDrop from "../../../components/DragAndDrop/DDPackages.jsx";
import Search from "../../../components/Search/Search";

import { getUserCompany } from "../../../utils/sessionHandler";
import { PrintLabelContext } from "../../../utils/context/PrintLabelContext";
import PageContainer from "../../../components/PageContainer/PageContainer.jsx";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";

import styles from "./CreateTrays.module.css";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextAreaQ from "../../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import PackageLabel from "../../../components/packageLabel/PackageLabel.jsx";
import { Checkbox } from "@material-ui/core";
import CrudIndicator from "../../../components/CrudIndicator/CrudIndicator.jsx";
import { parseBarcode } from "gs1-barcode-parser-mod";
import { v4 as uuidv4 } from "uuid";

// import { IoIosArrowBack } from "react-icons/io";
// import Redirect from "../../../utils/Redirect";

const MySwal = withReactContent(Swal);

export default function CreateTrays() {
  const [observations, setObservations] = useState("");
  const [description, setDescription] = useState("");
  const [materialsTypes, setMaterialsTypes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedFilteredMaterials, setSelectedFilteredMaterials] = useState(
    []
  );
  const [packagesTypes, setPackagesTypes] = useState([]);
  const [packageId, setPackageId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [packageType, setTraysType] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [responseWithId1, setResponseWithId1] = useState(null);
  const [responseWithId2, setResponseWithId2] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [validate, setValidate] = useState(false);
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [waitMethod, setWaitMethod] = useState(null);
  const [indicators, setIndicators] = useState([]);

  const [stopButtons, setStopButtons] = useState(false);

  const locale = useContext(LocalizationContext);

  const descriptionRef = useRef();
  const observationsRef = useRef();

  const clearFields = (methodChange = true) => {
    methodChange && setDescription("");
    setObservations("");
    methodChange && setEditId(null);
    setSelectedMaterials([]);
    methodChange && setSelectedMethod("");
    setPackageId("");
    setIndicators([])
    getMaterials();
  };

  const validateFields = (status) => {
    let notValid = false;

    if (!description.length > 0 || description.trim() === "") {
      notValid = true;
    }

    if (!selectedMethod || selectedMethod === "") {
      notValid = true;
    }

    if (status !== 1 && !selectedMaterials.length > 0) {
      notValid = true;
    }

    // if (!packageId && packageId === "") {
    //   notValid = true;
    // }

    return notValid;
  };

  // const handleSelectOption = (option) => {
  //   setShowModal(false);

  //   if (option === responseWithId1) {
  //     setIndicators([...indicators, option]);
  //   } else {
  //     setSelectedMaterials([...selectedMaterials, option]);
  //   }
  //   setResponseWithId1(null);
  //   setResponseWithId2(null);
  // };

  const handleSelectMethod = async (e) => {
    const newMethod = e.target.value;

    if (selectedMethod && selectedMaterials.length > 0) {
      const result = await MySwal.fire({
        title:
          t("LostSelectedDataTitle", locale) || "Unsaved data will be lost",
        text:
          t("LostSelectedData", locale) ||
          "The selected materials will be lost. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("ConfirmButton", locale) || "Confirm",
        cancelButtonText: t("CancelButton", locale) || "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      });

      if (result.isConfirmed) {
        confirmChangeMethod(newMethod);
      }
    } else {
      setSelectedMethod(newMethod);
    }
  };

  const handleSelectPredifinedPackage = async (e) => {
    const newData = e.target.value;

    if ((packageId || materials) && selectedMaterials.length > 0) {
      const result = await MySwal.fire({
        title: t("LostSelectedDataTitle", locale) || "Materials will be lost",
        text:
          t("LostSelectedData", locale) ||
          "The selected materials will be lost. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("ConfirmButton", locale) || "Confirm",
        cancelButtonText: t("CancelButton", locale) || "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      });

      if (result.isConfirmed) {
        confirmChangeMethod(selectedMethod);
        setPackageId(newData);
      }
    } else {
      setPackageId(newData);
    }
  };
  const handleIndicators = (data) => setIndicators(data);

  useEffect(() => {
    setDescription(description.replace(scanCode, ""));
    setScanCode("");
  }, [scanCode]);

  const trayEditId = localStorage.getItem("trayEditId");

  const getMaterials = async () => {
    setModalEditLoading(true);
    await request()
      .get("/api/Material/Filtered")
      .then((response) => {
        let newMaterials = [];
        response.data.map(
          (material) =>
            (newMaterials = [
              ...newMaterials,
              {
                ...material,
                materialType: findMaterialType(material.materialTypeId),
              },
            ])
        );

        setMaterials(newMaterials);
        setFilteredMaterials(newMaterials);
        setModalEditLoading(false);
      });
  };

  const getMethods = () => {
    request()
      .get("/api/method/2")
      .then((response) => {
        const newMethods = response.data.map((item) => ({
          value: item.id,
          name: item.name,
        }));
        setMethods(newMethods);
      });
  };

  const getMaterialsTypes = () => {
    request()
      .get("/api/materialType")
      .then((response) => setMaterialsTypes(response.data));
  };

  const getTraysType = () => {
    request()
      .get("/api/predefinedpackage/")
      .then((response) => {
        const newData = [
          ...response.data.map((p) => ({ name: p.name, value: p.id })),
        ];
        setPackagesTypes(newData);
      });
  };

  const getPrintPackage = (id) => {
    request()
      .get(`/api/packageWashing/info?packageWashingId=${id}`)
      .then((res) => {
        showPrintAlert(res.data);
      });
  };

  const showPrintAlert = (packageDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel packageDetail={packageDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: "Print",
      customClass: {
        popup: "your-popup-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  const getEditTray = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(`/api/packagewashing/info?packageWashingId=${trayEditId}`)
        .then((response) => {
          setDescription(response.data.description);
          setObservations(response.data.observations);
          setIndicators(
            response.data.indicatorsWashing.map((i) => ({
              ...i,
              indicator: { name: i.indicatorName },
              indicator_Id: i.indicatorWashingId,
              uuid: uuidv4(),
            }))
          );

          setEditId(response.data.packageWashingId);
          setSelectedMaterials(
            response.data.materials.map((m) => ({
              ...m,
              name: m.materialName,
              serial: m.materialSerial,
              materialType: { name: m.materialTypeName },
              id: m.materialId,
            }))
          );
          setSelectedMethod(response.data.methodId);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setModalEditLoading(false);
      localStorage.removeItem("trayEditId");
    }
  };

  const findMaterial = (id) => materials.find((m) => m.id === id);

  const findMaterialType = (id) => materialsTypes.find((mt) => mt.id === id);

  const handleMaterials = (arr) => setFilteredMaterials(arr);
  const handlePackageType = (e) => setPackageId(e.target.value);
  const handleSelectedMaterials = (data) => setSelectedMaterials(data);
  const handleSelectedFilteredMaterials = (data) =>
    setSelectedFilteredMaterials(data);
  const handleSearchActive = (bool) => setSearchActive(bool);
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };

  const handleChangeDescription = (value, scan = false) => {
    if (!(value.target?.value?.length == 0 && !validate)) {
      setValidate(false);
    }
    const inputValue = scan ? value : value.target.value;
    setDescription(inputValue);
  };

  const onClearTray = () => setTraysType([]);

  const createTray = async (status) => {
    const newMaterials = selectedMaterials.map((material) => ({
      materialId: material.id,
      quantity: material.quantity || 1,
    }));
    const newIndicators = indicators.map((indicator) => ({
      indicatorId: indicator.indicator_Id,
      serial: indicator.serial || null,
      lot: indicator.lot || null,
      quantity: indicator.quantity || 1,
    }));

    const requestData = {
      observations: observations,
      description: description.length > 0 ? description : null,
      packageStatus: status,
      indicatorsWashing: newIndicators,
      materials: newMaterials,
      methodId: selectedMethod,
    };

    try {
      setModalLoading(true);
      setStopButtons(true);
      if (!editId) {
        await request()
          .post("/api/packagewashing", requestData)
          .then((res) => status === 1 && setEditId(res.data));

        status === 1
          ? toast.success(
              t("TraySaved", locale) || "The tray was successfully saved"
            )
          : toast.success(
              t("PackageCompleted") || "The tray was successfully completed"
            );
      } else {
        requestData.id = editId;
        await request().put("/api/packagewashing", requestData);
        toast.success(
          t("PackageModified", locale) || "The tray was successfully modified"
        );
      }

      status !== 1 && clearFields(true);
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setModalLoading(false);
      setStopButtons(false);
    }
  };

  // useEffect(() => {
  //   if (packageId || packageId === "reset") {
  //     request()
  //       .get(`/api/predefinedpackage/${packageId}`)
  //       .then((response) => {
  //         let newMaterials = [];
  //         console.log(response.data)
  //         response.data.materials.map((material) => {
  //           let item = materials.find((m) => m.id === material.materialId);
  //           if (item)
  //             newMaterials = [
  //               ...newMaterials,
  //               findMaterial(material.materialId),
  //             ];
  //         });

  //         setTraysType(newMaterials);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         setTraysType([]);
  //       });
  //   }
  // }, [packageId]);

  useEffect(() => {
    if (packageId || packageId === "reset") {
      request()
        .get(`/api/predefinedpackage/${packageId}`)
        .then((response) => {
          let newMaterials = [];

          response.data.materials.forEach((material) => {
            let item = materials.find((m) => m.id === material.materialId);
            if (item) {
              newMaterials.push({
                ...findMaterial(material.materialId),
                quantity: material.quantity,
              });
            }
          });
          setTraysType(newMaterials);
        })
        .catch((e) => {
          console.log(e);
          setTraysType([]);
        });
    }
  }, [packageId]);

  useEffect(() => {
    setFilteredMaterials(packageId ? packageType : materials);
  }, [packageId]);

  useEffect(() => {
    getMaterialsTypes();
    getTraysType();
    getMethods();
    getEditTray();
  }, []);

  useEffect(() => {
    getMaterials();
  }, [materialsTypes]);

  const trayTypeTranslate =
    t("SelectPredefinedTrayOption") || "Select a predefined tray";

  const addScanIndicator = (indicator) => {
    if (indicators.find((i) => i.id === indicator.id)) {
      indicators.find((i) => i.id === indicator.id).quantity++;
      toast.success(t("IndicatorAdded", locale) || "Indicator Added");
    } else {
      indicator = { ...indicator, quantity: 1 };
      setIndicators([...indicators, indicator]);
      toast.success(t("IndicatorAdded", locale) || "Indicator Added");
    }
  };

  const onHandleScan = async (data) => {
    let itemMaterial = materials.find((m) => m.serial == data);
    let materialCondition = selectedMaterials.find((m) => m.serial == data);

    if (itemMaterial) {
      setScanCode(data);
      setScanCode(data);
      if (materialCondition) {
        setSelectedMaterials(
          selectedMaterials.filter((m) => m != itemMaterial)
        );
        toast.success(t("MaterialRemoved", locale) || "Material removed");
      } else {
        setSelectedMaterials([...selectedMaterials, itemMaterial]);
        toast.success(t("MaterialAdded", locale) || "Material added");
      }
    } else {
      try {
        const variableField = parseBarcode(data).parsedCodeItems[3];
        const Gtin = parseBarcode(data).parsedCodeItems[0];
        let lot = "";

        const handleResponse = async (url) => {
          const response = await request().get(url);
          if (response.data.id) {
            setScanCode(data);
            let newIndicator = response.data;
            addScanIndicator(newIndicator);

            setDescription(description.replace(data, ""));
          }
        };

        if (variableField.ai === "10") {
          const url = `/api/indicatorLot/GTINforPackage?gtin=${Gtin.data}&lot=${
            variableField.data
          }&disId=${getUserCompany().DistributorId}&methodId=${selectedMethod}`;
          await handleResponse(url);
        } else if (variableField.data.includes("PANTONE")) {
          let match = variableField.data.match(/(.{6})95PANTONE/);
          if (match) {
            lot = match[1];
          }
          const url = `/api/indicatorLot/GTINforPackage?gtin=${
            Gtin.data
          }&lot=${lot}&disId=${
            getUserCompany().DistributorId
          }&methodId=${selectedMethod}`;
          console.log(variableField, Gtin, lot);
          await handleResponse(url);
        } else {
          const answer = variableField.data;

          let product;

          const indexA = answer.lastIndexOf("A");
          const indexF = answer.lastIndexOf("F");
          const indexB = answer.lastIndexOf("B");
          const indexBT = answer.lastIndexOf("BT");
          const indexPCD = answer.lastIndexOf("PCD");
          const indexCDWA = answer.lastIndexOf("CDWA");

          if (indexA < indexB) {
            if (indexB !== -1) {
              lot = answer.substring(indexB);
              const startAfterB = indexB - 2;

              if (indexCDWA !== -1) {
                product = answer.substring(indexCDWA, startAfterB);
                console.log(product);
              }
            }
          } else if (indexA > indexF) {
            if (indexA !== -1) {
              lot = answer.substring(indexA);

              const startAfterA = indexA - 2;

              if (indexBT !== -1) {
                product = answer.substring(indexBT, startAfterA);
              }
            }
          } else if (indexA < indexF) {
            if (indexF !== -1) {
              lot = answer.substring(indexF);
              const startAfterF = indexF - 2;

              if (indexPCD !== -1) {
                product = answer.substring(indexPCD, startAfterF);
              }
            }
          }

          const url = `/api/IndicatorLot/lotForPackageWashing?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${selectedMethod}`;

          await handleResponse(url);
        }
      } catch (e) {
        toast.error(e.response?.data?.message);
      } finally {
        setModalLoading(false);
      }
    }
  };

  const differsMethod = useMemo(
    () =>
      selectedMaterials
        .filter((m) => m.methodId != selectedMethod)
        .map((m) => <li>{m.name}</li>),
    [selectedMaterials, selectedMethod]
  );

  const finishTray = () => {
    createTray(2);
  };

  const confirmChangeMethod = (data) => {
    setSelectedMethod(data);
    clearFields(false);
  };

  const showValidationErrors = (status) => {
    if (!description.length > 0 || description.trim() === "") {
      toast.error(t("NameRequired", locale) || "The Name field is required");
    }
    if (!selectedMethod || selectedMethod === "") {
      toast.error(
        t("YouMustSelectAMethod", locale) || "You must select a Method"
      );
    }
    if (
      status !== 1 &&
      (!selectedMaterials || selectedMaterials.length === 0)
    ) {
      toast.error(t("YouMustAddMaterials") || "You must add a Materials");
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else if (status == 1) {
      createTray(status);
    } else finishTray();
  };
  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [description, selectedMethod]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [description, selectedMethod, selectedMaterials]
  );

  return (
    <PageContainer
      categoryId={2}
      currentStep={4}
      category={t("WashingSpan")}
      subcategory={t("TraysSpan")}
      title={t("CreateTray")}
      backUrl={"/appmanagetrays"}
      scan={true}
      progress={true}
    >
      {/* <IoIosArrowBack
    className={styles.backIcon}
    onClick={() =>
      Redirect({
            redirect: true,
            path: "/appmanageloads",
          })
    }
    /> */}
      <BarcodeReader onScan={onHandleScan} />

      <ModalLoading category={"washing"} open={modalLoading} />
      <ModalLoading category={"washing"} open={modalEditLoading} />
      {/* <ModalSerial
        showModal={showModal}
        setShowModal={setShowModal}
        option1={responseWithId1}
        option2={responseWithId2}
        onSelectOption={handleSelectOption}
      /> */}

<div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
              <InputQ
                category="washing"
                label={t("NameSpan") || "Name"}
                handleChange={handleChangeDescription}
                value={description}
                containerWidth={"100%"}
                inputRef={descriptionRef}
                required={true}
                errorMessage={
                  t("RequiredFieldError", locale) || "This field is required"
                }
                validate={validate}
                onHandleScan={onHandleScan}
              />
              <div style={{ display: "flex", gap: "10px" }}>
                <SelectQ
                  category="washing"
                  selectValue={selectedMethod}
                  handleSelect={handleSelectMethod}
                  options={methods}
                  label={t("SelectMethodOption", locale) || "Select a Method"}
                  placeholder={
                    t("SelectMethodOption", locale) || "Select a Method"
                  }
                  containerWidth={"50%"}
                  required={true}
                  errorMessage={
                    t("RequiredFieldError", locale) || "This field is required"
                  }
                  validate={validate}
                />
                <SelectQ
                  category="washing"
                  label={trayTypeTranslate}
                  placeholder={trayTypeTranslate}
                  selectValue={packageId}
                  options={packagesTypes}
                  // handleSelect={handlePackageType}
                  handleSelect={handleSelectPredifinedPackage}
                  containerWidth={"50%"}
                />
                {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <label className={styles.printLabel}>Print Label</label>
                <Checkbox
                  type="checkbox"
                  checked={printLabel}
                  onChange={handlePrintLabel}
                />
              </div> */}
              </div>
            </div>

            <DragAndDrop
              filter1={
                <Search
                  category={"washing"}
                  data={packageId ? packageType : materials}
                  handleFilter={handleMaterials}
                  placeholder={
                    t("SearchMaterial", locale) || "Search material..."
                  }
                  searchActive={() => {}}
                  onHandleScan={onHandleScan}
                />
              }
              filter2={
                <Search
                  category={"washing"}
                  data={selectedMaterials}
                  handleFilter={handleSelectedFilteredMaterials}
                  placeholder={
                    t("SearchAddedMaterial", locale) ||
                    "Search added material..."
                  }
                  searchActive={handleSearchActive}
                  onHandleScan={onHandleScan}
                />
              }
              category={"Washing"}
              data={filteredMaterials}
              predifinedData={packageType}
              selectedData={selectedMaterials}
              selectedFilteredData={
                searchActive ? selectedFilteredMaterials : selectedMaterials
              }
              handleSelectedData={handleSelectedMaterials}
              onClearPredifinedData={onClearTray}
              method={parseInt(selectedMethod)}
              leftColumnName={t("BlankEntry7")}
              rightColumnName={t("BlankEntry8")}
            />
          </div>
          <div style={{ width: "30%", minHeight: "100%" }}>
            <CrudIndicator
              category="washing"
              indicators={indicators}
              handleIndicators={handleIndicators}
              onHandleScan={onHandleScan}
              methodId={selectedMethod}
              select={selectedMethod ? true : false}
              condition={"a method"}
            />
          </div>
        </div>
        <div
        style={{
          display: "flex",
          marginTop: "1.75rem",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
          <TextAreaQ
            label={t("ObservationSpan") || "Observations"}
            handleChange={handleChangeObservations}
            value={observations}
            width={"68%"}
            inputRef={observationsRef}
            onHandleScan={onHandleScan}
            category={"washing"}
          />

          <div className={styles.containerButtons}>
            <button
              className={`${styles.button} ${styles.cancel}`}
              onClick={() => clearFields()}
            >
              {t("ClearFields") || "Clear Fields"}
            </button>
            <button
              className={`${styles.button} ${styles.save} ${
                isSaveDisabled || stopButtons ? styles.disabled : ""
              }`}
              onClick={() => handleButtonClick(1)}
            >
              {t("Save") || "Save"}
            </button>
            <button
              className={`${styles.button} ${styles.finish} ${
                isFinishDisabled || stopButtons ? styles.disabled : ""
              }`}
              onClick={() => handleButtonClick(2)}
              // onClick={() => finishTray()}
            >
              {t("FinishTraySpan") || "Finish Tray"}
            </button>
          </div>
        </div>
    </PageContainer>
  );
}
