import React from "react";
import { Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const useStyles = makeStyles((theme) => ({
  ddElement: {
    display: "flex",
    alignItems: "center",
    padding: "4px", // Reducir el padding a 4px en todos los lados
    boxShadow: "none", // Eliminar la sombra
    borderBottom: "3px solid #00B189", // Agregar el borde inferior
    cursor: "grab",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%", // Asegurarse de que el contenido ocupe todo el ancho
  },
  contentTitle: {
    marginLeft: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "6px",
  },
  body: {
    fontSize: "10px",
    margin: "0",
  },
  quantity: {
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "auto",
    paddingRight: "5px",
  },
  dragIcon: {
    cursor: "grab",
    color: "#00B189",
  },
}));

const DDElementRules = ({ obj, name, dragStart }) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={3} 
      className={classes.ddElement}
      draggable
      onDragStart={(e) => dragStart(e, obj)}
    >
      <div className={classes.dragIcon}>
        <DragIndicatorIcon fontSize="small" />
      </div>
      <div className={classes.content}>
        <div className={classes.contentTitle}>
          <Typography className={classes.title}>{name}</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default DDElementRules;
