import React, { useState, useRef } from "react";
import classnames from "classnames";

import propTypes from "prop-types";

import useStyles from "./InputStyles";
import { Checkbox } from "@material-ui/core";
import FixedAlert from "../FixedAlert/FixedAlert";
import t from "../../utils/translation";

export default function Input(props) {

  const classes = useStyles();
  const fullwidth = props.fullwidth !== undefined ? props.fullwidth : true;
  const variant = props.variant || "standard";
  const value =
    typeof props.value !== 'boolean' ?
      props.type === "number"
        ? props.value
        : props.value === undefined || props.value === null
          ? ""
          : props.value.toString() : props.value;

  const [focused, setFocused] = useState(false);
  const [labelWidth, setlabelWidth] = useState(null);
  const [errorValidation, setErrorValidation] = useState("");

  const inputLabelRef = useRef();
  React.useLayoutEffect(() => {
    setlabelWidth(inputLabelRef.current.offsetWidth);
  }, []);

  const outlinePadding = () => (focused || value ? 8 : labelWidth * 0.35 + 14);
  const whiteSpaceWidth = () => (focused || value ? labelWidth * 0.75 + 12 : 0);

  const isFirefox = typeof InstallTrigger !== "undefined";
  const preventNonNumerical = (e) => {
    if (e.target.value.match(/^\d+[\.,]?\d*$/)) {
      props.onChange(e.target.value);
    }
  };
  if (variant === "detail") {
    return (
      <div style={{ padding: props.gutter ? props.gutter : "8px" }}>
        <div
          className={classnames(classes.root, {
            [classes.inputLabelShrink]: value || focused || !props.claro,
            [classes.inputLabelShrinkBright]: props.claro,
            [classes.hasError]: props.error,
            [classes.fullwidth]: fullwidth,
          })}
        >
          <label
            ref={inputLabelRef}
            className={classnames({
              [classes.inputLabel]: !props.claro,
            }, {
              [classes.inputLabelDark]: props.claro,
            }, {
              [classes.inputLabelOutlined]: variant === "outlined",
            })}
          >
            {props.label}
          </label>
          <div className={classes.inputBaseRoot}>
            {
              typeof value === "boolean" &&
              <div key={props.label} className={classnames(
                classes.inputBaseInput,
                classes.inputDetail,
              )}>
                <Checkbox
                  checked={value}
                />
              </div>
            }
            <span
              className={classnames(
                classes.inputBaseInput,
                classes.inputDetail
              )}
            >
              {value}
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "image") {
    return (
      <div style={{ padding: props.gutter ? props.gutter : "8px" }}>
        <div
          className={classnames(classes.root, classes.inputLabelShrink, {
            [classes.hasError]: props.error,
            [classes.fullwidth]: fullwidth,
          })}
        >
          <label
            ref={inputLabelRef}
            className={classnames({
              [classes.inputLabel]: !props.claro,
            }, {
              [classes.inputLabelDark]: props.claro,
            }, {
              [classes.inputLabelOutlined]: variant === "outlined",
            })}
          >
            {props.label}
          </label>
          <div className={classes.inputBaseRoot}>
            <img
              src={"data:image/png;base64, " + value}
              alt={""}
              style={{ width: "100%", marginTop: 20 }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: props.gutter ? props.gutter : "8px" }}>
      <div
        className={classnames(classes.root, {
          [classes.focused]: focused,
          [classes.inputLabelShrink]: value || focused || !props.claro,
          [classes.inputLabelShrinkBright]: props.claro,
          [classes.hasError]: props.error,
          [classes.disabled]: props.disabled,
          [classes.fullwidth]: fullwidth,
        })}
      >
        <label
          ref={inputLabelRef}
          className={classnames({
            [classes.inputLabel]: !props.claro,
          }, {
            [classes.inputLabelDark]: props.claro,
          }, {
            [classes.inputLabelOutlined]: variant === "outlined",
          })}
        >
          {props.label} {props.isRequired ? "*" : ""}
        </label>
        <div
          className={classnames(classes.inputBaseRoot, {
            [classes.inputUnderline]: variant === "standard",
          })}
        >
          <input
            onKeyPress={(e) => {
              //se utiliza para number int.
              if (props.propertySpecial == "inputSpecial") {
                //no permito punto, coma, más y menos.
                if (e.charCode === 46 || e.charCode === 45 || e.charCode === 44 || e.charCode === 43) {
                  props.onChange("not allowed")
                }
              }
            }}
            autoFocus={props.focused}
            spellCheck="false"
            className={classnames(classes.inputBaseInput, {
              [classes.outlinedInputInput]: variant === "outlined",
            })}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false)
              if (props.onFocusOut)
                props.onFocusOut(props.value)
            }}
            onChange={(e) => {
              var validateMaxError = props.maxValue ? (e.target.value > props.maxValue) : false;
              if (validateMaxError) {
                setErrorValidation(t("ValueMustBeLessThan") + props.maxValue)
                setTimeout(() => {
                  setErrorValidation("")
                }, 5000);
                e.target.value = props.maxValue
              }
              else {
                if (props.onChange) {
                  props.onChange(e.target.value)
                }
                setErrorValidation("")
              }
              props?.type === "number"
                ? preventNonNumerical(e)
                : props.onChange(e.target.value)
            }
            }
            value={value}
            type={isFirefox && props.type === "number" ? "text" : props.type}
            min={props.min}
            max={props.max}
            autoComplete=""
            required={props.required}
            disabled={props.disabled}
          />
          {variant === "outlined" && (
            <fieldset
              className={classes.inputOutline}
              style={{ paddingLeft: outlinePadding() }}
            >
              <legend
                className={classes.whiteSpace}
                style={{ width: whiteSpaceWidth() }}
              >
                <span>&#8203;</span>
              </legend>
            </fieldset>
          )}
        </div>
        {!props.noError && (
          <p
            className={classnames(classes.errorMessage, {
              [classes.errorMessageContained]: variant === "outlined",
            })}
          >
            {props.error}
          </p>
        )}
      </div>
      <FixedAlert text={errorValidation} type={"error"} closeErrorCb={() => { setErrorValidation("") }}></FixedAlert>
    </div>

  );
}

Input.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onChange: propTypes.func,
  type: propTypes.string,
  error: propTypes.string,
  variant: propTypes.oneOf(["standard", "outlined", "detail"]),
};
