import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import useLocale from "../../utils/Hooks/useLocale";
import ValidateURL from "../../utils/ValidateURL";
import WashingMenu from "../../pages/Washing/WashingMenu/WashingMenu";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.ABM,
    key: "Washing",
  };
  const screenName = 305;
  const locale = useLocale(screenName);

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: locale }}>
      <NavBar
        screenName={screenName}
        moduleName={90}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <WashingMenu />
      </NavBar>
    </LocalizationContext.Provider>
    // </ValidateURL>
  );
};
