import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";
import BILive from "../pages/BiLive/BILive";
import LicenseQProvider from "../utils/context/HaveLicenseQ";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.BI,
    key: "Live",
  };

  const screenName = 400;
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <LicenseQProvider>
          <NavBar
            screenName={screenName}
            moduleName={10}
            MenuOptions={menu}
            currentPage={currentPage}
          >
            {locale ? <BILive /> : <PageContentLoading />}
          </NavBar>
        </LicenseQProvider>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
