export default function reducer(state, action) {
    switch (action.type) {
      case "SET_CONTENT":
        return {
          ...state,
          loadingContent: false,
          products: action.payload.products,
          washers: action.payload.washers,
          requiredFields: action.payload.requiredFields,
          detergentTypes: action.payload.detergentTypes,
          visualResults: action.payload.visualResults,
          indicatorsCompatiblesTrazanto: action.payload.compatiblesTrazanto
        };
      case "FORMAT_ARRAY_FORM_FIELD": 
        return {
            ...state,
            formFields: [...action.payload.commonFormFields, ...action.payload.extraFormFields],
            commonFormFields: action.payload.commonFormFields,
            detailFields: [...action.payload.detailCommonFields, ...action.payload.extraDetailsFields],
            extraDetailFields: action.payload.extraDetailsFields,
            // productType: action.payload.productType
        };
      case "ADD_WASHER":
        return {
          ...state,
          washers: action.payload.Washers,
          selectedWasher: action.payload.Selected,
          abmWasherVisible: false
        };
      case "SHOW_ABM_WASHER":
        return {
          ...state,
          abmWasherVisible: true
        };
      case "HIDE_ABM_WASHER":
        return {
          ...state,
          abmWasherVisible: false
        };
      default:
        throw new Error();
    }
  }
  