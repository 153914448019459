export const Parameters = {
    CompanySecurityMode: 0,
    CompanyLanguage: 1,
    DaysLicenseExpiration: 2,
    ProteinUmbral: 4,
    WorkingMode: 10,
    ProteinAlertUmbral: 11,
    ProteinActionUmbral: 12,
    RegionalFormat: 13,
    TemperatureUnit: 14,
}

export const ParametersType = {
    Undefined: 0,
    String: 1,
    Mail: 2,
    Int: 3,
    Float: 4,
    Password: 5,
    Text: 6,
    Enum: 7,
}