import React, { createContext, useEffect, useState } from "react";
import { getUserCompany } from "../sessionHandler";
import request from "../request";

const LicenseQContext = createContext();

const LicenseQProvider = ({ children }) => {
  const [haveLicense, setHaveLicense] = useState(false);

  useEffect(() => {
    request()
      .post(`/api/LicenseCode/MyLicenses`, getUserCompany())
      .then((response) => {
        let item = response.data.find(
          (i) =>
            i.licenseProduct.name == "BionovaQ" &&
            i.license.licenseStatusDescription == "Active"
        );
        setHaveLicense(item ? true : false);
      });
  });

  return (
    <LicenseQContext.Provider value={haveLicense}>
      {children}
    </LicenseQContext.Provider>
  );
};

export { LicenseQContext };
export default LicenseQProvider;
