import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    },
    
    pageContainer : {
        margin: "10px auto",
        padding: "40px 30px",
        backgroundColor: "#f2f2f2",
        borderRadius: "10px",
        minHeight: "90vh",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90vw",
      },
      
      page : {
        width: "90%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        height: "auto"
      }
})