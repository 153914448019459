export default function reducer(state, action) {
    switch (action.type) {
      case "forgotPasswordClick": {
        return {
          ...state,
          message: null,
          loginForm: "forgotPassword"
        };
      }
      case "resetPasswordClick": {
        return {
          ...state,
          loginForm: "resetPassword",
          message: null,
          id: action.payload.id
        };
      }
      case "loginClick": {
        return {
          ...state,
          loginForm: "login",
          email: action?.payload?.email ? action.payload.email : state.email,
          message: action?.payload?.message ? action.payload.message : state.message,
        };
      }
      case "setEmail": {
        return {
          ...state,
          message: null,
          email: action.payload
        };
      }
      case "signUpClick": {
        return {
          ...state,
          message: null,
          loginForm: "signup"
        };
      }
      case "seeTermsAndConditions": {
        return {
          ...state,
          loginForm: "eulaForm",
          user: action.payload.User,
          company: action.payload.Company,
          language: action.payload.Language,
          sector: action.payload.Sector
        };
      }
      case 'acceptEula': {
        return {
          ...state,
          loginForm: "signup",
          eulaAccepted: true
        };
      }
      case 'cancelEula': {
        return {
          ...state,
          loginForm: "signup",
          eulaAccepted: false
        };
      }
      case 'setData': {
        return {
          ...state,
          loadingCountries: false,
          countries: action.payload.Countries,
          languages: action.payload.Languages
        };
      }
      default:
        throw new Error();
    }
  }
  