import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import { flatten } from "flat";

// import useStyles from './MessageStyles';

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { verifyColumns, verifyForm, verifyHeaders, verifyFieldsMap, verifyDetailFields } from '../../utils/languagePermittedHandler'
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import Select2 from "../../components/Select2";

export default function Message() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [messages, setMessages] = useState([]);
  const [languages, setLanguages] = useState([])

  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [fieldForm, setFieldForm] = useState([]);
  const [fieldsMap, setFieldMap] = useState([]);
  const [detailFields, setDetailFields] = useState([]);
  const [error, setError] = useState(); 

  useEffect(() => {

    async function fetchData() {
      Promise.all([
        request().get(`/api/module`),
        request().get(`/api/Language`),
      ])
        .then(([response, languages]) => {
          setModules(
            response.data.reduce((modulesOptions, module) => {
              modulesOptions.push([module.id, module.description]);
              return modulesOptions;
            }, [])
          );
          setLoadingContent(false);
          setLanguages(languages.data)
        })
        .catch(error => console.log(error));
    }

    fetchData()
  }, []);

  useEffect(() => {
    const headers = ["Type", "ID"]
    const columns = ["messageTypeDescription", "messageName"]
    const visibleFields = [
      { label: "Module", name: "moduleDescription" },
      { label: "Message Type", name: "messageTypeDescription" }
    ]
    setHeaders(verifyHeaders(headers, languages))
    setFieldForm(verifyForm(languages))
    setColumns(verifyColumns(columns, "message", languages))
    setFieldMap(verifyFieldsMap("message", languages))
    setDetailFields(verifyDetailFields(visibleFields, "message", languages))
    if (selectedModule !== "") {
      setLoadingData(true);
      request()
        .get(`/api/message?ModuleId=${selectedModule}`)
        .then(response => {
          setMessages(response.data.map(x => flatten(x)));
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        })
        .catch(error => console.log(error));
    }
  }, [selectedModule]);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/message?ModuleId=${selectedModule}`)
      .then(response => {
        setMessages(response.data.map(x => flatten(x)));
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch(error => console.log(error));
  };

  const resetValue = (row) => {
    request().get(`/api/Message/resetMessage?message=${row.messageName}`).then(() => {
      updateData();
    }).catch(err => {
      setError(err?.response?.data?.message)
    })
  }

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
        <div className={"row mx-0 justify-content-md-between"}>
          <div className={"col-12 col-md-6 px-20"}>
            <Select2
              label={"Module"}
              options={modules}
              emptyOptionText={"Select a Module"}
              variant={"outlined"}
              value={selectedModule}
              onChange={val => setSelectedModule(val)}
            />
          </div>
        </div>
      </div>
      {messages && (
        <CRUD
          tableActions={[
            {
              icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
              tooltip: "Reset",
              action: resetValue,
            }
          ]}
          newOption={false}
          deleteOption={false}
          data={messages}
          loadingData={loadingData}
          updateData={updateData}
          title={"Message"}
          url={"/api/message/save"}
          method={"post"}
          columns={columns}
          headers={headers}
          idField={"id"}
          fieldsMap={fieldsMap}
          formFields={fieldForm}
          detailFields={detailFields}
        />
      )}
    </div>
  );
}

