import React, { useEffect, useState } from "react";
import styles from "./LoadWashingDetails.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";
import df from "../../utils/dateFormater";
import packageImg from "../../assets/images/MENU PACKAGE.svg";
import packageInLoad from "../../assets/images/PACKAGE IN A LOAD.svg";
import ColumnQ from "../ColumnQ/ColumnQ";
import request from "../../utils/request";
import loadImg from "../../assets/images/MENU LOAD.svg";
import { Tooltip } from "@material-ui/core";
import washerIcon from "../../assets/images/purpleWashing.svg";
import PackageCard from "../CicleModal/PackageCard";
import SpinnerQ from "../SpinnerQ/SpinnerQ";
import t from "../../utils/translation";
import { CiCalendar, CiTimer, CiUser } from "react-icons/ci";
import { CiCircleInfo } from "react-icons/ci";
import { GoLink, GoUnlink } from "react-icons/go";
import { IoBookOutline, IoReload } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { FaTemperatureHigh } from "react-icons/fa";
import ModalLoading from "../ModalLoading/ModalLoading";
import biologicalImg from "../../assets/images/BIOLOGICO.svg";
import chemicalImg from "../../assets/images/QUIMICO.svg";
import indicatorsImg from "../../assets/images/INDICATORS.svg";
import PCDItemCycle from "../PCDItemCycle/PCDItemCycle";
import TrayCard from "../TraysCard/TrayCard";

export default function ManageDetail({ loadId, locale }) {
  const [load, setLoad] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  const getLoadId = async () => {
    setModalLoading(true);
    try {
      await request()
        .get(`/api/Chargewashing/chargewashingdto?chargewashingId=${loadId}`)
        .then((response) => {
          const data = response.data;
          setLoad(data);
        });
    } catch {
    } finally {
      setModalLoading(false);
    }
  };

  const status = (value) => {
    return value === 1 ? t("BuildingWashingLoadSpan") : t("ToWashSpan");
  };

  useEffect(() => {
    getLoadId();
    return () => {
      setLoad([]);
    };
  }, []);

  return (
    <div className={styles.container}>
      <ModalLoading category={"washing"} open={modalLoading} />
      <div className={styles.detailHeader}>
        <div className={styles.headerContent}>
          <div className={styles.headerImage}>
            <img src={loadImg} alt="Load Image" />
          </div>
          <div className={styles.headerText}>
            <div className={styles.headerTop}>
              <p className={styles.headerTitle}>
                {load.chargeNumber} - {load.description}
              </p>
              <div className={styles.detailItem}>
                <div className={styles.detailItem} style={{ gap: "10px" }}>
                  {/* <img
                className={styles.iconImg}
                alt={status(load.chargeStatus)}
                src={findImg(load.chargeStatus)}
              /> */}
                  <span className={styles.infoStatus}>
                    {status(load.chargeStatus)}
                  </span>
                </div>
                {/* {load.observations && (
              <Tooltip
                title={
                  <div className={styles.detailItem}>
                    <span className={styles.info}>{load.observations}</span>
                  </div>
                }
              >
                <CiCircleInfo className={styles.iconInfo} />
              </Tooltip>
            )} */}
              </div>
            </div>
            <div className={styles.headerDetails}>
              <div className={styles.detailItem}>
                <img
                  className={styles.iconSterilizer}
                  src={washerIcon}
                  alt="sterilizer icon"
                />
                <span
                  className={styles.info}
                >{`${load.washerName} - ${load.methodName}`}</span>
              </div>
            </div>
            <div className={styles.headerBottom}>
              <div className={styles.detailItem}>
                <CiCalendar className={styles.iconStyle} />
                <span className={styles.info}>
                  {df(load.creationDate, "dateTime")}
                </span>
              </div>
              <div className={styles.detailItem}>
                <CiUser className={styles.iconStyle} />
                <span className={styles.info}>{load.operator}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("TraysSpan")}</p>
          <div className={styles.packagesContainer}>
            {load.packagesWashing &&
              load.packagesWashing?.map((pkg) => (
                <TrayCard key={pkg.packageWashingId} pkg={pkg.packageWashing} />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.packagesSection}>
          <p className={styles.infoTitle}>{t("IndicatorSpan")}</p>

          <div className={styles.imageTitleContainer}>
            <img
              src={chemicalImg}
              alt="chemicalIndicator"
              className={styles.image}
            />
            <h2 className={styles.sectionSubTitle}>
              {" "}
              {t("ChemicalIndicatorSpan")}
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <div className={styles.indicatorsContainer}>
              {load.indicatorsWashing &&
              load.indicatorsWashing.filter(
                (i) => !i.indicator.name.toUpperCase().includes("BT")
              ).length === 0 ? (
                <div className={styles.noDataMessage}>
                  {t("NoChemicalIndicatorSpan")}
                </div>
              ) : (
                load.indicatorsWashing &&
                load.indicatorsWashing
                  .filter((i) => !i.indicator.name.toUpperCase().includes("BT"))
                  .map((indicator) => (
                    <div className={styles.indicatorItem} key={indicator.id}>
                      <IndicatorLabel
                        category={"washing"}
                        zone={indicator.indicatorModelArea.description}
                        backgroundColor={"white"}
                        name={indicator.indicator.name}
                        lot={indicator.lot}
                        serial={indicator.serial}
                        quantity={indicator.quantity}
                        expirationDate={indicator.indicatorExpirationDate}
                        border={true}
                      />
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>

      {load.observations && (
        <div className={styles.detailInfo}>
          <div className={styles.packagesSection}>
            <p className={`${styles.infoTitle}`}>Observation</p>
            <div className={styles.observationContainer}>
              <p className={styles.observation}>{load.observations}</p>
              <div className={styles.observationInfo}>
                <p>{df(load.creationDate, "dateTime")}</p>
                <p>{load.creationUserName}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
