import React, { useEffect, useState } from "react";
import DDColumn from "../DragAndDrop/DDColumn/DDColumn.jsx";
import DDRow from "../DragAndDrop/DDRow/DDRow.jsx";
import styles from "./DragAndDropRules.module.css";

import { Grid, Paper } from "@material-ui/core";
import DDElementeRules from "./DDElementRules/DDElementeRules";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel.jsx";


const DragAndDropRules = ({
  data,
  selectedFilteredData,
  selectedData,
  handleSelectedData,
  filter1,
  filter2,
  leftColumnName,
  rightColumnName,
  // doubleClick
}) => {
  // const [packagesList, setPackageList] = useState([]);

  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const paperStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //justifyContent: "flex-end",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    overflowY: "auto", // Añadido para mostrar la barra de desplazamiento cuando sea necesario
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    const item = data.find((item) => item.id == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };


  const moveItem = (itemID, columnID) => {
    const item = data.find((item) => item.id == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");

    const newState = selectedData.filter((material) => material.id != itemID);
    handleSelectedData(newState);
  };


  const onDelete = (id) => {
    const newState = selectedData.filter((material) => material.id != id);
    handleSelectedData(newState);
  };

 
  const packageExist = (id) => {
    return selectedData.find((m) => m.id === id);
  };

  useEffect(() => {
    if (selectedData.length > 0 && selectedData.length == 0)
      handleSelectedData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    handleSelectedData(selectedData);
  }, [selectedData]);

  return (
    <>
    <div className={styles.container}>
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{leftColumnName}</p>
            {filter1}
            <DDColumn id={"Drag"} dragOver={draggingOver} drop={onDropDelete} rule={true}>
              {data &&
                data
                  .filter((d) => !packageExist(d.id))
                  .map((p) => (
                    <DDRow
                      key={p.id}
                      obj={p}
                      name={`${p.name}`}
                      dragStart={startDragColumn}
                      doubleClick={doubleClick}
                      columnId={true}
                      rule={true}
                    />
                  ))}
            </DDColumn>
          </div>
        </div>
        <div className={styles.quantityPaper}></div>
        <div className={styles.rightColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{rightColumnName}</p>

            {filter2}

            <DDColumn id={"Drop"} dragOver={draggingOver} drop={onDrop} rule={true}>
              {selectedFilteredData.map((p) => (
                <DDRow
                  key={p.id}
                  obj={p}
                  name={`${p.name}`}
                  dragStart={startDrag}
                  onDelete={onDelete}
                  doubleClick={onDelete}
                  columnId={null}
                  rule={true}
                />
              ))}
            </DDColumn>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default DragAndDropRules;
