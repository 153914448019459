import React, { useEffect, useState } from 'react';
import request from '../../utils/request';
import Chart from "./Chart";
import "./Dashboard.css";
import totalChartState from "./InitialState/LineChartInitialState";
import chartState from "./InitialState/ChartInitialState";
import ScrollContainer from "react-indiana-drag-scroll";
import t from "../../utils/translation";
import df from "../../utils/dateFormater";
import LineChart from "./LineChart";
import { Box, CircularProgress } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';

const initialState = {
  loadingCharts: true,
  totalPieChart: {
    visible: false,
  },
  totalLineChart: {
    visible: false,
  }
}

const Panel = ({ userPanel, translations }) => {
  const theme = useTheme();
  const [state, setState] = useState(initialState);
  const template = totalChartState(t("CyclesPerDay", translations));
  const templateCakeChart = chartState(t("ChartDrawLabel", translations));

  const mapResponseToPieCharts = (sterilizerSeries) => {
    const graficos = [];
    sterilizerSeries.forEach((sterilizerSerie) => {
      graficos.push({
        title: sterilizerSerie.machine,
        serie: sterilizerSerie.series.map((serie) => {
          return {
            y: serie.cantidad,
            name: serie.result,
            color: serie.color
          };
        }),
      });
    });
    return graficos;
  };

  const calculateTotalPieChartSerie = (series) => {
    const totalChartSerie = [
      {
        serie: [],
      },
    ];
    series.forEach((serie) => {
      serie.series.forEach((serie) => {
        let state = totalChartSerie[0].serie.find(
          (x) => x.state === serie.resultEnum || x.state === serie.resultCHEEnum
        );
        if (state) {
          state.y += serie.cantidad;
        } else {
          totalChartSerie[0].serie.push({
            y: serie.cantidad,
            name: serie.result,
            state: serie.resultEnum || serie.resultCHEEnum,
            color: serie.color
          });
        }
      });
    });
    return totalChartSerie;
  };


  const mapResponseToLineCharts = (data) => {
    const lineCharts = data.result.map((value) => {
      return {
        title: value.machine,
        categories: value.series.map((serie) => {
          return df(serie.result, "date");
        }),
        data: [
          {
            data: value.series.map((serie) => {
              return serie.cantidad;
            }),
          },
        ],
      };
    });
    return lineCharts;
  };


  const calculateTotalLineChartSerie = (series) => {
    let seriesFormatted = [];
    series.forEach((serie) => {
      serie.series.forEach((serie) => {
        let date = new Date(serie.result);
        let dateFormatted = df(date, "date");
        let exist = seriesFormatted.find((x) => x.dateFormatted === dateFormatted);
        if (exist) {
          exist.cantidad += serie.cantidad;
        } else {
          seriesFormatted.push({
            cantidad: serie.cantidad,
            date: date,
            dateFormatted: dateFormatted
          });
        }
      });
    });
    seriesFormatted.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });
    return {
      categories: seriesFormatted.map((serie) => {
        return serie.dateFormatted;
      }),
      data: [
        {
          data: seriesFormatted.map((serie) => {
            return serie.cantidad;
          }),
        },
      ],
    };
  };

  useEffect(() => {
    request()
      .get(
        `/api/${userPanel.panel.controller}/${userPanel.panel.method}`
      )
      .then((response) => {
        let graficos = [];
        let totalPieChartSerie = [];
        if (response.data.lineChart) {
          const lineCharts = mapResponseToLineCharts(response.data);
          const totalLineChartSerie = calculateTotalLineChartSerie(
            response.data.result
          );
          setState({
            ...state,
            loadingCharts: false,
            lineCharts: lineCharts,
            totalLineChart: {
              ...totalLineChartSerie,
              visible: true,
            },
          });
        } else {
          graficos = mapResponseToPieCharts(response.data);
          totalPieChartSerie = calculateTotalPieChartSerie(response.data);
          response.panelUserSterilizer = userPanel.panelsUserSterilizer;
          setState({
            ...state,
            loadingCharts: false,
            pieCharts: graficos,
            totalPieChart: {
              visible: true,
              serie: totalPieChartSerie,
            },
          });
        }
      });
  }, [])

  return (
    <>
      <ScrollContainer className="scroll-container">
        <div className="row flex-row flex-nowrap">
          {/* TOTAL LINE CHART */}
          {state.totalLineChart && state.totalLineChart.visible && (
            <div className="col-8">
              <LineChart
                fontColor={theme.palette.terragene.lightThird}
                backgroundColor={theme.palette.terragene.darkSecondary}
                loadingCharts={state.loadingCharts}
                data={state.totalLineChart.data}
                categories={state.totalLineChart.categories}
                userConfig={template.userConfig}
                titleName={t("AllResultsLabel", translations)}
                translations={translations}
              />
            </div>
          )}

          {/* LINE CHARTS */}
          {state.lineCharts &&
            state.lineCharts.map((lineChart, i) => {
              return (
                <div className="col-8" key={i}>
                  <LineChart
                    loadingCharts={state.loadingCharts}
                    data={lineChart.data}
                    categories={lineChart.categories}
                    userConfig={template.userConfig}
                    titleName={lineChart.title}
                    translations={translations}
                  />
                </div>
              );
            })}

          {/* TOTAL PIE CHART */}
          {state.totalPieChart.serie &&
            state.totalPieChart.visible && (
              <>
                <Box
                  sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between',
                    flexWrap:'wrap',
                    alignContent:'center'
                  }}
                >
                  <Chart
                    fontColor={theme.palette.terragene.lightThird}
                    backgroundColor={theme.palette.terragene.darkSecondary}
                    loadingCharts={state.loadingCharts}
                    data={state.totalPieChart.serie[0].serie}
                    userConfig={templateCakeChart.userConfig}
                    titleName={t("AllResultsLabel", translations)}
                    translations={translations}
                  />
                </Box>
              </>
            )}

          {/* PIE CHARTS */}
          {state.pieCharts &&
            state.pieCharts.map((chart, i) => {
              return (
                <Box
                sx={{
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'space-between',
                  flexWrap:'wrap'
                }}
              >
                  <Chart
                    chartType={"pie"}
                    loadingCharts={state.loadingCharts}
                    data={chart.serie}
                    userConfig={templateCakeChart.userConfig}
                    titleName={chart.title}
                    translations={translations}
                  />
                </Box>
              );
            })}
        </div>
      </ScrollContainer>
      {state.loadingCharts && (
        <div style={{ margin: 100 }}>
          <CircularProgress size={25}></CircularProgress>
        </div>
      )}
    </>
  );
};

export default Panel;