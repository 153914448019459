import React from 'react';
import LocalizationContext from '../utils/LocalizationContext';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import {useParams} from 'react-router-dom'

const locale = {
	privacyPolicyForm: {
		title: 'Privacy policy'
	}
}

export default () => {
	const params = useParams();
	return (
		<LocalizationContext.Provider value={locale}>
			<PrivacyPolicy />
		</LocalizationContext.Provider>
	)
};