import React, { useContext, useEffect, useMemo, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableFilterQ from "../../components/TableBionovaQ/TableFilterQ/TableFilterQ.jsx";

import PackageDetail from "../../components/PackageDetail/PackageDetail.jsx";
import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import filterByProperties from "../../utils/filterByProperties.js";
import styles from "./ConsultPackage.module.css";
import pkgImg from "../../assets/images/PACKAGE IN A LOAD.svg";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import parseDf from "../../utils/parseDf.js";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../utils/printTicket.js";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import Search from "../../components/Search/Search.js";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import CircularProgressWithLabel from "../../components/CircularProgressWhitLabel/CircularProgressWithLabel.jsx";
import CircularProgressIndicators from "../../components/CircularProgressIndicators/CircularProgressIndicators.jsx";
import t from "../../utils/translation.js";

export default function ConsultPackage() {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [loads, setLoads] = useState([]);
  const [loadDetail, setLoadDetail] = useState(null);
  const [loadStatus, setloadStatus] = useState(null);
  const [indicators, setIndicators] = useState(null);
  const [charges, setCharges] = useState(null);
  const [loadingPackages, setLoadingPackages] = useState(false);
  // const [indicators, setIndicators] = useState({
  //   indicators: [],
  //   readIndicators: [],
  //   unreadIndicators: [],
  // });
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");

  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState("");
  const [packageNumber, setPackageNumber] = useState("");
  const [method, setMethod] = useState("");
  const [methods, setMethods] = useState([]);
  const [name, setName] = useState("");

  const handleIndicators = (value) => {
    setIndicators(value);
  };

  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  // const getPackages = async () => {
  //   await request()
  //     .get(`/api/package`)
  //     .then((response) => {
  //       setPackages(
  //         response.data.filter(
  //           (p) => p.packageStatus === 3
  //         )
  //       );
  //     });
  // };

  // const getPackages = async () => {
  //   setLoadingPackages(true);
  //   try {
  //     const response = await request().get(`/api/package`);
  //     setPackages(
  //       response.data
  //     );
  //   } catch (error) {
  //     console.error("Error fetching packages:", error);
  //   } finally {
  //     setLoadingPackages(false);
  //   }
  // };

  // const getPackages = async () => {
  //   setLoadingPackages(true);
  //   try {
  //     const response = await request().get(`/api/Package/list?page=${1}`);
  //     setPackages(
  //       response.data.packageList
  //     );
  //   } catch (error) {
  //     console.error("Error fetching packages:", error);
  //   } finally {
  //     setLoadingPackages(false);
  //   }
  // };

  const getPackages = async () => {
    setLoadingPackages(true);

    try {
      const response = await request().get(
        `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  const getMethods = () => {
    request()
      .get("/api/method/1")
      .then((res) =>
        setMethods(
          res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
        )
      );
  };
  const handleSearch = (e) => {
    setName(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${
            e.target.value
          }&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };
  const handlePackageNumber = (e) => {
    setPackageNumber(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${
            e.target.value
          }&status=${status}&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };
  const handleMethod = (e) => {
    setMethod(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${name}&method=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${
            e.target.value
          }&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };

  useEffect(() => {
    getMethods();
  }, []);

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}&isInCharge=${true}`
      );

      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  // const getPackageIndicators = async (packageId) => {
  //   await request()
  //     .get(`/api/package/info?packageId=${packageId}`)
  //     .then((response) => {
  //       console.log(response.data)
  //       handleIndicators({
  //         indicators: response.data.indicators,
  //         readIndicators: indicators.filter(
  //           (i) =>
  //             i.readings &&
  //             i.readings.length > 0
  //         ),
  //         unreadIndicators: indicators.filter(
  //           (i) =>
  //             !i.readings ||
  //             i.readings.length === 0
  //         ),
  //       });
  //     })
  //     .catch((error) => console.log(error));
  // };

  // const getPackageIndicators = async (packageId) => {
  //   try {
  //     // Realiza la solicitud al endpoint
  //     const response = await request().get(`/api/package/info?packageId=${packageId}`);

  //     // Extrae los indicadores del response.data
  //     const indicators = response.data.indicators; // Asegúrate de que 'indicators' sea la clave correcta

  //     // Filtra los indicadores con base en el contenido de 'readings'
  //     const readIndicators = indicators.filter(indicator => indicator.readings && indicator.readings.length > 0);
  //     const unreadIndicators = indicators.filter(indicator => !indicator.readings || indicator.readings.length === 0);

  //     // Imprime los resultados para depuración (opcional)
  //     // console.log('Read Indicators:', readIndicators);
  //     // console.log('Unread Indicators:', unreadIndicators);

  //     // Guarda los indicadores en el estado o realiza cualquier otra acción necesaria
  //     setIndicators({
  //       readIndicators,
  //       unreadIndicators
  //     });

  //   } catch (error) {
  //     // Maneja el error en caso de que la solicitud falle
  //     console.error('Error fetching package indicators:', error);
  //   }
  // };

  const getStatusName = (packageId, packageValue) => {
    // Encuentra la carga asociada al paquete
    // const load = findLoadForPackage(packageId);

    // if (load && load.chargeStatus === 4 && packageValue === 3) {
    //   packageValue = 4;
    // }

    // Determina el estado del paquete
    switch (packageValue) {
      case 1:
        return t("Assembling", locale);
      case 2:
        return t("ReadyForLoad", locale);
      case 3:
        return t("InLoad", locale);
      case 4:
        return t("Sterilized", locale);
      default:
        return "Unknown Status";
    }
  };

  // const getStatusName = (id) => {
  //   return id === 1 ? "Assembling" : "Ready for Load";
  // };

  // const getStatusColor = (id) => {
  //   return id === 3 ? "green" : "green";
  // };

  // const findLoadForPackage = (packageId) => {
  //   // console.log("Finding load for package:", packageId);
  //   // Recorre cada carga
  //   for (const load of loads) {
  //     // Verifica que 'load.packages' esté definido y sea un array
  //     if (Array.isArray(load.packages)) {
  //       // Busca en 'load.packages' si algún paquete tiene el 'packageId' que coincida
  //       const matchedPackage = load.packages.find(pkg => pkg.packageId === packageId);
  //       if (matchedPackage) {
  //         return load;
  //       }
  //     }
  //   }
  //   return null; // No se encontró la carga
  // };

  const handlePackageDetail = (packageGeneralId) => {
    let item = packages.find((p) => p.packageGeneralId === packageGeneralId);
    setPackageDetail(item);

    // const relatedLoad = findLoadForPackage(packageGeneralId);
    // setLoadDetail(relatedLoad);

    // const packageIndicator = getPackageIndicators(packageGeneralId)
    // setIndicators(packageIndicator)

    setIsDetailVisible(true); // Ocultar la tabla
  };

  // useEffect(() => {
  //   getPackages();
  // }, []);

  // const actualizedData = useMemo(() => {
  //   if (!isNaN(Date.parse(dateFrom)) || !isNaN(Date.parse(dateFrom))) {
  //     return filteredData.filter(
  //       (p) =>
  //         parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) >=
  //           parseDf(df(dateFrom, "date"), getRegionalFormat().dateFormat) &&
  //         parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) <=
  //           parseDf(df(dateTo, "date"), getRegionalFormat().dateFormat) &&
  //         (status ? p.packageStatus == status : true)
  //     );
  //   } else if (status) {
  //     return filteredData.filter((p) => p.packageStatus == status);
  //   } else {
  //     return filteredData;
  //   }
  // }, [dateTo, dateFrom, filteredData, status]);

  //   const actualizedData = useMemo(() => {
  //     let filtered = [];

  //     if (!isNaN(Date.parse(dateFrom)) && !isNaN(Date.parse(dateTo))) {
  //         filtered = filteredData.filter(
  //             (p) =>
  //                 parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) >=
  //                     parseDf(df(dateFrom, "date"), getRegionalFormat().dateFormat) &&
  //                 parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) <=
  //                     parseDf(df(dateTo, "date"), getRegionalFormat().dateFormat) &&
  //                 (status ? p.packageStatus == status : true)
  //         );
  //     } else if (status) {
  //         filtered = filteredData.filter((p) => p.packageStatus == status);
  //     } else {
  //         filtered = filteredData;
  //     }

  //     return filtered.sort((a, b) => {
  //         const dateA = parseDf(df(a.date, "date"), getRegionalFormat().dateFormat);
  //         const dateB = parseDf(df(b.date, "date"), getRegionalFormat().dateFormat);

  //         return dateB - dateA;
  //     });
  // }, [dateTo, dateFrom, filteredData, status]);

  const {
    currentPage,
    currentItems,
    // totalPages,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    packages,
    ["packageNumber", "date", "description", "methodId", "packageStatus"],
    totalPages
  );

  useEffect(() => {
    getPackages(itemsPerPage, currentPage);
  }, [itemsPerPage, currentPage]);

  const arrayStatus = [
    { value: 1, name: t("Assembling", locale) },
    { value: 2, name: t("ReadyForLoad", locale) },
    { value: 3, name: t("InLoad", locale) },
    { value: 4, name: t("Sterilized", locale) },
  ];

  return (
    <PageContainer
      categoryId={1}
      category={t("SterilizationSpan")}
      backUrl={"/appsterilization"}
      title={t("ConsultPackage", locale)}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handlePackageNumber}
                placeholder={t("NumberSpan")}
                label={t("NumberSpan")}
                value={packageNumber}
                containerWidth="100%"
              />
              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                items={packages}
                property={"Name"}
                handleChange={handleSearch}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
              />

              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={method}
                containerWidth={"100%"}
              />
              <SelectQ
                placeholder={t("SelectStatusOption")}
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={status}
                containerWidth={"100%"}
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true} progress={true}>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageNumber")}
                >
                  {t("NumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("date")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("description")}
                >
                  {t("NameSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingPackages ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((p) => (
                    <TableRowQ
                      progress={
                        <CircularProgressIndicators
                          totalIndicators={p.totalIndicators}
                          vinculatedIndicators={p.vinculatedIndicators}
                        />
                      }
                      key={p.packageGeneralId}
                      actions={true}
                      detail={p.packageStatus}
                      // edit={p.packageStatus === 3}
                      // print={p.packageStatus === 2}
                      // deleteRow={true}
                      onClick={() => handlePackageDetail(p.packageGeneralId)}
                      onDetail={() => handlePackageDetail(p.packageGeneralId)}
                      // onEdit={() => onEdit(p.id)}
                      // onDelete={() => onDelete(p.id)}
                      // doubleClick={() => p.packageStatus === 1 && onEdit(p.id)}
                    >
                      <TableRowCellQ>
                        <span style={{ fontWeight: 500, fontSize: "0.875rem" }}>
                          {p.packageNumber}
                        </span>
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={df(p.date, "date")}
                          maxLength={15}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.description} maxLength={15} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.methodName} maxLength={15} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        {/* <span
          className={`${styles.statusColor} ${styles[getStatusColor(p.packageStatus)]}`}
        ></span> */}
                        <TruncatedText
                          text={getStatusName(
                            p.packageGeneralId,
                            p.packageStatus
                          )}
                          maxLength={15}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}
              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                getPackages={getPackages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <PackageDetail
              packageId={packageDetail.packageGeneralId}
              locale={locale}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
