import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    },

    parameterRoot: {
        margin: '8px 0 16px',
    },

    parameterActionsRoot: {
        '& button': {
            margin: '0 16px 0 0'
        }
    },

    ParametersAllowedResetDefault: {
        padding: '10px 20px 10px 20px',
        margin: '10px'
    },

    ParametersNotAllowedResetDefault: {
        padding: '0 20px 0 20px',
        margin: '10px'
    },

    titleSectionParameters: {
        borderBottom: '1px solid',
        margin: '0px 0 15px 0',
        padding: '0 0 2px 0',
        borderColor: theme.palette.terragene.grey,
        fontSize: '1.1rem',
        color: theme.palette.terragene.grey
    },

    titleSectionWorkingMode: {
        borderBottom: '1px solid',
        margin: '10px 0 10px 0',
        padding: '0 0 2px 0',
        borderColor: theme.palette.terragene.grey,
        fontSize: '1.1rem',
        color: theme.palette.terragene.grey
    },
}))