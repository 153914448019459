import { useState } from "react";
import { getUserId, getTranslation, setTranslation } from "../sessionHandler";
import request from "../request";

const useLocale = screenId => {
  const [locale, setLocale] = useState(getTranslation(screenId))
  
    if (!locale) {
      request().get(`/api/screen/getjson?ScreenNameId=${screenId}&UserLoggedId=${getUserId()}`)
        .then(response => {
          setTimeout(() => {
            setTranslation(screenId, response.data); 
            setLocale(response.data);
          }, 500)
        })
        // .catch(error => { console.log(error.response) });
    }

  return locale
};

export default useLocale;
