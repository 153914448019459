import React, { useState, useEffect } from "react";
import CRUD from "../../components/CRUD";
import request from "../../utils/request";
import _ from "lodash";

export default function GlobalModels() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [machineMakes, setMachineMakes] = useState([]);
  const [methods, setMethods] = useState([]);
  const [allMethods, setAllMethods] = useState([]);

  const MachineTypes = [
    [0, "Select a Machine Type"],
    [1, "STERILIZER"],
    [2, "WASHER"],
    [3, "DISINFECTOR"],
  ];

  useEffect(() => {
    request()
      .get("/api/MachineMake")
      .then((types) => {
        setMachineMakes(
          types.data.reduce(
            (typesList, type) => {
              typesList.push([type.id, type.brand.toUpperCase()]);
              return typesList;
            },
            [[null, "Choose a Brand"]]
          )
        );
      })
      .catch((error) => console.log(error.response));
  }, []);

  machineMakes.sort(function (a, b) {
    if (a[0] == null || b[0] == null) {
      return 0;
    }

    if (a[1] > b[1]) {
      return 1;
    }
    if (a[1] < b[1]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  useEffect(() => {
    request()
      .get("/api/Method")
      .then((types) => {
        setAllMethods(types.data);
        setMethods(
          types.data.reduce(
            (typesList, type) => {
              typesList.push([type.id, type.name.toUpperCase()]);
              return typesList;
            },
            [[null, "Choose a Method"]]
          )
        );

        updateData();
      })
      .catch((error) => console.log(error.response));
  }, [machineMakes]);

  const filterMethods = (mtypeId) => {
    let filteredMethods = allMethods.filter((m) => m.machineType == mtypeId);
    setMethods(
      filteredMethods.reduce(
        (typesList, type) => {
          typesList.push([type.id, type.name.toUpperCase()]);
          return typesList;
        },
        [[null, "Choose a Method"]]
      )
    );
  };

  /*useEffect(() => {
    updateData();
  }, [machineMakes]);*/

  const updateData = () => {
    setLoadingData(true);

    request()
      .get(`/api/MachineModel`)
      .then((response) => {
        const modelsMapped = response.data.reduce((readingsList, reading) => {
          let machineMakeBrandFromArr = "";
          let methodNameFromArr = "";
          let machineTypeNameFromArr = "";
          for (let i = 0; i < machineMakes.length; i++) {
            if (machineMakes[i][0] === reading.machineMakeId) {
              machineMakeBrandFromArr = machineMakes[i][1];
            }
          }

          for (let i = 0; i < methods.length; i++) {
            if (reading.methodId && methods[i][0] === reading.methodId) {
              methodNameFromArr = methods[i][1];
            }
          }

          for (let i = 0; i < MachineTypes.length; i++) {
            if (MachineTypes[i][0] === reading.machineType) {
              machineTypeNameFromArr = MachineTypes[i][1];
            }
          }

          readingsList.push({
            ...reading,
            machineMakeBrand: machineMakeBrandFromArr,
            methodName: methodNameFromArr,
            machineTypeName: machineTypeNameFromArr,
          });

          return readingsList;
        }, []);
        setData(modelsMapped);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  //console.log(data);
  return (
    <React.Fragment>
      <CRUD
        data={data}
        loadingData={loadingData}
        url={"/api/MachineModel"}
        updateData={updateData}
        title={"Models"}
        columns={["machineMakeBrand", "name", "description", "methodName"]}
        headers={["Brands", "Models", "Description", "Method"]}
        idField={"id"}
        fieldsMap={[
          "machineMakeId",
          "name",
          "description",
          "observations",
          "machineType",
          "methodId",
        ]}
        formFields={[
          {
            label: "Brand",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: machineMakes,
            required: true,
          },

          {
            label: "Name",
            class: "col-12 col-md-6 px-0",
            required: true,
            regex: true,
          },
          {
            label: "Description",
            class: "col-12 col-md-6 px-0",
            regex: true,
          },
          {
            label: "Observations",
            class: "col-12 col-md-6 px-0",
            regex: true,
          },
          {
            label: "Machine Type",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: MachineTypes,
            required: true,
            onSelectedChange: (selected) => {
              filterMethods(selected);
            },
          },
          {
            label: "Method",
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: methods,
          },
        ]}
        detailFields={[
          { label: "Brand", name: "machineMakeBrand" },
          { label: "Model", name: "name" },
          { label: "Description", name: "description" },
          { label: "Observations", name: "observations" },
          { label: "Machine Type", name: "machineTypeName" },
          { label: "Method", name: "methodName" },
        ]}
        baseObject={{
          machineModel: {
            id: "",
            name: "",
            description: "",
            observations: "",
            machineMakeId: "",
            machineMake: { id: null },
            methodId: "",
            method: { id: null },
            machineType: "",
          },
        }}
      />
    </React.Fragment>
  );
}
