import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff", // Color de fondo del modal
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    backgroundColor: "#00b189", // Color de fondo del botón
    color: "#fff", // Color del texto del botón
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#008672", // Cambia el color de fondo al pasar el ratón
    },
  },
}));

const ModalSerial = ({
  showModal,
  setShowModal,
  option1,
  option2,
  onSelectOption,
}) => {
  const [bothRequestsSucceeded, setBothRequestsSucceeded] = useState(false);

  const classes = useStyles();

  const handleSelectOption = (option) => {
    onSelectOption(option);
    setShowModal(false);
  };

  useEffect(() => {
    if (option1 && option2) {
      // Verificar si ambas solicitudes se han completado con éxito
      if (option1.id && option2.id) {
        setBothRequestsSucceeded(true);
      }
    }
  }, [option1, option2]);

  return (
    <Modal
      open={showModal && bothRequestsSucceeded}
      onClose={() => setShowModal(false)}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <h5>Hay un indicador y un material con el mismo Serial</h5>
        <h6>Selecciona una opción:</h6>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => handleSelectOption(option1)}
        >
          Indicator
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => handleSelectOption(option2)}
        >
          Material
        </Button>
      </div>
    </Modal>
  );
};

export default ModalSerial;
