import React, { useState, useContext } from 'react';
import useStyles from './HistoricalExportFilterStyles';
import LocalizationContext from '../../utils/LocalizationContext';
import t from '../../utils/translation';

import Modal from "../Modal";
import Button from "../Button";
import Select from '../Select';
import DateInput from '../DateInput';
import Input from "../../components/Input";
import moment from "moment-timezone";

const HistoricalExportFilter = (props) => {
	const locale = useContext(LocalizationContext)
	const classes = useStyles()
	const { sectors, users, incubators, activeOption, filters, washers, sterilizers, disinfectors, biResults, proResults, cheResults } = props
	const [selectedSector, setSelectedSector] = useState(filters.SectorId || '')
	const [selectedUser, setSelectedUser] = useState(filters.AppUserId || '')
	const [selectedIncubator, setSelectedIncubator] = useState(filters.IncubatorId || "")
	const [selectedWasher, setSelectedWasher] = useState(filters.WasherId || "")
	const [selectedSterilizer, setSelectedSterilizer] = useState(filters.SterilizerId || "")
	const [selectedDisinfector, setSelectedDisinfector] = useState(filters.DisinfectorId || "")
	const [selectedBIResult, setBIResult] = useState(filters.ResultBI || "")
	const [selectedPROResult, setPROResult] = useState(filters.ResultPRO || "")
	const [selectedCHEResult, setCHEResult] = useState(filters.ResultCHE || "")

	const [dateFrom, setDateFrom] = useState(filters.DateFrom || null)
	const [dateTo, setDateTo] = useState(filters.DateTo || null)

	const [readNumber, setReadNumber] = useState(filters.ReadNumber || "")

	return (
		<Modal
		sx={{
			width:'fit-content'
		}}
			closeModal={props.closeFilters}
			header={
				<div className={classes.header}>
					<div className={classes.headerText}>{t('FilterText', locale)}</div>
					<span className={classes.modalCloseButton} onClick={props.closeFilters}>
						<svg viewBox="0 0 24 24">
							<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
						</svg>
					</span>
				</div>
			}
			footer={
				<div className={classes.footerAction}>
					<Button
						caption={t("ConfirmButton")}
						variant={"text"}
						disabled={isNaN(readNumber)}
						onClick={() => {
							props.confirmAction({
								"SectorId": selectedSector,
								"DateFrom": dateFrom,
								"DateTo": dateTo,
								"IncubatorId": selectedIncubator,
								"EditUserId": selectedUser,
								"SterilizerId": selectedSterilizer,
								"DisinfectorId": selectedDisinfector,
								"WasherId": selectedWasher,
								"ResultBI": selectedBIResult,
								"ResultPRO": selectedPROResult,
								"ResultCHE": selectedCHEResult,
								"ReadNumber": readNumber,
								"ClientDateTimeNow": moment().toISOString(true),
								"ClientTimeZoneId": moment.tz.guess(true),
							})
						}}
					/>
				</div>
			}
		>
			{(activeOption === 'ReadingBI' || activeOption === 'ReadingBIDisinfection' || activeOption === 'ReadingPRO') &&
				<div className={"container-fluid"}>
					<div className={"row"}>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<Select
								nullable={true}
								label={t('SectorLabel', locale)}
								options={sectors}
								variant={'outlined'}
								noError={true}
								value={selectedSector}
								onChange={value => setSelectedSector(value)}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<Select
								nullable={true}
								label={t('BaseBIPROIncubator', locale)}
								options={incubators}
								variant={'outlined'}
								noError={true}
								value={selectedIncubator}
								onChange={value => setSelectedIncubator(value)}
							/>
						</div>
						{activeOption === 'ReadingBI' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingBISterilizationSterilizerName', locale)}
									options={sterilizers}
									variant={'outlined'}
									noError={true}
									value={selectedSterilizer}
									onChange={value => { setSelectedSterilizer(value) }}
								/>
							</div>
						}
						{activeOption === 'ReadingPRO' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingPROWasherName', locale)}
									options={washers}
									variant={'outlined'}
									noError={true}
									value={selectedWasher}
									onChange={value => { setSelectedWasher(value) }}
								/>
							</div>
						}
						{activeOption === 'ReadingBIDisinfection' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingBIDisinfectionDisinfectorName', locale)}
									options={disinfectors}
									variant={'outlined'}
									noError={true}
									value={selectedDisinfector}
									onChange={value => { setSelectedDisinfector(value) }}
								/>
							</div>
						}
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<Select
								nullable={true}
								label={t('UserLabel', locale)}
								options={users}
								variant={'outlined'}
								noError={true}
								value={selectedUser}
								onChange={value => { setSelectedUser(value) }}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center", marginLeft: "-24px" }}>
							<DateInput
								label={t('StartedDateLabel', locale)}
								variant={'outlined'}
								clearable
								value={dateFrom}
								onChange={value => setDateFrom(value ? value.startOf('day').toISOString(true) : null)}
								gutter={'8px 0'}
								noError={true}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<DateInput
								label={t('FinishedDateLabel', locale)}
								variant={'outlined'}
								value={dateTo}
								clearable
								onChange={value => setDateTo(value ? value.endOf('day').toISOString(true) : null)}
								gutter={'8px 0'}
								noError={true}
							/>
						</div>
						{(activeOption === 'ReadingBI' || activeOption === 'ReadingBIDisinfection') &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingBICommonResultStatus', locale)}
									options={biResults}
									variant={'outlined'}
									noError={true}
									value={selectedBIResult}
									onChange={value => { setBIResult(value) }}
								/>
							</div>
						}
						{activeOption === 'ReadingPRO' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingBICommonResultStatus', locale)}
									options={proResults}
									variant={'outlined'}
									noError={true}
									value={selectedPROResult}
									onChange={value => { setPROResult(value) }}
								/>
							</div>
						}
						<div className={"col-12 col-md-6"} style={{ textAlign: "center",  marginLeft: "-24px" }}>
							<Input
								label={t("BaseReadReadNumber", locale)}
								value={readNumber}
								onChange={value => { setReadNumber(value) }}
								variant={"outlined"}
								gutter={"14px 0"}
								error={isNaN(readNumber) ? t("ValueMustBeNumber", locale) : ""}
								/>
						</div>
					</div>
				</div>
			}
			{(activeOption === 'readingchesterilizer' || activeOption === 'readingchewasher') &&
				<div className={"container-fluid"}>
					<div className={"row"}>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<Select
								nullable={true}
								label={t('UserLabel', locale)}
								options={users}
								variant={'outlined'}
								noError={true}
								value={selectedUser}
								onChange={value => { setSelectedUser(value) }}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<Select
								nullable={true}
								label={t('BaseCHEResult', locale)}
								options={cheResults}
								variant={'outlined'}
								noError={true}
								value={selectedCHEResult}
								onChange={value => { setCHEResult(value) }}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center", marginLeft: "-24px" }}>
							<DateInput
								label={t('StartedDateLabel', locale)}
								variant={'outlined'}
								value={dateFrom}
								clearable
								onChange={value => setDateFrom(value ? value.startOf('day').toISOString(true) : null)}
								gutter={'13px 0'}
								noError={true}
							/>
						</div>
						<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
							<DateInput
								label={t('FinishedDateLabel', locale)}
								variant={'outlined'}
								value={dateTo}
								clearable
								onChange={value => setDateTo(value ? value.endOf('day').toISOString(true) : null)}
								gutter={'13px 0'}
								noError={true}
							/>
						</div>
						{activeOption === 'readingchesterilizer' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingCHESterilizerSterilizerName', locale)}
									options={sterilizers}
									variant={'outlined'}
									noError={true}
									value={selectedSterilizer}
									onChange={value => { setSelectedSterilizer(value) }}
								/>
							</div>
						}
						{activeOption === 'readingchewasher' &&
							<div className={"col-12 col-md-6"} style={{ textAlign: "center" }}>
								<Select
									nullable={true}
									label={t('ReadingCHEWasherWasherName', locale)}
									options={washers}
									variant={'outlined'}
									noError={true}
									value={selectedWasher}
									onChange={value => { setSelectedWasher(value) }}
								/>
							</div>
						}
						<div className={"col-12 col-md-6"} style={{ textAlign: "center", marginLeft: "-24px" }}>
							<Input
								label={t("BaseReadReadNumber", locale)}
								value={readNumber}
								onChange={value => { setReadNumber(value) }}
								variant={"outlined"}
								gutter={"14px 0"}
								error={isNaN(readNumber) ? t("ValueMustBeNumber", locale) : ""}
								/>
						</div>
					</div>
				</div>
			}
		</Modal>
	)
}

export default HistoricalExportFilter;