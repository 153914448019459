import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import AppMain from "../pages/AppMain";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import useLocale from "../utils/Hooks/useLocale";
import AppMainQ from "../pages/AppMainQ/AppMainQ";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.AHome,
    key: "",
  };

  const screenName = 1100
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={150}
          MenuOptions={menu}
          currentPage={currentPage}
        >
        {locale ? <AppMainQ /> : <PageContentLoading />}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};

