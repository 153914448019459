import React, { useState, useEffect, useRef, useCallback } from "react";
import request from "../../utils/request";
import { flatten } from "flat";

import useStyles from "./ScreenStyles";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import classnames from "classnames";
import { verifyColumns, verifyForm, verifyHeaders, verifyFieldsMap, verifyDetailFields } from '../../utils/languagePermittedHandler'
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import Select2 from "../../components/Select2";

export default function Screen(props) {
  const classes = useStyles();

  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(
    window.location.search.substr(4) || props?.location?.state?.moduleId || ""
  );

  const [screens, setScreens] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState("");

  const [objects, setObjects] = useState([]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState();

  const [languages, setLanguages] = useState([])
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [formFields, setFieldForm] = useState([]);
  const [fieldsMap, setFieldMap] = useState([]);
  const [detailFields, setDetailFields] = useState([]);
  const [error, setError] = useState();

  const hasMount = useRef(false);

  useEffect(() => {
    async function fetchData() {
      Promise.all([
        request().get(`/api/module`),
        request().get(`/api/Language`),
      ])
        .then(([response, languages]) => {
          setModules(
            response.data.reduce((modulesOptions, module) => {
              modulesOptions.push([module.id, module.description]);
              return modulesOptions;
            }, [])
          );
          setLoadingContent(false);
          setLanguages(languages.data)
        })
        .catch(error => console.log(error));
    }
    fetchData()

    setSelectedScreen("");
  }, []);

  useEffect(() => {
    if (selectedModule !== "") {
      request()
        .get(`/api/screen?ModuleId=${selectedModule}`)
        .then(response => {
          setScreens(
            response.data.reduce((screensOptions, screen) => {
              screensOptions.push([screen.screenName, screen.screenNameDescription]);
              return screensOptions;
            }, [])
          );
          if (hasMount.current === true) {
            setObjects([]);
            setSelectedScreen("");
          } else {
            hasMount.current = true;
            setSelectedScreen(props?.location?.state?.screenId || selectedScreen);
          }
        })
        .catch(error => console.log(error));
    }
  }, [selectedModule]);

  const updateData = useCallback(() => {
    if (selectedScreen !== "") {
      setLoadingData(true);
      request()
        .get(`/api/object?screenName=${selectedScreen}`)
        .then(response => {
          setObjects(response.data.map(x => {
            return {
              ...x,
              objectTranslations: x.objectTranslations.sort((a, b) => (a.language > b.language) ? 1 : -1)
            }
          })
            .map(x => flatten(x)))
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        })
        .catch(error => console.log(error));
    }
  }, [selectedScreen]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  const customSuccessHandler = message => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage();
    }, 3000);
    updateData();
  };

  useEffect(() => {
    const headers = ["ID"]
    const columns = ["objectName"]
    const visibleFields = [
      { label: "Module", name: "screen.moduleDescription" },
      { label: "Screen", name: "screen.screenNameDescription" },
      { label: "Object Name", name: "objectNameDescription" }
    ]
    setHeaders(verifyHeaders(headers, languages))
    setFieldForm(verifyForm(languages))
    setColumns(verifyColumns(columns, "object", languages))
    setFieldMap(verifyFieldsMap("object", languages))
    setDetailFields(verifyDetailFields(visibleFields, "object", languages))
  }, [languages])

  if (loadingContent) return <PageContentLoading />;

  const resetValue = (row) => {
    request().get(`/api/Object/resetObject?object=${row.objectName}`).then(() => {
      updateData();
    }).catch(err => {
      setError(err?.response?.data?.message)
    })
  }

  return (
    <div>
      <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
      <div className={classes.screenOptions}>
        <Select2
          label={"Module"}
          options={modules}
          emptyOptionText={"Select a Module"}
          variant={"outlined"}
          value={selectedModule}
          onChange={val => setSelectedModule(val)}
        />
        {selectedModule && (
          <Select2
            label={"Screen"}
            options={screens}
            emptyOptionText={"Select a Screen"}
            variant={"outlined"}
            value={selectedScreen}
            onChange={val => setSelectedScreen(val)}
          />
        )}
      </div>

      {successMessage && (
        <div className={"alert alert-success"} role="alert">
          {successMessage}
        </div>
      )}

      <div className={classes.tabsRoot}>
        <div
          className={classnames(classes.tabItem, {
            [classes.active]: selectedTabIndex === 0
          })}
          onClick={() => setSelectedTabIndex(0)}
        >
          <span>Translations</span>
        </div>
        <div
          className={classnames(classes.tabItem, {
            [classes.active]: selectedTabIndex === 1
          })}
          onClick={() => setSelectedTabIndex(1)}
        >
          <span>Security</span>
        </div>
      </div>

      {selectedTabIndex === 0 && (
        <CRUD
          tableActions={[
            {
                icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
                tooltip: "Reset",
                action: resetValue,
            }
          ]}
          customSuccessHandler={customSuccessHandler}
          newOption={false}
          data={objects.filter(x => x.multiLanguage === true)}
          loadingData={loadingData}
          updateData={updateData}
          title={"Translations"}
          url={"/api/object/save"}
          method={"post"}
          columns={columns}
          headers={headers}
          idField={"id"}
          fieldsMap={fieldsMap}
          formFields={formFields}
          detailFields={detailFields}
        />
      )}

      {selectedTabIndex === 1 && (
        <CRUD
          customSuccessHandler={customSuccessHandler}
          newOption={false}
          data={objects.filter(x => x.securable === true)}
          loadingData={loadingData}
          updateData={updateData}
          title={"Security"}
          url={"/api/object/save"}
          method={"post"}
          columns={["objectNameDescription", "securityModeDescription"]}
          headers={["Object", "Security Mode"]}
          idField={"id"}
          fieldsMap={["securityMode"]}
          formFields={[
            {
              label: "Security mode",
              class: "col-12 px-0",
              type: "select",
              options: [
                [0, "Not Required"],
                [1, "Relax"],
                [2, "Medium"],
                [3, "Strict"]
              ],
              descriptionField: "securityModeDescription"
            }
          ]}
          detailFields={[
            { label: "Module", name: "screen.moduleDescription" },
            { label: "Screen", name: "screen.screenNameDescription" },
            { label: "Object Name", name: "objectNameDescription" },
            { label: "Security Mode", name: "securityModeDescription" }
          ]}
        />
      )}
    </div>
  );
}
