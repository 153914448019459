import React from "react";
import styles from "./ColumnQ.module.css";

export default function ColumnQ({ children, title, img }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        <img src={img} alt={title} />
      </div>
      <div className={styles.details}>{children}</div>
    </div>
  );
}
