import React from 'react';
import styles from './ImageModal.module.css'; 

const Modal = ({ imageSrc, onClose }) => {

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <img src={imageSrc} alt="Expanded view" className={styles.modalImage} />
          </div>
          <button className={styles.closeButton} onClick={onClose}>
              &times;
          </button>
        </div>
      );
};

export default Modal;
