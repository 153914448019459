import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    pageContainer : {
        margin: "10px auto",
        padding: "40px 30px",
        backgroundColor: "#f2f2f2",
        borderRadius: "10px",
        minHeight: "90vh",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90vw",
      },
      
      page : {
        width: "90%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        height: "auto"
      }

})