export default function reducer(state, action) {
    switch (action.type) {
      case "SET_CONTENTLOADING": {
        return {
          ...state,
          pageContentLoading: action.payload,
        };
      }
      case "SET_WASHERS": {
        return {
          ...state,
          washers: action.payload,
        };
      }
      case "SET_CHARTMODELS": {
        return {
          ...state,
          chartModels: action.payload,
        };
      }
      case "SELECT_WASHER": {
        return {
          ...state,
          selectedWasher: action.payload,
          chartData: []
        };
      }
      case "SELECT_CHARTMODEL": {
        return {
          ...state,
          selectedChartModel: action.payload,
          chartData: [],
        };
      }
      case "SET_DATEFROM": {
        return {
          ...state,
          dateFrom: action.payload,
          chartData: [],
        };
      }
      case "SET_DATETO": {
        return {
          ...state,
          dateTo: action.payload,
          chartData: [],
        };
      }
      case "SET_MEASUREMENTSGROUPING": {
        return {
          ...state,
          measurementGrouping:
            action.payload.measurementGrouping === "0"
              ? 1
              : action.payload.measurementGrouping > 10
              ? 10
              : action.payload.measurementGrouping,
          selectedChartModel: action.payload.newChartModel,
          chartData: [],
        };
      }
      case "SET_CHARTDATA": {
        return {
          ...state,
          chartData: action.payload,
        };
      }
      case "SET_IGNOREDREADINGS": {
        return {
          ...state,
          countIgnoredReadings: action.payload,
        };
      }
      case "CLOSE_ERROR": {
        return {
          ...state,
          countIgnoredReadings: 0,
        };
      }
      default:
        throw new Error();
    }
  }
  