export default function reducer(state, action) {
    switch (action.type) {
      case "SET_CONTENT":
        return {
          ...state,
          processBI: action.payload.processBI,
          zoneTypes: action.payload.zoneTypes,
          loadingContent: false,
        };
      default:
        throw new Error();
    }
  }