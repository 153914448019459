import React from 'react';
import t from '../../utils/translation';

import Button from '../../components/Button';

export default function InvalidUrl(props) {
	const {message} = props

	return (
		<div className="container" style={{height:'100vh',alignItems:'center', justifyContent:'center', display:'flex'}}>
		   <div className="row justify-content-center w-100">
		     <div className="col-12 col-md-8">
			 	<div className="alert alert-danger" style={{textAlign:'center'}}>Error: {message}</div>
		     </div>
			 <div className="col-12 col-md-8 justify-self-center" style={{display:'flex', justifyContent:'center'}}>
			 	<Button caption={t('GoToMainPageButton') || 'Go to main page'} variant={'text'} onClick={() => window.location.href='/'}/>
		     </div>
		   </div>
		</div>
	)
} 
