
import React, { useEffect, useState, useContext } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import useEulaStyles from "./AppEulaStyles";
import request from '../../utils/request';
import Button from '../../components/Button'
import LocalizationContext from "../../utils/LocalizationContext";
import { setEula, getEula, getUserCompany, logout } from "../../utils/sessionHandler";
import CircularProgress from '../../components/CircularProgress/CircularProgress';

const useStyles = makeStyles({
    root: props => ({
        maxHeight: props,
        width: '100%',
        "& .MuiInputBase-root": {
            color: 'white'
        }
    })
});

export default function Eula() {
    const classes = useEulaStyles();
    const locale = useContext(LocalizationContext);
    const textFieldClasses = useStyles(window.screen.height - 10);
    const [eulaTxt, setEulaTxt] = useState("");
    const [eulaId, setEulaId] = useState(null);
    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        let eu = getEula();
        if (eu) {
            if (eu.acceptedEula) {
                window.location.href = "/";
            } else {
                setEulaTxt(eu.eula.text);
                setEulaId(eu.eula.id);                
            }
        } else {
            request().get(`/api/EulaCompanies/validateeulacompany/${getUserCompany().Id}`)
                .then((response) => {
                    if (response.data.acceptedEula) {
                        window.location.href = "/";
                    } else {
                        setEula(response.data);
                        setEulaTxt(response.data.eula.text);
                        setEulaId(response.data.eula.id);
                    }                    
                })
        }
        let company = getUserCompany();
        setCompanyId(company.Id);
    }, []);

    const handleAceptEula = () => {
        const param = {
            EulaId: eulaId,
            CompanyId: companyId
        }
        request().put(`/api/EulaCompanies/saveeulacompany`, param).then((response) => {
            setEula(response.data);
            window.location.href = "/";
        })
    }

    const handleCancelEula = () => {
        logout();
    };

    return (
        <div className={classes.root}>
            <div className={classes.loginCardSignUp} style={{marginTop: 12,padding: '16px 24px 24px'}}>
                <div className={"row"}>
                    {
                        !eulaTxt ? 
                        <div className={"col-lg-12"}>
                            <CircularProgress class={textFieldClasses.loading} />
                        </div>
                        :
                        <>
                            <h2 className={classes.title}>{locale.eulaForm.title}</h2>
                            <div className={"col-lg-12"}>
                                {/*<TextField
                                    classes={textFieldClasses}
                                    multiline
                                    defaultValue={eulaTxt}
                                    rowsMax={25}
                                />*/}
                                
                                <div 
                                    className={classes.subTitle}
                                    style={{color:'white',fontFamily: 'Montserrat',whiteSpace: "pre-wrap",textAlign:'left',marginBottom:'25px'}} dangerouslySetInnerHTML={{ __html: eulaTxt}} />

                                <div className={"row"}>
                                    
                                </div>
                                <div className={"col-lg-12"}>
                                    <div className={"row"}>
                                        <Button
                                            fullwidth={true}
                                            caption={locale.eulaForm.acceptButton}
                                            onClick={() => handleAceptEula()} />                        
                                    </div>
                                    <div className={"row"}>
                                        <Button
                                            //isVerticalMargined={true}
                                            fullwidth={true}
                                            caption={locale.eulaForm.cancelButton}
                                            onClick={() => handleCancelEula()} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }                    
                </div>
            </div>
        </div>
    );
}