import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import SysMain from "../pages/SysMain";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.SHome,
    key: "",
  };

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={null}>
        <NavBar
          MenuOptions={menu}
          currentPage={currentPage}
        >
          <SysMain />
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};

