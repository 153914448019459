import React, { useContext, useState } from 'react';
import LocalizationContext from "../../../utils/LocalizationContext";
import Input from '../../Input';
import Autocomplete from '../../Autocomplete';
import request from '../../../utils/request';
import { allowOnlyCharactersAndNumbers, phoneCharAllowed } from '../../../utils/functions/Regex';
import LoginInput from '../../LoginInput';
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";
import SelectQ from "../../FormComponents/SelectQ/SelectQ.jsx";
import TableFilterQ from '../../TableBionovaQ/TableFilterQ/TableFilterQ.jsx';
import { FaLanguage } from "react-icons/fa6";
import { BiWorld } from "react-icons/bi";

const Company = (props) => {
    const { company, setCompany, error, setError, countries, loadingCountries, setEulaAccepted, languages, language, setLanguage, validationMessage, classes,setValidationMessage } = props;
    const locale = useContext(LocalizationContext);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');

    // const [selectedValue, setSelectedValue] = useState('');
    const [filters, setFilters] = useState([
        { property: "name", value: "" },
        { property: "description", value: "" },
      ]);


    // Funciones para manejar cambios 
    const handleChangeCompanyName = (value) => {
        setCompany({ ...company, Name: value.target.value })
        setError({})
        if (value.target.value)
            request().get(`/api/Company/CompareCompaniesNames?companyName=${value.target.value}`).then((response) => {

            }).catch((error) => {
                setError({ type: 'warning', message: "The company name already exists"})
                //si viene del back error.response?.data.Message
            })
    }

    const handleChangeProvince = (value) => {
        setCompany({ ...company, Province: allowOnlyCharactersAndNumbers(value.target.value) })
        setError({})
    }

    const handleChangeCity = (value) => {
        setCompany({ ...company, City: allowOnlyCharactersAndNumbers(value.target.value) })
        setError({})
    }

    const handleChangePostalCode = (value) => {
        setCompany({ ...company, PostalCode: allowOnlyCharactersAndNumbers(value.target.value) })
        setError({})
    }
    const handleChangeAddres = (value) => {
        setCompany({ ...company, Addres: allowOnlyCharactersAndNumbers(value.target.value) })
        setError({})
    }
    const handleChangePhone = (value) => {
      setCompany({ ...company, Phone: phoneCharAllowed(value.target.value) })
      setError({})
    }
    const handleChangeContact = (value) => {
      setCompany({ ...company, Contact: allowOnlyCharactersAndNumbers(value.target.value) })
      setError({})
    }

    // const handleSelectCountry = (e) => {
    //     const value = e.target.value
    //     setEulaAccepted(false)
    //     setCompany({ ...company, Country: value, CountryId: value?.id })
    //     setError({})
    //     setSelectedCountry(value);
    //   }
    // const handleSelectLanguage = (e) => {
    //     const value = e.target.value
    //     setLanguage({value})
    //     setSelectedLanguage(value)
    //     setError({})
    //   }

//con TableFilterQ
///////////// VERSION 1 /////////
    // const handleFilterChange = (property, value) => {
    //     setFilters((prevFilters) =>
    //         prevFilters.map((filter) =>
    //             filter.property === property ? { ...filter, value } : filter
    //         )
    //     );
    // console.log(value)
    //    // Determinar qué tipo de filtro estamos aplicando
    // if (property === "name") {
    //     setSelectedCountry(value); // Establece el nombre del país seleccionado
      

    //     // Encuentra el objeto de país correspondiente en `countries` por el nombre
    //     const selectedCountry = countries.find((country) => country.name === value);
    //     // console.log(selectedCountry)

    //     if (selectedCountry) {
    //         setEulaAccepted(false);
    //         setCompany({ ...company, Country: selectedCountry, CountryId: selectedCountry.id })
    //         // console.log(selectedCountry)
    //     } 
    //     // console.log("Company state after setting country:", company);
    // } else if (property === "description") {
    //     setSelectedLanguage(value); // Establece el nombre del idioma seleccionado

    //     // Encuentra el objeto de idioma correspondiente en `languages` por el nombre
    //     const selectedLanguage = languages.find((lang) => lang.description === value);
    //     // console.log(selectedLanguage)

    //     if (selectedLanguage) {
    //         setLanguage(selectedLanguage); // Actualiza el estado del idioma seleccionado
    //         // console.log(selectedLanguage)
    //         setError({});
    //     } 
    // }
    // };

//////////////////// VERSION 2 ///////////////
    // const handleFilterChange = (property, value) => {
    //     // Verifica si value está vacío
    //     if (value === null || value === undefined || value === '') {
    //         // Setea todo en null
    //         setSelectedCountry(null);
    //         setEulaAccepted(false);
    //         setCompany({ ...company, Country: null, CountryId: null });
    //         setSelectedLanguage(null);
    //         setLanguage(null);
    //         setError({});
    //     } else {
    //         setFilters((prevFilters) =>
    //             prevFilters.map((filter) =>
    //                 filter.property === property ? { ...filter, value } : filter
    //             )
    //         );
    
    //         //Determinar qué tipo de filtro estamos aplicando
    //         if (property === "name") {
    //             setSelectedCountry(value); // Establece el nombre del país seleccionado
    
    //             // Encuentra el objeto de país correspondiente en `countries` por el nombre
    //             const selectedCountry = countries.find((country) => country.name === value);
    
    //             if (selectedCountry) {
    //                 setEulaAccepted(false);
    //                 setCompany({ ...company, Country: selectedCountry, CountryId: selectedCountry.id });
    //             } else {
    //                 setCompany({ ...company, Country: null, CountryId: null });
    //             }
    //         } else if (property === "description") {
    //             setSelectedLanguage(value); // Establece el nombre del idioma seleccionado
    
    //             // Encuentra el objeto de idioma correspondiente en `languages` por el nombre
    //             const selectedLanguage = languages.find((lang) => lang.description === value);
    
    //             if (selectedLanguage) {
    //                 setLanguage(selectedLanguage); // Actualiza el estado del idioma seleccionado
    //                 setError({});
    //             } else {
    //                 setLanguage(null);
    //             }
    //         }
    //     }
    // };


    return (
        <div>
            {error.type === "alert" && (
                <div className={"alert alert-danger"}>{error.message}</div>
            )}
            {error.type === "warning" && (
                <div className={"alert alert-warning"}>{error.message}</div>
            )}
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.name}
                value={company.Name}
                errorMessage={error.companyName ? error.message : ""}
                // onFocusOut={(param) => {
                //     if (param)
                //         request().get(`/api/Company/CompareCompaniesNames?companyName=${param}`).then((response) => {

                //         }).catch((error) => {
                //             setError({ type: 'warning', message: error.response?.data.Message })
                //         })
                // }}
                // onChange={value => {
                //     setCompany({ ...company, Name: value })
                //     setError({})
                // }}
                handleChange={handleChangeCompanyName}
                validationMessage={validationMessage}
            />
             {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <Autocomplete
                header={true}
                placeholder={locale.companyForm.country}
                descriptionField={"toString"}
                white={true}
                options={countries}
                defaultValue={company.Country}
                onChange={value => {
                    setEulaAccepted(false)
                    setCompany({ ...company, Country: value, CountryId: value?.id })
                    setError({})
                }}
                loading={loadingCountries}
                error={error.country ? error.message : ""}
            />
            {/* <SelectQ
                label={locale.companyForm.country}
                descriptionField={"name"}
                options={countries}
                // value={company.Country}
                containerMargin={"5px"}
                containerWidth={"100%"}
                handleSelect={handleSelectCountry}
                selectValue={selectedCountry}
                errorMessage={error.country ? error.message : ""}
                validationMessage={validationMessage}
            /> */}
            {/* <TableFilterQ
             icon={<BiWorld />}
             label={"Country"}
             items={countries}
             property={"name"}
             onFilterChange={handleFilterChange}
             errorMessage={error.country ? error.message : ""}
             containerMargin={"10px"}
             validationMessage={validationMessage}
             selectedValue={selectedCountry}
            />  */}
            
              {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <Autocomplete
                header={true}
                placeholder={locale.companyForm.language}
                descriptionField={"description"}
                white={true}
                options={languages}
                defaultValue={language}
                onChange={value => {
                    setLanguage(value)
                    setError({})
                }}
                loading={loadingCountries}
                error={error.language ? error.message : ""}
            />
            {/* <SelectQ
                label={locale.companyForm.language}
                descriptionField={"description"}
                options={languages}
                // value={language}
                containerMargin={"5px"}
                containerWidth={"100%"}
                handleSelect={handleSelectLanguage}
                selectValue={selectedLanguage}
                errorMessage={error.language ? error.message : ""}
                validationMessage={validationMessage}
            /> */}
             {/* <TableFilterQ
             icon={<FaLanguage />}
             label={"Language"}
             items={languages}
             property={"description"}
             onFilterChange={handleFilterChange}
             errorMessage={error.language ? error.message : ""}
             containerMargin={"10px"}
             validationMessage={validationMessage}
             selectedValue={selectedLanguage}  //saco description??
            /> */}
              {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.province}
                value={company.Province}
                errorMessage={error.province ? error.message : ""}
                // onChange={value => {
                //     setCompany({ ...company, Province: allowOnlyCharactersAndNumbers(value) })
                //     setError({})
                // }}
                handleChange={handleChangeProvince}
                validationMessage={validationMessage}
            />
              {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.city}
                value={company.City}
                errorMessage={error.city ? error.message : ""}
                // onChange={value => {
                //     setCompany({ ...company, City: allowOnlyCharactersAndNumbers(value) })
                //     setError({})
                // }}
                handleChange={handleChangeCity}
                validationMessage={validationMessage}
            />
              {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.postalCode}
                value={company.PostalCode}
                errorMessage={error.postalCode ? error.message : ""}
                // onChange={value => {
                //     setCompany({ ...company, PostalCode: allowOnlyCharactersAndNumbers(value) })
                //     setError({})
                // }}
                handleChange={handleChangePostalCode}
                validationMessage={validationMessage}
            />
              {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.address}
                value={company.Addres}
                error={error.address ? error.message : ""}
                // onChange={value => {
                //     setCompany({ ...company, Addres: allowOnlyCharactersAndNumbers(value) })
                //     setError({})
                // }}
                handleChange={handleChangeAddres}
            />
            <InputQ
                containerWidth={"110%"}
                containerMargin={"10px"}
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.phone}
                value={company.Phone}
                // onChange={value => {
                //     setCompany({ ...company, Phone: phoneCharAllowed(value) })
                //     setError({})
                // }}
                handleChange={handleChangePhone}
                type={"text"}
                error={error.phone ? error.message : ""}
            />
            <InputQ
                gutter={"0px"}
                containerWidth={"110%"}
                containerMargin={"10px"}
                claro={true}
                label={locale.companyForm.companyRepresentative}
                value={company.Contact}
                // onChange={value => {
                //     setCompany({ ...company, Contact: allowOnlyCharactersAndNumbers(value) })
                //     setError({})
                // }}
                type={"text"}
                error={error.contact ? error.message : ""}
                handleChange={handleChangeContact}
            />
        </div>
    );
};

export default Company;