import React, { useContext, useEffect, useState } from "react";

import PageContainer from "../../components/PageGeneralContainer/PageGeneralContainer";

import request from "../../utils/request.js";
import t from "../../utils/translation.js";
import LocalizationContext from "../../utils/LocalizationContext.js";

import styles from "./Brands.module.css";
import BrandsTable from "./BrandsTable/BrandsTable.jsx";
import BrandsForm from "../../components/BrandsForm/BrandsForm.jsx";
import BrandsDetail from "./BrandsDetail/BrandsDetail.jsx";

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [modal, setModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [formData, setFormData] = useState({
    brand: "",
    description: "",
    observations: "",
  });
  const [brandData, setBrandData] = useState({});
  const [loading, setLoading] = useState(true);

  const locale = useContext(LocalizationContext);

  const clearForm = () => {
    setFormData({
      brand: "",
      description: "",
      observations: "",
    });
  };

  const getBrands = () => {
    setLoading(true);
    request()
      .get("/api/CompanyMachineMake")
      .then((res) => {
        setBrands(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const handleAdd = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    clearForm();
  };

  const handleDetail = (data) => {
    setBrandData(data);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setBrandData({});
  };

  return (
    <PageContainer title={"Brands"} category={"brands"}>
      <div className={styles.container}>
        <BrandsTable
          brands={brands}
          getBrands={getBrands}
          handleData={setFormData}
          handleModal={setModal}
          handleDetail={handleDetail}
          loading={loading}
        />
        <div className={styles.detailContainer}></div>
      </div>
      <button
        className={`${styles.button} ${styles.addButton}`}
        onClick={() => handleAdd()}
      >
        Add
      </button>

      {modal && (
        <BrandsForm
          formData={formData}
          handleFormData={setFormData}
          handleClose={handleClose}
          open={modal}
          getBrands={getBrands}
          brands={brands}
          t={t}
          locale={locale}
        />
      )}

      <BrandsDetail
        open={showDetail}
        handleClose={handleCloseDetail}
        t={t}
        locale={locale}
        brandData={brandData}
      />
    </PageContainer>
  );
};

export default Brands;
