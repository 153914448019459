import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import request from "../../../utils/request.js";
import t from "../../../utils/translation.js";

import SearchDescription from "../../../components/SearchDescription/SearchDescription.js";
import CrudIndicator from "../../../components/CrudIndicator/CrudIndicator.jsx";

import BarcodeReader from "react-barcode-reader";

import { parseBarcode } from "../../../utils/BarcodeParser.js";
import DragAndDropCharges from "../../../components/DragAndDropCharges/DragAndDropCharges.js";
import LocalizationContext from "../../../utils/LocalizationContext.js";
import { v4 as uuidv4 } from "uuid";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import {
  getSectorId,
  getSession,
  getUserCompany,
} from "../../../utils/sessionHandler.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";
import PageContainer from "../../../components/PageContainer/PageContainer.jsx";

import styles from "./CreateWasherLoading.module.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import TextAreaQ from "../../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import ModalLoading from "../../../components/ModalLoading/ModalLoading.js";
import DragAndDropWasherLoading from "../../../components/DragAndDropWasherLoading/DragAndDropWasherLoading.js";

const MySwal = withReactContent(Swal);

export default function CreateWasherLoading() {
  const [indicators, setIndicators] = useState([]);
  const [description, setDescription] = useState("");
  const [observations, setObservations] = useState(null);
  const [trays, setTrays] = useState([]);
  const [filteredTrays, setFilteredTrays] = useState("");
  const [selectedTrays, setSelectedTrays] = useState([]);
  const [selectedFilteredTrays, setSelectedFilteredTrays] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [editId, setEditId] = useState(null);
  const [Washers, setWashers] = useState([]);
  const [selectedWasher, setSelectedWasher] = useState("");
  const [validate, setValidate] = useState(false);
  const [scanMode, setScanMode] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [models, setModels] = useState([]);
  const [openWasherConfirm, setOpenWasherConfirm] = useState(false);
  const [waitWasher, setWaitWasher] = useState(null);
  const [methods, setMethods] = useState([]);
  const [methodName, setMethodName] = useState("");
  const [modelAreas, setModelAreas] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [stopButtons, setStopButtons] = useState(false);
  const [idDistributorCompany, setIdDistributorCompany] = useState(null);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editTrays, setEditTrays] = useState([]);

  const [selectedMethod, setSelectedMethod] = useState(null);

  const [packageQuantity, setpackageQuantity] = useState(1000000);

  const locale = useContext(LocalizationContext);
  const descriptionRef = useRef();
  const observationsRef = useRef();
  const validateFields = (status) => {
    let notValid = false;

    if (!description.length > 0 || description.trim() === "") {
      notValid = true;
    }

    if (!selectedWasher || selectedWasher === "") {
      notValid = true;
    }

    if (status !== 1 && !indicators.length > 0) {
      notValid = true;
    }

    return notValid;
  };

  // const getPackages = async () => {
  //   setModalLoading(true);
  //   await request()
  //     .get(`/api/package`)
  //     .then((response) => {
  //       setTrays(response.data.filter((p) => p.packageStatus === 2));
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       localStorage.removeItem("packageEditId");
  //     });
  //   setModalLoading(false);
  // };

  const getTrays = async (method, addPackages) => {
    setModalLoading(true);
    console.log(addPackages, method);
    let filter = addPackages.filter((p) => p.packageWashing.methodId == method);
    try {
      if (!method) {
        setTrays([]);
      } else {
        console.log("Ejecuto");
        const response = await request().get(
          `/api/PackageWashing/list?&pageSize=${packageQuantity}&Status=2&isInCharge=${false}&method=${method}`
        );
        setTrays([...filter, ...response.data.packageWashingList]);
      }
    } catch (e) {
      console.log(e);
      localStorage.removeItem("packageEditId");
    }
    setModalLoading(false);
  };
  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);

      const washers = response.data.washers.map((item) => ({
        value: item.washerId,
        ...item,
      }));

      setWashers(washers);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  const getModels = async () => {
    await request()
      .get(`/api/CompanyMachineModel`)
      .then((response) => {
        setModels(response.data);
      });
  };

  const getModelAreas = async (id) => {
    try {
      const response = await request().get(`/api/ModelAreas/${id}`);

      return setModelAreas(response.data);
    } catch (error) {
      console.error(`Error al obtener model areas para el id ${id}:`, error);
      return [];
    }
  };

  const loadEditId = localStorage.getItem("loadingEditId");

  const getEditCharges = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(`/api/chargewashing/${loadEditId}`)
        .then((response) => {
          if (response.data.id) localStorage.removeItem("loadingEditId");

          let newIndicators = [];

          response.data.indicatorsWashing.map(
            (i) =>
              (newIndicators = [
                ...newIndicators,
                {
                  ...i,
                  indicator_Id: i.indicatorWashingId,
                  uuid: uuidv4(),
                  expirationDate: i.indicatorExpirationDate,
                  modelAreaName: i.indicatorModelArea.description,
                  modelAreasId: i.indicatorModelArea.id
                },
              ])
          );

          let newPackages = [];
          response.data.packagesWashing.map(
            (p) =>
              (newPackages = [
                ...newPackages,
                { ...p, packageWashingGeneralId: p.packageWashingId },
              ])
          );

          setDescription(response.data.description);
          setSelectedWasher(response.data.washerId);
          setSelectedTrays(newPackages);
          getTrays(
            response.data.washer.companyMachineModel.method?.id,
            newPackages
          );
          setEditTrays(newPackages);
          setIndicators(newIndicators);
          setEditId(response.data.id);
          getModelAreas(response.data.washer.companyMachineModel.id);
          setMethodName(response.data.washer.companyMachineModel.method?.name);
          setMethodId(response.data.washer.companyMachineModel.method?.id);
          setObservations(response.data.observations);
        });
    } catch (e) {
      localStorage.removeItem("loadingEditId");
    } finally {
      setModalEditLoading(false);
    }
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const handleIndicators = (data) => {
    setIndicators(data);
  };
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };

  const handleChangeDescription = (value, scan = false) => {
    if (!(value.target?.value?.length == 0 && !validate)) {
      setValidate(false);
    }
    const inputValue = scan ? value : value.target.value;
    setDescription(inputValue);
  };

  const handleFilteredPackages = (data) => {
    setFilteredTrays(data);
  };
  const handleSelectedTrays = (data) => {
    setSelectedTrays(data);
  };
  const handleSelectedFilteredTrays = (data) => {
    setSelectedFilteredTrays(data);
  };

  const handleChangeWasher = (e) => {
    const selectedValue = e.target.value;
    const selectedWasher = Washers?.find((s) => s.value == selectedValue);

    if (selectedWasher && (selectedTrays.length > 0 || indicators.length > 0)) {
      MySwal.fire({
        title: "Are you sure you want to continue?",
        text: "All entered trays and indicators will be lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedWasher(selectedValue);

          getModelAreas(selectedWasher?.companyMachineModelId);

          setMethodName(!selectedValue ? "" : selectedWasher.washerMethodName);
          setMethodId(!selectedValue ? "" : selectedWasher.washerMethodId);
          getTrays(selectedWasher?.washerMethodId, editTrays);

          clearFields(false);
        }
      });
    } else {
      setSelectedWasher(selectedValue);

      getModelAreas(selectedWasher?.companyMachineModelId);

      setMethodName(!selectedValue ? "" : selectedWasher.washerMethodName);
      setMethodId(!selectedValue ? "" : selectedWasher.washerMethodId);
      getTrays(selectedWasher?.washerMethodId, editTrays);

      clearFields(false);
    }
  };

  const onHandleScan = async (data) => {
    const companyTray = data.includes(idDistributorCompany);
    if (!selectedWasher) return;

    if (companyTray) {
      let item = trays.find(
        (p) => `${idDistributorCompany}${p.packageWashingNumber}` == data
      );
      let itemExist = selectedTrays.find((p) => p == item);
      if (item && !itemExist) {
        if (methodId == item.methodId) {
          setScanCode(data);
          setSelectedTrays([...selectedTrays, item]);
          toast.success(t("TrayAdded") || "Tray added");
          return;
        } else {
          toast.error(
            t("WrongMethod") ||
              "The selected tray does not match the sterilizer method."
          );
        }
      } else if (item && itemExist) {
        setScanCode(data);
        setSelectedTrays(selectedTrays.filter((p) => p != item));
        toast.success(t("TrayRemoved") || "Tray Removed");
        return;
      } else {
        try {
          const res = await request().get(
            `/api/charge/packageId?PackageId=${data.replace(
              idDistributorCompany,
              ""
            )}`
          );

          toast.error(
            `The scanned tray ${data.replace(
              idDistributorCompany,
              ""
            )} is assigned to the washer loading ${res.data.description}.`
          );
        } catch {
          toast.error("Tray not found");
        }
      }
    } else {
      try {
        const variableField = parseBarcode(data).parsedCodeItems[3];
        const Gtin = parseBarcode(data).parsedCodeItems[0];

        let lot = "";

        const handleResponse = async (url) => {
          const response = await request().get(url);
          if (response.data.id) {
            setScanCode(data);
            let newIndicator = response.data;

            setIndicators([...indicators, { ...newIndicator, uuid: uuidv4() }]);

            toast.success(t("IndicatorAdded") || "Indicator Added");
            setDescription(description.replace(data, ""));
          }
        };

        if (variableField.ai === "10") {
          const url = `/api/indicatorLot/GTINforPackage?gtin=${Gtin.data}&lot=${
            variableField.data
          }&disId=${getUserCompany().DistributorId}&methodId=${methodId}`;
          await handleResponse(url);
        } else if (variableField.data.includes("PANTONE")) {
          let match = variableField.data.match(/(.{6})95PANTONE/);
          if (match) {
            lot = match[1];
          }
          const url = `/api/indicatorLot/GTINforPackage?gtin=${
            Gtin.data
          }&lot=${lot}&disId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;
          await handleResponse(url);
        } else {
          const answer = variableField.data;

          let product;

          const indexA = answer.lastIndexOf("A");
          const indexF = answer.lastIndexOf("F");
          const indexB = answer.lastIndexOf("B");
          const indexBT = answer.lastIndexOf("BT");
          const indexPCD = answer.lastIndexOf("PCD");
          const indexCDWA = answer.lastIndexOf("CDWA");

          if (indexA < indexB) {
            if (indexB !== -1) {
              lot = answer.substring(indexB);
              const startAfterB = indexB - 2;

              if (indexCDWA !== -1) {
                product = answer.substring(indexCDWA, startAfterB);
              }
            }
          } else if (indexA > indexF) {
            if (indexA !== -1) {
              lot = answer.substring(indexA);

              const startAfterA = indexA - 2;

              if (indexBT !== -1) {
                product = answer.substring(indexBT, startAfterA);
              }
            }
          } else if (indexA < indexF) {
            if (indexF !== -1) {
              lot = answer.substring(indexF);
              const startAfterF = indexF - 2;

              if (indexPCD !== -1) {
                product = answer.substring(indexPCD, startAfterF);
              }
            }
          }

          const url = `/api/IndicatorLot/lotForCharge?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`;

          await handleResponse(url);
        }
      } catch (e) {
        toast.error(e.response?.data?.message);
      } finally {
      }
    }
  };

  const getIdDistributorCompany = () => {
    const infouser = getSession();

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          setIdDistributorCompany(
            infouser.IdDistributorCompany.replace(/-/g, "")
          );
        });
    else return;
  };

  const createCharge = async (status) => {
    let newIndicators = indicators.flatMap((i) => [
      {
        IndicatorWashingId: i.indicator_Id,
        quantity: 1,
        serial: i.serial ? i.serial : null,
        lot: i.lot,
        modelAreasId: i.modelAreasId,
      },
    ]);

    let newPackages = selectedTrays.map((p) => ({
      PackageWashingId: p.packageWashingGeneralId,
    }));

    const requestData = {
      WasherId: selectedWasher,
      description: description,
      chargeStatus: status,
      indicatorsWashing: newIndicators,
      packagesWashing: newPackages,
      observations: observations,
    };

    try {
      setStopButtons(true);

      if (!editId) {
        await request()
          .post("/api/chargewashing", requestData)
          .then((res) => {
            status === 1 && setEditId(res.data);
          });
        status === 1
          ? toast.success(t("LoadSaved") || "The Load was successfully saved")
          : toast.success(
              t("LoadCompleted") || "The Load was successfully completed"
            );
      } else {
        requestData.id = editId;
        await request().put("/api/chargewashing", requestData);
        toast.success(
          t("LoadModified") || "The Load was successfully modified"
        );
      }
      if (status !== 1) clearFields();
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setStopButtons(false);
    }
  };

  const clearFields = (WasherChange = true) => {
    WasherChange && setDescription("");
    setObservations("");
    setIndicators([]);
    WasherChange && setEditId(null);
    setSelectedTrays([]);
    WasherChange && setSelectedWasher("");
    WasherChange && setMethodName("");
    WasherChange && setMethodId(null);
    setTrays([]);
  };

  const traysMethod = useMemo(() => {
    return (
      trays?.filter(
        (p) =>
          p?.methodId === methodId || p?.packageWashing?.methodId === methodId
      ) ?? []
    );
  }, [methodId, trays]);

  useEffect(() => {
    setFilteredTrays(traysMethod);
  }, [traysMethod, trays]);

  useEffect(() => {
    getParametersLoad();
    getModels();
    getEditCharges();
    getIdDistributorCompany();
  }, []);

  useEffect(() => {
    setDescription(description.replace(scanCode, ""));
    setScanCode("");
  }, [scanCode]);

  const showValidationErrors = (status) => {
    if (!description.length > 0 || description.trim() === "") {
      toast.error(t("NameRequired") || "The Name field is required");
    }
    if (!selectedWasher || selectedWasher === "") {
      toast.error(t("YouMustSelectAWasher") || "You must select a Washer");
    }
    if (status !== 1 && !indicators.length > 0) {
      toast.error(t("YouMustAddIndicator") || "You must add an Indicator");
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else createCharge(status);
  };

  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [description, selectedWasher, indicators]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [description, selectedWasher, indicators]
  );

  return (
    <PageContainer
      categoryId={2}
      currentStep={5}
      category={t("WashingSpan")}
      subcategory={t("WasherLoadingSpan")}
      title={t("CreateWashingLoading")}
      backUrl={"/appmanagewasherloadings"}
      scan={true}
      progress={true}
    >
      {!modalEditLoading && !modalLoading && (
        <BarcodeReader onScan={onHandleScan} />
      )}
      <ModalLoading category={"washing"} open={modalEditLoading} />
      <ModalLoading category={"washing"} open={modalLoading} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <InputQ
              label={t("NameSpan") || "Name"}
              handleChange={handleChangeDescription}
              value={description}
              containerWidth={"100%"}
              inputRef={descriptionRef}
              required={true}
              errorMessage={t("RequiredFieldError") || "This field is required"}
              validate={validate}
              onHandleScan={onHandleScan}
              category={"washing"}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <SelectQ
                selectValue={selectedWasher}
                handleSelect={handleChangeWasher}
                options={Washers}
                label={t("SelectWasherSpan") || "Select a Washer"}
                placeholder={t("SelectWasherSpan") || "Select a Washer"}
                containerWidth={"50%"}
                required={true}
                errorMessage={
                  t("RequiredFieldError") || "This field is required"
                }
                validate={validate}
                category={"washing"}
              />
              <InputQ
                category="washing"
                value={methodName}
                label={t("WashingMethodSpan") || "Washing Method"}
                placeholder={t("None") || "None"}
                containerWidth={"50%"}
                validate={validate}
                disabled={true}
              />
            </div>
          </div>

          <DragAndDropWasherLoading
            filter1={
              <SearchDescription
                data={traysMethod.length >= 1 && traysMethod}
                handleFilter={handleFilteredPackages}
                placeholder={t("SearchPackage") || "Search package..."}
                searchActive={() => {}}
                scanCode={scanCode}
                onHandleScan={onHandleScan}
              />
            }
            filter2={
              <SearchDescription
                data={selectedTrays}
                handleFilter={handleSelectedFilteredTrays}
                placeholder={
                  t("SearchAddedPackage") || "Search added package..."
                }
                searchActive={handleSearchActive}
                scanCode={scanCode}
                onHandleScan={onHandleScan}
              />
            }
            data={filteredTrays}
            selectedData={selectedTrays}
            selectedFilteredData={
              searchActive ? selectedFilteredTrays : selectedTrays
            }
            handleSelectedData={handleSelectedTrays}
            leftColumnName={t("TrayToIncludeSpan")}
            rightColumnName={t("TrayIncludedSpan")}
          />
        </div>
        <div style={{ width: "30%", minHeight: "100%" }}>
          <CrudIndicator
            indicators={indicators}
            handleIndicators={handleIndicators}
            onHandleScan={onHandleScan}
            modelAreas={modelAreas}
            zone={true}
            methodId={methodId}
            select={selectedWasher}
            condition={"a Washer"}
            category={"Washing"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.875rem",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <TextAreaQ
          label={t("ObservationSpan") || "Observations"}
          handleChange={handleChangeObservations}
          value={observations}
          width={"68%"}
          inputRef={observationsRef}
          onHandleScan={onHandleScan}
          category={"washing"}
        />

        <div className={styles.containerButtons}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => clearFields()}
          >
            {t("ClearFields") || "Clear Fields"}
          </button>
          <button
            className={`${styles.button} ${styles.save} ${
              isSaveDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            {t("Save") || "Save"}
          </button>
          <button
            className={`${styles.button} ${styles.finish} ${
              isFinishDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(2)}
          >
            {t("FinishWashingLoading") || "Finish Loading"}
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
