import {getSession} from '../../utils/sessionHandler';

export default function Home() {
  if (getSession().UserType === undefined) {
    window.location.href = '/sysmain'
  } else {
    if (getSession().UserType === 3){
      window.location.href = '/distmain'
    }else
    {
      window.location.href = '/appmain'
    }
  }
  
  return null
}
