
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_MEASURES":
            return {
                ...state,
                loadingContent: false,
                loadingDataCRUD: false,
                measures: action.payload.measures,
                showDownloadSuggestion: false
            }
        case "SET_READING_TO_ASSOCIATE":
            return {
                ...state,
                readingToAssociate: action.payload.readingToAssociate
            }
        case "SET_MEASURE_SELECTED":
            return {
                ...state,
                measureSelected: action.payload.measureSelected
            }
        case "SET_ENABLE_BUTTONS":
            return {
                ...state,
                disabledButtons: false,
                loadingDataCRUD: false
            }
        case "SET_DISABLED_BUTTONS":
            return {
                ...state,
                disabledButtons: true,
                loadingDataCRUD: true
            }
        case "SET_MEASURE_TO_SELECTED_DYNAMICALLY":
            return {
                ...state,
                associateFromBD: action.payload.associateFromBD
            }
        case "SHOW_DOWNLOAD_SUGGESTION":
            return {
                ...state,
                loadingContent: false,
                loadingDataCRUD: false,
                showDownloadSuggestion: true
            }
        case "BEFORE_HIDE_MODAL":
            return {
                ...state,
                readingToAssociate: action.payload.readingToAssociate
            }
        case "SET_INSTRUMENT_IMAGE":
            return {
                ...state,
                instrumentImage: action.payload.instrumentImage
            }
        case "CLEAR_INSTRUMENT_IMAGE":
            return {
                ...state,
                instrumentImage: null
            }
        case "SET_INSTRUMENT_DESCRIPTION":
            return {
                ...state,
                instrumentDescription: action.payload.instrumentDescription
            }
        default:
            throw new Error();
    }
};

export default reducer;