import React, { useEffect, useRef, useState, useCallback } from "react";
import classNames from 'classnames';

import useStyles from "./ModalStyles";

export default function Modal(props) {
  const classes = useStyles();

  const [headerOverlay, setHeaderOverlay] = useState(false)

  const modalBodyRef = useRef()

  const keyPressListner = useCallback((e) => {
    e.keyCode === 27 && props.closeModal && props.closeModal()
  }, [props])

  const handelBodyScroll = scroll => { scroll === 0 ? setHeaderOverlay(false) : setHeaderOverlay(true) }

  useEffect(() => {
    window.document.addEventListener('keydown', event => keyPressListner(event))
    modalBodyRef.current.addEventListener('scroll', event => handelBodyScroll(event.target.scrollTop))
    document.getElementById("root").style = "height: 100vh;overflow: hidden;"

    return () => {
      document.getElementById("root").style = "";
    }
  }, [keyPressListner])

  const rootElement = useRef()

  return (
    <div className={classes.backdrop}>
      <div className={classNames(classes.root, { [classes.responsive]: props.responsive })} ref={rootElement}>
        <div className={classNames(classes.modalRoot, { [classes.reporting]: props.reporting })}>
          <div className={classes.modal}>
            {
              props.header &&
              <div className={classNames(
                classes.header,
                { [classes.headerOverlay]: headerOverlay })
              }>
                {props.header}
              </div>
            }
            <div className={classes.body} ref={modalBodyRef}>{props.children}</div>
            {props.footer && <div className={classes.footer}>{props.footer}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

