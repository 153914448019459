import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.terragene.lightQ,
    textAlign: 'center',
    minHeight: '100vh',  
  },

  loginCard: {
    display: 'inline-grid',
    root: {
      backgroundColor: 'red'
    },
    
  },
   "@media only screen and (min-width: 1920px)": { //1366 0 1920
    loginCard :{
      marginTop: 100, 
    },
    root: {
      minHeight:'100vh',
    },
  },

  container: {
    marginLeft: '10px', // ajusta la distancia a la derecha según necesites
    marginTop: '35px', // ajusta la distancia hacia abajo según necesites
  },

  containerHeader: {
    display: "flex",
    justifyContent: "center",
    alignitems: "center",  
    marginBottom: "30px",
  },

  title:{
    // color: theme.palette.terragene.lightThird,
    color: 'black',
    fontWeight: "bold",
    fontSize: "20px",
  },  
  
  subTitle:{
    // color: theme.palette.terragene.lightThird,
    color: 'black',
    fontSize: "1rem",
    lineHeight: "1.33",
    letterSpacing: "0em",
    fontWeight: 600,
    alignSelf: "center",
    marginBottom: 8,
    marginLeft: 10,
  },

  loginCardSignUp: {
    display: 'inline-grid',
  },

  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.terragene.lightQ,
  },



  inputColor:{
    color:'white'
  },

  "@media (min-width: 448px)": {
    loginCard: {
      width: 300,
    },
    loginCardSignUp: {
      width: 800
    }

  },

  logoTerragene: {
    height: 30,
    float: 'middle',
    alignSelf: "center",
    // '@media only screen and (max-width: 1920px)': {
    // marginTop:90,
    // },
  //   '@media only screen and (max-width: 1366px)': {
  //   marginTop: 5, 
  // },
  },

  loginText: {
    textTransform: 'uppercase',
    // color: theme.palette.terragene.lightThird,
    color: 'black',
    fontFamily:"Montserrat",
    fontSize: "1.5rem",
    lineHeight: "1.33",
    letterSpacing: "0em",
    fontWeight: 400,
    alignSelf: "center",
    marginBottom: 50,
    marginLeft: 10,
  },

  forgotPasswordLink: {
    color:  "black",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 50,
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat"
  },
  backToLoginLink: {
    color:  "black",
    textAlign: "center",
    marginTop: 10,
    marginLeft: 100,
    marginRight: 100,
    fontSize:12,
    fontFamily:"Montserrat"
  },
  backTologinLink2:{
    color:  "#00b189",
    textAlign: "center",
    marginTop: 10,
    marginLeft: 4,
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat",
    fontWeight: 600,
  },
  newCompanyLink: {
    color:  "black",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0, 
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat",
  },

  

  loading: {
    margin: "0!important",
    position: "absolute!important",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },

  userIcon: {
    width: 40,
    height: 40,
    color: theme.palette.terragene.lightThird,
    "& svg": {
      fill: "currentcolor"
    }
  },

  img: {
    width: 352,
  },
  logoBionova: {
    width: theme.palette.images.widthlogoBionovaLogin ? theme.palette.images.widthlogoBionovaLogin : 180,
    paddingBottom:50, //80
    marginTop:40,
  },

  isoLogoBionovaQ: {
    width: theme.palette.images.widthlogoBionovaLogin ? theme.palette.images.widthlogoBionovaLogin : 120, //140
    // paddingBottom:20, //120
    marginBottom: 50, //40
    marginTop:20, //3
  },

  smallIsoLogo: {
    width: 30, //140
    // paddingTop:3, 
    // marginBottom: 20,
    // marginTop:10, 
    margin: "10px 0px 10px 0px"
  },

  logoTerragene2: {
    height: 40,
    position: 'fixed', //o relative
    bottom: 0, // Coloca el logo en la parte inferior de la página
    left: '50%', // Centra horizontalmente el logo
    transform: 'translateX(-50%)', // Ajusta la posición horizontalmente
    // marginTop: 60,
    marginBottom: 30, // Margen inferior del logo
    padding: 5, // Añade un espacio interno alrededor del logo
  },

  validationMessage:{
  color: "red", 
  fontSize: "0.8em",
  textAlign: "left"},



}));
