import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import Rules from "../pages/Rules/Rules";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Company,
    key: "Rules",
  };

  return (
    <LocalizationContext.Provider value={null}>
      {/*<ValidateURL currentPage={currentPage}>*/}
        <NavBar MenuOptions={menu} currentPage={currentPage}>
          <Rules />
        </NavBar>
      {/*</ValidateURL>*/}
    </LocalizationContext.Provider>
  );
};
