import React from "react";
import request from "../../utils/request";
import _ from "lodash";

export function useApiData(endpoint, companyId = null, sectorId = null) {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    const fetchData = React.useCallback(() => {
        // Si no hay endpoint, no hacer nada
        if (!endpoint) {
            setLoading(false);
            return;
        }

        // Si companyId es un parámetro necesario y no está presente, no hacer nada
        if (endpoint.includes(':companyId') && companyId === null) {
            setLoading(false);
            return;
        }

        // Si sectorId es un parámetro necesario y no está presente, no hacer nada
        if (endpoint.includes(':sectorId') && sectorId === null) {
            setLoading(false);
            return;
        }

        let fullEndpoint = endpoint;

        // Crear una lista de parámetros de consulta
        const params = [];
        if (companyId !== null) {
            params.push(`companyId=${companyId}`);
        }

        if (sectorId !== null) {
            params.push(`SectorId=${sectorId}`);
        }

        // Si hay parámetros, agregarlos a la URL
        if (params.length) {
            fullEndpoint += `?${params.join('&')}`;
        }

        setLoading(true);
        request()
            .get(fullEndpoint)
            .then((response) => {
                setData(response.data.map((x) =>
                    _.mapKeys(x, (value, key) => key)
                ));
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.response);
                setError(err.response);
                setLoading(false);
            });
    }, [endpoint, companyId, sectorId]);

    React.useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    return { data, loading, error, updateData: fetchData };
}