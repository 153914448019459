import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PackagesTypesList from "../pages/PackagesTypes/";
import useMenu from "../utils/Hooks/useMenu";
//import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Configuration,
    key: "",
  };


  return (
      <LocalizationContext.Provider value={{ locale: null }}>
        <NavBar
          moduleName={100}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          <PackagesTypesList />
        </NavBar>
      </LocalizationContext.Provider>
  );
};