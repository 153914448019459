import React, { useState } from "react";
import request from "../../utils/request";
import { getSession } from "../../utils/sessionHandler";
import _ from "lodash";
import { useApiData } from "../../utils/api";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { getUserCompany } from "../../utils/sessionHandler";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";

export default function MaterialsTypes() {
  const {
    data: materialTypes,
    loading: loadingMaterialTypes,
    updateData: updateMaterialTypes,
  } = useApiData("/api/MaterialType");

  const companyId = getUserCompany().Id;
  if (loadingMaterialTypes) return <PageContentLoading />;

  const handleUpdate = () => {
    updateMaterialTypes();
  };

  return (
    <PageGeneralContainer title={"Materials Types"} category={"materialsTypes"}>
      <CRUD
        data={materialTypes}
        url={"/api/MaterialType"}
        title={"Material Types"}
        updateData={() => handleUpdate()}
        loadingData={loadingMaterialTypes}
        columns={["name", "description", "observations"]}
        headers={[
          "Tipos de materiales",
          "Descripción",
          "Observaciones",
          "Acciones",
        ]}
        idField={"id"}
        fieldsMap={["name", "description", "observations"]}
        formFields={[
          {
            label: "Tipos de materiales",
            class: "col-12 col-md-6 px-0",
            required: true,
          },
          {
            label: "Descripción",
            class: "col-12 col-md-6 px-0",
            required: true,
          },
          { label: "Observaciones", class: "col-12 col-md-6 px-0" },
        ]}
        detailFields={[
          { label: "Name", name: "name" },
          { label: "Description", name: "description" },
          { label: "Observaciones", name: "observations" },
        ]}
        baseObject={{
          materialtypes: {
            id: null,
            Name: "",
            description: "",
            observations: "",
          },
          UserLogged: getSession(),
        }}
      />
    </PageGeneralContainer>
  );
}
