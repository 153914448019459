import React, { useState, useEffect } from "react";
import request from "../../utils/request";

import CRUD from "../../components/CRUD";

export default function LicensePeriod() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/licenseperiod`)
      .then((response) => {
        setData(response.data);

        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      data={data}
      loadingData={loadingData}
      url={"/api/licenseperiod"}
      updateData={updateData}
      title={"License Period"}
      columns={["periodName", "days", "trial"]}
      headers={["Name", "Days", "Is Trial"]}
      idField={"id"}
      fieldsMap={["periodName", "days", "trial"]}
      formFields={[
        { label: "Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Days", class: "col-12 col-md-6 px-0", required: true },
        { label: "Is Trial", class: "col-12 col-md-6 px-0", type: "checkbox", checkCategory: "primary", required: false }
      ]}
      detailFields={[
        { label: "Name", name: "periodName" },
        { label: "Days", name: "days" },
        { label: "Is Trial", name: "trial" }
      ]}
      baseObject={{
        periodName: "",
        days: "",
        trial: false
      }}
    />
  );
}
