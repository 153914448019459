import React, { useContext, useEffect, useState } from "react";
import request from "../../utils/request";
import SterilizationHeader from "../../components/SterilizationHeader/SterilizationHeader";
import Button from "@material-ui/core/Button";
import t from "../../utils/translation";

import printTicket from "../../utils/printTicket";
import PageContentLoading from "../../components/PageContentLoading";
import LocalizationContext from "../../utils/LocalizationContext";
import Ticket from "../../components/Ticket/Ticket";

const styles = {
  button: {
    backgroundColor: "#00b189",
    color: "white",
    marginTop: "10px",
  },
};

const TicketPackages = () => {
  const [packageDetail, setPackageDetail] = useState(null);
  const [loadingContent, setLoadingContent] = useState(true);
  const packageDetailId = localStorage.getItem("packageEditId");
  const locale = useContext(LocalizationContext);

  const getDetailPackage = async () => {
    await request()
      .get(`/api/package/${packageDetailId}`)
      .then((response) => {
        if (response.data.id) localStorage.removeItem("packageEditId");
        setPackageDetail(response.data);
      })
      .catch((e) => {
        console.log(e);
        localStorage.removeItem("packageEditId");
      });
  };

  useEffect(() => {
    getDetailPackage();
    setLoadingContent(false);
  }, []);

  if (loadingContent) return <PageContentLoading />;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SterilizationHeader
        subTitle={"Ticket"}
        title={"Esterilizacion"}
        urlBack={"/appfinalizedpackages"}
      />

      <Ticket packageDetail={packageDetail} />
      <Button
        variant="contained"
        style={styles.button}
        onClick={() => printTicket(packageDetail)}
      >
        {t("Print", locale) || "Print"}
      </Button>
    </div>
  );
};

export default TicketPackages;
