import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles ({
    root: {
        display: "flex",
        alignItems: "baseline",
        color: "#333",
        background: "#fff",
        textTransform: "uppercase",
        justifyContent: "center",

        "& ul": {
            marginBottom: "0!important"
        }
    },
    selectIcon: {
        color: theme.palette.terragene.lightMain
    },
    toolbar: {
        minHeight: "38px",
    },
    selectRoot:{
        color: "#333"
    },
    menuItem:{
        color: "#333"
    }
}));

