import React from "react";
import classNames from "classnames";

import useStyles from "./Button2Styles";

export default function Button2(props) {
  const classes = useStyles();
  const { type, 
    caption,
    disabled, 
    fullwidth, 
    onClick, 
    variant = "contained", 
    color = "primary", 
    loading = false, 
    loadingText = '', 
    hidden = false,
    isVerticalMargined = false,
    isLateralMargined = false
  } = props;

  if (loading) {
    return (
      <button
        type={type}
        className={classNames(
          classes.root,
          classes[variant + "Variant"],
          classes[color + "Color"],
          classes.disabled, {
          [classes.fullwidth]: fullwidth,
          [classes.verticalMargin]: isVerticalMargined,
          [classes.lateralMargin]: isLateralMargined
        })}
        onClick={classNames(classes.active)}
      >
        <span className={"spinner-border spinner-border-sm"} />
        <span style={{ marginLeft: 10 }}>{loadingText || 'Loading...'}</span>
      </button>
    )
  }

  return (
        <button
        hidden={hidden}
          type={type}
          className={ classNames(
            classes.root,
            classes[variant + "Variant"],
            classes[color + "Color"], {
            [classes.disabled]: disabled,
            [classes.active]: !disabled,
            [classes.fullwidth]: fullwidth,
            [classes.verticalMargin]: isVerticalMargined,
            [classes.lateralMargin]: isLateralMargined
          })}
          onClick={!disabled ? onClick : undefined}
        >
          {caption}
        </button>
  );
}

