// Librerías de terceros
import React, { useState, useEffect, useContext } from "react";
import { getRegionalFormat, getUserId } from "../../../utils/sessionHandler";
import t from "../../../utils/translation";
import LocalizationContext from "../../../utils/LocalizationContext";
import request from "../../../utils/request";
import Redirect from "../../../utils/Redirect";

// Estilos e imágenes
import styles from "./WashingMenu.module.css";
import pkgImg from "../../../assets/images/MENU PACKAGE.svg";
import loadImg from "../../../assets/images/MENU LOAD.svg";
import sterilizerImg from "../../../assets/images/MENU STERILIZATORS.svg";
import autoReadersImg from "../../../assets/images/MENU AUTOREADERS.svg";

// Componentes locales
import PageContainer from "../../../components/PageContainer/PageContainer";
import MenuTitleQ from "../../../components/MenuQ/MenuTitleQ/MenuTitleQ";
import MenuItemQ from "../../../components/MenuQ/MenuItemQ/MenuItemQ";
import InfoBar from "../../../components/MenuQ/infoBar/InfoBar";
import ButtonSterilization from "../../../components/ButtonSterilization";
import df from "../../../utils/dateFormater";
import parseFiltersDate from "../../../utils/parseFiltersDate";

export default function WashingMenu() {
  const [incubators, setIncubators] = useState([]);
  const [inProgressPackages, setInProgressPackages] = useState([]);
  const [finishedPackages, setFinishedPackages] = useState([]);
  const [savedLoads, setSavedLoads] = useState([]);
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);

  const locale = useContext(LocalizationContext);


  const getPackages = async () => {
    await request()
      .get( `/api/PackageWashing/list?includeCount=${true}&pageSize=${1000000000}&dateFrom=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat,
          true
        )}`)
      .then((response) => {
        setInProgressPackages(
          response.data.packageWashingList.filter((p) => p.packageWashingStatus === 1)
        );
        setFinishedPackages(response.data.packageWashingList.filter((p) => p.packageWashingStatus === 2));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLoads = async () => {
    request()
      .get( `/api/Chargewashing/list?includeCount=${true}&pageSize=${1000000000}&dateFrom=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(new Date(), "date"),
          getRegionalFormat().dateFormat,
          true
        )}`)
      .then((response) => {

        setSavedLoads(response.data.chargeWashingListatorList.filter((c) => c.chargeStatus === 1));
        setToStartLoads(response.data.chargeWashingListatorList.filter((c) => c.chargeStatus === 2));
        setInProgressLoads(response.data.chargeWashingListatorList.filter((c) => c.chargeStatus === 3));
        setFinishedLoads(response.data.chargeWashingListatorList.filter((c) => c.chargeStatus === 4));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPackages();
    getLoads();
  }, []);

  return (
    <PageContainer categoryId={2} category={t("WashingSpan")} backUrl={"/appmainQ"} progress={true}>
      <div className={styles.container}>
        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <MenuTitleQ title={t("TraysSpan")} image={pkgImg} />
            <MenuItemQ
              category={"washing"}
              title={t("ManageTraysSpan")}
              url={"/appmanagetrays"}
              addUrl={"/appcreatetrays"}
            >
              <InfoBar
                title={t("AssemblingSpan")}
                color={"yellow"}
                data={inProgressPackages}
              />
              <InfoBar
                title={t("ReadyForLoadSpan")}
                color={"purple"}
                data={finishedPackages}
              />
            </MenuItemQ>
          </div>
          <div className={styles.column}>
            <MenuTitleQ title={t("WasherLoadingSpan")} image={loadImg} />
            <MenuItemQ
              category={"washing"}
              title={t("ManageWasherLoadingSpan")}
              url={"/appmanagewasherloadings"}
              addUrl={"/appcreatewasherloading"}
            >
              <InfoBar
                title={t("BuildingWashingLoadSpan")}
                color={"yellow"}
                data={savedLoads}
              />
              <InfoBar title={t("ToWashSpan")} color={"purple"} data={toStartLoads} />
            </MenuItemQ>
          </div>

          <div className={styles.column}>
            <MenuTitleQ title={t("WashCycleSpan")} image={loadImg} />

            <MenuItemQ
              category={"washing"}
              title={t("ManageWashingCycleSpan")}
              add={false}
              url={"/appmanagewashcycle"}
            >
              <InfoBar
                title={t("ToStartSpan")}
                color={"purple"}
                data={toStartLoads}
              />
              <InfoBar
                title={t("InProgressSpan")}
                color={"yellow"}
                data={inProgressLoads}
              />
              <InfoBar
                title={t("FinishedSpan")}
                color={"purpleInverted"}
                data={finishedLoads}
              />
            </MenuItemQ>
          </div>
        </div>
        <div></div>
      </div>
    </PageContainer>
  );
}
