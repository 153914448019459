import { VisibilityOff, Visibility } from '@material-ui/icons';
import React, { useState } from 'react';

const ShowPassword = (props) => {
  const { parentCallback, style } = props;
  const [viewPassword, setViewPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setViewPassword(!viewPassword);
    parentCallback(); 
  };

  return (
    <div style={{ marginTop: '-10px' }}>
      {viewPassword ? (
        <Visibility onClick={togglePasswordVisibility} style={style} />
      ) : (
        <VisibilityOff onClick={togglePasswordVisibility} style={style} />
      )}
    </div>
  );
};

export default ShowPassword;
