import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import SterilizationHeader from "../../components/SterilizationHeader/SterilizationHeader";
import SterilizationTableAndDetail from "../../components/SterilizationTableAndDetail/SterilizationTableAndDetail";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

const SavedCharges = () => {
  const [packages, setPackages] = useState([]);
  const [chargeDetail, setChargeDetail] = useState({});
  const [charges, setCharges] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const fetchData = async () => {
    try {
      setModalLoading(true);
      const chargeResponse = await request().get("/api/charge");
      const packageResponse = await request().get("/api/package");
      const indicatorResponse = await request().get("/api/product");

      setCharges(chargeResponse.data.filter((c) => c.chargeStatus === 1));
      setPackages(packageResponse.data);
      setIndicators(indicatorResponse.data);
    } catch (error) {
      console.log(error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleCharges = (data) => {
    setCharges(data);
  };

  const handleChargeDetail = (value) => {
    let item = charges.find((p) => p.id === value);

    setSelectedCharge(value);

    if (value) {
      const packageIds = item.packages.map((p) => p.packageId);
      const filteredPackages = packages.filter((pkg) =>
        packageIds.includes(pkg.id)
      );
      let newCharge = { ...item, packages: filteredPackages };
      setChargeDetail(newCharge);
    } else {
      setChargeDetail({});
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "25px",
        height: "85vh",
      }}
    >
      <ModalLoading open={modalLoading} />

      <SterilizationHeader
        urlBack="/appcreatecharges"
        title="Esterilización"
        subTitle="Cargas en proceso de armado"
      />
      <SterilizationTableAndDetail
        handleData={handleCharges}
        data={charges}
        dataDetail={chargeDetail}
        handleDataDetail={handleChargeDetail}
        ticket={false}
        charge={true}
        selectedData={selectedCharge}
      />
    </div>
  );
};

export default SavedCharges;
