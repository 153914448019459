import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0 20px'
    },

    actionRoot: {
        display: 'flex',
        paddingLeft: 14,

        '& button': {
            margin: '0 5px'
        }
    },

    header: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: '16px 24px',
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
          fill: "currentcolor",
          width: "1em",
          height: "1em",
          fontSize: "1.5rem",
          userSelect: "none"
        }
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    }
})