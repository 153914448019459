import React, { useEffect, useState } from "react";
import request from "../../utils/request";
import { getDist, getSession } from "../../utils/sessionHandler";
import _ from "lodash";
import { unflatten, flatten } from "flat";

import useStyles from "./CompanyStyles";

import CRUD from "../../components/CRUD";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Progress from "../../components/Progress";
import PageContentLoading from "../../components/PageContentLoading";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { sortByStringField } from "../../utils/functions/Sorter";
import Select2 from "../../components/Select2";

export default function Company() {
  const classes = useStyles();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [loading, setloading] = useState(false);

  const [newAdministratorLastName, setNewAdministratorLastName] = useState("");
  const [newAdministratorName, setNewAdministratorName] = useState("");
  const [newAdministratorMail, setNewAdministratorMail] = useState("");
  const [companyToChangeAdmin, setCompanyToChangeAdmin] = useState(null);
  const [openChangeAdminConfirmation, setOpenChangeAdminConfirmation] =
    useState(false);
  const [changeAdminErrorMsg, setChangeAdminErrorMsg] = useState("");
  const [changeAdminSuccessMsg, setChangeAdminSuccessMsg] = useState("");
  const [countries, setCountries] = useState([]);

  const [selectedDistributor, setSelectedDistributor] = useState("");

  const [distributors, setDistributors] = useState([]);
  useEffect(() => {
    request()
      .get(`/api/distributor`)
      .then((response) => {
        setDistributors(
          response.data.reduce((distributorOptions, distr) => {
            distributorOptions.push([distr.id, distr.name]);
            return distributorOptions;
          }, [])
        );
        setLoadingContent(false);
      });
  }, []);

  useEffect(() => {
    request()
      .get(`/api/Country/`)
      .then((response) => {
        setCountries(
          sortByStringField(response.data, "name").reduce(
            (countriesOptions, country) => {
              countriesOptions.push([country.id, country.name]);
              return countriesOptions;
            },
            []
          )
        );
      });
  }, []);

  useEffect(() => {
    if (selectedDistributor) {
      setLoadingContent(true);
      updateData();
    }
  }, [selectedDistributor]);

  const updateData = () => {
    request()
      .get("/api/company/GetByDistributor?distributorId=" + selectedDistributor)
      .then((response) => {
        const comp = response.data.map((x) =>
          _.mapKeys(flatten(x), (value, key) => {
            return key.match(/^\w+\.\w+$/) ? key : "company." + key;
          })
        );
        setData(comp);
        setLoadingContent(false);
      });
  };

  const handleResetPassowrd = () => {
    setloading(true);
    request()
      .post("/api/appuser/resetpassword", {
        AppUser: unflatten(userToResetPassword).administrator,
        UserLogged: getSession(),
        DistributorId: getDist().id,
      })
      .then((response) => {
        setOpenResetPassword(false);
        setloading(false);
        setChangeAdminSuccessMsg(response.data);
        setTimeout(() => {
          setChangeAdminSuccessMsg("");
        }, 7000);
      })
      .catch((error) => {
        console.log(error.response);
        setloading(false);
      });
  };

  const clearNewAdministratorFields = () => {
    setNewAdministratorLastName("");
    setNewAdministratorName("");
    setNewAdministratorMail("");

    setChangeAdminErrorMsg("");
    setloading(false);
    setCompanyToChangeAdmin(null);
  };

  const handleChangeAdmin = () => {
    setloading(true);
    request()
      .post("/api/Company/setadministrator", {
        Company: unflatten(companyToChangeAdmin).company,
        UserLogged: getSession(),
        DistributorId: getDist().id,
        Administrator: {
          lastName: newAdministratorLastName,
          name: newAdministratorName,
          email: newAdministratorMail,
        },
      })
      .then((response) => {
        setOpenChangeAdminConfirmation(false);
        setChangeAdminSuccessMsg(response.data);
        clearNewAdministratorFields();
        updateData();
        setTimeout(() => {
          setChangeAdminSuccessMsg("");
        }, 3000);
      })
      .catch((error) => {
        setOpenChangeAdminConfirmation(false);
        setChangeAdminErrorMsg(error.response?.data?.message);
        setloading(false);
      });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <React.Fragment>
      {changeAdminSuccessMsg && (
        <div
          className="alert alert-success"
          role="alert"
          style={{ marginTop: 20 }}
        >
          {changeAdminSuccessMsg}
        </div>
      )}
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0"}>
          <div className={"col-12 col-md-5 px-0"}>
            <Select2
              label={"Distributor"}
              options={distributors}
              emptyOptionText={"Choose a Distributor"}
              variant={"outlined"}
              value={selectedDistributor}
              onChange={(val) => {
                setSelectedDistributor(val);
              }}
            />
          </div>
        </div>
      </div>
      {selectedDistributor && (
        <CRUD
          data={data}
          url={"/api/company"}
          title={"Company"}
          updateData={updateData}
          columns={[
            "company.name",
            "country.name",
            "company.city",
            "company.addres",
            "administrator.toString",
            "administrator.email",
          ]}
          headers={[
            "Name",
            "Country",
            "City",
            "Address",
            "Administrator",
            "Administrator Mail",
          ]}
          tableActions={[
            {
              action: (val) => {
                setOpenResetPassword(true);
                setUserToResetPassword(val);
              },
              icon: "M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z",
              tooltip: "Reset Password",
            },
            {
              action: (val) => setCompanyToChangeAdmin(val),
              icon: "M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z",
              tooltip: "Change Administrator",
            },
          ]}
          idField={"company.id"}
          fieldsMap={[
            "company.name",
            "country.id",
            "company.province",
            "company.city",
            "company.addres",
            "company.phone",
            "company.contact",
            "company.postalCode",
            "administrator.name",
            "administrator.lastName",
            "administrator.email",
          ]}
          formFields={[
            {
              label: "Name",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: "Country",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: countries,
              required: true,
            },
            {
              label: "Province",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: "City",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            { label: "Address", class: "col-12 col-md-6 px-0", regex: true },
            {
              label: "Phone",
              class: "col-12 col-md-6 px-0",
              regex: true,
              isPhoneField: true,
            },
            { label: "Contact", class: "col-12 col-md-6 px-0", regex: true },
            {
              label: "Postal Code",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: "Administrator Name",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: "Administrator Last Name",
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: "Administrator Mail",
              class: "col-12 col-md-6 px-0",
              newOnly: true,
              required: true,
            },
          ]}
          detailFields={[
            { label: "Name", name: "company.name" },
            { label: "Country", name: "country.name" },
            { label: "Province", name: "company.province" },
            { label: "City", name: "company.city" },
            { label: "Address", name: "company.addres" },
            { label: "Phone", name: "company.phone" },
            { label: "Contact", name: "company.contact" },
            { label: "Postal Code", name: "company.postalCode" },
            { label: "Administrator Name", name: "administrator.toString" },
            { label: "Administrator Mail", name: "administrator.email" },
          ]}
          baseObject={{
            company: {
              id: null,
              name: "",
              country: { id: null },
              countryId: "",
              city: "",
              addres: "",
              phone: "",
              province: "",
              contact: "",
              postalcode: "",
              distributorId: selectedDistributor,
            },
            administrator: {
              name: "",
              lastName: "",
              email: "",
              password: "",
            },
            UserLogged: getSession(),
          }}
        />
      )}
      {openResetPassword && (
        <Modal
          closeModal={() => setOpenResetPassword(false)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>Reset password</span>
              </div>
              <Progress active={loading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={"Confirm"}
                variant={"text"}
                onClick={handleResetPassowrd}
                disabled={loading}
              />
              <Button
                caption={"Cancel"}
                onClick={() => setOpenResetPassword(false)}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                Are you sure you want to reset the password for the user{" "}
                <b>
                  {userToResetPassword["administrator.name"]}{" "}
                  {userToResetPassword["administrator.lastName"]}
                </b>
                ?
              </div>
            </div>
          </div>
        </Modal>
      )}
      {companyToChangeAdmin && !openChangeAdminConfirmation && (
        <Modal
          closeModal={clearNewAdministratorFields}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>Change Administrator</span>
              </div>
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={"Confirm"}
                variant={"text"}
                onClick={() => setOpenChangeAdminConfirmation(true)}
                disabled={
                  loading ||
                  newAdministratorLastName === "" ||
                  newAdministratorName === "" ||
                  newAdministratorMail === ""
                }
              />
              <Button
                caption={"Cancel"}
                onClick={clearNewAdministratorFields}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            {changeAdminErrorMsg && (
              <div className="alert alert-danger" role="alert">
                {changeAdminErrorMsg}
              </div>
            )}
            <div className={"row"}>
              <div
                className={"col-12 col-md-6 px-0 mx-0"}
                style={{ textAlign: "center" }}
              >
                <Input
                  label={"Last Name"}
                  value={newAdministratorLastName}
                  onChange={(val) => setNewAdministratorLastName(val)}
                />
              </div>
              <div
                className={"col-12 col-md-6 px-0 mx-0"}
                style={{ textAlign: "center" }}
              >
                <Input
                  label={"Name"}
                  value={newAdministratorName}
                  onChange={(val) => setNewAdministratorName(val)}
                />
              </div>
              <div
                className={"col-12 col-md-6 px-0 mx-0"}
                style={{ textAlign: "center" }}
              >
                <Input
                  label={"Mail"}
                  value={newAdministratorMail}
                  onChange={(val) => setNewAdministratorMail(val)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {openChangeAdminConfirmation && (
        <Modal
          closeModal={() => {
            clearNewAdministratorFields();
            setOpenChangeAdminConfirmation(false);
          }}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>Change Administrator</span>
              </div>
              <Progress active={loading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={"Confirm"}
                variant={"text"}
                onClick={() => handleChangeAdmin()}
                loading={loading}
                loadingText={"Saving"}
              />
              <Button
                caption={"Cancel"}
                onClick={() => {
                  clearNewAdministratorFields();
                  setOpenChangeAdminConfirmation(false);
                }}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                The user <b>{companyToChangeAdmin["administrator.toString"]}</b>{" "}
                for the company{" "}
                <b>{companyToChangeAdmin["company.toString"]}</b> will be
                deleted and a new one will be created. Are you sure you want to
                continue?
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
