import React from 'react';
import df from '../../utils/dateFormater';
import Input from '../Input';
import classNames from "classnames";
import useStyles from "./GroupedDetailStyles";
import { sortByField } from '../../utils/functions/Sorter';

// groups: array con los diferentes grupos, se toma como base para order los detailFields
// detailFields: información de los campos a mostrar. Contiene la info para saber que campo pertenece a que grupo.
// detailObject: es el objeto que tiene la info para mostrar.
const GroupedDetail = ({ groups, detailFields, detailObject }) => {
    const classes = useStyles()

    const setVisibilityGroupDependencies = (value, visibilityGroupDependencies) => {
        if (visibilityGroupDependencies && visibilityGroupDependencies.length > 0) {
            visibilityGroupDependencies.forEach((visibleGroupDependency) => {
                let group = groups.find(x => x.Index === visibleGroupDependency.groupIndex)
                if (visibleGroupDependency.condition == value) {
                    group.visible = visibleGroupDependency.visible
                }
                else {
                    group.visible = false
                }
            })
        }
    }

    const setVisibilityFieldDependencies = (value, visibilityFieldDependencies, field) => {
        if (visibilityFieldDependencies && visibilityFieldDependencies.length > 0) {
            visibilityFieldDependencies.forEach((visibleFieldDependency) => {
                if(value === visibleFieldDependency.condition)
                    field.visible = visibleFieldDependency.visible;
            })
        }
    }

    return (
        <div>
            {
                groups && sortByField(groups, 'position').map((group, index) => {
                    return (
                        group.visible && 
                        <div key={index} className={classes.groupedDiv}>
                            <h6 className={classes.groupedTitle}>{group.Description}</h6>
                            <div className={classNames('row', 'col-lg-12')}>
                                {
                                    detailFields && sortByField(detailFields.filter(x => x.groupIndex === group.Index), 'position').map((field, index) => {
                                        field.visible = true;
                                        const value = field.type === "date" ? 
                                        df(detailObject[field.name], "date")
                                        : field.type === "dateTime" ? 
                                            df(detailObject[field.name], "dateTime")
                                            : detailObject[field.name];
                                        if (field.visibilityGroupDependencies) setVisibilityGroupDependencies(detailObject[field.valueVisibilityGroup], field.visibilityGroupDependencies)
                                        if (field.visibilityFieldDependencies) setVisibilityFieldDependencies(detailObject[field.valueVisibilityField], field.visibilityFieldDependencies, field)
                                        if (field.type === "image") {
                                            return (
                                                <div key={index} className={classes.imgSize}>
                                                    <div className="col-12 px-0">
                                                        <Input
                                                            label={field.label}
                                                            value={value}
                                                            variant={"image"}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            field.visible &&
                                            <div className="col-12 col-md-6 px-0" key={index}>
                                                <Input
                                                    label={field.label}
                                                    value={value}
                                                    variant={"detail"}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default GroupedDetail;