import React from "react";
import styles from "./MenuTitleQ.module.css";

export default function MenuTitleQ({ title, image }) {
  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{title}</h6>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt={title} />
      </div>
    </div>
  );
}
