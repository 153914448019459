import React, { useState, useEffect } from "react";
import CRUD from "../../components/CRUD";
import request from "../../utils/request";


export default function Methods () {
 const [data, setData] = useState([]);
 const [loadingData, setLoadingData] = useState(true);

 const machineTypesArr = [[null, "CHOOSE A MACHINE TYPE"], [1, "STERIALIZER"], [2, "WASHER"], [3, "DISINFECTOR"]]



 useEffect(() => {
    updateData()
  }, [])



 const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/Method`)
      .then((response) => {
        const machineTypesMapped = response.data.reduce((readingsList, reading) => {
            let machineTypeNameFromArr = "";
            for (let i = 0; i < machineTypesArr.length; i++) {
              if (machineTypesArr[i][0] === reading.machineType) {
                machineTypeNameFromArr = machineTypesArr[i][1];
              }
            }
            
  
            readingsList.push({
              ...reading,
              machineTypeName: machineTypeNameFromArr,
            });
            
            return readingsList;
          }, [])



        setData(machineTypesMapped);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };



    return(
<React.Fragment>
        <CRUD 

            data={data}
            loadingData={loadingData}
            url={`/api/Method/`}
            updateData={updateData}
            title={'Methods'}
            columns={['name', 'machineTypeName']}
            headers={['Method', 'Machine Type']}
            idField={'id'}
            fieldsMap={['name', 'machineType']}
            formFields={[
            { label: "Method", class: "col-12 col-md-6 px-0", required: true, regex: true },
            { label: "Machine Type", class: "col-12 col-md-6 px-0", type: "select", options: machineTypesArr, required: true, regex: true }
            ]}
            detailFields={[
            { label: "Method", name: "name" },
            { label: "Machine Type", name: "machineTypeName" },
            ]}
            baseObject={{
                Method: {id:"",
                name: "",
                machineType: null}
            }}
        />
    </React.Fragment>
    )
    
}