import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";
import { useParams } from "react-router-dom";
import UserResetPassword from "../components/Form/UserResetPassword";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Configuration,
    key: "",
  };
  const screenName = 320
  // const locale = useLocale(screenName);
  const params = useParams();

  const locale = {
    form: {
      input: {
        email: 'Email',
        username: 'User Name',
        password: 'Password',
        newPassword: 'New Password',
        repeatNewPassword: 'Repeat New Password',
        name: 'Name',
        lastName: 'Last Name',
        company: 'Company',
        sectorName: 'Sector'
      },
      submit: {
        login: 'Login',
        changePassword: 'Change Password',
        resetPassword: 'Reset Password',
      }
    },
    header: {
      signup: 'New Company - Sign up',
      login: 'Login',
      forgotPassword: 'Reset Password',
      changePassword: 'Change Password',
    },
    link: {
      forgotPassword: 'Forgot Your Password?',
      signup: "New Company"
    },
    companyForm: {
      name: 'Company name',
      country: 'Country',
      city: 'City',
      address: 'Address',
      confirm: 'Confirm',
      eulaText: "I've read and accept the terms and conditions",
      seeEulaText: "See terms and conditions",
      province: 'State / Province / Region',
      postalCode: 'Postal Code',
      phone: 'Phone',
      companyRepresentative: 'Contact / Representative',
      language: 'Company Language'
    },
    eulaForm: {
      acceptButton: 'Accept',
      cancelButton: 'Cancel',
      title: 'Terms and conditions'
    }
  }

  return (
      <LocalizationContext.Provider value={locale}>
          <UserResetPassword params={params}/>
      </LocalizationContext.Provider>
  );
};
