import axios from "axios";
import { dotnetToMomentLocaleInfo,
         dotnetToMomentShortTimeFormat,
         dotnetToMomentShortDateFormat } from "../utils/dateFormater";
import { parseJwtData, parseJwtExp } from "../utils/functions/ParserJwt"

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};
Storage.prototype.getObject = function (key) {
  return JSON.parse(this.getItem(key));
};

export const getSession = () => {
  const jwt = localStorage.getObject("__token");

  if (!jwt) return null;
  let json = parseJwtData(jwt);
  return json
};

export const login = (jwt) => {
  localStorage.setObject("__token", jwt);
};

export const saveDist = (info) => {
  info.id = encodeURIComponent(info.id);
  localStorage.setObject("__dist", info);
};

export const getDist = (info) => {
  return localStorage.getObject("__dist");
};

export const logout = () => {
  axios
    .post(
      process.env.REACT_APP_SERVER_URL + "/api/logout/logout",
      { Email: getSession().Email, DistId: getDist().id },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "bearer " + getToken(),
        },
      }
    )
    .finally(() => {
      localStorage.removeItem("__token");
      localStorage.removeItem("menuOptions");
      localStorage.removeItem("__generalObjects");
      localStorage.removeItem("__userLanguage");
      localStorage.removeItem("__dist");
      localStorage.removeItem("__regionalFormat");
      localStorage.removeItem("__eula");

      const screensId = localStorage.getObject("screensId");
      screensId &&
        Object.keys(screensId).map((id) => localStorage.removeItem(id));
      localStorage.removeItem("screensId");

      window.location.href = "/login";
    })
    ;
};

export const logoutExpiredSession = () => {
  localStorage.removeItem("__token");
  localStorage.removeItem("menuOptions");
  localStorage.removeItem("__generalObjects");
  localStorage.removeItem("__userLanguage");
  localStorage.removeItem("__dist");
  localStorage.removeItem("__regionalFormat");
  localStorage.removeItem("__eula");

  const screensId = localStorage.getObject("screensId");
  screensId && Object.keys(screensId).map((id) => localStorage.removeItem(id));
  localStorage.removeItem("screensId");

  window.location.href = "/login";
};

export const getSysUserLanguage = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.PermittedLanguage;
};

export const getUserName = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.toString;
};

export const getUserId = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.Id;
};

export const getUserHash = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.EmailHash;
};

export const getUserType = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.UserType;
};

export const getToken = () => {
  const jwt = localStorage.getObject("__token");
  if (!jwt) return null;

  return jwt;
};

export const checkToken = () => {
  const jwt = localStorage.getObject("__token");
  if (!jwt) return null;

  if (sessionExpired()) {
    window.location.href = "/sessionExpired";
    return jwt;
  } else {
    return jwt;
  }
};

export const getExpirationTime = (jwt) => {
  return parseJwtExp(jwt);
};

export const sessionExpired = () => {
  const jwt = localStorage.getObject("__token");
  if (new Date().getTime() / 1000 > getExpirationTime(jwt)) {
    return true;
  } else {
    return false;
  }
};

export const getUserCompany = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.Company;
};

export const getSectorId = () => {
  const jwt = getSession();
  if (!jwt) return null;

  return jwt.SectorId;
};

export const setMenu = (menu) => {
  localStorage.setObject("menuOptions", menu);
};

export const getMenu = () => {
  const menuOptions = localStorage.getObject("menuOptions");

  let newConfiguration = menuOptions?.Configuration?.Items?.filter((item) => !item?.MigrationDatabase);
  let newMenuOptions = menuOptions ? {...menuOptions, Configuration: {...menuOptions.Configuration, Items: newConfiguration}} : null

  if (menuOptions) {
    return newMenuOptions
  } else {
    return null;
  }
};

export const setTranslation = (id, translation) => {
  const screensId = localStorage.getObject("screensId");
  localStorage.setObject("screensId", { ...screensId, [id]: true });

  localStorage.setObject(id, translation);
};

export const getTranslation = (screenId) => {
  return localStorage.getObject(screenId);
};

export const setGeneralObjects = (generalObjectsTranslations) => {
  localStorage.setObject("__generalObjects", generalObjectsTranslations);
};

export const setRowsPerPageStorage = (rowsPerPage) => {
  localStorage.setObject("__rowsPerPage", rowsPerPage);
};

export const getGeneralObjects = () => {
  return localStorage.getObject("__generalObjects");
};

export const setUserLanguage = (lang) => {
  localStorage.setObject("__userLanguage", lang);
};

export const getUserLanguage = () => {
  return localStorage.getObject("__userLanguage");
};

export const getRowsPerPage = (cantRows) => {
  const rowsPerPage = localStorage.getItem("__rowsPerPage");
  if(rowsPerPage === "undefined" || rowsPerPage === null){
    setRowsPerPageStorage(cantRows);
    return localStorage.getObject("__rowsPerPage");
  }
  else 
    return rowsPerPage;
};

export const setRegionalFormat = (regionalFormat) => {
  regionalFormat.languageCode = dotnetToMomentLocaleInfo(regionalFormat.languageCode);
  regionalFormat.dateFormat = dotnetToMomentShortDateFormat(regionalFormat.dateFormat);
  regionalFormat.timeFormat = dotnetToMomentShortTimeFormat(regionalFormat.timeFormat);
  localStorage.setObject("__regionalFormat", regionalFormat);
};

export const setEula = (eula) => {
  localStorage.setObject("__eula", eula);
};

export const getEula = () => {
  return localStorage.getObject("__eula");
};

export const getRegionalFormat = () => {
  return localStorage.getObject("__regionalFormat");
};
