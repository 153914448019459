import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        // float: 'left',
        // paddingLeft: 15, 
        // backgroundColor: "#f3f3f3",
        // marginBottom: "10px"
        display: "flex",
        alignItems: "center", 
        gap: "10px", 
        backgroundColor: "#f3f3f3",
        padding: "5px 15px",
        marginBottom: "10px",
    },

}))