import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    selectsContainer: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'space-between',

        '& div': {
            width: '48%'
        }
    },

    loadingDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 45
    },
    
    editorWrapper: {
        margin: '20px 25px',   
        '&>div': {
            border: '1px solid #b3b3b3',

            '&>div:nth-child(1)': {
                border: 'none',
                borderBottom: '1px solid #F1F1F1',
            },

            '&>div:nth-child(2)': {
                padding: '0 10px'
            }
        }
    },

    parameterTag: {
        userSelect: 'none',
        margin: '0 10px',
        padding: 10,
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
        borderRadius: 4,
        cursor: 'pointer'
    },

    selectedParameterTag: {
        backgroundColor: 'rgba(0,0,0,.18)',
        boxShadow: 'inset 0 .125rem .25rem rgba(0,0,0,.075)',
        textDecoration: 'line-through',
        cursor: 'default'
    }
})