import React, { useEffect, useRef } from "react";
import styles from "./DDColumn.module.css";

const DDColumn = ({ title, dragOver, drop, children, id, autoScroll,rule}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && autoScroll) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [children]); 

  return (
    <div
      className={styles.column}
      onDragOver={dragOver}
      onDrop={drop}
    >
      <h6 className={styles.title}>{title}</h6>
      <div id={id} className={rule ? styles.ddZoneRule : styles.ddZone} ref={scrollRef}>
        {children}
      </div>
    </div>
  );
};

export default DDColumn;
