import React, { useState, useEffect, useRef, useContext } from "react";
import request from "../../utils/request";
import Redirect from "../../utils/Redirect";

import DragAndDropRules from "../../components/DragAndDropRules/DragAndDropRules";
import Search from "../../components/Search/Search";

import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { getUserCompany } from "../../utils/sessionHandler";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Checkbox, Grid } from "@material-ui/core";
import styles from "./RuleForm.module.css";
import PageContainer from "../../components/PageContainer/PageContainer";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ";
import InputQ from "../../components/FormComponents/InputQ/InputQ";
import InputDateQ from "../../components/FormComponents/InputDateQ/InputDateQ";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ";
import TimeRule from "../../components/TimeRule/TimeRule"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function RuleForm() {
  const company = getUserCompany();

  const [ruleId, setRuleId] = useState(null);
  const [ruleEditId, setEditRuleId] = useState(null);

  const [norms, setNorms] = useState([]);
  const [normsToFilter, setNormsToFilter] = useState([]);
  const [normId, setNormId] = useState(null);
  const [currentNormId, setCurrentNormId] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [newRule, setNewRule] = useState(false);
  const [name, setName] = useState("");
  const [privateName, setPrivateName] = useState("");

  const [loadTypes, setLoadTypes] = useState([]);
  const [loadTypeId, setLoadTypeId] = useState(null);
  const [helpUser, setHelpUser] = useState("");
  const [methods, setMethods] = useState([]);
  const [methodId, setMethodId] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [process, setProcess] = useState(["Sterilization", "Washing"]);
  const [applyTo, setApplyTo] = useState(["Load", "Packages"]);
  const [mandatory, setMandatory] = useState(false);
  const [active, setActive] = useState(false);
  const [implant, setImplant] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [forAny, setForAny] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredSelectedProducts, setFilteredSelectedProducts] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEditTime, setSelectedEditTime] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validateFrequency, setValidateFrequency] = useState(false);
  const [validateProducts, setValidateProducts] = useState(false);
  const [validateDates, setValidateDates] = useState(false);
  const [validateTime, setValidateTime] = useState(false)
  const [validateMethod, setValidateMethod] = useState(false)

  const locale = useContext(LocalizationContext);

  const nameRef = useRef();
  const frequencyRef = useRef();

  useEffect(() => {
    fetchDataRule();
    // fetchGlobalNorms();
    // fetchLoadTypes();
    fetchMethods();
    // fetchProducts();
  }, []);

  const clearFields = () => {
    setRuleId(null);
    setName("");
    setHelpUser("");
    setSelectedMethod("");
    setMandatory(false);
    setActive(true);
    setImplant(false)
    setEmpty(false)
    setForAny(false)
    setFilteredProducts([])
    setSelectedProducts([])
    setNewRule(true);
    setMethodId(null)
    setSelectedTime(null)
  };

  const fetchMethods = () => {
    setLoadingData(true);
    request()
      .get(`/api/method/1`)
      .then((response) => {
        let newMethods = response.data.map((m) => ({
          value: m.id,
          name: m.name,
        }));
        setMethods(newMethods);

        setMethodId(response.data[0].id);
        // console.log(newMethods);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const fetchProducts = (newMethod) => {
    setLoadingData(true);
    request()
      .get(`/api/product/list?methodId=${newMethod}&process=0`)
      .then((response) => {
        setProducts(
          response.data.map((p) => {
            return { id: p.id, name: p.name, description: p.name };
          })
        );
        setFilteredProducts(
          response.data.map((p) => {
            return { id: p.id, name: p.name, description: p.name };
          })
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const fetchDataRule = () => {
    const ruleEditId = localStorage.getItem("ruleEditId");
    setEditRuleId(ruleEditId)
    if (!ruleEditId) {
      clearFields()
      return;
    }

    setLoadingData(true);
    request()
      .get(`/api/Rule/list`)
      .then((response) => {
      const objectToEdit = response.data.find((obj) => obj.ruleId == ruleEditId);
      if (objectToEdit) {
        setRuleId(objectToEdit.ruleId);
        setName(objectToEdit.ruleName);
        setHelpUser(objectToEdit.helpUser);
        setSelectedMethod(objectToEdit.methodId);
        setMandatory(objectToEdit.mandatory);
        setActive(objectToEdit.active);
        setEmpty(objectToEdit.empty)
        setForAny(objectToEdit.mustRepeat)
        setImplant(objectToEdit.implant)
        // setSelectedDays(response.data.days)
        setSelectedTime(objectToEdit.days)
        setSelectedProducts(
          objectToEdit.products.map((p) => {
            return { id: p.productId, name: p.productName };
          })
        );

        setNewRule(false);
        removeLocalStorage();
        // console.log(JSON.stringify(response.data));
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
  }})
      .catch((error) => {
        console.log(error.response);
      });
  };

  const validateFields = () => {
    let error = false;
    if (
      (name == null || name == undefined || name.length == 0) &&
      privateName.length == 0
    ) {
      setLoadingData(false);
      setValidate(true);
      error = true;
      //nameRef.current.style.borderBottom = "4px solid #cc3300";
    }
    if (
      (selectedMethod == null || selectedMethod == undefined || selectedMethod.length == 0) 
    ) {
      setLoadingData(false);
      setValidateMethod(true);
      error = true;
    }
    // if (selectedDays == null || selectedDays == undefined || selectedDays < 1) {
    //   setLoadingData(false);
    //   setValidateTime(true);
    //   error = true;
    // }
    if (forAny == true) {
      setValidateTime(false);
    } else if (!selectedTime ||
    selectedTime.length === 0 || 
    selectedTime.some(item => 
      (Array.isArray(item.day) && item.day.length === 0) || 
      item.time == null
    )) {
      setLoadingData(false); 
      setValidateTime(true);
      error = true;
    }
    if (
      selectedProducts == null ||
      selectedProducts == undefined ||
      selectedProducts.length < 1
    ) {
      setLoadingData(false);
      setValidateProducts(true);
      error = true;
    }
    return error;
  };

  const handleChangeName = (value) => {
    setName(value.target.value);
  };

  
  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setValidateTime(false);
  }

  const removeLocalStorage = () => {
    const itemToRemove = localStorage.getItem("ruleEditId");

    if (itemToRemove) localStorage.removeItem("ruleEditId");
  };

  const onCancelClick = () => {
    removeLocalStorage();

    Redirect({
      redirect: true,
      path: "/apprules",
    });
  };

  const onSaveClick = () => {
    setLoadingData(true);

    if (validateFields()) return;

    const ruleInput = {
      Name:
        name != null && name != undefined && name != "" ? name : privateName,
      HelpUser: helpUser,
      methodId: selectedMethod,
      // Days: selectedDays,
      mustRepeat: forAny,
      Days: selectedTime,
      ApplyTo: 0,
      Process: 0,
      Mandatory: mandatory,
      Active: active,
      Implant: implant,
      Empty: empty,
      productIds: selectedProducts.map((sp) => {
        return sp.id;
      }),
      companyId: company.Id,
      // normId: currentNormId,
      // loadTypeId: loadTypeId,
      // frequency: frequency,
      // from: from,
      // to: to,
    };
    if (!newRule) ruleInput.id = ruleId;

    removeLocalStorage();
    if (!newRule) {
      updateRule(ruleInput);
      toast.success(
        t("RuleSuccessfullyModified") || "The rule was successfully modified"
      );
    } else {
      addRule(ruleInput);
      toast.success(
        t("RuleSuccessfullyCompleted") ||
          "The Rule was successfully completed"
      );
    }
  };

  const updateRule = (ruleInput) => {
    request()
      .put(`/api/rule`, ruleInput)
      .then(() => {
        setLoadingData(false);
        // Redirect({
        //   redirect: true,
        //   path: "/apprules",
        // });
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }
        console.log(error.response.data.message);
        setLoadingData(false);
      });
  };

  const addRule = (ruleInput) => {
    request()
      .post(`/api/rule`, ruleInput)
      .then(() => {
        setLoadingData(false);
        // Redirect({
        //   redirect: true,
        //   path: "/apprules",
        // });
        clearFields()
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }
        setLoadingData(false);
      });
  };

  const handleLoadType = (lt) => setLoadTypeId(lt.target.value);
  const handleChangeHelpUser = (value) => {
    setHelpUser(value.target.value);
  };
  
  // const handleMethod = async (e) => {
  //   const newMethod = e.target.value;
  //   setSelectedMethod(newMethod);
  //   if (newMethod) {  
  //     fetchProducts(newMethod);
  //   } else {
  //     setProducts([])
  //     setFilteredProducts([])
  //   }
  // };
  const handleMethod = async (e) => {
    const newMethod = e.target.value;
    setSelectedMethod(newMethod);
    setValidateMethod(false);
    setSelectedProducts([])
    if (!newMethod) { 
      setProducts([]);
      setFilteredProducts([]);
    }
  };
  useEffect(() => {
    if (selectedMethod) {  
      fetchProducts(selectedMethod);
    }
  }, [selectedMethod]);

  // useEffect(() => {
  //   if (newRule) {  
  //   }
  // }, [selectedTime]);

  const handleChangeFrequency = (value) => setFrequency(value.target.value);
  const handleChangeMandatory = (m) => setMandatory(m.target.checked);
  const handleChangeActive = (a) => setActive(a.target.checked);
  // const handleChangeImplant = (i) => setImplant(i.target.checked);
  // const handleChangeEmpty = (e) => setEmpty(e.target.checked);
  const handleChangeEmpty = (event) => {
    const isChecked = event.target.checked;
    setEmpty(isChecked);
    if (isChecked) 
      setImplant(false);
      //  if (!newRule)
      // setSelectedTime([])
  };
  const handleChangeImplant = (e) => {
    const isChecked = e.target.checked;
    setImplant(isChecked);
    if (isChecked) setEmpty(false); 
    //  if (!newRule)
    //   setSelectedTime([])
  };
  const handleChangeForAny = (a) => {
    const isChecked = a.target.checked
    setForAny(isChecked);
    // if (!newRule)
    //   setSelectedTime([])
  };
  const handleChangeFrom = (f) => {
    if (to && f > to) {
      setValidateDates(true);
      return;
    }
    setValidateDates(false);
    setFrom(f);
    //var txtDate = f ? f.startOf('day').toISOString(true) : null;
    //setFrom(txtDate);
  };
  const handleChangeTo = (f) => {
    if (from && from > f) {
      setValidateDates(true);
      return;
    }
    setValidateDates(false);
    setTo(f);

    //var txtDate = f ? f.startOf('day').toISOString(true) : null;
    //setTo(txtDate);
  };
  const handleSelectedProducts = (sl) => {
    setSelectedProducts(sl);
    setValidateProducts(false);
  };
  const handleProducts = (arr) => {
    /*let filtered = products.filter((d) =>
            d.description.toLowerCase().includes(arr.toLowerCase())
        );*/
    setFilteredProducts(arr);
  };

  const handleFilteredSelectedProducts = (arr) => {
    /*let filtered = products.filter((d) =>
            d.description.toLowerCase().includes(arr.toLowerCase())
        );*/
    setFilteredSelectedProducts(arr);
  };

  const handleSearchActive = (bool) => setSearchActive(bool);

  const subtitle =
    (newRule ? t("CreateLabel") || "Create" : t("Save") || "Save")

  const obligatoryMsg = (
    <p style={{ color: "#cc3300" }}>
      *{t("This field is required") || "This field is required"}
    </p>
  );

  const validationDatesMsg = (
    <p style={{ color: "#cc3300" }}>
      *
      {t("La fecha 'To' debe ser mayor a 'From'") ||
        "La fecha 'To' debe ser mayor a 'From'"}
    </p>
  );

  return (
    <PageContainer
      category={t("SterilizationSpan") || "Sterilization"}
      backUrl={"/apprules"}
      subcategory={t("BlankEntry3") || "Rules"}
      title={t("Create") || "Create"}
      categoryId={1}
    >
      <>
        {loadingData ? (
          <div className="spinnerContainer">
              <SpinnerQ />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "25px",
                marginLeft: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} sm={6}>
                    <InputQ
                      label={t("Norm", locale) || "Norm"}
                      value={name}
                      handleChange={handleChangeName}
                      containerWidth={"100%"}
                      required={true}
                      errorMessage={
                        t("RequiredField", locale) || "This field is required"
                      }
                      validate={validate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectQ
                      selectValue={selectedMethod}
                      handleSelect={handleMethod}
                      options={methods}
                      label={t("Method", locale) || "Method"}
                      placeholder={t("SelectAMethod", locale) || "Select a Method"}
                      containerWidth={"100%"}
                      required={true}
                      errorMessage={
                        t("RequiredField", locale) || "This field is required"
                      }
                      validate={validate}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputQ
                      value={helpUser}
                      handleChange={handleChangeHelpUser}
                      label={t("Help", locale) || "Help"}
                      containerWidth={"100%"}
                    />
                  </Grid>
                <Grid container  xs={12} sm={6}>
                <Grid item xs={12} sm={6}>
                    <SelectQ
                      selectValue={"loadTypeId"}
                      options={loadTypes}
                      handleSelect={handleLoadType}
                      containerWidth={"90%"}
                      label={t("Process", locale) || "Process"}
                      placeholder={t("Sterilization", locale) || "Sterilization"}
                      // required={true}
                      errorMessage={
                        t("RequiredField", locale) || "This field is required"
                      }
                      validate={validate}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectQ
                      selectValue={"loadTypeId"}
                      options={loadTypes}
                      handleSelect={handleLoadType}
                      containerWidth={"90%"}
                      label={t("Apply to", locale) || "Apply to"}
                      placeholder={t("Load", locale) || "Load"}
                      // required={true}
                      errorMessage={
                        t("RequiredField", locale) || "This field is required"
                      }
                      validate={validate}
                      disabled={true}
                    />
                  </Grid>
                </Grid>

                  <Grid container  xs={12} sm={6}>
                    <Grid container alignContent="center" xs={12} sm={3}>
                      <div
                        className="info-container"
                        style={{ marginRight: "10px", fontSize: "0.875rem",transition: "color 0.3s",fontWeight: "700" }}
                      >
                        <label style={{ minWidth: "fit-content" }}>
                          {t("Mandatory", locale) || "Mandatory"}:
                        </label>
                      </div>

                      <Checkbox
                        checked={mandatory}
                        onChange={handleChangeMandatory}
                      />
                    </Grid>
                    <Grid container alignContent="center" xs={12} sm={3}>
                      <div
                        className="info-container"
                        style={{ marginRight: "10px", fontSize: "0.875rem",transition: "color 0.3s",fontWeight: "700" }}
                      >
                        <label style={{ minWidth: "fit-content" }}>
                          {t("Active", locale) || "Active"}:{" "}
                        </label>
                      </div>

                      <Checkbox
                        checked={active}
                        onChange={handleChangeActive}
                      />
                    </Grid>
                    <Grid container alignContent="center" xs={12} sm={3}>
                      <div
                        className="info-container"
                        style={{ marginRight: "10px", fontSize: "0.875rem",transition: "color 0.3s",fontWeight: "700"}}
                      >
                        <label style={{ minWidth: "fit-content" }}>
                          {t("Implant", locale) || "Implant"}:
                        </label>
                      </div>
                      <Checkbox
                        checked={implant}
                        onChange={handleChangeImplant}
                      />
                    </Grid>
                    <Grid container alignContent="center" xs={12} sm={3}>
                      <div
                        className="info-container"
                        style={{ marginRight: "10px", fontSize: "0.875rem",transition: "color 0.3s",fontWeight: "700" }}
                      >
                        <label style={{ minWidth: "fit-content" }}>
                          {t("Empty", locale) || "Empty"}:
                        </label>
                      </div>
                      <Checkbox
                        checked={empty}
                        onChange={handleChangeEmpty}
                      />
                    </Grid>
                  </Grid>

                  
                  <div className={styles.label}>
                    Select day
                    <TimeRule />
                  </div> */}

                {/* OPCION 1 */}
                  {/* <Grid container spacing={2}>
                  
                    <Grid item xs={12} sm={6}>
                      <InputQ
                        label={t("Norm", locale) || "Norm"}
                        value={name}
                        handleChange={handleChangeName}
                        containerWidth="100%"
                        required={true}
                        errorMessage={t("RequiredField", locale) || "This field is required"}
                        validate={validate}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputQ
                        value={helpUser}
                        handleChange={handleChangeHelpUser}
                        label={t("Help", locale) || "Help"}
                        containerWidth="100%"
                      />
                    </Grid>

                    <Grid container item xs={12} sm={6} spacing={2}>
                      <Grid item xs={6}>
                        <SelectQ
                          selectValue="loadTypeId"
                          options={loadTypes}
                          handleSelect={handleLoadType}
                          containerWidth="90%"
                          label={t("Process", locale) || "Process"}
                          placeholder={t("Sterilization", locale) || "Sterilization"}
                          errorMessage={t("RequiredField", locale) || "This field is required"}
                          validate={validate}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectQ
                          selectValue="loadTypeId"
                          options={loadTypes}
                          handleSelect={handleLoadType}
                          containerWidth="90%"
                          label={t("Apply to", locale) || "Apply to"}
                          placeholder={t("Load", locale) || "Load"}
                          errorMessage={t("RequiredField", locale) || "This field is required"}
                          validate={validate}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <SelectQ
                        selectValue={selectedMethod}
                        handleSelect={handleMethod}
                        options={methods}
                        label={t("Method", locale) || "Method"}
                        placeholder={t("SelectAMethod", locale) || "Select a Method"}
                        containerWidth="100%"
                        required={true}
                        errorMessage={t("RequiredField", locale) || "This field is required"}
                        validate={validate}
                      />
                    </Grid>

                   
                    <Grid container item xs={12} sm={6} spacing={2}>
                      <Grid container item xs={6} alignItems="center">
                        <div className="info-container" style={{ marginRight: "10px", fontWeight: "700" }}>
                          <label>{t("Mandatory", locale) || "Mandatory"}:</label>
                        </div>
                        <Checkbox checked={mandatory} onChange={handleChangeMandatory} />
                      </Grid>

                      <Grid container item xs={6} alignItems="center">
                        <div className="info-container" style={{ marginRight: "10px", fontWeight: "700" }}>
                          <label>{t("Active", locale) || "Active"}:</label>
                        </div>
                        <Checkbox checked={active} onChange={handleChangeActive} />
                      </Grid>

                      <Grid container item xs={6} alignItems="center">
                        <div className="info-container" style={{ marginRight: "10px", fontWeight: "700" }}>
                          <label>{t("Implant", locale) || "Implant"}:</label>
                        </div>
                        <Checkbox checked={implant} onChange={handleChangeImplant} />
                      </Grid>

                      <Grid container item xs={6} alignItems="center">
                        <div className="info-container" style={{ marginRight: "10px", fontWeight: "700" }}>
                          <label>{t("Empty", locale) || "Empty"}:</label>
                        </div>
                        <Checkbox checked={empty} onChange={handleChangeEmpty} />
                      </Grid>
                    </Grid>

                    
                    <Grid item xs={12} sm={6}>
                      <div className={styles.label}>
                        Select day
                        <TimeRule />
                      </div>
                    </Grid>
                  </Grid> */}
                  
                  {/* OPCION 2 */}
                  <Grid container spacing={2}>
                    {/* Columna Izquierda */}
                    <Grid container item xs={12} sm={6} spacing={2} direction="column">
                      {/* Name */}
                      <Grid item>
                        <InputQ
                          label={t("NameSpan") || "Name"}
                          value={name}
                          handleChange={handleChangeName}
                          containerWidth="100%"
                          required
                          errorMessage={t("RequiredFieldError") || "This field is required"}
                          validate={validate}
                        />
                      </Grid>

                      {/* Description */}
                      <Grid item>
                        <InputQ
                          value={helpUser}
                          handleChange={handleChangeHelpUser}
                          label={t("DescriptionLabel") || "Description"}
                          containerWidth="100%"
                        />
                      </Grid>

                      {/* Process y Apply to en la misma fila */}
                      <Grid container item spacing={2}>
                        <Grid item xs={6}>
                          <SelectQ
                            selectValue="loadTypeId"
                            options={process}
                            handleSelect={handleLoadType}
                            containerWidth="100%"
                            label={t("Process") || "Process"}
                            placeholder={t("SterilizationSpan") || "Sterilization"}
                            errorMessage={t("RequiredFieldError") || "This field is required"}
                            validate={validate}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectQ
                            selectValue="loadTypeId"
                            options={applyTo}
                            handleSelect={handleLoadType}
                            containerWidth="100%"
                            label={t("ApplyTo") || "Apply to"}
                            placeholder={t("LoadSpan") || "Load"}
                            errorMessage={t("RequiredFieldError") || "This field is required"}
                            validate={validate}
                            disabled
                          />
                        </Grid>
                      </Grid>

                      {/* Method */}
                      <Grid item>
                        <SelectQ
                          selectValue={selectedMethod}
                          handleSelect={handleMethod}
                          options={methods}
                          label={t("MethodSpan") || "Method"}
                          placeholder={t("SelectMethodOption",locale) || "Select a Method"}
                          containerWidth="100%"
                          required
                          errorMessage={t("RequiredFieldError") || "This field is required"}
                          validationMessage={validateMethod}
                        />
                      </Grid>
                    </Grid>

                   {/* Columna Derecha */}
                   <Grid container item xs={12} sm={6} spacing={2} direction="column">
                        {/* Checkboxes en dos filas */}
                      <Grid className={styles.whiteBackgroundCheck}>
                        <Grid container item spacing={3}>
                          {/* Primera fila de checkboxes */}
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                                marginLeft:"20px"
                              }}
                            >
                              <label>{t("Mandatory") || "Mandatory"}:</label>
                            </div>
                            <Checkbox checked={mandatory} onChange={handleChangeMandatory} />
                          </Grid>

                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px"
                              }}
                            >
                              <label>{t("Active") || "Active"}:</label>
                            </div>
                            <Checkbox checked={active} onChange={handleChangeActive} />
                          </Grid>
                        </Grid>

                        <Grid container item spacing={3}>
                          {/* Segunda fila de checkboxes */}
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                                marginLeft:"20px"
                              }}
                            >
                              <label>{t("Empty") || "Empty"}:</label>
                            </div>
                            <Checkbox checked={empty} onChange={handleChangeEmpty} />
                          </Grid>
                          <Grid item xs={6} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginTop:"10px",
                              }}
                            >
                              <label>{t("Implant") || "Implant"}:</label>
                            </div>
                            <Checkbox checked={implant} onChange={handleChangeImplant} />
                          </Grid>

                          <Grid item xs={12} container alignItems="center">
                            <div
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                color: "#555555",
                                marginRight: "10px", 
                                marginLeft:"20px",
                                marginTop:"10px",
                              }}
                            >
                              <label>{t("BlankEntry6") || "For every load"}:</label>
                            </div>
                            <Checkbox checked={forAny} onChange={handleChangeForAny} />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Selector de días */}
                      <Grid item className={forAny ? styles.disabled : styles.whiteBackground}>
                        <div style={{ color: "#555555", marginLeft:"30px" }} >
                        <div className={styles.label}>
                          {/* Select day */}
                          <label>{t("SelectDay") || "Select day"}</label>
                          <TimeRule onDaysWithTimeChange={handleTimeChange} validate={validateTime} initialSelectedTime={selectedTime} forAny={forAny} ruleEditId={ruleEditId}/>
                        </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  

                  <Grid item xs={12} sm={4}></Grid>
                  <div style={{ width: "100%" }}>
                    <DragAndDropRules
                      filter1={
                        <Search
                          data={products}
                          handleFilter={handleProducts}
                          placeholder={
                            t("Search indicators to add") ||
                            "Search indicators to add"
                          }
                          searchActive={() => {}}
                        />
                      }
                      filter2={
                        <Search
                          data={selectedProducts}
                          handleFilter={handleFilteredSelectedProducts}
                          placeholder={
                            t("Search added indicators") ||
                            "Search added indicators"
                          }
                          searchActive={handleSearchActive}
                        />
                      }
                      leftColumnName={t("BlankEntry4") || "Indicators to include"}
                      rightColumnName={t("IndicatorsIncluded") || "Indicators included"}
                      data={filteredProducts}
                      selectedData={selectedProducts}
                      selectedFilteredData={
                        searchActive
                          ? filteredSelectedProducts
                          : selectedProducts
                      }
                      handleSelectedData={handleSelectedProducts}
                    />
                  </div>
                  <Grid item xs={5}>
                    {validateProducts ? (
                      <p style={{ color: "#FF0027", fontWeight: "700",  fontSize: "12px" }}>
                        *
                        {t("NeedToAddAtLeastOneProduct") ||
                          "You need to add at least one product"}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={styles.buttonContainer}>
                      <button
                        className={`${styles.button} ${styles.cancel}`}
                        onClick={() => onCancelClick()}
                      >
                        {t("CancelButton") || "Cancel"}
                      </button>
                      <button
                        className={`${styles.button} ${styles.save}`}
                        onClick={() => onSaveClick()}
                      >
                        {subtitle} 
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </>
    </PageContainer>
  );
}
