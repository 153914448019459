import React from "react";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import styles from "./TablePaginationQ.module.css";
import t from "../../../utils/translation";

export default function TablePaginationQ({
  handleChangePage,
  handleChangeItemsPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  itemsPerPage = 25
}) {
  return (
    <div className={styles.tfoot}>
      <div className={styles.footerRow}>
        <div className={styles.footerContent}>
          <div className={styles.rowsPerPage}>
            <span className={styles.label}>{t("RowsPerPageText")}:</span>
            <select
              className={styles.select}
              onChange={handleChangeItemsPerPage}
              defaultValue={itemsPerPage}
            >
              {[5, 10, 25, 50, 100].map((value, index) => (
                <option className={styles.option} key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.pageInfo}>
            <span className={styles.label}>
              {t("DisplayingPages")} {currentPage} {t("OfTotal")} {totalPages}
            </span>
            <FirstPageIcon
              className={styles.icon}
              onClick={() => handleChangePage(1)}
            />
            <NavigateBeforeIcon
              className={styles.icon}
              onClick={() => handleChangePage(currentPage - 1)}
            />
            <NavigateNextIcon
              className={styles.icon}
              onClick={() => handleChangePage(currentPage + 1)}
            />
            <LastPageIcon
              className={styles.icon}
              onClick={() => setCurrentPage(totalPages)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
