import React, { useEffect, useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import df from "../../utils/dateFormater";
import request from "../../utils/request";

function Ticket({ packageDetail }) {
  const styles = {
    packageLabel: {
      backgroundColor: "white",
      padding: "20px",
      border: "1px solid #00b189",
      textAlign: "center",
    },
    labelItem: {
      marginBottom: "10px",
    },
  };

  return (
    <Paper style={styles.packageLabel}>
      <Typography variant="h6">Etiqueta de Paquete</Typography>
      <Typography variant="subtitle1" style={styles.labelItem}>
        PKG-{packageDetail?.id} {packageDetail?.description}
      </Typography>
      <Typography variant="body1" style={styles.labelItem}>
        Method: {packageDetail?.methodName}
      </Typography>
      <Typography variant="body1" style={styles.labelItem}>
        Operador: {packageDetail?.creationUser}
      </Typography>
      <Typography variant="body1" style={styles.labelItem}>
        Fecha: {df(packageDetail?.creationDate, "dateTime")}
      </Typography>

      {packageDetail?.observations && (
        <Typography variant="body1" style={styles.labelItem}>
          Observaciones: {packageDetail.observations}
        </Typography>
      )}
    </Paper>
  );
}

export default Ticket;
