import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const TruncatedText = ({ text, maxLength }) => {
  if (!text) {
    return null; // Maneja el caso en que el texto es undefined o null
  }

  const shouldTruncate = text.length > maxLength;
  const truncatedText = shouldTruncate ? `${text.substring(0, maxLength)}...` : text;

  return (
    <Tooltip title={text} arrow>
      <Typography style={{ fontWeight: 500, fontSize: '0.875rem', fontFamily: "Montserrat" }}>
        {truncatedText}
      </Typography>
    </Tooltip>
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default TruncatedText;
