import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import MyLicenses from "../pages/MyLicenses";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default (props) => {
  const menu = useMenu();
  const screenName = 805
  const locale = useLocale(screenName);
  const currentPage = {
    menuGroup: menu?.License,
    key: "MyLicenses",
  };

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={120}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          {locale ? (
            <MyLicenses product={props?.location?.state?.product} />
          ) : (
            <PageContentLoading />
          )}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};

