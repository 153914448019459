import React, { useState } from "react";
import { getSession } from "../../utils/sessionHandler";
import _ from "lodash";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { useApiData } from "../../utils/api";
import { InputImage } from "../../components/InputImage";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";

export default function Materials() {
  const {
    data: materials,
    loading: loadingMaterials,
    updateData: updateMaterials,
  } = useApiData("/api/Material");
  const { data: materialTypes, loading: loadingMaterialTypes } =
    useApiData("/api/MaterialType");
  const { data: methods, loading: loadingMethods } = useApiData("/api/Method");

  const [isSerial, setIsSerial] = useState(false);
  const [isImplant, setIsImplant] = useState(false);
  const [isSerialState, setIsSerialState] = useState(false);
  const [defaultMaterialTypeId, setDefaultMaterialTypeId] =
    React.useState(null);
  const [defaultMethodId, setDefaultMethodId] = React.useState(null);

  const loadingContent =
    loadingMaterials || loadingMaterialTypes || loadingMethods;

  const transformedData = materials.map((material) => {
    const materialTypeName =
      materialTypes.find((x) => x.id === material?.materialTypeId)?.name || [];
    const method = methods.find((x) => x.id === material?.methodId)?.name || [];
    return { ...material, method, materialTypeName };
  });

  if (loadingContent) return <PageContentLoading />;

  const getOptions = (data, brand) => {
    let temp = [];
    data.reduce((options, current) => {
      temp.push([current.id, current?.[brand]]);
      return options;
    }, []);
    return temp;
  };

  const resetState = () => {
    setIsSerial(false);
    setDefaultMaterialTypeId(null);
    setDefaultMethodId(null);
  };

  const handleUpdate = () => {
    updateMaterials();
    resetState();
  };

  const handleSerial = () => {
    setIsSerialState((prevIsSerialState) => !prevIsSerialState);
  };

  const handleImplant = () => {
    setIsImplant((prevIsImplant) => !prevIsImplant);
  };

  return (
    <PageGeneralContainer title={"Materials"} category={"materials"}>
      <CRUD
        data={transformedData}
        url={"/api/Material"}
        title={"Materials"}
        updateData={() => handleUpdate()}
        loadingData={loadingMaterials}
        closeModalCallback={() => {
          resetState();
        }}
        onEditClick={(current) => {
          const { isSerialized, materialTypeId, methodId } = current || {};
          setIsSerial(isSerialized);
          setDefaultMethodId(methodId);
          setDefaultMaterialTypeId(materialTypeId);
        }}
        columns={[
          "materialTypeName",
          "name",
          "serial",
          "isImplant",
          "method",
          "cyclesWarning",
        ]}
        headers={[
          "Material Type",
          "Materials",
          "Serial number",
          "Implant",
          "Esterilization Method",
          "Number of Cicles",
        ]}
        idField={"id"}
        fieldsMap={[
          "materialTypeId",
          "name",
          "isSerialized",
          "isImplant",
          "serial",
          "methodId",
          "observations",
          "cyclesWarning",
        ]}
        formFields={[
          {
            label: "Material Type",
            class: "col-12 col-md-6 px-0",
            type: "select",
            defaultValue: defaultMaterialTypeId,
            options: [
              [null, "Select a Material Type"],
              ...getOptions(materialTypes, "name"),
            ],
            required: true,
          },
          { label: "Material", class: "col-12 col-md-6 px-0", required: true },

          {
            label: "Material con número de serie",
            class: "col-12 col-md-6 px-0",
            type: "checkbox",
            defaultValue: isSerialState,
            checkCategory: "primary",
            onSelectedChange: (e) => handleSerial(e),
            // onSelectedChange: (e) => setIsSerial((current) => !current),
          },
          {
            label: "Es implante",
            class: "col-12 col-md-6 px-0",
            type: "checkbox",
            defaultValue: isImplant,
            checkCategory: "primary",
            onSelectedChange: (e) => handleImplant(e),
          },
          {
            label: "Numero de Serie",
            class: "col-12 col-md-6 px-0",
            disabled: !isSerialState,
            required: isSerialState,
          },
          {
            label: "Método (sugerido)",
            class: "col-12 col-md-6 px-0",
            type: "select",
            defaultValue: defaultMethodId,
            options: [
              [null, "Select a Method"],
              ...getOptions(methods, "name"),
            ],
            required: true,
          },
          { label: "Observations", class: "col-12 col-md-6 px-0" },
          {
            label: "Number of Cicles",
            class: "col-12 col-md-6 px-0",
            required: true,
          },
        ]}
        hasCustomFields={true}
        detailFields={[
          { label: "Material type", name: "materialTypeName" },
          { label: "Materials", name: "name" },

          { label: "Material con número de serie", name: "isSerialized" },
          { label: "Es implante", name: "isImplant" },
          {
            label: "Número de serie",
            name: "serial",
            disabled: !isSerialState,
          },
          { label: "Método (sugerido)", name: "method" },
          { label: "Observations", name: "observations" },
          { label: "Number of Cicles", name: "cyclesWarning" },
        ]}
        baseObject={{
          id: null,
          name: "",
          materialTypeId: "",
          isSerialized: isSerialState ?? false,
          isImplant: "",
          serial: "",
          methodId: "",
          observations: "",
          cyclesWarning: "",
          UserLogged: getSession(),
        }}
      />
    </PageGeneralContainer>
  );
}
