import React from 'react';
import Chart from '../../components/Chart';

export default () => {
    return (
        <Chart
              data={[{"cicle":1,"cicle":2,"cicle":3,"cicle":4}]}
              XAxisKey={"cicle"}
              line={[
                {
                  key: "score",
                  color: "#1976d2",
                  label: "1",
                //   tooltip: [
                //     { value: "score", label: t("ScoreLabel", locale) },
                //     {
                //       value: "scoreTypeDescription",
                //       label: t("ScoreTypeLabel", locale),
                //     },
                //     { value: "cicle", label: t("CicleLabel", locale) },
                //   ],
                },
                { key: "cicle", color: "green", label: "1" },
              ]}
              referenceLine={[]}
            />
    )
}