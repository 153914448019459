import React, { useState, useEffect } from 'react';
import request from '../../utils/request';
import { useLocation } from "react-router-dom";

import Select from "../../components/Select";
import ParameterInput from  '../../components/ParameterInput';
import Button from '../../components/Button';	
import Modal from '../../components/Modal';	
import PageContentLoading from '../../components/PageContentLoading';	

import useStyles from './ParameterStyles';
import Select2 from '../../components/Select2';

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
  };

export default function Parameter() {
	const query = useQuery();
	const classes = useStyles();

	const [loadingContente, setLoadingContente] = useState(true)
	const [data, setData] = useState([])
	const [success, setsuccess] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [settingDefaultValues, setSettingDefaultValues] = useState(false)	
	const [distributors, setDistributors] = useState([]);	
	const [selectedDistributor, setSelectedDistributor] = useState(
		query.get("distributorId") || ""
	  );  

	useEffect(() => {
		request()
		  .get(`/api/distributor`)
		  .then((response) => {
			setTimeout(() => {
			  setDistributors(
				response.data.reduce((distributorOptions, distributor) => {
				  distributorOptions.push([distributor.id, distributor.name]);
				  return distributorOptions;
				}, [])
			  );
			  setLoadingContente(false);
			}, 250);
		  })
		  .catch((error) => console.log(error));
	  },[]);

	useEffect(() => {
    if (selectedDistributor) {
		request().get(`/api/sysparameter?distributorId=${selectedDistributor}`)
		.then(response => {
			setData(response.data)
			setLoadingContente(false)
		})
		.catch(error => console.log(error.response))
		}
	}, [selectedDistributor])

	const refreshParameters = () => {		
		if (selectedDistributor) {
		request().get(`/api/sysparameter?distributorId=${selectedDistributor}`)
		.then(response => setData(response.data))
		.catch(error => console.log(error.response))
		
		setTimeout(() => {
			setsuccess('')
		}, 3000);
	}
	}

	const setDefaultValues = () => {		
		if (selectedDistributor) {
			setSettingDefaultValues(true)
			setOpenModal(false)

			request().post(`/api/sysparameter/defaultvalues?distributorId=${selectedDistributor}`)
			.then(respose => {
				setsuccess(respose.data)
				setSettingDefaultValues(false)
				refreshParameters()
			})
			.catch(error => {
				console.log(error.response)
				setSettingDefaultValues(false)
			})

			setTimeout(() => {
				setsuccess('')
			}, 3000);
		}
	}

	const header = (
		<React.Fragment>
		  <div className={classes.header}>
			<span className={classes.modalCloseButton} onClick={()=>setOpenModal(false)}>
			  <svg viewBox="0 0 24 24">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			  </svg>
			</span>
		  </div>
		</React.Fragment>
	);

	const footer = (
		<div className={classes.footerAction}>
		  <Button
			caption={"Confirm"}
			dense
			onClick={setDefaultValues}
		  />
		</div>
	);
	
	if(loadingContente) return <PageContentLoading />

	return (
	<div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div className={"col-12 col-md-5 px-0"}>
            <Select2
              label={"Distributor"}
              options={distributors}
              emptyOptionText={"Choose A Distribuidor"}
              variant={"outlined"}
              value={selectedDistributor}
              onChange={(val) => setSelectedDistributor(val)}
            />
          </div>
        </div>
      </div>
      {selectedDistributor && (
		<>
		<div className={"container-fluid px-0"} >
			{success &&
				<div className={"row mx-0 justify-content-md-center"}>
          		  <div className={"col-12 col-md-8"}>
						<div className={"alert alert-success"}>
  							{success}
						</div>
          		  </div>
          		</div>
			}
			<div className={"row mx-0 justify-content-md-center"} style={{marginBottom: 15, marginTop: 15}}>
          	  <div className={"col-12 col-md-10"}>
					<Button 
						onClick={() => setOpenModal(true)}
						caption={"Reset all default values"}
						loading={settingDefaultValues}
						loadingText={'Resetting all default values...'}
					/>
          	  </div>
          	</div>
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-10"}>
				{data.map(parameter => 
					<ParameterInput
						key={parameter.id}
						value={parameter.value}
						label={parameter.sysParameterTypeDescription}
						parameter={parameter}
						setsuccess={setsuccess}
						refreshParameters={refreshParameters}
					/>
				)}
            </div>
          </div>
		</div>

		{openModal && 
			<Modal header={header} footer={footer} closeModal={()=>setOpenModal(false)} >
				<div className={'container-fluid'}>
					<div className={'row'}>
					  <div className={'col-12'} style={{textAlign:'center'}}>
						Are you sure you want to set the default value for all the parameters?
					  </div>
					</div>
				</div>
			</Modal>
		}
		</>
		
		)}
    </div>
	)
} 
 