import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) =>
  createStyles({
    groupedTitle: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "1.5rem",
      color: "#221e20",
    },
    groupedDiv: {
      margin: "0px 8px",
      marginBottom: 12,
    },
    imgSize: {
      width: "55%",
      height: "auto",
    },
  })
);
