import React from "react";

import styles from "./CycleColumn.module.css";

const CycleColumn = ({
  title,
  children,
  onDrop,
  status,
  dragOver,
  info,
  img,
  scroll = true,
}) => {
  return (
    <div
      className={styles.columnCycle}
      onDrop={(e) => onDrop(e, status)}
      onDragOver={(e) => dragOver(e)}
      draggable={false}
    >
      {scroll && (
        <div className={styles.columnHeader}>
          <div className={styles.data}>
            {info}
            <p className={styles.columnTitle}>{title}</p>
          </div>
          <div className={styles.imgContainer}>
            <img src={img} alt={title} />
          </div>
        </div>
      )}
      <div
        className={scroll ? styles.columnContentScroll : styles.columnContent}
      >
        {children}
      </div>
    </div>
  );
};

export default CycleColumn;
