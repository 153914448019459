import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        color: theme.palette.terragene.darkMain,
        animation: '$root-rotate-animation 1.4s linear infinite',
        display: 'inline-block',

        '& svg': {
            color: theme.palette.terragene.lightMain,
        },

        '& circle': {
            animation: '$circular-dash 1.4s ease-in-out infinite',
            strokeDasharray: '80px, 200px',
            strokeDashoffset: 0,
            color: 'rgb(220, 0, 78)',
            stroke: 'currentcolor'
        }
    },

    '@keyframes root-rotate-animation': {
        '100%': {transform: 'rotate(360deg)'}
    },

    '@keyframes circular-dash': {
        '0%': {
            strokeDasharray: '1px, 200px',
            strokeDashoffset: 0,
        },
        '50%': {
            strokeDasharray: '100px, 200px',
            strokeDashoffset: -15,
        },
        '100%': {
            strokeDasharray: '100px, 200px',
            strokeDashoffset: -125,
        }
    }
}))