export default function reducer(state, action) {
  switch (action.type) {
    case "SET_CONTENT":
      return {
        ...state,
        programs: action.payload.programs,
        products: action.payload.products,
        washers: action.payload.washers,
        requiredFields: action.payload.requiredFields,
        visualResults: action.payload.visualResults,
        loadingContent: false,
      };
    case "SHOW_TOOL_MEASURE":
      return {
        ...state,
        showToolMeasureComponent: true,
      };
    case "HIDE_TOOL_MEASURE":
      return {
        ...state,
        showToolMeasureComponent: false,
      };
    case "SET_READING_TO_ASSOCIATE":
      return {
        ...state,
        readingPROToAssociate: action.payload.readingPROToAssociate,
      };
    case "SET_AFTER_ASSOCIATE":
      return {
        ...state,
        afterAssociate: action.payload.afterAssociate,
      };
    default:
      throw new Error();
  }
}
