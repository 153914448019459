import { makeStyles, createStyles } from "@material-ui/styles";


export default makeStyles((theme) => createStyles({
  root: {
    backgroundColor: "#b2dfdb",
    height: 4,
    overflow: "hidden",
    position: "relative"
  },

  progress: {
    backgroundColor: theme.palette.terragene.darkMain,
    width: "auto",
    animation:
      "$LinearProgressKeyframes 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite",
    top: "0",
    left: "0",
    bottom: "0",
    position: "absolute",
    transition: "transform 0.2s linear",
    transformOrigin: "left"
  },

  progressDelayed: {
    backgroundColor: theme.palette.terragene.darkMain,
    width: "auto",
    animation:
      "$LinearProgressKeyframesDelayed 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite",
    animationDelay: "1.15s",
    top: "0",
    left: "0",
    bottom: "0",
    position: "absolute",
    transition: "transform 0.2s linear",
    transformOrigin: "left"
  },

  "@keyframes LinearProgressKeyframes": {
    "0%": {
      left: "-35%",
      right: "100%"
    },
    "60%": {
      left: "100%",
      right: "-90%"
    },
    "100%": {
      left: "100%",
      right: "-90%"
    }
  },

  "@keyframes LinearProgressKeyframesDelayed": {
    "0%": {
      left: "-200%",
      right: "100%"
    },
    "60%": {
      left: "107%",
      right: "-8%"
    },
    "100%": {
      left: "107%",
      right: "-8%"
    }
  },

  disabled: {
    backgroundColor: "unset",
    "& div": { display: "none" }
  }
}));

