import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";
import LinkIncubations from "../pages/LinkIncubations/LinkIncubations";
import { useParams } from "react-router-dom"; 

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.ABM,
    key: "LinkIncubations",
  };
  const screenName = 305;
  const locale = useLocale(screenName);
  const params = useParams();

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: locale }}>
      <NavBar
        screenName={screenName}
        moduleName={90}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        {/* {locale ? <Sterilizer /> : <PageContentLoading />} */}
        <LinkIncubations params={params} />
      </NavBar>
    </LocalizationContext.Provider>
    // </ValidateURL>
  );
};
