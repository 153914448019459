import React from "react";
import styles from "./IndicatorRule.module.css";
import { FaRegCalendarTimes } from "react-icons/fa";
import indicatorImg from "../../assets/images/chemical.jpg";
import { getImageIndicatorByName } from "../../utils/indicatorImage";
import df from "../../utils/dateFormater";

export default function IndicatorRule({
  name,
  lot,
  backgroundColor,
  border,
  animation,
}) {


  return (
    <div
      className={`${styles.labelContainer} ${styles[backgroundColor]} ${
        border ? styles.border : ""
      } ${animation ? styles.border : ""}`}
    >
      <div className={styles.indicatorInfo}>
        <p className={styles.indicatorName}>
          {name}
        </p>

        {/* <div className={styles.indicatorFooter}>
          <p>{lot}</p>
          <p>
          </p>
        </div> */}
      </div>
      <div className={styles.imgContainer}>
        <img src={getImageIndicatorByName(name?.toUpperCase())} alt={name} />
      </div>
    </div>
  );
}