import React from 'react';
import useStyles from "./RequestLicenseStyles";
import t from "../../utils/translation";
import classNames from "classnames";
import Button from '../Button';
import Redirect from "../../utils/Redirect";
import { getSession } from '../../utils/sessionHandler';

const RequestLicense = ({licenseProductId}) => {
  const classes = useStyles();
  return (
    <div className={classNames("row", classes.container) }>
      <div className={classNames('col-lg-12', classes.headerNotLicense)}>
        <div className={classes.headerNotLicenseText}>
          {t("NotActiveLicenseFound")}
        </div>
        {
          getSession().UserType === 0 &&
          <Button
            caption={t("RequestProductLicenseButton")}
            color={"secondary"}
            onClick={() =>
              Redirect({
                redirect: true,
                path: "/appmylicenses",
                parameters: { product: licenseProductId },
              })
            }
          />
        }
      </div>
    </div>
  );
};

export default RequestLicense;