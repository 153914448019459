import React, { useEffect, useRef, useState } from 'react';
import t from "../../utils/translation";
import Button from '../Button';
import useStyles from './ReportPrinterStyles'

const ReportPrinter = ({html_data}) => {
    const classes = useStyles();
    const iframe = useRef(null);
    const [state, setState] = useState({
        width: 750,
        html: null
    })

    useEffect(() => {
        setState({
            ...state,
            html: html_data
        })
    }, [])

    return (
        <>
            {
                state.html &&
                <iframe className={classes.iframe} ref={iframe} header={null} frameBorder={0} srcDoc={state.html} width={state.width}></iframe>
            }
            <div className={classes.printButtonContainer}>
                <Button fullwidth caption={t("Print") || "Print"} onClick={() => {
                    if (iframe.current){
                        const title = iframe.current.contentDocument.querySelector("title");
                        title ?? title.remove();
                        iframe.current.contentWindow.print();
                    }
                }}></Button>
            </div>
        </>
    );
};

export default ReportPrinter;