// Librerías de terceros
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FiPackage } from "react-icons/fi";

// Componentes locales
import PageContainer from "../../../components/PageContainer/PageContainer";
import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import LoadDetail from "../../../components/LoadWashingDetail/LoadWashingDetail.jsx";

// Utilidades
import useTableQ from "../../../utils/Hooks/useTableQ";
import useConfirmation from "../../../utils/Hooks/useConfirmation/useConfirmation";
import LocalizationContext from "../../../utils/LocalizationContext";
import request from "../../../utils/request";
import df from "../../../utils/dateFormater";
import Redirect from "../../../utils/Redirect";
// Estilos e imágenes
import styles from "./ManageWasherLoadings.module.css";
import FilterDate from "../../../components/FilterDate/FilterDate.jsx";
import { getRegionalFormat } from "../../../utils/sessionHandler.js";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../../components/SpinnerQ/SpinnerQ.jsx";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import t from "../../../utils/translation.js";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";
import SpinnerWashing from "../../../components/SpinnerWashing/SpinnerWashing.jsx";

export default function ManageWasherLoadings() {
  const [loads, setLoads] = useState([]);
  const [loadDetail, setLoadDetail] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [status, setStatus] = useState(5);
  const [name, setName] = useState("");
  const [loadNumber, setLoadNumber] = useState("");
  const [method, setMethod] = useState("");
  const [washers, setWashers] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedWasher, setSelectedWasher] = useState("");
  const [loadingLoads, setLoadingLoads] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const getLoads = async () => {
    setLoadingLoads(true);
    try {
      const response = await request().get(
        `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setLoads(response.data.chargeWashingListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingLoads(false);
    }
  };

  const getStatusName = (id) => {
    return id === 1 ? t("BuildingWashingLoadSpan") : t("ToWashSpan");
  };

  const getStatusColor = (id) => {
    return id === 1 ? "yellow" : "green";
  };

  const onEdit = (chargeWashingId) => {
    localStorage.setItem("loadingEditId", chargeWashingId);
    Redirect({
      redirect: true,
      path: "/appcreatewasherloading",
    });
  };

  const onDelete = (chargeWashingId) => {
    confirm({
      title: "Are you sure?",
      text: "Do you really want to delete this load?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
      confirmButtonColor: "var(--primary-green)",
      cancelButtonColor: "var(--delete)",
      backgroundColor: "#f4f4f4",
      onConfirm: () => handleDelete(chargeWashingId),
    });
  };

  const handleDelete = (chargeWashingId) => {
    request()
      .delete(`/api/chargeWashing`, { id: chargeWashingId })
      .then(() => {
        let newData = loads.filter(
          (load) => load.chargeWashingId !== chargeWashingId
        );
        setLoads(newData);
        setLoadDetail(null);
      });
  };

  const handleLoadDetail = (chargeWashingId) => {
    let item = loads.find((l) => l.chargeWashingId === chargeWashingId);
    console.log(item);
    setLoadDetail(item);
    setIsDetailVisible(true);
  };

  const handleMethod = (e) => {
    setMethod(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${name}&method=${
            e.target.value
          }&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeWashingListatorList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${
            e.target.value
          }&method=${method}&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeWashingListatorList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleLoadNumber = (e) => {
    setLoadNumber(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${
            e.target.value
          }&status=${status}&page=${currentPage}&search=${name}&method=${method}&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeWashingListatorList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setLoads(response.data.chargeWashingListatorList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingLoads(false);
    }
  };
  const handleChangeSterilizer = (e) => {
    setSelectedWasher(e.target.value);

    try {
      request()
        .get(
          `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&washer=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeWashingListatorList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };

  const getParametersLoad = async () => {
    try {
      const response = await request().get(`/api/parameter/chargeWashing`);

      const washers = response.data.washers.map((item) => ({
        value: item.washerId,
        name: item.name,
      }));

      const methods = response.data.methods.map((item) => ({
        value: item.methodId,
        name: item.methodName,
      }));
      setWashers(washers);
      setMethods(methods);
    } catch (error) {
      console.error("Error al obtener esterilizadores:", error);
    }
  };

  useEffect(() => {
    getLoads();
    getParametersLoad();
  }, []);

  const arrayStatus = [
    { value: 5, name: t("SelectStatusOption") },
    { value: 1, name: t("BuildingWashingLoadSpan") },
    { value: 2, name: t("ToWashSpan") },
  ];

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setLoadingLoads(true);

    try {
      request()
        .get(
          `/api/Chargewashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${loadNumber}&status=${
            e.target.value
          }&page=${currentPage}&search=${name}&method=${method}&washer=${selectedWasher}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setLoads(res.data.chargeWashingListatorList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingLoads(false);
    }
  };
  const {
    currentPage,
    currentItems,
    itemsPerPage,
    // totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    loads,
    [
      "chargeNumber",
      "creationDate",
      "chargeWashingName",
      "washerName",
      "methodName",
      "chargeStatus",
    ],
    totalPages
  );
  useEffect(() => {
    getLoads(itemsPerPage, currentPage);
  }, [itemsPerPage, currentPage]);

  return (
    <PageContainer
      category={t("WashingSpan")}
      categoryId={2}
      currentStep={5}
      backUrl={"/appwashingmenu"}
      subcategory={t("WasherLoadingSpan")}
      title={t("ManageWasherLoadingSpan")}
      newButton={isDetailVisible ? false : true}
      newButtonUrl={"/appcreatewasherloading"}
      progress={true}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handleLoadNumber}
                placeholder={t("NumberSpan")}
                label={t("NumberSpan")}
                value={loadNumber}
                containerWidth="100%"
              />
              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                property={"Name"}
                handleChange={handleName}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
              />
              <SelectQ
                value={selectedWasher}
                handleSelect={handleChangeSterilizer}
                width={"100%"}
                containerWidth={"100%"}
                options={washers}
                placeholder={t("SelectWasherSpan", locale) || "Select a Washer"}
              />
              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={method}
                containerWidth={"100%"}
              />
              <SelectQ
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={status}
                containerWidth={"100%"}
              />
            </div>

            <TableContainerQ>
              <TableHeadQ actions={true}>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeNumber")}
                >
                  {t("NumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("creationDate")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeWashingName")}
                >
                  {t("NameSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("washerName")}
                >
                  {t("WasherSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("chargeStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingLoads ? (
                <div className="spinnerContainer">
                  <SpinnerWashing />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems?.map((l) => (
                    <TableRowQ
                      key={l.ChargeId}
                      actions={true}
                      edit={l.chargeStatus === 1}
                      detail={l.chargeStatus === 1 || l.chargeStatus === 2}
                      deleteRow={true}
                      // onClick={() => handleLoadDetail(l.id)}
                      onEdit={() => onEdit(l.chargeWashingId)}
                      onDelete={() => onDelete(l.chargeWashingId)}
                      doubleClick={() =>
                        l.chargeStatus === 1 && onEdit(l.chargeWashingId)
                      }
                      onDetail={() => handleLoadDetail(l.chargeWashingId)}
                    >
                      <TableRowCellQ>
                        <TruncatedText text={l.chargeNumber} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={df(l.creationDate, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText
                          text={l.chargeWashingName}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={l.washerName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={l?.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[getStatusColor(l.chargeStatus)]
                          }`}
                        ></span>
                        <TruncatedText
                          text={getStatusName(l.chargeStatus)}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}

              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <LoadDetail loadId={loadDetail.chargeWashingId} locale={locale} />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
