import React, { useState, useEffect } from "react";
import LocalizationContext from "../utils/LocalizationContext";
import Exporter from "../pages/Exporter";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import request from "../utils/request";
import { getSession } from "../utils/sessionHandler";
import PageContentLoading from "../components/PageContentLoading";
import Redirect from "../utils/Redirect";

export default (props) => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Dashboard,
    key: "Exporter",
  };
  const locale = useLocale(820);

  const [hasLicense, setHasLicense] = useState(undefined);

  useEffect(() => {
    request()
      .post("/api/license/validateExporterLicense", {
        UserLogged: getSession(),
        LicenseProduct: 0,
      })
      .then(() => setHasLicense(true))
      .catch(() =>
        Redirect({
          redirect: true,
          path: "/appmylicenses",
          parameters: { product: 0 },
        })
      );
  }, []);

  return (
    <LocalizationContext.Provider value={{ locale: locale }}>
      <NavBar
        MenuOptions={menu}
        currentPage={currentPage}
      >
        {locale && hasLicense === true ? <Exporter indicatorSelected={props?.location?.state?.indicatorSelected}/> : <PageContentLoading />}
      </NavBar>
    </LocalizationContext.Provider>
  );
};
