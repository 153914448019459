import React from "react";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";

import useStyles from "./ChartStyles";

export default function Chart(props) {
  const {
    data,
    line,
    referenceLine = [],
    XAxisKey,
    xAxisLabel,
    yAxisLabel,
    yTicksVisible = true,
    xTicksVisible = true,
    referenceLinePosition = "right"
  } = props;
  const classes = useStyles();

  const AxisLabel = (props) => {
    // console.log(props);
    const { axisType, stroke, children } = props;
    const { x, y, width, height } = props.viewBox;

    const isVert = axisType === "yAxis";
    // console.log(isVert);
    const cx = isVert ? -(height / 2 + y) : x + width / 2;
    const cy = isVert ? 0 : y + height + 5;
    const rot = isVert ? `270` : 0;
    return (
      <text
        x={cx}
        y={cy}
        transform={`rotate(${rot})`}
        textAnchor="middle"
        stroke={stroke}
      >
        {children}
      </text>
    );
  };

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: referenceLine.length > 0 ? 80 : 5,
            left: 13,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {XAxisKey && (
            <XAxis
              tick={xTicksVisible}
              dataKey={XAxisKey}
              label={<AxisLabel axisType="xAxis">{xAxisLabel}</AxisLabel>}
            />
          )}

          <YAxis IsReversed={true} tick={yTicksVisible} label={<AxisLabel axisType="yAxis">{yAxisLabel}</AxisLabel>} />
  
          {line.map((l,index) => (
            (index+1)!==line.length ?
            <Line
              key={l.key}
              type="linear"
              dataKey={l.key}
              stroke={l.color}
              strokeWidth={2}
              legendType={l.hideLegend ? "none" : "line"}
              isAnimationActive={false}
            />
            :
            null
          ))}

          <Legend
            formatter={(value, payload, index) => (index+1)!==line.length ?line[index].label : null  }
            verticalAlign="top"
          />
          {data.length > 0 && (
            <Tooltip content={<CustomTooltip line={line} />} />
          )}
          {referenceLine &&
            referenceLine.map((r) => (
              <ReferenceLine
                y={r.value}
                stroke={r.color || "black"}
                strokeWidth={(r.value === 16 ? 75 : (r.value === 49.5 ? 75 : (r.value === 85 ? 85 : 1)) )}
                strokeOpacity={(r.value === 16 ? 0.3 : (r.value === 49.5 ? 0.3 : (r.value === 85 ? 0.3 : 1)) )}
                label={{
                  position: referenceLinePosition,
                  value: r.label,
                  fill: "black",
                  fontSize: 14,
                }}
                ifOverflow="extendDomain"
                isFront={false}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = ({ active, payload, label, line }) => {
  if (active) {
    line.map(
      (l) =>
        l.excludeTooltip &&
        l.excludeTooltip.map(
          (point) =>
            label === point &&
            (payload = payload.filter((x) => x.name !== l.key))
        )
    );
    return (
      <div
        style={{
          border: "1px solid #efefef",
          backgroundColor: "white",
          borderRadius: 6,
          padding: "10px 10px",
          boxShadow:
            "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        }}
      >
        {line.map((l) =>
          l.tooltip?.map((t) =>
            customTooltipItem(
              payload.filter((x) => x.name === l.key),
              t,
              l.color
              
            )
          )
        )}
      </div>
    );
  }
  return null;
};

const customTooltipItem = (payload, t, color) => {
  if (payload.length === 0) return null;
  if (payload[0].payload[t.value] === undefined) return null;
  return (
    <p style={{ color: color, marginBottom: 0 }}>
      {t.label}: {payload[0].payload[t.value]}
    </p>
  );
};
