import React, { useContext, useEffect, useState } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
import "date-fns";
import MomentUtils from '@date-io/moment';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import styles from "./TimeRule.module.css";
import moment from "moment";
import t from "../../utils/translation";
import LocalizationContext from '../../utils/LocalizationContext';
import { AiOutlineClockCircle } from "react-icons/ai";



function TimeRule({ onDaysWithTimeChange,validate, initialSelectedTime = [], forAny,ruleEditId}) {
  const days = [
    { short: "L", id: 1 },
    { short: "M", id: 2 },
    { short: "M", id: 3 },
    { short: "J", id: 4 },
    { short: "V", id: 5 },
    { short: "S", id: 6 },
    { short: "D", id: 0 },
  ];
  const [selectedDays, setSelectedDays] = useState([]); 
  const [time, setTime] = useState(null);  
  const locale = useContext(LocalizationContext);

// useEffect(() => {
//   if (initialSelectedTime && initialSelectedTime.length > 0) {
//     const daysFromEdit = initialSelectedTime.map(item => item.day);
//     const timeFromEdit = initialSelectedTime[0].time ? moment(initialSelectedTime[0].time, "HH:mm") : null;

//     setSelectedDays(daysFromEdit);
//     setTime(timeFromEdit);

//     onDaysWithTimeChange(initialSelectedTime);
//   } else {
//     setSelectedDays([]);
//     setTime(null);
//   }
// }, [initialSelectedTime]);

useEffect(() => {
  editDays()
}, [forAny]);

  const editDays = () => {
    if (forAny) {
      setSelectedDays([]);
      setTime(null);
      onDaysWithTimeChange([]);
    } else if (ruleEditId) {
  
        const daysFromEdit = initialSelectedTime && initialSelectedTime.length > 0 
        ? initialSelectedTime.map(item => item.day) 
        : [];
      
      const timeFromEdit = initialSelectedTime && initialSelectedTime[0]?.time 
        ? moment(initialSelectedTime[0].time, "HH:mm") 
        : null;
  
      setSelectedDays(daysFromEdit);
      setTime(timeFromEdit);
      onDaysWithTimeChange(initialSelectedTime);
    } else {
      setSelectedDays([]);
      setTime(null);
    }
  }

  const updateDaysWithTime = (newSelectedDays, newTime) => {
    const formattedTime = newTime ? newTime.format("HH:mm") : null;
    const daysWithTime = newSelectedDays.map(dayId => ({
      day: dayId,
      time: formattedTime,
    }));

    onDaysWithTimeChange(daysWithTime);
  };

  // const handleDayClick = (dayId) => {
  //   const newSelectedDays = selectedDays.includes(dayId)
  //     ? selectedDays.filter(d => d !== dayId)
  //     : [...selectedDays, dayId];

  //   setSelectedDays(newSelectedDays);

  //   if (newSelectedDays.length === 0) setTime(null);

  //   updateDaysWithTime(newSelectedDays, time);
  // };

  const handleDayClick = (dayId) => {
    if (forAny) return;

    const newSelectedDays = selectedDays.includes(dayId)
      ? selectedDays.filter(d => d !== dayId)
      : [...selectedDays, dayId];

    setSelectedDays(newSelectedDays);

    if (newSelectedDays.length === 0) setTime(null);

    updateDaysWithTime(newSelectedDays, time);
  };

  // const handleTimeChange = (newTime) => {
  //   setTime(newTime);
  //   updateDaysWithTime(selectedDays, newTime);
  // };

  const handleTimeChange = (newTime) => {
    if (forAny) return; 

    setTime(newTime);
    updateDaysWithTime(selectedDays, newTime);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00b189", 
        contrastText: "#ffffff", 
      },
    },
    overrides: {
      MuiIconButton: {
        root: {
          "&.MuiPickersCalendarHeader-iconButton": {
            color: "#ffffff", 
          },
        },
      },

      MuiTab: {
        root: {
          backgroundColor: "#fff", 
          "&$selected": {
            backgroundColor: "#00b189", 
            color: "#ffffff", 
          },
        },
        selected: {}, 
      },
      MuiSvgIcon: {
        root: {
          color: "#333",
          "&.Mui-selected": {
            color: "#ffffff", 
            "& .MuiSvgIcon-root": {
              color: "#ffffff", 
            },
          },
        },
      },
    },
  });


  return (
    <div>
      <div style={{display: "flex", gap: "10px" }}>
        {days.map(({ short, id }) => (
          <div
            key={short}
            onClick={() => handleDayClick(id)}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: forAny
              ? "#E5E5E5"
              : selectedDays.includes(id)
              ? "#00B189"
              : "lightgray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative"
            }}
          >
            {short}
            
          </div>
        ))}
      </div>

      
        <ThemeProvider theme={theme}>
         <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label={t("SelectHour", locale) || "Select hour"}
          value={time || null}
          disabled={forAny} 
          onChange={handleTimeChange}
          keyboardIcon={<AiOutlineClockCircle  style={{ fontSize: "20px" }}/>} 
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        </MuiPickersUtilsProvider>
        </ThemeProvider>
        {validate ? (
        <span className={styles.errorMessage}>{t("SelectDayAndHour", locale) || "Select day and hour"}</span>
        ) : ""}
    </div>
  );
}

export default TimeRule;