import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../../components/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import ValidateURL from "../../utils/ValidateURL";
import ManageWashCycles from "../../pages/Washing/ManageWashCycles/ManageWashCycles";
import useLocale from "../../utils/Hooks/useLocale";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Company,
    key: "ManageCycles",
  };


  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{ locale: null }}>
      <NavBar
        moduleName={180}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <ManageWashCycles />
      </NavBar>
      {/* </ValidateURL> */}
    </LocalizationContext.Provider>
  );
};
