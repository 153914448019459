import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  root: {
    '& svg': {
      overflow: "visible"
    }
  }
});

