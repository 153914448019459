import React from "react";
import Redirect from "../../utils/Redirect";
import t from "../../utils/translation";
import { useContext } from "react";
import LocalizationContext from "../../utils/LocalizationContext";

import imgArrow from "../../assets/images/Arrow.svg";
import Scan from "../../assets/images/scan.svg";


const SterilizationHeader = ({ title, subTitle, children, urlBack, scan, moreVh }) => {
  const locale = useContext(LocalizationContext);

  return (
    <div
      style={{
        width: "90%",
        margin: 0,
        height: "auto",
        fontFamily: "Montserrat"

      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#00B189",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            Redirect({
              redirect: true,
              path: urlBack,
            })
          }
        >
          <img src={imgArrow} alt="arrow" style={{ marginRight: "10px" }} />
          {t("Back", locale) || "Back"}
        </p>
        <h5 style={{ fontWeight: "bold", }}>{subTitle}</h5>
        <div>
          <h6
            style={{
              fontWeight: "bold",
              color: "#00B189",
            }}
          >
            {title}
            {scan && (
              <img
                src={Scan}
                alt="Scan Icon"
                style={{
                  height: "35px",
                  width: "35px",
                  marginLeft: "10px",
                }}
              />
            )}
          </h6>
        </div>
      </div>

      {children}
    </div>
  );
};

export default SterilizationHeader;
