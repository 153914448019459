const initialState = (translation) => {
    return {
      userConfig: {
      tooltip: {
        pointFormat: `<b>{point.y} ${translation}</b>`
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          dataLabels: {
            enabled: false,
            distance: -14,
            style: {
              fontsize: 25
            }
          }
        }
      }
    },
    loading: true,
    userPanels: []}
  };
  
  export default initialState;
  