import React from "react";
import styles from "./PCDItem.module.css";
import { getImageIndicatorByName } from "../../utils/indicatorImage";
import { FaRegCalendarTimes } from "react-icons/fa";
import df from "../../utils/dateFormater";

const PCDItem = ({ indicator, nameModelArea }) => {
  return (
    <div className={`${styles.pcdContainer} ${styles.pcdDetailContainer}`}>
      <div>
        <p className={styles.pcdProduct}>{indicator.pcd.indicator.name}</p>
        <p className={styles.pcdZone}>Zone: {nameModelArea}</p>
      </div>
      <div className={styles.pcdIndicatorsContainer}>
        <div className={styles.pcdIndicator}>
          <div className={styles.pcdIndicatorImage}>
            <img
              src={getImageIndicatorByName(
                indicator.biologicalIndicator.indicator.name?.toUpperCase()
              )}
              alt={indicator.biologicalIndicator.indicator.name}
            />
          </div>
          <p className={styles.pcdIndicatorProduct}>
            {indicator.biologicalIndicator.indicator.name}
          </p>
        </div>
        <div className={styles.pcdIndicator}>
          <div className={styles.pcdIndicatorImage}>
            <img
              src={getImageIndicatorByName(
                indicator.chemicalIndicator.indicator.name?.toUpperCase()
              )}
              alt={indicator.chemicalIndicator.indicator.name}
            />
          </div>
          <p className={styles.pcdIndicatorProduct}>
            {indicator.chemicalIndicator.indicator.name}
          </p>
        </div>
      </div>
      <div className={styles.pcdFooter}>
        <p>{indicator.pcd.lot}</p>
        <p>
          <FaRegCalendarTimes /> {df(indicator.pcd.expirationDate, "monthYear")}
        </p>
      </div>
    </div>
  );
};

export default PCDItem;
