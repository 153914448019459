export default function reducer(state, action) {
    switch (action.type) {
      case "SET_CONTENT":
        return {
          ...state,
          loadingContent: false,
          products: action.payload.products,
          sterilizers: action.payload.sterilizers,
          requiredFields: action.payload.requiredFields,
          visualResults: action.payload.visualResults,
          indicatorsCompatiblesTrazanto: action.payload.compatiblesTrazanto
        };
      case "FORMAT_ARRAY_FORM_FIELD":
        return {
            ...state,
            formFields: [...action.payload.commonFormFields, ...action.payload.extraFormFields],
            commonFormFields: action.payload.commonFormFields,
            detailFields: [...action.payload.detailCommonFields, ...action.payload.extraDetailsFields],
            extraDetailFields: action.payload.extraDetailsFields,
            // productType: action.payload.productType
        }
      case "ADD_STERILIZER":
        return {
          ...state,
          sterilizers: action.payload.Sterilizers,
          selectedSterilizer: action.payload.Selected,
          abmSterilizerVisible: false
        };
      case "SHOW_ABM_STERILIZER":
        return {
          ...state,
          abmSterilizerVisible: true
        };
      case "HIDE_ABM_STERILIZER":
        return {
          ...state,
          abmSterilizerVisible: false
        };
      default:
        throw new Error();
    }
  }
  