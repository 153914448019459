import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";
import Sterilization from "../pages/Sterilization/Sterilization.jsx";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.ABM,
    key: "Sterilization",
  };
  const screenName = 335;
  const locale = useLocale(screenName);

  return (
    // <ValidateURL currentPage={currentPage}>
    <LocalizationContext.Provider value={{locale: locale}}>
      <NavBar
        screenName={screenName}
        moduleName={170}
        MenuOptions={menu}
        currentPage={currentPage}
      >
        <Sterilization />
      </NavBar>
    </LocalizationContext.Provider>
    // </ValidateURL>
  );
};
