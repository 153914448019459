import React from 'react';
import InvalidUrl from '../pages/InvalidUrl';

export default (props) => {
	if(!props.location.state) {
		window.history.back()
		return null
	}

	const message = props.location.state.message

	return(
		<InvalidUrl message={message}/>
	)
};