import React from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "./FixedAlertStyles";

const FixedAlert = ({ text, type, dispatch, closeErrorCb }) => {
    const classes = useStyles();
    return (
        <Collapse in={text ? true : false}>
            <Alert
                classes={classes}
                variant="filled"
                severity={type}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            if (dispatch)
                                dispatch({ type: 'CLOSE_ERROR' })
                            else
                                closeErrorCb();
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {text}
            </Alert>
        </Collapse>
    );
};

export default FixedAlert;