export const sortByField = (list, prop) => {
    return list.sort((a, b) => {
        return a[prop] - b[prop]
    })
}

export const sortByStringField = (list, prop) => {
    return list.sort((a, b) => {
        return -b[prop].localeCompare(a[prop])
    })
}