import React, { useEffect, useState } from "react";
import request from "../../utils/request";

import CRUD from "../../components/CRUD";

export default function LicenseProduct() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    updateData()
  }, [])

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/LicenseProduct/`)
      .then((response) => {
        setData(response.data);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <>
        <CRUD
          detailOption={false}
          newOption={false}
          deleteOption={false}
          data={data}
          loadingData={loadingData}
          url={"/api/licenseproduct"}
          updateData={updateData}
          title={"License Product"}
          columns={["name"]}
          headers={["Product"]}
          idField={"license.id"}
          fieldsMap={["name"]}
          formFields={[
            { label: "Product", class: "col-12 col-md-6 px-0", required: true },
          ]}
          baseObject={{
            name: ""
          }}
        />
      
    </>
  );
}
