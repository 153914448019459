import React, { useContext, useState } from "react";

import styles from "./CycleCard.module.css";

import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

import sterilizerIcon from "../../assets/images/greenSterilizer.svg"

import { FaLink } from "react-icons/fa6";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CiCalendar, CiUser } from "react-icons/ci";

const CycleCard = ({ charge, startDrag, handleLoadDetail, img }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useContext(LocalizationContext);

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const toLink = charge.totalIndicators - charge.vinculatedIndicators;

  return (
    <div
      className={styles.chargeCard}
      onClick={() => handleLoadDetail(charge.chargeId)}
      draggable
      onDragStart={(e) => startDrag(e, charge)}
    >
      <div className={styles.iconsContainer}>
        <button
          className={styles.iconButton}
          onClick={(event) => toggleExpand(event)}
        >
          {!isExpanded ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      <div className={styles.chargeBody}>
        <div className={styles.headerCard}>
          <img src={img} alt="progress icon" />
          <div className={styles.headerContainer}>
            <div className={styles.headerTitleContainer}>
              <p className={styles.cycle}>
                {`${t("CycleNumberSpan")}: ${charge.cycleNumber ?? ""}`}
              </p>
              <p className={styles.description}>{`${t("LoadSpan") || "Load"}: ${charge.chargeName ?? ""}`}</p>
            </div>
            <div className={styles.sterilizerContainer}>
              <div className={styles.sterilizerIcon}>
                <img src={sterilizerIcon} alt={t("SterilizerSpan")}/>
              </div>
            <p className={styles.chargeText}>{` ${charge.sterilizerName}`}</p>
            </div>
            {charge.chargeStatus === 4 && toLink > 0 && (
              <div className={styles.indicatorWrapper}>
                <FaLink />
                <p className={styles.unlinked}>
                  {`${t("IndicatorsToLinkSpan")}: `}
                  <span className={styles.count}>{toLink}</span>
                </p>
              </div>
            )}
            {isExpanded && (
                <p className={styles.loadObservations}>
                  {t("BlankEntry16", locale) || "Load obs"}:
                  {charge.chargeObservations ? charge.chargeObservations : ""}
                </p>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <CiCalendar className={styles.icon} />
            <p className={styles.footerText}>
              {df(charge.creationDate, "date")}
            </p>
          </div>

          <div className={styles.footerItem}>
            <CiUser className={`${styles.icon} `} />
            <p className={styles.footerText}>{charge.operator}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CycleCard;
