import React from "react";
import styles from "./TableHeadQ.module.css";

export default function TableHeadQ({ children, actions = false, actionsContent = null, progress = false }) {
  return (
    <div className={styles.thead}>
      {progress && (
        <div className={styles.progress}>
        </div>
      )}
      <div className={styles.columns}>
        {children}
      </div>
      {actions && (
        <div className={styles.actionsCell}>
          {actionsContent}
        </div>
      )}
    </div>
  );
}
