import React, { useContext } from "react";
import { CiCalendar, CiUser } from "react-icons/ci";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IconButton, Tooltip } from "@material-ui/core";
import { useState } from "react";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import styles from "./TrayCard.module.css";
import IndicatorLabel from "../IndicatorLabel/IndicatorLabel";

const TrayCard = ({ pkg, toggle = true, status = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useContext(LocalizationContext);

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  let materials = pkg?.materials;
  let indicators = pkg?.indicatorsWashing
  console.log(indicators)
  let observations = pkg?.observations;

  return (
    <div className={styles.packageCard}>
      {toggle && (
        <div className={styles.iconsContainer}>
          <IconButton
            className={styles.iconButton}
            onClick={(event) => toggleExpand(event)}
          >
            {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </div>
      )}
      <div className={styles.packageBody}>
        <p style={{ margin: 0, fontSize: "14px" }}>
          {pkg.packageNumber} - {pkg.description}
        </p>
        {status && (
          <p style={{ fontSize: "14px", marginBottom: "5px" }}>
            {t("StatusSpan", locale) || "Status"}:{" "}
            {pkg.packageStatus == 1
              ? t("SavedSpan", locale) || "Saved"
              : t("FinishedSpan", locale) || "Finished"}
          </p>
        )}
        {(isExpanded || !toggle) && (
          <div className={styles.detail}>
            <div>
              <p className={`${styles.titles}`}>{t("MaterialsSpan")}</p>
              <div className={styles.materialsContainer}>
                {materials.map((material) => (
                  <div className={styles.material} key={material.id}>
                    <div className={styles.imgMaterialContainer}></div>
                    <div className={styles.materialTextContainer}>
                      <p className={styles.materialName}>
                        {material.material.name}
                      </p>
                      <p className={styles.materialQuantity}>
                        {material.material.serial
                          ? `${t("SerialSpan")}: ${material.material.serial}`
                          : `${t("QuantitySpan")}: ${material.quantity}`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {indicators && indicators.length > 0 && (
              <div>
                <p className={`${styles.titles}`}>{t("IndicatorSpan")}</p>
                <div className={styles.indicatorsContainer}>
                  {indicators && indicators?.map((indicator) => (
                  <div>
                    {indicator.readingCheSterilizer ? (
                      <Tooltip
                        key={indicator.id}
                        title={
                          <div>
                          <div>
                            Reading No.:{" "}
                            {indicator.readingCheSterilizer?.readNumber}
                          </div>
                          <div>
                            Date:{" "}
                            {df(
                              indicator.readingCheSterilizer?.creationTest,
                              "dateTime"
                            )}
                          </div>
                          <div>
                            Observations:{" "}
                            {indicator.readingCheSterilizer?.notes}
                          </div>
                        </div>
                        }
                        arrow
                      >
                        <div className={styles.indicatorItem}>
                          <IndicatorLabel
                            backgroundColor={"white"}
                            name={indicator.indicator.name}
                            lot={indicator.lot}
                            serial={indicator.serial}
                            expirationDate={indicator.expirationDate}
                            border={true}
                            resultCH={indicator.readingCheSterilizer.result}
                            userResultCH={indicator.readingCheSterilizer.visualResult}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={styles.indicatorItem}>
                        <IndicatorLabel
                          backgroundColor={"white"}
                          name={indicator.indicator.name}
                          lot={indicator.lot}
                          serial={indicator.serial}
                          expirationDate={indicator.expirationDate}
                          border={true}
                        />
                      </div>
                    )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {observations && (
              <div>
                <p className={`${styles.titles}`}>{t("ObservationSpan")}</p>

                <div className={styles.observationContainer}>
                  <p className={styles.observation}>{observations}</p>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.timestampContainer}>
          <div className={styles.iconText}>
            <CiCalendar className={styles.icon} />
            <p style={{ margin: 0 }}>
              {df(pkg.creationDate, "dateTime")}
            </p>
          </div>
          <div className={styles.iconText}>
            <CiUser className={styles.icon} />
            <p style={{ margin: 0 }}>{pkg.creationUser}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrayCard;
