import React, { useMemo, useState } from "react";

import ModalQ from "../../../components/TableBionovaQ/ModalQ/ModalQ";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ";
import ErrorMessage from "../../../components/TableBionovaQ/ErrorMessage/ErrorMessage";
import InputQ from "../../../components/FormComponents/InputQ/InputQ";

import request from "../../../utils/request";

import styles from "./WashersForm.module.css";
import { getSession } from "../../../utils/sessionHandler";

const WashersForm = ({
  formData,
  handleFormData,
  handleClose,
  open,
  locale,
  t,
  brands,
  models,
  getWashers,
  selectedSector,
}) => {
  const [error, setError] = useState("");
  const [validate, setValidate] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.companyMachineMakeId ||
      !formData.name ||
      !formData.companyMachineModelId
    ) {
      setValidate(true);
    } else setValidate(false);

    try {
      const bodyRequest = {
        Washer: { ...formData, sectorId: selectedSector },
        UserLogged: getSession(),
      };
      if (formData.id) {
        await request().put("/api/washer", bodyRequest);
      } else {
        await request().post("/api/washer", bodyRequest);
      }
      handleClose();
      setError("");
      setValidate(false);
      getWashers(selectedSector);
    } catch (err) {
      setError(
        err.response?.data?.message || "Error al guardar el esterilizador."
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBrandChange = (e) => {
    const { name, value } = e.target;
    const brandName = brands.find((b) => value == b.value)?.name;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
      brand: brandName,
      companyMachineModelId: "",
      methodName: "",
    }));
  };

  const handleModelChange = (e) => {
    let model = models.find((m) => m.value == e.target.value);
    const brandName = brands.find((b) => model?.brandId == b.value)?.name;

    if (model) {
      handleFormData((prevData) => ({
        ...prevData,
        companyMachineModelId: model.value,
        companyMachineMakeId: model.brandId,
        methodName: model.methodName,
        brand: brandName,
      }));
    } else {
      handleFormData((prevData) => ({
        ...prevData,
        companyMachineModelId: "",
        companyMachineMakeId: "",
        brand: "",
        methodName: "",
      }));
    }
  };
  const onClose = () => {
    handleClose();
    setError("");
    setValidate(false);
  };
  const filteredModels = useMemo(() => {
    let newModels =
      formData.brand !== ""
        ? models.filter((m) => formData.companyMachineMakeId == m.brandId)
        : models;
    return newModels;
  }, [formData.brand, models]);

  return (
    <ModalQ
      open={open}
      title={
        !formData.id
          ? t("New Washer", locale) || "New Washer"
          : t("Edit Washer", locale) || "Edit Washer"
      }
      handleClose={onClose}
    >
      {error && <ErrorMessage message={error} />}
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          <InputQ
            label={t("Name", locale) || "Name"}
            name="name"
            handleChange={handleInputChange}
            value={formData.name}
            required={true}
            validate={validate}
          />
          <InputQ
            label={t("Serial", locale) || "Serial"}
            name="serial"
            handleChange={handleInputChange}
            value={formData.serial}
            required={true}
            validate={validate}
          />
        </div>
        <div className={styles.inputGroup}>
          <SelectQ
            selectValue={formData.companyMachineMakeId}
            handleSelect={handleBrandChange}
            options={brands}
            name={"companyMachineMakeId"}
            label={t("SelectABrand", locale) || "Select a Brand"}
            placeholder={t("SelectABrand", locale) || "Select a Brand"}
            required={true}
            errorMessage={
              t("RequiredField", locale) || "This field is required"
            }
            validate={validate}
            // disabled={formData.companyMachineModelId}
          />
          <SelectQ
            selectValue={formData.companyMachineModelId}
            handleSelect={handleModelChange}
            options={filteredModels}
            name={"companyMachineModelId"}
            label={t("SelectAModel", locale) || "Select a Model"}
            placeholder={t("SelectAModel", locale) || "Select a Model"}
            required={true}
            errorMessage={
              t("RequiredField", locale) || "This field is required"
            }
            validate={validate}
          />
        </div>
        <div className={styles.inputGroup}>
          <InputQ
            label={t("Method", locale) || "Method"}
            name="method"
            value={formData.methodName}
            disabled={true}
          />
          <InputQ
            label={t("Observations", locale) || "Observations"}
            name="observations"
            handleChange={handleInputChange}
            value={formData.observations}
          />
        </div>

        <button
          className={`${styles.button} ${styles.confirmButton}`}
          onClick={(e) => handleSubmit(e)}
        >
          Confirm
        </button>
      </div>
    </ModalQ>
  );
};

export default WashersForm;
