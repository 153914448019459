import React, { useEffect, useState, useCallback } from "react";
import request from "../../../utils/request";
import _ from "lodash";
import t from "../../../utils/translation";
import { unflatten } from "flat";
import { getDist } from "../../../utils/sessionHandler";
import classnames from "classnames";

import Modal from "../../Modal";
import Progress from "../../Progress";
import Input from "../../Input";
import Select from "../../Select";
import DateInput from "../../DateInput/DateInput";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { sortByField } from "../../../utils/functions/Sorter";
import SplitButton from "../../SplitButton/SplitButton";
import {
  allowOnlyCharactersAndNumbers,
  phoneCharAllowed,
} from "../../../utils/functions/Regex";
import { WorkingMode } from "../../../dictionaries/workingMode";
import Button from "../../Button/Button";
import Grid from "@material-ui/core/Grid";

import BarcodeReader from "react-barcode-reader";

const useCheckBoxStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    padding: "8px",
    margin: "0px",
    fontFamily: "Montserrat",
  },
  label: {
    fontSize: "13px",
    fontFamily: "Montserrat",
  },
});

export default function Update(props) {
  const checkBoxClass = useCheckBoxStyles();
  const {
    classes,
    dispatch,
    url,
    title,
    fieldsMap,
    formFields,
    selectedObjet,
    mode,
    baseObject,
    baseObjectOverwrite,
    method,
    idField,
    serversidePagination,
    grouped,
    useFieldMap,
    splitButtonExtraOptions = [],
    showModalToolMeasure,
    errorsFromValidation,
    clearMessageValidation,
    closeModalCallback,
    onChangeCallback,
    hasCustomFields,
    onCloseModal,
    handleValidation
  } = props;

  const [object, setObject] = useState(selectedObjet);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [responseError, setResponseError] = useState(null);
  const [splitOptions, setSplitOptions] = useState(null);
  const [isVisibleButtonToolMeasure, setIsVisibleButtonToolMeasure] =
    useState(false);


  useEffect(() => {
    onChangeCallback(object)
  },[object])

  useEffect(() => {
    if (object["ReadingPRO.modeId"] === WorkingMode.ISO) {
      setIsVisibleButtonToolMeasure(true);
    } else {
      const splitExtra = splitButtonExtraOptions.map((splitOption) => {
        if (splitOption.useCommonFunction) {
          splitOption.onClickOption = () => {
            handleUpdate(splitOption.moveToSaved);
          };
        }
        return splitOption;
      });

      setSplitOptions([
        { text: (t("ConfirmButton") || "Confirm"), onClickOption: (text) => handleUpdate(false), disabled: false, closeModalCallback: closeModalCallback() },
        ...splitExtra
      ])
    }
  }, [object]);


  useEffect(() => {
    if (errorsFromValidation) {
      if (Object.keys(errorsFromValidation).length > 0) {
        setErrors(errorsFromValidation);
      } else if (Object.keys(errors).length > 0) {
        setErrors(errorsFromValidation);
      }
    }
  }, [errorsFromValidation]);

  const RequiredFieldMessage =
    t("RequiredFieldError") || "This field is required";
  const NametoString = `(${
    object[idField.split(".")[0] + ".toString"] ||
    object.name ||
    object.readNumber ||
    object.brand
  })`;

  let scanOn = false;

  if (idField != undefined) {
    if (idField === "ReadingBI.id" || idField === "ReadingPRO.id") {
      scanOn = true;
    }
  }

  let scannerInput2 = "";
  const keyPressListner = useCallback(
    (e) => {
      //if (e.keyCode != 40 && e.keyCode != 32 && e.keyCode != 27 && e.keyCode != 13 && e.keyCode != 16 && e.keyCode != 119 && e.keyCode != 120) {
      let gTin = "";
      //scannerInput2 += e.key.charAt(e.key.length - 1);
      scannerInput2 += e.replaceAll("Shift", "");

      //setTimeout(() => {
      let tempObject = Object.assign({}, object);
      if (scannerInput2.length >= 35) {
        if (tempObject["ReadingBI.statusDescription"] != null) {
          //if ((tempObject["ReadingBI.statusDescription"].toUpperCase() === "LIVE") || (tempObject["ReadingBI.statusDescription"].toUpperCase() === "PROVISIONAL")) {
          tempObject["gTin"] = scannerInput2.substr(2, 14);
          gTin = scannerInput2.substr(2, 14);

          if (
            scannerInput2.split("")[24] === "1" &&
            scannerInput2.split("")[25] === "0"
          ) {
            tempObject["ReadingBI.loteProduct"] = scannerInput2
              .substr(26)
              .toUpperCase();
          }
          if (
            scannerInput2.split("")[24] === "1" &&
            scannerInput2.split("")[25] === "1"
          ) {
            if (
              scannerInput2.split("")[32] === "1" &&
              scannerInput2.split("")[33] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(34)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[60] === "1" &&
              scannerInput2.split("")[61] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(62)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[61] === "1" &&
              scannerInput2.split("")[62] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(63)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[62] === "1" &&
              scannerInput2.split("")[63] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(64)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[63] === "1" &&
              scannerInput2.split("")[64] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(65)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[64] === "1" &&
              scannerInput2.split("")[65] === "0"
            ) {
              tempObject["ReadingBI.loteProduct"] = scannerInput2
                .substr(66)
                .toUpperCase();
            }
          }

          request()
            .get(
              `/api/Product/GetProductByGTIN?gtin=${gTin}&distributor=${
                getDist().id
              }`
            )
            .then((response) => {
              if (response.data.id != 0) {
                //tempObject["ReadingBI.productId"] = response.data.id;
                if (tempObject["ProdOpt"].length > 0) {
                  if (
                    tempObject["ProdOpt"].some((p) => p === response.data.id)
                  ) {
                    tempObject["ReadingBI.productId"] = response.data.id;
                  } else {
                    tempObject["ReadingBI.loteProduct"] = null;
                  }
                }

                setObject(tempObject);
                //scannerInput2="";
              }
            });
          //}
        } else if (tempObject["ReadingPRO.statusDescription"] != null) {
          //if ((tempObject["ReadingPRO.statusDescription"].toUpperCase() === "LIVE") || (tempObject["ReadingPRO.statusDescription"].toUpperCase() === "PROVISIONAL")) {
          tempObject["gTin"] = scannerInput2.substr(2, 14);
          gTin = scannerInput2.substr(2, 14);

          if (
            scannerInput2.split("")[24] === "1" &&
            scannerInput2.split("")[25] === "0"
          ) {
            tempObject["ReadingPRO.loteProduct"] = scannerInput2
              .substr(26)
              .toUpperCase();
          }
          if (
            scannerInput2.split("")[24] === "1" &&
            scannerInput2.split("")[25] === "1"
          ) {
            if (
              scannerInput2.split("")[32] === "1" &&
              scannerInput2.split("")[33] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(34)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[60] === "1" &&
              scannerInput2.split("")[61] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(62)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[61] === "1" &&
              scannerInput2.split("")[62] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(63)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[62] === "1" &&
              scannerInput2.split("")[63] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(64)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[63] === "1" &&
              scannerInput2.split("")[64] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(65)
                .toUpperCase();
            } else if (
              scannerInput2.split("")[64] === "1" &&
              scannerInput2.split("")[65] === "0"
            ) {
              tempObject["ReadingPRO.loteProduct"] = scannerInput2
                .substr(66)
                .toUpperCase();
            }
          }

          request()
            .get(
              `/api/Product/GetProductByGTIN?gtin=${gTin}&distributor=${
                getDist().id
              }`
            )
            .then((response) => {
              if (response.data.id != 0) {
                //tempObject["ReadingPRO.productId"] = response.data.id;

                if (tempObject["ProdOpt"].length > 0) {
                  if (
                    tempObject["ProdOpt"].some((p) => p === response.data.id)
                  ) {
                    tempObject["ReadingPRO.productId"] = response.data.id;
                  } else {
                    tempObject["ReadingPRO.loteProduct"] = null;
                  }
                }

                setObject(tempObject);
              }
            });
          //}
        }
      }
      scannerInput2 = "";
      //}, 220);
      //}
    },
    [props]
  );

  /*useEffect(() => {
    window.document.addEventListener('keydown', event => keyPressListner(event))

    return () => {
      document.getElementById("root").style = "";
    }
  }, [keyPressListner])*/

  const handleScan = (barcodeString) => {
    setTimeout(() => {
      keyPressListner(barcodeString);
    }, 220);
  };

  const closeModal = () => {
    dispatch({ type: "closeModal" });
    if (clearMessageValidation)
      clearMessageValidation();
      closeModalCallback()
    onCloseModal();
    if (clearMessageValidation) clearMessageValidation();
  };

  const handleCompanyFieldsChange = (
    value,
    field,
    isPhoneField,
    propOnObject
  ) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      //no permito el ingreso de caracteres especiales
      tempObject[field] = !isPhoneField
        ? allowOnlyCharactersAndNumbers(value)
        : phoneCharAllowed(value);
    } else {
      tempObject[propOnObject] = !isPhoneField
        ? allowOnlyCharactersAndNumbers(value)
        : phoneCharAllowed(value);
    }
    setObject(tempObject);
  };

  const handleFieldChange = (value, field, propOnObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      tempObject[field] = value;
    } else {
      tempObject[propOnObject] = value;
    }
    setObject(tempObject);
  };

  const handleFieldIntChange = (value, field, propOnObject) => {
    let tempObject = Object.assign({}, object);
    if (value !== "not allowed") {
      if (value.match(/^[0-9]*$/)) {
        if (useFieldMap) {
          tempObject[field] = value;
        } else {
          tempObject[propOnObject] = value;
        }
      }
    }
    setObject(tempObject);
  };

  const handleDateFieldChange = (value, field, utcField, propOnObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      tempObject[field] = value;
    } else {
      tempObject[propOnObject] = value;
    }
    tempObject[utcField] = value._d.toDateString();
    setObject(tempObject);
  };

  const handleCheckBoxChange = (value, field, propOnObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      tempObject[field] = value.target.checked;
    } else {
      tempObject[propOnObject] = value.target.checked;
    }
    setObject(tempObject);
  };

  let isScanning = false;
  let scannerInput = "";

  const handleScannerInput = (val, field) => {
    const manualInputValue = val;
    scannerInput += val.charAt(val.length - 1);
    if (isScanning) return;
    isScanning = true;

    setTimeout(() => {
      let tempObject = Object.assign({}, object);

      if (scannerInput.length > 15) {
        //si tiene codigo 01 i gtin cargo ambos inputs
        // tempObject['ReadingBI.productId'] = scannerInput.substr(2, 14);
        tempObject["gTin"] = scannerInput.substr(2, 14);

        if (
          scannerInput.split("")[24] === "1" &&
          scannerInput.split("")[25] === "0"
        ) {
          tempObject["ReadingBI.loteProduct"] = scannerInput.substr(26);
        }
        if (
          scannerInput.split("")[24] === "1" &&
          scannerInput.split("")[25] === "1"
        ) {
          tempObject["ReadingBI.loteProduct"] = scannerInput.substr(34);
        }
      } else {
        //pongo valor en el input seleccionado
        tempObject[field] = manualInputValue;
      }

      setObject(tempObject);
    }, 30);
  };

  const handleSelectChange = (value, index, dependencies, propOnObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      if (typeof value === "object") {
        tempObject[fieldsMap[index]] = value.id;
        tempObject[fieldsMap[index].split(".")[0]] = value;
      } else {
        tempObject[fieldsMap[index]] = value;
      }
    } else {
      if (typeof value === "object") {
        tempObject[propOnObject] = value.id;
        tempObject[propOnObject.split(".")[0]] = value;
      } else {
        tempObject[propOnObject] = value;
      }
    }
    if (dependencies)
      setDependenciesFields(tempObject, useFieldMap, dependencies);
    if (tempObject["gTin"]) tempObject["gTin"] = null;
    setObject(tempObject);
  };

  const setDependenciesFields = (tempObject, useFieldMap, dependencies) => {
    if (dependencies && dependencies.length > 0) {
      if (useFieldMap) {
        dependencies.forEach((dependency) => {
          const index = fieldsMap.indexOf(dependency.key);
          tempObject[fieldsMap[index]] = dependency.default.id;
        });
      } else {
        dependencies.forEach((dependency) => {
          tempObject[dependency.key] = dependency.default.id;
        });
      }
    }
  };

  const setRequiredFields = (value, useFieldMap, requiredDependencies) => {
    if (requiredDependencies && requiredDependencies.length > 0) {
      if (useFieldMap) {
        requiredDependencies.forEach((requiredDependency) => {
          const index = fieldsMap.indexOf(requiredDependency.keySingle);
          if (requiredDependency.condition == value) {
            let field = formFields[index];
            if (field) field.required = requiredDependency.required;
          }
        });
      } else {
        requiredDependencies.forEach((requiredDependency) => {
          if (requiredDependency.condition == value) {
            let dependentField = formFields.find(
              (x) => x.propOnObject === requiredDependency.keySingle
            );
            if (dependentField)
              dependentField.required = requiredDependency.required;
          }
        });
      }
    }
  };

  const setVisibilityGroupDependencies = (
    value,
    useFieldMap,
    visibilityGroupDependencies
  ) => {
    if (visibilityGroupDependencies && visibilityGroupDependencies.length > 0) {
      visibilityGroupDependencies.forEach((visibleDependency) => {
        let group = grouped.find(
          (x) => x.Index === visibleDependency.groupIndex
        );
        if (visibleDependency.condition == value) {
          group.visible = visibleDependency.visible;
        } else {
          group.visible = false;
        }
      });
    }
  };

  const handleUpdate = (moveToSaved) => {
    //OBSERVACION: SOLO SE PUEDE GENERAR DEPENDENCIA PARA LOS SELECT BOX QUE NO MANEJAN COMPOSITE.
    let requestObject = null;
    if (serversidePagination) {
      if (_.isEmpty(baseObject)) {
        Object.assign(baseObject, object);
      } else {
        let properies = Object.keys(baseObject);
        baseObject[properies[0]] = object;
      }

    }
  


    let modifiedObject;
    if (hasCustomFields) {
      const baseKeys = Object.keys(baseObject);
      modifiedObject = _.pick(unflatten(object), baseKeys); //custom fields hacked only when hasCustomFields as a prop.
    } else {
      modifiedObject = unflatten(object);
    }

    requestObject = _.merge(
      {},
      baseObject,
      modifiedObject,
      baseObjectOverwrite
    );


      
    requestObject.moveToSaved = moveToSaved
    let requiredError

    if (useFieldMap) {
      handleValidation()
      requiredError = fieldsMap.reduce((errorBag, field, index) => {
        (_.get(requestObject, field) === undefined ||
          _.get(requestObject, field) === "" ||
          _.get(requestObject, field) === null) &&
          formFields[index].required &&
          (errorBag[field] = RequiredFieldMessage);
        return errorBag;
      }, {});
    } else {
      requiredError = formFields.reduce((errorBag, field) => {
        (_.get(requestObject, field.propOnObject) === undefined ||
          _.get(requestObject, field.propOnObject) === "" ||
          _.get(requestObject, field.propOnObject) === null) &&
          formFields.find((x) => x.propOnObject == field.propOnObject)
            .required &&
          (errorBag[field.propOnObject] = RequiredFieldMessage);
        return errorBag;
      }, {});
    }
    if (Object.keys(requiredError).length > 0) {
      setErrors(requiredError);
    } else {
      setLoading(true);
      setResponseError(null);
      if (method === "save") {
        request()
          .save(url, requestObject, idField)
          .then((response) => {
            setLoading(false);
            if (typeof response.data === "object") {
              response.data.result === "ERRORS" &&
                setResponseError(
                  response.data.errors.map((e) => (
                    <p style={{ marginBottom: 0 }}>{e}</p>
                  ))
                );
              response.data.result === "OK" &&
                props.saveSuccess(response.data.message, false, requestObject);
            } else {
              props.saveSuccess(response.data, false, requestObject);
            }
            onCloseModal();
          })
          .catch((error) => {
            console.log(error.response);
            if (typeof error.response?.data?.errors === "object") {
              const errorBag = error.response.data.errors;
              setResponseError(
                <div>
                  <p>{error.response.data.title}: </p>
                  {Object.keys(errorBag).map((k) => (
                    <p className={classes.requiredField} key={k}>
                      {errorBag[k][0]}
                    </p>
                  ))}
                </div>
              );
            } else {
              setResponseError(
                error.response?.data?.message ||
                  error.response?.data?.title ||
                  error.response?.data
              );
            }
            setLoading(false);
          });
      } else {
        request()
          .updateObject(url, requestObject, method)
          .then((response) => {
            setLoading(false);
            if (typeof response.data === "object") {
              response.data.result === "ERRORS" &&
                setResponseError(
                  response.data.errors.map((e) => (
                    <p style={{ marginBottom: 0 }}>{e}</p>
                  ))
                );
              response.data.result === "OK" &&
                props.saveSuccess(response.data.message, true);
            } else {
              props.saveSuccess(response.data, true);
            }
            onCloseModal();

          })
          .catch((error) => {
            console.log(error.response);
            if (typeof error.response?.data?.errors === "object") {
              const errorBag = error.response.data.errors;
              setResponseError(
                <div>
                  <p>{error.response.data.title}: </p>
                  {Object.keys(errorBag).map((k) => (
                    <p key={k}>{errorBag[k][0]}</p>
                  ))}
                </div>
              );
            } else {
              setResponseError(
                error.response?.data?.message ||
                  error.response?.data?.title ||
                  error.response?.data
              );
            }
            setLoading(false);
          });
      }
    }
  };

  const formField = (field, index, groupIndex) => {
    let value = null;
    if (useFieldMap) {
      if (
        object[fieldsMap[index]] === undefined ||
        object[fieldsMap[index]] === null
      ) {
        if (field.defaultValue === undefined) {
          value = "";
        } else {
          object[fieldsMap[index]] = field.defaultValue;
          value = field.defaultValue;
        }
      } else {
        value = object[fieldsMap[index]];
      }
    } else {
      if (
        object[field.propOnObject] === undefined ||
        object[field.propOnObject] === null
      ) {
        if (field.defaultValue === undefined) {
          value = "";
        } else {
          object[field.propOnObject] = field.defaultValue;
          value = field.defaultValue;
        }
      } else {
        value = object[field.propOnObject];
      }
      if (field.requiredDependencies)
        setRequiredFields(value, useFieldMap, field.requiredDependencies);
      if (field.visibilityGroupDependencies)
        setVisibilityGroupDependencies(
          value,
          useFieldMap,
          field.visibilityGroupDependencies
        );
    }
    if (
      field.type === "select" &&
      (field.propOnObject === "ReadingBI.productId" ||
        field.propOnObject === "ReadingPRO.productId") &&
      (idField === "ReadingBI.id" || idField === "ReadingPRO.id")
    ) {
      let ids = [];
      if (field.options.length > 0) {
        field.options.map((p) => {
          if (p[0]) ids.push(p[0]);
        });
        object["ProdOpt"] = ids;
      }
    }
    switch (field.type) {
      case "select":
        return (
          <Select
            focused={groupIndex === 0 && field.position === 0}
            options={field.options}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            //defaultValue={field.defaultValue}
            value={value}
            component={field.component}
            uniqueIdentifier={object.gTin}
            getObject={field.objectField}
            onChange={(value) => {
              handleSelectChange(
                value,
                index,
                field.dependencies,
                field.propOnObject
              );
              if (field.onSelectedChange) {
                field.onSelectedChange(value, index);
              }
            }}
            disabled={
              loading ||
              object.securable === false ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new") 

            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
            nullable={field.required === false}
          />
        );
      case "scanner":
        return (
          <Input
            focused={groupIndex === 0 && field.position === 0}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            value={value}
            onChange={(value) => handleScannerInput(value, fieldsMap[index])}
            disabled={
              loading ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new")
            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
          />
        );
      case "date":
        return (
          <DateInput
            focused={groupIndex === 0 && field.position === 0}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            value={value || null}
            onChange={(value) => {
              handleDateFieldChange(
                value,
                fieldsMap[index],
                field.utcField,
                field.propOnObject
              );
            }}
            disabled={
              loading ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new")
            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
          />
        );
      case "number":
        return (
          <Input
            type="number"
            focused={groupIndex === 0 && field.position === 0}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            value={value}
            onChange={(value) =>
              handleFieldChange(value, fieldsMap[index], field.propOnObject)
            }
            disabled={
              loading ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new")
            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            classes={checkBoxClass}
            control={
              <Checkbox
                checked={value}

                onChange={(value) => {
                  handleCheckBoxChange(value, fieldsMap[index], field.propOnObject)
                    if (field.onSelectedChange) {
                      field.onSelectedChange(value, index)
                    }
                  }
                }
                color={field.checkCategory}
                disabled={
                  loading ||
                  field.disabled ||
                  (field.newOnly && mode === "update") ||
                  (field.updateOnly && mode === "new")
                }
              />
            }
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
          />
        );
      case "specialInput":
        return (
          <Input
            type="number"
            maxValue={2147483647}
            propertySpecial="inputSpecial"
            focused={groupIndex === 0 && field.position === 0}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            value={value}
            onChange={(value) =>
              handleFieldIntChange(value, fieldsMap[index], field.propOnObject)
            }
            disabled={
              loading ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new")
            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
          />
        );
        case "custom":
          return (
            <>
              {React.cloneElement(
                field.component,
                {
                  key: `custom_${index}`, // Agregar una clave única basada en el índice
                  callback: !field.regex
                    ? (value) => handleFieldChange(value, fieldsMap[index], field.propOnObject)
                    : (value) => handleCompanyFieldsChange(value, fieldsMap[index], field.isPhoneField, field.propOnObject)
                }
              )}
            </>
          );
        default:
        return (
          <Input
            focused={groupIndex === 0 && field.position === 0}
            label={
              <div>
                {field.label}
                {field.required && (
                  <span className={classes.requiredField}> * </span>
                )}
              </div>
            }
            value={value}
            onChange={
              !field.regex
                ? (value) =>
                    handleFieldChange(
                      value,
                      fieldsMap[index],
                      field.propOnObject
                    )
                : (value) =>
                    handleCompanyFieldsChange(
                      value,
                      fieldsMap[index],
                      field.isPhoneField,
                      field.propOnObject
                    )
            }
            disabled={
              loading ||
              field.disabled ||
              (field.newOnly && mode === "update") ||
              (field.updateOnly && mode === "new")
            }
            error={
              useFieldMap
                ? errors[fieldsMap[index]]
                : errors[field.propOnObject]
            }
          />
        );
    }
  };

  const showFormField = (field) => {
    if (field.visibleCondition) {
      if (
        object[field.visibleCondition[0]]?.toString() !==
        field.visibleCondition[2]
      ) {
        return false;
      }
    }
    return true;
  };

  const groupFields = () => {
    return sortByField(grouped, "position").map((group) => {
      const formFieldsByGroup = formFields.filter(
        (x) => x.groupIndex === group.Index
      );
      return (
        formFieldsByGroup.length > 0 &&
        group.visible && (
          <div className={classes.groupedDiv}>
            <h6 className={classes.groupedTitle}>{group.Description}</h6>
            <div className={classnames("row", "col-lg-12")}>
              {sortByField(
                formFields.filter((x) => x.groupIndex === group.Index),
                "position"
              ).map((field, index) => {
                if (showFormField(field)) {
                  return (
                    <div
                      className={classnames(field.class, {
                        [classes.disabled]: field.disabled,
                      })}
                      key={index}
                    >
                      {formField(field, index, group.Index)}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )
      );
    });
  };

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <div className={classes.headerText}>
          {mode === "new"
            ? (t("NewTitle") || "New") + " " + title
            : (t("EditTitle") || "Edit") + " " + title + ` ${NametoString}`}
        </div>

        {scanOn ? (
          <div className={classes.divScanner}>
            <Tooltip title={<h6>{t("ScannerAvailable")}</h6>} placement="left">
              <img
                src={"../images/scan.svg"}
                className={classes.imgScanner}
              ></img>
            </Tooltip>
          </div>
        ) : null}

        <span className={classes.modalCloseButton} onClick={closeModal}>
          <svg viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </span>
      </div>
      <Progress active={loading} />
    </React.Fragment>
  );

  const footer = splitOptions && !isVisibleButtonToolMeasure && (
    <SplitButton
      sx={{ backgroundColor: "red" }}
      disabled={loading}
      options={splitOptions}
      selectedOption={0}
      localStorageSelectedOption={"updateSplitButtonSelectedOption"}
    ></SplitButton>
  );

  const buttonAssociateMeasure = (
    <Grid container direction="column" alignItems="flex-end">
      <Grid item xs={12}>
        <Button
          caption={t("AssociateButton")}
          onClick={() => showModalToolMeasure(object, formFields)}
        ></Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      header={header}
      footer={footer || buttonAssociateMeasure}
      closeModal={closeModal}
      responsive
    >
      <div className={"container-fluid px-0"}>
        {responseError && (
          <div className={"row"}>
            <div className={"col-12"}>
              <div className={"alert alert-danger"}>{responseError}</div>
            </div>
          </div>
        )}
        {grouped && groupFields()}
        {!grouped && (
          <div className={"row mx-0"}>
            {sortByField(formFields, "position").map((field, index) => {
              if (showFormField(field)) {
                return (
                  <div
                    className={classnames(field.class, {
                      [classes.disabled]: field.disabled,
                    })}
                    key={index}
                  >
                    {formField(field, index)}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
      <BarcodeReader
        //onError={handleError}
        onScan={handleScan}
      />
    </Modal>
  );
}
