import React, { useState } from "react";
import styles from "./TableHeaderCellQ.module.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function TableHeaderQ({ children, handleClick }) {
  const [arrowDirection, setArrowDirecton] = useState(false);

  const orderClick = () => {
    setArrowDirecton(!arrowDirection);
    handleClick();
  };

  return (
    <th className={styles.headCell} onClick={orderClick}>
      {children}
      {children ? (
        arrowDirection ? (
          <IoIosArrowUp className={styles.icon} onClick={orderClick} />
        ) : (
          <IoIosArrowDown className={styles.icon} onClick={orderClick} />
        )
      ) : null}
    </th>
  );
}
