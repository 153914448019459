import React, { useState, useEffect } from "react";
import CRUD from "../../components/CRUD";
import request from "../../utils/request";


export default function GlobalBrands () {
 const [data, setData] = useState([]);
 const [loadingData, setLoadingData] = useState(true);


 useEffect(() => {
    updateData()
  }, [])



 const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/MachineMake`)
      .then((response) => {
        setData(response.data);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };



    return(
<React.Fragment>
        <CRUD 

            data={data}
            loadingData={loadingData}
            url={`/api/MachineMake/`}
            updateData={updateData}
            title={'Brands'}
            columns={['brand', 'description']}
            headers={['Brands', 'Description']}
            idField={'id'}
            fieldsMap={['brand', "description", "observations"]}
            formFields={[
            { label: "Brand", class: "col-12 col-md-6 px-0", required: true, regex: true },
            { label: "Description", class: "col-12 col-md-6 px-0", required: true, regex: true },
            { label: "Observations", class: "col-12 col-md-6 px-0", required: true, regex: true },
            ]}
            detailFields={[
            { label: "Brand", name: "brand" },
            { label: "Description", name: "description" },
            { label: "Observations", name: "observations" },
            ]}
            baseObject={{
                brand: {
                id:"",
                brand: "",
                description: "",
                observations: "",
              }
            }}
        />
    </React.Fragment>
    )
    
}