import React, { useState } from 'react'
import ShowPassword from '../../components/Form/UserResetPassword/ShowPassword.js';
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";

   const ViewChangePasswordButton = (props) => {
    const {locale,passwordActual,setPasswordActual,passwordNuevo,setPasswordNuevo,passwordConfirma,setPasswordConfirma,setError,error,loading,t,style,validationMessage} = props;


const [viewPasswordActual, setViewPasswordActual] = useState(false);
const [viewPasswordNuevo, setViewPasswordNuevo] = useState(false);
const [viewPasswordConfirma, setViewPasswordConfirma] = useState(false);

const togglePasswordActualVisibility = () => {
    setViewPasswordActual(!viewPasswordActual);
};

const togglePasswordNuevoVisibility = () => {
    setViewPasswordNuevo(!viewPasswordNuevo);
};

const togglePasswordConfirmaVisibility = () => {
    setViewPasswordConfirma(!viewPasswordConfirma);
};

//Función para manejar cambios en la contraseña
const handleChangeCurrentPassword = (value) => {
    setPasswordActual(value.target.value);
    error.type === "passwordActual" &&
      setError({ message: "", type: null });
  };

  const handleChangePasswordNuevo = (value) => {
    setPasswordNuevo(value.target.value);
    error.type === "passwordNuevo" &&
      setError({ message: "", type: null });
  };

  const handleChangePasswordConfirma = (value) => {
    setPasswordConfirma(value.target.value);
    error.type === "passwordConfirma" &&
      setError({ message: "", type: null });
  };

  return (
    <>
        <InputQ 
            placeholder={t("CurrentPasswordLabel",locale) || "Current Password"} 
            label={t("CurrentPasswordLabel",locale) || "Current Password"} 
            claro={true}
            value={passwordActual}
            // onChange={value => {
            //     setPasswordActual(value);
            //     setError({ type: "", message: "" });
            //     }}
                error={error.type === "passwordActual" ? error.message : ""}
                type={viewPasswordActual ? 'text' : 'password'}
                disabled={loading}
                containerWidth={"100%"}
                containerMargin={"10px"}
                handleChange={handleChangeCurrentPassword}
                validationMessage={validationMessage}
                errorMessage={error.type === "passwordActual" ? error.message : ""}
                // style={{color:"white",backgroundColor:"gray"}}
                />
      <ShowPassword style={style.iconStyle1} parentCallback={togglePasswordActualVisibility} />
      <InputQ 
            placeholder={t("NewPasswordLabel",locale) || "New Password"} 
            label={t("NewPasswordLabel",locale) || "New Password"} 
            claro={true}
            value={passwordNuevo}
          //   onChange={value => {
          //     setPasswordNuevo(value);
          //     setError({ type: "", message: "" });
          // }}
            error={error.type === "passwordNuevo" ? error.message : ""}
            type={viewPasswordNuevo ? 'text' : 'password'}
            disabled={loading}
            containerWidth={"100%"}
            containerMargin={"10px"}
            handleChange={handleChangePasswordNuevo}
            validationMessage={validationMessage}
            errorMessage={error.type === "passwordNuevo" ? error.message : ""}
      />
        <ShowPassword style={style.iconStyle2} parentCallback={togglePasswordNuevoVisibility} />
        <InputQ 
            placeholder={t("ConfirmPasswordLabel", locale) || "Confirm password"} 
            claro={true}
            value={passwordConfirma}
            label={t("ConfirmPasswordLabel", locale) || "Confirm password"}
          //   onChange={value => {
          //     setPasswordConfirma(value);
          //     setError({ type: "", message: "" });
          // }}
            error={error.type === "passwordConfirma" ? error.message : ""}
            type={viewPasswordConfirma ? 'text' : 'password'}
            disabled={loading}
            containerWidth={"100%"}
            containerMargin={"10px"}
            handleChange={handleChangePasswordConfirma}
            validationMessage={validationMessage}
            errorMessage={error.type === "passwordConfirma" ? error.message : ""}
       />
        <ShowPassword style={style.iconStyle3} parentCallback={togglePasswordConfirmaVisibility}/>
    </>
  )
}
export default ViewChangePasswordButton;