import React from "react";

import useTableQ from "../../../utils/Hooks/useTableQ";
import request from "../../../utils/request.js";

import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ.jsx";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../../components/SpinnerQ/SpinnerQ.jsx";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getSession } from "../../../utils/sessionHandler.js";

const SterilizersTable = ({
  sterilizers,
  getSterilizers,
  handleData,
  handleModal,
  handleDetail,
  selectedSector,
  loading,
}) => {
  const {
    currentPage,
    currentItems,
    totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(sterilizers, [
    "name",
    "brand",
    "modelName",
    "methodName",
    "serial",
    "observations",
  ]);

  const onEdit = (value) => {
    let item = sterilizers.find((m) => m.id === value);

    let { name, brand, serial, observations, companyMachineModelId, id } = item;

    handleData({
      name,
      brand,
      serial,
      observations,
      companyMachineModelId,
      companyMachineMakeId: item.companyMachineModel.companyMachineMakeId,
      methodName: item.companyMachineModel.method.name,
      id,
    });

    handleModal(true);
  };

  const onDelete = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        request()
          .delete("/api/Sterilizer", {
            Sterilizer: data,
            UserLogged: getSession(),
          })
          .then(() => {
            getSterilizers(selectedSector);
            toast.success("Your sterilizer has been deleted.");
          });
      }
    });
  };

  return (
    <TableContainerQ>
      <TableHeadQ actions={true}>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("name")}
        >
          Name
        </TableHeaderCellQ>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("brand")}
        >
          Brand
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("modelName")}>
          Model
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("methodName")}>
          Method
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("serial")}>
          Serial
        </TableHeaderCellQ>
        <TableHeaderCellQ
          handleClick={() => handleSortByColumn("observations")}
        >
          Observations
        </TableHeaderCellQ>
      </TableHeadQ>
      {loading ? (
        <div className="spinnerContainer">
          <SpinnerQ />
        </div>
      ) : (
        <TableBodyQ loading={loading}>
          {currentItems.map((m) => (
            <TableRowQ
              key={m.id}
              actions={true}
              edit={true}
              detail={true}
              onEdit={() => onEdit(m.id)}
              onDelete={() => onDelete(m)}
              doubleClick={() => onEdit(m.id)}
              onDetail={() => handleDetail(m)}
            >
              <TableRowCellQ date={true}>
                <TruncatedText text={m.name} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ date={true}>
                <TruncatedText text={m.brand} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.modelName} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.methodName} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.serial} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.observations} maxLength={25} />
              </TableRowCellQ>
            </TableRowQ>
          ))}
        </TableBodyQ>
      )}

      <TablePaginationQ
        handleChangePage={handleChangePage}
        handleChangeItemsPerPage={handleChangeItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </TableContainerQ>
  );
};

export default SterilizersTable;