export default function reducer(state, action) {
    switch (action.type) {
        case "SET_MIGRATIONS":
            return {
                ...state,
                migrations: action.payload.migrations,
                loadingData: false,
                loadingContent: false,
                alertText: action.payload.alertText,
                alertType: action.payload.alertType,
                loadingSave: false
            }
        case "SET_FILE":
            return {
                ...state,
                disableSave: false,
                file: action.payload.file,
                clearFileSelected: action.payload.clearFileSelected,
                name: action.payload.name
            }
        case "SET_SECTORS":
            return {
                ...state,
                sectors: action.payload.sectors
            }
        case "SET_SECTOR_SELECTED":
            return {
                ...state,
                sectorSelected: action.payload.sectorSelected
            }
        case "SET_ALERT":
            return {
                ...state,
                alertText: action.payload.message,
                alertType: action.payload.type,
                disableSave: action.payload.disableSave,
                loadingSave: false
            }
        case "SET_SAVING":
            return {
                ...state,
                loadingSave: action.payload.loadingSave,
                disableSave: action.payload.disableSave
            }
        case "SET_MODAL":
            return {
                ...state,
                openModal: action.payload.openModal
            }
        case "SET_BUTTON_SELECTFILE":
            return {
                ...state,
                disableSelectFile: action.payload.disableSelectFile
            }
        case "CLEAR":
            return {
                ...state,
                migrations: [],
                loadingData: false,
                loadingContent: false,
                loadingSave: false
            }
        default:
            throw new Error();
    }
}
