import React, { useState, useEffect } from 'react';
import request from '../../utils/request';

import Input from '../Input';
import Select from "../Select";
import Button from '../Button';
import Modal from '../Modal';	

import useStyles from './ParameterInputStyles';

export default function ParameterInput(props) {
	const classes = useStyles();
	const { label, parameter, setsuccess, refreshParameters } = props;
	const [languages, setLanguages] = useState([]);

	const [value, setValue] = useState(props.value)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [fetching, setFetching] = useState('')
	const [openModal, setOpenModal] = useState(false)

	const parameterType = ['text', 'email', 'number', 'float', 'float2', 'password', 'enum']

	useEffect(() => {
		Promise.all([
		  request().get(`/api/language`)
		])
		.then(([languagesData]) => {
		  setLanguages(
			languagesData.data.reduce((languagesList, lang) => {
			  languagesList.push([lang.id, lang.description]);
			  return languagesList;
			}, [])
		  );
		})
		.catch(error => console.log(error.response))
	  }, []);

	useEffect(() => {
		setValue(props.value)
	}, [props])

	const handleSave = () => {
		setLoading(true)
		setFetching('save')
		setError('')
		setsuccess('')

		setTimeout(() => {
			request().put('/api/sysparameter', { ...parameter, value: value })
			.then(respose => {
				setLoading(false)
				setFetching('')
				setsuccess(`Parameter ${parameter.sysParameterTypeDescription} successfully updated.`)
				refreshParameters()
			})
			.catch(error => {
				setLoading(false);
				setFetching('')
				setError(error.response.data.message || error.response.data.title);
			})
		}, 500);
	}
	
	const handleSetDefaultValue = () => {
		setOpenModal(false)
		setLoading(true)
		setFetching('reset')
		setError('')
		setsuccess('')
		

		setTimeout(() => {
			request().post('/api/sysparameter/defaultvalue', parameter)
			.then(respose => {
				setLoading(false)
				setFetching('')
				setsuccess(`Parameter ${parameter.sysParameterTypeDescription} successfully updated.`)
				refreshParameters()
			})
			.catch(error => {error.respone && 
				setLoading(false)
				setFetching('')
				setError(error.response.data.message || error.response.data.title) 
			})
		}, 500);
	}

	const header = (
		<React.Fragment>
		  <div className={classes.header}>
			<span className={classes.modalCloseButton} onClick={()=>setOpenModal(false)}>
			  <svg viewBox="0 0 24 24">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			  </svg>
			</span>
		  </div>
		</React.Fragment>
	  );
	
	  const footer = (
		<div className={classes.footerAction}>
		  <Button
			caption={"Confirm"}
			dense
			onClick={handleSetDefaultValue}
		  />
		</div>
	  );
	if (parameter.fieldType === 7){
	return (
		<>
		<div className={classes.root}>
			<Select
				value={value}
				label={label}
				variant={"outlined"}
				error={error}
				onChange={(val) => setValue(val)}
				disabled={loading}
				noError={error.length === 0}
				options={languages}
			/>
			
			<div className={classes.actionRoot}>
				<Button 
					caption={"Save"}
					loading={fetching === 'save'}
					loadingText={'Saving...'}
					onClick={handleSave}
					disabled={loading}
				/>
				<Button 
					caption={"Reset default value"}
					loading={fetching === 'reset'}
					loadingText={'Resetting default value...'}
					onClick={() => setOpenModal(true)}
					disabled={loading}
				/>
			</div>
		</div>

		{openModal &&
			<Modal header={header} footer={footer} closeModal={()=>setOpenModal(false)} >
				<div className={'container-fluid'}>
					<div className={'row'}>
						<div className={'col-12'} style={{textAlign:'center'}}>
						Are you sure you want to set the default value for the parameter <b>{parameter.sysParameterTypeDescription}</b>?
						</div>
					</div>
					</div>
			</Modal>
		}
		</>
	)
	}
	return (
		<>
		<div className={classes.root}>
			<Input 
				value={value}
				label={label}
				variant={"outlined"}
				error={error}
				onChange={(val) => setValue(val)}
				disabled={loading}
				noError={error.length === 0}
				type={parameterType[parameter.fieldType - 1]}
			/>
			<div className={classes.actionRoot}>
				<Button 
					caption={"Save"}
					loading={fetching === 'save'}
					loadingText={'Saving...'}
					onClick={handleSave}
					disabled={loading}
				/>
				<Button 
					caption={"Reset default value"}
					loading={fetching === 'reset'}
					loadingText={'Resetting default value...'}
					onClick={() => setOpenModal(true)}
					disabled={loading}
				/>
			</div>
		</div>

		{openModal &&
			<Modal header={header} footer={footer} closeModal={()=>setOpenModal(false)} >
				<div className={'container-fluid'}>
        	    	<div className={'row'}>
        	    	  <div className={'col-12'} style={{textAlign:'center'}}>
        	    	    Are you sure you want to set the default value for the parameter <b>{parameter.sysParameterTypeDescription}</b>?
        	    	  </div>
        	    	</div>
        	  	</div>
			</Modal>
		}
		</>
	)
} 
 