import generalObjectsMock from "./generalObjectsMock";
import { getGeneralObjects } from "../utils/sessionHandler";
import _ from "lodash";

// Cómo utilizar el mock de traducciones
// En la carpeta de la pantalla ej: ../pages/Sector/
// Crear un archivo ./translationMock.js (ver ejemplo en ../pages/Sector/translationMock.js)

// En el archivo que corresponde a la pantalla: ej: ../pages/Sector/Sector.js
// Reemplazar: import t from "../../utils/translation";
// Por:        import t from "../../translation_mocks/translationMocker";
// Importar:   import mock from "./translationMock"

// Reemplazar: const locale = useContext(LocalizationContext);
// Por:        const locale = mock;

// Ejemplos de los formatos para los mock:
// Para objetos generales: generalObjectsMock.js
// Para objetos de cada pantalla: Screen_translationMock.js

export default (objectName, localeContext) => {
  const context = localeContext;
  const locale = context ? context.locale || context : null;
  const generalObjects = getGeneralObjects();
  
  if (_.get(locale, objectName + ".Translation")) {
    return _.get(locale, objectName + ".Translation");
  } else if (_.get(generalObjects, objectName)) {
    return _.get(generalObjects, objectName);
  } else if (_.get(generalObjectsMock, objectName)) {
    return _.get(generalObjectsMock, objectName);
  } else {
    console.log(`Object ${objectName} translation was not found in the context`);
    return "";
  }
};
