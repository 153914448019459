import React, { useState, useEffect } from "react";
import styles from "./InputQ.module.css";

const InputQ = ({
  containerWidth = "48%",
  containerMargin = "0px",
  handleChange,
  defaultValue,
  value,
  label,
  type,
  placeholder,
  inputRef,
  required,
  disabled,
  name,
  errorMessage = "This field is required",
  validate = false,
  additionalValidation = false,
  additionalMessage = "",
  category = "sterilization",
  onHandleScan = null,
  min,
  onKeyDown = () => {},
  onFocus = () => {},
  autocomplete = "on"
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [input, setInput] = useState("");

  const inputThreshold = 110;

  const handleFocus = () => {
    onFocus();
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      setIsFocused(false);
    }
    if (required && !e.target.value) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  useEffect(() => {
    if (validate && required && !value) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [validate, value, required]);

  const handleKeyDown = (e) => {
    onKeyDown(e);
    if (onHandleScan) {
      const currentTime = new Date().getTime();
      const timeDifference =
        currentTime - (inputRef.current.lastInputTime || 0);

      if (timeDifference < inputThreshold && e.key === "Enter" && inputRef?.current?.value.length > 6) {
        e.preventDefault();
        inputRef.current.blur();
        const scannedText = inputRef?.current?.value || "";

        // Escanea y procesa cada subcadena del texto escaneado
        for (let i = 0; i < scannedText.length; i++) {
          const substring = scannedText.substring(i);
          onHandleScan(substring);

          // Verifica si cumple con el patrón esperado
          let regex = /^01\d{14}17.*$/; // GTIN y código de vencimiento
          if (regex.test(substring)) {
            handleChange((prevValue) => prevValue.replace(substring, ""), true);
            break;
          }
        }
      }
      inputRef.current.lastInputTime = currentTime; // Actualiza la última entrada
    }
  };

  const handleChangeInput = (e) => {
    handleChange(e);
    setInput(e.target.value);
    if (!(required && e.target.value === "")) {
      setShowMessage(false);
    }
  };

  return (
    <div
      className={styles.inputContainer}
      style={{ width: containerWidth, marginBottom: containerMargin }}
    >
      <input
        onChange={handleChangeInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={styles.inputField}
        type={type ? type : "text"}
        defaultValue={defaultValue}
        value={value}
        placeholder=" "
        ref={inputRef}
        disabled={disabled}
        name={name}
        onKeyDown={handleKeyDown}
        min={min}
        autocomplete={autocomplete}
      />
      <label
        className={`${styles.label} ${
          isFocused || value ? styles.labelFocused : ""
        } ${category === "washing" ? styles.labelPurple : styles.labelGreen}`}
      >
        {label} {required && "*"}
      </label>
      {showMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : additionalValidation ? (
        <span className={styles.errorMessage}>{additionalMessage}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputQ;
