import React, { useState, useEffect } from "react";
import classNames from "classnames";
import './font.css'

import useStyles from "./MenuItemStyles";

export default function MenuItem(props) {
  const classes = useStyles();
  const { item, open, activeMenuOption, menuOption, group, appUser } = props;
 
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    props.expandedOption === null
      ? item.Items.forEach(i => {
        const key = Object.keys(i)[0];

        if (i[key].Id === activeMenuOption) {
          setExpand(true);
          props.setExpandedOption(item.Id);
        }
      })
      : props.expandedOption === item.Id
        ? setExpand(true)
        : setExpand(false);
  }, [props.expandedOption, item, activeMenuOption, props]);

  const handleItemOpenClose = () => {
    item.Items.length === 0
      ? (window.location.href = item.Url)
      : props.expandedOption === item.Id
        ? props.setExpandedOption("")
        : props.setExpandedOption(item.Id);
  };

  // Función para determinar dinámicamente la clase del ícono
  function getDynamicIconClass(imageClassName,index) {
    // Mapeo de classNames a reemplazar con estilos CSS personalizados
    const customIconClasses = {
      'fas fa-home': {
        className: 'terragene-home',
      },
      'fas fa-building': {
        className: 'terragene-company',
      },
      'fas fa-bezier-curve': {
        className: 'terragene-sector'
      },
      'fas fa-users': {
        className: 'terragene-user',
      },
      'fas fa-hdd': {
        className: 'terragene-autoRead',
      },
      'fas fa-plug': {
        className: 'terragene-connected',
      },
      'fas fa-running': {
        className: 'terragene-inProgress',
      },
      'fas fa-braille': {
        className: 'terragene-completed',
      },
      'fas fa-temperature-high': {
        className: item.GroupNameDescription === "Equipment Group Text" ? 'terragene-sterilizer' : 'terragene-sterilization',
      },
      'fas fa-hot-tub': {
        className: item.GroupNameDescription === "Equipment Group Text" ? 'terragene-washer' : 'terragene-cleaning',
      },
      'fas fa-pen-square': {
        className: 'terragene-equipment'
      },
      'fas fa-magic': {
        className: 'terragene-disinfector'
      },
      'fas fa-tools': {
        className: 'terragene-instruments'
      },
      'fas fa-id-badge': {
        className: 'terragene-license'
      },
      'fas fa-user-tag': {
        className: 'terragene-myLicense'
      },
      'fas fa-tachometer-alt': {
        className: 'terragene-dashboard'
      },
      'fas fa-chart-line': {
        className: 'terragene-IQAS'
      },
      'fas fa-draw-polygon': {
        className: 'terragene-SPR'
      },
      'fas fa-database': {
        className: 'terragene-saved'
      },
      'fas fa-cogs': {
        className: 'terragene-configuration'
      },
      'fas fa-cog': {
        className: 'terragene-parameters'
      },
      'far fa-address-card': {
        className: 'terragene-log'
      },
      'fas fa-user': {
        className: 'terragene-changePassword'
      },
      'fas fa-retweet': {
        className: 'terragene-subscriptions'
      },
      'fas fa-coins': {
        className: 'terragene-database'
      },
      'fas fa-industry': {
        className: 'terragene-brands'
      },
      'fas fa-microchip': {
        className: 'terragene-models'
      },
      'fas fa-tasks': {
        className: 'terragene-instruments'
      },
      'fas fa-object-ungroup' : {
        className: 'terragene-instruments'
      },
      'fas fa-clipboard-list': {
        className: 'terragene-packages-types'
      },
    };

    // Verifica si el className existe en el mapeo
    if (customIconClasses.hasOwnProperty(imageClassName)) {
      // Devuelve el nuevo className
      return customIconClasses[imageClassName].className;
    }

    // Si no se encuentra en el mapeo, devuelve el className original
    return imageClassName;
  }
  


  return (
    <div
      className={classNames(
        classes.root,
        { [classes.expand]: expand },
        { [classes.open]: open }
      )}
      style={{ height: expand ? 40 + 35 * item.Items.length : 40 }} //height auto para mostrar todos los elementos
    >

      {/* Este div debe desaparecer para mostrar todos los iconos, este es el que agrupa */}
      <div
        className={classNames(classes.item, {
          [classes.active]: (item.Id === activeMenuOption && item.Items.length === 0)
        })}
        onClick={handleItemOpenClose}
        data-tip={!open ? item.Translation : ""}
        data-place="right"
      >
        <div className={classes.itemIcon}>
          {/* <i className={classNames(item.Image, classes.iTag)}></i> */}
          <i className={classNames(getDynamicIconClass(item.Image), classes.iTag)}></i>
        </div>
        <div className={classes.itemText}>{item.Translation}</div>
        {item.Items.length > 0 && (
          <div className={classes.expandIcon}>
            <svg viewBox="0 0 24 24">
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
        )}
      </div>      
      <ul className={classes.subitemsList}>
        {item.Items
          .map(x => {
            const key = Object.keys(x)[0];
            
            if (appUser && (!menuOption[group] || !menuOption[group].Items.some(g => g === key))) return;

            return (
              <li
                key={x[key].Id}
                className={classNames(classes.subitem, {
                  [classes.subitemActive]: x[key].Id === activeMenuOption
                })}
                data-tip={!open ? x[key].Translation : ""}
                data-place="right"
                onClick={() =>
                  x[key].Id !== activeMenuOption &&
                  (window.location.href = x[key].Url)
                }
              >
                <div className={classes.subitemIcon}>
                  {/* <i className={x[key].Image}></i> */}
                  <i className={getDynamicIconClass(x[key].Image)}></i>
                  {/* <svg viewBox="0 0 24 24">
                  <path d={x.svg} />
                </svg> */}
                </div>
                <div className={classes.subitemText}>{x[key].Translation}</div>
              </li>
            );
        })}
      </ul>
    </div>
  );
}
