import React, { useState, useContext, useEffect } from 'react';
import request from '../../../utils/request';
import Progress from '../../Progress';
import Input from '../../Input';
import Button from '../../Button';
import LocalizationContext from "../../../utils/LocalizationContext";
import Company from '../Company/Company';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { getDist } from '../../../utils/sessionHandler';
import { allowOnlyCharactersAndNumbers } from '../../../utils/functions/Regex';
import LoginInput from '../../LoginInput';
import Button2 from '../../Button2';
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";
import ButtonQ from "../../ButtonQ";
import isoLogoQ from "../../../assets/images/ISOLOGO-BIONOVAQ.svg"

const useCheckBoxStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 3,
        padding: '8px',
        margin: '0px',
        color: 'black', 
    },
    label: {
        fontSize: '13px'
    }
});

const BlueCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" required={true} {...props} />);

const SignUp = (props) => {
    const checkBoxClass = useCheckBoxStyles()
    const { classes, dispatch, state, images } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [eulaAccepted, setEulaAccepted] = useState(state.eulaAccepted);
    const [validationMessage, setValidationMessage] = useState("");

    const [user, setUser] = useState(
        state.user ? {
            Email: state.user.Email,
            Name: state.user.Name,
            LastName: state.user.LastName
        } : { Email: state.email }
    );

    const [language, setLanguage] = useState(state.language || null)

    const [company, setCompany] = useState(state.company ? {
        Name: state.company.Name,
        Country: state.company.Country,
        CountryId: state.company.CountryId,
        City: state.company.City,
        Addres: state.company.Addres,
        Phone: state.company.Phone,
        Province: state.company.Province,
        Contact: state.company.Contact,
        PostalCode: state.company.PostalCode
    } : {});

    const [sector, setSector] = useState(
        state.sector ? {
            Name: state.sector.Name
        } : {}
    );

    const locale = useContext(LocalizationContext);

    // Funciones para manejar los cambios en los input 
  const handleChangeEmail = (value) => {
        setUser({ ...user, Email: allowOnlyCharactersAndNumbers(value.target.value) });
        setError({})
    }
    const handleChangeName = (value) => {
        setUser({ ...user, Name: allowOnlyCharactersAndNumbers(value.target.value) });
        setError({})
    }
    const handleChangeLastName = (value) => {
        setUser({ ...user, LastName: allowOnlyCharactersAndNumbers(value.target.value) });
        setError({})
    }
    const handleChangeSector = (value) => {
        setSector({ ...sector, Name: allowOnlyCharactersAndNumbers(value.target.value) }); 
        setError({})
    }

    const checkRequiredFields = () => {
        let result = true;
        let error = { message: "This field is required"}
        let message = "This field is required"
        //User
        if (!user.Email) {
            error.email = true
            result = false
            setValidationMessage(message); 
        }
        if (!user.Name) {
            error.name = true
            result = false
            setValidationMessage(message); 
        }
        if (!user.LastName) {
            error.lastName = true
            result = false
            setValidationMessage(message); 
        }
        //Company
        if (!company.Name) {
            error.companyName = true
            result = false
            setValidationMessage(message); 
        }
        if (!company.CountryId) {
            error.country = true
            result = false
            setValidationMessage(message); 
        }
        if (!company.Province) {
            error.province = true
            result = false
            setValidationMessage(message); 
        }
        if (!company.City) {
            error.city = true
            result = false
            setValidationMessage(message); 
        }
        if (!company.PostalCode) {
            error.postalCode = true
            result = false
            setValidationMessage(message); 
        }

        if (!language) {
            error.language = true
            result = false
            setValidationMessage(message); 
        }
        if (!sector.Name) {
            error.sector = true
            result = false
            setValidationMessage(message); 
        }
        setError(error)
        // setValidationMessage(result ? "" : "This field is required");
        return result;
    };

    useEffect(() => {
        if (user.Email || user.Name || user.LastName || sector.Name || company.Name || company.Country || company.Province || company.City || company.PostalCode || language) {
          setValidationMessage("");
        }
      }, [user.Email,user.Name,user.LastName,sector.Name, company.Name, company.Country, company.Province, company.City, company.PostalCode, language]);

    const signup = e => {
        document.activeElement.blur();
        e.preventDefault();
        if (!checkRequiredFields()) {
            return;
        }
        setLoading(true);
        const data = { Administrator: user, Company: company, DistributorId: getDist().id, Sector: sector, Language: language.id }
        request().post(`/api/login/signup`, data)
            .then(response => {
                dispatch({
                    type: "loginClick",
                    payload: { email: data.Administrator.Email, message: response.data }
                })
            })
            .catch(error => {
                setError({ alert: true, message: error.response?.data?.message })
                setLoading(false)
            })
    };

    const preventDefault = (event) => {
        event.preventDefault()
        if (!company.CountryId) {
            setError({ alert: true, message: "You must select a Country to see Terms and Conditions" });
        } else {
            dispatch({
                type: "seeTermsAndConditions",
                payload: { User: user, Company: company, Language: language, Sector: sector }
            })
        }
    }

    return (
        <form className={classes.loginForm}  onSubmit={e => signup(e)}>
            <Progress class={classes.loading} active={loading} />
            <div className={classes.containerHeader}>
                {/* <img style={{ float: 'left' }} src={isoLogoQ} className={classes.smallIsoLogo}></img>
                <div className={classes.container}>
                <span className={classes.title}>{locale.header.signup}</span>
                </div> */}
                 <div >
              <img src={isoLogoQ} className={classes.smallIsoLogo} alt='title'/>
              </div>
              <div className={classes.container}>
                <p className={classes.title}>
                {isoLogoQ && `| ${"Sign up"}`}
              </p>
              </div>
            </div>
            <div className={"alert alert-info"}>{"Kindly complete the form below to register your company and set up the administrator account. Once submitted, please reach out to your sales executive to proceed with the next steps. Note that you will not be able to log in until the onboarding process is finalized with your sales executive."}</div>
            {error.alert && (
                <div className={"alert alert-danger"}>{error.message}</div>
            )}
            <div className={'row'}>
                <div className={'col-lg-6'}>
                    <h5 className={classes.subTitle}>User identification</h5>
                    <InputQ
                        containerWidth={"110%"}
                        containerMargin={"10px"}
                        gutter={"0px"}
                        claro={true}
                        label={locale.form.input.email}
                        value={user.Email}
                        // onChange={value => {
                        //     setUser({ ...user, Email: allowOnlyCharactersAndNumbers(value) });
                        //     setError({})
                        // }}
                        handleChange={handleChangeEmail}
                        errorMessage={error.email ? error.message : ""}
                        disabled={loading}
                        required={true}
                        validate={true}
                        validationMessage={validationMessage}
                        // type={"email"}
                    />
                     {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
                    <InputQ
                        containerWidth={"110%"}
                        containerMargin={"10px"}
                        gutter={"0px"}
                        claro={true}
                        label={locale.form.input.name}
                        value={user.Name}
                        // onChange={value => {
                        //     setUser({ ...user, Name: allowOnlyCharactersAndNumbers(value) });
                        //     setError({})
                        // }}
                        handleChange={handleChangeName}
                        type={"text"}
                        errorMessage={error.name ? error.message : ""}
                        disabled={loading}
                        required={true}
                        validationMessage={validationMessage}
                    />
                    {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
                    <InputQ
                        containerWidth={"110%"}
                        containerMargin={"10px"}
                        gutter={"0px"}
                        claro={true}
                        label={locale.form.input.lastName}
                        value={user.LastName}
                        // onChange={value => {
                        //     setUser({ ...user, LastName: allowOnlyCharactersAndNumbers(value) });
                        //     setError({})
                        // }}
                        handleChange={handleChangeLastName}
                        type={"text"}
                        errorMessage={error.lastName ? error.message : ""}
                        disabled={loading}
                        validationMessage={validationMessage}
                    />
                    {/* {validationMessage && (
                <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
                    <InputQ
                        containerWidth={"110%"}
                        containerMargin={"10px"}
                        gutter={"0px"}
                        claro={true}
                        label={locale.form.input.sectorName}
                        value={sector.Name}
                        // onChange={value => {
                        //     setSector({ ...sector, Name: allowOnlyCharactersAndNumbers(value) });
                        //     setError({})
                        // }}
                        handleChange={handleChangeSector}
                        type={"text"}
                        errorMessage={error.sector ? error.message : ""}
                        disabled={loading}
                        validationMessage={validationMessage}
                    />
                    {/* {validationMessage && (
               <p className={classes.validationMessage}>{validationMessage}</p>
                 )} */}
                </div>
                <div className={'col-lg-6'} style={{fontStretch:"condensed"}}>
                    <h5 className={classes.subTitle}>Company info</h5>
                    <Company company={company} setCompany={setCompany} error={error} setError={setError} countries={state.countries} loadingCountries={state.loadingCountries} setEulaAccepted={setEulaAccepted} languages={state.languages} language={language} setLanguage={setLanguage} validationMessage={validationMessage} classes={classes} setValidationMessage={setValidationMessage}></Company>
                </div>
            </div>
            <Link style={{ marginLeft: '34px', fontSize: '13px', color: "#00b189" }} to="#" onClick={preventDefault} variant="body2">
                {locale.companyForm.seeEulaText}
            </Link>
            <FormControlLabel
                classes={checkBoxClass}
                control={<BlueCheckbox onChange={(value) => {
                    setEulaAccepted(value.target.checked)
                }} checked={eulaAccepted} />}
                label={locale.companyForm.eulaText}
            />
            <ButtonQ
                type={"submit"}
                caption={locale.companyForm.confirm}
                disabled={loading || !eulaAccepted}
                minwidth={true}
            />
            {
                // <p
                //     className={classes.backToLoginLink}
                //     onClick={() => window.location.replace("/login")}
                // >
                //     Have an account? Log in
                // </p>
                <p className={classes.backToLoginLink}>
                   Have an account? 
              <span className={classes.backTologinLink2} onClick={() => window.location.replace("/login")}>
                   Log in
             </span>
               </p>
            }
       <img
        src={images.logo}
        alt=""
        className={classes.logoTerragene}
       ></img>
        </form>
    );
};

export default SignUp;