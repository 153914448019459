import React from "react";
import propTypes from "prop-types";

import useStyles from "./PaperStyles";

export default function Paper(props) {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}

Paper.propTypes = {
  children: propTypes.oneOfType([propTypes.array, propTypes.element]).isRequired
};

