import React from 'react';

import CircularProgress from '../CircularProgress';

import useStyles from './PageContentLoadingStyles';

export default function PageContentLoading() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress size={80} />
		</div>
	)	 
} 
 