import React, { useState, useEffect } from "react";
import CRUD from "../../components/CRUD";
import request from "../../utils/request";


export default function LoadTypes () {
 const [data, setData] = useState([]);
 const [loadingData, setLoadingData] = useState(true);


 useEffect(() => {
    updateData()
  }, [])



 const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/LoadType`)
      .then((response) => {
        setData(response.data);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };



    return(
<React.Fragment>
        <CRUD 

            data={data}
            loadingData={loadingData}
            url={`/api/LoadType/`}
            updateData={updateData}
            title={'Load Types'}
            columns={['name', 'description']}
            headers={['Name', 'Description']}
            idField={'id'}
            fieldsMap={['name', "description"]}
            formFields={[
            { label: "Name", class: "col-12 col-md-6 px-0", required: true, regex: true },
            { label: "Description", class: "col-12 col-md-6 px-0", required: true, regex: true }
            ]}
            detailFields={[
            { label: "Name", name: "name" },
            { label: "Description", name: "description" }
            ]}
            baseObject={{
                loadType: {
                id:"",
                name: "",
                description: ""
              }
            }}
        />
    </React.Fragment>
    )
    
}