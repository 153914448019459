import React, { useState, useEffect } from 'react';
import request from '../../utils/request';
import { flatten } from 'flat';

import CRUD from '../../components/CRUD';
import { verifyColumns, verifyForm, verifyHeaders, verifyFieldsMap, verifyDetailFields } from '../../utils/languagePermittedHandler'
import FixedAlert from '../../components/FixedAlert/FixedAlert';

export default function GeneralObjects() {
    const [loadingData, setLoadingData] = useState(true)
    const [listGeneralObjects, setlistGeneralObjects] = useState([])

    const [languages, setLanguages] = useState([])
    const [columns, setColumns] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [fieldForm, setFieldForm] = useState([]);
    const [fieldsMap, setFieldMap] = useState([]);
    const [detailFields, setDetailFields] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        async function fetchData() {
            request().get(`/api/Language`)
                .then((languages) => {
                    setLanguages(languages.data)
                })
                .catch(error => console.log(error));
        }
        fetchData()
    }, [])

    useEffect(() => {
        updateData()
    }, [languages])

    const updateData = () => {
        const headers = ["ID"]
        const columns = ["generalObjectName"]
        const visibleFields = []
        setHeaders(verifyHeaders(headers, languages))
        setFieldForm(verifyForm(languages))
        setColumns(verifyColumns(columns, "generalObject", languages))
        setFieldMap(verifyFieldsMap("generalObject", languages))
        setDetailFields(verifyDetailFields(visibleFields, "generalObject", languages))
        setLoadingData(true)
        request().get('/api/generalobject')
            .then(response => {
                setlistGeneralObjects(response.data.map(x => flatten(x)))
                setTimeout(() => { setLoadingData(false) }, 250);
            })
            .catch(error => console.log(error))
    }

    const resetValue = (row) => {
      request().get(`/api/GeneralObject/resetGeneralObject?generalObject=${row.generalObjectName}`).then(() => {
        updateData();
      }).catch(err => {
        setError(err?.response?.data?.message)
      })
    }

    return (
        <>
            <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
            <CRUD
                tableActions={[
                    {
                        icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
                        tooltip: "Reset",
                        action: resetValue,
                    }
                ]}
                newOption={false} deleteOption={false}
                data={listGeneralObjects} title={'General Objects'} updateData={updateData} loadingData={loadingData}
                url={'/api/generalobject/save'} method={'post'}
                idField={'id'}
                columns={columns}
                headers={headers}
                fieldsMap={fieldsMap}
                formFields={fieldForm}
                detailFields={detailFields}
            />
        </>
    )
}