import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import {
  getSession,
  getSysUserLanguage,
  logout,
  getUserType,
  getUserCompany,
  getEula,
  getUserLanguage,
} from "../../utils/sessionHandler";
import request from "../../utils/request";
import { useTheme } from "@material-ui/core/styles";
import Redirect from "../../utils/Redirect";

import Menu from "./Menu";

import useStyles from "./NavBarStyles";
import { getMenuItemId } from "../../utils/ValidateURL";
import Button from "../Button";
import { isNull, isUndefined } from "lodash";
import t from "../../utils/translation";

import Sterilization from "../../assets/images/Sterilization.svg";
import userImage from "../../components/NavBar/Menu/icons/USERWHITE.svg";
import { FaBookOpen } from "react-icons/fa";

export default function NavBar(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState();
  const [user, setUser] = useState({});
  const [userType, setUserType] = useState(1);
  const menuRef = useRef(null);

  useEffect(() => {
    const infouser = getSession();
    const eula = getEula();

    if (infouser.UserType === 0 && (!eula || !eula.acceptedEula))
      window.location.href = "/appeula";

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          setUser(infouser);
        });
    else setUser(infouser);

    setUserType(
      infouser.UserType === undefined ? 1 : infouser.UserType === 3 ? 3 : 2
    );
  }, []);

  const { MenuOptions = {}, currentPage = {}, screenName, moduleName } = props;

  const handleLogout = () => {
    logout();
  };

  const activeMenuOption = getMenuItemId(
    currentPage.menuGroup,
    currentPage.key
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false); // Cierra el menú cuando se hace clic fuera de él
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Si el menú no está abierto, no es necesario agregar el listener
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  const urlManual =
    getUserLanguage()?.enumId === "SPANISH"
      ? "https://www.manula.com/manuals/terragene/manual-de-usuario-bionovaq/1/es/topic/que-es-bionovaq"
      : "https://www.manula.com/manuals/terragene/manual-de-usuario-bionovaq";

  return (
    <div>
      <header className={classes.root}>
        <div className={classes.navbar}>
          <div ref={menuRef}>
            <Menu
              open={open}
              setOpen={setOpen}
              MenuOptions={MenuOptions}
              activeMenuOption={activeMenuOption}
              menuType={theme ? theme.menuType : null}
              userType={userType}
              menuOptions={theme ? theme.menuOptions : null}
              hamburgerIcon={
                <div
                  className={classes.hamburgerButtonRoot}
                  onClick={() => setOpen(!open)}
                >
                  <span
                    className={classNames({
                      [classes.hamburgerButtonContentOpen]: open,
                      [classes.hamburgerButtonContent]: !open,
                    })}
                  >
                    <svg
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className={classes.hamburgerButtonSvg}
                    >
                      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                    </svg>
                  </span>
                </div>
              }
            />
          </div>
          {/* <div
            className={classes.hamburgerButtonRoot} 
            onClick={() => setOpen(!open)}
          >
            <span className={classes.hamburgerButtonContent}> 
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className={classes.hamburgerButtonSvg}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </span>
          </div>  */}
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <div
              onClick={() =>
                Redirect({
                  redirect: true,
                  path: "/appmain",
                })
              }
              style={{
                cursor: "pointer",
              }}
            >
              <img
                src={theme.palette.images.logoBionovaQ}
                className={classes.logoBionovaQ}
              />
            </div>
          </div>
          <div style={{ marginLeft: 25 }}>
            {!isUndefined(getSysUserLanguage()) &&
              !isNull(getSysUserLanguage()) &&
              getUserType() === 0 && (
                <Button
                  caption={"Translate"}
                  color={"primary"}
                  onClick={() =>
                    Redirect({
                      redirect: true,
                      path: "/accesstotranslate",
                      parameters: {
                        moduleId: moduleName,
                        screenId: screenName,
                      },
                    })
                  }
                />
              )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.logoTerragene}>
            <img src={theme.palette.images.logo}></img>
            <a href={urlManual} target="_blank">
              <FaBookOpen
                style={{
                  color: "#00b189",
                  fontSize: "22px",
                  cursor: "pointer",
                  marginLeft: "24px",
                  marginTop: "5px",
                }}
              />
            </a>
          </div>
          <div className="dropdown">
            <div data-toggle="dropdown" className={classes.user}>
              <span className={classes.userName}>{user.toString}</span>
              <span className={classes.userCircle}>
                <span className={classes.userIcon}>
                  {/* <svg viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                </svg> */}
                  <img src={userImage} alt="user"></img>
                </span>
              </span>
            </div>
            <div
              className={classNames(classes.mainDropdown, "dropdown-menu")}
              style={{
                border: "none",
                backgroundColor: "#F8F8F8",
                zIndex: -10,
                marginTop: "-35px",
                borderRadius: "10px",
              }}
              aria-labelledby="dropdownMenu2"
            >
              <div className={classes.companyInfo}>
                {(user.Company || user.Distributor) && (
                  <div className={classes.title}>{t("CompanyInfo")}</div>
                )}
                <div>
                  {user.Company && (
                    <div className={classes.importantInfo}>
                      {user.Company.Name}{" "}
                    </div>
                  )}
                  {user.Distributor && <div>{user.Distributor.Name}</div>}
                  {user.Distributor && (
                    <div className={classes.title}>{t("Domain")}</div>
                  )}
                  {user.Distributor && <div>{user.Distributor.Domain}</div>}
                  {user.Company && <div>{user.IdDistributorCompany}</div>}
                  {user.Company && (
                    <span>{user.Company?.Country?.Name + " - "}</span>
                  )}

                  {user.Company && <span>{user.Company.Province + " - "}</span>}
                  {user.Company && <span>{user.Company.City + " - "}</span>}
                  {user.Company && <span>{user.Company.Addres}</span>}
                  <div
                    style={{
                      borderBottom:
                        "solid 1px " + theme.palette.terragene.darkSecondary,
                    }}
                  ></div>
                  {user.Sector && user.Sector.Name && (
                    <div className={classes.title}>{t("Sector")}</div>
                  )}
                  {user.Sector && user.Sector.Name && (
                    <div className={classes.importantInfo}>
                      {user.Sector.Name}
                    </div>
                  )}
                  <div
                    style={{
                      borderBottom:
                        "solid 1px " + theme.palette.terragene.darkSecondary,
                    }}
                  ></div>
                  {user.UserTypeDescription && (
                    <div className={classes.title}>{t("UserType")}</div>
                  )}
                  {user.UserTypeDescription && (
                    <div className={classes.importantInfo}>
                      {user.UserTypeDescription}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    borderBottom:
                      "solid 1px " + theme.palette.terragene.darkSecondary,
                  }}
                ></div>
                <div className={classes.title}>Email</div>
                <div className={classes.importantInfo}>{user.Email}</div>
              </div>
              <div>
                <div
                  style={{
                    borderBottom:
                      "solid 1px " + theme.palette.terragene.darkSecondary,
                  }}
                ></div>
                <div className={classes.btnLogout} onClick={handleLogout}>
                  {" "}
                  Log out{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className={classNames(classes.content, {
          [classes.menuOpenGutter]: open,
        })}
      >
        <div
          className={classNames(classes.contentChild, {
            [classes.contentWithBlur]: open, // Aplica el desenfoque si el menú está abierto
          })}
        >
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
}
