import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";

import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import styles from "./ManagePackages.module.css";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../utils/printTicket.js";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../components/SpinnerQ/SpinnerQ.jsx";
import PackageDetail from "../../components/PackageDetail/PackageDetail.jsx";
import PackageLabel from "../../components/packageLabel/PackageLabel.jsx";
import withReactContent from "sweetalert2-react-content";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../utils/parseFiltersDate.js";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import t from "../../utils/translation.js";

export default function ManagePackages() {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [status, setStatus] = useState(5);
  const [packageNumber, setPackageNumber] = useState("");
  const [method, setMethod] = useState("");
  const [methods, setMethods] = useState([]);
  // const [indicatorsWithoutDetail, setIndicatorsWithoutDetail] = useState(null);

  const [loadingPackages, setLoadingPackages] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [name, setName] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  // const getPackages = async () => {
  //   setLoadingPackages(true);
  //   try {
  //     const response = await request().get(`/api/package`);
  //     setPackages(
  //       response.data.filter(
  //         (p) => p.packageStatus === 1 || p.packageStatus === 2
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error fetching packages:", error);
  //   } finally {
  //     setLoadingPackages(false);
  //   }
  // };

  const getPackages = async () => {
    setLoadingPackages(true);

    try {
      const response = await request().get(
        `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setPackages(response.data.packageList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  const getMethods = () => {
    request()
      .get("/api/method/1")
      .then((res) =>
        setMethods(
          res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
        )
      );
  };

  const handleSearch = (e) => {
    setName(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${
            e.target.value
          }&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };

  const handlePackageNumber = (e) => {
    setPackageNumber(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${
            e.target.value
          }&status=${status}&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };

  const handleMethod = (e) => {
    setMethod(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${name}&method=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setLoadingPackages(true);

    try {
      request()
        .get(
          `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${
            e.target.value
          }&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setPackages(res.data.packageList);
          setCurrentPage(1);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching packages:", err);
    } finally {
      setLoadingPackages(false);
    }
  };

  // const getPackageIndicators = async (packageGeneralId) => {
  //   try {
  //     // Realiza la solicitud al endpoint
  //     const response = await request().get(
  //       `/api/package/info?packageId=${packageGeneralId}`
  //     );

  //     // Extrae los indicadores del response.data
  //     const indicators = response.data.indicators;

  //     setIndicatorsWithoutDetail({
  //       indicators,
  //     });
  //   } catch (error) {
  //     // Maneja el error en caso de que la solicitud falle
  //     console.error("Error fetching package indicators:", error);
  //   }
  // };

  const getStatusName = (id) => {
    return id === 1 ? t("AssemblingSpan") : t("ReadyForLoadSpan");
  };

  const getStatusColor = (id) => {
    return id === 1 ? "yellow" : "green";
  };

  const onEdit = (packageGeneralId) => {
    localStorage.setItem("packageEditId", packageGeneralId);
    Redirect({
      redirect: true,
      path: "/appcreatepackages",
    });
  };

  const onDelete = (packageGeneralId) => {
    confirm({
      title: "Delete Package",
      text: "Are you sure you want to delete this package?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      onConfirm: () => handleDelete(packageGeneralId),
    });
  };

  const handleDelete = (packageGeneralId) => {
    const objectToDelete = packages.find(
      (pkg) => pkg.packageGeneralId === packageGeneralId
    );

    const adjustedObject = {
      ...objectToDelete,
      method: {
        name: objectToDelete.methodName,
      },
      creationDate: objectToDelete.date,
      id: objectToDelete.packageGeneralId,
    };
    request()
      .delete(`/api/package`, adjustedObject)
      .then(() => {
        const newData = packages.filter(
          (pkg) => pkg.packageGeneralId !== packageGeneralId
        );
        setPackages(newData);
        setPackageDetail(null);
      });
  };

  const handlePackageDetail = (packageGeneralId) => {
    let item = packages.find((p) => p.packageGeneralId === packageGeneralId);

    setPackageDetail(item);

    setIsDetailVisible(true);

    // const packageIndicator = getPackageIndicators(packageGeneralId);
    // setIndicatorsWithoutDetail(packageIndicator);
  };

  // useEffect(() => {
  //   getPackages();
  // }, []);

  const {
    currentPage,
    currentItems,
    // totalPages,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    packages,
    [
      "packageNumber",
      getRegionalFormat().dateFormat,
      "description",
      "methodId",
      "packageStatus",
    ],
    totalPages
  );

  useEffect(() => {
    getMethods();
  }, []);
  useEffect(() => {
    getPackages();
  }, [itemsPerPage, currentPage]);

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/Package/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageNumber}&status=${status}&page=${1}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setPackages(response.data.packageList);
      setCurrentPage(1);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setLoadingPackages(false);
    }
  };

  const arrayStatus = [
    { value: 5, name: t("SelectStatusOption") },
    { value: 1, name: t("AssemblingSpan", locale) },
    { value: 2, name: t("ReadyForLoadSpan", locale) },
  ];

  const getPrintPackage = (id) => {
    request()
      .get(`/api/package/info?packageId=${id}`)
      .then((res) => {
        showPrintAlert(res.data);
      });
  };

  const showPrintAlert = (packageDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel packageDetail={packageDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: t("Print"),
      customClass: {
        popup: "your-popup-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  return (
    <PageContainer
      categoryId={1}
      currentStep={1}
      backUrl={"/appsterilization"}
      category={t("SterilizationSpan")}
      subcategory={t("PackageSpan")}
      title={t("ManagePackages", locale)}
      newButton={isDetailVisible ? false : true}
      newButtonUrl={"/appcreatepackages"}
      progress={true}
      subtitle={isDetailVisible ? t("PackageDetail", locale) : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handlePackageNumber}
                placeholder={t("NumberSpan") || "Number"}
                label={t("NumberSpan") || "Number"}
                value={packageNumber}
                containerWidth="100%"
              />
              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                items={packages}
                property={"Name"}
                handleChange={handleSearch}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
              />

              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={method}
                containerWidth={"100%"}
              />
              <SelectQ
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={status}
                containerWidth={"100%"}
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true}>
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("packageNumber")}
                >
                  {t("NumberSpan", locale) || "Number"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("date")}
                >
                  {t("DateSpan") || "Date"}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("description")}
                >
                  {t("NameSpan", locale)}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("Method", locale)}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageStatus")}
                >
                  {t("Status", locale)}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingPackages ? (
                <div className="spinnerContainer">
                  <SpinnerQ />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((p) => (
                    <TableRowQ
                      key={p.packageGeneralId}
                      actions={true}
                      edit={p.packageStatus === 1}
                      print={p.packageStatus === 2}
                      detail={p.packageStatus === 1 || p.packageStatus === 2}
                      deleteRow={true}
                      onPrint={() => getPrintPackage(p.packageGeneralId)}
                      // onClick={() => handlePackageDetail(p.id)}
                      onEdit={() => onEdit(p.packageGeneralId)}
                      onDelete={() => onDelete(p.packageGeneralId)}
                      doubleClick={() =>
                        p.packageStatus === 1 && onEdit(p.packageGeneralId)
                      }
                      onDetail={() => handlePackageDetail(p.packageGeneralId)}
                    >
                      <TableRowCellQ date={true}>
                        <TruncatedText text={p.packageNumber} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ date={true}>
                        <TruncatedText
                          text={df(p.date, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.description} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[getStatusColor(p.packageStatus)]
                          }`}
                        ></span>
                        <TruncatedText
                          text={getStatusName(p.packageStatus)}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}

              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <PackageDetail
              packageId={packageDetail.packageGeneralId}
              indicatorsWithoutDetail={true}
              locale={locale}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
