import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "baseline",
      color: "#221e20",
      background: "transparent",
      textTransform: "capitalize",
      justifyContent: "center",
      "&::first-letter": {
        textTransform: "uppercase",
      },

      "& ul": {
        marginBottom: "0!important",
      },
    },
    
    selectIcon: {
      color: "#221e20",
    },
    toolbar: {
      minHeight: "38px",
    },
    selectRoot: {
      color: "#221e20",
    },
    menuItem: {
      color: "#221e20",
    },
  })
);
