import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import ScoreRPE from "../pages/ScoreRPE";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Dashboard,
    key: "ScoreRPE",
  };

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={null}>
        <NavBar
          MenuOptions={menu}
          currentPage={currentPage}
        >
          <ScoreRPE />
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
