import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import SterilizationHeader from "../../components/SterilizationHeader/SterilizationHeader";
import SterilizationTableAndDetail from "../../components/SterilizationTableAndDetail/SterilizationTableAndDetail";
import t from "../../utils/translation";
import { useContext } from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

const SavedPackages = () => {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState({});
  const [materials, setMaterials] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [methods, setMethods] = useState([]);

  const locale = useContext(LocalizationContext);

  const fetchData = async () => {
    try {
      setModalLoading(true);
      const packageResponse = await request().get("/api/package");
      const materialResponse = await request().get("/api/material");
      const indicatorResponse = await request().get("/api/product");
      const methodResponse = await request().get("/api/method");

      setMethods(methodResponse.data);
      setPackages(packageResponse.data.filter((d) => d.packageStatus === 1));
      setMaterials(materialResponse.data);
      setIndicators(indicatorResponse.data);
    } catch (error) {
      console.log(error);
    } finally {
      setModalLoading(false);
    }
  };

  const handlePackages = (data) => {
    setPackages(data);
  };

  const handlePackageDetail = (value) => {
    let item = packages.find((p) => p.id === value);
    setPackageDetail(value ? item : {});
    setSelectedPackage(value);
  };


  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "25px",
        height: "85vh",
      }}
    >
      <ModalLoading open={modalLoading} />
      <SterilizationHeader
        urlBack="/appcreatepackages"
        title={t("Sterilization", locale) || "Sterilization"}
        subTitle={t("SavedPackages", locale) || "Saved Packages"}
      />
      <SterilizationTableAndDetail
        handleData={handlePackages}
        data={packages}
        dataDetail={packageDetail}
        handleDataDetail={handlePackageDetail}
        ticket={false}
        selectedData={selectedPackage}
        methods={methods}
      />
    </div>
  );
};

export default SavedPackages;
