import axios from "axios";
import { getToken, logout } from "./sessionHandler";
import _ from "lodash";

const token = getToken();
const serverUrl = process.env.REACT_APP_SERVER_URL;

axios.interceptors.response.use(response=> response,
  error=>{
    console.log(error.response?.data);
    if(error.response?.data?.StatusCode === 500 && error.response?.data?.message.includes("Encrypt500")){
      localStorage.removeItem("__dist")
    }
     return Promise.reject(error)}
  );

const request = () => {
  const defaultOptions = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: token ? "bearer " + getToken() : null
    }
  };

  return {
    get: (url, options = {}) =>
      axios.get(serverUrl + url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(serverUrl + url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(serverUrl + url, data, { ...defaultOptions, ...options }),
    delete: (url, data, options = {}) =>
      axios.delete(serverUrl + url, { ...defaultOptions, ...options, data }),
    save: (url, data, idField, options = {}) =>
      (_.get(data, idField, null) === null)
        ? axios.post(serverUrl + url, data, { ...defaultOptions, ...options })
        : axios.put(serverUrl + url, data, { ...defaultOptions, ...options }),
    updateObject: (url, data, method = 'post', options = {}) =>
      (method === 'put')
          ? axios.put(serverUrl + url, data, { ...defaultOptions, ...options })
          : axios.post(serverUrl + url, data, { ...defaultOptions, ...options })
  };
};

export default request;
