import { ProductType } from "../dictionaries/productType";
import request from "./request";
import { getUserId } from "./sessionHandler";

export const exportToPdf = (indicatorType, filter, pagination) => {
  return new Promise((resolve, reject) => {
    switch (indicatorType) {
      case "ReadingBI": {
        filter.ProductType = ProductType.BiologicalSterilizer;
        request()
          .post(`/api/ReadingBI/GetReport`, {
            UserLoggedIn: getUserId(),
            filter,
            pagination,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error?.response?.data?.message);
          });
        break;
      }
      case "ReadingBIDisinfection": {
        filter.ProductType = ProductType.BiologicalDisinfection;
        request()
          .post(`/api/ReadingBI/GetReport`, {
            UserLoggedIn: getUserId(),
            filter,
            pagination,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error?.response?.data?.message);
          });
        break;
      }
      case "ReadingPRO": {
        request()
          .post(`/api/ReadingPRO/GetReport`, {
            UserLoggedIn: getUserId(),
            filter,
            pagination,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error?.response?.data?.message);
          });
        break;
      }
      case "readingchesterilizer": {
        request()
          .post(`/api/ReadingCHESterilizer/GetReport`, {
            UserLoggedIn: getUserId(),
            filter,
            pagination,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error?.response?.data?.message);
          });
        break;
      }
      case "readingchewasher": {
        request()
          .post(`/api/ReadingCHEWasher/GetReport`, {
            UserLoggedIn: getUserId(),
            filter,
            pagination,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error?.response?.data?.message);
          });
        break;
      }
      default:
        break;
    }
  });
};
