import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    root: {
        maxHeight: window.screen.height - 10,
        width: '100%',
        "& .MuiInputBase-root": {
            color: 'white'
        },
        textAlign: 'center'
      },
    loginCard: {
        display: 'inline-grid',
        marginTop: 50,
        root: {
          backgroundColor: 'red'
        }
      },
    
      title:{
        color: theme.palette.terragene.lightThird,
        textTransform: 'uppercase'
      },  
      
      subTitle:{
        color: theme.palette.terragene.lightThird,
        fontSize: "1rem",
        lineHeight: "1.33",
        letterSpacing: "0em",
        fontWeight: 400,
        alignSelf: "center",
        marginBottom: 8,
        marginLeft: 10
      },
    
      loginCardSignUp: {
        display: 'inline-grid',
      },
    
      loginForm: {
        display: "flex",
        flexDirection: "column"
      },
    
      "@media (min-width: 448px)": {
        loginCard: {
          width: 400
        },
        loginCardSignUp: {
          width: 800
        }
      },
    
      loading: {
        margin: "0!important",
        position: "absolute!important",
        top: 0,
        left: 0,
        right: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
      },
}))