import React, { useEffect, useState } from "react";
import DDColumn from "../DragAndDrop/DDColumn/DDColumn.jsx";
import DDElement from "../DragAndDrop/DDRow/DDRow.jsx";

import useWindowSize from "../../utils/Hooks/useWindowSize.js";
import DDRow from "../DragAndDrop/DDRow/DDRow.jsx";
import imgPackage from "../../assets/images/PACKAGE IN A LOAD.svg";

import styles from "./DragAndDropWasherLoading.module.css";

const DragAndDropWasherLoading = ({
  data,
  selectedFilteredData,
  selectedData,
  handleSelectedData,
  filter1,
  filter2,
  leftColumnName,
  rightColumnName,
}) => {
  const windowSize = useWindowSize();

  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.packageWashingGeneralId);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.packageWashingGeneralId);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    const item = data.find((item) => item.packageWashingGeneralId == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find(
        (material) =>
          material.packageWashingGeneralId === item.packageWashingGeneralId
      );

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };

  const moveItem = (itemID, columnID) => {
    const item = data.find((item) => item.packageWashingGeneralId == itemID);

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find(
        (material) =>
          material.packageWashingGeneralId === item.packageWashingGeneralId
      );

      if (!isItemExists) {
        newState = [...newState, item];
      }
      handleSelectedData(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");

    const newState = selectedData.filter(
      (material) => material.packageWashingGeneralId != itemID
    );
    handleSelectedData(newState);
  };

  const onDelete = (id) => {
    const newState = selectedData.filter(
      (material) => material.packageWashingGeneralId != id
    );

    handleSelectedData(newState);
  };

  const packageExist = (packageWashingGeneralId) => {
    return selectedData.find(
      (m) => m.packageWashingGeneralId === packageWashingGeneralId
    );
  };

  useEffect(() => {
    if (selectedData.length > 0 && selectedData.length == 0)
      handleSelectedData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    handleSelectedData(selectedData);
  }, [selectedData]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.columnContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.paper}>
              <p className={styles.title}>{leftColumnName}</p>
              {filter1}
              <DDColumn
                packageWashingGeneralId={"Drag"}
                dragOver={draggingOver}
                drop={onDropDelete}
              >
                {data &&
                  data
                    .filter((d) => !packageExist(d.packageWashingGeneralId))
                    .map((p) => (
                      <DDRow
                        key={p.packageWashingGeneralId}
                        obj={p}
                        name={`${
                          p.packageWashingNumber
                            ? p.packageWashingNumber
                            : p.packageWashing?.packageNumber
                        } - ${
                          p.description
                            ? p.description
                            : p.packageWashing?.description
                        }`}
                        dragStart={startDragColumn}
                        doubleClick={doubleClick}
                        columnId={true}
                        load={true}
                        img={imgPackage}
                        category={"washing"}
                      />
                    ))}
              </DDColumn>
            </div>
          </div>
          <div className={styles.quantityPaper}></div>
          <div className={styles.rightColumn}>
            <div className={styles.paper}>
              <p className={styles.title}>{rightColumnName}</p>

              {filter2}

              <DDColumn
                packageWashingGeneralId={"Drop"}
                dragOver={draggingOver}
                drop={onDrop}
                autoScroll={true}
              >
                {selectedFilteredData.map((p) => (
                  <DDRow
                    key={p.packageWashingGeneralId}
                    obj={p}
                    name={`${
                      p.packageWashingNumber
                        ? p.packageWashingNumber
                        : p.packageWashing?.packageNumber
                    } - ${
                      p.description
                        ? p.description
                        : p.packageWashing?.description
                    }`}
                    dragStart={startDrag}
                    onDelete={onDelete}
                    doubleClick={onDelete}
                    columnId={null}
                    load={true}
                    img={imgPackage}
                    category={"washing"}
                  />
                ))}
              </DDColumn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DragAndDropWasherLoading;
