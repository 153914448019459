import React, { useState, useContext, useEffect } from "react";
import request from "../../../utils/request";
import LocalizationContext from "../../../utils/LocalizationContext";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./UserResetPasswordStyles";
import { Input } from "@material-ui/core";
import Button2 from "../../Button2";

import ViewPasswordButton from "./ViewPasswordButton";
import t from "../../../utils/translation";
import Redirect from "../../../utils/Redirect";
import ButtonQ from "../../ButtonQ";
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";
import isoLogoQ from "../../../assets/images/ISOLOGO-BIONOVAQ.svg";

export default function UserResetPassword({ params }) {
  let { token, email, distId } = params;

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [error, setError] = useState({ message: null, type: null });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const locale = useContext(LocalizationContext);
  const classes = useStyles();
  const theme = JSON.parse(localStorage.getItem("__dist"))?.themeFileName;

  // const iconStyle={
  //   position:'relative',
  //   display:'block',
  //   // bottom:  error.type === "newPassword" || error.type === "repeatPassword" ? '65px' : '40px',
  //   bottom: error.type === "newPassword" ? (error.newPassword ? '75px' : '55px') :
  //       error.type === "repeatPassword" ? (error.repeatPassword ? '70px' : '55px') :
  //       '40px',
  //   // bottom: error.type === "newPassword" || error.type === "repeatPassword" ? '65px' : (error.type === "" ? '40px' : '40px'),
  //   color: 'black',
  //   cursor:'pointer',
  //   height:'20px',
  //   left:'470%'
  // }

  const iconStyles = {
    iconStyle1: {
      position: "relative",
      display: "block",
      bottom: error.type === "newPassword" ? "65px" : "40px",
      color: "black",
      cursor: "pointer",
      height: "20px",
      left: "470%",
    },
    iconStyle2: {
      position: "relative",
      display: "block",
      bottom: error.type === "repeatPassword" ? "65px" : "40px",
      color: "black",
      cursor: "pointer",
      height: "20px",
      left: "470%",
    },
  };

  // const iconStyle2={
  //   // position:'relative',
  //   // display:'block',
  //   // bottom: validationMessage && validationMessage.length > 0 ? '65px' : '40px',
  //   // color: 'black',
  //   // cursor:'pointer',
  //   // height:'20px',
  //   // left:'470%'

  //   position:'relative',
  //   display:'block',
  //   bottom:'3.5rem',
  //   left:'85%',
  //   color: 'black',
  //   height:'20px',
  //   cursor:'pointer',
  // }

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  const checkRequiredFields = () => {
    // Validación de campo newPassword
    if (newPassword.length === 0) {
      setError({ type: "newPassword", message: "This field is required" });
      setValidationMessage("This field is required");
      return false;
    } else if (!validatePassword(newPassword)) {
      setError({
        type: "newPassword",
        message: "8+ characters,1 uppercase letter,1 number.",
      });
      setValidationMessage("8+ characters,1 uppercase letter,1 number.");
      return false;
    }

    // Validación de campo repeatNewPassword
    if (repeatNewPassword.length === 0) {
      setError({ type: "repeatPassword", message: "This field is required" });
      setValidationMessage("This field is required");
      return false;
    } else if (repeatNewPassword !== newPassword) {
      setError({
        type: "repeatPassword",
        message: "The passwords do not match",
      });
      setValidationMessage("The passwords do not match");
      return false;
    }

    // if (newPassword.length === 0) {
    //   setError({ type: "newPassword", message: "This field is required" });
    //   setValidationMessage("This field is required" );
    //   return false;
    // }

    // if (repeatNewPassword.length === 0) {
    //   setError({ type: "repeatPassword", message: "This field is required" });
    //   setValidationMessage("This field is required" );
    //   return false;
    // }

    // if (!validatePassword(newPassword)) {
    //   setError({
    //     type: 'newPassword',
    //     message: '8+ characters,1 uppercase letter,1 number.',
    //   });
    //   setValidationMessage('8+ characters,1 uppercase letter,1 number.');
    //   return false;
    // }

    // if (repeatNewPassword !== newPassword) {
    //   setError({
    //     type: "repeatPassword",
    //     message: "The passwords do not match."
    //   });
    //   setValidationMessage("The passwords do not match.");
    //   return false;
    // }

    return true;
  };

  useEffect(() => {
    if (newPassword || repeatNewPassword) {
      setValidationMessage("");
    }
  }, [newPassword, repeatNewPassword]);

  const resetPasswordSubmit = (e) => {
    document.activeElement.blur();
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    const user = {
      email: email,
      distId: distId,
      token: token,
      password: newPassword,
    };

    request()
      .post(`/api/login/password-recovery`, user)
      .then((response) => {
        setSuccess(true);
        setLoading(false);
        setError({ type: "success", message: response.data });
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }
        console.log(error.response.data.message);
        setError({ type: "error", message: error.response.data.message });
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        <div className={"alert alert-info"}>
          {
            "Please set up your account password. Kindly note that you will not be able to log in to BionovaQ in the next page, until the onboarding process with your sales executive is complete."
          }
        </div>
        {/* <div>
      {theme === "aspTheme" ? (
        <img src={ "/../../images/aspcloudwhite.png"} alt="" className={classes.logoBionova}/>
        ) : (
        <img src={"/../../images/ISOLOGO-BIONOVAQ.svg"} alt="" className={classes.logoBionova}/>
      )}
      </div>
      <h1 className={classes.loginText}> {locale.form.submit.resetPassword}</h1> */}
        <div className={classes.containerHeader} style={{ marginTop: "50px" }}>
          <div>
            <img src={isoLogoQ} className={classes.smallIsoLogo} alt="title" />
          </div>
          <div className={classes.container}>
            <p className={classes.title}>
              {isoLogoQ && `| ${locale.form.submit.resetPassword}`}
            </p>
          </div>
        </div>
        {(error.type === "error" || error.type === "success") && (
          <div
            className={` ${
              error.type === "error" ? "alert-danger" : " alert alert-primary"
            }`}
            role="alert"
          >
            {error.message}
          </div>
        )}
        {!success && (
          <form
            className={classes.loginForm}
            onSubmit={(e) => resetPasswordSubmit(e)}
          >
            {!success && (
              <InputQ
                claro={true}
                value={email}
                type={"text"}
                disabled={true}
                containerWidth={"100%"}
                containerMargin={"20px"}
              />
            )}

            {!success && (
              <ViewPasswordButton
                locale={locale}
                setNewPassword={setNewPassword}
                newPassword={newPassword}
                setError={setError}
                error={error}
                loading={loading}
                style={iconStyles}
                success={success}
                setRepeatNewPassword={setRepeatNewPassword}
                repeatNewPassword={repeatNewPassword}
                t={t}
                validationMessage={validationMessage}
              />
            )}

            {/* {!success && (
      <ViewPasswordButton2
      locale={locale}
      setNewPassword={setNewPassword}
      newPassword={newPassword}
      setError={setError}
      error={error}
      loading={loading}
      style={iconStyle2}
      success={success}
      setRepeatNewPassword={setRepeatNewPassword}
      repeatNewPassword={repeatNewPassword}
      t={t}
      validationMessage={validationMessage}
      /> 
      )}   */}

            {/* <Button2
      type={"submit"}
      disabled={loading}
      onClick={() =>
        error.type === "success"
          ? window.location.replace("/login")
          : null
      }
      caption={
        error.type === "success"
          ? locale.form.submit.login
          : locale.eulaForm.acceptButton
      }
     /> */}

            {!success && (
              <ButtonQ
                type={"submit"}
                disabled={loading}
                caption={locale.eulaForm.acceptButton}
              />
            )}
          </form>
        )}

        {success && (
          <form className={classes.loginForm}>
            <ButtonQ
              type={"submit"}
              disabled={loading}
              caption={locale.form.submit.login}
              // onClick={() => window.location.replace("/login")}
              onClick={() =>
                Redirect({
                  redirect: true,
                  path: "/login",
                })
              }
            />
          </form>
        )}

        <div>
          {theme === "aspTheme" ? null : (
            <img
              src={"/../../images/logotipoverde.png"}
              alt=""
              className={classes.logoTerragene}
            />
          )}
        </div>
      </div>
    </div>
  );
}
