import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    textNotFound: {
        textAlign: 'center',
        fontSize: '0.9em'
    },
    downloadSuggestion: {
        textAlign: 'center',
        fontSize: '1.1em',
        margin: '20px auto'
    },
    cardPrueba: {
        padding: '15px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },
    headerPopUpImage: {
        display: "flex",
        padding: "10px 24px",
        justifyContent: "flex-end"
    },
    // cardPlayStore: {
    //     margin: "10px auto",
    //     width: "45%",
    //     backgroundColor: "#000000",
    //     float: "left",
    //     display: "block",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     margin: "0 0 0 15px"
    // },
    // cardAppStore: {
    //     margin: "10px auto",
    //     width: "45%",
    //     backgroundColor: "#000000",
    //     float: "right",
    //     display: "block",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     margin: "0 15px 0 0"
    // }
}));