import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    screenOptions: {
        display: 'flex',
        justifyContent: 'space-between',

        '& div': {
            width: '48%'
        }
    },

    tabsRoot: {
        display: 'flex',
        height: 40,

        '&>div:nth-child(1)': {
            borderRadius: '6px 0 0 0'
        },

        '&>div:nth-last-child(1)': {
            borderRadius: '0 6px 0 0'
        }
    },

    tabItem: {
        boxShadow: '0 0.125rem 0.25rem rgba(0,0,0,.075) inset',
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft:"25px",

        '&$active': {
            boxShadow: '0 2px 3px rgba(0,0,0,.25)',
            backgroundColor: theme.palette.terragene.darkSecondary,
            color: 'white'
        }
    },

    active: {}
}))