import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import AppEula from "../pages/AppEula";

const locale = {
	form: {
		input: {
			email: 'Email',
			username: 'User Name',
			password: 'Password',
			newPassword: 'New Password',
			repeatNewPassword: 'Repeat New Password',
			name: 'Name',
			lastName: 'Last Name',
			company: 'Company',
			sectorName: 'Sector'
		},
		submit: {
			login: 'Login',
			changePassword: 'Change Password',
			resetPassword: 'Reset Password',
		}
	},
	header: {
		signup: 'New Company - Sign up',
		login: 'Login',
		forgotPassword: 'Reset Password',
		changePassword: 'Change Password',
	},
	link: {
		forgotPassword: 'Forgot Your Password?',
		signup: "New Company"
	},
	companyForm: {
		name: 'Company name',
		country: 'Country',
		city: 'City',
		address: 'Address',
		confirm: 'Confirm',
		eulaText: "I've read and accept the terms and conditions",
		seeEulaText: "See terms and conditions",
		province: 'State / Province / Region',
		postalCode: 'Postal Code',
		phone: 'Phone',
		companyRepresentative: 'Contact / Representative',
		language: 'Company Language'
	},
	eulaForm: {
		acceptButton: 'Accept',
		cancelButton: 'Cancel',
		title: 'Terms and conditions'
	}
}

export default () => {
  return (
    <LocalizationContext.Provider value={locale}>
      <AppEula />
    </LocalizationContext.Provider>
  );
};
