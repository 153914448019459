import React, { useState, useEffect } from "react";

import useStyles from "./MenuConfigurationStyles";
import request from "../../utils/request";
import { flatten } from "flat";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { verifyForm, verifyColumns, verifyHeaders, verifyFieldsMap, verifyDetailFields } from '../../utils/languagePermittedHandler'
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import Select2 from "../../components/Select2";

export default function MenuConfiguration() {
  const classes = useStyles();

  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [menuTypes, setMenuTypes] = useState([]);
  const [selectedMenuType, setSelectedMenuType] = useState("");
  const [menuGroups, setMenuGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const [languages, setLanguages] = useState([])
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState();
  const [headers, setHeaders] = useState();
  const [fieldForm, setFieldForm] = useState();
  const [fieldsMap, setFieldMap] = useState([]);
  const [detailFields, setDetailFields] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchData() {
      Promise.all([
        request().get(`/api/menu`),
        request().get(`/api/Language`),
      ])
        .then(([response, languages]) => {
          setMenuTypes(
            response.data
              .filter((x) => x.menuNameDescription !== "System")
              .reduce((menuTypes, item) => {
                menuTypes.push([item.id, item.toString]);
                return menuTypes;
              }, [])
          );
          setLanguages(languages.data)
          setLoadingContent(false);
        })
    }
    fetchData()
  }, []);

  const updateData = React.useCallback(() => {
    const type = selectedGroup === "" ? "group" : "item"
    const headers = [type + " ID"]
    const columns = [type + "Name"]
    const visibleFields = [
      { label: "Name", name: "toString" }
    ]
    setColumns(verifyColumns(columns, type, languages))
    setHeaders(verifyHeaders(headers, languages))
    setFieldForm(verifyForm(languages))
    setFieldMap(verifyFieldsMap(type, languages))
    setDetailFields(verifyDetailFields(visibleFields, type, languages))
    if (selectedGroup !== "") {
      setLoadingData(true);
      request()
        .get(`/api/item?GroupId=${selectedGroup}`)
        .then((response) => {
          setData(response.data.map((x) => flatten(x)));
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        });
    } else if (selectedMenuType) {
      setLoadingData(true);
      request()
        .get(`/api/group?MenuId=${selectedMenuType}`)
        .then((response) => {
          setData(response.data.map((x) => flatten(x)));
          setMenuGroups(
            response.data.reduce((menuGroups, group) => {
              menuGroups.push([
                group.id,
                group.groupTranslations[0].translation,
              ]);
              return menuGroups;
            }, [])
          );
          setSelectedGroup("");
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        });
    }
  }, [selectedMenuType, selectedGroup]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  const resetValue = (row) => {
    if(row.itemName)
      request().get(`/api/Item/resetMenuItem?item=${row.itemName}`).then(() => {
        updateData();
      }).catch(err => {
        setError(err?.response?.data?.message)
      })
    if(row.groupName)
      request().get(`/api/Group/resetMenuGroup?group=${row.groupName}`).then(() => {
        updateData();
      }).catch(err => {
        setError(err?.response?.data?.message)
      })
  }

  if (loadingContent) return <PageContentLoading />;

  return (
    <div style={{ margin: 30, display: "contents" }}>
      <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
      <div className={classes.selectBlock}>
        <Select2
          label="Menu Type"
          options={menuTypes}
          emptyOptionText={"Choose a Menu Type"}
          variant={"outlined"}
          value={selectedMenuType}
          onChange={(val) => setSelectedMenuType(val)}
        />
        <Select2
          label="Group"
          options={menuGroups}
          emptyOptionText={"Choose a Group"}
          variant={"outlined"}
          value={selectedGroup}
          onChange={(val) => setSelectedGroup(val)}
          nullable={true}
        />
      </div>
      <CRUD
        tableActions={[
          {
              icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
              tooltip: "Reset",
              action: resetValue,
          }
        ]}
        newOption={false}
        deleteOption={false}
        data={data}
        loadingData={loadingData}
        updateData={updateData}
        title={"Menu Configuration"}
        url={selectedGroup === "" ? "/api/group/save" : "/api/item/save"}
        method={"post"}
        columns={columns}
        headers={headers}
        idField={"id"}
        fieldsMap={fieldsMap}
        formFields={fieldForm}
        detailFields={detailFields}
      />
    </div>
  );
}
