import React, { useState } from "react";
import df from "../../../utils/dateFormater";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import TableRowCellQ from "../../TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableRowQ from "../../TableBionovaQ/TableRowQ/TableRowQ";
import minutesToHourFormat from "../../../utils/minutesToHours";

const TableRowItemIS = ({ rbi, onLink }) => {
  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 1:
        return "#E2A60A";
      case 3:
        return "#00b189";
      case 4:
        return "#0E579A";
      default:
        return "";
    }
  };

  const getResultBackgroundColor = (result) => {
    switch (result) {
      case 1:
        return "#d81111";
      case 2:
        return "#00b189";
      case 3:
        return "#E2A60A";
      default:
        return "";
    }
  };
  const getResultText = (result) => {
    switch (result) {
      case 1:
        return "Positive";
      case 2:
        return "Negative";
      case 3:
        return "Canceled";
      default:
        return "";
    }
  };

  return (
    <TableRowQ key={rbi.readingBiId} onClick={() => onLink(rbi.readingBiId)}>
      <TableRowCellQ>
        <span
          style={{
            backgroundColor: getStatusBackgroundColor(rbi.statusEnum),
            padding: "5px 0",
            width: "100%",
            borderRadius: "5px",
            color: "white",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {rbi.status}
        </span>
      </TableRowCellQ>
      <TableRowCellQ>{rbi.readingNumber}</TableRowCellQ>
      <TableRowCellQ>
        <span
          style={{
            backgroundColor: getResultBackgroundColor(rbi.resultEnum),
            padding: "5px 0",
            width: "100%",
            borderRadius: "5px",
            color: "white",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {rbi.result}
        </span>
      </TableRowCellQ>
      <TableRowCellQ>{df(rbi.startedDate, "dateTime")}</TableRowCellQ>
      <TableRowCellQ>{df(rbi.resultDate, "dateTime")}</TableRowCellQ>
      <TableRowCellQ>{rbi.averageTemperatureFormat}</TableRowCellQ>
      <TableRowCellQ>{minutesToHourFormat(rbi.incubationTime)}</TableRowCellQ>
      <TableRowCellQ>{rbi.programName}</TableRowCellQ>
    </TableRowQ>
  );
};

export default TableRowItemIS;
