import React, { useEffect, useReducer } from "react";
import useStyles from "./LoginStyles";
import Paper from "../../components/Paper";
import LoginForm from "../../components/Form/Login";
import ForgotPasswordForm from "../../components/Form/ForgotPassword";
import ResetPassword from "../../components/Form/ResetPassword";
import SignUp from "../../components/Form/Signup/SignUp";
import Eula from "../../components/Form/Eula/Eula";
import { useTheme } from '@material-ui/core/styles';
import request from "../../utils/request";
import reducer from './LoginReducer';
import {OpenModeLogin} from '../../dictionaries/openModeLogin'

const initialState = {
  loginForm: "login",
  loadingCountries: true,
  eulaAccepted: false,
  countries: [],
  languages: [],
  email: "",
  id: null
};

export default function Login({params}) {
  if(params){
    if(params.mode){
      if(params.mode == OpenModeLogin.ForgotPassword)
        initialState.loginForm = "forgotPassword"
      if(params.mode == OpenModeLogin.SignUp)
        initialState.loginForm = "signup"
    }
    if(params.email){
      initialState.email = params.email
    }
  }
  const theme = useTheme();
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loginForm, email, id } = state;

  useEffect(() => {
    Promise.all([
      request().get(`/api/Country/`),
      request().get(`/api/Language/GetLanguages`)
    ])
    .then(([countries, languages]) => {
        dispatch({ type: 'setData', payload: { Countries: countries.data, Languages: languages.data}})
      })
  }, [])

  const renderLoginForm = () => {
    if (loginForm === "login") {
      return (<>
        {
          state.message &&
          <div className={"alert alert-success"}>{state.message}</div>
        }
        <LoginForm  classes={classes} dispatch={dispatch} email={email} images={theme.palette.images} />
      </>
      );
    }

    if (loginForm === "forgotPassword") {
      return (
        <ForgotPasswordForm
          classes={classes}
          dispatch={dispatch}
          state={state}
          images={theme.palette.images}
        />
      );
    }

    if (loginForm === "resetPassword") {
      return (
        <ResetPassword
          classes={classes}
          dispatch={dispatch}
          email={email}
          id={id}
          images={theme.palette.images}
        />
      );
    }

    if (loginForm === "signup") {
      return (
        <SignUp
          state={state}
          classes={classes}
          dispatch={dispatch}
          email={email}
          id={id}
          images={theme.palette.images}
        />
      );
    }

    if (loginForm === "eulaForm") {
      return (
        <Eula
          dispatch={dispatch}
          regionId={state.company.Country.regionId}
          classes={classes}
        />
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={state.loginForm === 'signup' || state.loginForm === 'eulaForm' ? classes.loginCardSignUp : classes.loginCard}>
        <Paper>{renderLoginForm()}</Paper>
      </div>
    </div>
  );
}
