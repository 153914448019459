import React, { useState, useEffect } from "react";
import request from "../../utils/request";

import CRUD from "../../components/CRUD";
import { getDist, getSession } from "../../utils/sessionHandler";
import _ from "lodash";
import useStyles from "../Company/CompanyStyles";
import { flatten, unflatten } from "flat";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Progress from "../../components/Progress";

export default function Distributor() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [changeAdminSuccessMsg, setChangeAdminSuccessMsg] = useState('');

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    
    setLoadingData(true);
    request()
      .get(`/api/distributor`)
      .then((response) => {
        const flattenDist = response.data.map(x =>
          _.mapKeys(flatten(x), (value, key) => {
            return key.match(/^\w+\.\w+$/) ? key : "distributor." + key;
          })
        )
        setData(flattenDist);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleResetPassword = () => {
    setLoadingData(true);
    request()
      .post("/api/appuser/resetpassword", {
        AppUser: unflatten(userToResetPassword).administrator,
        UserLogged: getSession(),
        DistributorId: userToResetPassword["distributor.encryptedId"]
      })
      .then(response => {
        setOpenResetPassword(false);
        setLoadingData(false);
        setChangeAdminSuccessMsg(response.data)
        setTimeout(() => {
          setChangeAdminSuccessMsg('')  
        }, 7000);
      })
      .catch(error => {
        console.log(error.response);
        setLoadingData(false);
      });
  }

  return (
    <>
    {changeAdminSuccessMsg && <div className="alert alert-success" role="alert" style={{marginTop:20}}>{changeAdminSuccessMsg}</div> }
    <CRUD
      data={data}
      loadingData={loadingData}
      url={"/api/distributor"}
      updateData={updateData}
      title={"Distributor"}
      columns={[
        "distributor.name",
        "distributor.domain",
        "administrator.email",
        "administrator.toString",
        "distributor.themeFileName"
      ]}
      headers={["Name", "Domain", "Administrator Email", "Administrator", "Theme File Name"]}
      idField={"distributor.id"}
      fieldsMap={[
        "distributor.name",
        "distributor.domain",
        "administrator.name",
        "administrator.lastName",
        "administrator.email",
        "distributor.themeFileName"
      ]}
      formFields={[
        { label: "Distributor Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Domain", class: "col-12 col-md-6 px-0", required: true },
        { label: "Administrator Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Administrator Last Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Administrator Mail", class: "col-12 col-md-6 px-0", newOnly: true, required: true },
        { label: "Theme File Name", class: "col-12 col-md-6 px-0", required: true }
      ]}
      detailFields={[
        { label: "Distributor Name", name: "distributor.name" },
        { label: "Domain", name: "distributor.domain" },
        { label: "Administrator Name", name: "administrator.toString" },
        { label: "Administrator Mail", name: "administrator.email" },
        { label: "Theme File Name", name: "distributor.themeFileName" },
        { label: "Own Distributor", name: "distributor.ownDistributor", type: '' }
      ]}
      tableActions={[
        {
          action: val => {
            setOpenResetPassword(true);
            setUserToResetPassword(val);
          },
          icon:
            "M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z",
          tooltip: "Reset Password"
        }
      ]}
      baseObject={
        {
          distributor: {
            id: null,
            name: "",
            domain: ""
          },
          administrator: {
            name: "",
            lastName: "",
            email: "",
            password: ""
          },
          UserLogged: getSession()
        }}
    />
    {openResetPassword && (
      <Modal
        closeModal={() => setOpenResetPassword(false)}
        header={
          <React.Fragment>
            <div className={classes.header}>
              <span className={classes.headerText}>Reset password</span>
            </div>
            <Progress active={loadingData} />
          </React.Fragment>
        }
        footer={
          <div className={classes.footerAction}>
            <Button
              caption={"Confirm"}
              variant={"text"}
              onClick={handleResetPassword}
              disabled={loadingData}
            />
            <Button
              caption={"Cancel"}
              onClick={() => setOpenResetPassword(false)}
              variant={"text"}
              color={"error"}
              disabled={loadingData}
            />
          </div>
        }
      >
        <div className={"container-fluid"}>
          <div className={"row"}>
            <div className={"col-12"} style={{ textAlign: "center" }}>
              Are you sure you want to reset the password for the distributor{" "}
              <b>
                {userToResetPassword["distributor.name"]}
              </b>
              ?
            </div>
            <div className={"col-12"} style={{ textAlign: "center" }}>
              User{" "}
              <b>
                {userToResetPassword["administrator.name"]}{" "}
                {userToResetPassword["administrator.lastName"]}
              </b>
            </div>
          </div>
        </div>
      </Modal>
    )}
    </>
  );
}
