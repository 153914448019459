export default function minutesToHourFormat(minutes) {
  if (isNaN(minutes) || minutes < 0) {
    return "0:00hrs";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hourFormat = `${hours}:${
    remainingMinutes < 10 ? "0" : ""
  }${remainingMinutes}hrs`;

  return hourFormat;
}
