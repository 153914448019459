import React, { useEffect, useState, useRef } from "react";
import styles from "./InputDateQ.module.css";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import df from "../../../utils/dateFormater";
import { CiCalendar } from "react-icons/ci";
import Calendar from "../Calendar/Calendar"; 
import t from "../../../utils/translation";

const InputDateQ = ({
  defaultValue,
  onChange,
  label,
  validate,
  required,
  errorMessage,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    defaultValue ? new Date(defaultValue) : null
  );
  const [labelColor, setLabelColor] = useState("#221e20");
  const [showMessage, setShowMessage] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  const handleDateChange = (date) => {
    const newDate = new Date(date);
    setSelectedDate(newDate);
    if (onChange) {
      onChange(newDate);
    }
    setIsCalendarOpen(false);
  };

  useEffect(() => {
    setShowMessage(validate && required && selectedDate === null);
  }, [validate, selectedDate]);

  useEffect(() => {
    setLabelColor(showMessage ? "red" : "#555");
  }, [showMessage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className={styles.datePickerContainer}>
      {label && (
        <label className={styles.label} style={{ color: labelColor }}>
          {label}
          {required && <span className={styles.required}>*</span>}
          {showMessage && (
            <span className={styles.errorMessage}> ({errorMessage})</span>
          )}
        </label>
      )}
      <div className={styles.customInput} onClick={toggleCalendar}>
        <div className={styles.displayField}>
          <CiCalendar className={styles.calendarIcon} />
          <span>
            {selectedDate ? df(selectedDate, "date") : t("SelectDateSpan")}
          </span>
        </div>
      </div>
      {isCalendarOpen && (
        <div className={styles.calendarContainer} ref={calendarRef}>
          <Calendar onSelectDate={handleDateChange} actualDate={selectedDate} />
        </div>
      )}
    </div>
  );
};

export default InputDateQ;
