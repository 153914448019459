import React, { useState, useContext, useEffect } from "react";
import request from "../../../utils/request.js";
import LocalizationContext from "../../../utils/LocalizationContext.js";
import PageContainer from "../../../components/PageContainer/PageContainer.jsx";
import WashDetail from "./WashDetail/WashDetail.jsx";
import WashBoard from "./WashCycleBoard/WashCycleBoard.jsx";
import df from "../../../utils/dateFormater";
import { getRegionalFormat } from "../../../utils/sessionHandler.js";
import t from "../../../utils/translation.js";
import WashFinishModal from "./WashFinishModal/WashFinishModal.jsx";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";

const ManageWashCycles = () => {
  const [load, setLoadDetail] = useState(null);
  const [loads, setLoads] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [toStartLoads, setToStartLoads] = useState([]);
  const [inProgressLoads, setInProgressLoads] = useState([]);
  const [finishedLoads, setFinishedLoads] = useState([]);
  const [selectedWasher, setSelectedWasher] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    cicleNumber: "",
    cicleObservations: "",
    cicleTime: "",
    usedProgram: "",
    bookNumber: "N/A",
    pageNumber: "N/A",
    cicleStartDate: null,
    img: "",
    chargeStatus: "",
  });
  const [cycleStartDate, setCycleStartDate] = useState(null);
  const [loadID, setLoadID] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [reloadDetail, setRealoadDetail] = useState(false);
  const [loadingFinishModal, setLoadingFinishModal] = useState(false);

  const locale = useContext(LocalizationContext);

  const handleChangeDate = (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        const today = new Date();
        setDateTo(today);
        getLoads(selectedWasher, value, today);
      } else {
        getLoads(selectedWasher, value, dateTo);
      }
    } else if (type === "dateTo") {
      setDateTo(value);
      getLoads(selectedWasher, dateFrom, value);
    } else if (type === "clear") {
      setDateFrom("");
      setDateTo("");
      getLoads(selectedWasher, "", "");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    setFormData({});
  };

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleLoad = (chargeWashingId) => {
    setLoadDetail(
      loads.find((load) => load.chargeWashingId == chargeWashingId)
    );
  };

  const getLoads = async (washer = "", from = new Date(), to = new Date()) => {
    setModalLoading(true);
    try {
      await request()
        .get(
          `/api/Chargewashing/list?pageSize=1000000&Status=6&washer=${washer}&dateFrom=${parseFiltersDate(
            df(from, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(to, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((response) => {
          setLoads(response.data.chargeWashingListatorList);
          setToStartLoads(
            response.data.chargeWashingListatorList.filter(
              (l) => l.chargeStatus == 2
            )
          );
          setInProgressLoads(
            response.data.chargeWashingListatorList.filter(
              (l) => l.chargeStatus == 3
            )
          );
          setFinishedLoads(
            response.data.chargeWashingListatorList.filter(
              (l) => l.chargeStatus == 4
            )
          );
        });
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDrop = async (value, status, e, finishObject) => {
    e.preventDefault();

    try {
      const { data: infoLoad } = await request().get(
        `/api/chargeWashing/chargeWashingDto?chargeWashingId=${value}`
      );

      let newIndicators = infoLoad.indicatorsWashing.map((i) => ({
        IndicatorId: i.indicatorWashingId,
        quantity: 1,
        serial: i.serial || null,
        lot: i.lot || null,
        modelAreasId: i.modelAreasId,
      }));

      let newPackages = infoLoad.packagesWashing.map((p) => ({
        PackageId: p.packageWashingId,
      }));

      const requestInProgressData = {
        chargeWashingId: infoLoad.chargeWashingId,
        washerId: infoLoad.washerId,
        description: infoLoad.description,
        chargeStatus: 3,
        indicatorsWashing: newIndicators,
        packagesWashing: newPackages,
        ...finishObject,
        cicleFinishDate: null,
      };

      const requestFinishedData = {
        chargeWashingId: infoLoad.chargeWashingId,
        washerId: infoLoad.washerId,
        description: infoLoad.description,
        chargeStatus: 4,
        indicatorsWashing: newIndicators,
        packagesWashing: newPackages,
        ...finishObject,
      };

      await request()
        .put(
          "/api/chargeWashing/modifyChargeWashing",

          status === 4 ? requestFinishedData : requestInProgressData
        )
        .then(() => {
          status === 4 && closeModal();
          getLoads(selectedWasher, dateFrom, dateTo);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const onConfirmEdit = (id) => {
    setLoadID(id);
    setRealoadDetail(true);
  };

  useEffect(() => {
    getLoads();
  }, []);

  return (
    <PageContainer
      categoryId={2}
      currentStep={6}
      category={t("WashingSpan")}
      subcategory={t("WashCycleSpan")}
      title={t("ManageWasherLoadingSpan")}
      menu={false}
      backUrl={"/appwashingmenu"}
      scan={true}
      clickBack={load ? true : null}
      onClickBack={() => {
        setLoadDetail(null);
      }}
      progress={true}
    >
      <>
        {isModalOpen && (
          <WashFinishModal
            isModalOpen={isModalOpen}
            handleDrop={handleDrop}
            closeModal={closeModal}
            locale={locale}
            loadId={loadID}
            startDate={cycleStartDate}
            formData={formData}
            handleFormData={handleFormData}
            isEdit={isEdit}
            onConfirmEdit={onConfirmEdit}
            loadingFinishModal={loadingFinishModal}
          />
        )}
        {load && (
          <WashDetail
            loadId={load.chargeWashingId}
            locale={locale}
            handleFormData={handleFormData}
            handleLoadId={setLoadID}
            openEdit={setIsModalOpen}
            handleIsEdit={setIsEdit}
            reloadDetail={reloadDetail}
            handleReload={setRealoadDetail}
          />
        )}
        {!load && (
          <WashBoard
            locale={locale}
            handleLoad={handleLoad}
            setLoads={setLoads}
            loads={loads}
            getLoads={getLoads}
            toStartLoads={toStartLoads}
            startedLoads={inProgressLoads}
            finishedLoads={finishedLoads}
            handleChangeDate={handleChangeDate}
            dateTo={dateTo}
            dateFrom={dateFrom}
            modalLoading={modalLoading}
            handleFilterSterilizer={setSelectedWasher}
            handleStartDate={setCycleStartDate}
            handleLoadId={setLoadID}
            handleDrop={handleDrop}
            handleFormData={handleFormData}
            handleModal={setIsModalOpen}
            handleLoadingFinishModal={setLoadingFinishModal}
          />
        )}
      </>
    </PageContainer>
  );
};

export default ManageWashCycles;
