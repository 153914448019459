import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
// import { getUserName } from "../utils/sessionHandler";

// import NavBar from "../components/NavBar";
import Home from "../pages/Home";

// import list from '../components/NavBar/Menu/MenuList';

// const userName = getUserName()

export default () => (
  <LocalizationContext.Provider value={null}>
    {/* <NavBar MenuOptions={list} userName={userName}> */}
      <Home />
    {/* </NavBar> */}
  </LocalizationContext.Provider>
);

