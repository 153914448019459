import React, { useEffect, useState } from "react";
import InputQ from "../../../../components/FormComponents/InputQ/InputQ";
import TextAreaQ from "../../../../components/FormComponents/TextAreaQ/TextAreaQ";
import styles from "./WashFinishModal.module.css";
import t from "../../../../utils/translation";
import request from "../../../../utils/request";
import { getRegionalFormat, getUserId } from "../../../../utils/sessionHandler";
import { toast } from "react-toastify";
import ImageDrop from "../../../../components/ImageDrop/ImageDrop";
import InputDateTimeQ from "../../../../components/FormComponents/InputDateTimeQ/InputDateTimeQ";
import SelectQ from "../../../../components/FormComponents/SelectQ/SelectQ";
import ModalQ from "../../../../components/TableBionovaQ/ModalQ/ModalQ";
import parseFiltersDate from "../../../../utils/parseFiltersDate";
import df from "../../../../utils/dateFormater";

function WashFinishModal({
  loadId,
  isModalOpen,
  closeModal,
  handleDrop,
  locale,
  formData,
  handleFormData,
  isEdit = false,
  onConfirmEdit = () => {},
  loadingFinishModal,
}) {
  const [validate, setValidate] = useState(false);
  const [temperatureOptions, setTemperatureOptions] = useState([]);
  const [detergentTypes, setDetergentTypes] = useState([]);
  const timeUnitOptions = [
    { value: "Minutes", name: t("MinuteSpan") || "Minutes" },
    { value: "Seconds", name: t("SecondSpan") || "Seconds" },
  ];
  const [error, setError] = useState({ type: "", message: "" });

  const loggedUserId = getUserId();

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  }

  const handleSelectChange = (e) => {
    let { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFileChange = (value) => {
    handleFormData((prevData) => ({ ...prevData, img: value }));
  };

  const handleClose = () => {
    closeModal();
  };

  const validateRequiredFields = () => {
    const requiredFields = ["cicle"];

    if (formData.chargeStatus != 3) {
      for (const field of requiredFields) {
        if (!formData[field] || formData[field].toString().trim() === "") {
          return false;
        }
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !validateRequiredFields() ||
      error.type ||
      !isValidDate(formData.cicleStartDate)
    ) {
      if (error.type) {
        toast.error(error.message);
      }
      if (!isValidDate(formData.cicleStartDate)) {
        toast.error(t("InvalidDateSpan"));
      }
      if (!validateRequiredFields()) {
        toast.error(
          t("RequiredFieldMissingSpan") ||
            "Please fill out all required fields."
        );
        setValidate(true);
      }
      return;
    }

    try {
      await handleDrop(formData.chargeWashingId, formData.chargeStatus, e, {
        ...formData,
        detergentType: parseInt(formData.detergentType),
        temperatureUnit: temperatureOptions?.find(
          (u) => u.value == formData.temperatureUnit
        )?.name,
        ImageData:
          formData.chargeStatus == 3 || isEdit
            ? null
            : formData.img?.base64Image,
        ImageName:
          formData.chargeStatus == 3 || isEdit ? null : formData.img?.imageName,
        ImageFormat:
          formData.chargeStatus == 3 || isEdit
            ? null
            : formData.img?.fileFormat,
      });

      if (isEdit) {
        onConfirmEdit(formData.chargeWashingId);
      }

      handleClose();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const handleStartDate = (value) => {
    const finishDate = formData.cicleFinishDate;

    if (
      finishDate &&
      new Date(value?._d).getTime() >
        new Date(
          parseFiltersDate(
            df(finishDate, "dateTime"),
            getRegionalFormat().dateFormat
          )
        ).getTime()
    ) {
      toast.error(
        t("StartDateCannotBeAfterFinishDate") ||
          "Start date cannot be after finish date."
      );
      return;
    } else if (new Date(value?._d).getTime() > new Date().getTime()) {
      toast.error(
        t("StartDateCannotBeAfterFinishDate") ||
          "Start date cannot be later than the current date."
      );
      return;
    }

    handleFormData((prev) => ({ ...prev, cicleStartDate: value }));
  };

  const handleFinishDate = (value) => {
    const startDate = formData.cicleStartDate;

    if (
      new Date(value?._d).getTime() <
      new Date(
        parseFiltersDate(
          df(startDate, "dateTime"),
          getRegionalFormat().dateFormat
        )
      ).getTime()
    ) {
      toast.error(
        t("FinishDateCannotBeBeforeStartDate") ||
          "Finish date cannot be before start date."
      );

      setError({
        type: "finishDate",
        message: t("FinishDateCannotBeBeforeStartDate"),
      });
      return;
    }

    if (!isValidDate(value)) {
      setError({
        type: "invalidDate",
        message: t("InvalidDateSpan"),
      });
    }

    if (
      (error.type === "finishDate" || error.type === "invalidDate") &&
      isValidDate(value)
    ) {
      setError({
        type: "",
        message: "",
      });
    }
    handleFormData((prev) => ({ ...prev, cicleFinishDate: value }));
  };

  console.log(formData.cicleFinishDate, formData.cicleStartDate);

  useEffect(() => {
    const fetchAppParameter = request().get(
      `/api/appparameter?userLoggedId=${loggedUserId}`
    );
    const fetchTemperatureUnit = request().get(
      `/api/temperatureunit?userLoggedId=${loggedUserId}`
    );
    const fetchDetergentType = request().get(
      `/api/Enum/getDetergentTypes?userLoggedId=${loggedUserId}`
    );

    Promise.all([fetchAppParameter, fetchTemperatureUnit, fetchDetergentType])
      .then(([appParameterRes, temperatureUnitRes, detergentTypeRes]) => {
        const appParameterData = appParameterRes.data;
        const temperatureUnitData = temperatureUnitRes.data.map((item) => ({
          value: item.id,
          name: item.description,
        }));
        const detergentTypeData = detergentTypeRes.data.map((item) => ({
          value: item.id,
          name: item.description,
        }));

        handleFormData((prevData) => ({
          ...prevData,
          securityMode: appParameterData[4].value,
          temperatureUnit:
            formData.chargeStatus === 4
              ? temperatureUnitData.find(
                  (t) => t.name === formData.temperatureUnit
                ).value
              : appParameterData[8]?.value,
        }));
        setTemperatureOptions(temperatureUnitData);
        setDetergentTypes(detergentTypeData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <ModalQ
      open={isModalOpen}
      title={t("ProcessData") || "Process Data"}
      handleClose={handleClose}
      loading={loadingFinishModal}
      category="washing"
    >
      <div className={styles.inputGroup}>
        <div style={{ width: "48%" }}>
          <InputDateTimeQ
            category="washing"
            label={t("StartDateSpan") || "Start Date"}
            name="cicleStartDate"
            required={true}
            errorMessage={t("RequiredField") || "This field is required"}
            validate={validate}
            value={formData.cicleStartDate}
            handleChange={handleStartDate}
          />
        </div>
        {formData.chargeStatus !== 3 && (
          <div style={{ width: "48%" }}>
            <InputDateTimeQ
              category="washing"
              label={t("FinishDateSpan") || "Finish Date"}
              name="cicleFinishDate"
              handleChange={handleFinishDate}
              required={true}
              errorMessage={t("RequiredField") || "This field is required"}
              validate={validate}
              defaultValue={formData.cicleFinishDate}
              value={formData.cicleFinishDate}
              onChange={handleFinishDate}
            />
          </div>
        )}
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          category={"washing"}
          label={t("CycleNumberSpan") || "Cycle Number"}
          name="cicle"
          handleChange={handleInputChange}
          value={formData.cicle}
          type="number"
          min={0}
          required={formData.chargeStatus != 3}
          errorMessage={t("RequiredField") || "This field is required"}
          validate={validate}
        />
        <InputQ
          category={"washing"}
          label={t("UsedProgramSpan") || "Used Program"}
          name="usedProgram"
          handleChange={handleInputChange}
          value={formData.usedProgram}

        />
      </div>

      <div className={styles.inputGroup}>
        <div style={{ width: "48%" }} className={styles.inputGroup}>
          <InputQ
            label={t("WashingTimeSpan") || "Washing Time"}
            name="washingTime"
            handleChange={handleInputChange}
            value={formData.washingTime}
            width="48%"
            type="number"
            min={0}
            category="washing"
          />
          <SelectQ
            selectValue={formData.timeUnit}
            handleSelect={handleSelectChange}
            name={"timeUnit"}
            width={"100%"}
            containerWidth={"48%"}
            options={timeUnitOptions}
            category="washing"
          />
        </div>
        <div style={{ width: "48%" }} className={styles.inputGroup}>
          <InputQ
            label={t("TemperatureSpan") || "Temperature"}
            name="temperature"
            handleChange={handleInputChange}
            value={formData.temperature}
            width="48%"
            type="number"
            min={0}
            category="washing"
          />
          <SelectQ
            selectValue={formData.temperatureUnit}
            handleSelect={handleSelectChange}
            name={"temperatureUnit"}
            width={"100%"}
            containerWidth={"48%"}
            options={temperatureOptions}
            category="washing"
          />
        </div>
      </div>
      <div className={styles.inputGroup}>
        <SelectQ
          selectValue={formData.detergentType}
          handleSelect={handleSelectChange}
          name={"detergentType"}
          placeholder={t("SelectDetergentTypeOption") || "Detergent Type"}
          width={"100%"}
          containerWidth={"48%"}
          options={detergentTypes}
          category="washing"
        />
        <InputQ
          category={"washing"}
          label={t("DetergentConcentrationSpan") || "Detergent Concentration"}
          name="detergentConcentration"
          handleChange={handleInputChange}
          value={formData.detergentConcentration}
          min={0}
          type="number"
        />
      </div>
      <div className={styles.inputGroup}>
        <InputQ
          category={"washing"}
          label={t("WaterPressureSpan") || "Water Pressure"}
          name="waterPressure"
          handleChange={handleInputChange}
          value={formData.waterPressure}
          type="number"
          min={0}
        />
        <InputQ
          category={"washing"}
          label={t("WaterHardnessSpan") || "Water Hardness"}
          name="waterHardness"
          handleChange={handleInputChange}
          value={formData.waterHardness}
          type="number"
          min={0}
        />
      </div>


      <TextAreaQ
        category={"washing"}
        width="100%"
        label={t("ObservationSpan") || "Observations"}
        name="cicleObservations"
        handleChange={handleInputChange}
        value={formData.cicleObservations}
      />

      {formData.chargeStatus !== 3 && !isEdit && (
        <ImageDrop
          defaultValue={formData.ticketWasher}
          locale={locale}
          onFileChange={onFileChange}
        />
      )}

      <div className={styles.buttonGroup}>
        <div></div>
        <button
          className={`${styles.button} ${styles.saveButton}`}
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {t("Save") || "Save"}
        </button>
      </div>
    </ModalQ>
  );
}

export default WashFinishModal;
