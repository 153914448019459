import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FixedAlert from '../FixedAlert/FixedAlert';
import InputAdornment from '@material-ui/core/InputAdornment';
import t from "../../utils/translation";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    }
}));

const FormattedField = (props) => {
    const classes = useStyles();
    const { object, updateValue, label, min, max, adornment, maxLength, step, isUmbral, handleResponseError } = props;
    const [errorValidation, setErrorValidation] = useState("");

    useEffect(() => {
        if (handleResponseError) {
            setErrorValidation(handleResponseError)
            setTimeout(() => {
                setErrorValidation("")
            }, 5000);
        }
    }, [props.handleResponseError])

    const withValueLimit = (inputObj, min, max, setErrorValidation) => {
        if (inputObj) {
            const floatValue = parseFloat(inputObj);
            if (floatValue <= max && floatValue >= min) {
                object.disabled = false;
                setErrorValidation("")
                return inputObj
            } else {
                object.disabled = true;
                if (floatValue > max)
                    setErrorValidation(t("ValueMustBeLessThan") + max)
                if (floatValue < min)
                    setErrorValidation(t("ValueMustBeBiggerThan") + min)
            }
        } else {
            object.disabled = true;
        }
    };

    const handleMaxLengthDynamic = (input) => {
        let int = input.split(".");
        if (maxLength === 4) {
            if (int[0].length < 2) {
                return 3;
            } else
                return 4;
        }
    };

    return (
        <div style={{ marginBottom: 8 }}>
            <TextField
                style={{ width: '100%' }}
                d={object.appParameterTypeDescription}
                label={label}
                type="number"
                size="small"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                onChange={(val) => {
                    withValueLimit(val.target.value, min, max, setErrorValidation)
                    updateValue(val.target.value, object.id)
                }}
                inputProps={{
                    value: object.value,
                    step: step,
                    max: max,
                    min: min
                }}
                InputProps={{
                    startAdornment: adornment && <InputAdornment position={"end"} className={classes.margin}>{adornment}</InputAdornment>
                }}
                onInput={(e) => {
                    if (!isNaN(parseFloat(e.target.value))) {
                        if (isUmbral) {
                            let maxLengthDynamic = handleMaxLengthDynamic(e.target.value)
                            e.target.value = parseFloat(e.target.value.slice(0, maxLengthDynamic))
                        } else {
                            e.target.value = parseFloat(e.target.value.slice(0, maxLength))
                        }
                    }
                }}
            />
            <FixedAlert text={errorValidation} type={"error"} closeErrorCb={() => { setErrorValidation("") }}></FixedAlert>
        </div>
    )
};
export default FormattedField;