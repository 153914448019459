import React, { useState, useEffect, useContext } from "react";
import request from "../../utils/request";
import { getUserCompany, getSession } from "../../utils/sessionHandler";
import LocalizationContext from "../../utils/LocalizationContext";
// import t from "../../utils/translation";
import t from "../../translation_mocks/translationMocker";
import mock from "./translationMock";
import r from "../../utils/required";

import _ from "lodash";

import CRUD from "../../components/CRUD";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";

export default function Sector() {
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);

  const locale = useContext(LocalizationContext);

  const memorizedCompany = React.useMemo(() => getUserCompany(), []);

  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      request()
        .get(`/api/sector?companyId=${memorizedCompany.Id}`)
        .then((response) => {
          setData(
            response.data.map((x) => ({
              "company.name": memorizedCompany.name,
              ..._.mapKeys(x, (value, key) => "sector." + key),
            }))
          );
          setLoadingData(false);
        })
        .catch((error) => {
          console.log(error.response);
          setLoadingData(false);
        });
    }, 250);
  }, [memorizedCompany]);

  const updateData = () => {
    setLoadingData(true);

    setTimeout(() => {
      request()
        .get(`/api/sector?companyId=${memorizedCompany.Id}`)
        .then((response) => {
          setData(
            response.data.map((x) => ({
              "company.name": memorizedCompany.name,
              ..._.mapKeys(x, (value, key) => "sector." + key),
            }))
          );
          setLoadingData(false);
        })
        .catch((error) => {
          console.log(error.response);
          setLoadingData(false);
        });
    }, 250);
  };

  return (
    <PageGeneralContainer title={t("Title", locale)} category={"sector"}>
      <CRUD
        url={"/api/sector"}
        title={t("Title", locale)}
        data={data}
        loadingData={loadingData}
        updateData={updateData}
        columns={["sector.name"]}
        headers={[t("NameLabel", locale)]}
        idField={"sector.id"}
        fieldsMap={["sector.name"]}
        formFields={[
          {
            label: t("NameLabel", locale),
            class: "col-12 px-0",
            required: true,
            regex: true,
          },
        ]}
        detailFields={[{ label: t("NameLabel", locale), name: "sector.name" }]}
        baseObject={{
          sector: {
            id: null,
            name: "",
          },
          UserLogged: getSession(),
        }}
        baseObjectOverwrite={{
          sector: { company: memorizedCompany },
          company: { name: memorizedCompany.name },
        }}
      />
    </PageGeneralContainer>
  );
}
