import React from 'react';
import classnames from 'classnames';

import useStyles from './ProgressStyles';

export default function Progress(props) {
	const classes = useStyles();

	return (
		<div className={classnames(props.class, classes.root, {[classes.disabled]: !props.active})}>
			<div className={classes.progress}/>
			<div className={classes.progressDelayed}/>
		</div>
	)
} 
 