import React, { Component } from "react";
import "./ChartApp.css";
import template from "./InitialState/ChartInitialState";
import Panel from "./Panel.jsx";
import request from '../../utils/request'
import { CircularProgress } from "@material-ui/core";
import NewPanel from "./Actions/NewPanel";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import t from "../../utils/translation";

class DashboardModule extends Component {
  translations = this.props.translations;
  panelOptios = this.props.panelOptios;
  state = template(t("ChartDrawLabel", this.translations));
  copyDataSeries = async (panels, obj = {}) => {
    this.setState({
      ...obj,
      loading: false,
      userPanels: panels,
      error: ''
    });
  };

  createDash = () => {
    request().get(`/api/PanelUser`)
      .then((response) => {
        let panelOpt = [];
        response.data.forEach((pu, i) => {
          if (this.panelOptios.some(opt => opt == pu.panelId)) panelOpt.push(pu);
        })
        this.copyDataSeries(panelOpt);
      }).catch((error) => {
        this.setState({ ...this.state, error: error?.response?.data?.message, loading: false });
      });
  }

  componentDidMount() {
    this.createDash();
    // dataProcessing(this.state.yearFrom, this.state.yearTo, this.state.msg);
  }

  handleSubmit = e => {
    // let msg = dataProcessing(this.state.yearFrom, this.state.yearTo);
    // this.copyDataSeries({ msg: msg });
    e.preventDefault();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.yearFrom !== this.state.yearFrom) {
      this.handleChangeSelect();
    }
    if (prevState.yearTo !== this.state.yearTo) {
      this.handleChangeSelect();
    }
  }
  // handleChangeSelect() {
  //   let msg = dataProcessing(this.state.yearFrom, this.state.yearTo);
  //   this.copyDataSeries({ msg: msg });
  // }

  handleChangeYear = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onClickDeletePanel = (userPanelId) => {
    let userPanel = this.state.userPanels.find(x => x.id === userPanelId)
    request()
      .put(`/api/PanelUser/${userPanelId}`, {
        ...userPanel,
        inactive: true,
      })
      .then(() => {
        this.createDash();
      });
  };
  render() {
    return (
      <>
        <NewPanel
          currentUserPanels={this.state.userPanels}
          createDash={this.createDash}
          translations={this.translations}
          panelOptios={this.panelOptios}
        ></NewPanel>
        <div className={"row"}>
          <div className={"col-lg-12"}>
            <FixedAlert
              text={this.state.error}
              type={"error"}
              closeErrorCb={() => {
                this.setState({ ...this.state, error: '' })
              }}
            ></FixedAlert>
          </div>
        </div>
        <div className="mb-5 pb-2 bg-light">
          {/*<div
            className={
              "text-center mb-0 pt-3 bold " +
              (this.state.msg !== "Select the range" ? "text-danger" : "")
            }
          >
            <strong>{this.state.msg}</strong>
          </div>
           <Selection
            yearFrom={this.state.yearFrom}
            yearTo={this.state.yearTo}
            onChangeYear={this.handleChangeYear}
            onSubmit={this.handleSubmit}
          /> */}
          {this.state.loading && (
            <CircularProgress
              style={{ margin: 20 }}
              size={60}
              thickness={3.4}
            ></CircularProgress>
          )}
          {
            this.state.userPanels &&
            this.state.userPanels.map((userPanel, i) => {
              return (
                <div style={
                  {
                    textAlign: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    marginTop: 10,
                    padding: "25px 25px 25px 25px",
                    borderRadius: "10px 10px 10px 10px",
                    border: '1px solid rgba(0,0,0,.125)'
                  }
                } key={userPanel.id}>
                  <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
                    <IconButton title={t("DeleteTooltip")} aria-label="delete" onClick={() => {
                      this.onClickDeletePanel(userPanel.id)
                    }
                    }>
                      <Icon className="fas fa-times" style={{ fontSize: 15 }} />
                    </IconButton>
                    <span style={{ marginLeft: 10 }}><b>{userPanel.panel.name}</b></span>
                  </div>
                  <Panel
                    userConfig={this.state.userConfig}
                    charts={this.state.charts}
                    userPanel={userPanel}
                    translations={this.translations}
                  />
                </div>
              );
            })
          }
        </div>
      </>
    );
  }
}

export default DashboardModule;
