import React from 'react';
import { CircularProgress, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    position: 'absolute',
  },
  danger: {
    color: '#f44336', 
  },
  secondaryYellow: {
    color: '#ffaa00', 
  },
  secondaryGreen: {
    color: '#00e8ab', 
  },
  grey: {
    color: '#e6e6e6', 
  },
  text: {
    position: 'absolute',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
}));

const CircularProgressIndicators = ({ totalIndicators, vinculatedIndicators }) => {
  const classes = useStyles();

  // Si totalIndicators es 0, pintar todo el círculo de verde independientemente de vinculatedIndicators
  if (totalIndicators === 0) {
    return (
      <Tooltip
        title={
          <div>
            <div>Total indicators: {totalIndicators}</div>
            <div>Vinculated Indicators: {vinculatedIndicators}</div>
          </div>
        }
        arrow
      >
        <div className={classes.container}>
          <CircularProgress
            variant="determinate"
            value={100}
            size={40}
            thickness={4}
            className={`${classes.circle} ${classes.secondaryGreen}`} // Círculo completamente verde
          />
          <div className={classes.text}>
            {vinculatedIndicators}/{totalIndicators}
          </div>
        </div>
      </Tooltip>
    );
  }

  // Comportamiento normal cuando totalIndicators no es 0
  const getColorClass = (index) => {
    if (vinculatedIndicators === 0 && totalIndicators > 0 && index === 0) return classes.danger;
    if (vinculatedIndicators > 0 && vinculatedIndicators < totalIndicators && index < 2)
      return classes.secondaryYellow;
    if (vinculatedIndicators === totalIndicators) return classes.secondaryGreen;
    return classes.grey;
  };

  return (
    <Tooltip
      title={
        <div>
          <div>Total indicators: {totalIndicators}</div>
          <div>Vinculated Indicators: {vinculatedIndicators}</div>
        </div>
      }
      arrow
    >
      <div className={classes.container}>
        <CircularProgress
          variant="determinate"
          value={33.33}
          size={40}
          thickness={4}
          className={`${classes.circle} ${getColorClass(0)}`}
          style={{ zIndex: 3 }}
        />
        <CircularProgress
          variant="determinate"
          value={66.66}
          size={40}
          thickness={4}
          className={`${classes.circle} ${getColorClass(1)}`}
          style={{ zIndex: 2 }}
        />
        <CircularProgress
          variant="determinate"
          value={100}
          size={40}
          thickness={4}
          className={`${classes.circle} ${getColorClass(2)}`}
          style={{ zIndex: 1 }}
        />
        <div className={classes.text}>
          {vinculatedIndicators}/{totalIndicators}
        </div>
      </div>
    </Tooltip>
  );
};

export default CircularProgressIndicators;
