import { makeStyles, createStyles } from "@material-ui/styles";


export default makeStyles((theme) => createStyles({
    printButtonContainer: {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        bottom: 0, 
        left: 0,
    },

    iframe: {
        height: '500px',
        width: '100%',
        '@media(min-width:1920px)': {
            height: '700px',
        }
    }
}));

