import React, { useEffect, useReducer } from "react";
import { forwardRef, useImperativeHandle } from "react";

import t from "../../utils/translation";
import propTypes from "prop-types";
import useStyles from "./CRUDStyles";

import Table from "../Table";
import Button from "../Button";
import Update from "./Update";
import Delete from "./Delete";
import Detail from "./Detail";
import Massive from "./Massive";
import { getUserType } from "../../utils/sessionHandler";
import { UserType } from "../../dictionaries/userTypes";
import reducer from "./CRUDReducer";
import Button2 from "../Button2";
import ButtonQ from "../ButtonQ";

const initialState = {
  data: [],
  selectedObjet: {},
  openMassive: false,
  selectedObjectsId: [],
  selectedObjectsList: [],
  objectToDelete: {},
  openForm: false,
  openDetail: false,
  openConfirmation: false,
  mode: "",
  idField: "",
  selectOneRowOnly: false,
  errorsValidate: undefined,
};

const CRUD = forwardRef((props, ref) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    url,
    title,
    columns,
    order,
    headers,
    tableHeader = "",
    idField = "",
    fieldsMap = [],
    formFields = [],
    formFieldsMachine = [],
    massiveFieldsMap = [],
    detailFields = [],
    baseObject = {},
    massiveBaseObject = {},
    baseObjectOverwrite = {},
    tableActions = [],
    massiveActions = [],
    method = "save",
    newOption = true,
    editOption = true,
    detailOption = true,
    deleteOption = true,
    multiSelect = false,
    connectionStatus = false,
    loadingData = false,
    massiveURL = "",
    ////////////////////////////////
    serversidePagination = false,
    page = null,
    handlePagination = null,
    btnTrazantoVisible = false,
    totalRowsServerSide = 0,
    resultStatus = false,
    editedInfo,
    migrationInfo,
    masterDetail = {},
    viewedMark = false,
    onEditClick = () => {},
    onOpenDetail = () => {},
    onEditMassiveButtonClick = () => {},
    counterDown = {},
    grouped = null,
    useFieldMap = true,
    splitButtonExtraOptions,
    selectOneRowOnly = false,
    handleSelectionClick = () => {},
    closeModalCallback = () => {},
    onChangeCallback = () => {},
    afterAssociate,
    selectedDynamically,
    hasCustomFields = false,
    onCloseModal = () => {},
    handleValidation = () => {},
  } = props;

  const {
    data,
    openForm,
    openDetail,
    selectedObjet,
    openMassive,
    selectedObjectsId,
    selectedObjectsList,
    openConfirmation,
    objectToDelete,
    mode,
    successMessage,
    showSuccessMessage,
    errorMessage,
    showErrorsMessage,
  } = state;

  const sterilizerArray = [];
  if (openForm && mode === "update") {
    if (props.formFieldsMachine != null) {
      for (let i = 0; i < props.formFields.length; i++) {
        if (props.formFields[i]["id"] === "machine") {
          props.formFields[i]["options"] = formFieldsMachine[0];
          props.formFields[i]["disabled"] = false;

          for (let e = 0; e < props.formFields[i]["options"].length; e++) {
            if (e === 0) {
              sterilizerArray.push([
                props.formFields[i]["options"][e][0],
                props.formFields[i]["options"][e][1],
              ]);
            } else {
              if (
                props.formFields[i]["options"][e][2] ===
                  state.selectedObjet["ReadingBI.sectorId"] ||
                props.formFields[i]["options"][e][2] ===
                  state.selectedObjet["ReadingPRO.sectorId"] ||
                props.formFields[i]["options"][e][2] ===
                  state.selectedObjet["ReadingCHESterilizer.sectorId"] ||
                props.formFields[i]["options"][e][2] ===
                  state.selectedObjet["ReadingCHEWasher.sectorId"]
              ) {
                sterilizerArray.push([
                  props.formFields[i]["options"][e][0],
                  props.formFields[i]["options"][e][1],
                  props.formFields[i]["options"][e][2],
                ]);
              }
            }
          }
          if (sterilizerArray.length > 0) {
            formFields[i]["options"] = sterilizerArray;
          }
        }
      }
    }
  }

  if (mode === "massive") {
    if (props.formFieldsMachine != null) {
      for (let i = 0; i < props.formFields.length; i++) {
        if (props.formFields[i]["id"] === "machine") {
          props.formFields[i]["options"] = formFieldsMachine[0];
          if (props.formFields[i]["userType"] === 0) {
            props.formFields[i]["disabled"] = true;
            props.formFields[i]["msgTooltip"] = t("FieldCannotModified");
          } else {
            for (let e = 0; e < props.formFields[i]["options"].length; e++) {
              if (e === 0) {
                sterilizerArray.push([
                  props.formFields[i]["options"][e][0],
                  props.formFields[i]["options"][e][1],
                ]);
              } else {
                if (state.selectedObjectsList.length > 0) {
                  if (
                    props.formFields[i]["options"][e][2] ===
                      state.selectedObjectsList[0]["ReadingBI.sectorId"] ||
                    props.formFields[i]["options"][e][2] ===
                      state.selectedObjectsList[0]["ReadingPRO.sectorId"] ||
                    props.formFields[i]["options"][e][2] ===
                      state.selectedObjectsList[0][
                        "ReadingCHESterilizer.sectorId"
                      ] ||
                    props.formFields[i]["options"][e][2] ===
                      state.selectedObjectsList[0]["ReadingCHEWasher.sectorId"]
                  ) {
                    sterilizerArray.push([
                      props.formFields[i]["options"][e][0],
                      props.formFields[i]["options"][e][1],
                      props.formFields[i]["options"][e][2],
                    ]);
                  }
                }
              }
            }
            if (sterilizerArray.length > 0) {
              formFields[i]["options"] = sterilizerArray;
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    dispatch({
      type: "setData",
      payload: { data: props.data, idField: props.idField },
    });
    dispatch({
      type: "SET_MODE_SELECTION",
      payload: { selectOneRowOnly: props.selectOneRowOnly },
    });
  }, [props.data, props.idField]);

  const saveSuccess = (alertMessage, clearCache, updatedObj) => {
    if (clearCache) dispatch({ type: "clearSelected" });
    if (props.customSuccessHandler) {
      props.customSuccessHandler(alertMessage);
      dispatch({ type: "closeModal" });
    } else {
      dispatch({ type: "saveSuccess", payload: alertMessage });
      setTimeout(() => {
        dispatch({ type: "hideSuccessMessage" });
      }, 3000);
      props.updateData(updatedObj);
    }
  };

  useImperativeHandle(ref, () => ({
    api(options) {
      dispatch(options);
    },
  }));

  //Se utiliza para manejar el estado de la pantalla luego de hacer una vinculación lectura-medición.
  useEffect(() => {
    if (afterAssociate) {
      if (afterAssociate.executeAction) {
        if (afterAssociate.type === "OK") {
          dispatch({ type: "saveSuccess", payload: afterAssociate.message });
          setTimeout(() => {
            dispatch({ type: "hideSuccessMessage" });
          }, 3000);
        } else {
          dispatch({ type: "saveError", payload: afterAssociate.message });
          setTimeout(() => {
            dispatch({ type: "hideErrorMessage" });
          }, 3000);
        }
        props.updateData(afterAssociate.updatedObject);
      }
    }
  }, [props.afterAssociate]);

  //se utiliza para seleccionar dinamicamente una medición vinculada en el componente ToolMeasure.
  useEffect(() => {
    if (selectedDynamically) {
      dispatch({ type: "SELECT_ONE_ROW", payload: selectedDynamically });
    }
  }, [props.selectedDynamically]);

  return (
    <React.Fragment>
      {/* <div style={{ margin: !props.customSuccessHandler ? "0px 0" : 0 }}/> */}
      {showSuccessMessage && (
        <div className={"alert alert-success"} role="alert">
          {successMessage}
        </div>
      )}
      {showErrorsMessage && (
        <div className={"alert alert-danger"} role="alert">
          {errorMessage}
        </div>
      )}
      <div
        id={"cont"}
        style={{
          paddingBottom: "20px",
          margin: "0 auto",
          width: "95%",
          borderRadius: "25px",
        }}
      >
        <Table
          counterDown={counterDown}
          viewedMark={viewedMark}
          masterDetail={masterDetail}
          resultStatus={resultStatus}
          editedInfo={editedInfo}
          migrationInfo={migrationInfo}
          totalRowsServerSide={totalRowsServerSide}
          btnTrazantoVisible={btnTrazantoVisible}
          multiSelect={multiSelect}
          connectionStatus={connectionStatus}
          selectedObjectsId={selectedObjectsId}
          idField={idField}
          headerText={tableHeader || title}
          loadingData={loadingData}
          data={data}
          columns={columns}
          headers={headers}
          ////
          serversidePagination={serversidePagination}
          page={page}
          handlePagination={handlePagination}
          /////
          order={order || { dir: "asc", orderBy: columns[0] }}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          rowsPerPageText={t("RowsPerPageText") || "Rows per page"}
          handleRowClick={
            multiSelect
              ? !selectOneRowOnly
                ? (row) => dispatch({ type: "selectRow", payload: row })
                : (row) => {
                    dispatch({ type: "SELECT_ONE_ROW", payload: row });
                    handleSelectionClick(row);
                  }
              : undefined
          }
          handleRowEdit={
            editOption &&
            ((row) => {
              dispatch({ type: "openUpdateObject", payload: row });
              onEditClick(row);
            })
          }
          handleRowDetail={
            detailOption &&
            ((row) => {
              dispatch({ type: "openObjectDetail", payload: row });
              onOpenDetail(row);
            })
          }
          handleRowDelete={
            deleteOption &&
            ((row) => dispatch({ type: "openConfirmation", payload: row }))
          }
          actions={tableActions}
        />
        {newOption && (
          <div style={{ position: "absolute", marginTop: -36, marginLeft: 15 }}>
            <ButtonQ
              caption={t("AddButton") || "Add"}
              onClick={() => dispatch({ type: "openNewObject" })}
            />
          </div>
        )}
        {multiSelect && selectedObjectsId.length > 0 && (
          <div style={{ display: "flex", marginTop: 10 }}>
            {/* {getUserType() !== UserType.Administrator &&
              <div style={{ marginLeft: 15 }}>
                <Button
                  caption={t("MultipleEditButton") || "Multiple Edit"}
                  onClick={() => {
                    dispatch({ type: "openMassiveModify" })
                    onEditMassiveButtonClick()
                  }}
                />
              </div>
            } */}
            {massiveActions.map((a) => (
              <div style={{ marginLeft: 15 }}>
                <Button
                  caption={a.caption}
                  onClick={() =>
                    a.action(selectedObjectsList, selectedObjectsId)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {openForm && (
        <Update
          useFieldMap={useFieldMap}
          grouped={grouped}
          serversidePagination={serversidePagination}
          idField={idField}
          saveSuccess={saveSuccess}
          classes={classes}
          dispatch={dispatch}
          url={url}
          title={title}
          fieldsMap={fieldsMap}
          formFields={formFields}
          selectedObjet={selectedObjet}
          mode={mode}
          baseObject={baseObject}
          baseObjectOverwrite={baseObjectOverwrite}
          method={method}
          onChangeCallback={(e) => onChangeCallback(e)}
          splitButtonExtraOptions={splitButtonExtraOptions}
          showModalToolMeasure={props.showModalToolMeasure}
          errorsFromValidation={props.errorsFromValidation}
          closeModalCallback={() => closeModalCallback()}
          clearMessageValidation={props.clearMessageValidation}
          hasCustomFields={hasCustomFields}
          onCloseModal={onCloseModal}
          handleValidation={handleValidation}
        />
      )}

      {openMassive && (
        <Massive
          useFieldMap={useFieldMap}
          grouped={grouped}
          idField={idField}
          url={massiveURL}
          saveSuccess={saveSuccess}
          updateData={props.updateData}
          classes={classes}
          dispatch={dispatch}
          fieldsMap={massiveFieldsMap}
          formFields={formFields}
          baseObject={massiveBaseObject}
          selectedObjectsList={selectedObjectsList}
          selectedObjectsId={selectedObjectsId}
          splitButtonExtraOptions={splitButtonExtraOptions}
          onCloseModal={onCloseModal}
        />
      )}
      {openConfirmation && (
        <Delete
          dispatch={dispatch}
          saveSuccess={saveSuccess}
          classes={classes}
          url={url}
          title={title}
          objectToDelete={objectToDelete}
          baseObject={baseObject}
          baseObjectOverwrite={baseObjectOverwrite}
          idField={idField}
          hasCustomFields={hasCustomFields}
        />
      )}
      {openDetail && (
        <Detail
          fields={detailFields}
          object={selectedObjet}
          classes={classes}
          title={title}
          dispatch={dispatch}
          idField={idField}
          closeModalCallback={() => closeModalCallback()}
        />
      )}
    </React.Fragment>
  );
});

CRUD.propTypes = {
  url: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  columns: propTypes.array.isRequired,
  headers: propTypes.array.isRequired,
  idField: propTypes.string.isRequired,
};

export default CRUD;
