import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    }
})