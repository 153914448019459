import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import Historical from "../pages/Historical";
import PageContentLoading from "../components/PageContentLoading";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default (props) => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Dashboard,
    key: "Historical",
  };
  const screenName = 810
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={40}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          {locale ? (
            <Historical defaultReadOption={props?.location?.state?.read} />
          ) : (
            <PageContentLoading />
          )}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};

