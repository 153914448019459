export default function reducer(state, action) {
    switch (action.type) {
      case "SET_CONTENT":
        return {
          ...state,
          programs: action.payload.programs,
          scoreTypes: action.payload.scoreTypes,
          loadingContent: false,
        };
      default:
        throw new Error();
    }
  }
  