import { makeStyles, createStyles } from "@material-ui/styles";
export default makeStyles((theme) =>
    createStyles({
      root: {
        fontSize: "0.900rem",
        fontFamily: "Montserrat",
        color: "black",
        fontWeight: "500",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        lineHeight: "1.75",
        borderRadius: 20,
        alignSelf: "center",
        letterSpacing: "0.02857em",
        border: "black",
        outline: "0!important",
        margin: "20px 5px 10px 0px", //100 en el 1
        whiteSpace: 'nowrap', // Evita que el texto se divida en varias líneas
      },
  
      disabled: {
        cursor: "auto!important",
        color: "rgba(0,0,0,0.26)",
      },
  
      containedVariant: {
        padding: "8px 20px" ,
  
        "&$disabled": {
          boxShadow: "none",
          backgroundColor: "rgba(0,0,0,0.12)",
        },
      },
  
      textVariant: {
        padding: "6px 8px",
        backgroundColor: "transparent",
      },
  
      //colores
      primaryColor: {
        "&$containedVariant$active": {
          backgroundColor: "transparent",
           border: "1px solid black",
          transition: "all 0.4s ease-in-out",
          "&:visited": {
            // backgroundColor: "red!important",
            color: theme.palette.terragene.darkSecondary,
          },
          "&:active": {
            // backgroundColor: "red!important",
            color: theme.palette.terragene.darkSecondary,
          },
        },
  
        "&$textVariant$active": {
          color: theme.palette.terragene.darkMain,
  
          "&:hover": {
            backgroundColor: "rgba(3, 69, 128, 0.08)",
          },
        },
      },
  
      fullwidth: {
        width: "100%",
      },
  
      minwidth: {
        width: "20%",
      },
  
      mediumwidth: {
        width: "50%",
      },
  
      verticalMargin: {
        margin: "50px 0",
      },
  
      lateralMargin: {
        margin: "0 100px",
      },
  
      dense: {
        margin: 0,
      },
      active: {
        backgroundColor: theme.palette.terragene.lightMain,
      },
  
      prueba: {
        float: "right",
      },
    })
  );