import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles(
    {
        container:{
            padding: 20
        },
        headerNotLicense: {
            backgroundColor: theme.palette.terragene.lightMain,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px',
        },

        headerNotLicenseText: { 
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '1.5rem',
            color: theme.palette.terragene.lightThird
        }
    }
));
