import React, { useState } from "react";
import request from "../../utils/request";
import _ from "lodash";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

export default function ProductModel() {
  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [productTypes, setproductTypes] = useState([]);
  const [programs, setPrograms] = useState([]);

  React.useEffect(() => {
    Promise.all([request().get("/api/Productmodel/getTypes"), request().get("/api/Program"), updateData()])
      .then(([types, programs]) => {
        setPrograms(programs.data.reduce((programList, program) => {
          programList.push([program.id, program.programIndex + ". " + program.nombre + " - [" + Math.round(program.time*60) + "min.]"]);
          return programList;
        }, [ [null, 'Choose a Program'] ]))
        setproductTypes(
          types.data.reduce((typesList, type) => {
            typesList.push([type.id, type.description]);
            return typesList;
          }, [ [null, 'Choose a Type'] ])
        );

        setLoadingContent(false);
      })
      .catch(error => console.log(error.response));
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get("/api/productmodel")
      .then(response => {
        const productModelsMapped = response.data.reduce((readingsList, reading) => {
          readingsList.push({
            ...reading,
            program: null,
            programName: reading.program != null ? reading.program?.programIndex + ". " + reading.program?.nombre : ""
          });
          return readingsList;
        }, []).map(x =>
          _.mapKeys(x, (value, key) => {
            return "productmodel." + key;
          })
        )
        setData(productModelsMapped);
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <CRUD
      data={data}
      url={"/api/productmodel"}
      title={"Product Model"}
      updateData={updateData}
      loadingData={loadingData}
      columns={[
        "productmodel.typeDescription",
        "productmodel.name",
        "productmodel.brand",
        "productmodel.compatibleTrazanto",
        "productmodel.programName"
      ]}
      headers={["Type", "Name", "Brand", "Compatible Trazanto", "Associated Program"]}
      idField={"productmodel.id"}
      fieldsMap={[
        "productmodel.type",
        "productmodel.name",
        "productmodel.brand",
        "productmodel.compatibleTrazanto",
        "productmodel.programId"
      ]}
      formFields={[
        { label: "Type", class: "col-12 col-md-6 px-0", type: "select", options: productTypes, required: true },
        { label: "Name", class: "col-12 col-md-6 px-0", required: true },
        { label: "Brand", class: "col-12 col-md-6 px-0", required: true },
        { label: "Trazanto compatible", class: "col-12 col-md-6 px-0", type: "checkbox", checkCategory: "primary", required: false },
        { label: "Associated Program", class: "col-12 col-md-6 px-0", type: "select", options: programs }
      ]}
      detailFields={[
        { label: "Type", name: "productmodel.typeDescription" },
        { label: "Name", name: "productmodel.name" },
        { label: "Brand", name: "productmodel.brand" },
        { label: "Compatible Trazanto", name: "productmodel.compatibleTrazanto" },
        { label: "Associated Program", name: "productmodel.programName" }
      ]}
      baseObject={{
        productmodel: {
          id: null,
          type: "",
          name: "",
          brand: "",
          compatibleTrazanto: false,
          programId: null
        }
      }}
    />
  );
}
