import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import t from "../../utils/translation";

const LineChart = ({
  data,
  userConfig,
  titleName,
  translations,
  backgroundColor,
  fontColor,
  categories
}) => {
  const initialState = {
    chartData: {
      chart: {
        type: "line",
        marginBottom: 90,
        spacingBottom: 0,
        height: 300,
        backgroundColor: backgroundColor ? backgroundColor : "white",
      },
      legend: {
        enabled: false
      },
      title: {
        style: {
          color: fontColor,
        },
        text: titleName,
      },
      series: [
        {
          data: data,
        },
      ],
      ...userConfig,
    },
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      chartData: {
        ...state.chartData,
        xAxis: {
          categories: categories,
          title: {
            text: t("Date", translations),
            style: {
              color: fontColor
            }
          }
        },
        yAxis: {
          title: {
            text: t("CyclesCount", translations),
            style: {
              color: fontColor
            }
          },
        },
        subtitle: {
          text: t("Last31Days", translations),
          floating: true,
          style: {
            color: fontColor,
            fontSize: 12,
            fontWeight: "bold",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true,
          },
        },
        series: data
      },
    });
  }, []);

  return (
    <>
      {
        data[0].data.length > 0 ? (
          <HighchartsReact highcharts={Highcharts} options={state.chartData} />
        ) : (
            <p>{t("NotFoundInfo", translations)}</p>
          )
      }
    </>

  );
};

export default LineChart;
