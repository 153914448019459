import { CircularProgress } from "@material-ui/core";
import React from "react";
import Time from "../../TimeFormat/Time";
import Tooltip from "../../Tooltip/Tooltip";
import ThreeCircle from "./ThreeCircle";
import SpinnerQ from "../../SpinnerQ/SpinnerQ";

function ThreePositions({
  imgPositionsFiltered,
  loadingImage,
  incubator,
  colorStatus,
  programFiltered,
  threePositions,
  t,
  locale,
  handleClick,
  vinculation,
  position,
}) {
  const threePositionsMapped = threePositions.map((p, index) => (
    <ThreeCircle
      handleClick={handleClick}
      p={p}
      programFiltered={programFiltered}
      t={t}
      locale={locale}
      colorStatus={colorStatus}
      vinculation={vinculation}
      position={position}
    />
  ));

  return incubator == "MINIBIO" || incubator == "MINIPRO" ? (
    loadingImage ? (
      <div className="spinnerContainer">
        <SpinnerQ />
      </div>
    ) : (
      <div
        style={{
          backgroundImage: `url(${imgPositionsFiltered(incubator)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {threePositionsMapped}
      </div>
    )
  ) : null;
}

export default ThreePositions;
