import moment from "moment";
import "moment/min/locales.min";
import { getRegionalFormat } from "../utils/sessionHandler";

export const userLanguageCode = () => {
  const regionalFormat = getRegionalFormat();
  if (regionalFormat){
    return regionalFormat.languageCode;
  }
};

export const dotnetToMomentLocaleInfo = (languageCode) => {
  languageCode = languageCode.toLowerCase()
  switch (languageCode)
  {
    case "zh-hans-mo":
    case "zh-hans-hk":
    case "zh-hans":
    case "zh-sg":
      return "zh-cn";
    case "zh-hant":
      return "zh-tw";
    default:
      return languageCode;
  }
}

export const dotnetToMomentShortTimeFormat = (timeFormat) => {
  return timeFormat.replaceAll('tt', 'a')
                   .replaceAll('t', 'a')
}

export const dotnetToMomentShortDateFormat = (dateFormat) => {
  return dateFormat.replaceAll('d', 'D')
                   .replaceAll('y', 'Y')
}

export default function dateFormater(date, toFormat) {
  if (!date) return "";

  const dateFormat = getRegionalFormat()?.dateFormat ?? "L";
  const timeFormat = getRegionalFormat()?.timeFormat ?? "LT";

  moment.locale(userLanguageCode());
  const momentDate = moment.utc(date).local();
  if (toFormat === "date") {
    return momentDate.format(dateFormat);
  } else if (toFormat === "dateTime") {
    return momentDate.format(dateFormat) + " " + momentDate.format(timeFormat);
  } else if (toFormat === "monthYear") {
  return momentDate.format("MM/YYYY");
  }
}