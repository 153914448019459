import React, { useState, useEffect } from "react";
import { getSession } from "../../utils/sessionHandler";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { useApiData } from "../../utils/api";

import { PackagesGenerator } from "../../components/PackagesGenerator";
import DetailPackages from "../../components/PackagesGenerator/detailPackages";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";

export default function PackagesTypesList() {
  const {
    data: predefinedPackages,
    loading: loadingPredefinedPackages,
    updateData: updatePredefinedPackage,
  } = useApiData("/api/PredefinedPackage");
  const { data: materials, loading: loadingMaterials } =
    useApiData("/api/Material");
  const { data: methods, loading: loadingMethods } = useApiData("/api/Method");
  const { data: materialsType, loading: loadingMaterialsType } =
    useApiData("/api/materialType");

  const loadingContent =
    loadingPredefinedPackages ||
    loadingMaterials ||
    loadingMethods ||
    loadingMaterialsType;
  const [prefilledMethod, setPrefilledMethod] = useState(0);
  const [defaultMaterial, setDefaultMaterial] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const getOptions = (data, brand) => {
    let temp = [];
    data.reduce((options, current) => {
      temp.push([current.id, current?.[brand]]);
      return options;
    }, []);
    return temp;
  };

  const transformedData = predefinedPackages.map((packages) => {
    const method = methods.find((x) => x.id === packages?.methodId)?.name || [];
    return { ...packages, method };
  });

  if (loadingContent) return <PageContentLoading />;

  const resetState = (e) => {
    setPrefilledMethod("");
    setDefaultMaterial();
  };

  const handleUpdate = (e) => {
    updatePredefinedPackage();
    resetState();
  };

  const changeDefault = (e) => {
    setDefaultMaterial(e?.materials);
    setPrefilledMethod(parseInt(e?.methodId) || 0);
  };

  return (
    <>
      <PageGeneralContainer title={"Packages Types"} category={"packagesTypes"}>
        <CRUD
          data={transformedData}
          url={"/api/PredefinedPackage"}
          title={"Packages Types"}
          updateData={(e) => handleUpdate(e)}
          loadingData={loadingPredefinedPackages}
          closeModalCallback={(e) => resetState(e)}
          onCloseModal={(e) => setSelectedValue("")}
          onOpenDetail={(e) => changeDefault(e)}
          onEditClick={(e) => changeDefault(e)}
          columns={["name", "method", "description", "observations"]}
          headers={[
            "Type of Packages",
            "Method",
            "Description",
            "Observations",
          ]}
          idField={"id"}
          fieldsMap={[
            "name",
            "methodId",
            "description",
            "observations",
            "materials",
          ]}
          formFields={[
            {
              label: "Type of Packages",
              class: "col-12 col-md-6 px-0",
              required: true,
            },
            {
              label: "Method",
              class: "col-12 col-md-6 px-0",
              type: "select",
              defaultValue: prefilledMethod,
              options: [[0, "Select a Method"], ...getOptions(methods, "name")],
              required: true,
              onSelectedChange: (e) => setSelectedValue(parseInt(e)),
            },
            {
              label: "Description",
              class: "col-12 col-md-6 px-0",
              required: true,
            },
            {
              label: "Observations",
              class: "col-12 col-md-6 px-0",
              required: true,
            },

            {
              label: "Brand",
              class: "col-12 col-md-12 px-0",
              type: "custom",
              component: (
                <PackagesGenerator
                  dataType={materialsType}
                  data={materials}
                  defaultValue={defaultMaterial}
                  selectedValue={selectedValue}
                />
              ),
              required: true,
            },
          ]}
          detailFields={[
            { label: "Type of Packages", name: "name" },
            { label: "Method", name: "method" },
            { label: "Description", name: "description" },
            { label: "Observations", name: "observations" },
            {
              label: "Materials",
              name: "defaultMaterial",
              component: <DetailPackages materials={defaultMaterial} />,
            },
          ]}
          hasCustomFields={true}
          baseObject={{
            id: null,
            name: "",
            methodId: "",
            description: "",
            observations: "",
            materials: "",
            UserLogged: getSession(),
          }}
        />
      </PageGeneralContainer>
    </>
  );
}
