import { VisibilityOff, Visibility } from '@material-ui/icons'
import React, { useState } from 'react'


const ShowPassword = (props) => {
    const {parentCallback,style,} = props
    const [viewPassword, setViewPassword] = useState(false)
    function changePasswordView(){
    viewPassword ? setViewPassword(false) : setViewPassword(true)
   }
  return (
    <div>
       { viewPassword ? <Visibility  onClick={(e)=>{changePasswordView();parentCallback(); e.preventDefault()}} style={style}></Visibility> : <VisibilityOff onClick={(e)=>{changePasswordView(); parentCallback(); e.preventDefault()}} style={style}></VisibilityOff>}
    </div>
  )
}

export default ShowPassword