import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    root:{
        backgroundColor: theme.palette.terragene.lightMain,
        color: theme.palette.terragene.darkMain,
        fontWeight:"500",
    },

    groupedContainedSecondary:{
        backgroundColor: theme.palette.terragene.lightMain,
        color: theme.palette.terragene.darkMain,
        borderRadius:10,
    }
}));
