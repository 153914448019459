import React, { useState, useEffect, useRef, useContext } from "react";
import request from "../../utils/request";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { v4 as uuidv4 } from "uuid";

import IndicatorItem from "./IndicatorItem/IndicatorItem.jsx";
import ModalLoading from "../ModalLoading/ModalLoading";
import InputQ from "../FormComponents/InputQ/InputQ";
import styles from "./CrudIndicator.module.css";

import { getUserCompany } from "../../utils/sessionHandler";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import ColumnQ from "../ColumnQ/ColumnQ";

import chemicalImg from "../../assets/images/QUIMICO.svg";
import biologicalImg from "../../assets/images/INDICATORS.svg";

const CrudIndicator = ({
  indicators,
  handleIndicators,
  zone = false,
  machine,
  onHandleScan,
  modelAreas,
  methodId,
  select,
  condition,
  category = "sterilization",
}) => {
  const [searchType, setSearchType] = useState("productLot");
  const [product, setProduct] = useState("");
  const [lot, setLot] = useState("");
  const [serial, setSerial] = useState("");
  const [validateId, setValidateId] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const dropdownRef = useRef(null);

  const locale = useContext(LocalizationContext);

  const lotRef = useRef();
  const productRef = useRef();
  const serialRef = useRef();

  const handleSearchTypeChange = (value) => {
    setSearchType(value);
  };

  const handleFilterClick = (e, filter) => {
    e.preventDefault();

    if (filter === "productLot") {
      handleSearchTypeChange("productLot");
    } else if (filter === "serial") {
      handleSearchTypeChange("serial");
    }
  };

  const handleProduct = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setProduct(item);
  };

  const handleLot = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setLot(item);
  };

  const handleSerial = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setSerial(item);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (searchType === "serial" && !serial) {
      setValidateId(2);
      toast.warning(t("SerialEmpty", locale) || "The 'Serial' field is empty.");

      return;
    }

    if (searchType === "productLot" && (!product || !lot)) {
      setValidateId(2);
      toast.warning(
        t("BlankEntry5", locale) ||
          "You must enter both the 'Product' field and the 'Lot' field."
      );

      return;
    }
    await getIndicator();

    setProduct("");
    setLot("");
    setSerial("");
  };

  const urlLot = zone ? "lotForCharge" : category.toUpperCase() === "WASHING" ? "lotForPackageWashing" : "lotForPackage";
  const urlSerial = zone ? "serialForCharge" : "serialForPackage";

  const getIndicator = async () => {
    try {
      setModalLoading(true);
      let response;
      if (searchType === "serial") {
        response = await request().get(
          `/api/IndicatorSerial/${urlSerial}?serial=${serial}&distId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`
        );
      } else {
        response = await request().get(
          `/api/IndicatorLot/${urlLot}?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${methodId}`
        );
      }

      if (!response.data.id) {
        setValidateId(2);
        toast.error(t("IndicatorNotFound") || "Indicator Not Foud");
      } else if (
        indicators.find((i) => i.serial && i.serial === response.data.serial)
      ) {
        setValidateId(3);
        toast.error(
          t("SerializeIndicatorAlredyAdded", locale) ||
            "Serialize indicator already added"
        );
      } else {
        let newIndicator = response.data;
        if (!newIndicator.serial && !zone) {
          if (
            indicators.find(
              (i) =>
                i.indicator_Id === newIndicator.indicator_Id &&
                i.lot === newIndicator.lot
            )
          ) {
            indicators.find(
              (i) =>
                i.indicator_Id === newIndicator.indicator_Id &&
                i.lot === newIndicator.lot
            ).quantity++;
          } else {
            newIndicator = { ...newIndicator, quantity: 1 };
            setIndicator(newIndicator);
          }
        } else {
          setIndicator(newIndicator);
        }
        setValidateId(1);
        toast.success(t("IndicatorAdded", locale) || "Indicator Added");
      }
    } catch (error) {
      console.error(error.message);
      setValidateId(2);
      toast.error(error.response?.data.message);
    } finally {
      setModalLoading(false);
    }
  };

  const deleteIndicator = (id, ia) => {
    const newIndicators = indicators.filter((i) => i.uuid !== id);

    handleIndicators(newIndicators);
  };

  const selectIndicatorArea = (uuid, e, isPCD) => {
    let item = indicators.find((i) => i.uuid === uuid);
    item.modelAreaName = modelAreas.find((a) => a.id === e).description;
    item.modelAreasId = e;
  };

  useEffect(() => {
    if (indicator) {
      if (zone) {
        let zoneIndicator = {
          ...indicator,
          uuid: uuidv4(),
          modelAreasId: modelAreas[0]?.id,
          modelAreaName: modelAreas[0]?.description,
        };
        handleIndicators([...indicators, zoneIndicator]);
      } else {
        let newIndicator = { ...indicator, uuid: uuidv4() };

        handleIndicators([...indicators, newIndicator]);
      }
    }
  }, [indicator]);

  const handleDisabled = () => {
    if (!select) {
      toast.error(`First, you must select ${condition}`);
    }
  };

  return (
    <div
      className={`${styles.crud} ${!select ? styles.disabled : ""}`}
      onClick={handleDisabled}
    >
      <ColumnQ
        title={t("MonitoringIndicators")}
        img={
          category.toUpperCase() === "WASHING"
            ? chemicalImg
            : zone
            ? biologicalImg
            : chemicalImg
        }
      >
        <ModalLoading open={modalLoading} />
        <div className={styles.crud}>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className={`${styles.formContainer} ${
              !select ? styles.disabled : ""
            }`}
          >
            <div className={styles.buttonContainer}>
              <p
                className={`${styles.button} ${
                  searchType === "productLot" ? styles.active : ""
                } ${category.toUpperCase() === "WASHING" ? styles.purple : styles.green}`}
                onClick={(e) => handleFilterClick(e, "productLot")}
              >
                {t("ProductAndLotSpan", locale) || "Product and Lot"}
              </p>
              <p
                className={`${styles.button} ${
                  searchType === "serial" ? styles.active : ""
                } ${category.toUpperCase() === "WASHING" ? styles.purple : styles.green}`}
                style={{
                  userSelect: "none",
                  pointerEvents: "none",
                  cursor: "not-allowed",
                }}
                onClick={(e) => handleFilterClick(e, "serial")}
              >
                {t("SerialSpan", locale) || "Serial"}
              </p>
            </div>
            <div className={styles.inputContainer}>
              {searchType === "productLot" && (
                <>
                  <div className={styles.widthInput}>
                    <InputQ
                      placeholder={t("Product", locale) || "Product"}
                      value={product}
                      handleChange={handleProduct}
                      containerWidth={"47%"}
                      inputRef={productRef}
                      onHandleScan={onHandleScan}
                    />
                    <InputQ
                      placeholder={t("Lot", locale) || "Lot"}
                      value={lot}
                      handleChange={handleLot}
                      containerWidth={"47%"}
                      inputRef={lotRef}
                      onHandleScan={onHandleScan}
                    />
                  </div>
                  <button
                    className={`${styles.iconButton} ${
                      category.toUpperCase() === "WASHING"
                        ? styles.purpleIcon
                        : styles.greenIcon
                    }`}
                    aria-label="add"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <FaPlus className={`${styles.addIcon}`} />
                  </button>
                </>
              )}
              {searchType === "serial" && (
                <>
                  <div className={styles.widthInput}>
                    <InputQ
                      placeholder={t("Serial", locale) || "Serial"}
                      value={serial}
                      handleChange={handleSerial}
                      containerWidth={"100%"}
                      inputRef={serialRef}
                      onHandleScan={onHandleScan}
                    />
                  </div>
                  <button
                    className={styles.iconButton}
                    aria-label="add"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <FaPlus />
                  </button>
                </>
              )}
            </div>
          </form>
          <div className={styles.itemList}>
            {indicators.map((indicator) => (
              <IndicatorItem
                indicator={indicator}
                modelAreas={modelAreas}
                zone={zone}
                deleteIndicator={deleteIndicator}
                selectIndicatorArea={selectIndicatorArea}
                isPCD={indicator.pcd}
                category={category}
              />
            ))}
          </div>
        </div>
      </ColumnQ>
    </div>
  );
};

export default CrudIndicator;
