import React, { useContext, useEffect, useMemo, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../components/PageContainer/PageContainer";
import TableContainerQ from "../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TableFilterQ from "../../components/TableBionovaQ/TableFilterQ/TableFilterQ.jsx";

import ManageDetail from "../../components/ManageDetail/ManageDetail.jsx";
import useTableQ from "../../utils/Hooks/useTableQ";
import LocalizationContext from "../../utils/LocalizationContext";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import Redirect from "../../utils/Redirect";
import filterByProperties from "../../utils/filterByProperties.js";
import styles from "./DetailPackage.module.css";
import pkgImg from "../../assets/images/PACKAGE IN A LOAD.svg";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";
import { getRegionalFormat } from "../../utils/sessionHandler.js";
import parseDf from "../../utils/parseDf.js";
import FilterDate from "../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../utils/printTicket.js";
import useConfirmation from "../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";

export default function ConsultPackages() {
  const [packages, setPackages] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [status, setStatus] = useState(null);
  const [filters, setFilters] = useState([
    { property: "creationDate", value: "" },
    { property: "description", value: "" },
    { property: "method.name", value: "" },
    { property: "packageStatus", value: "" },
  ]);

  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  const getPackages = async () => {
    await request()
      .get(`/api/package`)
      .then((response) => {
        setPackages(response.data.filter((p) => p.packageStatus === 3));
      });
  };

  const getStatusName = (id) => {
    return id === 3 ? "Finish" : "Ready";
  };

  const getStatusColor = (id) => {
    return id === 3 ? "green" : "green";
  };

  const onEdit = (id) => {
    localStorage.setItem("packageEditId", id);
    Redirect({
      redirect: true,
      path: "/appdetailpackage",
    });
  };

  // const onDelete = (id) => {
  //   confirm({
  //     title: "Delete Package",
  //     text: "Are you sure you want to delete this package?",
  //     icon: "warning",
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "Cancel",
  //     onConfirm: () => handleDelete(id),
  //   });
  // };

  // const handleDelete = (id) => {
  //   request()
  //     .delete(`/api/package`, packages.find((pkg) => pkg.id === id))
  //     .then(() => {
  //       const newData = packages.filter((pkg) => pkg.id !== id);
  //       setPackages(newData);
  //       setPackageDetail(null);
  //     });
  // };

  const handlePackageDetail = (id) => {
    let item = packages.find((p) => p.id === id);
    setPackageDetail(item);
  };

  const handleFilterChange = (property, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.property === property ? { ...filter, value } : filter
      )
    );
  };

  useEffect(() => {
    getPackages();
  }, []);

  const filteredData = useMemo(
    () => filterByProperties(packages, filters),
    [packages, filters]
  );

  const {
    currentPage,
    currentItems,
    totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(filteredData, [
    "creationDate",
    "description",
    "methodId",
    "packageStatus",
  ]);

  const handleChangeDateTo = (value) => {
    setDateTo(value);
  };

  const handleChangeDateFrom = (value) => {
    setDateFrom(value);
  };

  const actualizedData = useMemo(() => {
    if (!isNaN(Date.parse(dateFrom)) || !isNaN(Date.parse(dateFrom))) {
      return currentItems.filter(
        (p) =>
          parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) >=
            parseDf(df(dateFrom, "date"), getRegionalFormat().dateFormat) &&
          parseDf(df(p.creationDate, "date"), getRegionalFormat().dateFormat) <=
            parseDf(df(dateTo, "date"), getRegionalFormat().dateFormat) &&
          (status ? p.packageStatus == status : true)
      );
    } else if (status) {
      return currentItems.filter((p) => p.packageStatus == status);
    } else {
      return currentItems;
    }
  }, [dateTo, dateFrom, currentItems, status]);

  const handleStatus = (value) => {
    setStatus(value.target.value);
  };

  const arrayStatus = [
    { value: 1, name: "In Progress" },
    { value: 2, name: "Ready" },
    { value: 3, name: "Finish" },
  ];

  const showPrintAlert = (packageDetail) => {
    Swal.fire({
      html: `
        <div class="${styles.containerPrint}">
          <div class="${styles.label}">
            <p class="${styles.line}">PKG-${packageDetail?.id} ${
        packageDetail?.description
      }</p>
            <p class="${styles.line}">Method: ${packageDetail?.method.name}</p>
            <p class="${styles.line}">Operator: ${
        packageDetail?.editUserName
          ? packageDetail?.editUserName
          : packageDetail?.creationUser
      }</p>
            <p class="${styles.line}">Date: ${df(
        packageDetail?.creationDate,
        "dateTime"
      )}</p>
            <p class="${styles.line}">Observations: ${
        packageDetail?.observations
      }</p>
            <hr class="${styles.divider}"/>
            <div class="${styles.barcode}">
              <p>PKG${packageDetail?.id}</p>
            </div>
          </div>
        </div>
      `,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: "Print",
      customClass: {
        popup: styles.popup,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  return (
    <PageContainer
      category={"Sterilization"}
      backUrl={"/appsterilization"}
      title={"Consult Packages"}
      // newButton={true}
      // newButtonUrl={"/appcreatepackages"}
    >
      <div className={styles.detailContainer}>
        <ManageDetail pkg={packageDetail} />
      </div>
    </PageContainer>
  );
}
