import React, { useState, useEffect, useRef, useContext, useMemo } from "react";

import request from "../../utils/request";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import BarcodeReader from "react-barcode-reader";
import { parseBarcode } from "gs1-barcode-parser-mod";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import printTicket from "../../utils/printTicket";

import ModalSerial from "../../components/ModalSerial/ModalSerial";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DragAndDrop from "../../components/DragAndDrop/DDPackages.jsx";
import Search from "../../components/Search/Search";
import CrudIndicator from "../../components/CrudIndicator/CrudIndicator.jsx";

import { getUserCompany } from "../../utils/sessionHandler";
import { PrintLabelContext } from "../../utils/context/PrintLabelContext";
import PageContainer from "../../components/PageContainer/PageContainer.jsx";
import SelectQ from "../../components/FormComponents/SelectQ/SelectQ.jsx";

import styles from "./CreatePackage.module.css";
import InputQ from "../../components/FormComponents/InputQ/InputQ.jsx";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextAreaQ from "../../components/FormComponents/TextAreaQ/TextAreaQ.jsx";
import PackageLabel from "../../components/packageLabel/PackageLabel.jsx";
import { Checkbox } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

// import { IoIosArrowBack } from "react-icons/io";
// import Redirect from "../../utils/Redirect";

const MySwal = withReactContent(Swal);

export default function CreatePackages() {
  const [indicators, setIndicators] = useState([]);
  const [observations, setObservations] = useState("");
  const [description, setDescription] = useState("");
  const [materialsTypes, setMaterialsTypes] = useState([]);
  const [indicatorProducts, setIndicatorProducts] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedFilteredMaterials, setSelectedFilteredMaterials] = useState(
    []
  );
  const [packagesTypes, setPackagesTypes] = useState([]);
  const [predefinedPackageId, setPredefinedPackageId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [packageType, setPackageType] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [responseWithId1, setResponseWithId1] = useState(null);
  const [responseWithId2, setResponseWithId2] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [scanCode, setScanCode] = useState("");
  const [validate, setValidate] = useState(false);
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [waitMethod, setWaitMethod] = useState(null);
  const [stopButtons, setStopButtons] = useState(false);

  const locale = useContext(LocalizationContext);

  const { printLabel, handlePrintLabel } = useContext(PrintLabelContext);

  const descriptionRef = useRef();
  const observationsRef = useRef();

  const clearFields = (methodChange = true) => {
    methodChange && setDescription("");
    setObservations("");
    setIndicators([]);
    methodChange && setEditId(null);
    setSelectedMaterials([]);
    methodChange && setSelectedMethod("");
    setPredefinedPackageId("");
    getMaterials();
  };

  const validateFields = (status) => {
    let notValid = false;

    if (!description.length > 0 || description.trim() === "") {
      notValid = true;
    }

    if (!selectedMethod || selectedMethod === "") {
      notValid = true;
    }

    if (status !== 1 && !selectedMaterials.length > 0) {
      notValid = true;
    }

    // if (!predefinedPackageId && predefinedPackageId === "") {
    //   notValid = true;
    // }

    return notValid;
  };

  const handleSelectOption = (option) => {
    setShowModal(false);

    if (option === responseWithId1) {
      setIndicators([...indicators, option]);
    } else {
      setSelectedMaterials([...selectedMaterials, option]);
    }
    setResponseWithId1(null);
    setResponseWithId2(null);
  };

  const handleSelectMethod = async (e) => {
    const newMethod = e.target.value;

    if (
      selectedMethod &&
      (selectedMaterials.length > 0 || indicators.length > 0)
    ) {
      const result = await MySwal.fire({
        title: t("UnsavedDataAlertSpan") || "Unsaved data will be lost",
        text:
          t("MaterialAndIndicatorLostAlert", locale) ||
          "The selected materials and indicators will be lost. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("ConfirmButton", locale) || "Confirm",
        cancelButtonText: t("CancelButton", locale) || "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      });

      if (result.isConfirmed) {
        confirmChangeMethod(newMethod);
      }
    } else {
      setSelectedMethod(newMethod);
    }
  };

  const handleSelectPredifinedPackage = async (e) => {
    const newData = e.target.value;
    const item = packagesTypes.find((p) => p.value == e.target.value);

    if ((predefinedPackageId || materials) && selectedMaterials.length > 0) {
      const result = await MySwal.fire({
        title: t("LostSelectedDataTitle", locale) || "Materials will be lost",
        text:
          t("LostSelectedData", locale) ||
          "The selected materials will be lost. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("ConfirmButton", locale) || "Confirm",
        cancelButtonText: t("CancelButton", locale) || "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      });

      if (result.isConfirmed) {
        confirmChangeMethod(selectedMethod);
        setPredefinedPackageId(newData);
        setDescription(item ? item.name : "");
        setSelectedMethod(item ? item.methodId : "");
      }
    } else {
      setPredefinedPackageId(newData);
      setDescription(item ? item.name : "");
      setSelectedMethod(item ? item.methodId : "");
    }
  };

  async function fetchData(url, setResponse) {
    try {
      setModalLoading(true);
      const response = await request().get(url);
      const condition =
        response !== null &&
        typeof response !== "undefined" &&
        response.data?.id;

      if (condition) {
        setResponse(response.data);
      }

      return { response, condition };
    } catch (e) {
      toast.error(e.response.data.message);
      return { response: null, condition: false };
    } finally {
      setModalLoading(false);
    }
  }

  function checkIfExists(serial, array) {
    return array.find((item) => item.serial === serial);
  }

  // async function handleData(data) {
  //   try {
  //     const { response: response1, condition: indicatorCondition } =
  //       await fetchData(
  //         `/api/IndicatorSerial/serialForPackage
  //         ?serial=${data}&distId=${
  //           getUserCompany().DistributorId
  //         }&methodId=${selectedMethod}`,
  //         toast,
  //         setResponseWithId1
  //       );

  //     const indicatorSerialExist = checkIfExists(
  //       response1?.data?.serial,
  //       indicators
  //     );

  //     if (indicatorCondition) {
  //       setScanCode(data);

  //       setIndicators(
  //         indicatorSerialExist
  //           ? indicators.filter((i) => i.serial !== response1.data.serial)
  //           : [...indicators, response1.data]
  //       );
  //       toast.success(t("IndicatorAdded", locale) || "Indicator added");
  //     } else {
  //       toast.error(
  //         t("SerialNumberNotFound", locale) || "Serial Number Not Found"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error al manejar los datos:", error);
  //   } finally {
  //     setResponseWithId1(null);
  //     setResponseWithId2(null);
  //     setModalLoading(false);
  //   }
  // }

  useEffect(() => {
    setDescription(description.replace(scanCode, ""));
    setScanCode("");
  }, [scanCode]);

  const packageEditId = localStorage.getItem("packageEditId");

  const getMaterials = async () => {
    setModalEditLoading(true);
    await request()
      .get("/api/Material/Filtered")
      .then((response) => {
        let newMaterials = [];
        response.data.map(
          (material) =>
            (newMaterials = [
              ...newMaterials,
              {
                ...material,
                materialType: findMaterialType(material.materialTypeId),
              },
            ])
        );

        setMaterials(newMaterials);
        setFilteredMaterials(newMaterials);
        setModalEditLoading(false);
      });
  };

  const getMethods = () => {
    request()
      .get("/api/method/1")
      .then((response) => {
        const newMethods = response.data.map((item) => ({
          value: item.id,
          name: item.name,
        }));
        setMethods(newMethods);
      });
  };

  const getMaterialsTypes = () => {
    request()
      .get("/api/materialType")
      .then((response) => setMaterialsTypes(response.data));
  };

  const getIndicatorsProduct = () => {
    request()
      .get("/api/product")
      .then((response) => setIndicatorProducts(response.data));
  };

  const getPackagesTypes = () => {
    request()
      .get("/api/predefinedpackage/")
      .then((response) => {
        const newData = [
          ...response.data.map((p) => ({
            name: p.name,
            value: p.id,
            methodId: p.method.id,
          })),
        ];
        setPackagesTypes(newData);
      });
  };

  const getPrintPackage = (id) => {
    if (!printLabel) return;
    request()
      .get(`/api/package/info?packageId=${id}`)
      .then((res) => {
        showPrintAlert(res.data);
      });
  };

  const showPrintAlert = (packageDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel packageDetail={packageDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: t("Print"),
      customClass: {
        popup: "your-popup-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(packageDetail);
      }
    });
  };

  const getEditPackage = () => {
    try {
      setModalEditLoading(true);

      request()
        .get(`/api/package/info?packageId=${packageEditId}`)
        .then((response) => {
          setDescription(response.data.description);
          setObservations(response.data.observations);
          setIndicators(
            response.data.indicators.map((i) => ({
              ...i,
              indicator: { name: i.indicatorName },
              indicator_Id: i.indicatorId,
              uuid: uuidv4(),
            }))
          );
          setEditId(response.data.packageId);
          setSelectedMaterials(
            response.data.materials.map((m) => ({
              ...m,
              name: m.materialName,
              serial: m.materialSerial,
              materialType: { name: m.materialTypeName },
              id: m.materialId,
            }))
          );
          setSelectedMethod(response.data.methodId);
          setPredefinedPackageId(response.data.predefinedPackageId);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setModalEditLoading(false);
      localStorage.removeItem("packageEditId");
    }
  };

  const findMaterial = (id) => materials.find((m) => m.id === id);
  const findIndicatorProduct = (id) =>
    indicatorProducts.find((ip) => ip.id === id);
  const findMaterialType = (id) => materialsTypes.find((mt) => mt.id === id);

  const handleMaterials = (arr) => setFilteredMaterials(arr);
  const handlePackageType = (e) => setPredefinedPackageId(e.target.value);
  const handleSelectedMaterials = (data) => setSelectedMaterials(data);
  const handleSelectedFilteredMaterials = (data) =>
    setSelectedFilteredMaterials(data);
  const handleSearchActive = (bool) => setSearchActive(bool);
  const handleIndicators = (data) => setIndicators(data);
  const handleChangeObservations = (e, scan = false) => {
    let item = scan ? e : e.target.value;
    setObservations(item);
  };

  const handleChangeDescription = (value, scan = false) => {
    if (!(value.target?.value?.length == 0 && !validate)) {
      setValidate(false);
    }
    const inputValue = scan ? value : value.target.value;
    setDescription(inputValue);
  };

  const onClearPackage = () => setPackageType([]);

  function formatIndicators(array) {
    let resultadoFinal = [];

    array.forEach((objeto) => {
      if (objeto.quantity > 1) {
        for (let i = 0; i < objeto.quantity; i++) {
          resultadoFinal.push({ ...objeto, quantity: 1 });
        }
      } else {
        resultadoFinal.push(objeto);
      }
    });

    return resultadoFinal;
  }
  const createPackage = async (status) => {
    const newMaterials = selectedMaterials.map((material) => ({
      materialId: material.id,
      quantity: material.quantity || 1,
    }));

    const newIndicators = indicators.map((indicator) => ({
      indicatorId: indicator.indicator_Id,
      serial: indicator.serial || null,
      lot: indicator.lot || null,
      quantity: indicator.quantity || 1,
    }));

    const requestData = {
      observations,
      description,
      packageStatus: status,
      materials: newMaterials,
      indicators: formatIndicators(newIndicators),
      methodId: selectedMethod,
      predefinedPackageId: parseInt(predefinedPackageId) ?? predefinedPackageId ,
    };

    try {
      setModalLoading(true);
      setStopButtons(true);
      if (!editId) {
        await request()
          .post("/api/package", requestData)
          .then((res) =>
            status === 1 ? setEditId(res.data) : getPrintPackage(res.data)
          );

        status === 1
          ? toast.success(
              t("PackageSaved", locale) || "The Package was successfully saved"
            )
          : toast.success(
              t("PackageCompleted", locale) ||
                "The package was successfully completed"
            );
      } else {
        requestData.id = editId;
        await request()
          .put("/api/package", requestData)
          .then((res) => status !== 1 && getPrintPackage(res.data));
        toast.success(
          t("PackageModified", locale) ||
            "The package was successfully modified"
        );
      }

      status !== 1 && clearFields(true);
    } catch (e) {
      toast.error(e.response.data.message);
    } finally {
      setModalLoading(false);
      setStopButtons(false);
    }
  };

  // useEffect(() => {
  //   if (predefinedPackageId || predefinedPackageId === "reset") {
  //     request()
  //       .get(`/api/predefinedpackage/${predefinedPackageId}`)
  //       .then((response) => {
  //         let newMaterials = [];
  //         console.log(response.data)
  //         response.data.materials.map((material) => {
  //           let item = materials.find((m) => m.id === material.materialId);
  //           if (item)
  //             newMaterials = [
  //               ...newMaterials,
  //               findMaterial(material.materialId),
  //             ];
  //         });

  //         setPackageType(newMaterials);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         setPackageType([]);
  //       });
  //   }
  // }, [predefinedPackageId]);

  useEffect(() => {
    if (predefinedPackageId || predefinedPackageId === "reset") {
      request()
        .get(`/api/predefinedpackage/${predefinedPackageId}`)
        .then((response) => {
          let newMaterials = [];

          response.data.materials.forEach((material) => {
            let item = materials.find((m) => m.id === material.materialId);
            if (item) {
              // Aquí incluimos la propiedad quantity en el nuevo objeto
              newMaterials.push({
                ...findMaterial(material.materialId),
                quantity: material.quantity,
              });
            }
          });
          setPackageType(newMaterials);
        })
        .catch((e) => {
          console.log(e);
          setPackageType([]);
        });
    }
  }, [predefinedPackageId]);

  useEffect(() => {
    setFilteredMaterials(predefinedPackageId ? packageType : materials);
  }, [predefinedPackageId]);

  useEffect(() => {
    getMaterialsTypes();
    getIndicatorsProduct();
    getPackagesTypes();
    getMethods();
    getEditPackage();
  }, []);

  useEffect(() => {
    getMaterials();
  }, [materialsTypes]);

  const packageTypeTranslate =
    t("SelectPredifinedPackage", locale) || "Select a predifined package";

  const addScanIndicator = (indicator) => {
    if (indicators.find((i) => i.id === indicator.id)) {
      indicators.find((i) => i.id === indicator.id).quantity++;
      toast.success(t("IndicatorAdded", locale) || "Indicator Added");
    } else {
      indicator = { ...indicator, quantity: 1 };
      setIndicators([...indicators, indicator]);
      toast.success(t("IndicatorAdded", locale) || "Indicator Added");
    }
  };

  console.log(indicators);

  const onHandleScan = async (data) => {
    let itemMaterial = materials.find((m) => m.serial == data);
    let materialCondition = selectedMaterials.find((m) => m.serial == data);
    if (itemMaterial) {
      setScanCode(data);
      if (materialCondition) {
        setSelectedMaterials(
          selectedMaterials.filter((m) => m != itemMaterial)
        );
        toast.success(t("MaterialRemoved", locale) || "Material removed");
      } else {
        setSelectedMaterials([...selectedMaterials, itemMaterial]);
        toast.success(t("MaterialAdded", locale) || "Material added");
      }
    } else {
      try {
        const variableField = parseBarcode(data).parsedCodeItems[3];
        const Gtin = parseBarcode(data).parsedCodeItems[0];
        let lot = "";

        const handleResponse = async (url) => {
          const response = await request().get(url);
          if (response.data.id) {
            setScanCode(data);
            let newIndicator = response.data;
            addScanIndicator(newIndicator);

            setDescription(description.replace(data, ""));
          }
        };

        if (variableField.ai === "10") {
          const url = `/api/indicatorLot/GTINforPackage?gtin=${Gtin.data}&lot=${
            variableField.data
          }&disId=${getUserCompany().DistributorId}&methodId=${selectedMethod}`;
          await handleResponse(url);
        } else if (variableField.data.includes("PANTONE")) {
          let match = variableField.data.match(/(.{6})95PANTONE/);
          if (match) {
            lot = match[1];
          }
          const url = `/api/indicatorLot/GTINforPackage?gtin=${
            Gtin.data
          }&lot=${lot}&disId=${
            getUserCompany().DistributorId
          }&methodId=${selectedMethod}`;
          console.log(variableField, Gtin, lot);
          await handleResponse(url);
        } else {
          const answer = variableField.data;

          let product;

          const indexA = answer.lastIndexOf("A");
          const indexF = answer.lastIndexOf("F");
          const indexB = answer.lastIndexOf("B");
          const indexBT = answer.lastIndexOf("BT");
          const indexPCD = answer.lastIndexOf("PCD");
          const indexCDWA = answer.lastIndexOf("CDWA");

          if (indexA < indexB) {
            if (indexB !== -1) {
              lot = answer.substring(indexB);
              const startAfterB = indexB - 2;

              if (indexCDWA !== -1) {
                product = answer.substring(indexCDWA, startAfterB);
                console.log(product);
              }
            }
          } else if (indexA > indexF) {
            if (indexA !== -1) {
              lot = answer.substring(indexA);

              const startAfterA = indexA - 2;

              if (indexBT !== -1) {
                product = answer.substring(indexBT, startAfterA);
              }
            }
          } else if (indexA < indexF) {
            if (indexF !== -1) {
              lot = answer.substring(indexF);
              const startAfterF = indexF - 2;

              if (indexPCD !== -1) {
                product = answer.substring(indexPCD, startAfterF);
              }
            }
          }

          const url = `/api/IndicatorLot/lotForPackage?Product=${product}&lot=${lot}&distId=${
            getUserCompany().DistributorId
          }&methodId=${selectedMethod}`;

          await handleResponse(url);
        }
      } catch (e) {
        toast.error(e.response?.data?.message);
      } finally {
        setModalLoading(false);
      }
    }
  };

  const differsMethod = useMemo(
    () =>
      selectedMaterials
        .filter((m) => m.methodId != selectedMethod)
        .map((m) => <li>{m.name}</li>),
    [selectedMaterials, selectedMethod]
  );

  const finishPackage = () => {
    if (differsMethod.length > 0) {
      MySwal.fire({
        title:
          t("DiffersMethodTitle", locale) ||
          "Materials with different sterilization methods",
        text:
          t("DiffersMethodMessage", locale) ||
          "There are materials whose sterilization method does not match the sterilization method in the package. Do you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("ConfirmButton", locale) || "Confirm",
        cancelButtonText: t("CancelButton", locale) || "Cancel",
        customClass: {
          confirmButton: "confirmButton",
          cancelButton: "cancelButton",
        },
        confirmButtonColor: "var(--primary-green)",
        cancelButtonColor: "var(--delete)",
        backgroundColor: "#f4f4f4",
      }).then((result) => {
        if (result.isConfirmed) {
          createPackage(2);
        }
      });
    } else {
      createPackage(2);
    }
  };

  const confirmChangeMethod = (data) => {
    setSelectedMethod(data);
    clearFields(false);
  };

  const showValidationErrors = (status) => {
    if (!description.length > 0 || description.trim() === "") {
      toast.error(t("NameRequired", locale) || "The Name field is required");
    }
    if (!selectedMethod || selectedMethod === "") {
      toast.error(t("YouMustSelectAMethod") || "You must select a Method");
    }
    if (
      status !== 1 &&
      (!selectedMaterials || selectedMaterials.length === 0)
    ) {
      toast.error(
        t("YouMustAddMaterials", locale) || "You must add an Materials"
      );
    }
  };

  const handleButtonClick = async (status) => {
    const notValid = validateFields(status);

    if (notValid) {
      setValidate(notValid);
      showValidationErrors(status);
    } else if (status == 1) {
      createPackage(status);
    } else finishPackage();
  };
  const isSaveDisabled = useMemo(
    () => validateFields(1),
    [description, selectedMethod]
  );
  const isFinishDisabled = useMemo(
    () => validateFields(2),
    [description, selectedMethod, selectedMaterials]
  );

  return (
    <PageContainer
      categoryId={1}
      currentStep={1}
      category={t("SterilizationSpan")}
      subcategory={t("PackageSpan")}
      title={t("CreateNewPackage", locale)}
      backUrl={"/appmanagepackages"}
      scan={true}
      progress={true}
    >
      {/* <IoIosArrowBack
    className={styles.backIcon}
    onClick={() =>
      Redirect({
            redirect: true,
            path: "/appmanageloads",
          })
    }
    /> */}
      <BarcodeReader onScan={onHandleScan} />

      <ModalLoading open={modalLoading} />
      <ModalLoading open={modalEditLoading} />
      <ModalSerial
        showModal={showModal}
        setShowModal={setShowModal}
        option1={responseWithId1}
        option2={responseWithId2}
        onSelectOption={handleSelectOption}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "68%",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <SelectQ
                  label={packageTypeTranslate}
                  placeholder={packageTypeTranslate}
                  selectValue={predefinedPackageId}
                  options={packagesTypes}
                  // handleSelect={handlePackageType}
                  handleSelect={handleSelectPredifinedPackage}
                  containerWidth={"100%"}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <label className={styles.printLabel}>
                  {t("PrintLabelSpan")}
                </label>
                <Checkbox
                  type="checkbox"
                  checked={printLabel}
                  onChange={handlePrintLabel}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <InputQ
                label={t("Name", locale) || "Name"}
                handleChange={handleChangeDescription}
                value={description}
                containerWidth={"50%"}
                inputRef={descriptionRef}
                required={true}
                errorMessage={
                  t("RequiredFieldError", locale) || "This field is required"
                }
                validate={validate}
                onHandleScan={onHandleScan}
              />

              <SelectQ
                selectValue={selectedMethod}
                handleSelect={handleSelectMethod}
                options={methods}
                label={t("SelectMethodOption")}
                placeholder={t("SelectMethodOption")}
                containerWidth={"50%"}
                required={true}
                errorMessage={
                  t("RequiredFieldError", locale) || "This field is required"
                }
                validate={validate}
              />
            </div>
          </div>

          <DragAndDrop
            filter1={
              <Search
                data={predefinedPackageId ? packageType : materials}
                handleFilter={handleMaterials}
                placeholder={
                  t("SearchMaterial", locale) || "Search material..."
                }
                searchActive={() => {}}
                onHandleScan={onHandleScan}
              />
            }
            filter2={
              <Search
                data={selectedMaterials}
                handleFilter={handleSelectedFilteredMaterials}
                placeholder={
                  t("SearchAddedMaterial", locale) || "Search added material..."
                }
                searchActive={handleSearchActive}
                onHandleScan={onHandleScan}
              />
            }
            data={filteredMaterials}
            predifinedData={packageType}
            selectedData={selectedMaterials}
            selectedFilteredData={
              searchActive ? selectedFilteredMaterials : selectedMaterials
            }
            handleSelectedData={handleSelectedMaterials}
            onClearPredifinedData={onClearPackage}
            method={parseInt(selectedMethod)}
            leftColumnName={t("MaterialToInclude", locale)}
            rightColumnName={t("MaterialIncluded", locale)}
            category={"Sterilization"}
          />
        </div>
        <div style={{ width: "30%", minHeight: "100%" }}>
          <CrudIndicator
            indicators={indicators}
            handleIndicators={handleIndicators}
            onHandleScan={onHandleScan}
            methodId={selectedMethod}
            select={selectedMethod ? true : false}
            condition={"a method"}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1.75rem",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <TextAreaQ
          label={t("ObservationSpan") || "Observations"}
          handleChange={handleChangeObservations}
          value={observations}
          width={"68%"}
          inputRef={observationsRef}
          onHandleScan={onHandleScan}
        />

        <div className={styles.containerButtons}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => clearFields()}
          >
            {t("ClearFields", locale) || "Clear Fields"}
          </button>
          <button
            className={`${styles.button} ${styles.save} ${
              isSaveDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            {t("Save", locale) || "Save"}
          </button>
          <button
            className={`${styles.button} ${styles.finish} ${
              isFinishDisabled || stopButtons ? styles.disabled : ""
            }`}
            onClick={() => handleButtonClick(2)}
            // onClick={() => finishPackage()}
          >
            {t("FinishPackage", locale) || "Finish Package"}
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
