// import React, { useState, useContext } from "react";
// import request from "../../../utils/request";
// import LocalizationContext from "../../../utils/LocalizationContext";

// import Input from "../../Input";
// import Progress from "../../Progress";
// import Button from "../../Button";
// import { getDist } from "../../../utils/sessionHandler";
// import Button2 from "../../Button2";

// export default function ResetPassword(props) {
//   const { classes, dispatch, email, id, images } = props;

//   const [newPassword, setNewPassword] = useState("");
//   const [repeatNewPassword, setRepeatNewPassword] = useState("");
//   const [error, setError] = useState({ message: null, type: null });
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);

//   const locale = useContext(LocalizationContext);

//   const checkRequiredFields = () => {
//     if (email.length === 0) {
//       setError({ type: "email", message: "This field is required" });
//       return false;
//     }

//     if (newPassword.length === 0) {
//       setError({ type: "newPassword", message: "This field is required" });
//       return false;
//     }

//     if (repeatNewPassword !== newPassword) {
//       setError({
//         type: "repeatPassword",
//         message: "The passwords do not match."
//       });
//       return false;
//     }

//     return true;
//   };

//   const resetPasswordSubmit = e => {
//     document.activeElement.blur();
//     e.preventDefault();

//     if (!checkRequiredFields()) {
//       return;
//     }

//     setLoading(true);

//     if (success) {
//       dispatch({ type: "loginClick" });
//       return;
//     }

//     const user = {
//       AppUser: {
//         email: email,
//         id: id
//       },
//       Password: newPassword,
//       DistributorId: getDist().id
//     };

//     request()
//       .post(`/api/appuser/restorepassword`, user)
//       .then(response => {
//         setSuccess(true);
//         setLoading(false);
//         setError({ type: "success", message: response.data });
//       })
//       .catch(error => {
//         if (!error.response) {
//           return;
//         }
//         console.log(error.response.data.message);
//         setError({ type: "error", message: error.response.data.message });
//         setLoading(false);
//       });
//   };

//   return (
//     <form className={classes.loginForm} onSubmit={e => resetPasswordSubmit(e)}>
//       <Progress class={classes.loading} active={loading} />
//       <div>
//         <img
//           src={images.logo}
//           alt=""
//           className={classes.logoTerragene}
//         ></img>
//       </div>
//       <h1 className={classes.loginText}>{locale.header.changePassword}</h1>
//       {(error.type === "error" || error.type === "success") && (
//         <div
//           className={`alert ${
//             error.type === "error" ? "alert-danger" : "alert-primary"
//           }`}
//           role="alert"
//         >
//           {error.message}
//         </div>
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.email}
//           value={email}
//           onChange={value => {
//             dispatch({ type: "setEmail", payload: value });
//             error.type === "email" &&
//               setError({ message: null, type: null });
//           }}
//           type={"text"}
//           error={error.type === "email" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.newPassword}
//           value={newPassword}
//           onChange={value => {
//             setNewPassword(value);
//             error.type === "newPassword" &&
//               setError({ message: null, type: null });
//           }}
//           type={"password"}
//           error={error.type === "newPassword" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.repeatNewPassword}
//           value={repeatNewPassword}
//           onChange={value => {
//             setRepeatNewPassword(value);
//             error.type === "repeatPassword" &&
//               setError({ message: null, type: null });
//           }}
//           type={"password"}
//           error={error.type === "repeatPassword" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       <Button2
//         type={"submit"}
//         caption={
//           success ? locale.form.submit.login : locale.form.submit.changePassword
//         }
//         disabled={loading}
//       />
//     </form>
//   );
// }


////////////////////////// RESETPASSWORD ///////////////////////7

import React, { useState, useContext, useEffect } from "react";
import request from "../../../utils/request";
import LocalizationContext from "../../../utils/LocalizationContext";

import Progress from "../../Progress";
import Button from "../../Button";
import { getDist } from "../../../utils/sessionHandler";
import Button2 from "../../Button2";
import InputQ from "../../FormComponents/InputQ/InputQ.jsx";
import ButtonQ from "../../ButtonQ"
import ViewPasswordButton from "../UserResetPassword/ViewPasswordButton.js";
import isoLogoQ from "../../../assets/images/ISOLOGO-BIONOVAQ.svg"

export default function ResetPassword(props) {
  const { classes, dispatch, email, id, images } = props;

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [error, setError] = useState({ message: null, type: null });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const locale = useContext(LocalizationContext);

  const iconStyles = {
    iconStyle1: {
      position:'relative',
      display:'block',
      bottom:  error.type === "newPassword" ? '65px' : '40px',
      color: 'black',
      cursor:'pointer',
      height:'20px',
      left:'470%'
    },
    iconStyle2: {
      position:'relative',
      display:'block',
      bottom:  error.type === "repeatPassword" ? '65px' : '40px',
      color: 'black',
      cursor:'pointer',
      height:'20px',
      left:'470%'
    },
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
      return regex.test(password);
    };

  const checkRequiredFields = () => {
    if (email.length === 0) {
      setError({ type: "email", message: "This field is required" });
      setValidationMessage("This field is required" ); 
      return false;
    }

    if (newPassword.length === 0) {
      setError({ type: "newPassword", message: "This field is required" });
      setValidationMessage("This field is required" ); 
      return false;
    }
    if (!validatePassword(newPassword)) {
      setError({
          type: "newPassword",
          message: "8+ characters,1 uppercase letter,1 number."
      });
      setValidationMessage("8+ characters,1 uppercase letter,1 number.");
      return false;
  }

    if (repeatNewPassword.length === 0) {
      setError({ type: "repeatPassword", message: "This field is required" });
      setValidationMessage("This field is required" ); 
      return false;
    }

    if (repeatNewPassword !== newPassword) {
      setError({
        type: "repeatPassword",
        message: "The passwords do not match."
      });
      setValidationMessage("The passwords do not match." ); 
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (newPassword || repeatNewPassword) {
      setValidationMessage("");
    }
  }, [newPassword, repeatNewPassword]);

  const handleChangeEmail = (value) => {
    dispatch({ type: "setEmail", payload: value.target.value });
    error.type === "email" && setError({ message: null, type: null });
  };

//   //Función para manejar cambios en la contraseña
// const handleChangeNewPassword = (value) => {
//   setNewPassword(value);
//   error.type === "newPassword" &&
//     setError({ message: null, type: null });
// };

// const handleChangeRepeatPassword = (value) => {
//   setRepeatNewPassword(value);
//   error.type === "repeatPassword" &&
//     setError({ message: null, type: null });
// };

  const resetPasswordSubmit = e => {
    document.activeElement.blur();
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    if (success) {
      dispatch({ type: "loginClick" });
      return;
    }

    const user = {
      AppUser: {
        email: email,
        id: id
      },
      Password: newPassword,
      DistributorId: getDist().id
    };

    request()
      .post(`/api/appuser/restorepassword`, user)
      .then(response => {
        setSuccess(true);
        setLoading(false);
        setError({ type: "success", message: response.data });
      })
      .catch(error => {
        if (!error.response) {
          return;
        }
        console.log(error.response.data.message);
        setError({ type: "error", message: error.response.data.message });
        setLoading(false);
      });
  };

  return (
    <form className={classes.loginForm} onSubmit={e => resetPasswordSubmit(e)}>
      <Progress class={classes.loading} active={loading} />
      {/* <img src={"/../../images/LOGO_BIONOVA Q.svg"} alt="" className={classes.logoBionova}/>
      <h1 className={classes.loginText}>{locale.header.changePassword}</h1> */}
       <div className={classes.containerHeader} style={{marginTop: "50px"}}>
                 <div >
              <img src={isoLogoQ} className={classes.smallIsoLogo} alt='title'/>
              </div>
              <div className={classes.container}>
                <p className={classes.title}>
                {isoLogoQ && `| ${locale.header.changePassword}`}
              </p>
              </div>
            </div>
      {(error.type === "error" || error.type === "success") && (
        <div
          className={`alert ${
            error.type === "error" ? "alert-danger" : "alert-primary"
          }`}
          role="alert"
        >
          {error.message}
        </div>
      )}
      {!success && (
        <InputQ
          claro={true}
          label={locale.form.input.email}
          value={email}
          handleChange={handleChangeEmail}
          type={"text"}
          errorMessage={error.type === "email" ? error.message : ""}
          disabled={true}
          containerWidth={"100%"}
          containerMargin={"20px"}
          validationMessage={validationMessage}
        />
      )}
      {!success && (
        // <InputQ
        //   claro={true}
        //   placeholder={locale.form.input.newPassword}
        //   value={newPassword}
        //   handleChange={handleChangeNewPassword}
        //   type={"password"}
        //   errorMessage={error.type === "newPassword" ? error.message : ""}
        //   disabled={loading || success}
        //   containerWidth={"100%"}
        //   containerMargin={"20px"}
        //   validationMessage={validationMessage}
        // />
      <ViewPasswordButton
      locale={locale}
      setNewPassword={setNewPassword}
      newPassword={newPassword}
      setError={setError}
      error={error}
      loading={loading}
      style={iconStyles}
      success={success}
      setRepeatNewPassword={setRepeatNewPassword}
      repeatNewPassword={repeatNewPassword}
      validationMessage={validationMessage}
      /> 
      )}
      {/* {!success && (
        <InputQ
          claro={true}
          placeholder={locale.form.input.repeatNewPassword}
          value={repeatNewPassword}
          handleChange={handleChangeRepeatPassword}
          type={"password"}
          errorMessage={error.type === "repeatPassword" ? error.message : ""}
          disabled={loading || success}
          containerWidth={"100%"}
          containerMargin={"20px"}
          validationMessage={validationMessage}
        />
      )} */}
      <ButtonQ
        type={"submit"}
        caption={
          success ? locale.form.submit.login : locale.form.submit.changePassword
        }
        disabled={loading}
      />
       <div>
        <img
          src={images.logo}
          alt=""
          className={classes.logoTerragene2}
        ></img>
      </div>
    </form>
  );
}


