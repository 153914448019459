import React, { useState, useEffect, useContext, useRef } from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import { getUserCompany, getSession } from "../../utils/sessionHandler";
import _ from "lodash";
import { useTheme } from "@material-ui/core";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import { useApiData } from "../../utils/api";
import { QRCode } from "react-qrcode-logo";
import ReactToPrint from "react-to-print";
import PageGeneralContainer from "../../components/PageGeneralContainer/PageGeneralContainer.jsx";
import useStyles from "./DisinfectorStyles.js";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Disinfector() {
  const theme = useTheme();
  const classes = useStyles();
  const {
    data: companyMachineModels,
    loading: loadingCompanyMachineModels,
    updateData: updateCompanyMachineModels,
  } = useApiData("/api/CompanyMachineModel");
  const {
    data: companyMachineMake,
    loading: loadingCompanyMachineMake,
    updateData: updateCompanyMachineMake,
  } = useApiData("/api/CompanyMachineMake");

  const locale = useContext(LocalizationContext);
  const company = getUserCompany();
  const query = useQuery();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(
    query.get("sectorId") || ""
  );
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [printeable, setPrinteable] = useState(null);
  const [defaultBrand, setDefaultBrand] = useState(null);
  const [defaultModel, setDefaultModel] = useState(null);
  const defaultMachineType = 1;

  const componentRef = React.useRef(null);
  const reactToPrintRef = React.useRef(null); // referencia para ReactToPrint

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const reactToPrintTrigger = React.useCallback(() => {
    return <button>Print QR</button>;
  }, []);

  const getOptions = (data, currentId, brand) => {
    let temp = [];
    data.reduce((options, current) => {
      const id = current?.[currentId] || current?.id;
      temp.push([id, current?.[brand]]);
      return options;
    }, []);
    return temp;
  };

  useEffect(() => {
    request()
      .get(`/api/sector?companyId=${company.Id}`)
      .then((response) => {
        setTimeout(() => {
          setSectors(
            response.data.reduce((sectorOptions, sector) => {
              sectorOptions.push([sector.id, sector.name]);
              return sectorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, [company.Id]);

  useEffect(() => {
    updateData();
  }, [selectedSector]);

  const updateData = () => {
    if (selectedSector) {
      setLoadingData(true);
      request()
        .get(
          `/api/Disinfector/GetBySector?CompanyId=${
            getUserCompany().Id
          }&SectorId=${selectedSector}`
        )
        .then((response) => {
          const updatedData = response.data.map((current) => {
            const modelData =
              companyMachineModels.find(
                (model) => model.id === current.companyMachineModelId
              ) || {};
            const brandData =
              companyMachineMake.find(
                (brand) => brand.id === modelData.companyMachineMakeId
              ) || {};
            const newObject = _.mapKeys(current, (value, key) => {
              return "Disinfector." + key;
            });
            return {
              ...newObject,
              "Disinfector.brand": brandData.brand || "", // Access "brand" directly
              "Disinfector.companyMachineMakeId":
                modelData.companyMachineMakeId || "",
              "Disinfector.modelName": modelData.name || "",
              "Disinfector.modelDescription": modelData.description || "",
            };
          });

          setData(updatedData);

          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        })
        .catch((error) => console.log(error.response));
      updateCompanyMachineModels();
      updateCompanyMachineMake();
    }
  };

  const isLoading =
    loadingContent || loadingCompanyMachineModels || loadingCompanyMachineMake;

  const getQR = (current) => {
    setPrinteable(current);
  };
  useEffect(() => {
    if (printeable) {
      reactToPrintRef.current.handlePrint();
    }
  }, [printeable]);

  const handleChangeData = (current) => {
    setDefaultModel(parseInt(current?.["Disinfector.companyMachineModelId"]));
    setDefaultBrand(parseInt(current?.["Disinfector.companyMachineMakeId"]));
  };

  const getCurrentModels = () => {
    const filtered = companyMachineModels.filter(
      (model) => model.machineType === defaultMachineType
    );
    return filtered.reduce((accumulator, currentObject) => {
      const exists = accumulator.some(
        (objeto) =>
          objeto.companyMachineMakeId === currentObject.companyMachineMakeId
      );

      if (!exists) {
        accumulator.push(currentObject);
      }

      return accumulator;
    }, []);
  };

  const getFilteredModels = () => {
    return selectedBrand
      ? getCurrentModels().filter(
          (m) => m.companyMachineMakeId == selectedBrand
        )
      : getCurrentModels();
  };

  const getFilteredBrands = () => {
    let brand;
    if (selectedModel)
      brand = companyMachineModels.find(
        (m) => m.id == selectedModel
      ).companyMachineMakeId;

    return selectedModel
      ? [
          ...getOptions(
            getCurrentModels()
              ?.map((current) => current.companyMachineMake)
              .filter((b) => b.id == brand),
            "companyMachineMakeId",
            "brand"
          ),
          ...getOptions(
            getCurrentModels()
              ?.map((current) => current.companyMachineMake)
              .filter((b) => b.id != brand),
            "companyMachineMakeId",
            "brand"
          ),
        ]
      : [
          [null, "Select a Brand"],
          ...getOptions(
            getCurrentModels()?.map((current) => current.companyMachineMake),
            "companyMachineMakeId",
            "brand"
          ),
        ];
  };

  if (isLoading) return <PageContentLoading />;

  return (
    <div>
      <div className={classes.pageContainer}>
        <div className={classes.page}>
          <div className={"container-fluid px-0"}>
            <div className={"row mx-0 "}>
              <div
                style={{ margin: "25px 0px 0px 25px" }}
                className={"col-12 col-md-5 px-0"}
              >
                <Select
                  label={t("SectorLabel", locale) || "Sector"}
                  options={sectors}
                  emptyOptionText={
                    t("ChooseASector", locale) || "Choose A Sector"
                  }
                  variant={"standard"}
                  value={selectedSector}
                  onChange={(val) => setSelectedSector(val)}
                />
              </div>
            </div>
          </div>
          {printeable && (
            <div>
              <ReactToPrint
                content={reactToPrintContent}
                ref={reactToPrintRef}
                onAfterPrint={handleAfterPrint}
                documentTitle="QR Print"
              />
              <div
                ref={componentRef}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                id="printLabel"
              >
                <QRCode
                  value={printeable?.["serial"]}
                  logoImage={theme.palette.images.logoBionovaOscuro}
                  logoWidth={70}
                  logoHeight={25}
                  removeQrCodeBehindLogo={true}
                  logoPadding={10}
                  quietZone={20}
                  bgColor={"#f7f7f7"}
                />
                <div style={{ paddingLeft: "25px" }}>
                  <h1>
                    <small>Serial:</small> <br />
                    {printeable?.["serial"]}
                  </h1>
                  <h3>
                    <small>Name:</small> <br /> {printeable?.["name"]}
                  </h3>
                </div>
              </div>
            </div>
          )}
          {selectedSector && (
            <PageGeneralContainer
              title={t("Title", locale)}
              pageStyles={false}
              category={"disinfector"}
            >
              <CRUD
                data={data}
                loadingData={loadingData}
                url={"/api/Disinfector"}
                updateData={updateData}
                title={t("Title", locale)}
                onChangeCallback={(e) => handleChangeData(e)}
                migrationInfo={{
                  enabled: true,
                  field: "Disinfector.desktopMigrationId",
                }}
                closeModalCallback={() => {
                  setDefaultBrand("");
                  setDefaultModel("");
                  setSelectedBrand("");
                  setSelectedModel("");
                }}
                onEditClick={(e) => {
                  setDefaultBrand(e?.["Disinfector.companyMachineMakeId"]);
                  setDefaultModel(e?.["Disinfector.companyMachineModelId"]);
                  setSelectedModel(e?.["Disinfector.companyMachineModelId"]);
                  setSelectedBrand(e?.["Disinfector.companyMachineMakeId"]);
                }}
                columns={[
                  "Disinfector.name",
                  "Disinfector.brand",
                  "Disinfector.modelName",
                  "Disinfector.serial",
                  "Disinfector.observations",
                ]}
                headers={[
                  t("NameLabel", locale),
                  t("BrandLabel", locale),
                  "Models",
                  t("SerialLabel", locale),
                  "Observaciones",
                ]}
                idField={"Disinfector.id"}
                fieldsMap={[
                  "Disinfector.sectorId",
                  "Disinfector.name",
                  "Disinfector.companyMachineMakeId",
                  "Disinfector.companyMachineModelId",
                  "Disinfector.serial",
                  "Disinfector.observations",
                ]}
                formFields={[
                  {
                    label: t("SectorLabel", locale),
                    class: "col-12 col-md-6 px-0",
                    type: "select",
                    defaultValue: selectedSector,
                    options: sectors,
                    disabled: true,
                    required: true,
                  },
                  {
                    label: t("NameLabel", locale),
                    class: "col-12 col-md-6 px-0",
                    required: true,
                  },
                  {
                    label: t("BrandLabel", locale),
                    class: "col-12 col-md-6 px-0",
                    type: "select",
                    defaultValue: defaultBrand,
                    options: getFilteredBrands(),
                    onSelectedChange: (selected) => {
                      setSelectedBrand(selected);
                    },
                    value: defaultBrand,
                    required: true,
                  },
                  {
                    label: "Modelos",
                    class: "col-12 col-md-6 px-0",
                    type: "select",
                    defaultValue: defaultModel,
                    options: [
                      [null, "Select a Model"],
                      ...getOptions(
                        getFilteredModels(),
                        "companyMachineModelId",
                        "name"
                      ),
                    ],
                    onSelectedChange: (selected) => {
                      setSelectedModel(selected);
                    },
                    required: true,
                  },
                  {
                    label: t("SerialLabel", locale),
                    class: "col-12 col-md-6 px-0",
                    required: true,
                  },
                  {
                    label: "Observations",
                    class: "col-12 col-md-6 px-0",
                  },
                ]}
                hasCustomFields={true}
                detailFields={[
                  { label: t("NameLabel", locale), name: "Disinfector.name" },
                  { label: "Brand", name: "Disinfector.brand" },
                  { label: "Modelo", name: "Disinfector.modelName" },
                  {
                    label: t("SerialLabel", locale),
                    name: "Disinfector.serial",
                  },
                  { label: "Observations", name: "Disinfector.observations" },
                ]}
                // tableActions={[
                //   {
                //     icon: "M11,4V9a2,2,0,0,1-2,2H4A2,2,0,0,1,2,9V4A2,2,0,0,1,4,2H9A2,2,0,0,1,11,4Zm9-2H15a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2ZM9,13H4a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V15A2,2,0,0,0,9,13Zm5,5h3a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v3A1,1,0,0,0,14,18Zm7-5a1,1,0,0,0-1,1v5a1,1,0,0,1-1,1H14a1,1,0,0,0,0,2h5a3,3,0,0,0,3-3V14A1,1,0,0,0,21,13Z",
                //     tooltip: "Scan Serial",
                //     action: (current) => getQR(current),
                //   },
                // ]}
                baseObject={{
                  Disinfector: {
                    id: null,
                    sectorId: selectedSector,
                    brand: "",
                    name: "",
                    companyMachineMakeId: "",
                    companyMachineModel: null,
                    companyMachineModelId: null,
                    serial: "",
                    desktopMigrationId: "",
                    observations: "",
                  },
                  UserLogged: getSession(),
                }}
              />
            </PageGeneralContainer>
          )}
        </div>
      </div>
    </div>
  );
}
