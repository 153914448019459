import React, { useContext, useEffect, useState } from "react";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import useWindowSize from "../../utils/Hooks/useWindowSize";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import IC1020 from "../../assets/images/IC1020.png";
import PHOTON from "../../assets/images/PHOTON.png";
import HYPER from "../../assets/images/HYPER.png";
import MINIBIO from "../../assets/images/MINIBIO.png";
import MINIPRO from "../../assets/images/MINIPRO.png";
import FRLCD from "../../assets/images/FRLCD.png";
import styles from "./IncubatorsCarousel.module.css";
import SpinnerQ from "../SpinnerQ/SpinnerQ";

function IncubatorsCarousel({
  loadingCarousel,
  incubators,
  onIncubatorHandler,
  incubatorCode,
  cycle,
}) {
  const ITEMS_PER_PAGE = 6;
  const totalSlides = Math.ceil(incubators.length / ITEMS_PER_PAGE);
  const initialSlide = totalSlides > 0 ? Math.floor(totalSlides / 2) : 0;
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [selectedIncubator, setSelectedIncubator] = useState(null);
  const locale = useContext(LocalizationContext);
  const windowSize = useWindowSize();

  const imgIncubatorsFiltered = (value) => {
    const condition = value.imageName.toUpperCase();
    return condition === "PHOTON"
      ? PHOTON
      : condition === "HYPER"
      ? HYPER
      : condition === "MINIPRO"
      ? MINIPRO
      : condition === "MINIBIO"
      ? MINIBIO
      : condition === "IC1020FR"
      ? IC1020
      : condition === "FRLCD"
      ? FRLCD
      : null;
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = (prevSlide - 1 + totalSlides) % totalSlides;
      return newSlide;
    });
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = (prevSlide + 1) % totalSlides;
      return newSlide;
    });
  };

  const handleClick = (index) => {
    setSelectedIncubator(index);
    setCurrentSlide(Math.floor(index / ITEMS_PER_PAGE));
    onIncubatorHandler(incubators[index]);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedIncubator || selectedIncubator === 0) {
        onIncubatorHandler(incubators[selectedIncubator]);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [selectedIncubator, incubators]);

  const paginatedIncubators = incubators.slice(
    currentSlide * ITEMS_PER_PAGE,
    (currentSlide + 1) * ITEMS_PER_PAGE
  );

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselContent}>
        <div className={styles.carousel}>
          {loadingCarousel ? (
            <div className="spinnerContainer">
              <SpinnerQ />
            </div>
          ) : (
            <div className={styles.carouselInner}>
              {paginatedIncubators.map((incubator, index) => {
                const realIndex = currentSlide * ITEMS_PER_PAGE + index;
                return (
                  <div
                    key={realIndex}
                    className={`${styles.card} ${
                      realIndex === selectedIncubator ? styles.selectedCard : ""
                    }`}
                    onClick={() => handleClick(realIndex)}
                  >
                    <img
                      loading="lazy"
                      src={imgIncubatorsFiltered(incubator)}
                      alt={incubator.toString}
                    />
                    <div
                      className={`${styles.cardTitle} ${
                        realIndex === selectedIncubator
                          ? styles.selectedTitle
                          : ""
                      }`}
                    >
                      {incubator.toString}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.navContainer}>
            <button className={styles.navButton} onClick={handlePrev}>
              <NavigateBeforeIcon />
            </button>

            <div className={styles.indicatorContainer}>
              {Array.from({ length: totalSlides }).map((_, index) => (
                <div
                  key={index}
                  className={`${styles.indicator} ${
                    index === currentSlide ? styles.activeIndicator : ""
                  }`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>

            <button className={styles.navButton} onClick={handleNext}>
              <NavigateNextIcon />
            </button>
          </div>
        </div>
      </div>
      {!cycle && (
        <div className={styles.selectionInfo}>
          <ul className={styles.statusList}>
            <li className={styles.statusItem}>
              <div className={`${styles.statusIcon} ${styles.available}`}></div>
              {t("AvailableSpan", locale) || "Available"}
            </li>
            <li className={styles.statusItem}>
              <div
                className={`${styles.statusIcon} ${styles.inProgress}`}
              ></div>
              {t("InProgressSpan", locale) || "In Progress"}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default IncubatorsCarousel;
