import React, { useEffect, useState } from "react";
import DDColumn from "./DDColumn/DDColumn";
import DDRow from "./DDRow/DDRow";
import QuantityElement from "../QuantityElement/QuantityElement";
import useWindowSize from "../../utils/Hooks/useWindowSize";
import styles from "./DragAndDropPackages.module.css";
import t from "../../utils/translation";

const DragAndDropPackages = ({
  data,
  selectedData,
  selectedFilteredData,
  handleSelectedData,
  predifinedData,
  filter1,
  filter2,
  method,
  leftColumnName,
  rightColumnName,
  category = "Sterilization",
}) => {
  const [quantity, setQuantity] = useState(1);
  const windowSize = useWindowSize();

  const startDragColumn = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
    e.dataTransfer.setData("ColumnMaterial", true);
  };

  const startDrag = (e, item) => {
    e.dataTransfer.setData("itemID", item.id);
  };

  const draggingOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const columnID = e.dataTransfer.getData("ColumnMaterial");
    moveItem(itemID, columnID);
  };

  const doubleClick = (itemID, columnID) => {
    moveItem(itemID, columnID);
  };

  const moveItem = (itemID, columnID) => {
    const itemData = data.find((item) => item.id == itemID);
    const item = { ...itemData };

    if (item && columnID) {
      let newState = [...selectedData];
      const isItemExists = newState.find((material) => material.id === item.id);

      if (data === predifinedData) {
        if (handleQuantityMaterial(item) < quantity) {
          setQuantity(1);
          return;
        }
      }

      if (!isItemExists) {
        if (!item.serial) item.quantity = quantity;
        newState = [...newState, { ...item, quantity }];
      } else {
        isItemExists.quantity += quantity;
        if (data === predifinedData) {
          item.quantity -= quantity;
        }
      }

      if (item.quantity < 0) {
        item.quantity = 0;
      }

      setQuantity(1);
      handleSelectedData(newState);
    }
  };

  const onDropDelete = (e) => {
    const itemID = e.dataTransfer.getData("itemID");
    const item = selectedData.find((material) => material.id == itemID);
    const originalItem = data.find((material) => material.id == itemID);

    if (
      data === predifinedData &&
      item &&
      originalItem &&
      item.originalQuantity !== undefined
    ) {
      originalItem.quantity += item.quantity;
    }

    const newState = selectedData.filter((material) => material.id != itemID);
    handleSelectedData(newState);
  };

  const onDelete = (id) => {
    const item = selectedData.find((material) => material.id === id);
    const originalItem = data.find((material) => material.id === id);

    if (
      data === predifinedData &&
      item &&
      originalItem &&
      item.originalQuantity !== undefined
    ) {
      originalItem.quantity += item.quantity;
    }

    const newState = selectedData.filter((material) => material.id != id);
    handleSelectedData(newState);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const reduceQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const serialExist = (id) => {
    return selectedData.find((m) => m.id === id);
  };

  useEffect(() => {
    if (selectedData.length > 0 && selectedData.length == 0)
      handleSelectedData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    handleSelectedData(selectedData);
  }, [selectedData]);

  const handleQuantityMaterial = (item) => {
    const selectedItem = selectedData.find((s) => s.id === item.id);

    return selectedItem ? item.quantity - selectedItem.quantity : item.quantity;
  };

  const handleQuantity = (e) => {
    if(e.target.value <= 0) return
    setQuantity(parseInt(e.target.value));
  };
  return (
    <div className={styles.container}>
      <div className={styles.columnContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{leftColumnName}</p>
            {filter1}
            <DDColumn id={"Drag"} dragOver={draggingOver} drop={onDropDelete}>
              {data === predifinedData
                ? predifinedData
                    .filter(
                      (d) => !d.serial || (d.serial && !serialExist(d.id))
                    )
                    .map(
                      (material) =>
                        handleQuantityMaterial(material) > 0 && (
                          <DDRow
                            key={material.id}
                            obj={material}
                            name={material.name}
                            serial={material.serial}
                            body={material?.materialType?.name}
                            dragStart={startDragColumn}
                            method={method}
                            materialMethod={material.methodId}
                            doubleClick={doubleClick}
                            columnId={true}
                            // quantity={material?.quantity}
                            quantity={` ${handleQuantityMaterial(material)}`}
                            category={category}
                          />
                        )
                    )
                : data
                    .filter(
                      (d) => !d.serial || (d.serial && !serialExist(d.id))
                    )
                    .map((material) => (
                      <DDRow
                        key={material.id}
                        obj={material}
                        name={material.name}
                        serial={material.serial}
                        body={material?.materialType?.name}
                        dragStart={startDragColumn}
                        method={method}
                        materialMethod={material.methodId}
                        doubleClick={doubleClick}
                        columnId={true}
                        category={category}
                      />
                    ))}
            </DDColumn>
          </div>
        </div>
        <div className={styles.quantityPaper}>
          <QuantityElement
            quantity={quantity}
            reduceQuantity={reduceQuantity}
            increaseQuantity={increaseQuantity}
            category={category}
            handleQuantityChange={handleQuantity}
          />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.paper}>
            <p className={styles.title}>{rightColumnName}</p>

            {filter2}

            <DDColumn
              id={"Drop"}
              dragOver={draggingOver}
              drop={onDrop}
              autoScroll={true}
            >
              {selectedFilteredData.map((material) => (
                <DDRow
                  key={material.id}
                  obj={material}
                  name={material.name}
                  serial={material.serial}
                  quantity={material.quantity}
                  body={material?.materialType?.name}
                  dragStart={startDrag}
                  onDelete={onDelete}
                  method={method}
                  materialMethod={material.methodId}
                  doubleClick={onDelete}
                  columnId={null}
                  category={category}
                />
              ))}
            </DDColumn>
          </div>
        </div>
      </div>
      {category == "Sterilization" && (
        <p className={styles.wrongMethod}>
          <div className={styles.circleMethod}></div>
          {t("WrongMethodSpan")}
        </p>
      )}
    </div>
  );
};

export default DragAndDropPackages;
