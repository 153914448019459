import React, { useEffect, useState } from "react";
import { differenceInMilliseconds, parseISO } from "date-fns";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { useContext } from "react";

const Time = ({ time, text }) => {
  const fechaReferencia = parseISO(time);
  const [tiempoTranscurrido, setTiempoTranscurrido] = useState("");

  const locale = useContext(LocalizationContext);

  useEffect(() => {
    const intervalo = setInterval(() => {
      const fechaActual = new Date();

      const añoFormateado = fechaActual.getUTCFullYear();
      const mesFormateado = fechaActual.getUTCMonth() + 1;
      const diaFormateado = fechaActual.getUTCDate();
      const horaFormateada = fechaActual.getUTCHours();
      const minutosFormateados = fechaActual.getUTCMinutes();
      const segundosFormateados = fechaActual.getUTCSeconds();

      // Formatea la fecha y hora en UTC como una cadena de texto
      const fechaHoraFormateada = `${añoFormateado}-${mesFormateado
        .toString()
        .padStart(2, "0")}-${diaFormateado
        .toString()
        .padStart(2, "0")} ${horaFormateada
        .toString()
        .padStart(2, "0")}:${minutosFormateados
        .toString()
        .padStart(2, "0")}:${segundosFormateados.toString().padStart(2, "0")}`;
      const fechaIso = parseISO(fechaHoraFormateada);

      const diferencia = differenceInMilliseconds(fechaIso, fechaReferencia);

      const segundos = Math.floor(diferencia / 1000);
      const minutos = Math.floor(segundos / 60);
      const horas = Math.floor(minutos / 60);
      const dias = Math.floor(horas / 24);

      const resultado = [];

      if (dias % 365 > 0) {
        resultado.push(
          `${dias % 365}  ${t("Day", locale) || "Day"}${
            dias % 365 !== 1 ? "s" : ""
          }`
        );
      }
      if (horas % 24 > 0) {
        resultado.push(
          `${horas % 24}  ${t("Hour", locale) || "Hour"}${
            horas % 24 !== 1 ? "s" : ""
          }`
        );
      }
      if (minutos % 60 > 0) {
        resultado.push(
          `${minutos % 60}  ${t("Minute", locale) || "Minute"}${
            minutos % 60 !== 1 ? "s" : ""
          }`
        );
      }
      if (segundos % 60 > 0) {
        resultado.push(
          `${segundos % 60}  ${t("Second", locale) || "Second"}${
            segundos % 60 !== 1 ? "s" : ""
          }`
        );
      }

      const resultadoFinal = resultado.join(", ");

      setTiempoTranscurrido(resultadoFinal);
    }, 1000);

    return () => {
      clearInterval(intervalo);
    };
  }, []);

  return (
    <p
      style={{
        color: "#f7f7f7",
        margin: 0,
        fontWeight: "normal",
        whiteSpace: "normal",
      }}
    >
      {text}: <br />
      {tiempoTranscurrido}
    </p>
  );
};

export default Time;
