import React, { useEffect, useState, useCallback, useContext } from "react";
import request from "../../../utils/request";
import _ from "lodash";
import LocalizationContext from "../../../utils/LocalizationContext";
import t from "../../../utils/translation";
import { unflatten, flatten } from "flat";
import { getDist } from '../../../utils/sessionHandler';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import Modal from "../../Modal";
import Progress from "../../Progress";
import Input from "../../Input";
import Select from "../../Select";
import SplitButton from "../../SplitButton/SplitButton";

import Tooltip from '@material-ui/core/Tooltip';
import classNames from "classnames";
import { sortByField } from "../../../utils/functions/Sorter";

const useCheckBoxStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    padding: '8px',
    margin: '0px'
  },
  label: {
    fontSize: '13px'
  }
});

export default function Update(props) {
  const checkBoxClass = useCheckBoxStyles()
  const {
    classes,
    idField,
    dispatch,
    fieldsMap,
    formFields,
    baseObject,
    selectedObjectsList,
    selectedObjectsId,
    url,
    updateData,
    grouped,
    useFieldMap,
    splitButtonExtraOptions = [],
    saveSuccess,
    onCloseModal,
  } = props;
  console.log('inside Massive')

  const locale = useContext(LocalizationContext);

  const [object, setObject] = useState(flatten(baseObject));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [responseError, setResponseError] = useState(null);
  const [responseMessage, setResponseMessage] = useState({ type: "none" });
  const [splitOptions, setSplitOptions] = useState(null);
  useEffect(() => {
    const splitExtra = splitButtonExtraOptions.map((splitOption) => {
      if(splitOption.useCommonFunction){
        splitOption.onClickOption = () => handleUpdate(splitOption.moveToSaved)
      }
      return splitOption
    })
  
    setSplitOptions([
      { text: (t("ConfirmButton") || "Confirm"), onClickOption: (text) => handleUpdate(false), disabled: false },
      ...splitExtra
    ])
  }, [object])


  const RequiredFieldMessage =
    t("RequiredFieldError") || "This field is required";

  let scanOn = false;

  if (idField != undefined) {
    if ((idField === "ReadingBI.id") || (idField === "ReadingPRO.id")) {
      scanOn = true
    }
  }

  const NametoString = "( " +
    selectedObjectsList.reduce((name, item, index) => {
      return (
        name +
        item[idField.split(".")[0] + ".toString"] +
        (index === selectedObjectsList.length - 1 ? "" : ", ")
      );
    }, "") +
    " )";

  let scannerInput2 = "";
  const keyPressListner = useCallback((e) => {
    if (e.keyCode != 40 && e.keyCode != 32 && e.keyCode != 27 && e.keyCode != 13 && e.keyCode != 16 && e.keyCode != 119 && e.keyCode != 120) {

      let gTin = "";
      scannerInput2 += e.key.charAt(e.key.length - 1);

      setTimeout(() => {
        let tempObject = Object.assign({}, object);
        if (scannerInput2.length >= 35) {
          if (props.selectedObjectsList.length > 0) {
            if (props.selectedObjectsList[0]["ReadingBI.statusDescription"] != null) {
              if ((props.selectedObjectsList[0]["ReadingBI.statusDescription"].toUpperCase() === "LIVE") || (props.selectedObjectsList[0]["ReadingBI.statusDescription"].toUpperCase() === "PROVISIONAL")) {
                tempObject["gTin"] = scannerInput2.substr(2, 14);
                gTin = scannerInput2.substr(2, 14);

                if (
                  scannerInput2.split("")[24] === "1" &&
                  scannerInput2.split("")[25] === "0"
                ) {
                  tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(26).toUpperCase();
                }
                if (
                  scannerInput2.split("")[24] === "1" &&
                  scannerInput2.split("")[25] === "1"
                ) {
                  if (
                    scannerInput2.split("")[32] === "1" &&
                    scannerInput2.split("")[33] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(34).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[60] === "1" &&
                    scannerInput2.split("")[61] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(62).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[61] === "1" &&
                    scannerInput2.split("")[62] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(63).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[62] === "1" &&
                    scannerInput2.split("")[63] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(64).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[63] === "1" &&
                    scannerInput2.split("")[64] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(65).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[64] === "1" &&
                    scannerInput2.split("")[65] === "0"
                  ) {
                    tempObject["ReadBIMasive.loteProduct"] = scannerInput2.substr(66).toUpperCase();
                  }
                }

                request().get(`/api/Product/GetProductByGTIN?gtin=${gTin}&distributor=${getDist().id}`).then((response) => {
                  if (response.data.id != 0) {
                    tempObject["ReadBIMasive.productId"] = response.data.id;
                    setObject(tempObject);
                    //scannerInput2="";
                  }
                })
              }
            }
            else if (props.selectedObjectsList[0]["ReadingPRO.statusDescription"] != undefined) {
              if ((props.selectedObjectsList[0]["ReadingPRO.statusDescription"].toUpperCase() === "LIVE") || (props.selectedObjectsList[0]["ReadingPRO.statusDescription"].toUpperCase() === "PROVISIONAL")) {
                tempObject["gTin"] = scannerInput2.substr(2, 14);
                gTin = scannerInput2.substr(2, 14);

                if (
                  scannerInput2.split("")[24] === "1" &&
                  scannerInput2.split("")[25] === "0"
                ) {
                  tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(26).toUpperCase();
                }
                if (
                  scannerInput2.split("")[24] === "1" &&
                  scannerInput2.split("")[25] === "1"
                ) {
                  if (
                    scannerInput2.split("")[32] === "1" &&
                    scannerInput2.split("")[33] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(34).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[60] === "1" &&
                    scannerInput2.split("")[61] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(62).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[61] === "1" &&
                    scannerInput2.split("")[62] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(63).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[62] === "1" &&
                    scannerInput2.split("")[63] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(64).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[63] === "1" &&
                    scannerInput2.split("")[64] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(65).toUpperCase();
                  }
                  else if (
                    scannerInput2.split("")[64] === "1" &&
                    scannerInput2.split("")[65] === "0"
                  ) {
                    tempObject["ReadProMasive.loteProduct"] = scannerInput2.substr(66).toUpperCase();
                  }
                }

                request().get(`/api/Product/GetProductByGTIN?gtin=${gTin}&distributor=${getDist().id}`).then((response) => {
                  if (response.data.id != 0) {
                    tempObject["ReadProMasive.productId"] = response.data.id;
                    setObject(tempObject);
                  }
                })
              }
            }
          }
        }
        scannerInput2 = "";
      }, 220);
    }
  }, [props])

  useEffect(() => {
    window.document.addEventListener('keydown', event => keyPressListner(event))

    return () => {
      document.getElementById("root").style = "";
    }
  }, [keyPressListner])

  const closeModal = () => {
    dispatch({ type: "closeModal" });
    onCloseModal()
  };

  const handleFieldChange = (value, field, propOnMassiveObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      tempObject[field] = value;
    } else {
      tempObject[propOnMassiveObject] = value;
    }
    setObject(tempObject);
  };

  const handleCheckBoxChange = (value, field, propOnMassiveObject) => {
    let tempObject = Object.assign({}, object);
    if(useFieldMap){
      tempObject[field] = value.target.checked;
    } else {
      tempObject[propOnMassiveObject] = value.target.checked;
    }
    setObject(tempObject);
  };

  let isScanning = false;
  let scannerInput = "";

  const handleScannerInput = (val, field) => {
    const manualInputValue = val;
    scannerInput += val.charAt(val.length - 1);
    if (isScanning) return;
    isScanning = true;

    setTimeout(() => {
      let tempObject = Object.assign({}, object);

      if (scannerInput.length > 15) {
        //si tiene codigo 01 i gtin cargo ambos inputs
        // tempObject['ReadBIMasive.productId'] = scannerInput.substr(2, 14);
        tempObject["gTin"] = scannerInput.substr(2, 14);

        if (
          scannerInput.split("")[24] === "1" &&
          scannerInput.split("")[25] === "0"
        ) {
          tempObject["ReadBIMasive.loteProduct"] = scannerInput.substr(26);
        }
        if (
          scannerInput.split("")[24] === "1" &&
          scannerInput.split("")[25] === "1"
        ) {
          tempObject["ReadBIMasive.loteProduct"] = scannerInput.substr(34);
        }
      } else {
        //pongo valor en el input seleccionado
        tempObject[field] = manualInputValue;
      }

      setObject(tempObject);
    }, 30);
  };

  const handleSelectChange = (value, index, dependencies, propOnMassiveObject) => {
    let tempObject = Object.assign({}, object);
    if (useFieldMap) {
      if (typeof value === "object") {
        tempObject[fieldsMap[index]] = value.id;
        tempObject[fieldsMap[index].split(".")[0]] = value;
      } else {
        tempObject[fieldsMap[index]] = value;
      }
    } else {
      if (typeof value === "object") {
        tempObject[propOnMassiveObject] = value.id;
        tempObject[propOnMassiveObject.split(".")[0]] = value;
      } else {
        tempObject[propOnMassiveObject] = value;
      }
      if (dependencies && dependencies.length > 0) {
        dependencies.forEach((dependency) => {
          tempObject[dependency.key] = dependency.default.id;
        })
      }
    }
    if (tempObject["gTin"]) tempObject["gTin"] = null;
    setObject(tempObject);
  };

  const handleUpdate = (moveToSaved) => {
    let massiveObjectName
    if (useFieldMap) {
      massiveObjectName = fieldsMap[0].split(".")[0]
    } else {
      massiveObjectName = formFields[0].propOnMassiveObject.split(".")[0]
    }
    let requestObject = { ...unflatten(object) };
    requestObject[massiveObjectName].ReadIds = selectedObjectsId;
    requestObject.moveToSaved = moveToSaved

    let requiredError
    if (useFieldMap) {
      requiredError = fieldsMap.reduce(
        (errorBag, field, index) => {
          (
            _.get(requestObject, field) === undefined ||
            _.get(requestObject, field) === "" ||
            _.get(requestObject, field) === null
          )
            && formFields[index].required
            && (errorBag[field] = RequiredFieldMessage)
          return errorBag;
        }, {});
    } else {
      requiredError = formFields.reduce(
        (errorBag, field) => {
          (
            _.get(requestObject, field.propOnMassiveObject) === undefined ||
            _.get(requestObject, field.propOnMassiveObject) === "" ||
            _.get(requestObject, field.propOnMassiveObject) === null
          )
            && formFields.find(x => x.propOnMassiveObject == field.propOnMassiveObject).required
            && (errorBag[field.propOnMassiveObject] = RequiredFieldMessage)
          return errorBag;
        }, {});
    }

    if (Object.keys(requiredError).length > 0) {
      setErrors(requiredError);
    } else {
      setLoading(true);
      setResponseError(null);
      request()
        .post(url, requestObject)
        .then((response) => {
          setLoading(false);
          setResponseError(null);
          if (response.data.result === "ERRORS") {
            setResponseMessage({
              type: "error",
              message: response.data.errors.map((e) => <p style={{ marginBottom: 0 }} key={e}>{e}</p>),
            });
          } else {
            setResponseMessage({
              type: "success",
              message: response.data.message,
            });
            if(moveToSaved) saveSuccess(response.data.message, true);
            if(!moveToSaved) updateData();
          }
        })
        .catch((error) => {
          if (typeof error.response?.data?.errors === "object") {
            const errorBag = error.response.data.errors;
            setResponseError(
              <div>
                <p>{error.response.data.title}: </p>
                {Object.keys(errorBag).map((k) => (
                  <p key={k}>{errorBag[k][0]}</p>
                ))}
              </div>
            );
          } else {
            setResponseError(
              error.response?.data?.message ||
              error.response?.data?.title ||
              error.response?.data
            );
          }
          setLoading(false);
        });
    }
  };

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <div className={classes.headerText}>
          {t("MultipleEditText", locale) + " " + NametoString}
        </div>

        {scanOn //si es true lo muestro
          ?
          <div className={classes.divScanner}>
            <Tooltip title={<h6>{t("ScannerAvailable")}</h6>} placement="left">
              <img src={'../images/scan.svg'} className={classes.imgScanner}></img>
            </Tooltip>
          </div>
          :
          null
        }

        <span className={classes.modalCloseButton} onClick={closeModal}>
          <svg viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </span>
      </div>
      <Progress active={loading} />
    </React.Fragment>
  );

  const footer = splitOptions && (
    <SplitButton
      disabled={loading}
      options={splitOptions}
      selectedOption={0}
      localStorageSelectedOption={"updateSplitButtonSelectedOption"}>
    </SplitButton>
  );

  const setRequiredFields = (value, useFieldMap, requiredDependencies) => {
    if (requiredDependencies && requiredDependencies.length > 0) {
      if (useFieldMap) {
        requiredDependencies.forEach((requiredDependency) => {
          const index = fieldsMap.indexOf(requiredDependency.keySingle);
          if (requiredDependency.condition == value) {
            let field = formFields[index]
            if (field)
              field.required = requiredDependency.required
          }
        })
      } else {
        requiredDependencies.forEach((requiredDependency) => {
          if (requiredDependency.condition == value) {
            let dependentField = formFields.find(x => x.propOnObject === requiredDependency.keySingle)
            if (dependentField)
              dependentField.required = requiredDependency.required
          }
        })
      }
    }
  }

  const setVisibilityGroupDependencies = (value, useFieldMap, visibilityGroupDependencies) => {
    if (visibilityGroupDependencies && visibilityGroupDependencies.length > 0) {
      visibilityGroupDependencies.forEach((visibleDependency) => {
        let group = grouped.find(x => x.Index === visibleDependency.groupIndex)
        if (visibleDependency.condition == value) {
          group.visible = visibleDependency.visible
        }
        else {
          group.visible = false
        }
      })
    }
  }

  const formField = (field, index, groupIndex) => {
    let value = null;
    if (useFieldMap) {
      if (object[fieldsMap[index]] === undefined || object[fieldsMap[index]] === null) {
        if (field.defaultValue === undefined) {
          value = "";
        } else {
          object[fieldsMap[index]] = field.defaultValue;
          value = field.defaultValue;
        }
      } else {
        value = object[fieldsMap[index]];
      }
    } else {
      if (
        object[field.propOnMassiveObject] === undefined ||
        object[field.propOnMassiveObject] === null
      ) {
        if (field.defaultValue === undefined) {
          value = "";
        } else {
          object[field.propOnMassiveObject] = field.defaultValue;
          value = field.defaultValue;
        }
      } else {
        value = object[field.propOnMassiveObject];
      }
    }
    if(field.requiredDependencies) setRequiredFields(value, useFieldMap, field.requiredDependencies)
    if(field.visibilityGroupDependencies) setVisibilityGroupDependencies(value, useFieldMap, field.visibilityGroupDependencies)
    switch (field.type) {
      case "select":
        return (
          <Select
            focused={(groupIndex === 0 && field.position === 0)}
            options={field.options}
            label={
              <div>{field.label}
                {field.required && <span className={classes.requiredField}>  * </span>}
              </div>
            }
            component={field.component}
            value={value}
            uniqueIdentifier={object.gTin}
            getObject={field.objectField}
            onChange={(value) => {
              handleSelectChange(value, index, field.dependencies, field.propOnMassiveObject)
              if (field.onSelectedChange) {
                field.onSelectedChange(value, index)
              }
            }}
            disabled={loading || object.securable === false || field.disabled}
            error={useFieldMap ? errors[fieldsMap[index]] : errors[field.propOnMassiveObject]}
            nullable={field.required === false}
            msgTooltip={field.msgTooltip}
          />
        );
      case "scanner":
        return (
          <Input
            focused={(groupIndex === 0 && field.position === 0)}
            label={<div>{field.label}
              {field.required && <span style={{ color: 'red' }}>  * </span>}
            </div>}
            value={value}
            onChange={(value) => handleScannerInput(value, fieldsMap[index])}
            disabled={loading || field.disabled}
            error={useFieldMap ? errors[fieldsMap[index]] : errors[field.propOnMassiveObject]}
          />
        );
      case "number":
        return (
          <Input
            type="number"
            focused={(groupIndex === 0 && field.position === 0)}
            label={<div>{field.label}
              {field.required && <span style={{ color: 'red' }}>  * </span>}
            </div>}
            value={value}
            onChange={(value) => handleFieldChange(value, fieldsMap[index], field.propOnMassiveObject)}
            disabled={
              loading ||
              field.disabled
            }
            error={useFieldMap ? errors[fieldsMap[index]] : errors[field.propOnMassiveObject]}
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            classes={checkBoxClass}
            control={
              <Checkbox
                checked={value}
                onChange={(value) => handleCheckBoxChange(value, fieldsMap[index], field.propOnMassiveObject)}
                color={field.checkCategory}
                disabled={
                  loading ||
                  field.disabled
                }
              />
            }
            label={<div>{field.label}
              {field.required && <span className={classes.requiredField}>  * </span>}
            </div>}
          />
        );
      default:
        return (
          <Input
            focused={(groupIndex === 0 && field.position === 0)}
            label={<div>{field.label}
              {field.required && <span style={{ color: 'red' }}>  * </span>}
            </div>}
            value={value}
            onChange={(value) => handleFieldChange(value, fieldsMap[index], field.propOnMassiveObject)}
            disabled={loading || field.disabled}
            error={useFieldMap ? errors[fieldsMap[index]] : errors[field.propOnMassiveObject]}
          />
        );
    }
  };

  const groupFields = () => {
    return sortByField(grouped, 'position').map((group) => {
      const formFieldsByGroup = formFields.filter(x => x.groupIndex === group.Index)
      return (
        formFieldsByGroup.length > 0 && group.visible && <div className={classes.groupedDiv}>
          <h6 className={classes.groupedTitle}>{group.Description}</h6>
          <div className={classNames('row', 'col-lg-12')}>
            {
              sortByField(formFields.filter(x => x.groupIndex === group.Index), 'position').map((field, index) => {
                return (
                  <div className={classNames(field.class, {
                    [classes.disabled]: field.disabled,
                  })} key={index}>
                    {formField(field, index, group.Index)}
                  </div>
                );
              })}
          </div>
        </div>
      )
    })
  }

  return (
    <Modal
      header={header}
      footer={footer}
      closeModal={closeModal}
      responsive
    >
      <div className={"container-fluid px-0"}>
        {responseError && (
          <div className={"row mx-0"}>
            <div className={"col-12"}>
              <div className={"alert alert-danger"}>{responseError}</div>
            </div>
          </div>
        )}
        {responseMessage.type === "error" && (
          <div className={"row mx-0"}>
            <div className={"col-12"}>
              <div className={"alert alert-danger"}>
                {responseMessage.message}
              </div>
            </div>
          </div>
        )}
        {responseMessage.type === "success" && (
          <div className={"row mx-0"}>
            <div className={"col-12"}>
              <div className={"alert alert-success"}>
                {responseMessage.message}
              </div>
            </div>
          </div>
        )}
        {
          grouped &&
          groupFields()
        }
        {
          !grouped &&
          <div className={"row mx-0"}>
            {sortByField(formFields, 'position').map((field, index) => {
              return (
                <div className={field.class} key={fieldsMap[index]}>
                  {formField(field, index)}
                </div>
              )
            })}
          </div>
        }
      </div>
    </Modal>
  );
}
