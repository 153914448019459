import React, { useState } from "react";
import request from "../../utils/request";
import _ from "lodash";

import CRUD from "../../components/CRUD";

export default function Program() {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);

  React.useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get("/api/program")
      .then(response => {
        setData(
          response.data
          .map(x => {return {...x, programIndex: parseInt(x.programIndex)} })
          .map(x =>
            _.mapKeys(x, (value, key) => {
              return "program." + key;
            })
          )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      });
  };

  return (
    <CRUD
      data={data}
      url={"/api/program"}
      title={"Program"}
      updateData={updateData}
      loadingData={loadingData}
      columns={["program.programIndex", "program.time", "program.temperature", "program.nombre"]}
      headers={["Index", "Time", "Temperature", "Nombre"]}
      idField={"program.id"}
      fieldsMap={[
        "program.programIndex",
        "program.time",
        "program.temperature",
        "program.nombre"
      ]}
      formFields={[
        { label: "Index", class: "col-12 col-md-6 px-0", required: true },
        { label: "Time", class: "col-12 col-md-6 px-0", required: true },
        { label: "Temperature", class: "col-12 col-md-6 px-0", required: true },
        { label: "Nombre", class: "col-12 col-md-6 px-0", required: true }
      ]}
      detailFields={[
        { label: "Index", name: "program.programIndex" },
        { label: "Time", name: "program.time" },
        { label: "Temperature", name: "program.temperature" },
        { label: "Nombre", name: "program.nombre" }
      ]}
      baseObject={{
        program: {
          id: null,
          programIndex: "",
          time: "",
          temperature: ""
        }
      }}
    />
  );
}

