import SysMain from "../routes/SysMain";
import IncubatorModel from "../routes/IncubatorModel";
import IncubatorDistributor from "../routes/IncubatorDistributor";
import Program from "../routes/Program";
import ProductModel from "../routes/ProductModel";
import Product from "../routes/Product";
import Gtin from "../routes/Gtin";
import Company from "../routes/Company";
import GeneralObject from "../routes/GeneralObjects";
import MailConfiguration from "../routes/MailConfiguration";
import MenuConfiguration from "../routes/MenuConfiguration";
import Message from "../routes/Message";
import Module from "../routes/Module";
import Parameter from "../routes/Parameter";
import Screen from "../routes/Screen";
import SpecialObject from "../routes/SpecialObject";
import LicenseProduct from "../routes/LicenseProduct";
import LicensePeriod from "../routes/LicensePeriod";
import License from "../routes/License";
import SysLog from "../routes/SysLog";
import ScoreRPE from "../routes/ScoreRPE";
import SettingValues from "../routes/SettingValues";
import Distributor from "../routes/Distributor";
import LicenseCodes from "../routes/LicenseCodes";
import GlobalBrands from "../routes/GlobalBrands";
import GlobalModels from "../routes/GlobalModels";
import Methods from "../routes/Methods";
import LoadTypes from "../routes/LoadTypes";
import GlobalNorms from "../routes/GlobalNorms";
import { LicenseDateComponent } from "../components/LicenseDate/LicenseDateComponent";

const Sys = [
  { path: "/sysbrand", component: GlobalBrands },

  { path: "/sysmodel", component: GlobalModels },
  { path: "/sysmethod", component: Methods },

  { path: "/sysmain", component: SysMain },
  { path: "/sysdistributors", component: Distributor },

  { path: "/sysincubatormodel", component: IncubatorModel },
  { path: "/sysincubatordistributor", component: IncubatorDistributor },
  { path: "/sysincubatorprogram", component: Program },
  { path: "/sysproductmodel", component: ProductModel },
  { path: "/sysproduct", component: Product },
  { path: "/sysgtin", component: Gtin },

  { path: "/syscompany", component: Company },

  { path: "/sysgeneralobjects", component: GeneralObject },
  { path: "/sysmailconfiguration", component: MailConfiguration },
  { path: "/sysmenu", component: MenuConfiguration },
  { path: "/sysmessage", component: Message },
  { path: "/sysmodule", component: Module },
  { path: "/sysparameter", component: Parameter },
  { path: "/sysscreen", component: Screen },
  { path: "/sysspecialobjects", component: SpecialObject },
  { path: "/SysLog", component: SysLog },

  { path: "/SysLicenseProduct", component: LicenseProduct },
  { path: "/SysLicensePeriod", component: LicensePeriod },
  { path: "/SysLicenses", component: License },

  { path: "/SysScoreRPE", component: ScoreRPE },
  { path: "/SysSettingValues", component: SettingValues },
  { path: "/SysLicenseCodes", component: LicenseCodes },
  { path: "/sysloadtypes", component: LoadTypes },
  { path: "/sysglobalnorms", component: GlobalNorms },
  { path: "/syslicenseeditor", component: LicenseDateComponent },
];

export default Sys;
