import React from 'react';
import { TablePagination } from '@material-ui/core';
import useStyles from "./MaterialPaginationStyles";
import "./MuiIconButton.css";
import ActionsPagination  from './ActionsPagination/ActionsPagination'
import { setRowsPerPageStorage } from "../../../utils/sessionHandler"
import t from "../../../utils/translation";


const MaterialPagination = (props) => {

    const classes = useStyles();
    const { rowsPerPage, setRowsPerPage, page, setPage } = props;

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPageStorage(rowsPerPage);
        setRowsPerPage(rowsPerPage);
        setPage(1);
    };

    return (
        <TablePagination
            component="div"
            page={page - 1}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={({ from, to, count }) => ` ${t("DisplayingPages")} ${from}-${to} ${t("OfTotal")} ${count}`}
            count={props.totalRows}
            onChangePage={handleChangePage}
            rowsPerPageOptions={props.rowsPerPageOptions}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={props.rowsPerPageText}
            classes={classes}
            ActionsComponent={ActionsPagination}
        />
    );
};

export default MaterialPagination;