import React, { createContext, useEffect, useState } from "react";

const PrintLabelContext = createContext();

const PrintLabelProvider = ({ children }) => {
  const [printLabel, setPrintLabel] = useState(
    localStorage.getItem("printLabel") === "true"
  );

  useEffect(() => {
    localStorage.setItem("printLabel", printLabel);
  }, [printLabel]);

  const handlePrintLabel = () => {
    setPrintLabel(!printLabel);
  };

  return (
    <PrintLabelContext.Provider value={{ printLabel, handlePrintLabel }}>
      {children}
    </PrintLabelContext.Provider>
  );
};

export { PrintLabelProvider, PrintLabelContext };
