import React from "react";
import t from "../../utils/translation";
import useStyles from "./SessionExpiredStyles";

import Paper from "../../components/Paper";
import Button from "../../components/Button";
import { logoutExpiredSession } from "../../utils/sessionHandler";

export default function SessionExpired() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.baseRoot}>
          <p className={classes.text}>
            {t("SessionExpiredText") ||
              "Your session has expired, please login again."}
          </p>
          <Button caption={"Login"} onClick={() => logoutExpiredSession()} />
        </div>
      </Paper>
    </div>
  );
}
