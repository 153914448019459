import React, { useState, useRef, useContext, useEffect, useMemo } from "react";

import LocalizationContext from "../../../utils/LocalizationContext";
import TableRowItemIS from "./TableRowItemIS";

import styles from "./IndicatorSelected.module.css";
import TableContainerQ from "../../TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../../TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../../TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TablePaginationQ from "../../TableBionovaQ/TablePaginationQ/TablePaginationQ";
import useTableQ from "../../../utils/Hooks/useTableQ";
import SelectQ from "../../FormComponents/SelectQ/SelectQ";
import FilterDate from "../../FilterDate/FilterDate";
import df from "../../../utils/dateFormater";
import { getRegionalFormat } from "../../../utils/sessionHandler";
import parseDf from "../../../utils/parseDf";
import t from "../../../utils/translation";
const statusOptions = [
  { value: 1, name: t("InProgressSpan") },
  { value: 3, name: t("FinishedSpan") },
  { value: 4, name: t("SavedSpan") },
];

const resultsOptions = [
  { value: 1, name: t("Positive") },
  { value: 2, name: t("Negative") },
  { value: 3, name: t("Canceled") },
];

const IndicatorSelected = ({
  readings,
  selectedIndicator,
  linkHandler,
  charge,
  position,
  handleList,
  totalPages
}) => {
  const [statusFilter, setStatusFilter] = useState("");
  const [resultFilter, setResultFilter] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const locale = useContext(LocalizationContext);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const intervalRef = useRef(null);

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    handleList(position, e.target.value, resultFilter, dateFrom, dateTo, currentPage, itemsPerPage);
  };

  const handleResultFilterChange = (e) => {
    setResultFilter(e.target.value);
    handleList(position, statusFilter, e.target.value, dateFrom, dateTo, currentPage, itemsPerPage);
  };

  const handleDateChange = (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
        handleList(position, statusFilter, resultFilter, value, new Date(), currentPage, itemsPerPage);
      } else {
        handleList(position, statusFilter, resultFilter, value, dateTo, currentPage, itemsPerPage);
      }
    } else if (type === "dateTo") {
      setDateTo(value);
      handleList(position, statusFilter, resultFilter, dateFrom, value, currentPage, itemsPerPage);
    } else if (type === "clear") {
      setDateFrom("");
      setDateTo("");
      handleList(position, statusFilter, resultFilter, "", "", currentPage, itemsPerPage);
    }
  };

  const onLink = (readingBiId) => {
    linkHandler(selectedIndicator.id, readingBiId);

  };



  const {
    currentPage,
    currentItems,
    // totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
    itemsPerPage
  } = useTableQ(readings, [
    "positionNumber",
    "statusDescription",
    "readNumber",
    "result",
    "startedTime",
    "resultDate",
    "averageTemperatureFormat",
    "incubationTime",
    "program.nombre",
  ], totalPages ,10);

  const onChangePage = (value) => {
    handleList(position, statusFilter, resultFilter, dateFrom, dateTo, value, itemsPerPage) 
    handleChangePage(value)
  }

  const onItemsPerPage = (e) => {
    handleList(position, statusFilter, resultFilter, dateFrom, dateTo, currentPage, e.target.value) 
    handleChangeItemsPerPage(e)
  }

  const onCurrentPage = (value) => {
    handleList(position, statusFilter, resultFilter, dateFrom, dateTo, value, itemsPerPage) 
    setCurrentPage(value)
  }

  return (
    <div>
      <div className={styles.linkTableContainer}>
        <div>
          <div className={styles.indicatorInfo}>
            {selectedIndicator && (
              <div>
                <p className={styles.indicatorDetails}>
                  {t("ProductSpan")}: {selectedIndicator.indicator.name} - {t("LotSpan")}:{" "}
                  {selectedIndicator.lot} -
                  {selectedIndicator.serial
                    ? ` ${t("SerialSpan")}: ${selectedIndicator.serial} - `
                    : " "}
                  {t("ZoneSpan")}: {selectedIndicator.indicatorModelArea.description}
                </p>
                <p
                  className={`${styles.indicatorDetails} ${styles.indicatorDetailsBold}`}
                >
                  {t("IncubationsInProgressCompleted")} - {t("LoadNumberSpan")} {charge?.id}
                </p>
                <p
                  className={`${styles.indicatorDetails} ${styles.indicatorDetailsBold}`}
                >
                  {t("PositionSpan")}: {position}
                </p>
              </div>
            )}
          </div>
          <div>
            <div className={styles.filtersRow}>
              <div className={styles.filterItem}>
                <SelectQ
                  selectValue={statusFilter}
                  handleSelect={handleStatusFilterChange}
                  containerWidth={"100%"}
                  options={statusOptions}
                  placeholder={t("SelectStatusOption") || "Select Status"}
                />
              </div>
              <div className={styles.filterItem}>
                <SelectQ
                  selectValue={resultFilter}
                  handleSelect={handleResultFilterChange}
                  containerWidth={"100%"}
                  options={resultsOptions}
                  placeholder={t("SelectResultOption") || "Select Result"}
                />
              </div>
              <div className={styles.filterItem}>
                <FilterDate
                  handleChange={handleDateChange}
                  dateTo={dateTo}
                  dateFrom={dateFrom}
                />
              </div>
            </div> 
            <TableContainerQ>
              <TableHeadQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("statusDescription")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("readNumber")}
                >
                  {t("ReadingSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ onClick={() => handleSortByColumn("result")}>
                {t("ResultSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("startedTime")}
                >
                  {t("StartSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("resultDate")}
                >
                  {t("FinishSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("averageTemperatureFormat")}
                >
                  {t("TemperatureSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("incubationTime")}
                >
                  {t("IncubationTimeSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  onClick={() => handleSortByColumn("programName")}
                >
                  {t("ProgramSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {currentItems.map((rbi, index) => (
                <TableRowItemIS key={index} rbi={rbi} onLink={onLink} />
              ))}
              <TablePaginationQ
                handleChangePage={onChangePage}
                handleChangeItemsPerPage={onItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={onCurrentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
              />
            </TableContainerQ>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSelected;
