import React, { useContext, useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CiCalendar, CiUser } from "react-icons/ci";
import df from "../../utils/dateFormater";
import request from "../../utils/request";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import styles from "./WashCycleCard.module.css";
import { FaLink } from "react-icons/fa6";

const WashCycleCard = ({ charge, startDrag, handleLoadDetail, img }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const locale = useContext(LocalizationContext);

  const toggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const toLink = charge.totalIndicators - charge.vinculatedIndicators

  return (
    <div
      className={styles.chargeCard}
      onClick={() => handleLoadDetail(charge.chargeWashingId)}
      draggable
      onDragStart={(e) => startDrag(e, charge)}
    >
      <div className={styles.iconsContainer}>
        <button
          className={styles.iconButton}
          onClick={(event) => toggleExpand(event)}
        >
          {!isExpanded ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      <div className={styles.chargeBody}>
        <div className={styles.headerCard}>
          <img src={img} alt="progress icon" />
          <div>
            <p className={styles.description}>
              {charge.chargeNumber} - {charge.chargeWashingName}
            </p>
            <p className={styles.chargeText}>{` ${charge.washerName}`}</p>
            {charge.chargeStatus === 4 && toLink > 0 && (
              <div className={styles.indicatorWrapper}>
                <FaLink />
                <p className={styles.unlinked}>
                  {`${t("IndicatorsToLinkSpan")}: `}
                  <span className={styles.count}>
                    {toLink}
                  </span>
                </p>
              </div>
            )}
            {isExpanded && (
              <div>
                <p>
                  {t("ObservationSpan", locale) || "Observations"}:
                  {charge.chargeObservations ? charge.chargeObservations : ""}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <CiCalendar className={styles.icon} />
            <p className={styles.footerText}>
              {df(charge.creationDate, "date")}
            </p>
          </div>

          <div className={styles.footerItem}>
            <CiUser className={`${styles.icon} `} />
            <p className={styles.footerText}>{charge.operator}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WashCycleCard;
