import React from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import { useContext } from "react";
import t from "../../utils/translation";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import df from "../../utils/dateFormater";

const SterilizationDetailTable = ({ dataDetail, charge }) => {
  const packageCard = {
    position: "relative",
    width: "30%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    padding: "7px 12px",
    borderRadius: "4px",
    userSelect: "none",
    marginBottom: "5px",
    backgroundColor: "#fefefe",
    fontFamily: "Montserrat",
  };

  const packageBody = {
    fontSize: "12px",
    fontWeight: "600",
  };

  const listStyle = {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    padding: "0",
    margin: "0",
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
    marginRight: "10px",
  };

  const locale = useContext(LocalizationContext);

  console.log(dataDetail);

  return (
    <div style={packageCard}>
      {dataDetail?.id ? (
        <div style={packageBody}>
          <p style={{ margin: 0, fontSize: "14px" }}>
            {charge ? t("CH", locale) || "CH" : t("PKG", locale) || "PKG"}-
            {dataDetail.id} {dataDetail.description}
          </p>

          <div style={{ marginTop: "15px" }}>
            <div>
              <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                {charge
                  ? t("Packages", locale) || "Packages"
                  : t("Materials", locale) || "Materials"}
              </p>
              <ul style={listStyle}>
                {charge
                  ? dataDetail?.packages?.map((p) => (
                      <li key={p.packageId} style={listItemStyle}>
                        <CheckCircleIcon
                          style={{
                            color: "#00b189",
                            fontSize: "14px",
                            marginRight: "5px",
                          }}
                        />
                        {p.description}
                      </li>
                    ))
                  : dataDetail?.materials?.map((m) => (
                      <li key={m.materialId} style={listItemStyle}>
                        <CheckCircleIcon
                          style={{
                            color: "#00b189",
                            fontSize: "14px",
                            marginRight: "5px",
                          }}
                        />
                        {`(${m.quantity}) `}
                        {m.material.name}
                      </li>
                    ))}
              </ul>
            </div>
            <div style={{ marginTop: "15px" }}>
              <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                {t("Indicators") || "Indicators"}
              </p>
              <ul style={listStyle}>
                {dataDetail?.indicators
                  ?.sort((a, b) => {
                    const nameComparison = a.name.localeCompare(b.name);
                    if (nameComparison !== 0) {
                      return nameComparison;
                    }

                    if (!a.serial && b.serial) {
                      return -1;
                    } else if (a.serial && !b.serial) {
                      return 1;
                    }

                    const lotComparison = (a.lot || "").localeCompare(
                      b.lot || ""
                    );
                    if (lotComparison !== 0) {
                      return lotComparison;
                    }

                    const serialComparison = (a.serial || "").localeCompare(
                      b.serial || ""
                    );
                    if (serialComparison !== 0) {
                      return serialComparison;
                    }

                    return (a.modelArea || "").localeCompare(b.modelArea || "");
                  })
                  ?.map((indicator) => (
                    <li key={indicator.id} style={listItemStyle}>
                      <ErrorOutlineIcon
                        style={{
                          color: "#00b189",
                          fontSize: "14px",
                          marginRight: "5px",
                        }}
                      />
                      {!charge && `(${indicator.quantity}) `}
                      {indicator.indicator.name} -{" "}
                      {indicator.lot ? indicator.lot : indicator.serial}
                      {charge &&
                        ` - ${indicator.indicatorModelArea.description} `}
                    </li>
                  ))}
              </ul>
            </div>
            <p style={{ fontSize: "14px", marginTop: "15px" }}>
              {t("Observations", locale) || "Observations"}:{" "}
              <span style={{ fontWeight: "normal" }}>
                {dataDetail.observations ? dataDetail.observations : ""}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              marginTop: "10px",
              position: "relative",
              bottom: "0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon
                style={{
                  color: "#00b189",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
              />
              <p style={{ margin: 0 }}>
                {df(dataDetail.creationDate, "dateTime")}
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AccountCircleIcon
                style={{
                  color: "#00b189",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
              />
              <p style={{ margin: 0 }}>{dataDetail.creationUserName}</p>
            </div>
          </div>
        </div>
      ) : (
        <h6>
          {charge
            ? t("SelectALoad", locale) || "Select a Load"
            : t("SelectAPackage", locale) || "Select a Package"}
        </h6>
      )}
    </div>
  );
};

export default SterilizationDetailTable;
