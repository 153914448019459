import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        verticalAlign: 'top',
        fontFamily:"Montserrat",
        alignSelf:'center',
    },

    fullwidth: {
        width: '280px',
    },

    inputLabel: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        transform: 'translate(0, 24px) scale(1)',
        display: 'block',
        transformOrigin: 'top left',
        transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        color: 'rgba(01,0,0,0.54)',  
        lineHeight: '1',
        letterSpacing: '0.00938em',
        margin: 0,
        pointerEvents: 'none',
        padding: '0 5px',
        
    },

    inputLabelDark: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        transform: 'translate(-2px, 0px) scale(0.75)',
        display: 'block',
        transformOrigin: 'top left',
        transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        color: 'white', //transparent
        lineHeight: '1',
        letterSpacing: '0.00938em',
        margin: 0,
        pointerEvents: 'none',
        padding: '0 5px'
    },

    inputLabelOutlined :{
        transform: 'translate(14px, 20px) scale(1)',
    },

    focused: {
        '& $inputLabel': {
            color: theme.palette.terragene.darkMain,
        },

        '& $inputOutline': {
            borderColor: `${theme.palette.terragene.darkSecondary}!important`,
            borderWidth: 2,
        },

        '& $inputUnderline::after': {
            transform: 'scaleX(1)',
        },
    },

    inputLabelShrink: {
        '& $inputLabel': {
            transform: 'translate(0, 1.5px) scale(0.75)',
        },
        
        '& $inputLabelOutlined': {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
    },

    inputLabelShrinkBright: {
        '& $inputLabel': {
            transform: 'translate(0, 1.5px) scale(0.75)',
            color: 'white' //black
        },
        
        '& $inputLabelOutlined': {
            transform: 'translate(14px, -6px) scale(0.75)',
            color: 'white' //black
        },
    },

    inputBaseRoot: {
        position: 'relative',
        color: 'rgba(10, 0, 0, 0.87)',
        display: 'inline-flex',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        boxSizing: 'border-box',
        alignItems: 'center',                                         
        lineHeight: '1.1875em',
        fontWeight:"bolder",
        '&:hover $inputOutline': {
            borderColor: 'rgba(0,0,0,0.87)',
        }
    },
    
    inputBaseInput: {
        width: '100%',
        border: 0,
        outline: 0,
        height: '1.1875em',
        margin: 0,
        display: 'block',
        padding: '6px 0 7px',
        minWidth: 0,
        boxSizing: 'content-box',
        marginTop: 16,
        textOverflow: 'ellipsis',
        borderRadius: 10,
    },
    
    inputDetail: {
        height: 'auto'
    },

    outlinedInputInput: {
        padding: '12.5px 13px 10.5px 8px',
        marginTop: 0,
        borderRadius: 5
    },
    
    inputUnderline: {
        '&:hover::before': {
            borderColor: "rgba(0,0,0,0.87)"
        },

        '&::before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: "''",
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            pointerEvents: 'none',
        },

        '&::after': {
            left: 0,
            right: 0,
            bottom: 0,
            content: "''",
            position: 'absolute',
            transform: 'scaleX(0)',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            borderBottom: `2px solid ${theme.palette.terragene.darkMain}`,
            pointerEvents: 'none',
        }
    },

    inputOutline: {
        borderColor: 'rgba(0,0,0,0.23)',
        position: 'absolute',
        top: '-5px',
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        transition: 'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        pointerEvents: 'none',
        padding: '0 5px'
    },

    whiteSpace: {
        padding: 0,
        textAlign: 'left',
        transition: 'width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        lineHeight: '11px',
    },

    hasError: {
        '& $inputLabel': {
            color: theme.palette.terragene.requiredField
        },

        '& $inputOutline': {
            borderColor: `${theme.palette.terragene.requiredField}!important`,
            borderWidth: 2,
        },

        '& $inputUnderline::after': {
            transform: 'scaleX(1)',
            borderBottomColor: theme.palette.terragene.requiredField,
        },
    },

    errorMessage: {
        color: theme.palette.terragene.requiredField,
        margin: 0,
        fontSize: '0.75rem',
        minHeight: '1em',
        textAlign: 'left',
        lineHeight: '1em',
        letterSpacing: '0.03333em',
        marginTop: 8,
    },

    errorMessageContained: {
        margin: '8px 14px 0',
    },

    disabled: {
        '& $inputLabel, & $inputBaseInput, & $inputUnderline::before, & $inputUnderline:hover::before': {
            color: 'rgba(0, 0, 0, 0.38)',
        },

        '& $inputUnderline::before, & $inputUnderline:hover::before': {
            borderColor: 'rgba(0, 0, 0, 0.38)',
        },

        '& $inputUnderline::after': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.38)'
        },

        '& $inputOutline': {
            borderColor: 'rgba(0, 0, 0, 0.38)!important',
            borderWidth: 1,
        }
    }
}))