import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import moment from "moment";
import df from "../../../utils/dateFormater";
import { getRegionalFormat } from "../../../utils/sessionHandler";
import parseFormatDate from "../../../utils/parseFormatDate";

export default function InputDateTimeQ({ handleChange, value, label, category = "Sterilization" }) {
  const handleDateChange = (date) => {
    handleChange(date);
  };
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189", // Color de fondo principal
        contrastText: "#ffffff", // Color del texto sobre el fondo primary
      },
    },
    overrides: {
      MuiIconButton: {
        root: {
          "&.MuiPickersCalendarHeader-iconButton": {
            color: "#ffffff", // Cambia los íconos de navegación del calendario (flechas)
          },
        },
      },

      MuiTab: {
        root: {
          backgroundColor: "#fff", // Color del texto por defecto del botón
          "&$selected": {
            backgroundColor: category.toUpperCase() === "WASHING" ? "#a146f9" : "#00b189", // Fondo del botón seleccionado
            color: "#ffffff", // Color del texto del botón seleccionado
          },
        },
        selected: {}, // Para aplicar los estilos del botón seleccionado
      },
      MuiSvgIcon: {
        root: {
          color: "#333",
          "&.Mui-selected": {
            // Cambia el color del ícono cuando el botón está seleccionado
            color: "#ffffff", // Color del ícono seleccionado
            "& .MuiSvgIcon-root": {
              color: "#ffffff", // Cambia el color del icono al seleccionado
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justifyContent="space-around">
          <KeyboardDateTimePicker
            margin="normal"
            id="date-picker-dialog"
            label={label}
            format={`${parseFormatDate(getRegionalFormat().dateFormat)} ${getRegionalFormat().timeFormat}`}
            value={moment.utc(value).toDate()}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            style={{
              width: "100%",
              backgroundColor: "#f0f0f0",
              border: "none",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: value ? "#fff" : "#f0f0f0",
                padding: "0.75rem",
                paddingTop: "1rem",
                border: "none",
                borderRadius: "7px",
                fontSize: "0.875rem",
                outline: "none",
                transition: "borderColor 0.3s ease, color 0.3s ease",
              },
            }}
            DialogProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#fff", // Fondo del modal
                  color: "#333", // Color del texto en el modal
                  borderRadius: "10px", // Bordes redondeados
                },
              },
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
