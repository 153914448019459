import React, { useEffect, useState } from "react";
import styles from "./StatusIncubators.module.css";
import request from "../../utils/request";
import { getUserId } from "../../utils/sessionHandler";
import IncubatorsCarousel from "../../components/incubatorsCarousel/IncubatorsCarousel";
import ShowIncubator from "../../components/showIncubator/ShowIncubator";
import PageContainer from "../../components/PageContainer/PageContainer";
import autoReaderImg from "../../assets/images/MENU AUTOREADERS.svg";
import t from "../../utils/translation";

const emptyTwelvePositions = [
  [
    { positionNumber: 1, status: "", programId: "-" },
    { positionNumber: 2, status: "", programId: "-" },
    { positionNumber: 3, status: "", programId: "-" },
    { positionNumber: 4, status: "", programId: "-" },
    { positionNumber: 5, status: "", programId: "-" },
    { positionNumber: 6, status: "", programId: "-" },
  ],

  [
    { positionNumber: 12, status: "", programId: "-" },
    { positionNumber: 11, status: "", programId: "-" },
    { positionNumber: 10, status: "", programId: "-" },
    { positionNumber: 9, status: "", programId: "-" },
    { positionNumber: 8, status: "", programId: "-" },
    { positionNumber: 7, status: "", programId: "-" },
  ],
];

const emptyTwoPositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
];
const emptyThreePositions = [
  { positionNumber: 1, status: "", programId: "-" },
  { positionNumber: 2, status: "", programId: "-" },
  { positionNumber: 3, status: "", programId: "-" },
];

export default function StatusIncubators() {
  const [incubators, SetIncubators] = useState([]);
  const [incubator, setIncubator] = useState("");
  const [incubatorCode, setIncubatorCode] = useState(null);
  const [incubatorId, setIncubatorId] = useState(null);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [twelvePositions, setTwelvePositions] = useState([]);
  const [twoPositions, setTwoPositions] = useState([]);
  const [threePositions, setThreePositions] = useState([]);

  useEffect(() => {
    setLoadingCarousel(true);
    request()
      .get(`/api/Incubator?${getUserId()}`)
      .then((response) => {
        SetIncubators(response.data.filter((i) => i.status === 1));
        setLoadingCarousel(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoadingImage(true);
    incubatorId &&
      request()
        .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${incubatorId}`)
        .then((response) => {
          filteredClick(response.data);
          setLoadingImage(false);
        })
        .catch((error) => console.log(error));
  }, [incubatorId]);

  const getPositions = (value) => {
    setLoadingImage(true);
    request()
      .get(`/api/ReadingBI/getreadingsbyincubator?incubatorId=${value.id}`)
      .then((response) => {
        filteredClick(response.data);
        setLoadingImage(false);
      })
      .catch((error) => console.log(error));
  };

  const filteredClick = (value) => {
    incubator.toUpperCase() === "IC1020FR" || incubator == "FRLCD"
      ? handleClickTwelve(value)
      : incubator.toUpperCase() === "MINIPRO" ||
        incubator.toUpperCase() === "MINIBIO"
      ? handleClickThree(value)
      : handleClickTwo(value);
  };

  const onIncubatorHandler = (value) => {
    setIncubator(value.imageName);
    setIncubatorCode(value.toString);
    setIncubatorId(value.id);
    getPositions(value);
  };

  const handleClickTwelve = (array) => {
    const newArr = emptyTwelvePositions.map((row) => {
      return row.map((position) => {
        const matchingNewPosition = array.find(
          (newPosition) =>
            newPosition.positionNumber === position.positionNumber
        );

        if (matchingNewPosition) {
          return matchingNewPosition;
        }

        return position;
      });
    });

    setTwelvePositions(newArr);
  };

  const handleClickTwo = (array) => {
    const updatedPositions = emptyTwoPositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setTwoPositions(updatedPositions);
  };

  const handleClickThree = (array) => {
    const updatedPositions = emptyThreePositions.map((position) => {
      const matchingNewPosition = array.find(
        (newPosition) => newPosition.positionNumber === position.positionNumber
      );

      if (matchingNewPosition) {
        return matchingNewPosition;
      }

      return position;
    });
    setThreePositions(updatedPositions);
  };

  return (
    <PageContainer
      categoryId={1}
      category={"Sterilization"}
      subcategory={"Incubations"}
      title={"Auto-readers Status"}
      backUrl={"/appsterilization"}
      scan={true}
    >
      <div className={styles.status}>
        <div className={styles.connected}>
          <div className={styles.header}>
            <p className={styles.text}>{t("ConnectedAutoReaderSpan")}</p>
            <div className={styles.imageContainer}>
              <img src={autoReaderImg} />
            </div>
          </div>
          <IncubatorsCarousel
            onIncubatorHandler={onIncubatorHandler}
            incubators={incubators}
            loadingCarousel={loadingCarousel}
            incubatorCode={incubatorCode}
            cicle={false}
          />
        </div>
        <div className={styles.show}>
          <div className={styles.header}>
            <p className={styles.text}>{t("SelectedAutoReaderSpan")}</p>
            <p className={`${styles.text} ${styles.green}`}>{incubatorCode}</p>
          </div>
          <ShowIncubator
            incubator={incubator}
            loadingImage={loadingImage}
            twelvePositions={twelvePositions}
            threePositions={threePositions}
            twoPositions={twoPositions}
          />
        </div>
      </div>
    </PageContainer>
  );
}
