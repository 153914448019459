import React, { useReducer, useEffect, useContext, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import useStyles from "./ToolMeasureStyles";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import t from "../../utils/translation";
import _ from "lodash";
import request from "../../utils/request";
import PageContentLoading from "../PageContentLoading";
import LocalizationContext from "../../utils/LocalizationContext";
import CRUD from "../CRUD";
import reducer from "./ToolMeasureReducer";
import dateFormater from "../../utils/dateFormater";
import SplitButton from "../SplitButton/SplitButton";
import { unflatten } from "flat";
import { StatusRead } from "../../dictionaries/statusRead";
import Card from "@material-ui/core/Card";
import Modal from "../Modal/Modal";

const formatToolMeasures = (toolMeasures) => {
  toolMeasures.forEach((toolMeasure) => {
    toolMeasure.creationDate = dateFormater(
      toolMeasure.creationDate,
      "dateTime"
    );
  });
  return toolMeasures;
};

export default function ToolMeasure(props) {
  const theme = useTheme();

  const { method, url, images } = props;
  const initialState = {
    loadingContent: true,
    measures: {},
    measureSelected: {},
    readingToAssociate: props.readingPRO,
    disabledButtons: true,
    loadingDataCRUD: true,
    associateFromBD: null,
    showDownloadSuggestion: false,
    showModal: true,
    instrumentImage: null,
    instrumentDescription: "",
  };

  const classes = useStyles();
  const locale = useContext(LocalizationContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [splitOptions, setSplitOptions] = useState(null);
  //al cerrar el componente, si existe una medición vinculada la vuelve a setear en caso de que el usuario la haya modificado sin haber guardado los cambios.
  const [toolMeasureIdFromDB, setToolMeasureIdFromDB] = useState(
    props.readingPRO["ReadingPRO.toolMeasureId"]
  );

  useEffect(() => {
    searchMeasures();
    configButtons();
    handleAssociateFromDB();
  }, []);

  const searchMeasures = () => {
    request()
      .get(`/api/InstrumentMeasure`)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: "SET_MEASURES",
            payload: { measures: formatToolMeasures(response.data) },
          });
        } else {
          dispatch({ type: "SHOW_DOWNLOAD_SUGGESTION" });
        }
      });
  };

  const configButtons = () => {
    let buttons = [
      {
        text: t("ConfirmButton") || "Confirm",
        onClickOption: (text) => saveAssociate(false),
        disabled: false,
      },
    ];
    if (state.readingToAssociate["ReadingPRO.status"] === StatusRead.FINISHED) {
      buttons.push({
        text: t("ConfirmAndSave"),
        onClickOption: (text) => saveAssociate(true),
        disabled: false,
        moveToSaved: true,
      });
    }
    setSplitOptions(buttons);
  };

  //recupero la medición vinculada a la lectura seleccionada. Envio objeto al componente CRUD para seleccionar dinamicante la medición vinculada.
  const handleAssociateFromDB = () => {
    if (state.readingToAssociate["ReadingPRO.instrumentMeasureId"]) {
      dispatch({ type: "SET_ENABLE_BUTTONS" });
      let objectReading = unflatten(state.readingToAssociate);
      let objectToolMeasure = {
        id: objectReading.ReadingPRO.instrumentMeasureId,
      };
      dispatch({
        type: "SET_MEASURE_TO_SELECTED_DYNAMICALLY",
        payload: { associateFromBD: objectToolMeasure },
      });
    }
  };

  const handleSelectionClick = (measureToAssociate) => {
    state.readingToAssociate["ReadingPRO.instrumentMeasureId"] =
      measureToAssociate.id;
    dispatch({ type: "SET_ENABLE_BUTTONS" });
    state.readingToAssociate["ReadingPRO.toolMeasureId"] =
      measureToAssociate.id;
  };

  const saveAssociate = (moveToSaved) => {
    dispatch({ type: "SET_DISABLED_BUTTONS" });
    let requestObject = unflatten(state.readingToAssociate);
    requestObject.moveToSaved = moveToSaved;

    request()
      .updateObject(url, requestObject, method)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: "SET_ENABLE_BUTTONS" });
          props.handleAfterAssociate(response.data, requestObject);
        }, 1500);
      })
      .catch((error) => {
        console.log(error.response);
        setTimeout(() => {
          dispatch({ type: "SET_ENABLE_BUTTONS" });
          props.handleAfterAssociate(error.response.data, requestObject);
        }, 1500);
      });
  };

  const getImageInstrument = (instrument) => {
    request()
      .get(
        `/api/instrumentmeasure/SASUriInstrumentFile?fileName=` +
          instrument.fileName
      )
      .then((resp) => {
        dispatch({
          type: "SET_INSTRUMENT_IMAGE",
          payload: { instrumentImage: resp.data },
        });
        dispatch({
          type: "SET_INSTRUMENT_DESCRIPTION",
          payload: {
            instrumentDescription:
              instrument.code + " " + instrument.description,
          },
        });
      });
  };

  const closeModalToolMeasure = () => {
    dispatch({
      type: "BEFORE_HIDE_MODAL",
      payload: {
        readingToAssociate: (state.readingToAssociate[
          "ReadingPRO.toolMeasureId"
        ] = toolMeasureIdFromDB),
      },
    });
    props.closeModalToolMeasure();
  };

  const buttonsOptions = splitOptions && !state.showDownloadSuggestion && (
    <SplitButton
      disabled={state.disabledButtons}
      options={splitOptions}
      selectedOption={0}
      localStorageSelectedOption={"updateSplitButtonSelectedOption"}
    ></SplitButton>
  );

  if (state.loadingContent) return <PageContentLoading />;

  return (
    <>
      {state.showModal && (
        <div>
          {!state.showDownloadSuggestion && (
            <CRUD
              data={state.measures}
              order={{ dir: "desc", orderBy: "id" }}
              newOption={false}
              editOption={false}
              deleteOption={false}
              detailOption={false}
              multiSelect={false}
              selectOneRowOnly={false}
              handleSelectionClick={handleSelectionClick}
              selectedDynamically={state.associateFromBD}
              loadingData={state.loadingDataCRUD}
              url={"/api/InstrumentMeasure"}
              title={t("ToolMeasureTitle", locale)}
              columns={[
                "code",
                "description",
                "surfaceFormat",
                "bothSides",
                "creationDate",
              ]}
              headers={[
                t("ColumnHeaderCodeText", locale),
                t("ColumnHeaderInstrumentText", locale),
                t("ColumnHeaderAreaText", locale),
                t("ColumnHeaderBothSidesText", locale),
                t("ColumnHeaderCreationDateText", locale),
              ]}
              idField={"id"}
              fieldsMap={[]}
              tableActions={[
                {
                  icon: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
                  tooltip: t("ImageLabel"),
                  action: (instrument) => getImageInstrument(instrument),
                },
              ]}
            ></CRUD>
          )}
          {state.instrumentImage && (
            <Modal
              header={
                <>
                  <span style={{ float: "left", padding: "10px 24px" }}>
                    {state.instrumentDescription}
                  </span>
                  <div className={classes.headerPopUpImage}>
                    <span
                      className={classes.modalCloseButton}
                      onClick={() =>
                        dispatch({ type: "CLEAR_INSTRUMENT_IMAGE" })
                      }
                    >
                      <svg viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </span>
                  </div>
                </>
              }
              closeModal={() => dispatch({ type: "CLEAR_INSTRUMENT_IMAGE" })}
              responsive
            >
              {!state.instrumentImage && (
                <CircularProgress
                  className={classes.circularProgressRoot}
                  size={20}
                />
              )}
              {state.instrumentImage && (
                <div style={{ textAlign: "center" }}>
                  <img src={state.instrumentImage}></img>
                </div>
              )}
            </Modal>
          )}
          <div>
            {state.showDownloadSuggestion && (
              <>
                <div className={"col-12"} className={classes.textNotFound}>
                  <Card variant="outlined" className={classes.cardPrueba}>
                    {t("MeasurementsNotFoundText", locale)}
                  </Card>
                </div>
                <div
                  className={"col-12"}
                  className={classes.downloadSuggestion}
                >
                  {t("AppDownloadSuggestionText", locale)}
                </div>
                {/* <Card variant="outlined" className={classes.cardPlayStore}>
                                <div>Available on the Play Store</div>
                            </Card>
                            <Card variant="outlined" className={classes.cardAppStore}>
                                <div>Available on the App Store</div>
                            </Card> */}
              </>
            )}
          </div>
          <div className={classes.downloadSuggestion}>
            {t("AppDownloadSuggestionText", locale)}
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.terragene.antinova"
                target="blank"
              >
                <img
                  src={theme.palette.images.google}
                  style={{ width: 150 }}
                ></img>
              </a>
              <a
                href="https://apps.apple.com/us/app/surface-eye/id1604592632"
                target="blank"
              >
                <img
                  src={theme.palette.images.apple}
                  style={{ width: 120 }}
                ></img>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
