import React from "react";

import useTableQ from "../../../utils/Hooks/useTableQ";
import request from "../../../utils/request.js";

import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ.jsx";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../../components/SpinnerQ/SpinnerQ.jsx";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Checkbox } from "@material-ui/core";

const ModelsTable = ({
  companyMachineModels,
  getCompanyMachineModels,
  handleData,
  handleModal,
  handleDetail,
  loading,
}) => {
  const {
    currentPage,
    currentItems,
    totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(companyMachineModels, [
    "companyMachineMake.brand",
    "name",
    "description",
    "method.name",
    "observations",
    "isGlobalModel",
  ]);

  const onEdit = (value) => {
    let item = companyMachineModels.find((m) => m.id === value);

    handleData({
      machineType: item.machineType,
      companyMachineMakeId: item.companyMachineMakeId,
      machineModelId: item.machineModelId,
      name: item.name,
      methodId: item.methodId,
      description: item.description,
      observations: item.observations,
      technicalService: item.technicalService,
      technicalServiceMail: item.technicalServiceMail,
      technicalServicePhone: item.technicalServicePhone,
      modelAreas: item.modelAreas,
      id: item.id,
    });

    handleModal(true);
  };

  const onDelete = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        request()
          .delete("/api/CompanyMachineModel", data)
          .then(() => {
            getCompanyMachineModels();
            toast.success("Your model has been deleted.");
          });
      }
    });
  };

  return (
    <TableContainerQ>
      <TableHeadQ actions={true}>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("isGlobalModel")}
        >
          Is Global
        </TableHeaderCellQ>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("companyMachineMake.brand")}
        >
          Brand
        </TableHeaderCellQ>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("name")}
        >
          Models
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("description")}>
          Description
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("method.name")}>
          Method
        </TableHeaderCellQ>
        <TableHeaderCellQ
          handleClick={() => handleSortByColumn("observations")}
        >
          Observations
        </TableHeaderCellQ>
      </TableHeadQ>
      {loading ? (
        <div className="spinnerContainer">
          <SpinnerQ />
        </div>
      ) : (
        <TableBodyQ>
          {currentItems.map((m) => (
            <TableRowQ
              key={m.id}
              actions={true}
              edit={true}
              detail={true}
              deleteRow={true}
              onEdit={() => onEdit(m.id)}
              onDelete={() => onDelete(m)}
              doubleClick={() => onEdit(m.id)}
              onDetail={() => handleDetail(m)}
            >
              <TableRowCellQ>
                <Checkbox checked={m.isGlobalModel} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText
                  text={m.companyMachineMake.brand}
                  maxLength={25}
                />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.name} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.description} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.method.name} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={m.observations} maxLength={25} />
              </TableRowCellQ>
            </TableRowQ>
          ))}
        </TableBodyQ>
      )}

      <TablePaginationQ
        handleChangePage={handleChangePage}
        handleChangeItemsPerPage={handleChangeItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </TableContainerQ>
  );
};

export default ModelsTable;
