import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import LicenseCodes from "../pages/DistLicenseCodes/DistLicenseCodes";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.License,
    key: "LicenseCodes",
  };

  const locale = useLocale(1201);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          MenuOptions={menu}
          currentPage={currentPage}
        >
          {locale ? <LicenseCodes /> : <PageContentLoading />}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
