import { CircularProgress } from "@material-ui/core";
import React from "react";
import Time from "../../TimeFormat/Time";
import Tooltip from "../../Tooltip/Tooltip";
import TwoCircle from "./TwoCircle";
import SpinnerQ from "../../SpinnerQ/SpinnerQ";

function TwoPositions({
  imgPositionsFiltered,
  loadingImage,
  incubator,
  colorStatus,
  programFiltered,
  twoPositions,
  t,
  locale,
  handleClick,
  position,
  vinculation
}) {
  const twoPositionsMapped = twoPositions.map((p, index) => (
    <TwoCircle handleClick={handleClick}  p={p} programFiltered={programFiltered} t={t} locale={locale} colorStatus={colorStatus} vinculation={vinculation} position={position}/>
  ));

  return incubator == "PHOTON" || incubator == "HYPER" ? (
    loadingImage ? (
      <div className="spinnerContainer">
        <SpinnerQ />
      </div>
    ) : (
      <div
        style={{
          backgroundImage: `url(${imgPositionsFiltered(incubator)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {twoPositionsMapped}
      </div>
    )
  ) : null;
}

export default TwoPositions;
