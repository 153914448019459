import { Modal } from "@material-ui/core";
import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import styles from "./ModalQ.module.css";
import SpinnerQ from "../../SpinnerQ/SpinnerQ";
import SpinnerWashing from "../../SpinnerWashing/SpinnerWashing";

const ModalQ = ({
  open,
  handleSubmit = () => {},
  title,
  children,
  handleClose,
  category = "sterilization",
  loading,
}) => {
  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.closeButton} onClick={handleClose}>
          <IoIosCloseCircleOutline />
        </div>
        <h6 className={styles.modalTitle}>{title}</h6>
        {loading ? <div style={{display: "flex", justifyContent: "center"}}>{category.toUpperCase() === "WASHING" ? <SpinnerWashing /> : <SpinnerQ />} </div>:
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          {children}
        </form>}
      </div>
    </Modal>
  );
};

export default ModalQ;
