import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const DetailPackages = ({ materials }) => {
  const listStyle = {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    padding: "0 5px",
    margin: "0",
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  };

  return (
    <div style={{display: "flex", flexDirection: "column", padding: "5px"}}>
      <label
        style={{
          color: "rgba(1, 0, 0, 1)",
          lineHeight: "1",
          letterSpacing: "0.00938em",
          marginLeft: "5px",
          pointerEvents: "none",
          padding: "0 5px",
          display: "block",
          fontSize: "14px",
          // fontWeight: "lighter",
        }}
      >
        Materials
      </label>
      <ul style={listStyle}>
        {materials?.map((m) => (
          <li key={m.materialId} style={listItemStyle}>
            <CheckCircleIcon
              style={{
                color: "#00b189",
                fontSize: "14px",
                marginRight: "5px",
              }}
            />
            {`(${m.quantity}) `}
            {m.material.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DetailPackages;
