import React from "react";
import propTypes from "prop-types";

import useStyles from "./PaginationStyles";
import { TablePagination } from "@material-ui/core";
import { setRowsPerPageStorage } from "../../../utils/sessionHandler";
import ActionsPagination from "../MaterialPagination/ActionsPagination/ActionsPagination";

const Pagination = props => {
  const classes = useStyles();
  const { rowsPerPage, totalRows, page, setRowsPerPage, rowsPerPageText, handlePagination, order, rowsPerPageOptions } = props;

  const handleChangePage = (event, newPage) => {
    handlePagination(newPage + 1, rowsPerPage, order.orderBy, order.dir)
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPageStorage(rowsPerPage);
    setRowsPerPage(rowsPerPage);
    handlePagination(1, event.target.value, order.orderBy, order.dir)
  };

  return (
    <TablePagination
      component="div"
      page={page - 1}
      rowsPerPage={rowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `(${from}-${to}/${count})`}
      count={totalRows}
      onChangePage={handleChangePage}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelRowsPerPage={rowsPerPageText}
      classes={classes}
      ActionsComponent={ActionsPagination}
    />
  );
};

Pagination.propTypes = {
  rowsPerPage: propTypes.number.isRequired,
  page: propTypes.number.isRequired,
  setPage: propTypes.func.isRequired,
  totalRows: propTypes.number.isRequired,
};

export default Pagination;

