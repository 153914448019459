import { HubConnectionBuilder } from "@microsoft/signalr";
const serverUrl = process.env.REACT_APP_SERVER_URL;

const hubConnection = (urlHub, token) => {
  return new HubConnectionBuilder()
    .withUrl(serverUrl + urlHub,  { accessTokenFactory: () => token })
    .withAutomaticReconnect()
    .build();
}

export default hubConnection;