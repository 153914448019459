export default function reducer(state, action) {
    switch (action.type) {
        case "SET_CONTENT":
            return {
                ...state,
                programs: action.payload.programs,
                processes: action.payload.processes,
                conditionSCIB: action.payload.conditionSCIB,
                sterilizers: action.payload.sterilizers,
                disinfectors: action.payload.disinfectors,
                requiredFields: action.payload.requiredFields,
                productsStatic: action.payload.products,
                unit: action.payload.unit,
                loadingContent: false
            };
        case "TOGGLE_LOADING_DATA":
            return {
                ...state,
                loadingData: !state.loadingData
            };
        case "SET_PAGINATION":
            return {
                ...state,
                pagination: action.payload.pagination
            };
        case "SET_UNIQUE_FIELDS":
            return {
                ...state,
                formUniqueFields: []
            }
        case "SET_DATA":
            return {
                ...state,
                data: action.payload.data
            }
        case "SET_DETAIL_OBJECT":
            return {
                ...state,
                detailObject: action.payload.detailObject,
                data: action.payload.data ?? state.data,
                loadingDetail: action.payload.loadingDetail
            }
        case "SET_HISTORICAL":
            return {
                ...state,
                historical: {
                    toHistoricalObject: 'toHistoricalObject' in action.payload ? action.payload.toHistoricalObject : state.historical.toHistoricalObject,
                    toHistoricalLoading: 'toHistoricalLoading' in action.payload ? action.payload.toHistoricalLoading : state.historical.toHistoricalLoading,
                    toHistoricalMessage: 'toHistoricalMessage' in action.payload ? action.payload.toHistoricalMessage : state.historical.toHistoricalMessage
                }
            }
        case "FORMAT_ARRAY_FORM_FIELD": {
            return {
                ...state,
                formFields: [...action.payload.commonFormFields, ...action.payload.extraFormFields],
                commonFormFields: action.payload.commonFormFields,
                detailFields: [...action.payload.detailCommonFields, ...action.payload.extraDetailsFields],
                productType: action.payload.productType
            }
        }
        case "ADD_STERILIZER":
            return {
                ...state,
                formFields: [...state.commonFormFields, ...action.payload.extraFormFields],
                sterilizers: action.payload.Sterilizers,
                abmSterilizerVisible: false
            };
        case "SHOW_ABM_STERILIZER":
            return {
                ...state,
                abmSterilizerVisible: true
            };
        case "HIDE_ABM_STERILIZER":
            return {
                ...state,
                abmSterilizerVisible: false
            };
        case "ADD_DISINFECTOR":
            return {
                ...state,
                formFields: [...state.commonFormFields, ...action.payload.extraFormFields],
                disinfectors: action.payload.Disinfectors,
                abmDisinfectorVisible: false
            };
        case "SHOW_ABM_DISINFECTOR":
            return {
                ...state,
                abmDisinfectorVisible: true
            };
        case "HIDE_ABM_DISINFECTOR":
            return {
                ...state,
                abmDisinfectorVisible: false
            };
        default:
            throw new Error();
    }
}
