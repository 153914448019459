import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import ValidateURL from "../utils/ValidateURL";
import GlobalNorms from "../pages/GlobalNorms/GlobalNorms";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.RountineTest,
    key: "GlobalNorms",
  };

  return (
    <LocalizationContext.Provider value={null}>
      <ValidateURL currentPage={currentPage}>
        <NavBar MenuOptions={menu} currentPage={currentPage}>
          <GlobalNorms />
        </NavBar>
      </ValidateURL>
    </LocalizationContext.Provider>
  );
};
