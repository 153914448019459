import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    root: {
        width: '100%',
        cursor: 'pointer',
        '& > div': {
          minHeight: 60,
          width: 350,
          alignItems: 'center'
        },
      },
})
