export const parseJwtData = (jwt) => {
    return JSON.parse(
        parseJwt(jwt).unique_name
    );
};

export const parseJwtExp = (jwt) => {
    return parseJwt(jwt).exp;
};

const parseJwt = (jwt) => {
    return JSON.parse( Buffer.from(jwt.split(".")[1], 'base64') );
};