import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        display:'flex',
        height:'100vh',
        justifyContent:'center',
        alignItems:'center',
        background: 'white',
    },
    
    baseRoot: {
        display:'flex',
        flexDirection:'column'
    },
    
    text: {
        color: 'black',
        fontWeight: 'bold'
    }
}))