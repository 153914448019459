import React from "react";
import styles from "./InfoBar.module.css";

function InfoBar({ title, data, color }) {
  return (
    <div className={`${styles.container} ${styles[color]}`}>
      <div className={styles.spanContainer}><span>{data?.length}</span></div>
      <p>{title}</p>
    </div>
  );
}

export default InfoBar;
