import React, { useState, useEffect } from 'react';
import request from '../../utils/request';
import Button from '../Button';
import FixedAlert from '../FixedAlert/FixedAlert';
import t from "../../utils/translation";
import Input from '../Input';
import { getUserId } from '../../utils/sessionHandler';

const QuickSterilizer = ({ onClickCallBack }) => {
    const screenId = 305
    const [sterilizer, setSterilizer] = useState({})
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({});
    const [temporalTranslations, setTemporalTranslations] = useState({})

    useEffect(() => {
        request().get(`/api/screen/getjson?ScreenNameId=${screenId}&UserLoggedId=${getUserId()}`)
            .then(response => {
                setTemporalTranslations(response.data);
            })
    }, [])

    const isValidForm = () => {
        let localErrors = {}
        let isValid = true;
        if(!sterilizer.Name){
            localErrors.Name = t("RequiredFieldError")
            isValid = false
        }
        if(!sterilizer.Brand){
            localErrors.Brand = t("RequiredFieldError")
            isValid = false
        }
        if(!sterilizer.Serial){
            localErrors.Serial = t("RequiredFieldError")
            isValid = false
        }
        setErrors(localErrors)
        return isValid;
    }

    return (
        <div>
            <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
            <Input
                label={t("NameLabel", temporalTranslations)}
                value={sterilizer.Name}
                onChange={(value) => setSterilizer({ ...sterilizer, Name: value })}
                error={errors.Name}
            />
            <Input
                label={t("BrandLabel", temporalTranslations)}
                value={sterilizer.Brand}
                onChange={(value) => setSterilizer({ ...sterilizer, Brand: value })}
                error={errors.Brand}
            />
            <Input
                label={t("SerialLabel", temporalTranslations)}
                value={sterilizer.Serial}
                onChange={(value) => setSterilizer({ ...sterilizer, Serial: value })}
                error={errors.Serial}
            />
            <Button caption={t("ConfirmButton")} onClick={(e) => {
                if(isValidForm()){
                    request().post(`/api/Sterilizer/QuickSave`, { Sterilizer: sterilizer }).then(resp => {
                        sterilizer.Id = resp.data
                        onClickCallBack(sterilizer)
                    }).catch(err => {
                        setError(err?.response?.data?.Message)
                    })
                }
            }}></Button>
        </div>
    );
};

export default QuickSterilizer;