import React, { useState, useEffect, useReducer } from "react";
import request from "../../utils/request";

import CRUD from "../../components/CRUD";
import reducer from './SettingValueReducer';

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = { zoneTypes: [], processBI: [], loadingContent: true };

export default function SettingValues() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { zoneTypes, processBI } = state;

  useEffect(() => {
    Promise.all([
      request().get("/api/SettingValue/getZoneTypes"),
      request().get(`/api/SettingValue/getProcessBI`),
    ])
      .then(([zoneTypesData, processBIData]) => {
        setTimeout(() => {
          dispatch({
            type: "SET_CONTENT",
            payload: {
              zoneTypes: formatSelectObject(zoneTypesData.data, ""),
              processBI: formatSelectObject(processBIData.data, ""),
            },
          });
        }, 250);
      })
      .catch((error) => console.log(error.response));
  }, []);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/SettingValue`)
      .then((response) => {
        setData(response.data);

        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      data={data}
      loadingData={loadingData}
      url={"/api/SettingValue"}
      updateData={updateData}
      title={"Setting values"}
      columns={[
        "processBIDescription",
        "valueDFrom",
        "valueDTo",
        "zoneDescription",
      ]}
      headers={["Process", "ValueD From", "ValueD To", "Zone"]}
      idField={"id"}
      fieldsMap={["processBI", "valueDFrom", "valueDTo", "zone"]}
      formFields={[
        {
          label: "Process",
          class: "col-12 col-md-6 px-0",
          required: true,
          type: "select",
          options: processBI,
        },
        { label: "From", class: "col-12 col-md-6 px-0", required: true },
        { label: "To", class: "col-12 col-md-6 px-0", required: true },
        {
          label: "Zone",
          class: "col-12 col-md-6 px-0",
          required: true,
          type: "select",
          options: zoneTypes,
        },
      ]}
      detailFields={[
        { label: "Process", name: "processBIDescription" },
        { label: "From", name: "valueDFrom" },
        { label: "To", name: "valueDTo" },
        { label: "Zone", name: "zoneDescription" },
      ]}
      baseObject={{
        id: null,
        processBI: "",
        zone: "",
        valueDFrom: "",
        valueDTo: "",
      }}
    />
  );
}
