import React from "react";
import styles from "./SterilizersDetail.module.css";
import ModalQ from "../../../components/TableBionovaQ/ModalQ/ModalQ";

const SterilizersDetail = ({
  sterilizerData,
  t,
  locale,
  handleClose,
  open,
}) => {
  return (
    <ModalQ handleClose={handleClose} open={open} title={"Sterilizer Detail"}>
      <div className={styles.detailContainer}>
        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Name", locale) || "Name"}:
            </label>
            <div className={styles.detailValue}>
              {sterilizerData.name || "-"}
            </div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Brand", locale) || "Brand"}:
            </label>
            <div className={styles.detailValue}>
              {sterilizerData.brand || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Model", locale) || "Model"}:
            </label>
            <div className={styles.detailValue}>
              {sterilizerData.companyMachineModel?.name || "-"}
            </div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Method", locale) || "Method"}:
            </label>
            <div className={styles.detailValue}>
              {sterilizerData.companyMachineModel?.method.name || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Observations", locale) || "Observations"}:
            </label>
            <div className={styles.detailValue}>
              {sterilizerData.description || "-"}
            </div>
          </div>
        </div>
      </div>
    </ModalQ>
  );
};

export default SterilizersDetail;
