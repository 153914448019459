import React, { useEffect, useState } from "react";
import request from "../../../utils/request";
import Select2 from "../../../components/Select";
import Button from "../../Button";
import t from "../../../utils/translation";
import { getUserId } from "../../../utils/sessionHandler";
import FixedAlert from "../../../components/FixedAlert/FixedAlert";
import Button2 from "../../Button2";

const NewPanel = ({ currentUserPanels, createDash, translations, panelOptios }) => {
  const [state, setState] = useState({
    panels: [],
    error: "",
  });

  useEffect(() => {
    cutAlreadyExisting(state.panels, currentUserPanels);
  }, [currentUserPanels, state.panels])

  useEffect(() => {
    request()
      .get(`/api/Panel/GetActives`)
      .then((resp) => {
        let panelOpt = [];
        resp.data.forEach((p, i) => {
          if (panelOptios.some(opt => opt == p.id)) panelOpt.push(p);
        })
        setState({
          ...state,
          panels: formatSelectObject(panelOpt, t("ChoosePanel", translations)),
        });
      });
  }, [currentUserPanels]);

  const cutAlreadyExisting = (panels, currentUserPanels) => {
    if(currentUserPanels.length > 0 && panels.length > 0){
       currentUserPanels.forEach((value, i) => {
         let panelIndex = panels.findIndex(x => x[0] === value.panel.id)
         if (panelIndex >= 0) panels.splice(panelIndex, 1);
       })
       setState({
        ...state,
        panels: panels,
        selectedPanel: 0
      });
    }
  };

  const formatSelectObject = (objectData, emptyText) => {
    return objectData.reduce(
      (objectsList, object) => {
        objectsList.push([object.id, object.name]);
        return objectsList;
      },
      [[null, emptyText]]
    );
  };

  const onClickAddPanel = () => {
    const panel = state.panels[state.selectedPanel]
    request()
      .post(`/api/PanelUser/`, {
        AppUserId: getUserId(),
        PanelId: panel[0],
      })
      .then(() => {
        createDash();
      })
      .catch((error) => {
        setState({ ...state, error: error?.response?.data?.message });
      });
  };
  return (
    <div className={"container-fluid"}>
      <div className="row">
        <div className={"col-lg-5"}>
          <Select2
            getIndex={true}
            value={
              state.selectedPanel ? state.panels[state.selectedPanel][0] : ""
            }
            label={t("Panel", translations)}
            options={state.panels}
            onChange={(val) => setState({ ...state, selectedPanel: val })}
          />
        </div>
        <div className={"col-lg-2"} style={{padding: 0}}>
          <div style={{ marginTop: 22 }}>
            <Button2 caption={t("AddButton")} onClick={onClickAddPanel}></Button2>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-lg-12"}>
          <FixedAlert
            text={state.error}
            type={"error"}
            closeErrorCb={() => {
              setState({...state, error: ''})
            }}
          ></FixedAlert>
        </div>
      </div>
    </div>
  );
};

export default NewPanel;
