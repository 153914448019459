import React from "react";
import styles from "./BrandsDetail.module.css";
import ModalQ from "../../../components/TableBionovaQ/ModalQ/ModalQ";

const BrandsDetail = ({ brandData, t, locale, handleClose, open }) => {
  return (
    <ModalQ handleClose={handleClose} open={open} title={"Brand Detail"}>
      <div className={styles.detailContainer}>
        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Brand", locale) || "Brand"}:
            </label>
            <div className={styles.detailValue}>{brandData.brand || "-"}</div>
          </div>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Description", locale) || "Description"}:
            </label>
            <div className={styles.detailValue}>
              {brandData.description || "-"}
            </div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <div className={styles.detailItem}>
            <label className={styles.detailLabel}>
              {t("Observation", locale) || "Observation"}:
            </label>
            <div className={styles.detailValue}>
              {brandData.observations || "-"}
            </div>
          </div>
        </div>
      </div>
    </ModalQ>
  );
};

export default BrandsDetail;
