import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import User from "../pages/User";
import PageContentLoading from "../components/PageContentLoading";
import NavBar from "../components/NavBar";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Company,
    key: "User",
  };
  const screenName = 120
  const locale = useLocale(screenName);

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{ locale: locale }}>
        <NavBar
          screenName={screenName}
          moduleName={5}
          MenuOptions={menu}
          currentPage={currentPage}
        >
          {locale ? <User /> : <PageContentLoading />}
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};

