import React from 'react'
import styles from './HomeMenuCard.module.css'
import Redirect from '../../../utils/Redirect'


export default function HomeMenuCard({image, title, url, categoryId}) {

    const imageColor = categoryId === 1 ? styles.sterilization : styles.washing
  return (
    <div className={styles.card}  onClick={() =>
      Redirect({
        redirect: true,
        path: url,
        // parameters: { moduleId: moduleName, screenId: screenName },
      })
    }>
        <div className={`${styles.imageContainer} ${imageColor}`}>
        <img  src={image} className={styles.image}/>
        </div>
        <p className={styles.title}>{title}</p>
    </div>
  )
}
