import React, { useContext, useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import Swal from "sweetalert2";
import PageContainer from "../../../components/PageContainer/PageContainer";
import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ.jsx";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";

import useTableQ from "../../../utils/Hooks/useTableQ";
import LocalizationContext from "../../../utils/LocalizationContext";
import request from "../../../utils/request";
import df from "../../../utils/dateFormater";
import Redirect from "../../../utils/Redirect";
import styles from "./ManageTrays.module.css";
import SelectQ from "../../../components/FormComponents/SelectQ/SelectQ.jsx";
import FilterDate from "../../../components/FilterDate/FilterDate.jsx";
import printTicket from "../../../utils/printTicket.js";
import useConfirmation from "../../../utils/Hooks/useConfirmation/useConfirmation.js";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../../components/SpinnerQ/SpinnerQ.jsx";
import PackageWashingDetail from "../../../components/PackageWashingDetail/PackageWashingDetail.jsx";
import PackageLabel from "../../../components/packageLabel/PackageLabel.jsx";
import withReactContent from "sweetalert2-react-content";
import InputQ from "../../../components/FormComponents/InputQ/InputQ.jsx";
import parseFiltersDate from "../../../utils/parseFiltersDate.js";
import { getRegionalFormat } from "../../../utils/sessionHandler.js";
import SpinnerWashing from "../../../components/SpinnerWashing/SpinnerWashing.jsx";
import t from "../../../utils/translation.js";

export default function ManageTrays() {
  const [trays, setTrays] = useState([]);
  const [trayDetail, setTrayDetail] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [status, setStatus] = useState(5);
  const [packageWashingNumber, setPackageWashingNumber] = useState("");
  const [method, setMethod] = useState("");
  const [methods, setMethods] = useState([]);
  // const [indicatorsWithoutDetail, setIndicatorsWithoutDetail] = useState(null);

  const [loadingTrays, setLoadingTrays] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [name, setName] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const locale = useContext(LocalizationContext);
  const { confirm } = useConfirmation();

  // const getTrays = async () => {
  //   setLoadingTrays(true);
  //   try {
  //     const response = await request().get(`/api/packageWashing`);
  //     setTrays(
  //       response.data.filter(
  //         (p) => p.packageWashingStatus === 1 || p.packageWashingStatus === 2
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error fetching trays:", error);
  //   } finally {
  //     setLoadingTrays(false);
  //   }
  // };

  const getTrays = async () => {
    setLoadingTrays(true);

    try {
      const response = await request().get(
        `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageWashingNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFrom, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateTo, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );
      setTrays(response.data.packageWashingList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching trays:", error);
    } finally {
      setLoadingTrays(false);
    }
  };

  const getMethods = () => {
    request()
      .get("/api/method/2")
      .then((res) =>
        setMethods(
          res.data.map((m) => ({ value: m.id, name: m.methodTranslation }))
        )
      );
  };

  const handleSearch = (e) => {
    setName(e.target.value);
    setLoadingTrays(true);

    try {
      request()
        .get(
          `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageWashingNumber}&status=${status}&page=${currentPage}&search=${
            e.target.value
          }&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setTrays(res.data.packageWashingList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching trays:", err);
    } finally {
      setLoadingTrays(false);
    }
  };

  const handlePackageNumber = (e) => {
    setPackageWashingNumber(e.target.value);
    setLoadingTrays(true);

    try {
      request()
        .get(
          `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${
            e.target.value
          }&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setTrays(res.data.packageWashingList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching trays:", err);
    } finally {
      setLoadingTrays(false);
    }
  };

  const handleMethod = (e) => {
    setMethod(e.target.value);
    setLoadingTrays(true);

    try {
      request()
        .get(
          `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageWashingNumber}&status=${status}&page=${currentPage}&search=${name}&method=${
            e.target.value
          }&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setTrays(res.data.packageWashingList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching trays:", err);
    } finally {
      setLoadingTrays(false);
    }
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setLoadingTrays(true);

    try {
      request()
        .get(
          `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageWashingNumber}&status=${
            e.target.value
          }&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
            df(dateFrom, "date"),
            getRegionalFormat().dateFormat
          )}&dateTo=${parseFiltersDate(
            df(dateTo, "date"),
            getRegionalFormat().dateFormat,
            true
          )}`
        )
        .then((res) => {
          setTrays(res.data.packageWashingList);
          setTotalPages(res.data.totalPages);
        });
    } catch (err) {
      console.error("Error fetching trays:", err);
    } finally {
      setLoadingTrays(false);
    }
  };

  // const getPackageIndicators = async (packageWashingGeneralId) => {
  //   try {
  //     // Realiza la solicitud al endpoint
  //     const response = await request().get(
  //       `/api/packageWashing/info?packageId=${packageWashingGeneralId}`
  //     );

  //     // Extrae los indicadores del response.data
  //     const indicators = response.data.indicators;

  //     setIndicatorsWithoutDetail({
  //       indicators,
  //     });
  //   } catch (error) {
  //     // Maneja el error en caso de que la solicitud falle
  //     console.error("Error fetching package indicators:", error);
  //   }
  // };

  const getStatusName = (id) => {
    return id === 1 ? t("AssemblingSpan") : t("ReadyForLoadSpan");
  };

  const getStatusColor = (id) => {
    return id === 1 ? "yellow" : "purple";
  };

  const onEdit = (packageWashingGeneralId) => {
    localStorage.setItem("trayEditId", packageWashingGeneralId);
    Redirect({
      redirect: true,
      path: "/appcreatetrays",
    });
  };

  const onDelete = (packageWashingGeneralId) => {
    confirm({
      title: "Delete Package",
      text: "Are you sure you want to delete this package?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      onConfirm: () => handleDelete(packageWashingGeneralId),
    });
  };

  const handleDelete = (packageWashingGeneralId) => {
    const objectToDelete = trays.find(
      (pkg) => pkg.packageWashingGeneralId === packageWashingGeneralId
    );

    const adjustedObject = {
      ...objectToDelete,
      method: {
        name: objectToDelete.methodName,
      },
      creationDate: objectToDelete.date,
      id: objectToDelete.packageWashingGeneralId,
    };
    request()
      .delete(`/api/packageWashing`, adjustedObject)
      .then(() => {
        const newData = trays.filter(
          (pkg) => pkg.packageWashingGeneralId !== packageWashingGeneralId
        );
        setTrays(newData);
        setTrayDetail(null);
      });
  };

  const handlePackageDetail = (packageWashingGeneralId) => {
    let item = trays.find(
      (p) => p.packageWashingGeneralId === packageWashingGeneralId
    );

    setTrayDetail(item);

    setIsDetailVisible(true);

    // const packageIndicator = getPackageIndicators(packageWashingGeneralId);
    // setIndicatorsWithoutDetail(packageIndicator);
  };

  // useEffect(() => {
  //   getTrays();
  // }, []);

  const {
    currentPage,
    currentItems,
    // totalPages,
    itemsPerPage,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(
    trays,
    [
      "packageWashingNumber",
      "date",
      "description",
      "methodId",
      "packageWashingStatus",
    ],
    totalPages
  );

  useEffect(() => {
    getMethods();
  }, []);
  useEffect(() => {
    getTrays();
  }, [itemsPerPage, currentPage]);

  const handleChangeDate = async (value, type) => {
    if (type === "dateFrom") {
      setDateFrom(value);
      if (!dateTo) {
        setDateTo(new Date());
      }
    } else if (type === "dateTo") {
      setDateTo(value);
    } else {
      setDateFrom("");
      setDateTo("");
    }

    try {
      const dateFromValue =
        type === "clear" ? "" : type === "dateFrom" ? value : dateFrom;
      const dateToValue =
        type === "clear"
          ? ""
          : type === "dateTo"
          ? value
          : dateTo || new Date();

      const response = await request().get(
        `/api/PackageWashing/list?includeCount=${true}&pageSize=${itemsPerPage}&number=${packageWashingNumber}&status=${status}&page=${currentPage}&search=${name}&method=${method}&dateFrom=${parseFiltersDate(
          df(dateFromValue, "date"),
          getRegionalFormat().dateFormat
        )}&dateTo=${parseFiltersDate(
          df(dateToValue, "date"),
          getRegionalFormat().dateFormat,
          true
        )}`
      );

      setTrays(response.data.packageWashingList);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching trays:", error);
    } finally {
      setLoadingTrays(false);
    }
  };

  const arrayStatus = [
    { value: 5, name: t("SelectStatusOption") },
    { value: 1, name: t("AssemblingSpan") },
    { value: 2, name: t("ReadyForLoadSpan") },
  ];

  const getPrintPackage = (id) => {
    request()
      .get(`/api/packageWashing/info?packageId=${id}`)
      .then((res) => {
        showPrintAlert(res.data);
      });
  };

  const showPrintAlert = (trayDetail) => {
    const ReactSwal = withReactContent(Swal);
    ReactSwal.fire({
      html: <PackageLabel trayDetail={trayDetail} />,
      background: "var(--grey)",
      showConfirmButton: true,
      confirmButtonText: "Print",
      customClass: {
        popup: "your-popup-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        printTicket(trayDetail);
      }
    });
  };

  return (
    <PageContainer
      categoryId={2}
      currentStep={4}
      category={t("WashingSpan")}
      subcategory={t("TraysSpan")}
      title={t("ManageTraysSpan")}
      newButton={isDetailVisible ? false : true}
      newButtonUrl={"/appcreatetrays"}
      backUrl={"/appwashingmenu"}
      progress={true}
      subtitle={isDetailVisible ? t("DetailSpan") : null}
      clickBack={isDetailVisible ? true : null}
      onClickBack={() => setIsDetailVisible(false)}
    >
      <div className={styles.container}>
        {!isDetailVisible ? (
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <InputQ
                icon={<FiPackage />}
                handleChange={handlePackageNumber}
                placeholder={t("NumberSpan")}
                label={t("NumberSpan")}
                value={packageWashingNumber}
                containerWidth="100%"
                category="washing"
              />
              <FilterDate
                handleChange={handleChangeDate}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
              <InputQ
                icon={<FiPackage />}
                items={trays}
                property={"Name"}
                handleChange={handleSearch}
                placeholder={t("NameSpan")}
                label={t("NameSpan")}
                value={name}
                containerWidth="100%"
                category="washing"
              />

              <SelectQ
                placeholder={t("SelectMethodOption")}
                options={methods}
                handleSelect={handleMethod}
                selectValue={method}
                containerWidth={"100%"}
                category="washing"
              />
              <SelectQ
                options={arrayStatus}
                handleSelect={handleStatus}
                selectValue={status}
                containerWidth={"100%"}
                category="washing"
              />
            </div>
            <TableContainerQ>
              <TableHeadQ actions={true}>
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("packageWashingNumber")}
                >
                  {t("NumberSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  date={true}
                  handleClick={() => handleSortByColumn("date")}
                >
                  {t("DateSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("description")}
                >
                  {t("NameSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("methodName")}
                >
                  {t("MethodSpan")}
                </TableHeaderCellQ>
                <TableHeaderCellQ
                  handleClick={() => handleSortByColumn("packageWashingStatus")}
                >
                  {t("StatusSpan")}
                </TableHeaderCellQ>
              </TableHeadQ>
              {loadingTrays ? (
                <div className="spinnerContainer">
                  <SpinnerWashing />
                </div>
              ) : (
                <TableBodyQ>
                  {currentItems.map((p) => (
                    <TableRowQ
                      key={p.packageWashingGeneralId}
                      actions={true}
                      edit={p.packageWashingStatus === 1}
                      detail={
                        p.packageWashingStatus === 1 ||
                        p.packageWashingStatus === 2
                      }
                      deleteRow={true}
                      // onClick={() => handlePackageDetail(p.id)}
                      onEdit={() => onEdit(p.packageWashingGeneralId)}
                      onDelete={() => onDelete(p.packageWashingGeneralId)}
                      doubleClick={() =>
                        p.packageWashingStatus === 1 &&
                        onEdit(p.packageWashingGeneralId)
                      }
                      onDetail={() =>
                        handlePackageDetail(p.packageWashingGeneralId)
                      }
                    >
                      <TableRowCellQ date={true}>
                        <TruncatedText
                          text={p.packageWashingNumber}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ date={true}>
                        <TruncatedText
                          text={df(p.date, "date")}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.description} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <TruncatedText text={p.methodName} maxLength={25} />
                      </TableRowCellQ>
                      <TableRowCellQ>
                        <span
                          className={`${styles.statusColor} ${
                            styles[getStatusColor(p.packageWashingStatus)]
                          }`}
                        ></span>
                        <TruncatedText
                          text={getStatusName(p.packageWashingStatus)}
                          maxLength={25}
                        />
                      </TableRowCellQ>
                    </TableRowQ>
                  ))}
                </TableBodyQ>
              )}

              <TablePaginationQ
                handleChangePage={handleChangePage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </TableContainerQ>
          </div>
        ) : (
          <div className={styles.detailContainer}>
            <PackageWashingDetail
              packageId={trayDetail.packageWashingGeneralId}
              indicatorsWithoutDetail={true}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
}
