export default function reducer(state, action) {
  switch (action.type) {
    case "setData": {
      return {
        ...state,
        data: action.payload.data,
        idField: action.payload.idField
      };
    }
    case "openNewObject": {
      return {
        ...state,
        openForm: true,
        selectedObjet: {},
        mode: "new"
      };
    }
    case "openMassiveModify": {
      return {
        ...state,
        openMassive: true,
        mode: "massive"
      };
    }
    case 'selectRow': {
      if (state.selectedObjectsId.includes(action.payload[state.idField])) {
        return {
          ...state,
          selectedObjectsId: state.selectedObjectsId.filter(x => x !== action.payload[state.idField]),
          selectedObjectsList: state.selectedObjectsList.filter(x => x !== action.payload)
        }
      }
      return {
        ...state,
        selectedObjectsId: [...state.selectedObjectsId, action.payload[state.idField]],
        selectedObjectsList: state.selectedObjectsList.concat(action.payload)
      }
    }
    case 'clearSelected': {
      return {
        ...state,
        selectedObjectsId: [],
        selectedObjectsList: [],
      }
    }
    case "openUpdateObject": {
      return {
        ...state,
        openForm: true,
        selectedObjet: action.payload,
        mode: "update"
      };
    }
    case "closeModal": {
      return {
        ...state,
        openForm: false,
        openMassive: false
      };
    }
    case "openObjectDetail": {
      return {
        ...state,
        openDetail: true,
        selectedObjet: action.payload,
      };
    }
    case "closeDetail": {
      return {
        ...state,
        openDetail: false
      };
    }
    case "saveSuccess": {
      return {
        ...state,
        selectedObjet: {},
        openForm: false,
        openMassive: false,
        successMessage: action.payload,
        showSuccessMessage: true
      };
    }
    case "hideSuccessMessage": {
      return {
        ...state,
        successMessage: '',
        showSuccessMessage: false
      };
    }
    case "saveError": {
      return {
        ...state,
        selectedObjet: {},
        openForm: false,
        openMassive: false,
        errorMessage: action.payload,
        showErrorsMessage: true
      };
    }
    case "hideErrorMessage": {
      return {
        ...state,
        errorMessage: '',
        showErrorsMessage: false
      };
    }
    case "openConfirmation": {
      return {
        ...state,
        openConfirmation: true,
        objectToDelete: action.payload
      };
    }
    case "closeConfirmation": {
      return {
        ...state,
        openConfirmation: false,
        objectToDelete: {}
      };
    }
    case "delete": {
      return {
        ...state,
        openConfirmation: false,
        data: state.data.filter(
          x => x[state.idField] !== state.objectToDelete[state.idField]
        ),
        objectToDelete: null
      };
    }
    case "SET_MODE_SELECTION":
      return {
        ...state,
        selectOneRowOnly: action.payload.selectOneRowOnly
      }
    case "SELECT_ONE_ROW":
      return {
        ...state,
        selectedObjectsId: [action.payload[state.idField]],
        selectedObjectsList: state.selectedObjectsList.concat(action.payload)
      }
    default:
      throw new Error();
  }
}
