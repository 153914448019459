import React, { useEffect, useRef, useState } from "react";
import JsBarcode from "jsbarcode";
import styles from "./PackageLabel.module.css";
import { getSession } from "../../utils/sessionHandler";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";

const PackageLabel = ({ packageDetail }) => {
  const barcodeRef = useRef(null);
  const barcodeValue = `PKG${packageDetail?.packageNumber}`;
  const [loading, setLoading] = useState(false);

  const [idCompany, setIdCompany] = useState("");

  useEffect(() => {
    const infouser = getSession();

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          if (barcodeRef.current) {
            JsBarcode(
              barcodeRef.current,
              `${infouser.IdDistributorCompany.replace(/-/g, "")}${
                packageDetail?.packageGeneralId
              }`,
              {
                format: "CODE128",
                displayValue: false,
                fontSize: 12,
                height: 60,
                margin: 0,
              }
            );
          }
          setIdCompany(infouser.IdDistributorCompany.replace(/-/g, ""));
        });
    else return;
  }, [barcodeValue]);

  return (
    <div className={styles.containerPrint}>
      <div className={styles.label}>
        <p className={styles.line}>
          {packageDetail?.packageNumber} - {packageDetail?.description}
        </p>
        <p className={styles.line}>
          {t("OperatorSpan")}:{" "}
          {packageDetail?.operator}
        </p>
        <p className={styles.line}>
        {t("DateSpan")}: {df(packageDetail?.creationDate, "dateTime")}
        </p>
        <hr className={styles.divider} />
      </div>
      <div className={styles.barcode}>
        <canvas ref={barcodeRef}></canvas>{" "}
        <p
          className={styles.barcodeText}
        >{`${idCompany}${packageDetail?.packageNumber}`}</p>{" "}
      </div>
      <p className={styles.line}>
      {t("ObservationSpan")}:{" "}
          {packageDetail?.observations}
        </p>
    </div>
  );
};

export default PackageLabel;
