import React, { useState, useEffect } from "react";
import Redirect from "../../utils/Redirect";
import t from "../../utils/translation";
import { useContext } from "react";
import LocalizationContext from "../../utils/LocalizationContext";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import SterilizationItemTable from "./SterilizationItemTable";

const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: "#00b189 !important",
    color: "white !important",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
  },

  headerText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "1.5rem",
    color: "#5a5a5d",
  },
  footerAction: {
    position: "absolute",
    right: 20,
    "& *": {
      margin: "0 8px",
    },
  },
}));

const SterilizationTable = ({
  data,
  handleDataDetail,
  handleData,
  ticket,
  selectedData,
  charge,
  methods,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const locale = useContext(LocalizationContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEdit = (id) => {
    localStorage.setItem(charge ? "chargeEditId" : "packageEditId", id);
    Redirect({
      redirect: true,
      path: ticket
        ? `/appticketpackages/${id}`
        : charge
        ? "/appcreatecharges"
        : "/appcreatepackages",
    });
  };



  const methodName = (id) => {
    const itemName = methods?.find((m) => m.id === id)?.name;

    return itemName;
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        width: "68%",
        userSelect: "none",
        fontFamily: "montserrat",
        overflow: "visible",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "80%" }}>
              {charge
                ? t("Loads", locale) || "Loads"
                : t("Packages", locale) || "Packages"}
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              {t("Methods", locale) || "Methods"}
            </TableCell>
            <TableCell style={{ width: "20%" }}>
              {t("Actions", locale) || "Actions"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <SterilizationItemTable
                key={index}
                index={index}
                onEdit={onEdit}
                data={data}
                handleDataDetail={handleDataDetail}
                charge={charge}
                item={item}
                classes={classes}
                handleData={handleData}
                selectedData={selectedData}
                ticket={ticket}
                methodName={methodName(item?.methodId)}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default SterilizationTable;
