import React from "react";
import styles from "./PageGeneralContainer.module.css";
import { ToastContainer } from "react-toastify";
import isoLogoBionovaQ from "../../assets/images/ISOLOGO-BIONOVAQ.svg"
import changePasswordImage from "../../components/NavBar/Menu/icons/CHANGEPASSWORD.svg"
import sectorImage from "../../components/NavBar/Menu/icons/SECTOR.svg"
import userImage from "../../components/NavBar/Menu/icons/USER.svg"
import connectedImage from "../../components/NavBar/Menu/icons/CONNECTED.svg"
import inProgressImage from "../../components/NavBar/Menu/icons/INPROGRESS.svg"
import completedImage from "../../components/NavBar/Menu/icons/COMPLETED.svg"
import sterilizationImage from "../../components/NavBar/Menu/icons/STERILIZATION.svg"
import cleaningImage from "../../components/NavBar/Menu/icons/CLEANING.svg"
import sterilizerImage from "../../components/NavBar/Menu/icons/STERILIZER.svg"
import disinfectorImage from "../../components/NavBar/Menu/icons/DISINFECTOR.svg"
import washerImage from "../../components/NavBar/Menu/icons/WASHER.svg"
import myLicenseImage from "../../components/NavBar/Menu/icons/MYLICENSES.svg"
import iqasImage from "../../components/NavBar/Menu/icons/IQAS.svg"
import sprImage from "../../components/NavBar/Menu/icons/SPR.svg"
import parametersImage from "../../components/NavBar/Menu/icons/PARAMETERS.svg"
import dataBaseImage from "../../components/NavBar/Menu/icons/DATABASEMIGRATION.svg"
import brandsImage from "../../components/NavBar/Menu/icons/BRANDS.svg"
import modelsImage from "../../components/NavBar/Menu/icons/MODELS.svg"
import instrumentImage from "../../components/NavBar/Menu/icons/INSTRUMENTS.svg"
import packagesTypesImage from "../../components/NavBar/Menu/icons/PACKAGESTYPES.svg"
import savedImage from "../../components/NavBar/Menu/icons/SAVED.svg"
import { IoIosArrowBack } from "react-icons/io";
import Redirect from "../../utils/Redirect";


export default function PageContainer({
  title,
  subtitle,
  children,
  pageStyles = true,
  withImage = true,
  clickBack = false,
  backUrl,
  category,
  onClickBack = () => {},
}) {

  // const imageColor = circleColor ? circleColor : (category === "Sterilization" ? styles.sterilization : styles.washing);
  // const imageColor = category == "Sterilization" || "Change Password" ? styles.sterilization : styles.washing;
  // const image = category === "Sterilization" ? sterilizationImage : customImage;
  // const image = title === "Change Password" ? changePasswordImage : isoLogoBionovaQ;
  
  const imageColor = styles.menuItems


  const imageMap = {
    "changePassword": changePasswordImage,
    "sector": sectorImage,
    "user" : userImage,
    "connected": connectedImage,
    "biTestInProgress" : inProgressImage,
    "biTestCompleted": completedImage,
    "hygieneInProgress": inProgressImage,
    "hygieneCompleted" : completedImage,
    "sterilization" : sterilizationImage,
    "cleaning" : cleaningImage,
    "sterilizer" : sterilizerImage,
    "instruments" : instrumentImage,
    "disinfector" : disinfectorImage,
    "washer" : washerImage,
    "myLicense": myLicenseImage,
    "IQAS" : iqasImage,
    "SPR" : sprImage,
    "saved" : isoLogoBionovaQ,
    "parameters" : parametersImage,
    "log" : userImage,
    "dataBaseMigration" : dataBaseImage,
    "brands": brandsImage,
    "models": modelsImage,
    "materialsTypes": instrumentImage,
    "materials": instrumentImage,
    "packagesTypes": packagesTypesImage,
    "saved" : savedImage,
  };
  const image = imageMap[category]

  return (
    <div className={pageStyles ? styles.pageContainer : undefined} >
      <ToastContainer />
      <div className={pageStyles ? styles.page : undefined}>
        {clickBack &&  <IoIosArrowBack
            className={styles.backIcon}
            onClick={() =>
              clickBack
                ? onClickBack()
                : Redirect({
                    redirect: true,
                    path: backUrl,
                    // parameters: { moduleId: moduleName, screenId: screenName },
                  })
            }
          />}
        <div className={category === "changePassword" ? styles.headerChange : styles.header}>
            <div className={styles.breadcrumb}>
              <div className={`${styles.imageContainer} ${imageColor}`}>
              {withImage && <img src={image} className={styles.image} alt={title} />}
              </div>
              <div className={styles.titlesContainer}>
                {/* <p className={styles.title}>{title}</p> */}
                <p className={styles.title}>
                {image && `| ${title}`}
              </p>
              </div>
            </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}