import React, { useContext } from "react";
import styles from "./IndicatorLabel.module.css";
import { FaRegCalendarTimes } from "react-icons/fa";
import indicatorImg from "../../assets/images/chemical.jpg";
import { getImageIndicatorByName } from "../../utils/indicatorImage";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";

export default function IndicatorLabel({
  name,
  lot,
  expirationDate,
  serial,
  quantity,
  zone,
  load,
  backgroundColor,
  border,
  animation,
  status,
  nro,
  result,
  evaluationResult,
  resultBiologic,
  pcd,
  resultCH,
  userResultCH,
  detail,
  category = "Sterilization"
}) {

  const getColorForResult = (result) => {
    switch (result) {
      case 3 :
        return "red"; 
      case 4 :
        return "green"; 
      default:
        return ""; 
    }
  };

  const getColorForEvaluationResult = (evaluationResult) => {
    switch (evaluationResult) {
      case 1 :
        return "red"; 
      case 0 :
        return "green"; 
      default:
        return ""; 
    }
  }

  const getColorForResultBiologic = (result) => {
    switch (result) {
      case 1:
        return "red"; 
      case 2:
        return "green"; 
      case 3:
        return "black";
      default:
        return ""; 
    }
  };

  const getResultText = (result) => {
    switch (result) {
      case 3:
        return `${t("UnsafeSpan")} - `;
      case 4:
        return `${t("SafeSpan")} - `;
      default:
        return "";
    }
  };

  const getEvaluationText = (evaluationResult) => {
    switch (evaluationResult) {
      case 0:
        return t("AgreeSpan");
      case 1:
        return t("DisagreeSpan");
      default:
        return "";
    }
  };

  const getResultBiologic = (result) => {
    switch (result) {
      case 1:
        return t("PositiveSpan");
      case 2:
        return t("NegativeSpan");
      case 3:
        return t("CanceledSpan");
      default:
        return "";
    }
  };

  const getResultCH = (result) => {
      switch (result) {
        case 3:
          return `${t("UnsafeSpan")} - `;
        case 4:
          return `${t("SafeSpan")} - `;
        default:
          return "";
      }
    };

    const getColorForResultCH = (result) => {
      switch (result) {
        case 3 :
          return "red"; 
        case 4 :
          return "green"; 
        default:
          return ""; 
      }
    };

    const getUserResultCH = (result) => {
      switch (result) {
        case 0:
          return t("AgreeSpan");
        case 1:
          return t("DisagreeSpan");
        default:
          return "";
      }
    };

    const getColorForUserResultCH = (result) => {
      switch (result) {
        case 0 :
          return "green"; 
        case 1 :
          return "red"; 
        default:
          return ""; 
      }
    };

  return (
    <div
      className={`${styles.labelContainer} ${styles[backgroundColor]} ${
        border ? styles.border : ""
      } ${animation ? styles.border : ""}`}
    >
      {detail ? (
      <div className={styles.indicatorInfo}>
        <p className={styles.indicatorName}>
          {name} {serial && ` - ${serial}`}
          <span
          className={styles.result2}
          style={{ color: getColorForResultBiologic(resultBiologic) }}
        >
          {getResultBiologic(resultBiologic)}
        </span>
        <span
          className={styles.result2}
          style={{ color: getColorForResultCH(resultCH) }}
        >
          {getResultCH(resultCH)}
        </span>
        <span
          className={styles.result2}
          style={{ color: getColorForUserResultCH(userResultCH) }}
        >
          {getUserResultCH(userResultCH)}
        </span>
        </p>
        <p className={styles.quantity}>
          {!zone && quantity && `${t("QuantitySpan")}: ${quantity}`}
        </p>
        <p className={styles.quantity}>{pcd && `PCD: ${pcd}`}</p>

        <p className={styles.quantity}>{zone && `${category.toUpperCase() === "WASHING"  ? t("BlankEntry1") : t("ZoneSpan")}: ${zone}`}</p>
        <div className={styles.indicatorFooter}>
          <p>{lot}</p>
          <p>
          <FaRegCalendarTimes /> {df(expirationDate, "monthYear")}
          </p>
        </div>
      </div>
      ):(
      <div className={styles.indicatorInfo}>
        <p className={styles.indicatorName}>
          {name} {serial && ` - ${serial}`}
        </p>
        <span
          className={styles.result}
          style={{ color: getColorForResult(result) }}
        >
          {getResultText(result)}
        </span>
        <span
          className={styles.result}
          style={{ color: getColorForEvaluationResult(evaluationResult) }}
        >
          {getEvaluationText(evaluationResult)}
        </span>
        <span
          className={styles.result}
          style={{ color: getColorForResultBiologic(resultBiologic) }}
        >
          {getResultBiologic(resultBiologic)}
        </span>
        <span
          className={styles.result}
          style={{ color: getColorForResultCH(resultCH) }}
        >
          {getResultCH(resultCH)}
        </span>
        <span
          className={styles.result}
          style={{ color: getColorForUserResultCH(userResultCH) }}
        >
          {getUserResultCH(userResultCH)}
        </span>
        <p className={styles.quantity}>
          {!zone && quantity && `${t("QuantitySpan")}: ${quantity}`}
        </p>
        <p className={styles.quantity}>{pcd && `PCD: ${pcd}`}</p>

        <p className={styles.quantity}>{zone && `${category.toUpperCase() === "WASHING"  ? t("BlankEntry1") : t("ZoneSpan")}: ${zone}`}</p>
        <div className={styles.indicatorFooter}>
          <p>{lot}</p>
          <p>
          <FaRegCalendarTimes /> {df(expirationDate, "monthYear")}
          </p>
        </div>
      </div>
       )}
      <div className={styles.imgContainer}>
        <img src={getImageIndicatorByName(name?.toUpperCase())} alt={name} />
      </div>
    </div>
  );
}
