import React from "react";
import t from "../../../utils/translation";
import df from "../../../utils/dateFormater";
// import useStyles from './DetailStyles';

import Modal from "../../Modal";
import Input from "../../Input";

export default function Detail(props) {
  // const classes = useStyles();
  const { fields, object, classes, dispatch, title, idField, closeModalCallback,
  } = props;
  const NametoString = `(${
    object[idField.split(".")[0] + ".toString"] || object.name || object.brand
  })`;


  const closeModal = () => {
    dispatch({ type: "closeDetail" });
    closeModalCallback()

  };

  const header = (
    <React.Fragment>
      <div className={classes.header}>
        <div className={classes.headerText}>
          {(t("Title") || `${title}`) + ` ${NametoString}`}
        </div>
        <span className={classes.modalCloseButton} onClick={closeModal}>
          <svg viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </span>
      </div>
    </React.Fragment>
  );

  const showDetailField = (field) => {
    if(Object.keys(field).includes('component')) {
      return true;
    }
    if (field.visibleCondition) {
      if (
        object[field.visibleCondition[0]]?.toString() !==
        field.visibleCondition[2]
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal header={header} closeModal={closeModal} responsive>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0"}>
          {fields.map((field, index) => {
            const value =
              field.type === "date"
                ? df(object[field.name], "date")
                : field.type === "dateTime"
                ? df(object[field.name], "dateTime")
                : object[field.name];

            if(Object.keys(field).includes('component')) {
              return React.cloneElement(field.component, {...field.component.props})
            }
            if (field.type === "image") {
              return (
                <div className="col-12 px-0" key={index}>
                  <Input
                    label={field.label}
                    value={value.base64}
                    variant={"image"}
                  />
                </div>
              );
            } else {

            }
            if (showDetailField(field)) {
              return (
                <div
                  className={field.class || "col-12 col-md-6 px-0"}
                  key={index}
                >
                  <Input label={field.label} value={value} variant={"detail"} />
                </div>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
}
