import React, { useState, useEffect, useReducer } from "react";
import request from "../../utils/request";
import { flatten } from "flat";

import CRUD from "../../components/CRUD";
import reducer from './ScoreRPEReducer';

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([
        object.id,
        object.description || object.toString,
        object,
      ]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = { programs: [], scoreTypes: [], loadingContent: true };

export default function ScoreRPE() {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { programs, scoreTypes } = state;

  useEffect(() => {
    Promise.all([
      request().get("/api/Program"),
      request().get(`/api/ScoreRPE/GetScoreTypes`),
    ])
      .then(([programsData, scoreTypesData]) => {
        setTimeout(() => {
          dispatch({
            type: "SET_CONTENT",
            payload: {
              programs: formatSelectObject(programsData.data, "Empty Program"),
              scoreTypes: formatSelectObject(
                scoreTypesData.data,
                "Empty Score Type"
              ),
            },
          });
        }, 250);
      })
      .catch((error) => console.log(error.response));
  }, []);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/ScoreRPE`)
      .then((response) => {
        setData(response.data.map((x) => flatten(x)));
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      data={data}
      loadingData={loadingData}
      url={"/api/ScoreRPE"}
      updateData={updateData}
      title={"Score RPE"}
      columns={[
        "program.toString",
        "minutesFrom",
        "minutesTo",
        "score",
        "scoreTypeDescription",
      ]}
      headers={["Program", "From", "To", "Score", "Type"]}
      idField={"id"}
      fieldsMap={[
        "program.id",
        "minutesFrom",
        "minutesTo",
        "score",
        "scoreType",
      ]}
      formFields={[
        {
          label: "Program",
          class: "col-12 col-md-6 px-0",
          required: true,
          type: "select",
          options: programs,
          objectField: true,
        },
        { label: "MinutesFrom", class: "col-12 col-md-6 px-0", required: true },
        { label: "MinutesTo", class: "col-12 col-md-6 px-0", required: true },
        { label: "Score", class: "col-12 col-md-6 px-0", required: true },
        {
          label: "Type",
          class: "col-12 col-md-6 px-0",
          required: true,
          type: "select",
          options: scoreTypes,
        },
      ]}
      detailFields={[
        { label: "Program", name: "program.toString" },
        { label: "MinutesFrom", name: "minutesFrom" },
        { label: "MinutesTo", name: "minutesTo" },
        { label: "Score", name: "score" },
        { label: "Type", name: "scoreTypeDescription" },
      ]}
      baseObject={{
        id: null,
        // programId:"",
        program: {
          id: "",
          programIndex: "",
          time: "",
          temperature: "",
        },
        minutesFrom: "",
        minutesTo: "",
        score: "",
        scoreType: "",
      }}
    />
  );
}

