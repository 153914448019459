import React from "react";
import LocalizationContext from "../utils/LocalizationContext";
import NavBar from "../components/NavBar";
import PageContentLoading from "../components/PageContentLoading";
import AppParameter from "../pages/AppParameter";
import useMenu from "../utils/Hooks/useMenu";
import useLocale from "../utils/Hooks/useLocale";
import ValidateURL from "../utils/ValidateURL";
import { CompanyContextProvider } from '../utils/context/CompanyContext';

export default () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.Configuration,
    key: "Parameters",
  };
  const screenName = 310
  const locale = useLocale(screenName);

  return (
    <LocalizationContext.Provider value={{ locale: locale }}>
      <CompanyContextProvider>
        <ValidateURL currentPage={currentPage}>
          <NavBar
            screenName={screenName}
            moduleName={100}
            MenuOptions={menu}
            currentPage={currentPage}
          >
            {locale ? <AppParameter /> : <PageContentLoading />}
          </NavBar>
        </ValidateURL>
      </CompanyContextProvider>
    </LocalizationContext.Provider>
  );
};

