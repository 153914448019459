import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Select2 from "../../components/Select2";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Gtin() {
  const query = useQuery();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [products, setproducts] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState(
    query.get("distributorId") || ""
  );

  useEffect(() => {
    request()
      .get(`/api/distributor`)
      .then((response) => {
        setTimeout(() => {
          setDistributors(
            response.data.reduce((distributorOptions, distributor) => {
              distributorOptions.push([distributor.id, distributor.name]);
              return distributorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedDistributor) {
      request()
        .get(`/api/product/GetByDistributor?distributorId=${selectedDistributor}`)
        .then((types) => {
          setproducts(
            types.data.reduce((productList, prods) => {
              productList.push([prods.id, '[' + prods.productModel.typeDescription.toUpperCase() + '] ' + prods.brand.toUpperCase() + ' - ' + prods.name.toUpperCase()]);
              return productList;
            }, [[null, 'Choose a Product']])
          );

          setLoadingContent(false);
        })
        .catch(error => console.log(error.response));
    }
  }, [selectedDistributor]);

  products.sort(function (a, b) {
    if (a[0] == null || b[0] == null) {
      return 0;
    }

    if (a[1] > b[1]) {
      return 1;
    }
    if (a[1] < b[1]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  useEffect(() => {
    if (selectedDistributor) {
      setLoadingData(true);
      request()
        .get(`/api/gtin/GetByDistributor?distributorId=${selectedDistributor}`)
        .then(response => {
          setData(
            response.data.reduce((readingsList, reading) => {
              let productNameFromArr = "";

              for (let i = 0; i < products.length; i++) {
                if (products[i][0] === reading.productId) {
                  productNameFromArr = products[i][1];
                }
              }

              readingsList.push({
                ...reading,
                productName: productNameFromArr,
              });
              return readingsList;
            }, [])
              .map(x =>
                _.mapKeys(x, (value, key) => {
                  return "gtin." + key;
                })
              )
          );
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        }).catch((error) => console.log(error.response));
    }
  }, [loadingContent, products, selectedDistributor]);

  const updateData = () => {
    if (selectedDistributor) {
      setLoadingData(true);
      request()
        .get(`/api/gtin/GetByDistributor?distributorId=${selectedDistributor}`)
        .then(response => {
          setData(
            response.data.reduce((readingsList, reading) => {
              let productNameFromArr = "";

              for (let i = 0; i < products.length; i++) {
                if (products[i][0] === reading.productId) {
                  productNameFromArr = products[i][1];
                }
              }

              readingsList.push({
                ...reading,
                productName: productNameFromArr,
              });
              return readingsList;
            }, [])
              .map(x =>
                _.mapKeys(x, (value, key) => {
                  return "gtin." + key;
                })
              )
          );
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        });
    }
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div className={"col-12 col-md-5 px-0"}>
            <Select2
              label={"Distributor"}
              options={distributors}
              emptyOptionText={"Choose A Distribuidor"}
              variant={"outlined"}
              value={selectedDistributor}
              onChange={(val) => setSelectedDistributor(val)}
            />
          </div>
        </div>
      </div>
      {selectedDistributor && (
        <CRUD
          data={data}
          url={`/api/gtin`}
          title={"Gtin"}
          updateData={updateData}
          loadingData={loadingData}
          columns={[
            "gtin.productName",
            "gtin.codigo"
          ]}
          headers={["Product", "Codigo"]}
          idField={"gtin.id"}
          fieldsMap={[
            "gtin.distributorId",
            "gtin.productId",
            "gtin.codigo"
          ]}
          formFields={[
            {
              label: "Distributor",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: distributors,
              defaultValue: selectedDistributor,
              disabled: true,
              required: true,
            },
            {
              label: "Product",
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: products,
              required: true
            },
            { label: "Codigo", class: "col-12 col-md-6 px-0", required: true }
          ]}
          detailFields={[
            { label: "Product", name: "gtin.productName" },
            { label: "Codigo", name: "gtin.codigo" }
          ]}
          baseObject={{
            gtin: {
              id: null,
              productId: "",
              codigo: "",
              distributorId: selectedDistributor,
            }
          }}
        />
      )}
    </div>
  );
}
