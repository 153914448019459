import React from "react";

import useTableQ from "../../../utils/Hooks/useTableQ";
import request from "../../../utils/request.js";

import TableContainerQ from "../../../components/TableBionovaQ/TableContainerQ/TableContainerQ";
import TableHeadQ from "../../../components/TableBionovaQ/TableHeadQ/TableHeadQ";
import TableHeaderCellQ from "../../../components/TableBionovaQ/TableHeaderCellQ/TableHeaderCellQ";
import TableBodyQ from "../../../components/TableBionovaQ/TableBodyQ/TableBodyQ";
import TableRowQ from "../../../components/TableBionovaQ/TableRowQ/TableRowQ";
import TableRowCellQ from "../../../components/TableBionovaQ/TableRowCellQ/TableRowCellQ";
import TablePaginationQ from "../../../components/TableBionovaQ/TablePaginationQ/TablePaginationQ.jsx";
import TruncatedText from "../../../components/TableBionovaQ/TruncatedText/TruncatedText.jsx";
import SpinnerQ from "../../../components/SpinnerQ/SpinnerQ.jsx";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Checkbox } from "@material-ui/core";

const BrandsTable = ({
  brands,
  getBrands,
  handleData,
  handleModal,
  handleDetail,
  loading,
}) => {
  const {
    currentPage,
    currentItems,
    totalPages,
    handleChangePage,
    handleChangeItemsPerPage,
    handleSortByColumn,
    setCurrentPage,
  } = useTableQ(brands, [
    "hasGlobalBrand",
    "brand",
    "description",
    "observations",
  ]);

  const onEdit = (value) => {
    let item = brands.find((m) => m.id === value);

    handleData({
      brand: item.brand,
      description: item.description,
      observations: item.observations,
      id: item.id,
      machineMakeId: item.machineMakeId,
    });

    handleModal(true);
  };

  const onDelete = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        request()
          .delete("/api/CompanyMachineMake", data)
          .then(() => {
            getBrands();
            toast.success("Your brand has been deleted.");
          });
      }
    });
  };

  return (
    <TableContainerQ>
      <TableHeadQ actions={true}>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("hasGlobalBrand")}
        >
          IsGlobal
        </TableHeaderCellQ>
        <TableHeaderCellQ
          date={true}
          handleClick={() => handleSortByColumn("brand")}
        >
          Brand
        </TableHeaderCellQ>
        <TableHeaderCellQ handleClick={() => handleSortByColumn("description")}>
          Description
        </TableHeaderCellQ>

        <TableHeaderCellQ
          handleClick={() => handleSortByColumn("observations")}
        >
          Observations
        </TableHeaderCellQ>
      </TableHeadQ>
      {loading ? (
        <div className="spinnerContainer">
          <SpinnerQ />
        </div>
      ) : (
        <TableBodyQ>
          {currentItems.map((b) => (
            <TableRowQ
              key={b.id}
              actions={true}
              edit={true}
              detail={true}
              deleteRow={true}
              onEdit={() => onEdit(b.id)}
              onDelete={() => onDelete(b)}
              doubleClick={() => onEdit(b.id)}
              onDetail={() => handleDetail(b)}
            >
              <TableRowCellQ >
                <Checkbox checked={b.hasGlobalBrand} />
              </TableRowCellQ>
              <TableRowCellQ >
                <TruncatedText text={b.brand} maxLength={25} />
              </TableRowCellQ>
              <TableRowCellQ>
                <TruncatedText text={b.description} maxLength={25} />
              </TableRowCellQ>

              <TableRowCellQ>
                <TruncatedText text={b.observations} maxLength={25} />
              </TableRowCellQ>
            </TableRowQ>
          ))}
        </TableBodyQ>
      )}

      <TablePaginationQ
        handleChangePage={handleChangePage}
        handleChangeItemsPerPage={handleChangeItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </TableContainerQ>
  );
};

export default BrandsTable;
